import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
  InputGroup,
} from "react-bootstrap";
import "../assets/css/App.css";

import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import { v4 } from "uuid";
import mixpanel from "mixpanel-browser";
import NotyfContext from "../contexts/NotyfContext";
import useSidebar from "../hooks/useSidebar";
import useAuth from "../hooks/useAuth";
import { logError } from "../utils/useErrorlogging";

const Editexpensemodal = (props) => {
  var store = firebase.storage();
  const [savemodalloading, setSavemodalloading] = useState(false);
  const [imageupload, setImageupload] = useState(null);
  const [imageerror, setImageerror] = useState("");
  const [upload, setupload] = useState("");
  const [savemodalopen, setsavemodalopen] = useState("first");
  const [imagename, setImagename] = useState("");
  const [existingimage, setExistingimage] = useState("");
  const [disablesave, setDisablesave] = useState(false);
  const [globalValidationError, setGlobalValidationError] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isTeamAccount, userPermission } = useAuth();
  let expensePermission =
    Object.keys(userPermission).includes("expense") &&
    Object.keys(userPermission.expense).includes("permission_key")
      ? userPermission.expense.permission_key
      : "";
  const [teamMember, setTeamMember] = useState(
    props.editdata && props.editdata.expense_assignee
      ? props.editdata.expense_assignee
      : {}
  );
  console.log(props);
  const { isOpen } = useSidebar();
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  let usercurrency = localStorage.getItem("currency") || "USD";
  const file = useRef();
  const data = props.editdata;
  useEffect(() => {
    if (props.createnewmodal) {
      setDisablesave(false);
    }
  }, [props.createnewmodal]);
  const uploadToCloud = () => {
    if (imageupload == null) return;
    const imageref = ref(store, `files/${imageupload.name + v4()}`);
    setupload("starting");
    uploadBytes(imageref, imageupload).then((res) => {
      setImagename(res.metadata.name);
      console.log("done");
      console.log(res);
      setupload("uploaded");
    });
    // }
  };
  useEffect(() => {
    setImagename("");
    setupload("");
    if (imageupload) {
      console.log(imageupload);
      if (imageupload.size > 5242880) {
        setImageerror("Select file size upto 5 Mb");
        file.current.value = "";

        return;
      } else {
        setImageerror("");
        uploadToCloud();
      }
    } else {
      setImageerror("");
      setupload("");
    }
  }, [imageupload]);
  useEffect(() => {
    console.log("noooooooo");
    setExistingimage(props.editdata.expense_image_id);
    if (props.createnewmodal) {
      if (
        props.editdata &&
        Object.keys(props.editdata).includes("expense_assignee")
      ) {
        setTeamMember(props.editdata.expense_assignee);
      } else {
        setTeamMember({});
      }
    } else {
      setTeamMember({});
    }
  }, [props.createnewmodal]);

  const submitData = async (data) => {
    mixpanel.track("Update Expenses >>> Edit Expense(Edit Expense Web)", {
      ...data,
    });
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        data.team = true;
      } else {
        delete data.team;
      }
      if (teamMember && Object.keys(teamMember).length !== 0) {
        data.expense_assignee_uid = teamMember.team_member_uid;
        data.expense_assignee_ref_id = teamMember.team_member_reference_id;
        data.expense_assignee = teamMember;
      }
      fetch("https://bestmate.us/api/expenses/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          if (res.status >= 200 && res.status <= 300) {
            console.log(res);
            const result = await res.json();

            console.log(result);
            // props.setData(newchange);
            setsavemodalopen("first");
            setupload("");
            setImageerror("");
            setImagename("");
            setExistingimage("");
            props.setCreatenewmodal(false);
            // setDisablesave(false);
            setGlobalValidationError(false);
            props.setLoading(true);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `Expense updated successfully`,
              ripple: "true",
              dismissible: "true",
            });
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Expense Edit Modal Web)`, {
            //   error: "expense add api",
            // });
            logError({
              error: "fetch error",
              api: "expense add api",
              component: "Expense Edit Modal Web",
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          // mixpanel.track(`Error (Expense Edit Modal Web)`, {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: "Expense Edit Modal Web",
          });
          console.log(err);
        });
    }
  };
  function updateDateWithoutChangingTime(
    originalDate,
    newYear,
    newMonth,
    newDay
  ) {
    // Get the time components from the original date
    var hours = originalDate.getHours();
    var minutes = originalDate.getMinutes();
    var seconds = originalDate.getSeconds();
    var milliseconds = originalDate.getMilliseconds();

    // Create a new date with the desired year, month, and day
    var updatedDate = new Date(
      newYear,
      newMonth - 1,
      newDay,
      hours,
      minutes,
      seconds,
      milliseconds
    );

    return updatedDate;
  }

  function parseDateString(dateString) {
    var parts = dateString.split("-");
    var year = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var day = parseInt(parts[2], 10);
    return { year: year, month: month, day: day };
  }
  // submitHandler function is used to arrange data before sending to backend
  const submitHandler = (elem) => {
    setDisablesave(true);

    console.log(elem, data);
    // console.log(imagename ? imagename : props.editdata.expense_image_id);

    console.log(imagename ? imagename : existingimage ? existingimage : "");
    const data = {
      expense_name: elem.expenseName,
      expense_description: elem.description,
      //   item_description: desc,d
      expense_total: elem.total.toString(),
      // expense_date: Math.floor(new Date(elem.date).getTime()),
      expense_category: elem.category,
      expense_type: elem.type,
      expense_id: props.editdata.expense_id,
      expense_image_id: imagename
        ? imagename
        : existingimage
        ? existingimage
        : "",

      //   item_supplier: elem.supplier,
      //   item_quantity: Number(elem.quantity),
      //   item_quantity_per_box: elem.quantityperbox,
    };
    console.log(data);
    console.log(new Date());
    const originalDate = new Date();
    var parsedDate = parseDateString(elem.date);

    var updatedDate = updateDateWithoutChangingTime(
      originalDate,
      parsedDate.year,
      parsedDate.month,
      parsedDate.day
    );
    console.log(updatedDate);
    console.log(data);
    data.expense_date = moment(new Date(updatedDate)).valueOf();
    submitData(data);
  };
  console.log(
    moment(new Date()).format("YYYY-MM-DD"),
    data.expense_date,
    moment(new Date(data.expense_date)).format("YYYY-MM-DD")
  );
  console.log(imagename, props.editdata.expense_image_id);

  return (
    <>
      <Modal
        show={props.createnewmodal}
        onHide={() => {
          setsavemodalopen("first");
          setupload("");
          setImageerror("");
          setImagename("");
          setExistingimage("");
          mixpanel.track("Close >>> Edit Expense(Edit Expense Web)");
          props.setCreatenewmodal(false);
          setGlobalValidationError(false);
        }}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              {`Edit ${
                props.type.charAt(0).toUpperCase() + props.type.slice(1)
              }`}
            </h1>
            <div
              onClick={() => {
                setsavemodalopen("first");
                setupload("");
                setImageerror("");
                setImagename("");
                setExistingimage("");
                mixpanel.track("Close >>> Edit Expense(Edit Expense Web)");
                props.setCreatenewmodal(false);
                setGlobalValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {savemodalloading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "10px 24px" }}>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  expenseName: Yup.string().required(
                    "Expense name is required"
                  ),
                  total: Yup.number()
                    .required("Total is required")
                    .typeError("Enter a valid number"),
                })}
                onSubmit={(elem) => submitHandler(elem)}
                initialValues={{
                  expenseName: data.expense_name,
                  date: moment(new Date(data.expense_date)).format(
                    "YYYY-MM-DD"
                  ),
                  category: data.expense_category,
                  total: data.expense_total,
                  description: data.expense_description,
                  //   unit: "",
                  //   sell: "",
                  //   cost: "",
                  //   code: "",
                  //   supplier: "",
                  //   quantity: "",
                  //   quantityperbox: "",
                  type: props.type.toUpperCase(),
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  validateForm,
                }) => (
                  <Form
                    noValidate
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const obj = await validateForm();
                      console.log(obj);
                      if (Object.keys(obj).length !== 0) {
                        setGlobalValidationError(true);
                      } else {
                        setGlobalValidationError(false);
                        handleSubmit();
                      }
                    }}
                    // onKeyDown={(keyEvent) => {
                    //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    //   }
                    // }}
                  >
                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        // backgroundColor: "pink",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("first")}
                    >
                      <div>{`${
                        props.type.charAt(0).toUpperCase() + props.type.slice(1)
                      } Details`}</div>
                      {/* {savemodalopen === "first" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )} */}
                    </div>
                    {/* {savemodalopen === "first" ? (
                      <> */}
                    <div style={{ margin: "5px 0" }}>
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Expense Name"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="expenseName"
                              onKeyDown={handleKeyDown}
                              placeholder="Expense Name"
                              value={values.expenseName}
                              onChange={handleChange}
                              isInvalid={!!errors.expenseName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.expenseName}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          {/* <FloatingLabel
                                controlId="floatingInput"
                                label="Item Code"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="code"
                                  placeholder="Item Code"
                                  value={values.code}
                                  onChange={handleChange}
                                  style={{ padding: "8px" }}
                                />
                              </FloatingLabel> */}
                          <Form.Group style={{ height: "100%" }}>
                            <InputGroup style={{}}>
                              <InputGroup.Text>
                                {getSymbolFromCurrency(usercurrency)}
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                onKeyDown={handleKeyDown}
                                name="total"
                                min={0}
                                placeholder="Total Expense"
                                value={values.total}
                                onChange={(event) => {
                                  if (
                                    event.target.value.replace(/\s/g, "")
                                      .length <= 10
                                  ) {
                                    handleChange(event);
                                  }
                                }}
                                style={{ padding: "8px", height: "57px" }}
                                isInvalid={!!errors.total}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.total}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Expense Category"
                            className="mb-3"
                          >
                            <Form.Select
                              aria-label="Floating label select example"
                              name="category"
                              onKeyDown={handleKeyDown}
                              value={values.category}
                              onChange={handleChange}
                            >
                              <option value={""}>
                                Select Expense Category
                              </option>
                              <option value={"Automobile Expense"}>
                                {"Automobile Expense"}
                              </option>
                              <option value={"Employee Expense"}>
                                {"Employee Expense"}
                              </option>
                              <option value={"Parking Expense"}>
                                {"Parking Expense"}
                              </option>
                              <option value={"Equipment"}>{"Equipment"}</option>
                              <option value={"Fuel and Mileage Expense"}>
                                {"Fuel and Mileage Expense"}
                              </option>
                              <option value={"Insurance"}>{"Insurance"}</option>
                              <option value={"Labor"}>{"Labor"}</option>
                              <option value={"Materials"}>{"Materials"}</option>
                              <option value={"Meals and Entertainment"}>
                                {"Meals and Entertainment"}
                              </option>
                              <option value={"Office Supplies"}>
                                {"Office Supplies"}
                              </option>
                              <option value={"Other Expenses"}>
                                {"Other Expenses"}
                              </option>
                              <option value={"Phone Expense"}>
                                {"Phone Expense"}
                              </option>
                              <option value={"Rent"}>{"Rent"}</option>
                              <option value={"Taxes"}>{"Taxes"}</option>
                              <option value={"Travel Expense"}>
                                {"Travel Expenses"}
                              </option>
                            </Form.Select>
                            {/* <Form.Control
                              list="expensecategoryinput"
                              type="text"
                              name="category"
                              placeholder="text"
                              value={values.category}
                              onChange={handleChange}
                            /> */}
                          </FloatingLabel>
                          {/* <datalist id="expensecategoryinput">
                            <option value="Parking Charges" />
                            <option value="Fuel Expenses" />
                            <option value="Helper Cost" />
                            <option value="Raw Material Cost" />
                          </datalist> */}
                        </Col>
                        <Col md>
                          {/* <FloatingLabel
                                controlId="floatingInput"
                                label="Item Code"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="code"
                                  placeholder="Item Code"
                                  value={values.code}
                                  onChange={handleChange}
                                  style={{ padding: "8px" }}
                                />
                              </FloatingLabel> */}

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Expense Date"
                            className="mb-3"
                          >
                            <Form.Control
                              type="date"
                              onKeyDown={handleKeyDown}
                              className="calendar-input"
                              isInvalid={errors.date}
                              required
                              name="date"
                              placeholder="text"
                              value={values.date}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.date}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Description"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          rows="6"
                          type="text"
                          name="description"
                          placeholder="Type something"
                          value={values.description}
                          onChange={handleChange}
                          // isInvalid={errors.item_name}
                        />
                      </FloatingLabel>
                      {props.type === "product" ? (
                        <Row className="g-2">
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Supplier"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                onKeyDown={handleKeyDown}
                                name="supplier"
                                placeholder="Supplier"
                                value={values.supplier}
                                onChange={handleChange}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Quantity Per Box"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                onKeyDown={handleKeyDown}
                                name="quantityperbox"
                                placeholder="Quantity Per Box"
                                value={values.quantityperbox}
                                onChange={handleChange}
                                style={{ padding: "8px" }}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                      {expensePermission.includes("ALL") ? (
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Team Member"
                          className="mb-3"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="category"
                            onKeyDown={handleKeyDown}
                            onChange={(event) => {
                              const selectedId = event.target.value;
                              if (selectedId) {
                                const selectedMember =
                                  props.teamMemberList.find((member) => {
                                    console.log(
                                      member,
                                      member.team_member_email,
                                      selectedId,
                                      event
                                    );
                                    return (
                                      member.team_member_email === selectedId
                                    );
                                  });
                                console.log(selectedMember);
                                setTeamMember(selectedMember);
                              } else {
                                setTeamMember({});
                              }
                            }}
                            value={teamMember.team_member_email}
                          >
                            <option
                              disabled={
                                Object.keys(teamMember).length === 0
                                  ? false
                                  : true
                              }
                              value={""}
                            >
                              Select Team Member
                            </option>

                            {props.teamMemberList.map((elem) => (
                              <option value={elem.team_member_email}>
                                {elem.team_member_name}
                              </option>
                            ))}
                          </Form.Select>
                        </FloatingLabel>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* </>
                    ) : (
                      <></>
                    )} */}
                    <hr style={{ margin: 0 }} />

                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("second")}
                    >
                      <div>Expense Document</div>
                      {/* {savemodalopen === "second" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )} */}
                    </div>
                    {/* {savemodalopen === "second" ? (
                      <> */}
                    {existingimage ? (
                      <div style={{ margin: "5px 0" }}>
                        {/* <a
                          type="button"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#3679e8",
                            borderWidth: 0,
                            color: "white",
                          }}
                          target="_blank"
                          href={`https://firebasestorage.googleapis.com/v0/b/bestmate-app.appspot.com/o/files%2F${props.editdata.expense_image_id}?alt=media&token=75f5f723-170f-42ca-a65c-92174cdcaa44`}
                        >
                          View Document
                        </a> */}
                        <button
                          type="button"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#3679e8",
                            borderWidth: 1,
                            borderColor: "#3679e8",

                            color: "white",
                          }}
                          onClick={async () => {
                            mixpanel.track(
                              "View Document >>> Edit Expense(Edit Expense Web)"
                            );
                            store
                              .ref("files/" + props.editdata.expense_image_id)
                              .getDownloadURL()
                              .then((res) => {
                                window.open(res);
                              });
                          }}
                        >
                          View Document
                        </button>
                        <button
                          type="button"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderColor: "black",
                            marginLeft: 5,
                            color: "black",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "Replace Document >>> Edit Expense(Edit Expense Web)"
                            );
                            setExistingimage("");
                          }}
                        >
                          Replace
                        </button>
                      </div>
                    ) : (
                      <div style={{ margin: "5px 0" }}>
                        <Form.Group className="mb-3">
                          {/* <Form.Label style={{ color: "black" }}>
                            Expense Document
                          </Form.Label> */}
                          <Form.Control
                            ref={file}
                            type="file"
                            placeholder="Unit Type"
                            onChange={(val) => {
                              mixpanel.track(
                                "Expense Document >>> Edit Expense(Edit Expense Web)"
                              );
                              setImageupload(val.target.files[0]);
                              // uploadToCloud(val);
                            }}
                          />
                        </Form.Group>
                        {upload ? (
                          upload === "starting" ? (
                            <p style={{ color: "black", fontSize: 13 }}>
                              Uploading ...
                            </p>
                          ) : (
                            <p style={{ color: "black", fontSize: 13 }}>
                              &#x2705; Uploaded
                            </p>
                          )
                        ) : (
                          <></>
                        )}
                        {imageerror ? (
                          <p style={{ color: "red", fontSize: 11 }}>
                            {imageerror}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {/* </>
                    ) : (
                      <></>
                    )} */}
                    <hr style={{ margin: 0 }} />
                    {globalValidationError ? (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          alignItems: "center",
                        }}
                      >
                        <AlertCircle
                          size={15}
                          strokeWidth={2}
                          stroke="#d8534e"
                        />
                        <div
                          style={{
                            color: "#d8534e",
                            fontSize: 11,
                            fontWeight: "400",
                            marginLeft: 5,
                          }}
                        >
                          There was an error saving the form. Please check the
                          messages above and try again.
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          setsavemodalopen("first");
                          setupload("");
                          setImageerror("");
                          setImagename("");
                          setExistingimage("");
                          mixpanel.track(
                            "Close >>> Edit Expense(Edit Expense Web)"
                          );
                          props.setCreatenewmodal(false);
                          setGlobalValidationError(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                        }}
                        type="submit"
                        disabled={disablesave}
                      >
                        {`Update ${
                          props.type.charAt(0).toUpperCase() +
                          props.type.slice(1)
                        }`}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Editexpensemodal;
