import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, FloatingLabel, InputGroup, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/css/App.css";
import google from "../../assets/img/icons/google.png";
import SignUp from "../../components/auth/SignUp";
// import icon from "../../assets/img/icons/icon2.png";
import icon from "../../assets/img/illustrations/icon.png";
import useAuth from "../../hooks/useAuth";
import GoogleButton from "react-google-button";
import mixpanel from "mixpanel-browser";
import { Eye, EyeOff } from "react-feather";
import { isMobile } from "react-device-detect";
import { usePremium } from "../../contexts/PremiumContext";
import { logError } from "../../utils/useErrorlogging";
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
const SignUpUserPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { signIn, signUp, isAuthenticated, signInWithGoogle, signOut, bdata } =
    useAuth();
  const { setMoreInfo } = usePremium();
  // const [queryEmail, setQueryEmail] = useState("");
  const [data, setData] = useState(null);
  const { id } = useParams();
  console.log(id);
  // const handleGoogleSignIn = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await signInWithGoogle();
  //     navigate("/dashboard");
  //   } catch (error) {
  //     console.log(error.message);
  //     mixpanel.track(`Error Sign Up with Google (Sign Up Web)`, {
  //       error: error.code,
  //     });
  //   }
  // };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const signout = async (e) => {
    // e.preventDefault();
    try {
      await signOut();
      setMoreInfo(null);
      // navigate("/dashboard");
      // getInviteDetails();
    } catch (error) {
      console.log(error.message);
    }
  };
  const getInviteDetails = () => {
    fetch(`https://bestmate.us/team/get-invite-details/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        // if (res.ok) {
        //   await signout();
        // }
        return res.json();
      })
      .then(async (result) => {
        console.log(result);
        await signout();
        setData(result);
        console.log("set");
        localStorage.setItem("InviteData", JSON.stringify(result));
        console.log("set sucess");

        // setMoreInfo(null);
        setLoader(false);
      })
      .catch(async (error) => {
        await signout();
        navigate("/");
        setLoader(false);
        console.log(error);
        // alert("Invalid Invite Id.");
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    console.log("signin out");
    // if (isAuthenticated) {
    // signout();
    // } else {
    getInviteDetails();
    // }

    // if (params.has("email")) {
    //   const paramValue = params.get("email");
    //   console.log(paramValue);

    //   setQueryEmail(paramValue);
    // }
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Join Team" />

      {loader ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            animation="border"
            // variant="dark"
            size="xl"
            className="me-2"
            style={{ color: "#2E6ACF" }}
          />
        </div>
      ) : (
        <>
          <div
            id="authheader"
            style={{
              width: "100%",
              // height: "80px",
              backgroundColor: "white",
              borderBottom: "1px solid #ebebeb",
              // boxShadow: "0px 45px 10px -15px #111",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                maxWidth: "820px",
                // maxWidth: "1050px",
                margin: "auto",
                paddingRight: 20,
                paddingLeft: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 0,
                  marginTop: 4,
                }}
              >
                <img
                  src={icon}
                  alt="BestMate"
                  id="authheaderlogo"
                  style={{ alignSelf: "center" }}
                />
                <strong id="authheadertext" style={{ fontWeight: "bolder" }}>
                  BESTMATE
                </strong>
              </div>
              {/* <img src={icon} alt="BestMate" id="authheaderlogo" /> */}
              <a
                style={{
                  color: "black",
                  fontSize: "16px",
                  margin: 0,
                  fontWeight: "400",
                }}
                href="mailto:support@bestmate.us"
                onClick={() => mixpanel.track(`Help (Sign Up Web)`)}
              >
                Help
              </a>
            </div>
          </div>
          <div
            style={{
              // backgroundColor: "#f3f3f3",
              backgroundColor: "white",
              display: "flex",
              // justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <div
              style={{
                // backgroundColor: "pink",
                paddingRight: 20,
                paddingLeft: 20,
                display: "flex",
                // justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2
                id="signupheader"
                style={{
                  color: "black",
                  margin: 0,
                  textAlign: "center",
                  paddingTop: "5rem",
                  maxWidth: 400,
                  fontSize: 30,
                  marginBottom: 30,
                }}
              >
                Accept Invitation to Join{" "}
                {truncateTextByWordCount(data.business_name, 50)}'s team
              </h2>
              {/* <div
            style={{
              display: "flex",
              marginTop: 10,
              marginBottom: 30,
              textAlign: "center",
            }}
          >
            <strong
              style={{
                color: "black",
                fontSize: "13px",
              }}
            >
              &#10004; 30-day free trial
            </strong>
            <strong
              style={{
                color: "black",
                fontSize: "13px",
                marginLeft: 20,
              }}
            >
              &#10004; No credit card required
            </strong>
          </div> */}
              <div
                style={{
                  width: "100%",
                  maxWidth: 350,
                  // marginTop: 10,
                }}
              >
                {/* <button
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c2",
                    padding: 10,
                    backgroundColor: "transparent",
                    fontWeight: "500",
                    marginTop: 10,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={(e) => {
                    mixpanel.track(`Sign In with Google (Sign Up Web)`);
                    return handleGoogleSignIn(e);
                  }}
                >
                  <img
                    src={google}
                    alt="google"
                    style={{ height: 20, marginRight: 5 }}
                  />
                  Sign up with Google
                </button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                >
                  <hr style={{ width: "45%" }} />
                  <p style={{ margin: 0 }}>OR</p>
                  <hr style={{ width: "45%" }} />
                </div> */}
                <hr style={{ width: "100%" }} />

                <Formik
                  enableReinitialize
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    // email: "demo5@gmail.com",
                    name: data.invited_member_name,
                    email: data.invited_member_email,
                    password: "",
                    submit: false,
                  }}
                  validationSchema={Yup.object().shape({
                    // firstName: Yup.string().max(255).required("First name is required"),
                    // lastName: Yup.string().max(255).required("Last name is required"),
                    name: Yup.string().required("Name is required"),
                    email: Yup.string()

                      .email("Must be a valid email")
                      .required("Email is required"),
                    password: Yup.string()
                      // .min(12, "Must be at least 12 characters")
                      .required("Password is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      localStorage.setItem(
                        "InviteData",
                        JSON.stringify({
                          ...data,
                          invited_member_name: values.name,
                        })
                      );
                      localStorage.setItem("isRequest", true);
                      await signUp(values.email, values.password);
                      navigate("/teams-onboard-block");
                      // if (isMobile) {
                      //   navigate("/user-signup-status/success");
                      // } else {
                      //   navigate("/dashboard");
                      // }
                    } catch (error) {
                      localStorage.setItem("isRequest", false);
                      console.log(error);
                      // mixpanel.track(`Error Sign Up with Email (Sign Up Web)`, {
                      //   error: error.code,
                      // });
                      logError({
                        error: error.code,
                        component: `Error Sign Up with Email (Sign Up Web`,
                      });
                      if (error.code === "auth/email-already-in-use") {
                        // try {
                        //   await signIn(values.email, values.password);
                        //   localStorage.setItem("isRequest", true);
                        //   if (isMobile) {
                        //     navigate("/user-signup-status/success");
                        //   } else {
                        //     navigate("/dashboard");
                        //   }
                        // } catch (e) {
                        //   if (e.code === "auth/user-not-found") {
                        //     setErrors({
                        //       submit:
                        //         "No user found with this email. Please sign up.",
                        //     });
                        //   } else if (e.code === "auth/network-request-failed") {
                        //     setErrors({
                        //       submit:
                        //         "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
                        //     });
                        //   } else if (e.code === "auth/invalid-email") {
                        //     setErrors({
                        //       submit:
                        //         "Invalid email address. Please try again.",
                        //     });
                        //   } else if (e.code === "auth/user-disabled") {
                        //     setErrors({
                        //       submit: "No user found with this email.",
                        //     });
                        //   } else if (e.code === "auth/wrong-password") {
                        //     setErrors({
                        //       submit: "Incorrect password. Please try again.",
                        //     });
                        //   } else if (e.code === "auth/too-many-requests") {
                        //     setErrors({
                        //       submit:
                        //         "Too many unsuccessful login attempts. Please try again later.",
                        //     });
                        //   } else if (e.code === "auth/operation-not-allowed") {
                        //     setErrors({
                        //       submit: "This sign-in method is not allowed.",
                        //     });
                        //   } else {
                        //     setErrors({ submit: "Something went wrong" });
                        //   }
                        // }
                        setErrors({
                          submit: "Email is already taken",
                        });
                      } else if (error.code === "auth/user-not-found") {
                        setErrors({
                          submit:
                            "No user found with this email. Please sign up.",
                        });
                      } else if (error.code === "auth/network-request-failed") {
                        setErrors({
                          submit:
                            "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
                        });
                      } else if (error.code === "auth/invalid-email") {
                        setErrors({
                          submit: "Invalid email address. Please try again.",
                        });
                      } else if (error.code === "auth/user-disabled") {
                        setErrors({
                          submit: "No user found with this email.",
                        });
                      } else if (error.code === "auth/wrong-password") {
                        setErrors({
                          submit: "Incorrect password. Please try again.",
                        });
                      } else if (error.code === "auth/too-many-requests") {
                        setErrors({
                          submit:
                            "Too many unsuccessful login attempts. Please try again later.",
                        });
                      } else if (error.code === "auth/operation-not-allowed") {
                        setErrors({
                          submit: "This sign-in method is not allowed.",
                        });
                      } else if (error.code === "auth/weak-password") {
                        setErrors({
                          submit: "Password should be at least 6 characters.",
                        });
                      } else {
                        setErrors({ submit: "Something went wrong" });
                      }

                      setStatus({ success: false });
                      // setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    validateForm,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={async (e) => {
                        e.preventDefault();
                        const obj = await validateForm();
                        if (Object.keys(obj).length !== 0) {
                          // mixpanel.track(
                          //   `Error Sign Up with Email (Sign Up Web)`,
                          //   {
                          //     error: obj,
                          //   }
                          // );
                          logError({
                            error: obj,
                            component: `Error Sign Up with Email (Sign Up Web)`,
                          });
                        }
                        handleSubmit();
                      }}
                    >
                      {errors.submit && (
                        <Alert className="my-3" variant="danger">
                          <div className="alert-message">{errors.submit}</div>
                        </Alert>
                      )}

                      {/* <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
                      {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-3"
                  > */}
                      <Form.Group style={{ marginBottom: 10 }}>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="name"
                          name="name"
                          placeholder="Full Name"
                          value={values.name}
                          isInvalid={Boolean(touched.name && errors.name)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // disabled
                          style={{
                            borderColor: "black",
                            borderRadius: 5,
                            borderColor: "#c4c4c2",
                            height: 40,
                          }}
                          // defaultValue={queryEmail}
                        />
                        {!!touched.name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email address"
                          value={values.email}
                          isInvalid={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled
                          style={{
                            borderColor: "black",
                            borderRadius: 5,
                            borderColor: "#c4c4c2",
                            height: 40,
                          }}
                          // defaultValue={queryEmail}
                        />
                        {!!touched.email && (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      {/* </FloatingLabel> */}
                      {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Password"
                    className="mb-3"
                  > */}
                      <Form.Group style={{ marginBottom: 10 }}>
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={values.password}
                            isInvalid={Boolean(
                              touched.password && errors.password
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor: "black",
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderColor: "#c4c4c2",
                              height: 40,
                            }}
                            className="password_field"
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: 5,
                              borderBottomRightRadius: 5,
                              cursor: "pointer",
                              width: 50,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <EyeOff
                                size={15}
                                strokeWidth={2}
                                stroke="#485056"
                              />
                            ) : (
                              <Eye size={15} strokeWidth={2} stroke="#485056" />
                            )}
                          </InputGroup.Text>
                          {!!touched.password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>
                      {/* </FloatingLabel> */}
                      {/* <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
                      <div className="text-center mt-3">
                        <Button
                          type="submit"
                          variant="primary"
                          size="lg"
                          disabled={isSubmitting}
                          style={{ width: "100%", borderRadius: 5 }}
                          onClick={() =>
                            mixpanel.track(`Start Free Trial (Sign Up Web)`)
                          }
                        >
                          {/* Get Started */}
                          Join Now
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* <p className="mt-4 d-flex justify-content-center">
              Already have an account ? &nbsp;
              <Link
                to="/sign-in"
                onClick={() => mixpanel.track(`Sign In (Sign Up Web)`)}
              >
                Sign In
              </Link>
            </p> */}

                {/* <GoogleButton
            className="g-btn"
            type="dark"
            onClick={handleGoogleSignIn}
            style={{ width: "100%", height: 50, marginTop: 30 }}
          /> */}

                <p style={{ margin: 0, marginTop: 10, fontSize: 11 }}>
                  By clicking Join Now you agree to Bestmate's{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://bestmate.us/terms-of-services.html",
                        "_blank"
                      );
                    }}
                  >
                    Terms of Service
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open("https://bestmate.us/privacy.html", "_blank");
                    }}
                  >
                    Privacy Policy
                  </span>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default SignUpUserPage;
