import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertCircle, Check, Lock } from "react-feather";
import { usePremium } from "../../contexts/PremiumContext";
import {
  Elements,
  ExpressCheckoutElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import { STRIPE_PRODUCTION } from "../../constants";
import { logError } from "../../utils/useErrorlogging";
const stripePromise = STRIPE_PRODUCTION
  ? loadStripe(
      "pk_live_51KPdvrG7VHS7aQmPiA5WFAK4Ugi966oWQHzFjqNC4AlgD734VWhXMC4qDUoXzn4ek5C1ArfPHrn0KcFXEcqm65Zy00C1oujwzL"
    )
  : loadStripe(
      "pk_test_51OKyHJHcyWy6FW9iuYKms5TD6sNN8i2VAERrrttyqLqkx3bGN1y8FSDWe2a4CGDotBFMkMlJeKlDKfI4YBJFR1Qp00ijJ6sQ2P"
    );
const Subscription = () => {
  const { moreInfo, isPremium, isFree, isTrial, premiumid, useIsFree } =
    usePremium();
  console.log(premiumid);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [showpaymentmodal, setShowpaymentmodal] = useState(false);
  const [showupgrademodal, setShowUpgrademodal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState("month");
  const [fetchSubscription, setFetchSubscriptin] = useState(false);
  const [showerror, setShowError] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  console.log(location.state);
  const navigate = useNavigate();
  const subsctiptionPoints = useIsFree
    ? [
        [
          "Up to 10 Invoices / month",
          "Up to 10 Schedules / month",
          "Up to 10 Bookings / month",
          "Up to 10 Estimates / month",
        ],
        [
          "Unlimited Invoices",
          "Unlimited Schedules & Bookings",
          "Unlimited Estimates",
          "Unlimited Customer Profiles & CRM",
          "Images & Attachments for Bookings, Invoices, Estimates",
          "Display Your Logo On Invoices",
          "Mobile and Web App",
          "Manual Invoice Reminders",
          "Email Support",
          "Accept Online Payments",
        ],
        [
          "Team Members (1-2 members)",
          "Custom Branded Website",
          "Online Booking Portal",
          "Send Texts to Clients for Invoices, Estimates, Bookings (up to 200 / mo)",
          "Expense Management",
          "Online Estimate Acceptance",
          "Automatic Invoice Reminder",
          "Automated Booking Notifications",
          "Automated Estimate Followup",
          "Zapier Integration",
          "Live 1-on-1 Support",
        ],
        [
          "Team Members (3-10 members)",
          "Time Tracking",
          "Send Texts to Clients for Invoices, Estimates, Bookings (up to 2000 / mo)",
          "Inventory Management",
          "Supplier Management",
          "Reports",
          "Profit & Loss ",
          "Taxes & Accounting",
          "Video Training",
          "Dedicated Onboarding",
        ],
        // [
        //   "Reports",
        //   "Client Timeline",
        //   "Share Stocks with Clients",
        //   "Booking Notes and Attachments",
        //   "Online Client Portal",
        // ],
      ]
    : [
        [
          "Unlimited Invoices",
          "Unlimited Schedules & Bookings",
          "Unlimited Estimates",
          "Unlimited Customer Profiles & CRM",
          "Images & Attachments for Bookings, Invoices, Estimates",
          "Display Your Logo On Invoices",
          "Mobile and Web App",
          "Manual Invoice Reminders",
          "Email Support",
          "Accept Online Payments",
        ],
        [
          "Team Members (1-2 members)",
          "Custom Branded Website",
          "Online Booking Portal",
          "Send Texts to Clients for Invoices, Estimates, Bookings (up to 200 / mo)",
          "Expense Management",
          "Online Estimate Acceptance",
          "Automatic Invoice Reminder",
          "Automated Booking Notifications",
          "Automated Estimate Followup",
          "Zapier Integration",
          "Live 1-on-1 Support",
        ],
        [
          "Team Members (3-10 members)",
          "Time Tracking",
          "Send Texts to Clients for Invoices, Estimates, Bookings (up to 2000 / mo)",
          "Inventory Management",
          "Supplier Management",
          "Reports",
          "Profit & Loss ",
          "Taxes & Accounting",
          "Video Training",
          "Dedicated Onboarding",
        ],
      ];

  const getProducts = async () => {
    try {
      var user = firebase.auth().currentUser;
      let token = await user.getIdToken();
      const response2 = await fetch(
        `https://bestmate.us/api/business/web-subscription/get-stripe-customer?${
          STRIPE_PRODUCTION ? "" : "stripe_test=true"
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const response = await fetch(
        // `http://localhost:4242/get-prices`,
        `https://bestmate.us/api/business/web-subscription/get-prices?${
          STRIPE_PRODUCTION ? "" : "stripe_test=true"
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status >= 200 && response.status <= 300) {
        const res = await response.json();
        if (res && res.products && res.products.length !== 0) {
          setProducts(
            useIsFree
              ? [
                  {
                    id: "free",
                    name: "Free",
                    description: "Free Plan Description",
                    prices: [
                      {
                        id: "free",
                        unit_amount_decimal: 0,
                        offered_unit_amount_decimal: null,
                        interval: "month",
                        currency: "usd",
                      },
                      {
                        id: "free",
                        unit_amount_decimal: 0,
                        offered_unit_amount_decimal: null,
                        interval: "year",
                        currency: "usd",
                      },
                    ],
                  },
                  ...res.products.filter((elem) =>
                    elem.name.includes("Starter")
                  ),
                  ...res.products.filter((elem) => elem.name.includes("Basic")),
                  ...res.products.filter((elem) => elem.name.includes("Pro")),
                ]
              : [
                  ...res.products.filter((elem) =>
                    elem.name.includes("Starter")
                  ),
                  ...res.products.filter((elem) => elem.name.includes("Basic")),
                  ...res.products.filter((elem) => elem.name.includes("Pro")),
                ]
          );

          setLoading(false);
        }
      } else {
        // mixpanel.track(`Error Fetcching Products (Subscription Web)`, {
        //   error: "Error Fetching Products",
        // });
        logError({
          error: "Error Fetching Products",
          component: "Subscription Get Fetching Products Web",
        });
      }
    } catch (err) {
      console.log(err);
      // mixpanel.track(`Error Getting Products (Subscription Web)`, {
      //   error: err.message,
      //   stack: err.stack,
      // });
      logError({
        error: err.message,
        stack: err.stack,
        component: "Subscription Getting Products Web",
      });
    }
  };

  useEffect(() => {
    mixpanel.track(`On Subscription (Web)`);
    getProducts();
  }, []);

  const getCardPricingUI = (elem) => {
    if (elem.offered_unit_amount_decimal) {
      return (
        <>
          <div style={{ fontSize: 14 }}>
            {getSymbolFromCurrency(elem.currency)}
            <span style={{ fontSize: 37, fontWeight: "bold" }}>
              {elem.offered_unit_amount_decimal}
            </span>
            / mo
          </div>
          <div style={{ fontSize: 10, textAlign: "center" }}>
            for first 2 months
          </div>
          <div>
            <span>then</span>
            <s style={{ marginLeft: 9, fontSize: 17 }}>
              {getSymbolFromCurrency(elem.currency)}
              {elem.unit_amount_decimal}/mo
            </s>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ fontSize: 17 }}>
            {getSymbolFromCurrency(elem.currency)}
            <span style={{ fontSize: 47, fontWeight: "bold" }}>
              {elem.unit_amount_decimal}
            </span>
            / mo
          </div>
        </>
      );
    }
  };
  const getCardPricingUIModal = (elem) => {
    if (elem.offered_unit_amount_decimal) {
      return (
        <>
          <div
            style={{
              fontSize: 16,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {`${getSymbolFromCurrency(elem.currency)}${Math.ceil(
              elem.offered_unit_amount_decimal
            )} / month  `}
            <span style={{ fontSize: 10 }}>for first 2 months</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              fontSize: 16,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {`${getSymbolFromCurrency(elem.currency)}${Math.ceil(
              elem.unit_amount_decimal
            )} / month`}
          </div>
        </>
      );
    }
  };
  const createSubscription = async () => {
    try {
      var user = firebase.auth().currentUser;
      let tokens = await user.getIdToken();
      const response = await fetch(
        `https://bestmate.us/api/business/web-subscription/create-subscription/`,
        // `http://localhost:4242/create-subscription-v2/`,
        // create-subscription-v2
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokens,
          },
          body: JSON.stringify({
            priceId: selectedPlanId,
            stripe_test: !STRIPE_PRODUCTION,
          }),
          signal,
        }
      );
      if (response.status >= 200 && response.status <= 300) {
        // console.log(response);
        const res = await response.json();
        setSubscription(res);
        setFetchSubscriptin(false);
      } else {
        const res = await response.json();
        if (res && res.error && res.error === "CLIENT_ID_NOT_FOUND") {
          window.location.reload();
        } else {
          // mixpanel.track(`Error Creating Subscriptoin (Subscription Web)`, {
          //   error: "Fetch error",
          // });
          logError({
            error: "Fetch error",
            component: "Creating Subscriptoin Web",
          });
          setShowError(true);
        }
        setFetchSubscriptin(false);
      }
    } catch (err) {
      setShowError(true);
      console.log(err);
      // mixpanel.track(`Error Creating Subscriptoin (Subscription Web)`, {
      //   error: err.message,
      //   stack: err.stack,
      // });
      logError({
        error: err.message,
        stack: err.stack,
        component: "Subscription Web",
      });
    }
  };
  useEffect(() => {
    if (selectedPlanId) {
      // setSubscription(null);
      setShowError(false);
      console.log(moreInfo, moreInfo.isPremiumStripeUser);
      if (moreInfo && moreInfo.isPremiumStripeUser) {
        setShowUpgrademodal(true);
        setFetchSubscriptin(true);
        createSubscription();
      } else {
        setShowpaymentmodal(true);
        setFetchSubscriptin(true);
        createSubscription();
      }
    }
  }, [selectedPlanId]);
  return (
    <React.Fragment>
      <Helmet title="Subscription" />
      <Container fluid className="p-0">
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Subscription
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item active>Invoice</Breadcrumb.Item> */}
              <Breadcrumb.Item active className="font-weight-bold">
                Subscription
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div></div>
        </Card>
        {!loading ? (
          <>
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: 1110,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
                  >
                    Select Subscription Plan
                  </div>
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      borderRadius: 20,
                    }}
                    className="border"
                  >
                    <div
                      style={
                        defaultSelected === "month"
                          ? {
                              padding: 7,
                              paddingRight: 20,
                              paddingLeft: 20,
                              borderRadius: 20,
                              backgroundColor: "black",
                              cursor: "pointer",
                              color: "white",
                            }
                          : {
                              padding: 7,
                              paddingRight: 20,
                              paddingLeft: 20,
                              borderRadius: 20,
                              cursor: "pointer",
                            }
                      }
                      onClick={() => {
                        mixpanel.track(`Monthly Tab (Subscription Web)`);
                        setDefaultSelected("month");
                      }}
                    >
                      Monthly
                    </div>
                    <div
                      style={
                        defaultSelected === "year"
                          ? {
                              padding: 7,
                              paddingRight: 20,
                              paddingLeft: 20,
                              borderRadius: 20,
                              backgroundColor: "black",
                              cursor: "pointer",
                              color: "white",
                            }
                          : {
                              padding: 7,
                              paddingRight: 20,
                              paddingLeft: 20,
                              borderRadius: 20,
                              cursor: "pointer",
                            }
                      }
                      onClick={() => {
                        mixpanel.track(`Yearly Tab (Subscription Web)`);
                        setDefaultSelected("year");
                      }}
                    >
                      Yearly
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  //   backgroundColor: "red",
                  display: "flex",
                  justifyContent: "center",
                  // height: 550,
                }}
              >
                <>
                  {products.map((elem, index) => {
                    console.log(products);
                    let id = `${elem.name} ${defaultSelected}ly`;
                    let matchPlan = premiumid
                      .toLowerCase()
                      .includes(id.toLowerCase());
                    console.log(matchPlan, id, premiumid);
                    // console.log(premiumid.toLowerCase().includes(id));
                    // console.log(
                    //   premiumid.toLowerCase(),
                    //   id.toLowerCase(),
                    //   premiumid.toLowerCase().includes(id.toLowerCase())
                    // );
                    return (
                      <>
                        {defaultSelected === "month" ? (
                          <div
                            className="col-md-1 col-sm-12 pt-2 pb-2 border"
                            style={{
                              width: 270,
                              backgroundColor: "white",
                              margin: 5,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: 22, fontWeight: "bold" }}>
                              {elem.name}
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: 11,
                                color: "grey",
                                margin: 0,
                              }}
                            >
                              {/* Lorem ipsum dolor sit amet, consectetur adipiscing eli */}
                              {elem.description}
                            </p>
                            {
                              getCardPricingUI(
                                elem.prices.filter(
                                  (elem) => elem.interval === "month"
                                )[0]
                              )
                              //   <div style={{ fontSize: 17 }}>
                              //   {getSymbolFromCurrency(
                              // elem.prices.filter(
                              //   (elem) => elem.interval === "month"
                              // )[0].currency
                              //   )}
                              //   <span
                              //     style={{ fontSize: 47, fontWeight: "bold" }}
                              //   >
                              //     {
                              //       elem.prices.filter(
                              //         (elem) => elem.interval === "month"
                              //       )[0].offered_unit_amount_decimal
                              //     }
                              //   </span>
                              //   / mo
                              // </div>
                            }
                            <div
                              style={{
                                width: "100%",
                                marginTop: 20,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  textDecoration: "underline",
                                  textDecorationColor: "#D3D3D3",
                                  margin: 0,
                                  color: "black",
                                  marginLeft: 22,
                                }}
                              >
                                {elem.name === "Free" && useIsFree
                                  ? "Free includes :"
                                  : index === 0
                                  ? "Starter includes :"
                                  : `Everything in ${
                                      products[index - 1].name
                                    } plus :`}
                              </p>
                              <div style={{ marginTop: 10 }}>
                                {subsctiptionPoints[index].map((elem) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 2,
                                        //   borderBottom: "solid 1 black",
                                      }}
                                    >
                                      <Check
                                        size={16}
                                        strokeWidth={5}
                                        stroke="green"
                                        style={{ marginRight: 10 }}
                                      />
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 13,
                                          flex: 1,
                                        }}
                                      >
                                        {elem}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {elem.name === "Free" ? (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "auto",
                                  marginBottom: 10,
                                }}
                              >
                                {!isFree ? (
                                  <button
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      padding: 10,
                                      backgroundColor: "#e1ad01",
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: 13,
                                    }}
                                  >
                                    Exceeded
                                  </button>
                                ) : (
                                  <>
                                    {!isPremium ? (
                                      <>
                                        <button
                                          style={{
                                            border: "none",
                                            width: "100%",
                                            padding: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 13,
                                          }}
                                        >
                                          Active
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "auto",
                                  marginBottom: 10,
                                }}
                              >
                                <button
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    padding: 10,
                                    backgroundColor: matchPlan
                                      ? "green"
                                      : "white",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "green",
                                    color: matchPlan ? "white" : "green",
                                    fontWeight: "bold",
                                    fontSize: 13,
                                  }}
                                  onClick={
                                    moreInfo &&
                                    moreInfo.isPremiumStripeUser &&
                                    moreInfo.stripeSubscription ===
                                      elem.prices.filter(
                                        (elem) => elem.interval === "month"
                                      )[0].id
                                      ? () => {
                                          mixpanel.track(
                                            `Select Plan Monthly (Subscription Web)`,
                                            { product: elem }
                                          );
                                          console.log(
                                            moreInfo,
                                            moreInfo.isPremiumStripeUser,
                                            moreInfo.stripeSubscription,
                                            elem.prices.filter(
                                              (elem) =>
                                                elem.interval === "month"
                                            )[0].id,
                                            moreInfo.stripeSubscription ===
                                              elem.prices.filter(
                                                (elem) =>
                                                  elem.interval === "month"
                                              )[0].id
                                          );
                                          console.log("whongone");
                                        }
                                      : () => {
                                          mixpanel.track(
                                            `Select Plan Monthly (Subscription Web)`,
                                            { product: elem }
                                          );
                                          setSelectedProduct(elem);
                                          setSelectedPlanId(
                                            elem.prices.filter(
                                              (elem) =>
                                                elem.interval === "month"
                                            )[0].id
                                          );
                                          // setShowpaymentmodal(true);
                                        }
                                  }
                                >
                                  {
                                    // moreInfo &&
                                    // moreInfo.isPremiumStripeUser &&
                                    // moreInfo.stripeSubscription ===
                                    //   elem.prices.filter(
                                    //     (elem) => elem.interval === "month"
                                    //   )[0].id
                                    matchPlan ? "Active" : "Select Plan"
                                  }
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="col-md-1 col-sm-12 pt-2 pb-2 border"
                            style={{
                              width: 270,
                              backgroundColor: "white",
                              margin: 5,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: 22, fontWeight: "bold" }}>
                              {elem.name}
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: 11,
                                color: "grey",
                                margin: 0,
                              }}
                            >
                              {/* Lorem ipsum dolor sit amet, consectetur adipiscing eli */}
                              {elem.description}
                            </p>
                            <div style={{ fontSize: 17 }}>
                              {getSymbolFromCurrency(
                                elem.prices.filter(
                                  (elem) => elem.interval === "year"
                                )[0].currency
                              )}
                              <span
                                style={{ fontSize: 47, fontWeight: "bold" }}
                              >
                                {Math.ceil(
                                  elem.prices.filter(
                                    (elem) => elem.interval === "year"
                                  )[0].unit_amount_decimal / 12
                                )}
                              </span>
                              / mo
                            </div>
                            <div style={{ position: "relative", top: -10 }}>
                              Billed Annually
                            </div>
                            <div
                              style={{
                                width: "100%",
                                marginTop: 20,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  textDecoration: "underline",
                                  textDecorationColor: "#D3D3D3",
                                  margin: 0,
                                  color: "black",
                                  marginLeft: 22,
                                }}
                              >
                                {elem.name === "Free" && useIsFree
                                  ? "Free includes :"
                                  : index === 0
                                  ? "Starter includes :"
                                  : `Everything in ${
                                      products[index - 1].name
                                    } plus :`}
                              </p>
                              <div style={{ marginTop: 10 }}>
                                {subsctiptionPoints[index].map((elem) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 2,
                                        //   borderBottom: "solid 1 black",
                                      }}
                                    >
                                      <Check
                                        size={16}
                                        strokeWidth={5}
                                        stroke="green"
                                        style={{ marginRight: 10 }}
                                      />
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 13,
                                          flex: 1,
                                        }}
                                      >
                                        {elem}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {elem.name === "Free" ? (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "auto",
                                  marginBottom: 10,
                                }}
                              >
                                {!isFree ? (
                                  <button
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      padding: 10,
                                      backgroundColor: "#e1ad01",
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: 13,
                                    }}
                                  >
                                    Exceeded
                                  </button>
                                ) : (
                                  <>
                                    {!isPremium ? (
                                      <>
                                        <button
                                          style={{
                                            border: "none",
                                            width: "100%",
                                            padding: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 13,
                                          }}
                                        >
                                          Active
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "auto",
                                  marginBottom: 10,
                                }}
                              >
                                <button
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    padding: 10,
                                    // backgroundColor: "white",
                                    backgroundColor: matchPlan
                                      ? "green"
                                      : "white",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "green",
                                    // color: "green",
                                    color: matchPlan ? "white" : "green",
                                    fontWeight: "bold",
                                    fontSize: 13,
                                  }}
                                  onClick={
                                    moreInfo &&
                                    moreInfo.isPremiumStripeUser &&
                                    moreInfo.stripeSubscription ===
                                      elem.prices.filter(
                                        (elem) => elem.interval === "year"
                                      )[0].id
                                      ? () => {}
                                      : () => {
                                          mixpanel.track(
                                            `Select Plan Yearly (Subscription Web)`,
                                            { product: elem }
                                          );
                                          setSelectedProduct(elem);
                                          setSelectedPlanId(
                                            elem.prices.filter(
                                              (elem) =>
                                                elem.interval ===
                                                defaultSelected
                                            )[0].id
                                          );
                                          // setShowpaymentmodal(true);
                                        }
                                  }
                                >
                                  {matchPlan ? "Active" : "Select Plan"}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
                {/* <div
                  className="col-md-1 col-sm-12 pt-2 pb-2 border"
                  style={{
                    // flex: 1,
                    // height: 500,
                    width: 270,
                    // maxWidth: "100%",
                    backgroundColor: "white",
                    margin: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 22, fontWeight: "bold" }}>Free</div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      color: "grey",
                      margin: 0,
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli
                  </p>
                  <div style={{ fontSize: 17 }}>
                    $<span style={{ fontSize: 47, fontWeight: "bold" }}>0</span>
                    / mo
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "#D3D3D3",
                        margin: 0,
                        color: "black",
                        marginLeft: 22,
                      }}
                    >
                      Free includes :
                    </p>
                    <div style={{ marginTop: 10 }}>
                      {subsctiptionPoints[0].map((elem) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingBottom: 2,
                              //   borderBottom: "solid 1 black",
                            }}
                          >
                            <Check
                              size={16}
                              strokeWidth={5}
                              stroke="green"
                              style={{ marginRight: 10 }}
                            />
                            <p
                              style={{
                                margin: 0,
                                fontSize: 13,
                                flex: 1,
                              }}
                            >
                              {elem}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: 10,
                    }}
                  >
                    {moreInfo && !moreInfo.isFreeUser ? (
                      <button
                        style={{
                          border: "none",
                          width: "100%",
                          padding: 10,
                          backgroundColor: "#e1ad01",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 13,
                        }}
                      >
                        Exceeded
                      </button>
                    ) : (
                      <button
                        style={{
                          border: "none",
                          width: "100%",
                          padding: 10,
                          backgroundColor: "green",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 13,
                        }}
                      >
                        Active
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className="col-md-1 col-sm-12 pt-2 pb-2 border"
                  style={{
                    // flex: 1,
                    // height: 500,
                    width: 270,
                    // maxWidth: "100%",
                    backgroundColor: "white",
                    margin: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 22, fontWeight: "bold" }}>
                    Starter
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      color: "grey",
                      margin: 0,
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli
                  </p>
                  <div style={{ fontSize: 17 }}>
                    $<span style={{ fontSize: 47, fontWeight: "bold" }}>9</span>
                    / mo
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "#D3D3D3",
                        margin: 0,
                        color: "black",
                        marginLeft: 22,
                      }}
                    >
                      Everything in Free plus :
                    </p>
                    <div style={{ marginTop: 10 }}>
                      {subsctiptionPoints[1].map((elem) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingBottom: 2,
                              //   borderBottom: "solid 1 black",
                            }}
                          >
                            <Check
                              size={16}
                              strokeWidth={5}
                              stroke="green"
                              style={{ marginRight: 10 }}
                            />
                            <p
                              style={{
                                margin: 0,
                                fontSize: 13,
                                flex: 1,
                              }}
                            >
                              {elem}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: 10,
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        width: "100%",
                        padding: 10,
                        backgroundColor: "white",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "green",
                        color: "green",
                        fontWeight: "bold",
                        fontSize: 13,
                      }}
                    >
                      Select Plan
                    </button>
                  </div>
                </div>
                <div
                  className="col-md-1 col-sm-12 pt-2 pb-2 border"
                  style={{
                    // flex: 1,
                    // height: 500,
                    width: 270,
                    // maxWidth: "100%",
                    backgroundColor: "white",
                    margin: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 22, fontWeight: "bold" }}>Basic</div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      color: "grey",
                      margin: 0,
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli
                  </p>
                  <div style={{ fontSize: 17 }}>
                    $
                    <span style={{ fontSize: 47, fontWeight: "bold" }}>39</span>
                    / mo
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "#D3D3D3",
                        margin: 0,
                        color: "black",
                        marginLeft: 22,
                      }}
                    >
                      Everything in Starter plus :
                    </p>
                    <div style={{ marginTop: 10 }}>
                      {subsctiptionPoints[2].map((elem) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingBottom: 2,
                              //   borderBottom: "solid 1 black",
                            }}
                          >
                            <Check
                              size={16}
                              strokeWidth={5}
                              stroke="green"
                              style={{ marginRight: 10 }}
                            />
                            <p
                              style={{
                                margin: 0,
                                fontSize: 13,
                                flex: 1,
                              }}
                            >
                              {elem}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: 10,
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        width: "100%",
                        padding: 10,
                        backgroundColor: "white",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "green",
                        color: "green",
                        fontWeight: "bold",
                        fontSize: 13,
                      }}
                    >
                      Select Plan
                    </button>
                  </div>
                </div>
                <div
                  className="col-md-1 col-sm-12 pt-2 pb-2 border"
                  style={{
                    // flex: 1,
                    // height: 500,
                    width: 270,
                    // maxWidth: "100%",
                    backgroundColor: "white",
                    margin: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 22, fontWeight: "bold" }}>Pro</div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      color: "grey",
                      margin: 0,
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli
                  </p>
                  <div style={{ fontSize: 17 }}>
                    $
                    <span style={{ fontSize: 47, fontWeight: "bold" }}>79</span>
                    / mo
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "#D3D3D3",
                        margin: 0,
                        color: "black",
                        marginLeft: 22,
                      }}
                    >
                      Everthing in Basic plus :
                    </p>
                    <div style={{ marginTop: 10 }}>
                      {subsctiptionPoints[3].map((elem) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingBottom: 2,
                              //   borderBottom: "solid 1 black",
                            }}
                          >
                            <Check
                              size={16}
                              strokeWidth={5}
                              stroke="green"
                              style={{ marginRight: 10 }}
                            />
                            <p
                              style={{
                                margin: 0,
                                fontSize: 13,
                                flex: 1,
                              }}
                            >
                              {elem}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: 10,
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        width: "100%",
                        padding: 10,
                        backgroundColor: "white",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "green",
                        color: "green",
                        fontWeight: "bold",
                        fontSize: 13,
                      }}
                    >
                      Select Plan
                    </button>
                  </div>
                </div> */}
                {/* <div
              className="col-md-1 col-sm-12 pt-2 pb-2 border"
              style={{
                // flex: 1,
                // height: 500,
                width: 250,
                // maxWidth: "100%",
                backgroundColor: "white",
                margin: 10,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 22, fontWeight: "bold" }}>Premium</div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  color: "grey",
                  margin: 0,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing eli
              </p>
              <div style={{ fontSize: 17 }}>
                $<span style={{ fontSize: 47, fontWeight: "bold" }}>159</span>/
                mo
              </div>
              <div
                style={{
                  width: "100%",
                  marginBottom: 10,
                  marginTop: 20,
                }}
              >
                <p
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "#D3D3D3",
                    margin: 0,
                    color: "black",
                    marginLeft: 22,
                  }}
                >
                  Everting in Pro plus :
                </p>
                <div style={{ marginTop: 10 }}>
                  {subsctiptionPoints[2].map((elem) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingBottom: 2,
                          //   borderBottom: "solid 1 black",
                        }}
                      >
                        <Check
                          size={16}
                          strokeWidth={5}
                          stroke="green"
                          style={{ marginRight: 10 }}
                        />
                        <p
                          style={{
                            margin: 0,
                            fontSize: 13,
                            flex: 1,
                          }}
                        >
                          {elem}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "auto",
                  marginBottom: 10,
                }}
              >
                <button
                  style={{
                    border: "none",
                    width: "100%",
                    padding: 10,
                    backgroundColor: "white",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "green",
                    color: "green",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  Select Plan
                </button>
              </div>
            </div> */}
              </div>
            </div>
            <Modal
              show={showpaymentmodal}
              onHide={() => {
                mixpanel.track(`Close Modal (Subscription Web)`);
                setShowpaymentmodal(false);
                controller.abort();
                setSelectedProduct(null);
                setSubscription(null);
                setSelectedPlanId("");
              }}
              centered
              size="xl"
              style={{ overflow: "auto" }}
            >
              {selectedProduct !== null && subscription !== null ? (
                <>
                  {!showerror ? (
                    <Row>
                      <Col
                        md={6}
                        style={{
                          padding: 45,
                          paddingRight: 0,
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 25,
                            fontWeight: "bold",
                            marginBottom: 20,
                          }}
                        >
                          Card Details
                        </div>
                        <Elements
                          stripe={stripePromise}
                          options={{
                            clientSecret: subscription.clientSecret,
                          }}
                        >
                          <PayForm
                            subscription={subscription}
                            fetchSubscription={fetchSubscription}
                          />
                        </Elements>
                      </Col>
                      <Col md={6} style={{}}>
                        <div
                          style={{
                            borderLeftWidth: 0,
                            borderTop: 0,
                            borderBottom: 0,
                            borderRight: 0,
                            borderStyle: "solid",
                            marginLeft: 20,
                            height: "100%",
                            backgroundColor: "#f2f5fa",
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            padding: 45,
                            paddingLeft: 0,
                          }}
                        >
                          <div style={{ paddingLeft: 50 }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <p
                                style={{
                                  fontSize: 23,
                                  color: "black",
                                  fontWeight: "bold",
                                  marginBottom: 0,
                                }}
                              >
                                {selectedProduct.name} Plan
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontWeight: "bold",
                                  marginBottom: 0,
                                  color: "#407FEA",
                                  cursor: "pointer",
                                  marginLeft: 7,
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Change Plan (Subscription Web)`
                                  );
                                  setShowpaymentmodal(false);
                                  controller.abort();
                                  setSelectedProduct(null);
                                  setSubscription(null);
                                  setSelectedPlanId("");
                                }}
                              >
                                (change)
                              </p>
                            </div>
                            <div>
                              {selectedProduct.prices.map((item) => (
                                <div
                                  style={{
                                    border:
                                      selectedPlanId === item.id
                                        ? "2px solid #407FEA"
                                        : "1px solid #c9c9c9",
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    padding: 20,
                                    marginTop: 20,
                                    borderRadius: 10,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    cursor:
                                      selectedPlanId === item.id
                                        ? "default"
                                        : "pointer",
                                  }}
                                  onClick={
                                    moreInfo &&
                                    moreInfo.isPremiumStripeUser &&
                                    moreInfo.stripeSubscription === item.id
                                      ? () => {}
                                      : () => {
                                          mixpanel.track(
                                            `Change Plan (Subscription Web)`,
                                            { plan: item.id }
                                          );
                                          if (selectedPlanId !== item.id) {
                                            setSelectedPlanId(item.id);
                                            controller.abort();
                                          }
                                        }
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      checked={selectedPlanId === item.id}
                                      size={500}
                                    />
                                    <div style={{ marginLeft: 20 }}>
                                      {/* {`${getSymbolFromCurrency(
                                          item.currency
                                        )}${
                                          item.interval === "month"
                                            ? item.unit_amount_decimal
                                            : Math.ceil(
                                                item.unit_amount_decimal / 12
                                              )
                                        } / month`} */}
                                      {item.interval === "month" ? (
                                        getCardPricingUIModal(item)
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: 16,
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${getSymbolFromCurrency(
                                            item.currency
                                          )}${Math.ceil(
                                            item.unit_amount_decimal / 12
                                          )} / month`}
                                        </div>
                                      )}

                                      <div style={{ fontSize: 13.5 }}>
                                        {item.interval === "month"
                                          ? "Billed Monthly"
                                          : `Billed Yearly (${getSymbolFromCurrency(
                                              item.currency
                                            )}${item.unit_amount_decimal})`}
                                      </div>
                                    </div>
                                  </div>
                                  {moreInfo &&
                                  moreInfo.isPremiumStripeUser &&
                                  moreInfo.stripeSubscription === item.id ? (
                                    <div
                                      style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        padding: 10,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        borderRadius: 5,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        maxWidth: 90,
                                        textAlign: "center",
                                        fontSize: 11,
                                      }}
                                    >
                                      Active
                                    </div>
                                  ) : (
                                    <>
                                      {item.interval === "year" ? (
                                        <div
                                          style={{
                                            backgroundColor: "#407FEA",
                                            color: "white",
                                            padding: 10,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            borderRadius: 5,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          Best Value
                                          <div style={{ fontSize: 11 }}>
                                            {`Save ${getSymbolFromCurrency(
                                              item.currency
                                            )}${
                                              selectedProduct.prices.filter(
                                                (elem) =>
                                                  elem.interval === "month"
                                              )[0].unit_amount_decimal *
                                                12 -
                                              item.unit_amount_decimal
                                            }`}{" "}
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                marginTop: 40,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderTop: "1px solid #d6d2d2",
                                paddingTop: 10,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 17,
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                Amount Due
                              </div>
                              <div
                                style={{
                                  fontSize: 22,
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                {getSymbolFromCurrency(subscription.currency)}
                                <span style={{ marginLeft: 2 }}>
                                  {subscription.amountDue}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div
                      style={{
                        color: "black",
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      Something Went Wrong
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "500px" }}
                >
                  <Spinner animation="border" variant="dark" className="me-2" />
                </div>
              )}
            </Modal>
            <Modal
              show={showupgrademodal}
              onHide={() => {
                mixpanel.track(`Close Modal (Subscription Web)`);
                setShowUpgrademodal(false);
                controller.abort();
                setSelectedProduct(null);
                setSubscription(null);
                setSelectedPlanId("");
              }}
              centered
              size="sm"
              style={{ overflow: "auto" }}
            >
              {selectedProduct !== null && subscription !== null ? (
                <div
                  style={{
                    borderLeftWidth: 0,
                    borderTop: 0,
                    borderBottom: 0,
                    borderRight: 0,
                    borderStyle: "solid",
                    height: "100%",
                    backgroundColor: "#f2f5fa",
                    borderRadius: 5,
                    padding: 10,
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ padding: 20 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{
                          fontSize: 18,
                          color: "black",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        Change Subscription to {selectedProduct.name} Plan
                      </p>
                      {/* <p
                        style={{
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                          marginBottom: 0,
                          color: "#407FEA",
                          cursor: "pointer",
                          marginLeft: 7,
                        }}
                        onClick={() => {
                          setShowUpgrademodal(false);
                          controller.abort();
                          setSelectedProduct(null);
                          setSubscription(null);
                          setSelectedPlanId("");
                        }}
                      >
                        (change)
                      </p> */}
                    </div>
                    <div>
                      {subscription.invoiceDate
                        ? "Subscription will change instantly and amount will be adjusted with next invoice"
                        : "Subscription will change instantly and amount will be adjusted and charged"}
                    </div>
                    {/* <div>
                      Next invoice will be generated on{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {moment(subscription.invoiceDate).format(
                          "DD MMM YYYY hh:mm a"
                        )}
                      </span>
                    </div> */}
                    <div
                      style={{
                        marginTop: 40,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderTop: "1px solid #d6d2d2",
                        paddingTop: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {/* Amount Due (Next Invoice) */}
                        {subscription.invoiceDate
                          ? "Amount Due (Next Invoice)"
                          : "Amount Due"}
                      </div>
                      <div
                        style={{
                          fontSize: 22,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {getSymbolFromCurrency(subscription.currency)}
                        <span style={{ marginLeft: 2 }}>
                          {subscription.amountDue}
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                      <button
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: 10,
                          border: "2px solid green",
                          backgroundColor: "transparent",
                          color: "green",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          mixpanel.track(`Cancel Upgrade (Subscription Web)`);
                          setShowUpgrademodal(false);
                          controller.abort();
                          setSelectedProduct(null);
                          setSubscription(null);
                          setSelectedPlanId("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: 10,
                          border: "2px solid green",
                          backgroundColor: "green",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          try {
                            mixpanel.track(
                              `Upgrade Subscription (Subscription Web)`,
                              { plan: selectedPlanId }
                            );

                            var user = firebase.auth().currentUser;
                            let tokenss = await user.getIdToken();
                            const response = await fetch(
                              `https://bestmate.us/api/business/web-subscription/change-subscription`,
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization: "Bearer " + tokenss,
                                },
                                body: JSON.stringify({
                                  priceId: selectedPlanId,
                                  stripe_test: !STRIPE_PRODUCTION,
                                }),
                                signal,
                              }
                            );
                            if (
                              response.status >= 200 &&
                              response.status <= 300
                            ) {
                              // console.log(response.status);
                              // let put = await response.json();
                              // console.log(put);
                              window.location.reload();
                            } else {
                              // mixpanel.track(
                              //   `Error Upgrade Subscription (Subscription Web)`,
                              //   {
                              //     error: "Fetch fail error",
                              //   }
                              // );
                              logError({
                                error: "Fetch fail error",
                                component: "Error Upgrade Subscription Web",
                              });
                            }
                          } catch (err) {
                            // mixpanel.track(
                            //   `Error Upgrade Subscription (Subscription Web)`,
                            //   {
                            //     error: err,
                            //   }
                            // );
                            logError({
                              error: err,
                              component: "Error Upgrade Subscription Web",
                            });
                          }
                        }}
                      >
                        Upgrade
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "500px" }}
                >
                  <Spinner animation="border" variant="dark" className="me-2" />
                </div>
              )}
            </Modal>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "450px" }}
          >
            <Spinner animation="border" variant="dark" className="me-2" />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

const PayForm = ({ subscription, fetchSubscription }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  console.log(
    // !stripe,
    subscription,
    Object.keys(subscription).length !== 0,
    fetchSubscription,
    // !stripe &&
    subscription && Object.keys(subscription).length !== 0 && fetchSubscription
  );
  return (
    <form>
      <PaymentElement />
      {error ? (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <AlertCircle size={17} strokeWidth={3} stroke="red" />
          <div style={{ color: "red", fontSize: 14, marginLeft: 6 }}>
            {error}
          </div>
        </div>
      ) : (
        <></>
      )}
      <button
        style={{
          marginTop: error ? 15 : 30,
          border: "none",
          backgroundColor:
            !stripe === false &&
            subscription &&
            Object.keys(subscription).length !== 0 &&
            fetchSubscription
              ? "#a5c4fa"
              : "#407FEA",
          color: "white",
          paddingTop: 10,
          paddingBottom: 10,
          paddingRight: 20,
          paddingLeft: 20,
          fontSize: 15,
          borderRadius: 5,
          width: "100%",
        }}
        type="button"
        onClick={async (event) => {
          mixpanel.track(`Buy Subscription (Subscription Web)`);

          event.preventDefault();
          setError("");
          if (!stripe || !elements) {
            return;
          }

          const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `${window.location.href}`,
            },
          });

          if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setError(result.error.message);
            // mixpanel.track(`Error Buying (Subscription Web)`, {
            //   error: result.error,
            // });
            logError({
              error: result.error,
              component: "Subscription Web",
            });
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        }}
        // disabled={
        // subscription &&
        // Object.keys(subscription).length !== 0
        // }
        disabled={
          !stripe === false &&
          subscription &&
          Object.keys(subscription).length !== 0 &&
          fetchSubscription
        }
      >
        {!stripe === false &&
        subscription &&
        Object.keys(subscription).length !== 0 &&
        fetchSubscription ? (
          <>
            <Spinner
              animation="border"
              variant="light"
              className="me-2"
              size="sm"
            />
          </>
        ) : (
          "Buy"
        )}
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Lock size={12} strokeWidth={2} stroke="grey" />
        <p
          style={{
            fontSize: 12,
            margin: 0,
            marginLeft: 5,
            color: "grey",
          }}
        >
          Guaranteed safe & secure payments
        </p>
      </div>
    </form>
  );
};
export default Subscription;
