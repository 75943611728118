import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown } from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { MoreHorizontal } from "react-feather";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Newschedulemodal from "../../../components/Newschedulemodal";
// import DatePicker from "react-horizontal-datepicker";
// import DatePicker from "react-horizontal-strip-datepicker";
// import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import {
  Datepicker,
  DatepickerEvent,
} from "@meinefinsternis/react-horizontal-date-picker";
import Editschedulemodal from "../../../components/Editschedulemodal";
import mixpanel from "mixpanel-browser";
import useAuth from "../../../hooks/useAuth";
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
const Appointments = () => {
  const [data, setData] = useState([]);
  const [token, settoken] = useState("");
  const [createnewmodal, setCreatenewmodal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editmmodal, setEditmodal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [date, setDate] = useState({
    startValue: null,
    endValue: null,
    rangeDates: [],
  });
  const [teamMemberList, setTeamMemberList] = useState([]);
  const { isTeamAccount, userPermission } = useAuth();
  let schedulePermission =
    Object.keys(userPermission).includes("schedule") &&
    Object.keys(userPermission.schedule).includes("permission_key")
      ? userPermission.schedule.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  const navigate = useNavigate();
  var ss = moment().startOf("day").valueOf();
  var se = moment().startOf("day").add(1, "day").valueOf();
  var ws = moment().startOf("week").add(1, "day").valueOf();
  var t = moment().startOf("day").add(7, "days").valueOf();
  var ms = moment().startOf("month").valueOf();
  const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);
  const getTeamMemberList = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdToken();
      const response = await fetch(
        "https://bestmate.us/api/team/get-all-members",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let result = await response.json();
      setTeamMemberList(result);
      // setLoading(false);
    }
  };
  const getData = (token) => {
    fetch(
      `https://bestmate.us/api/dashboard/v2/${ms}/${se}/${ws}/${se}/${ss}/${t}/${timeZoneOffset}` +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result.appointments);
          console.log(result);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  useEffect(async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    if (loading) {
      var user = firebase.auth().currentUser;
      if (user) {
        let tokenss = await user.getIdToken();
        settoken(tokenss);
        getData(tokenss);
        if (schedulePermission.includes("ALL")) {
          getTeamMemberList();
        }
      }
    }
    // });
  }, [loading]);
  // useEffect(() => {
  //   if(loading){

  //   }
  // }, [loading]);
  console.log(data);
  return (
    <>
      <Card className="flex-fill w-100 mt-2 border rounded-0">
        <Card.Header
          style={{
            borderBottom: "1px solid #c2c0c0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "pink",
            paddingTop: 12,
            paddingBottom: 12,
          }}
        >
          <Card.Title className="mb-0">Appointments</Card.Title>
          {/* <div className="card-actions float-end"> */}
          {/* <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <Badge
          bg="primary"
          className=""
          style={{
            cursor: "pointer",
            height: 20,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          onClick={() => navigate("/schedule/add-schedule")}
        >
          <text>Add new Event</text>
        </Badge> */}
          {schedulePermission.includes("ADD") ? (
            <div
              style={{
                fontSize: 10.5,
                fontWeight: "bold",
                color: "white",
                // color: "#407fea",
                border: "1px solid #209BCF",
                backgroundColor: "#209BCF",
                padding: "1px 3px 1px 3px",
                paddingRight: 8,
                paddingLeft: 8,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 5,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                mixpanel.track("New Event(Dashboard Web)");
                return setCreatenewmodal(true);
              }}
            >
              New Event
            </div>
          ) : (
            <div style={{ height: 26 }}></div>
          )}
          {/* </div> */}
        </Card.Header>
        <Card.Body
          className="d-flex"
          style={{ paddingTop: 0, flexDirection: "column" }}
        >
          {/* <DatePicker
            enableScroll={true}
            enableDaysBefore={1}
            enableDays={3660}
            selectedDay={() => {}}
            color={"pink"}
          /> */}
          {/* <Datepicker
            onChange={(e) => {
              const [startValue, endValue, rangeDates] = e;
              setDate((prev) => ({
                ...prev,
                endValue: startValue,
                startValue,
                rangeDates,
              }));
            }}
            // locale={enUS}
            startValue={date.startValue}
            endValue={date.endValue}
            className={{ selectedDay: "backgroundColor:pink" }}
          /> */}

          {data ? (
            <div className="" style={{ width: "100%" }}>
              {/* <li className="timeline-item">
              <strong>The big launch</strong>
              <span className="float-end text-muted text-sm">2h ago</span>
              <p>
                Sed aliquam ultrices mauris. Integer ante arcu, accumsan a,
                consectetuer eget, posuere ut, mauris. Praesent adipiscing.
                Phasellus ullamcorper ipsum rutrum nunc...
              </p>
            </li> */}
              {data.length === 0 ? (
                <div style={{ paddingTop: 20 }}>No meetings for now</div>
              ) : (
                <>
                  {data.map((elem) => {
                    if (
                      Object.keys(elem).includes("invoice_id") &&
                      elem.invoice_workflow_type === "ORDER"
                    ) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: ",
                            padding: "10px 0",
                            borderTop: "1px solid #f5f6f9",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "Individual Booking Row (Dashboard Web)",
                              {
                                ...elem,
                              }
                            );
                            navigate("/bookings/" + elem.invoice_id, {
                              state: {
                                row: elem,
                              },
                            });
                          }}
                        >
                          <div
                            style={{
                              height: 60,
                              width: 4,
                              backgroundColor: "#1AB293",
                              marginRight: 10,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#f5f6f9",
                              width: "40px",
                              height: "43px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                // lineHeight: 0.9,
                              }}
                            >
                              {moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("DD")}
                            </div>
                            <div
                              style={{
                                lineHeight: 1,
                                fontSize: "13px",
                              }}
                            >
                              {moment(
                                elem.invoice_visits[0].appointment_start_time
                              )
                                .format("dddd")
                                .substring(0, 3)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 10px",
                              maxWidth: "70%",
                            }}
                          >
                            <div>
                              <strong style={{ fontSize: 14 }}>
                                {truncateTextByWordCount(
                                  `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
                                  40
                                )}
                              </strong>
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              {/* {`${moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("hh:mm a")} - ${moment(
                                elem.invoice_visits[0].appointment_end_time
                              ).format("hh:mm a")}`} */}
                              {`${moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("hh:mm a")} ${moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("MMM DD")} - ${moment(
                                elem.invoice_visits[0].appointment_end_time
                              ).format("hh:mm a")} ${moment(
                                elem.invoice_visits[0].appointment_end_time
                              ).format("MMM DD")}`}
                            </div>
                            <div>
                              <strong style={{ fontSize: 12 }}>
                                {`${
                                  elem.invoice_title
                                    ? truncateTextByWordCount(
                                        elem.invoice_title,
                                        50
                                      )
                                    : ""
                                }`}
                              </strong>
                            </div>
                            {bookingPermission.includes("ALL") &&
                            ((elem.multi_invoice_assignee &&
                              elem.multi_invoice_assignee.length !== 0) ||
                              (elem.invoice_assignee &&
                                Object.keys(elem.invoice_assignee).length !==
                                  0)) ? (
                              <div
                                style={{
                                  fontSize: 12,
                                  marginTop: 3,
                                  color: "#424040",
                                }}
                              >
                                Assigned to{" "}
                                {elem.multi_invoice_assignee &&
                                  elem.multi_invoice_assignee.length !== 0 &&
                                  elem.multi_invoice_assignee.map(
                                    (elems, index) => {
                                      return (
                                        <>
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {index ===
                                            elem.multi_invoice_assignee.length -
                                              1
                                              ? elem.invoice_assignee &&
                                                Object.keys(
                                                  elem.invoice_assignee
                                                ).length !== 0
                                                ? elems.team_member_name + ", "
                                                : elems.team_member_name
                                              : elems.team_member_name + ", "}
                                          </span>
                                        </>
                                      );
                                    }
                                  )}
                                {elem.invoice_assignee &&
                                Object.keys(elem.invoice_assignee).length !==
                                  0 ? (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {elem.invoice_assignee.team_member_name}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      );
                    } else if (
                      Object.keys(elem).includes("invoice_id") &&
                      elem.invoice_workflow_type === "REQUEST"
                    ) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: ",
                            padding: "10px 0",
                            borderTop: "1px solid #f5f6f9",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "Individual Request Row (Dashboard Web)",
                              {
                                ...elem,
                              }
                            );
                            navigate("/requests/" + elem.invoice_id, {
                              state: {
                                row: elem,
                              },
                            });
                          }}
                        >
                          <div
                            style={{
                              height: 60,
                              width: 4,
                              backgroundColor: "orange",
                              marginRight: 10,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#f5f6f9",
                              width: "40px",
                              height: "43px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                // lineHeight: 0.9,
                              }}
                            >
                              {moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("DD")}
                            </div>
                            <div
                              style={{
                                lineHeight: 1,
                                fontSize: "13px",
                              }}
                            >
                              {moment(
                                elem.invoice_visits[0].appointment_start_time
                              )
                                .format("dddd")
                                .substring(0, 3)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 10px",
                              maxWidth: "70%",
                            }}
                          >
                            <div>
                              <strong style={{ fontSize: 14 }}>
                                {truncateTextByWordCount(
                                  `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
                                  40
                                )}
                              </strong>
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              {/* {`${moment(
                                elem.invoice_visits[0]
                                  .appointment_start_time
                              ).format("hh:mm a")} - ${moment(
                                elem.invoice_visits[0]
                                  .appointment_end_time
                              ).format("hh:mm a")}`} */}
                              {`${moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("hh:mm a")} ${moment(
                                elem.invoice_visits[0].appointment_start_time
                              ).format("MMM DD")} - ${moment(
                                elem.invoice_visits[0].appointment_end_time
                              ).format("hh:mm a")} ${moment(
                                elem.invoice_visits[0].appointment_end_time
                              ).format("MMM DD")}`}
                            </div>
                            <div>
                              <strong style={{ fontSize: 12 }}>
                                {`${
                                  elem.invoice_title
                                    ? truncateTextByWordCount(
                                        elem.invoice_title,
                                        50
                                      )
                                    : ""
                                }`}
                              </strong>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: ",
                            padding: "10px 0",
                            borderTop: "1px solid #f5f6f9",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "Individual Schedule Row (Dashboard Web)",
                              {
                                ...elem,
                              }
                            );
                            if (schedulePermission.includes("EDIT")) {
                              setSelectedEvent(elem);
                              setEditmodal(true);
                            }
                          }}
                        >
                          <div
                            style={{
                              height: 60,
                              width: 4,
                              backgroundColor: "#407FEA",
                              marginRight: 10,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#f5f6f9",
                              width: "40px",
                              height: "43px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                // lineHeight: 0.9,
                              }}
                            >
                              {moment(elem.appointment_start_time).format("DD")}
                            </div>
                            <div
                              style={{
                                lineHeight: 1,
                                fontSize: "13px",
                              }}
                            >
                              {moment(elem.appointment_start_time)
                                .format("dddd")
                                .substring(0, 3)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 10px",
                              maxWidth: "70%",
                            }}
                          >
                            <strong style={{ fontSize: 14 }}>
                              {truncateTextByWordCount(
                                `${elem.appointment_partner.partner_first_name} ${elem.appointment_partner.partner_last_name}`,
                                40
                              )}
                            </strong>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              {`${moment(elem.appointment_start_time).format(
                                "hh:mm a"
                              )} ${moment(elem.appointment_start_time).format(
                                "MMM DD"
                              )} - ${moment(elem.appointment_end_time).format(
                                "hh:mm a"
                              )} ${moment(elem.appointment_end_time).format(
                                "MMM DD"
                              )}`}
                            </div>
                            <div>
                              <strong style={{ fontSize: 12 }}>
                                {truncateTextByWordCount(
                                  `${elem.appointment_name}`,
                                  50
                                )}
                              </strong>
                            </div>
                            {schedulePermission.includes("ALL") &&
                            ((elem.multi_appointment_assignee &&
                              elem.multi_appointment_assignee.length !== 0) ||
                              (elem.appointment_assignee &&
                                Object.keys(elem.appointment_assignee)
                                  .length !== 0)) ? (
                              <div
                                style={{
                                  fontSize: 12,
                                  marginTop: 3,
                                  color: "#424040",
                                }}
                              >
                                Assigned to{" "}
                                {elem.multi_appointment_assignee &&
                                  elem.multi_appointment_assignee.length !==
                                    0 &&
                                  elem.multi_appointment_assignee.map(
                                    (elems, index) => {
                                      return (
                                        <>
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {index ===
                                            elem.multi_appointment_assignee
                                              .length -
                                              1
                                              ? elem.appointment_assignee &&
                                                Object.keys(
                                                  elem.appointment_assignee
                                                ).length !== 0
                                                ? elems.team_member_name + ", "
                                                : elems.team_member_name
                                              : elems.team_member_name + ", "}
                                          </span>
                                        </>
                                      );
                                    }
                                  )}
                                {elem.appointment_assignee &&
                                Object.keys(elem.appointment_assignee)
                                  .length !== 0 ? (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {elem.appointment_assignee.team_member_name}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
      <Newschedulemodal
        type={"event"}
        createnewmodal={createnewmodal}
        setCreatenewmodal={setCreatenewmodal}
        setLoading={setLoading}
        setEventsData={[]}
        selecteddate={null}
        setSelecteddate={() => {}}
        teamMemberList={teamMemberList}
      />
      <Editschedulemodal
        type={"event"}
        createnewmodal={editmmodal}
        setCreatenewmodal={setEditmodal}
        setLoading={setLoading}
        setEventsData={[]}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        teamMemberList={teamMemberList}
      />
    </>
  );
};

export default Appointments;
