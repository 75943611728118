import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Image, Button } from "react-bootstrap";
import {
  faCheck,
  faCheckSquare,
  faCoffee,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Calendar, DollarSign, FileText, User, Users } from "react-feather";
import "../../assets/css/App.css";
import customergif from "../../assets/img/gif/customer.gif";
import estimategif from "../../assets/img/gif/estimate.gif";
import jobgif from "../../assets/img/gif/job.gif";
import invoicegif from "../../assets/img/gif/invoice.gif";
import paymentspng from "../../assets/img/gif/payments.png";
import mixpanel from "mixpanel-browser";

const Estimate = () => {
  const htmlCode = `<div>
        <table
          cellspacing="0"
          border="0"
          cellpadding="0"
          align="center"
          bgcolor="white"
          style="
      background-color: white;
      border-collapse: separate;
      border-spacing: 0;
      font-family: Helvetica, Arial, sans-serif;
      letter-spacing: 0;
      table-layout: fixed;
    "
        >
          <tbody>
            <tr>
              <td
                style="
            font-family: Helvetica, Arial, sans-serif;
            font-size: 16px;
            padding: 5px 20px;
          "
              >
                
                <table
                  width="100%"
                  style="
              border-collapse: separate;
              border-spacing: 0;
              table-layout: fixed;
            "
                >
                  <tbody>
                    <tr>
                      <td
                        style="
                    color: #4d6974;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 15px;
                    line-height: 26px;
                    padding: 0;
                  "
                      >
                        <p
                          style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                        >
                          Now that you're up and running, let's use Bestmate to
                          create and send your first estimate to a client.
                          <br />
                        </p>

                        <div
                          style="
                      line-height: 0;
                      margin-bottom: 17px;
                      margin-top: 17px;
                      display: flex;
                      justify-content: left;
                    "
                        >
                          <img
                            src="https://bestmate.us/assets/images/tutorial-estimate.png"
                            width="600"
                            style="margin: 0; max-width: 100%"
                            tabindex="0"
                          />
                          <div style="opacity: 0.01; left: 506px; top: 577.5px"></div>
                        </div>
                        <hr
                          style="
                      border-style: solid none none;
                      border-top-color: #cfcfcf;
                      border-top-width: 1px;
                      margin: 0px 0%;
                    "
                        />

                        <h2
                          style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 18px;
                      margin-top: 30px;
                    "
                        >
                          <b>Create your first estimate on BestMate app</b>
                        </h2>

                        <p style="line-height: 1.4; margin: 0 0 10px">
                          1. <b>Estimate No: </b>Estimate number is self
                          generated, a number after the last number. But you can
                          also give your own number
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          2. <b>Estimate Date: </b>
                          It would take the date of today but you can also
                          change it manually
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          3. <b>Due Date: </b>
                          This is the date as per your terms by when you expect
                          the customer to pay.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          4.<b> Add Customer: </b>
                          Choose your customer name and address from pre-filled
                          data.If in mobile or computer choose from the drop
                          down showing all contacts in file else create a new
                          one. If required edit the name and address clicking on
                          the edit button.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          5. <b>Add Product: </b>
                          Choose your Product from pre-filled data or add new
                          one if not pre-existing.Items are filled using add
                          items page.If required edit the details clicking on
                          the edit button.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          6. <b>Add Service: </b>
                          Choose your Service name from pre-filled services or
                          add a new service if not in the list through the add
                          Service page. If required edit the details clicking on
                          the edit button.
                        </p>

                        <p style="line-height: 1.4; margin: 0 0 10px">
                          7. <b>Subtotal: </b>
                          This is total amount of products and services without
                          taxes etc
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          8. <b>Discount: </b>
                          If you have any discounts to offer you can input them
                          here.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          9. <b>Taxes: </b>
                          Input the taxes or GST
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          10. <b>Total: </b>
                          This gives the Total Invoice value including taxes and
                          discounts
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          11. <b>Payment Received</b>
                          It would get payments from the transactions list.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          12.<b> Amount Due: </b>
                          It is the total balance amount due for the invoice
                          after deducting payments received.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          13. <b>Online Payments: </b> Give options for
                          customers to make payments on tap of this button.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          14. <b>Auto Reminder: </b>
                          Set in days when you want an auto reminder to be sent
                          to the buyer.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          15.<b> Notes: </b>
                          Add any additional notes here.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          16. <b> Send Estimate: </b>
                          You can send the above Estimate by email to customer
                          or save it and send from the stored Estimates list.
                        </p>
                        <p style="line-height: 1.4; margin: 0 0 10px">
                          17. <b>Save Only: </b>
                          Estimate gets saved and is also listed in Estimates
                          list
                        </p>
                        <h2
                          style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 20px;
                    "
                        >
                          <b>Automated quote follow-ups & reminders</b>
                        </h2>
                        <p style="line-height: 1.8; margin: 0 0 10px">
                          Bestmate streamlines the process of obtaining estimate
                          approvals with automated, customized emails to
                          customers for follow-up. It proactively prompts
                          customers to respond to your quotes, saving time and
                          effort.
                        </p>
                        <h2
                          style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 20px;
                    "
                        >
                          <b>Online approvals</b>
                        </h2>
                        <p style="line-height: 1.8; margin: 0 0 20px">
                          Clients can approve or respond to quotes online,
                          saving you time and ensuring you never miss approved
                          quotes.
                        </p>
                        <hr
                          style="
                      border-style: solid none none;
                      border-top-color: #cfcfcf;
                      border-top-width: 1px;
                      margin-top: 10px;
                    "
                        />

                        <table
                          style="
                      border-collapse: collapse;
                      border-spacing: 0;
                      margin: 17px 0;
                      table-layout: fixed;
                    "
                        ></table>
                        <p style="line-height: 1.8; margin: 0 0 10px">
                          <b>Need more assistance?</b> We're here to help. Reach
                          out to us with any questions at +1 (415) 735-6106 or
                          replying to this email, we're happy to assist you.
                        </p>

                        <a
                          href="https://calendly.com/jim-bestmate/30min"
                          style="
                      border: none;
                      color: #1251ba;
                      outline: none !important;
                    "
                          target="_blank"
                          data-saferedirecturl="https://calendly.com/jim-bestmate/30min"
                        >
                          Book a meeting with Sam
                        </a>
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>`;

  return <div dangerouslySetInnerHTML={{ __html: htmlCode }}></div>;
};
const Job = () => {
  const htmlCode = `<div>
  <table
    cellspacing="0"
    border="0"
    cellpadding="0"
    align="center"
    bgcolor="white"
    style="
      background-color: white;
      border-collapse: separate;
      border-spacing: 0;
      font-family: Helvetica, Arial, sans-serif;
      letter-spacing: 0;
      table-layout: fixed;
    "
  >
    <tbody>
      <tr>
        <td
          style="
            font-family: Helvetica, Arial, sans-serif;
            font-size: 16px;
            padding: 5px 20px;
          "
        >
         
          <table
            width="100%"
            style="
              border-collapse: separate;
              border-spacing: 0;
              table-layout: fixed;
            "
          >
            <tbody>
              <tr>
                <td
                  style="
                    color: #4d6974;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 15px;
                    line-height: 26px;
                    padding: 0;
                  "
                >
              
                  <p
                    style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                  >
                    Bestmate revolutionizes the way you schedule bookings and
                    appointments for your clients. Let's take advantage of
                    its capabilities and start sending out bookings to your
                    clients today.<br />
                  </p>

                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 8px;
                    "
                  >
                    <b>Setup bookings for your clients</b>
                  </h2>
                  <p
                    style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                  >
                    Schedule bookings with your clients using BestMate. Easily
                    book bookings in less than a minute and keep track of
                    upcoming ones. Save your time. Impress your customers.
                    Get more business.<br />
                  </p>

                  <div
                    style="
                      line-height: 0;
                      margin-bottom: 17px;
                      margin-top: 17px;
                    "
                  >
                    <img
                      src="https://bestmate.us/assets/images/tutorial-job.png"
                      width="600"
                      style="margin: 0; max-width: 100%"
                      tabindex="0"
                    />
                    <div
                      style="opacity: 0.01; left: 506px; top: 577.5px"
                    ></div>
                  </div>

                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 18px;
                      margin-top: 20px;
                    "
                  >
                    <b>Create your first booking on BestMate</b>
                  </h2>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    You can now schedule online bookings for your clients.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    1. <b>Add Customer : </b>
                    Choose the customers name from drop down pre entered
                    list and if not there add the new customer as directed.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    2. <b> Booking Name : </b>
                    Choose from drop down prefilled else write new name for
                    the Booking
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    3. <b>Booking Description : </b>
                    Choose from drop down list or input a new booking
                    description
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    4. <b>Internal Notes : </b>
                    Write down any additional notes if required.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    5. <b>Booking Date : </b>
                    Choose from the calender
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    6. <b>Booking Start Time : </b>
                    Tap and from the watch tool add the Booking start time
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    7. <b>Booking End Time : </b>
                    Tap and from the watch tool add the Booking End time
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    8. <b> Email Confirmation : </b>
                    Choose the required option and confirmation and
                    reminders would be sent accordingly
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    9. <b>Inventory : </b>
                    Give initial inventory or quantity of product in
                    stock.This is starting point while later inventory would
                    get added if supplier bill added or decreased if some
                    customer invoiced this product as the case maybe.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    10. <b>Save Schedule :</b>
                    Save this booking by clicking save
                  </p>

                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 8px;
                    "
                  >
                    <b>Automatic Reminders</b>
                  </h2>
                  <p
                    style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                  >
                    With Bestmate, you'll see booking details instantly, and
                    that's not all. We keep your customers informed with
                    booking reminders and keep you updated via automated
                    email notifications within our platform.<br />
                  </p>

                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 20px;
                    "
                  >
                    <b>Setting automatic reminders</b>
                  </h2>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    You can now schedule online bookings for your clients.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    1. An automated reminder is automatically set and sent
                    when you save the booking in the app, so there's no need to
                    worry.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    2. Bestmate will remember and send the reminder for you.
                  </p>

                  <p style="line-height: 1.8; margin: 0 0 10px">
                    <b style="color: #000000">So simple. So easy.</b> Save
                    valuable time and never miss a booking.
                  </p>

                  <hr
                    style="
                      border-style: solid none none;
                      border-top-color: #cfcfcf;
                      border-top-width: 1px;
                      margin-top: 10px;
                    "
                  />

                  <table
                    style="
                      border-collapse: collapse;
                      border-spacing: 0;
                      margin: 17px 0;
                      table-layout: fixed;
                    "
                  >
                  
                  </table>
                  <p style="line-height: 1.8; margin: 0 0 10px">
                    <b>Need more assistance?</b> We're here to help. Reach
                    out to us with any questions at +1 (415) 735-6106 or
                    replying to this email, we're happy to assist you.
                  </p>

                  <a
                    href="https://calendly.com/jim-bestmate/30min"
                    style="
                      border: none;
                      color: #1251ba;
                      outline: none !important;
                    "
                    target="_blank"
                    data-saferedirecturl="https://calendly.com/jim-bestmate/30min"
                    >Book a meeting with Sam</a
                  >
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>

`;

  return <div dangerouslySetInnerHTML={{ __html: htmlCode }}></div>;
};
const Invoice = () => {
  const htmlCode = `<div>
  <table
    cellspacing="0"
    border="0"
    cellpadding="0"
    align="center"
    bgcolor="white"
    style="
      background-color: white;
      border-collapse: separate;
      border-spacing: 0;
      font-family: Helvetica, Arial, sans-serif;
      letter-spacing: 0;
      table-layout: fixed;
    "
  >
    <tbody>
      <tr>
        <td
          style="
            font-family: Helvetica, Arial, sans-serif;
            font-size: 16px;
            padding: 5px 20px;
          "
        >
         
          <table
            width="100%"
            style="
              border-collapse: separate;
              border-spacing: 0;
              table-layout: fixed;
            "
          >
            <tbody>
              <tr>
                <td
                  style="
                    color: #4d6974;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 15px;
                    line-height: 26px;
                    padding: 0;
                  "
                >
                 
                  <p
                    style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                  >
                    BestMate can streamline your billing and invoicing
                    process, improve collection efforts, and make informed
                    decisions based on real-time information about your cash
                    flows.<br />
                  </p>
                  <ul>
                    <li>e-Invoicing on the go from your mobile device</li>
                    <li>Automated reminders for payments</li>
                    <li>
                      Collect anywhere, even on the field, with payment
                      gateway
                    </li>
                    <li>
                      Client's outstanding balance information on your
                      mobile 24x7
                    </li>
                  </ul>
                  <p
                    style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                  >
                    <b>So simple. So easy.</b> So what are you waiting for?
                    Get in control and improve your cash flows.<br />
                  </p>

                  <div
                    style="
                      line-height: 0;
                      margin-bottom: 17px;
                      margin-top: 17px;
                    "
                  >
                    <img
                      src="https://bestmate.us/assets/images/tutorial-invoice.png"
                      width="600"
                      style="margin: 0; max-width: 100%"
                      tabindex="0"
                    />
                    <div
                      style="opacity: 0.01; left: 506px; top: 577.5px"
                    ></div>
                  </div>
                  <hr
                    style="
                      border-style: solid none none;
                      border-top-color: #cfcfcf;
                      border-top-width: 1px;
                      margin: 0px 0%;
                    "
                  />
                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 18px;
                      margin-top: 30px;
                    "
                  >
                    <b>Create your first invoice</b>
                  </h2>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    1. <b>Invoice No: </b>Invoice number is self generated,
                    a number after the last number. But you can also give
                    your own number
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    2. <b>Invoice Date: </b>
                    It would take the date of today but you can also change
                    it manually
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    3. <b>Due Date: </b>
                    This is the date as per your terms by when you expect
                    the customer to pay.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    4.<b> Add Customer: </b>
                    Choose your customer name and address from pre-filled
                    data.If in mobile or computer choose from the drop down
                    showing all contacts in file else create a new one. If
                    required edit the name and address clicking on the edit
                    button.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    5. <b>Add Product: </b>
                    Choose your Product from pre-filled data or add new one
                    if not pre-existing.Items are filled using add items
                    page.If required edit the details clicking on the edit
                    button.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    6. <b>Add Service: </b>
                    Choose your Service name from pre-filled services or add
                    a new service if not in the list through the add Service
                    page. If required edit the details clicking on the edit
                    button.
                  </p>

                  <p style="line-height: 1.4; margin: 0 0 10px">
                    7. <b>Subtotal: </b>
                    This is total amount of products and services without
                    taxes etc
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    8. <b>Discount: </b>
                    If you have any discounts to offer you can input them
                    here.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    9. <b>Taxes: </b>
                    Input the taxes or GST
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    10. <b>Total: </b>
                    This gives the Total Invoice value including taxes and
                    discounts
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    11. <b>Payment Received</b>
                    It would get payments from the transactions list.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    12.<b> Amount Due: </b>
                    It is the total balance amount due for the invoice after
                    deducting payments received.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    13. <b>Online Payments: </b> Give options for customers
                    to make payments on tap of this button.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    14. <b>Auto Reminder: </b>
                    Set in days when you want an auto reminder to be sent to
                    the buyer.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    15.<b> Notes: </b>
                    Add any additional notes here.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    16. <b> Send Invoice: </b>
                    You can send the above invoice by email to customer or
                    save it and send from the stored Invoices list.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    17. <b>Save Only: </b>
                    Invoices gets saved and is also listed in Invoices list
                  </p>

                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 20px;
                    "
                  >
                    <b>Set Automated Reminder</b>
                  </h2>
                  <p style="line-height: 1.4; margin: 0 0 6px">
                    1. Automated reminders are set and sent by specifying
                    the number of days from the invoice date that you want
                    the reminder to be sent.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 10px">
                    2. You don't need to worry, Bestmate sends payment
                    reminders automatically.
                  </p>
                  <h2
                    style="
                      color: #012939;
                      font-size: 18px;
                      font-weight: bold;
                      margin-bottom: 8px;
                      margin-top: 20px;
                    "
                  >
                    <b>Collect anywhere on the field </b>
                  </h2>
                  <p style="line-height: 1.4; margin: 0 0 6px">
                    1. Invoices with payment options allow clients to easily
                    access the payment gateway and make payments using
                    credit card or bank details of their choice.
                  </p>
                  <p style="line-height: 1.4; margin: 0 0 18px">
                    2. You can also use the payment gateway on-site at the
                    client's location, allowing you to collect payments on
                    your mobile.
                  </p>

                  <p
                    style="
                      color: #546270;
                      line-height: 1.7;
                      margin: 0 0 17px;
                    "
                  >
                    <b>So simple. So easy.</b> So what are you waiting for?
                    Create your first e-invoice, take control, and improve
                    your cash flows<br />
                  </p>

                  <hr
                    style="
                      border-style: solid none none;
                      border-top-color: #cfcfcf;
                      border-top-width: 1px;
                      margin-top: 17px;
                    "
                  />

                  <table
                    style="
                      border-collapse: collapse;
                      border-spacing: 0;
                      margin: 17px 0;
                      table-layout: fixed;
                    "
                  >
                   
                  </table>
                  <p style="line-height: 1.8; margin: 0 0 10px">
                    <b>Need more assistance?</b> We're here to help. Reach
                    out to us with any questions at +1 (415) 735-6106 or
                    replying to this email, we're happy to assist you.
                  </p>

                  <a
                    href="https://calendly.com/jim-bestmate/30min"
                    style="
                      border: none;
                      color: #1251ba;
                      outline: none !important;
                    "
                    target="_blank"
                    data-saferedirecturl="https://calendly.com/jim-bestmate/30min"
                    >Book a meeting with Sam</a
                  >
                  <br />
                 
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>

  
</div>`;

  return <div dangerouslySetInnerHTML={{ __html: htmlCode }}></div>;
};

const Onboarding = () => {
  const location = useLocation();
  console.log(location.state);
  const [progress, setProgress] = useState(0);
  const { bdata } = useAuth();
  const [selected, setSelected] = useState("CUSTOMER");
  const [activepoint, setActivepoint] = useState(0);
  var maxPoints = 0;
  console.log(bdata);
  const gifData = [
    {
      id: "CUSTOMER",
      title: "Start by creating your first Customer",
      gif: customergif,
      thirdBoxHeader:
        "Add and manage customers with all their details, including Invoices, Estimates, and Bookings all in one place",
      thirdBoxpoints: [
        "Request for Service from Client received by phone, mail, or on our portal",
        "Add client details or get them automatically when a customer makes a request on the portal.",
        "CRM with all Customer Details, Estimates, Booking, Invoices, Notes and Photos in one place for better customer service.",
        "Call, mail or message from same page.",
        "Elevate your impression on customers to a new level.",
      ],
    },
    {
      id: "QUOTE",
      title: "Create your first Estimate / Quote",
      gif: estimategif,
      thirdBoxHeader:
        "Impress your clients with e-quotes with automated follow-up, and converting requests to quote. Get an e-confirmation from the client and convert it to an order.",
      // rightsidehtml: <Estimate />,
      thirdBoxpoints: [
        "Choose customer from available list.",
        "Add Product and/or Services from existing list.",
        "Offer discounts if any on pre-loaded prices.",
        "Option to add notes and more details in estimate.",
        "Send estimate with a click.",
        "Convert to Booking with a click on approval.",
        "Keep track of all estimates sent. Bestmate also sends auto-reminders.",
      ],
    },
    {
      id: "BOOKING",
      title: "Schedule a Booking",
      gif: jobgif,
      thirdBoxHeader:
        "Schedule a time to do your client's booking and send an email reminding them with a few clicks.",
      // rightsidehtml: <Job />,
      thirdBoxpoints: [
        "Convert your Estimate to Booking with a click. No need to add details again",
        "Choose date and time for Booking",
        "Send Booking schedule to client",
        "Auto-remind client and yourself a day and/or 30 minutes before schedule",
        "Never miss a schedule",
      ],
    },
    {
      id: "INVOICE",
      title: "Create Invoice and Send to Customer",
      gif: invoicegif,
      thirdBoxHeader:
        "Complete the Booking, create an Invoice, and send to Customer",
      // rightsidehtml: <Invoice />,
      thirdBoxpoints: [
        "One click converts completed Booking to Invoice. No need to add details again",
        "Email professional impressive invoices to clients",
        "Auto-remind for payments",
        "Click of button on invoice opens payment gateway for client",
      ],
    },
    {
      id: "PAYMENTS",
      title: "Accept Payments Online",
      gif: paymentspng,
      thirdBoxHeader:
        "Customers pay on the payment gateway which they access from the Invoice. You can collect on mobile anywhere anytime, even on the field",
      // rightsidehtml: <></>,
      thirdBoxpoints: [
        "Click of button on invoice opens payment gateway for client",
        "Many options for client to pay",
        "Even collect while on the go, with your mobile",
        "Easy to set up payment gateway for your customers",
      ],
    },
  ];
  useEffect(() => {
    // Start the interval when the component mounts
    setActivepoint(0);
    setProgress(0);
    const interval = setInterval(() => {
      setActivepoint((prevCount) => {
        if (prevCount === maxPoints && selected === "CUSTOMER") {
          setSelected("QUOTE");
        } else if (prevCount === maxPoints && selected === "QUOTE") {
          setSelected("BOOKING");
        } else if (prevCount === maxPoints && selected === "BOOKING") {
          setSelected("INVOICE");
        } else if (prevCount === maxPoints && selected === "PAYMENTS") {
          setSelected("CUSTOMER");
        }
        return prevCount + 1;
      });
    }, 5000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [selected]);
  useEffect(() => {
    mixpanel.track(`On Onboard Page (Web)`);
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Getting Started" />
      <Container fluid className="p-0">
        <Card
          style={{
            display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",

            // alignItems: "center",
          }}
          className="pt-3 pb-3 border-bottom "
        >
          {/* <div style={{ fontSize: 50 }}>&#127881;</div> */}
          <div style={{ marginLeft: 0 }}>
            <h2 style={{ margin: 0, fontSize: 22 }}>
              {/* Welcome, {bdata.business_user_name}! */}
              Recommended Workflow
            </h2>
            <p style={{ margin: 0 }}>
              {/* We Will help you Getting Started. */}
              Get started using this Workflow.
            </p>
          </div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem" }}>
          {/* <Row>
            <Col xs={2}>
              <Card className="mb-2 border rounded-0" id="onboardinglist">
                <Card.Header>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#3f80ea",
                        padding: "15px 25px",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      <Users size={24} color="white" />
                      <div style={{ marginTop: 5, color: "white" }}>
                        Customer
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "15px 25px",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      <Users size={24} />
                      <div style={{ marginTop: 5 }}>Quote</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "15px 25px",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      <Users size={24} />
                      <div style={{ marginTop: 5 }}>Job</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "15px 25px",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      <Users size={24} />
                      <div style={{ marginTop: 5 }}>Invoice</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        // padding: "15px 25px",
                        width: 100,
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      <Users size={24} />
                      <div style={{ marginTop: 5 }}>Payments</div>
                    </div>
                  </div>
                </Card.Header>
              </Card>
            </Col>
            <Col xs={10}>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    Create Your first Customer
                  </div>
                  <Row
                    style={{ borderTop: "1px solid #c8ccc9", marginTop: 15 }}
                  >
                    <Col
                      xs={8}
                      style={{
                        borderRight: "1px solid #c8ccc9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 500,
                          height: 300,
                          border: "1px solid #c8ccc9",
                          margin: "30px 0",
                        }}
                      ></div>
                    </Col>
                    <Col xs={4}>
                      <div></div>
                    </Col>
                  </Row>
                </Card.Header>
              </Card>
            </Col>
          </Row> */}
          {/* <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Recommended Workflow
          </div> */}
          {/* <div
            style={{
              backgroundColor: "red",
              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 75,
                  height: 5,
                  backgroundColor: "pink",
                  // marginLeft: 90,
                  // marginRight: 5,
                }}
              ></div>
              <div
                style={{
                  width: 75,
                  height: 5,
                  backgroundColor: "pink",
                  // marginLeft: 5,
                  // marginRight: 5,
                }}
              ></div>
              <div
                style={{
                  width: 75,
                  height: 5,
                  backgroundColor: "pink",
                  // marginLeft: 5,
                  // marginRight: 5,
                }}
              ></div>
              <div
                style={{
                  width: 75,
                  height: 5,
                  backgroundColor: "pink",
                  // marginLeft: 10,
                  // marginRight: 5,
                }}
              ></div>
            </div>
            <div
              style={{
                // width: "100%",
                // height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "green",
                // marginTop: 20,
              }}
            >
              <div
                style={{
                  backgroundColor:
                    selected === "CUSTOMER" ? "#407FEA" : "white",
                  display: "flex",
                  boxShadow: "0px 0.5px 4px grey",
                  padding: 15,
                  flexDirection: "row",
                  borderRadius: 50,
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 10,
                  marginLeft: 10,
                }}
                // className="border"
                onClick={() => setSelected("CUSTOMER")}
              >
                <User
                  size={24}
                  color={selected === "CUSTOMER" ? "white" : "#6C757D"}
                />
                {selected === "CUSTOMER" ? (
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        marginLeft: 10,
                        fontWeight: "bold",
                        color: selected === "CUSTOMER" ? "white" : "black",
                      }}
                    >
                      Add Customer
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  backgroundColor: selected === "QUOTE" ? "#407FEA" : "white",
                  display: "flex",
                  boxShadow: "0px 0.5px 4px grey",
                  padding: 15,
                  flexDirection: "row",
                  borderRadius: 50,
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 10,
                  marginLeft: 10,
                }}
                // className="border"
                onClick={() => setSelected("QUOTE")}
              >
                <FileText
                  size={24}
                  color={selected === "QUOTE" ? "white" : "#6C757D"}
                />
                {selected === "QUOTE" ? (
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        marginLeft: 10,
                        fontWeight: "bold",
                        color: "black",
                        color: selected === "QUOTE" ? "white" : "black",
                      }}
                    >
                      Create Quote
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  backgroundColor: selected === "JOB" ? "#407FEA" : "white",
                  display: "flex",
                  boxShadow: "0px 0.5px 4px grey",
                  padding: 15,
                  flexDirection: "row",
                  borderRadius: 50,
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 10,
                  marginLeft: 10,
                }}
                onClick={() => setSelected("JOB")}
                // className="border"
              >
                <Calendar
                  size={24}
                  color={selected === "JOB" ? "white" : "#6C757D"}
                />
                {selected === "JOB" ? (
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: "bold",
                        marginLeft: 10,
                        color: "black",
                        color: selected === "JOB" ? "white" : "black",
                      }}
                    >
                      Schedule Job
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  backgroundColor: selected === "INVOICE" ? "#407FEA" : "white",
                  display: "flex",
                  boxShadow: "0px 0.5px 4px grey",
                  padding: 15,
                  flexDirection: "row",
                  borderRadius: 50,
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 10,
                  marginLeft: 10,
                }}
                // className="border"
                onClick={() => setSelected("INVOICE")}
              >
                <FileText
                  size={24}
                  color={selected === "INVOICE" ? "white" : "#6C757D"}
                />
                {selected === "INVOICE" ? (
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: "bold",
                        marginLeft: 10,
                        color: "black",
                        color: selected === "INVOICE" ? "white" : "black",
                      }}
                    >
                      Create Invoice
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  backgroundColor:
                    selected === "PAYMENTS" ? "#407FEA" : "white",
                  display: "flex",
                  boxShadow: "0px 0.5px 4px grey",
                  padding: 15,
                  flexDirection: "row",
                  borderRadius: 50,
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 10,
                  marginLeft: 10,
                }}
                // className="border"
                onClick={() => setSelected("PAYMENTS")}
              >
                <DollarSign
                  size={24}
                  color={selected === "PAYMENTS" ? "white" : "#6C757D"}
                />
                {selected === "PAYMENTS" ? (
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "black",
                        marginLeft: 10,
                        color: selected === "PAYMENTS" ? "white" : "black",
                      }}
                    >
                      Collect Payment
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div> */}

          <Row>
            <Col md={2} className="pt-3" style={{ position: "relative" }}>
              {/* <div className="dotted-line"></div> */}
              <h2
                className="d-none d-md-block"
                style={{
                  margin: 0,
                  fontSize: 18,
                  // position: "absolute",
                  // right: 10,
                  marginLeft: 0,
                  position: "absolute",
                  width: "100%",
                  // marginRight: 200,
                  // marginRight: 50,
                  // minWidth: "100%",
                  // maxWidth: "175px",
                  marginTop: 18,
                  color: "black",
                  // borderBottom: "solid 1px grey",
                  paddingBottom: 9,
                  // width: "175px",
                  // backgroundColor: "pink",
                  textAlign: "center",
                  right: 0,
                }}
              >
                Workflow
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  // backgroundColor: "pink",
                  // maxHeight: 480,
                }}
              >
                <div
                  className="d-block d-md-none"
                  style={{
                    height: "280px",
                    width: "100%",
                    maxWidth: "300px",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 25,
                    marginTop: 20,
                    zIndex: -10,
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 5,
                      marginTop: 35,
                      backgroundColor:
                        selected === "QUOTE" ||
                        selected === "BOOKING" ||
                        selected === "INVOICE" ||
                        selected === "PAYMENTS"
                          ? "#407FEA"
                          : "#dbdbdb",
                    }}
                  ></div>
                  <div
                    style={{
                      height: 40,
                      width: 5,
                      marginTop: 35,
                      backgroundColor:
                        selected === "BOOKING" ||
                        selected === "INVOICE" ||
                        selected === "PAYMENTS"
                          ? "#407FEA"
                          : "#dbdbdb",
                    }}
                  ></div>
                  <div
                    style={{
                      height: 40,
                      width: 5,
                      marginTop: 30,
                      backgroundColor:
                        selected === "INVOICE" || selected === "PAYMENTS"
                          ? "#407FEA"
                          : "#dbdbdb",
                    }}
                  ></div>
                  <div
                    style={{
                      height: 40,
                      width: 5,
                      marginTop: 35,
                      backgroundColor:
                        selected === "PAYMENTS" ? "#407FEA" : "#dbdbdb",
                    }}
                  ></div>
                </div>
                <div
                  className="d-none d-md-block"
                  style={{
                    height: "100%",
                    maxHeight: 400,
                    alignSelf: "center",
                    // width: "100%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 25,
                    marginTop: 20,
                    zIndex: -10,
                  }}
                >
                  <div
                    style={
                      selected === "QUOTE" ||
                      selected === "BOOKING" ||
                      selected === "INVOICE" ||
                      selected === "PAYMENTS"
                        ? {
                            height: 85,
                            width: 5,
                            marginTop: 20,
                            borderLeft: "8px dotted #407fea",
                          }
                        : {
                            height: 85,
                            width: 5,
                            marginTop: 20,
                            backgroundColor: "#dbdbdb",
                          }
                    }
                  ></div>
                  <div
                    style={
                      selected === "BOOKING" ||
                      selected === "INVOICE" ||
                      selected === "PAYMENTS"
                        ? {
                            height: 70,
                            width: 5,
                            marginTop: 20,
                            borderLeft: "8px dotted #407fea",
                          }
                        : {
                            height: 70,
                            width: 5,
                            marginTop: 20,
                            backgroundColor: "#dbdbdb",
                          }
                    }
                  ></div>
                  <div
                    style={
                      selected === "INVOICE" || selected === "PAYMENTS"
                        ? {
                            height: 70,
                            width: 5,
                            marginTop: 20,
                            borderLeft: "8px dotted #407fea",
                          }
                        : {
                            height: 70,
                            width: 5,
                            marginTop: 20,
                            backgroundColor: "#dbdbdb",
                          }
                    }
                  ></div>
                  <div
                    style={
                      selected === "PAYMENTS"
                        ? {
                            height: 70,
                            width: 5,
                            marginTop: 15,
                            borderLeft: "8px dotted #407fea",
                          }
                        : {
                            height: 70,
                            width: 5,
                            marginTop: 15,
                            backgroundColor: "#dbdbdb",
                          }
                    }
                  ></div>
                </div>
                <div
                  style={{
                    width: "100%",
                    // backgroundColor: "red",
                    height: "100%",
                    maxHeight: 400,
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginTop: 30,
                    // paddingTop: 3,
                    // alignItems: "center",
                    // marginTop: 20,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      backgroundColor:
                        selected === "CUSTOMER" ? "#407FEA" : "white",
                      // marginTop: 20,
                      marginBottom: 5,
                      // marginBottom: 15,
                      display: "flex",
                      height: 50,
                      boxShadow: "0px 0.5px 4px grey",

                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      cursor: "pointer",
                      paddingRight: 5,
                    }}
                    // className="border"
                    onClick={() => {
                      mixpanel.track("Add Customer (Onboarding Web)");
                      setSelected("CUSTOMER");
                    }}
                  >
                    <User
                      size={24}
                      color={selected === "CUSTOMER" ? "white" : "#6C757D"}
                      style={{ width: "35%" }}
                    />
                    <div>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: "bold",
                          color: selected === "CUSTOMER" ? "white" : "black",
                        }}
                      >
                        Add Customer
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      backgroundColor:
                        selected === "QUOTE" ? "#407FEA" : "white",
                      marginTop: 10,
                      // height: 63,
                      height: 50,
                      boxShadow: "0px 0.5px 4px grey",
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      cursor: "pointer",
                      paddingRight: 5,
                    }}
                    // className="border"
                    onClick={() => {
                      mixpanel.track("Create Quote (Onboarding Web)");

                      setSelected("QUOTE");
                    }}
                  >
                    <FileText
                      size={24}
                      style={{ width: "35%" }}
                      color={selected === "QUOTE" ? "white" : "#6C757D"}
                    />
                    <div>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "black",
                          color: selected === "QUOTE" ? "white" : "black",
                        }}
                      >
                        Create Estimate
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      backgroundColor:
                        selected === "BOOKING" ? "#407FEA" : "white",
                      marginTop: 10,
                      // height: 63,
                      height: 50,
                      boxShadow: "0px 0.5px 4px grey",
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      cursor: "pointer",
                      paddingRight: 5,
                    }}
                    onClick={() => {
                      mixpanel.track("Schedule Booking (Onboarding Web)");
                      setSelected("BOOKING");
                    }}
                    // className="border"
                  >
                    <Calendar
                      size={24}
                      style={{ width: "35%" }}
                      color={selected === "BOOKING" ? "white" : "#6C757D"}
                    />
                    <div>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "black",
                          color: selected === "BOOKING" ? "white" : "black",
                        }}
                      >
                        Schedule Booking
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      backgroundColor:
                        selected === "INVOICE" ? "#407FEA" : "white",
                      marginTop: 10,
                      // height: 63,
                      height: 50,
                      boxShadow: "0px 0.5px 4px grey",
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      cursor: "pointer",
                      paddingRight: 5,
                    }}
                    // className="border"
                    onClick={() => {
                      mixpanel.track("Create invoice (Onboarding Web)");
                      setSelected("INVOICE");
                    }}
                  >
                    <FileText
                      size={24}
                      style={{ width: "35%" }}
                      color={selected === "INVOICE" ? "white" : "#6C757D"}
                    />
                    <div>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "black",
                          color: selected === "INVOICE" ? "white" : "black",
                        }}
                      >
                        Create Invoice
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      backgroundColor:
                        selected === "PAYMENTS" ? "#407FEA" : "white",
                      marginTop: 10,
                      // height: 63,
                      height: 50,
                      boxShadow: "0px 0.5px 4px grey",
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      cursor: "pointer",
                      paddingRight: 5,
                    }}
                    // className="border"
                    onClick={() => {
                      mixpanel.track("Collect Payment (Onboarding Web)");
                      setSelected("PAYMENTS");
                    }}
                  >
                    <DollarSign
                      size={24}
                      style={{ width: "35%" }}
                      color={selected === "PAYMENTS" ? "white" : "#6C757D"}
                    />
                    <div>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "black",
                          color: selected === "PAYMENTS" ? "white" : "black",
                        }}
                      >
                        Collect Payment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={10}>
              <Row className="align-items-stretch">
                {gifData.map((elem) => {
                  if (elem.id === selected) {
                    return (
                      <>
                        <Col md={9} className="d-flex">
                          <div
                            style={{
                              width: "100%",
                              // height: 230,
                              height: "100%",
                              maxHeight: 480,
                              marginTop: 20,
                              backgroundColor: "white",
                              // marginTop: 20,
                              marginBottom: 10,
                              boxShadow: "1px 2px 6px grey",
                              textAlign: "left",
                              padding: 30,
                              // marginTop: 30,
                              borderRadius: 30,
                              // height: 400,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="pt-3 pb-4 p-3 border"
                          >
                            <p
                              style={{
                                color: "black",
                                fontSize: 18,
                                fontWeight: "bold",
                                lineHeight: "120%",
                              }}
                            >
                              {elem.title}
                            </p>
                            <div
                              style={{
                                flexGrow: 1,
                                // backgroundColor: "pink",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Image
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: 400,
                                  borderRadius: 10,
                                }}
                                src={elem.gif}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={3} className="d-flex" style={{}}>
                          <div
                            style={{
                              width: "100%",
                              marginTop: 20,
                              backgroundColor: "white",
                              // position: "relative",
                              // zIndex: 0,
                              marginBottom: 10,
                              boxShadow: "1px 2px 6px grey",
                              textAlign: "left",
                              borderRadius: 30,
                              overflow: "scroll",
                              height: "100%",
                              maxHeight: 480,
                            }}
                            className="p-3 border"
                          >
                            <p
                              style={{
                                color: "black",
                                fontSize: 18,
                                fontWeight: "bold",
                                lineHeight: "120%",
                              }}
                            >
                              {elem.title}
                            </p>
                            {/* {elem.rightsidehtml} */}
                            <div style={{ fontSize: 12, lineHeight: 1.4 }}>
                              {elem.thirdBoxHeader}
                            </div>
                            <div>
                              {elem.thirdBoxpoints.map((point, index) => {
                                let lastindex = elem.thirdBoxpoints.length - 1;
                                console.log(lastindex);
                                maxPoints = lastindex + 1;
                                return (
                                  <>
                                    <div
                                      style={{
                                        marginTop: 5,
                                        marginBottom: 5,
                                        display: "flex",
                                        alignItems: "center",
                                        // justifyContent: "center",
                                        // backgroundColor: "red",
                                        zIndex: 2,
                                      }}
                                    >
                                      <div
                                        style={{
                                          maxWidth: 40,
                                          width: "100%",
                                          // backgroundColor: "green",
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor:
                                              activepoint === index ||
                                              activepoint < index
                                                ? "transparent"
                                                : "#407FEA",
                                            // width: "100%",
                                            // maxWidth: "50px",
                                            height: "40px",
                                            borderRadius: 50,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // backgroundColor: "pink",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              // width: "100%",
                                              // maxWidth: "50px",
                                              height: "35px",
                                              width: "35px",
                                              borderRadius: 50,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              // backgroundColor: "pink",
                                            }}
                                          >
                                            <div style={{}}>{index + 1}</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: 10,
                                          width: "100%",
                                          fontSize: 11,
                                          color:
                                            activepoint === index + 1
                                              ? "#407FEA"
                                              : "#929394",
                                          fontWeight:
                                            activepoint === index + 1
                                              ? "bold"
                                              : "normal",
                                        }}
                                      >
                                        {point}
                                      </div>
                                    </div>
                                    {index !== lastindex ? (
                                      <div
                                        style={{
                                          width: "2px",
                                          height: "20px",
                                          // display: "flex",
                                          // flexGrow: 1,
                                          // position: "absolute",
                                          // backgroundColor:
                                          //   activepoint === index ||
                                          //   activepoint < index
                                          //     ? "#dbdbdb"
                                          //     : "#407FEA",
                                          backgroundColor: "#dbdbdb",

                                          marginLeft: 19,
                                          // top: -10,

                                          // zIndex: 1,
                                        }}
                                      >
                                        <div
                                          style={{
                                            // height: "50%",
                                            height:
                                              activepoint === index ||
                                              activepoint < index
                                                ? `${progress}%`
                                                : "100%",
                                            width: "100%",
                                            backgroundColor: "#407FEA",
                                            transition: `height ${
                                              activepoint === index ||
                                              activepoint < index
                                                ? "0s"
                                                : "5s"
                                            } ease-in`,
                                          }}
                                        ></div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  }
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default Onboarding;
