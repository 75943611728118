import React, { useState } from "react";

const ImageUploadContext = React.createContext();

export const useImageData = () => React.useContext(ImageUploadContext);

export const ImageUploadProvider = ({ children }) => {
  const [imageData, setImageData] = useState(null);

  return (
    <ImageUploadContext.Provider value={{ imageData, setImageData }}>
      {children}
    </ImageUploadContext.Provider>
  );
};
