import React, { useEffect, useState } from "react";
import { STRIPE_PRODUCTION } from "../constants";

const PremiumContext = React.createContext();

export const usePremium = () => React.useContext(PremiumContext);

export const PremiumProvider = ({ children }) => {
  const [isTeammember, setIsTeammember] = useState(false);
  const [moreInfo, setMoreInfo] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [premiumid, setPremiumID] = useState("");
  const [useIsFree, setUseIsFree] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  const [moreInfoCount, setMoreinfoCount] = useState(0);
  const isProduction = STRIPE_PRODUCTION;

  const stripePlans = isProduction
    ? {
        price_1OgNC3G7VHS7aQmPMvgRyr0a: "Basic Monthly",
        price_1OgN9hG7VHS7aQmPG3TKS6G7: "Starter Monthly",
        price_1OgNCrG7VHS7aQmPlsSGCE5D: "Pro Monthly",
        price_1OgNC3G7VHS7aQmPRIKsO6mw: "Basic Yearly",
        price_1OgN9hG7VHS7aQmPnpskHkzj: "Starter Yearly",
        price_1OgNDLG7VHS7aQmPhNiSWryY: "Pro Yearly",
      }
    : {
        price_1OKyKyHcyWy6FW9itFL4gzKH: "Basic Monthly",
        price_1OKyK8HcyWy6FW9iS475Sz8V: "Starter Monthly",
        price_1OKyNdHcyWy6FW9iifFNJvnG: "Pro Monthly",
        price_1OKyOeHcyWy6FW9ilNNAvogX: "Basic Yearly",
        price_1OKyKSHcyWy6FW9i6n6zdDpP: "Starter Yearly",
        price_1OKyNsHcyWy6FW9iJtyPPC91: "Pro Yearly",
      };
  const androidPlans = {
    "android.basic_monthly": "Basic Monthly",
    "android.basic_3month": "Basic Monthly",
    "android.basic_tier_p1m": "Basic Monthly",
    "android.basic_tier_p1m3": "Basic Monthly",
    "android.starter_monthly": "Starter Monthly",
    "android.starter_3month": "Starter Monthly",
    "android.starter_tier_str1m": "Starter Monthly",
    "android.starter_tier_p1m3": "Starter Monthly",
    "android.pro_monthly": "Pro Monthly",
    "android.pro_3month": "Pro Monthly",
    "android.pro_tier_pro1m": "Pro Monthly",
    "android.pro_tier_pro1m3": "Pro Monthly",
    "android.basic_yearly": "Basic Yearly",
    "android.basic_tier_year_p1y": "Basic Yearly",
    "android.starter_yearly": "Starter Yearly",
    "android.starter_tier_year_str1y": "Starter Yearly",
    "android.pro_yearly": "Pro Yearly",
    "android.pro_tier_year_pro1y": "Pro Yearly",
  };
  const iosPlans = {
    basic_plan: "Basic Monthly",
    starter_plan: "Starter Monthly",
    pro_plan: "Pro Monthly",
    basic_plan_year: "Basic Yearly",
    starter_plan_year: "Starter Yearly",
    pro_plan_year: "Pro Yearly",
  };
  const series = ["Starter", "Basic", "Pro"];

  useEffect(() => {
    if (isPremium) {
      if (
        moreInfo &&
        moreInfo.isPremiumAndroidUser &&
        moreInfo.isPremiumIOSUser &&
        moreInfo.isPremiumStripeUser
      ) {
        let androidId =
          androidPlans[
            `${moreInfo.androidSubscription}_${moreInfo.androidLineItem[0].offerDetails.basePlanId}`
          ];
        let androidPreferenceLevel = 0;
        let iosId = iosPlans[moreInfo.iOSSubscription];
        let iosPreferenceLevel = 0;
        let stripeId = stripePlans[moreInfo.stripeSubscription];
        let stripePreferenceLevel = 0;
        series.map((elem, index) => {
          if (androidId.includes(elem)) {
            androidPreferenceLevel = index + 1;
          }
          if (iosId.includes(elem)) {
            iosPreferenceLevel = index + 1;
          }
          if (stripeId.includes(elem)) {
            stripePreferenceLevel = index + 1;
          }
        });
        if (
          stripePreferenceLevel >= androidPreferenceLevel &&
          stripePreferenceLevel >= iosPreferenceLevel
        ) {
          setPremiumID(stripeId);
        } else if (
          iosPreferenceLevel >= androidPreferenceLevel &&
          iosPreferenceLevel >= stripePreferenceLevel
        ) {
          setPremiumID(iosId);
        } else if (
          stripePreferenceLevel >= androidPreferenceLevel &&
          stripePreferenceLevel >= iosPreferenceLevel
        ) {
          setPremiumID(androidId);
        }
      } else if (
        moreInfo &&
        moreInfo.isPremiumAndroidUser &&
        moreInfo.isPremiumIOSUser
      ) {
        let androidId =
          androidPlans[
            `${moreInfo.androidSubscription}_${moreInfo.androidLineItem[0].offerDetails.basePlanId}`
          ];
        let androidPreferenceLevel = 0;
        let iosId = iosPlans[moreInfo.iOSSubscription];
        let iosPreferenceLevel = 0;
        series.map((elem, index) => {
          if (androidId.includes(elem)) {
            androidPreferenceLevel = index + 1;
          }
          if (iosId.includes(elem)) {
            iosPreferenceLevel = index + 1;
          }
        });
        if (androidPreferenceLevel > iosPreferenceLevel) {
          setPremiumID(androidId);
        } else if (iosPreferenceLevel > androidPreferenceLevel) {
          setPremiumID(iosId);
        } else {
          if (androidId.includes("Year")) {
            setPremiumID(androidId);
          } else {
            setPremiumID(iosId);
          }
        }
      } else if (
        moreInfo &&
        moreInfo.isPremiumAndroidUser &&
        moreInfo.isPremiumStripeUser
      ) {
        let androidId =
          androidPlans[
            `${moreInfo.androidSubscription}_${moreInfo.androidLineItem[0].offerDetails.basePlanId}`
          ];
        let androidPreferenceLevel = 0;
        let stripeId = stripePlans[moreInfo.stripeSubscription];
        let stripePreferenceLevel = 0;
        series.map((elem, index) => {
          console.log(elem);
          if (androidId.includes(elem)) {
            androidPreferenceLevel = index + 1;
          }
          if (stripeId.includes(elem)) {
            stripePreferenceLevel = index + 1;
          }
        });
        console.log(androidId, stripeId);
        if (androidPreferenceLevel > stripePreferenceLevel) {
          setPremiumID(androidId);
        } else if (stripePreferenceLevel > androidPreferenceLevel) {
          setPremiumID(stripeId);
        } else {
          if (stripeId.includes("Year")) {
            setPremiumID(stripeId);
          } else {
            setPremiumID(androidId);
          }
        }
      } else if (
        moreInfo &&
        moreInfo.isPremiumIOSUser &&
        moreInfo.isPremiumStripeUser
      ) {
        let iosId = iosPlans[moreInfo.iOSSubscription];
        let iosPreferenceLevel = 0;
        let stripeId = stripePlans[moreInfo.stripeSubscription];
        let stripePreferenceLevel = 0;
        series.map((elem, index) => {
          if (iosId.includes(elem)) {
            iosPreferenceLevel = index + 1;
          }
          if (stripeId.includes(elem)) {
            stripePreferenceLevel = index + 1;
          }
        });
        if (stripePreferenceLevel > iosPreferenceLevel) {
          setPremiumID(stripeId);
        } else if (iosPreferenceLevel > stripePreferenceLevel) {
          setPremiumID(iosId);
        } else {
          if (stripeId.includes("Year")) {
            setPremiumID(stripeId);
          } else {
            setPremiumID(iosId);
          }
        }
      } else if (moreInfo && moreInfo.isPremiumAndroidUser) {
        setPremiumID(
          androidPlans[
            `${moreInfo.androidSubscription}_${moreInfo.androidLineItem[0].offerDetails.basePlanId}`
          ]
        );
      } else if (moreInfo && moreInfo.isPremiumIOSUser) {
        setPremiumID(iosPlans[moreInfo.iOSSubscription]);
      } else if (moreInfo && moreInfo.isPremiumStripeUser) {
        setPremiumID(stripePlans[moreInfo.stripeSubscription]);
      }
    } else {
      setPremiumID("");
    }
  }, [isPremium, isFree, isTrial]);

  return (
    <PremiumContext.Provider
      value={{
        moreInfo,
        setMoreInfo,
        isPremium,
        setIsPremium,
        isFree,
        setIsFree,
        isTrial,
        setIsTrial,
        premiumid,
        setPremiumID,
        useIsFree,
        moreInfoCount,
        setMoreinfoCount,
        isTeammember,
        setIsTeammember,
      }}
    >
      {children}
    </PremiumContext.Provider>
  );
};
