import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
// import "./table.css"
import { useEffect, useState, useContext } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import { useTable } from "react-table";
import { PlusSquare, ChevronRight, Plus, X } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { Formik } from "formik";
import * as Yup from "yup";
import "../../assets/css/App.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import {
  Card,
  Col,
  Row,
  Form,
  Container,
  Table,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  ListGroup,
  Breadcrumb,
} from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import SelectInvoicemodal from "../../components/SelectInvoicemodal";
import mixpanel from "mixpanel-browser";
import { logError } from "../../utils/useErrorlogging";

const FilteringTable = (props) => {
  const navigate = useNavigate();
  // console.log(props.data);
  let usercurrency = localStorage.getItem("currency") || "USD";

  const COLUMNS = [
    {
      Header: "Invoice Number",
      accessor: "invoice_number",
    },
    {
      Header: "Name",
      accessor: (elem) =>
        `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
    },
    {
      Header: "Invoice Total",
      accessor: "invoice_grand_total",
      Cell: ({ value, row }) => {
        const num = Number(value);
        // console.log(row);
        if (Number.isNaN(num)) {
          return "-";
        } else {
          return num.toLocaleString("en-US", {
            style: "currency",
            currency: usercurrency,
          });
        }
      },
    },
    {
      Header: "Invoice Settlement Amount",
      accessor: "invoice_settled_amount",
      Cell: ({ value, row }) => {
        const num = Number(value);
        // console.log(row);
        if (Number.isNaN(num)) {
          return "-";
        } else {
          return num.toLocaleString("en-US", {
            style: "currency",
            currency: usercurrency,
          });
        }
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = props.data;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <>
      <Table striped bordered {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getFooterGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  props.InvoiceData(row.original);
                  console.log(row.original);
                  props.setOpenModals(!props.openModals);
                  // navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const AddForm = (props) => {
  // console.log(localStorage);
  const [openModals, setOpenModals] = useState();
  const [InvoiceData, setInvoiceData] = useState([]);
  const [disablesave, setDisablesave] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [disablebtn, setDisablebtn] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  const createTransaction = async (finalData) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      console.log(finalData);
      fetch("https://bestmate.us/api/transactions/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(finalData),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            console.log(res);
            navigate("/transaction");
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Transaction added successfully",
              ripple: "true",
              dismissible: "true",
            });
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });

            // mixpanel.track(`Fetch Error (Add Transaction Web)`, {
            //   error: "transaction add api",
            // });
            logError({
              error: "fetch error",
              api: "transaction add api",
              component: `Add Transaction Web`,
            });
            setDisablebtn(false);
          }
        })
        .catch((err) => {
          setDisablebtn(false);
          // mixpanel.track(`Error (Add Transaction Web)`, {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: "Add Transaction Web",
          });
          console.log(err);
        });
    }
  };
  // console.log(props.dataInvoice);
  // console.log(props.dataOrder);
  // console.log(props.dataEstimate);
  let currencyCode = localStorage.getItem("currency") || "USD";
  let currencyName =
    localStorage.getItem("currencyName") || "United States Dollar";
  console.log(currencyCode, " ", currencyName);

  let diff = InvoiceData.invoice_grand_total
    ? InvoiceData.invoice_grand_total
    : 0 - InvoiceData.invoice_settled_amount
    ? InvoiceData.invoice_settled_amount
    : 0;
  diff = diff.toLocaleString("en-US", {
    style: "currency",
    currency: InvoiceData.invoice_currency_code
      ? InvoiceData.invoice_currency_code
      : currencyCode,
  });
  console.log(
    diff,
    InvoiceData.invoice_grand_total,
    InvoiceData.invoice_settled_amount
  );
  useEffect(() => {
    if (InvoiceData.length === 0) {
      setDisablesave(true);
    } else {
      setDisablesave(false);
    }
  }, [InvoiceData]);
  useEffect(() => {
    mixpanel.track(`On Transaction Add (Web)`);
  }, []);
  const navigate = useNavigate();
  const METHORDS = ["Cash", "Cheque", "Credit Card", "Debit Card"];
  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          transaction_date: new Date().toLocaleDateString("en-CA"),
          transaction_invoice: "",
          transaction_total_amount: 0,
          payment_methord: "Cash",
        }}
        validationSchema={Yup.object().shape({
          transaction_date: Yup.date().required("Date is required"),
          transaction_total_amount: Yup.number()
            .typeError("Enter number only")
            .min(1, "Amount must be greater than zero")
            .required("Amount is Required"),
        })}
        onSubmit={(values) => {
          // if (InvoiceData.length > 0) {
          if (disablesave) {
            setValidationError(true);
            return;
          }
          setDisablebtn(true);
          // setDisablesave(true);
          // setDisablebtn(true);
          console.log(values);
          let date = new Date(values.transaction_date);
          let todayDate = new Date();
          if (date.toLocaleDateString() === todayDate.toLocaleDateString()) {
            date = new Date().getTime();
          } else {
            date = new Date(date).getTime();
          }
          let finalData = {};
          finalData["transaction_total_amount"] =
            values.transaction_total_amount.toString();
          finalData["transaction_invoice"] = InvoiceData;
          finalData["transaction_invoice_id"] = InvoiceData.invoice_id;
          finalData["transaction_created_at"] = date;
          finalData["transaction_modified_at"] = date;
          finalData["transaction_partner"] = InvoiceData.invoice_partner;
          finalData["transaction_currency_code"] = currencyCode;
          finalData["transaction_currency_name"] = currencyName;
          finalData["transaction_payment_method"] = values.payment_methord;
          // finalData["transaction_methord"] = values.payment_methord;
          console.log(finalData);
          mixpanel.track("Save (Add Transaction Web)", { ...finalData });
          createTransaction(finalData);
          // props.createTransaction(finalData);
          // setDisablebtn(false);
          // navigate(-1);
        }}
        onReset={() => {
          navigate("/transaction");
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          handleChange,
          handleReset,
          handleBlur,
          touched,
          validateForm,
        }) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const obj = await validateForm();
              if (Object.keys(obj).length !== 0) {
                setValidationError(true);
              } else {
                handleSubmit();
              }
            }}
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            {/* Card Transaction Details */}
            {/* <Card>
              <Card.Header className="pb-0"> */}
            <p style={{ fontSize: "17px", fontWeight: "500" }}>
              Transaction Details
            </p>
            <FloatingLabel
              controlId="floatingInput"
              label="Date "
              className="mb-3"
            >
              <Form.Control
                type="date"
                className="calendar-input"
                name="transaction_date"
                onBlur={handleBlur}
                placeholder="text"
                value={values.transaction_date}
                onChange={handleChange}
                isInvalid={errors.transaction_date}
              />
              <Form.Control.Feedback type="invalid">
                {touched.transaction_date && errors.transaction_date}
              </Form.Control.Feedback>
            </FloatingLabel>
            <Button
              variant="primary"
              size="lg"
              type="reset"
              onClick={() => {
                // navigate("/pages/invoiceselection");
                mixpanel.track("Add Invoice (Add Transaction Web)");
                setOpenModals(!openModals);
              }}
              className="mb-2 mt-n2"
            >
              {InvoiceData.length === 0
                ? "Add Invoice"
                : `${
                    InvoiceData.invoice_workflow_type === "ORDER"
                      ? "Booking"
                      : InvoiceData.invoice_workflow_type
                          .charAt(0)
                          .toUpperCase() +
                        InvoiceData.invoice_workflow_type.slice(1).toLowerCase()
                  } No.: ${
                    InvoiceData.invoice_number
                  } \u00A0\u00A0\u00A0 Outstanding : ${diff}`}
              <ChevronRight />
            </Button>

            <FloatingLabel
              controlId="floatingInput"
              label="Amount "
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="total amount"
                name="transaction_total_amount"
                value={values.transaction_total_amount}
                onChange={(event) => {
                  if (event.target.value.replace(/\s/g, "").length <= 10) {
                    handleChange(event);
                  }
                }}
                onBlur={handleBlur}
                isInvalid={errors.transaction_total_amount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.transaction_total_amount}
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingSelectGrid"
              label="Payment Methord"
              className="mb-4"
            >
              <Form.Select
                type="text"
                // isInvalid={errors.currency}
                name="payment_methord"
                placeholder="text"
                value={values.payment_methord}
                onChange={handleChange}
                aria-label="Floating label select example"
              >
                {METHORDS.map((methord) => (
                  <option value={methord} key={methord.id}>
                    {methord}
                  </option>
                ))}
              </Form.Select>
              {/* <Form.Control.Feedback type="invalid">
                {errors.business_currency_code}
              </Form.Control.Feedback> */}
            </FloatingLabel>
            {/* </Card.Header>
            </Card> */}
            {/* Call to Action Button*/}
            <Button
              variant="secondary"
              size="lg"
              type="reset"
              onClick={() => {
                mixpanel.track("Cancel (Add Transaction Web)");
                handleReset();
              }}
              className="me-1 mb-1 center"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              // disabled={InvoiceData.length === 0}
              size="lg"
              type="submit"
              className="me-1 mb-1 center"
              disabled={disablebtn}
            >
              Save Transaction
            </Button>
          </Form>
        )}
      </Formik>
      <Modal
        show={validationError}
        onHide={() => setValidationError(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Error
            </h1>
            <div
              onClick={() => {
                setValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                There was an error saving the form. Please check the messages in
                the form and try again.
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setValidationError(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={openModals}
        size="lg"
        backdrop="static"
        onHide={() => setOpenModals(!openModals)}
        centered
      >
        <Modal.Header className="h6">Invoices</Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush" className="text-center">
            <ListGroup.Item>
              <h5>Invoices</h5>
              <div>
                {props.dataInvoice.length === 0 ? (
                  <p>No Invoices</p>
                ) : (
                  <FilteringTable
                    data={props.dataInvoice}
                    setOpenModals={setOpenModals}
                    openModals={openModals}
                    InvoiceData={setInvoiceData}
                  />
                )}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>Estimates</h5>
              <div>
                {props.dataEstimate.length === 0 ? (
                  <p>No Estimates</p>
                ) : (
                  <FilteringTable
                    data={props.dataEstimate}
                    setOpenModals={setOpenModals}
                    openModals={openModals}
                    InvoiceData={setInvoiceData}
                  />
                )}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>Orders</h5>
              <div>
                {props.dataOrder.length === 0 ? (
                  <p>No Orders</p>
                ) : (
                  <FilteringTable
                    data={props.dataOrder}
                    setOpenModals={setOpenModals}
                    openModals={openModals}
                    InvoiceData={setInvoiceData}
                  />
                )}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal> */}
      <Modal
        show={validationError}
        onHide={() => setValidationError(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Error
            </h1>
            <div
              onClick={() => {
                setValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                {InvoiceData.length === 0
                  ? "Invoice is Required. Please specify an invoice for this transaction."
                  : "There was an error saving the form. Please check the messages in the form and try again."}
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setValidationError(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SelectInvoicemodal
        openModals={openModals}
        setOpenModals={setOpenModals}
        setInvoiceData={setInvoiceData}
      />
    </>
  );
};

const TransactionAdd = () => {
  const [token, settoken] = useState();
  const [data, setData] = useState([]);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const navigate = useNavigate();
  const { isTeamAccount } = useAuth();
  console.log("render");
  const createTransaction = async (finalData) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      console.log(finalData);
      fetch("https://bestmate.us/api/transactions/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(finalData),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            console.log(res);
            navigate("/transaction");
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Transaction added successfully",
              ripple: "true",
              dismissible: "true",
            });
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });

            // mixpanel.track(`Fetch Error (Add Transaction Web)`, {
            //   error: "transaction add api",
            // });
            logError({
              error: "fetch error",
              api: "transaction add api",
              component: `Add Transaction Web`,
            });
          }
        })
        .catch((err) => {
          // mixpanel.track(`Error (Add Transaction Web)`, {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: "Add Transaction Web",
          });
          console.log(err);
        });
    }
  };
  const getData = (token) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    fetch(
      `https://bestmate.us/api/invoices/findAll?timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
          // console.log(result);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  useEffect(async () => {
    var user = firebase.auth().currentUser;

    // firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      let tokenss = await user.getIdToken();
      getData(tokenss);
      settoken(tokenss);
    }
    // });
  }, []);
  const dataInvoice = [];
  const dataEstimate = [];
  const dataOrder = [];
  data.filter((elem) => {
    if (elem.invoice_workflow_type === "INVOICE") {
      dataInvoice.push(elem);
    } else if (elem.invoice_workflow_type === "ESTIMATE") {
      dataEstimate.push(elem);
    } else {
      dataOrder.push(elem);
    }
  });
  return (
    <React.Fragment>
      <Helmet title="Transaction" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Add Receipt</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              New Transaction
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/transaction")}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Transactions
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                New Transaction
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "21px",
            }}
          >
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="#676a6c"
                  style={{ marginRight: "2px" }}
                />
                Btn 1
              </div>
            </Link>
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="#676a6c"
                  style={{ marginRight: "2px" }}
                />
                Btn 2
              </div>
            </Link>
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#4f83cb",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  width: "100px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="white"
                  style={{ marginRight: "2px" }}
                />
                Btn 3
              </div>
            </Link>
          </div> */}
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col md={6}>
              <Card className=" border rounded-0">
                <Card.Header>
                  <AddForm
                    dataInvoice={dataInvoice}
                    dataEstimate={dataEstimate}
                    dataOrder={dataOrder}
                    createTransaction={createTransaction}
                  />
                </Card.Header>
                {/* <Card.Body>&nbsp;</Card.Body> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default TransactionAdd;
