export const useCurrencyFormating = () => {
  const currencyFormater = (value, currency, decimal, iskrequired = true) => {
    value = Number(value);
    console.log(value);
    if (value) {
      if (Math.abs(value) >= 1000000000) {
        console.log("jo haa on right path");
        return (
          (value / 1000000000).toLocaleString("en-US", {
            style: "currency",
            currency: currency,
          }) + " B"
        );
      } else {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: currency,
        });
      }
    } else {
      return (0).toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      });
    }
  };

  return { currencyFormater };
};
