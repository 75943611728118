import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  ArrowRight,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Copy,
  Edit,
  Eye,
  X,
} from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Edittimesheetmodal from "../../components/Edittimesheetmodal";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import mixpanel from "mixpanel-browser";
import Newtimesheetmodal from "../../components/Newtimesheetmodal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faCopy,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

const BusinessWebsite = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [websiteData, setWebsiteData] = useState({});
  const [id, setId] = useState("");
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const fetchdata = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/portal/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setWebsiteData(result);
          setLoading(false);
          setId(result.business.business_app_name);
          console.log(result);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    mixpanel.track(`On Business Website (Web)`);

    fetchdata();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Business Website" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      ) : (
        <Container fluid className="p-0">
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor: "white",
              padding: "0rem 1.5rem 2rem 1.5rem",
            }}
            className="pt-2 pb-2 border-bottom "
          >
            <div>
              <h2
                className="mt-3 lead"
                style={{
                  fontSize: "24px",
                  // , fontWeight: 100
                }}
              >
                Business Website
              </h2>
              <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                <Breadcrumb.Item
                  active
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="font-weight-bold">
                  Business Website
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div style={{ marginRight: "21px" }}></div>
          </Card>
          <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
            <Row style={{ display: "flex" }}>
              <Col md={6}>
                <Card
                  className="mb-2 border rounded-0"
                  // style={{ minHeight: 300 }}
                >
                  <Card.Header>
                    <div
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Online Portal for Booking
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div style={{ marginTop: 0 }}>
                      We have created an online portal for your business. Share
                      it with your current clients or prospective leads. Clients
                      can send a request with this portal. An email is sent when
                      the form is submitted and the request shows up in the app.
                    </div>
                    <div style={{ marginTop: 40 }}>
                      {id ? (
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#e8f3fa",
                            padding: 10,
                            borderLeftColor: "#3679E8",
                            borderLeftWidth: 7,
                            borderLeftStyle: "solid",
                            fontSize: 15,
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `https://${id}.bestmate.us/book`,
                              "_blank"
                            );
                          }}
                        >
                          {`https://${id}.bestmate.us/book`}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        style={{
                          marginTop: 30,
                          marginBottom: 20,
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {id ? (
                          <>
                            <button
                              style={{
                                border: "0px solid #2E6ACF",
                                backgroundColor: "transparent",
                                color: "#2E6ACF",
                                // borderRadius: 15,
                                // padding: 10,
                                fontWeight: "bold",
                                // width: 120,
                                marginRight: 10,
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://${id}.bestmate.us/book`
                                );
                                notyf.open({
                                  type: isOpen ? "home" : "full",
                                  message: `Link Copied!`,
                                  ripple: "true",
                                  dismissible: "true",
                                });
                              }}
                            >
                              <span>
                                <Copy
                                  size={18}
                                  strokeWidth={2}
                                  stroke="#2E6ACF"
                                  style={{ cursor: "pointer", marginRight: 5 }}
                                />
                              </span>
                              Copy Link
                            </button>
                            <button
                              style={{
                                border: "0px solid #2E6ACF",
                                backgroundColor: "transparent",
                                color: "#2E6ACF",
                                // borderRadius: 15,
                                // padding: 10,
                                fontWeight: "bold",
                                // width: 120,
                                marginRight: 10,
                              }}
                              onClick={() => {
                                window.open(
                                  `https://${id}.bestmate.us/book`,
                                  "_blank"
                                );
                              }}
                            >
                              <span>
                                <Eye
                                  size={18}
                                  strokeWidth={2}
                                  stroke="#2E6ACF"
                                  style={{ cursor: "pointer", marginRight: 5 }}
                                />
                              </span>
                              View as Client
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                        <Button
                          style={{
                            borderRadius: 15,
                            padding: 10,
                            fontWeight: "bold",
                            width: 180,
                          }}
                          onClick={() => {
                            navigate("/business-website/booking");
                          }}
                        >
                          <Edit
                            size={18}
                            strokeWidth={2}
                            stroke="white"
                            style={{ cursor: "pointer", marginRight: 9 }}
                          />
                          Customize info
                        </Button>
                      </div>
                      <div style={{ float: "right", marginTop: 5 }}>
                        <button
                          style={{
                            border: "0px solid #2E6ACF",
                            backgroundColor: "transparent",
                            color: "#2E6ACF",
                            // borderRadius: 15,
                            // padding: 10,
                            fontWeight: "bold",
                            // width: 120,
                            // marginRight: 10,
                          }}
                          onClick={() => {
                            navigate("/requests");
                          }}
                        >
                          View Incoming Request
                          <span>
                            <ArrowRight
                              size={18}
                              strokeWidth={2}
                              stroke="#2E6ACF"
                              style={{ cursor: "pointer", marginLeft: 5 }}
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card
                  className="mb-2 border rounded-0"
                  // style={{ minHeight: 00 }}
                >
                  <Card.Header>
                    <div
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Business Website for Client & Leads
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div style={{ marginTop: 0 }}>
                      We have created a custom website for your business. Share
                      it with your current clients or prospective leads. Having
                      a website adds professional branding to your company.
                      Clients can also call you, learn more about your services,
                      send a request or book an appointment with the website.
                    </div>
                    <div style={{ marginTop: 10 }}>
                      With the Basic Plan, our team can also support you by
                      connecting your domain directly to this site for an even
                      more branded experience.
                    </div>
                    <div style={{ marginTop: 40 }}>
                      {id ? (
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#e8f3fa",
                            padding: 10,
                            borderLeftColor: "#3679E8",
                            borderLeftWidth: 7,
                            borderLeftStyle: "solid",
                            fontSize: 15,
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(`https://${id}.bestmate.us`, "_blank");
                          }}
                        >
                          {`https://${id}.bestmate.us`}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        style={{
                          marginTop: 30,
                          marginBottom: 20,
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {id ? (
                          <>
                            <button
                              style={{
                                border: "0px solid #2E6ACF",
                                backgroundColor: "transparent",
                                color: "#2E6ACF",
                                // borderRadius: 15,
                                // padding: 10,
                                fontWeight: "bold",
                                // width: 120,
                                marginRight: 10,
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://${id}.bestmate.us`
                                );
                                notyf.open({
                                  type: isOpen ? "home" : "full",
                                  message: `Link Copied!`,
                                  ripple: "true",
                                  dismissible: "true",
                                });
                              }}
                            >
                              <span>
                                <Copy
                                  size={18}
                                  strokeWidth={2}
                                  stroke="#2E6ACF"
                                  style={{ cursor: "pointer", marginRight: 5 }}
                                />
                              </span>
                              Copy Link
                            </button>
                            <button
                              style={{
                                border: "0px solid #2E6ACF",
                                backgroundColor: "transparent",
                                color: "#2E6ACF",
                                // borderRadius: 15,
                                // padding: 10,
                                fontWeight: "bold",
                                // width: 120,
                                marginRight: 10,
                              }}
                              onClick={() => {
                                window.open(
                                  `https://${id}.bestmate.us`,
                                  "_blank"
                                );
                              }}
                            >
                              <span>
                                <Eye
                                  size={18}
                                  strokeWidth={2}
                                  stroke="#2E6ACF"
                                  style={{ cursor: "pointer", marginRight: 5 }}
                                />
                              </span>
                              View as Client
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                        <Button
                          style={{
                            borderRadius: 15,
                            padding: 10,
                            fontWeight: "bold",
                            width: 180,
                          }}
                          onClick={() => {
                            navigate("/business-website/website");
                          }}
                        >
                          <Edit
                            size={18}
                            strokeWidth={2}
                            stroke="white"
                            style={{ cursor: "pointer", marginRight: 9 }}
                          />
                          Customize info
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </React.Fragment>
  );
};
export default BusinessWebsite;
