import React, { useEffect, useState } from "react";

const LocalCountryContext = React.createContext();

export const useLocalCountry = () => React.useContext(LocalCountryContext);

export const LocalCountryProvider = ({ children }) => {
  const [country, setCountry] = useState("US");
  useEffect(() => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const result = await res.json();
        console.log(result);
        setCountry(result.country);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <LocalCountryContext.Provider value={{ country, setCountry }}>
      {children}
    </LocalCountryContext.Provider>
  );
};
