import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Calendar,
  ChevronLeft,
  ChevronRight,
  X,
} from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Edittimesheetmodal from "../../components/Edittimesheetmodal";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import mixpanel from "mixpanel-browser";
import Newtimesheetmodal from "../../components/Newtimesheetmodal";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";

function truncateTextByWordCountLine(text, wordLimit, lineLimit) {
  function getWords(str) {
    return str.split(/\s+/).filter(Boolean);
  }

  function countLines(str) {
    return str.split("\n").length;
  }

  // Handle falsy text values
  if (!text) {
    return "";
  }

  console.log(text);
  const words = getWords(text);
  console.log("Original word count:", words.length);

  let truncatedText = text;
  if (words.length > wordLimit) {
    truncatedText = words.slice(0, wordLimit).join(" ") + "...";
  }

  let lines = truncatedText.split("\n");

  if (lines.length > lineLimit) {
    truncatedText = lines.slice(0, lineLimit).join("\n") + "...";
  }

  console.log("Truncated text:", truncatedText);
  return truncatedText;
}

const Timesheet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clockbtn, showClockbtn] = useState(false);
  const [totalDuration, setTotalDuration] = useState("0:00:00");
  const [showclockin, setShohwclockin] = useState(true);
  const [createnewmodal, setCreatenewmodal] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  //   const [deleteModal, setDeleteModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [btnloading, setBtnloading] = useState(false);
  const [deletemodal, setDeletemodal] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isTeamAccount, userPermission, userData } = useAuth();
  let timesheetPermission =
    Object.keys(userPermission).includes("timesheet") &&
    Object.keys(userPermission.timesheet).includes("permission_key")
      ? userPermission.timesheet.permission_key
      : "";
  const [teamMember, setTeamMember] = useState(userData);
  const [teamMemberList, setTeamMemberList] = useState([]);

  const { isOpen } = useSidebar();
  let durationArr = [];

  function calculateTotalDuration(durations) {
    // Convert duration strings to seconds
    console.log(durations);
    const durationsInSeconds = durations.map((duration) =>
      durationToSeconds(duration)
    );

    // Calculate the total duration in seconds
    const totalSeconds = durationsInSeconds.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    // Convert the total back to the duration format
    const totalDuration = secondsToDuration(totalSeconds);

    return totalDuration;
  }

  function durationToSeconds(duration) {
    const [hours, minutes, seconds] = duration.split(":");
    const totalSeconds =
      Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
    return totalSeconds;
  }

  function secondsToDuration(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const duration = `${hours}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return duration;
  }
  const getData = async (v1) => {
    var user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdToken();
      var v = moment(moment(v1).format("YYYY-MM-DD") + " " + "00:00").valueOf();
      const v2 = moment(v).add(1, "days").valueOf();

      fetch(
        `https://bestmate.us/api/timesheet/findAll/${v}/${v2}` +
          `${isTeamAccount ? "?team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          let newe = result.filter((elem) => {
            return (
              elem.timesheet_start_time >= v && elem.timesheet_start_time < v2
            );
          });
          newe.map((elem) => {
            durationArr.push(
              elem.timesheet_end_time !== 0
                ? calculateDuration(
                    elem.timesheet_start_time,
                    elem.timesheet_end_time
                  )
                : calculateDuration(
                    elem.timesheet_start_time,
                    moment().valueOf()
                  )
            );
          });
          //   console.log(durationArr);
          setTotalDuration(calculateTotalDuration(durationArr));
          if (
            date.toDateString() === new Date().toDateString() &&
            result.some((obj) => obj.timesheet_end_time === 0)
          ) {
            setShohwclockin(false);
          }
          setData(
            newe.sort(function (x, y) {
              return y.timesheet_start_time - x.timesheet_start_time;
            })
          );
          if (timesheetPermission.includes("ALL")) {
            getTeamMemberList();
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  };

  const getTeamMemberList = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdToken();
      const response = await fetch(
        "https://bestmate.us/api/team/get-all-members",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let result = await response.json();
      setTeamMemberList(result);
      // setLoading(false);
    }
  };
  const fetchData = () => {
    setLoading(true);
    showClockbtn(false);
    console.log(
      date.toDateString(),
      new Date().toDateString(),
      date.toDateString() === new Date().toDateString()
    );
    if (date.toDateString() === new Date().toDateString()) {
      showClockbtn(true);
    }
    getData(date);
  };
  useEffect(() => {
    mixpanel.track(`On Timesheet (Web)`);
  }, []);

  useEffect(() => {
    fetchData();
  }, [date]);
  function calculateDuration(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the difference in milliseconds
    const diff = Math.abs(end - start);

    // Convert milliseconds to hours, minutes, and seconds
    let totalSeconds = Math.floor(diff / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Format the duration string
    const duration = `${hours}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return duration;
  }

  const deleteTimesheet = async (deleteData1) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      let deleteData = { timesheet_id: deleteData1.timesheet_id };
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/timesheet/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("response", response);

            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Timesheet deleted successfully",
              ripple: "true",
              dismissible: "true",
            });
            // setLoading(true);
            fetchData();
            setSelected(null);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            setSelected(null);
            // mixpanel.track("Fetch Error (Timesheet List Web)", {
            //   error: "timesheet delete api",
            // });
            logError({
              error: "fetch error",
              api: "timesheet delete api",
              component: `Timesheet List Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setSelected(null);
          // mixpanel.track("Error (Timesheet List Web)", {
          // error: error.message,
          // stack: error.stack,
          // });
          logError({
            error: error.message,
            stack: error.stack,
            component: `Timesheet List Web`,
          });
        });
    }
  };
  const clockin = async () => {
    setBtnloading(true);
    var user = firebase.auth().currentUser;
    if (user) {
      let obj = {
        timesheet_start_time: moment().valueOf(),
      };
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        obj.team = true;
      } else {
        delete obj.team;
      }
      console.log(teamMember);
      if (teamMember && Object.keys(teamMember).length !== 0) {
        obj.timesheet_assignee_uid = teamMember.team_member_uid;
        obj.timesheet_assignee_ref_id = teamMember.team_member_reference_id;
        obj.timesheet_assignee = teamMember;
      }
      fetch("https://bestmate.us/api/timesheet/clock-in", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(obj),
      })
        .then(async (response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("response", response);
            const res = await response.json();
            console.log(res);
            data.unshift(res);
            setShohwclockin(false);

            //   notyf.open({
            //     type: isOpen ? "home" : "full",
            //     message: "Timesheet deleted successfully",
            //     ripple: "true",
            //     dismissible: "true",
            //   });
            setBtnloading(false);
          } else {
            setBtnloading(false);
          }
        })
        .catch((error) => {
          setBtnloading(false);
          console.error(error);
        });
    }
  };

  const clockout = async () => {
    setBtnloading(true);
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      let obj = {
        clock_out_time: moment().valueOf(),
      };
      if (isTeamAccount) {
        obj.team = true;
      } else {
        delete obj.team;
      }
      if (teamMember && Object.keys(teamMember).length !== 0) {
        obj.timesheet_assignee_uid = teamMember.team_member_uid;
        obj.timesheet_assignee_ref_id = teamMember.team_member_reference_id;
        obj.timesheet_assignee = teamMember;
      }
      fetch("https://bestmate.us/api/timesheet/clock-out", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(obj),
      })
        .then(async (response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("clocked out");
            const res = await response.json();
            let datas = data.filter((elem) => elem.timesheet_end_time !== 0);
            setData([res, ...datas]);
            setShohwclockin(true);
            durationArr = [];
            [res, ...datas].map((elem) => {
              durationArr.push(
                elem.timesheet_end_time !== 0
                  ? calculateDuration(
                      elem.timesheet_start_time,
                      elem.timesheet_end_time
                    )
                  : calculateDuration(
                      elem.timesheet_start_time,
                      moment().valueOf()
                    )
              );
            });
            //   console.log(durationArr);
            setTotalDuration(calculateTotalDuration(durationArr));

            setBtnloading(false);
          } else {
            setBtnloading(false);
          }
        })
        .catch((error) => {
          setBtnloading(false);
          console.error(error);
        });
    }
  };
  console.log(location.state);
  return (
    <React.Fragment>
      <Helmet title="Timesheet" />
      <Container fluid className="p-0">
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Timesheet
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Timesheet
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{ marginRight: "21px" }}></div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <div
                    style={{
                      //   backgroundColor: "pink",
                      display: "flex",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: "7px 18px",
                          border: "1px solid #a8a8a8",
                          borderRight: "0px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          //   console.log(date, date.getDate() + 1);
                          //   setDate(date.getDate() + 1);
                          mixpanel.track("Decrement Date (Timesheet Web)");
                          let decrement = moment(new Date(date)).subtract(
                            1,
                            "day"
                          );
                          setDate(new Date(decrement));
                        }}
                      >
                        <ChevronLeft />
                      </div>
                      {/* <div
                        style={{
                          padding: "7px 18px",
                          border: "1px solid #a8a8a8",
                          borderRight: "0px",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        Day
                      </div>
                      <div
                        style={{
                          padding: "7px 18px",
                          border: "1px solid #a8a8a8",
                          borderRight: "0px",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        Week
                      </div> */}
                      <div
                        style={{
                          padding: "7px 18px",
                          border: "1px solid #a8a8a8",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          mixpanel.track("Increment Date (Timesheet Web)");
                          //   console.log(date, date.getDate() + 1);
                          //   setDate(date.getDate() + 1);
                          let increment = moment(new Date(date)).add(1, "day");
                          setDate(new Date(increment));
                        }}
                      >
                        <ChevronRight />
                      </div>
                    </div>
                    <div style={{ display: "flex", marginLeft: 20 }}>
                      <div
                        style={{
                          padding: "7px 18px",
                          border: "1px solid #a8a8a8",
                          borderRight: "0px",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          mixpanel.track("Today (Timesheet Web)");
                          setDate(new Date());
                        }}
                      >
                        Today
                      </div>

                      <DatePicker
                        selected={date}
                        onChange={(date) => {
                          mixpanel.track("Selcet Date (Timesheet Web)", {
                            date,
                          });
                          setDate(date);
                        }}
                        customInput={
                          <div
                            style={{
                              padding: "7px 18px",
                              border: "1px solid #a8a8a8",
                              cursor: "pointer",
                            }}
                          >
                            <Calendar />
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <div
                      style={{
                        // height: 60,
                        backgroundColor: "#F2F2F4",
                        border: "1px solid #DDE6EC",
                        borderBottom: "0px",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          padding: 15,
                          color: "black",
                        }}
                      >
                        {`My hours for ${
                          date.toDateString() === new Date().toDateString()
                            ? "Today"
                            : moment(date).format("MMMM DD, YYYY")
                        }`}
                      </h4>
                    </div>
                    <div
                      style={{
                        border: "1px solid #DDE6EC",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            minHeight: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="dark"
                            className="me-2"
                            // size="md"
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              padding: "20px 15px",
                              borderBottom: "1px solid #DDE6EC",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                color: "black",
                                margin: 0,
                              }}
                            >
                              Total Hours : {totalDuration}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {clockbtn ? (
                                <>
                                  {btnloading ? (
                                    <div
                                      style={{
                                        padding: "4px 10px",
                                        backgroundColor: "#407FEA",
                                        color: "white",
                                        cursor: "pointer",
                                        minWidth: "90px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                      }}
                                    >
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {showclockin ? (
                                        <div
                                          style={{
                                            padding: "4px 10px",
                                            backgroundColor: "#407FEA",
                                            color: "white",
                                            cursor: "pointer",
                                            minWidth: "90px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => {
                                            mixpanel.track(
                                              "Clock In (Timesheet Web)"
                                            );
                                            clockin();
                                          }}
                                        >
                                          Clock In
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            padding: "4px 10px",
                                            backgroundColor: "#407FEA",
                                            color: "white",
                                            cursor: "pointer",
                                            minWidth: "90px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => {
                                            mixpanel.track(
                                              "Clock Out (Timesheet Web)"
                                            );
                                            clockout();
                                          }}
                                        >
                                          Clock Out
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              <div
                                style={{
                                  padding: "4px 10px",
                                  backgroundColor: "#407FEA",
                                  color: "white",
                                  cursor: "pointer",
                                  minWidth: "90px",
                                  display: "flex",
                                  marginLeft: 10,
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  mixpanel.track("Add Time (Timesheet Web)");
                                  setCreatenewmodal(true);
                                }}
                              >
                                Add Time
                              </div>
                            </div>
                          </div>
                          <>
                            {data.length !== 0 ? (
                              <>
                                {data.map((elem) => {
                                  if (elem.timesheet_end_time === 0) {
                                    return (
                                      <div
                                        style={{
                                          padding: "20px 15px",
                                          borderBottom: "1px solid #DDE6EC",
                                          backgroundColor: "#f0f6fa",
                                        }}
                                      >
                                        <Row>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                textAlign: "center",
                                                whiteSpace: "pre-line",
                                                width: "100%",

                                                wordWrap: "break-word",

                                                marginBottom: 0,
                                              }}
                                            >
                                              {/* {elem.timesheet_name &&
                                              elem.timesheet_name.length > 120
                                                ? elem.timesheet_name.substring(
                                                    0,
                                                    120
                                                  ) + " ..."
                                                : elem.timesheet_name} */}
                                              {truncateTextByWordCountLine(
                                                elem.timesheet_name,
                                                120,
                                                6
                                              )}
                                            </p>
                                          </Col>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {`Started at ${moment(
                                                new Date(
                                                  Number(
                                                    elem.timesheet_start_time
                                                  )
                                                )
                                              ).format("hh:mm A")}
                                           `}
                                            </div>
                                            <div>
                                              {timesheetPermission.includes(
                                                "ALL"
                                              ) &&
                                              elem.timesheet_assignee &&
                                              elem.timesheet_assignee
                                                .team_member_name ? (
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    marginTop: 3,
                                                    color: "#424040",
                                                  }}
                                                >
                                                  Time entry of{" "}
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {elem.timesheet_assignee &&
                                                    elem.timesheet_assignee
                                                      .team_member_name
                                                      ? elem.timesheet_assignee
                                                          .team_member_name
                                                      : ""}
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </Col>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              {calculateDuration(
                                                elem.timesheet_start_time,
                                                Number(moment().valueOf())
                                              )}
                                            </div>
                                          </Col>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "end",
                                              alignItems: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                // alignItems: "flex-end",
                                              }}
                                            ></div>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        style={{
                                          padding: "20px 15px",
                                          borderBottom: "1px solid #DDE6EC",
                                        }}
                                      >
                                        <Row>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                textAlign: "center",
                                                whiteSpace: "pre-line",
                                                width: "100%",

                                                wordWrap: "break-word",

                                                marginBottom: 0,
                                              }}
                                            >
                                              {/* {elem.timesheet_name &&
                                              elem.timesheet_name.length > 120
                                                ? elem.timesheet_name.substring(
                                                    0,
                                                    120
                                                  ) + " ..."
                                                : elem.timesheet_name} */}
                                              {truncateTextByWordCountLine(
                                                elem.timesheet_name,
                                                120,
                                                6
                                              )}
                                            </p>
                                          </Col>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {`${moment(
                                                new Date(
                                                  Number(
                                                    elem.timesheet_start_time
                                                  )
                                                )
                                              ).format("hh:mm A")} -
                                            ${moment(
                                              Number(elem.timesheet_end_time)
                                            ).format("hh:mm A")}`}
                                            </div>
                                            <div
                                              style={{
                                                whiteSpace: "pre-line",
                                                width: "100%",
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              {timesheetPermission.includes(
                                                "ALL"
                                              ) &&
                                              elem.timesheet_assignee &&
                                              elem.timesheet_assignee
                                                .team_member_name ? (
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    marginTop: 3,
                                                    color: "#424040",
                                                    whiteSpace: "pre-line",
                                                    width: "100%",
                                                    wordWrap: "break-word",
                                                  }}
                                                >
                                                  Time entry of{" "}
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      whiteSpace: "pre-line",
                                                      width: "100%",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    {elem.timesheet_assignee &&
                                                    elem.timesheet_assignee
                                                      .team_member_name
                                                      ? elem.timesheet_assignee
                                                          .team_member_name
                                                          .length > 120
                                                        ? elem.timesheet_assignee.team_member_name.substring(
                                                            0,
                                                            120
                                                          ) + " ..."
                                                        : elem
                                                            .timesheet_assignee
                                                            .team_member_name
                                                      : ""}
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </Col>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              {calculateDuration(
                                                elem.timesheet_start_time,
                                                elem.timesheet_end_time
                                              )}
                                            </div>
                                          </Col>
                                          <Col
                                            md={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "end",
                                              alignItems: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                // alignItems: "flex-end",
                                              }}
                                            >
                                              {/* <div
                                                style={{
                                                  padding: "4px 14px",
                                                  borderWidth: "0.5px",
                                                  borderStyle: "solid",
                                                  borderColor: "black",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  mixpanel.track(
                                                    "Edit (Timesheet Web)",
                                                    { ...elem }
                                                  );
                                                  setSelected(elem);
                                                  setEditmodal(true);
                                                }}
                                              >
                                                Edit
                                              </div> */}
                                              <ButtonGroup>
                                                <Button
                                                  size="md"
                                                  variant="light"
                                                  style={{
                                                    backgroundColor: "white",
                                                  }}
                                                  className="border"
                                                  onClick={() => {
                                                    mixpanel.track(
                                                      "Edit (Timesheet Web)",
                                                      { ...elem }
                                                    );
                                                    setSelected(elem);
                                                    setEditmodal(true);
                                                  }}
                                                >
                                                  Edit
                                                </Button>
                                                <Button
                                                  size="md"
                                                  variant="light"
                                                  style={{
                                                    backgroundColor: "white",
                                                  }}
                                                  className="border"
                                                  onClick={() => {
                                                    mixpanel.track(
                                                      "Delete (Timesheet Web)",
                                                      { ...elem }
                                                    );
                                                    setSelected(elem);
                                                    setDeletemodal(true);
                                                  }}
                                                >
                                                  Delete
                                                </Button>
                                              </ButtonGroup>
                                              {/* <div
                                            style={{
                                              marginLeft: "10px",
                                              padding: "4px 14px",
                                              backgroundColor: "#407FEA",
                                              color: "white",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setSelected(elem);
                                              setDeleteModal(true);
                                            }}
                                          >
                                            Delete
                                          </div> */}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                })}
                              </>
                            ) : (
                              <>
                                <p
                                  style={{
                                    minHeight: "200px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  No Time Tracked
                                </p>
                              </>
                            )}
                          </>
                        </>
                      )}
                    </div>
                  </div>
                </Card.Header>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <Edittimesheetmodal
        createnewmodal={editmodal}
        setCreatenewmodal={setEditmodal}
        setLoading={setLoading}
        selected={selected}
        setSelected={setSelected}
        fetchData={fetchData}
        teamMemberList={teamMemberList}
      />
      <Newtimesheetmodal
        createnewmodal={createnewmodal}
        setCreatenewmodal={setCreatenewmodal}
        setLoading={setLoading}
        selecteddate={date}
        fetchData={fetchData}
        teamMemberList={teamMemberList}
      />
      <Modal
        show={deletemodal}
        onHide={() => {
          setSelected(null);
          setDeletemodal(false);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Delete Timesheet
            </h1>
            <div
              onClick={() => {
                setSelected(null);
                setDeletemodal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0 20px", textAlign: "center" }}>
                Are you sure you want to delete this timesheet?
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: 5,
                  padding: "5px 10px",
                  backgroundColor: "white",
                  borderWidth: 1,
                }}
                onClick={() => {
                  setSelected(null);

                  setDeletemodal(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setDeletemodal(false);
                  deleteTimesheet(selected);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default Timesheet;
