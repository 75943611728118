import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Button, Breadcrumb } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { THEME } from "../../constants";
import useTheme from "../../hooks/useTheme";
import mixpanel from "mixpanel-browser";

const themeOptions = [
  {
    name: "Light",
    value: THEME.LIGHT,
  },
  {
    name: "Colored 1",
    value: THEME.DEFAULT,
  },
  {
    name: "Colored 2",
    value: THEME.COLORED,
  },
  // {
  //   name: "Dark",
  //   value: THEME.DARK,
  // },
];

const Settings = () => {
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const setSettingByQueryParam = (name, set) => {
    const value = query.get(name);
    if (value) {
      set(value);
    }
  };

  // Read from query parameter (e.g. ?theme=dark)
  // only for demo purposes
  useEffect(() => {
    mixpanel.track(`On Settings (Web)`);
    setSettingByQueryParam("theme", setTheme);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(location.state);
  return (
    <React.Fragment>
      <Helmet title="Settings Page" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Settings</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Settings
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item active>Invoice</Breadcrumb.Item> */}
              <Breadcrumb.Item active className="font-weight-bold">
                Settings
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            {/* <Link to="/pricelistadd">
              <div
                style={{
                  border: "1px #E9EAEC solid",
                  borderRadius: "3px",
                  padding: "4px 7px",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  textColor: "#666B6C",
                  alignItems: "center",
                  textDecoration: "none",
                  margin: "3px",
                  cursor: "pointer",
                }}
              >
                <Plus
                  size={15}
                  stroke="black"
                  style={{ marginRight: "10px" }}
                />
                Add
              </div>
            </Link> */}
          </div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Card.Title className="mb-0">Theme</Card.Title>
                  <Card.Body>
                    <div className="row g-0 text-center mx-n1 mb-2">
                      {themeOptions.map(({ name, value }) => (
                        <div className="col-3" key={value}>
                          <label className="mx-1 d-block mb-1">
                            <input
                              className="settings-scheme-label"
                              type="radio"
                              name="theme"
                              value={value}
                              checked={theme === value}
                              onChange={() => {
                                mixpanel.track("Theme (Settings Web)", {
                                  name,
                                });
                                setTheme(value);
                              }}
                            />
                            <div className="settings-scheme">
                              <div
                                className={`settings-scheme-theme settings-scheme-theme-${value}`}
                              ></div>
                            </div>
                          </label>
                          {name}
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                  <hr />
                  <Card.Title>
                    Online Payments
                    <Button
                      style={{ marginLeft: 20 }}
                      onClick={() => {
                        mixpanel.track("Online Payments (Settings Web)");
                        navigate("/payments");
                      }}
                    >
                      Online Payments
                    </Button>
                  </Card.Title>
                </Card.Header>
                {/* <Card.Body>&nbsp;</Card.Body> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default Settings;
