import React, { useState } from "react";
import { ArrowUp } from "react-feather";

const ScrolltoTop = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1400) {
      setVisible(true);
    } else if (scrolled <= 1400) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <div style={{ display: visible ? "inline" : "none" }}>
      <div
        style={{
          position: "fixed",
          bottom: "120px",
          zIndex: 10,
          right: 30,
          boxShadow: "-5px 0px 10px 0px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          height: "55px",
          width: "55px",
          backgroundColor: "black",
          borderRadius: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={scrollToTop}
      >
        <ArrowUp size={25} strokeWidth={3} color={"white"} />
      </div>
    </div>
  );
};

export default ScrolltoTop;
