import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Delete, Trash, UploadCloud, X } from "react-feather";
import imageCompression from "browser-image-compression";
import s3 from "../aws-config";
import { v4 } from "uuid";

const UploadHelper = (props) => {
  const [uploadError, setUploadError] = useState("");
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  // let abortController;
  var uploadRequest = null;
  const uploadToCloud = async (compressedFile) => {
    if (compressedFile == null) return;
    // abortController = new AbortController(); // Create AbortController
    // const signal = abortController.signal; // Get signal from AbortController
    const bucketName = "bestmate-attachments";
    const key = `website/${v4()}${compressedFile.name}`; // S3 object key

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: compressedFile,
      ContentType: compressedFile.type,
      // Signal: signal,
    };
    uploadRequest = s3.upload(params);
    try {
      // const bucketName = "bestmate";

      let response = await uploadRequest.promise();
      console.log(response, response.status);

      if (props.type === "hero") {
        props.setWebsiteData((old) => {
          return { ...old, hero_image: response.Location };
        });
      } else if (props.type === "about") {
        props.setWebsiteData((old) => {
          let oldabout =
            Object.keys(old).includes("about_us") &&
            Object.keys(old.about_us).length !== 0
              ? old.about_us
              : {};
          console.log(oldabout);
          console.log("))))((((");
          return {
            ...old,
            about_us: { ...oldabout, image: response.Location },
          };
        });
      } else if (props.type === "team") {
        props.setWebsiteData((old) => {
          return {
            ...old,
            image: response.Location,
          };
        });
      } else if (props.type === "image") {
        props.setWebsiteData((old) => {
          return {
            image: response.Location,
          };
        });
      }
      setUploadStatus("");
    } catch (err) {
      // if (err.name === "AbortError") {
      //   console.log("Upload cancelled");
      // } else {
      console.log(err);
      setUploadStatus("");
      setUploadError("Something went Wrong!");
      // }
    }
  };

  // const cancelUpload = () => {
  //   abortController.abort(); // Abort the upload
  //   setUploadStatus(""); // Reset upload status
  //   setUploadError("Upload cancelled"); // Set error message
  // };
  const handleCancel = () => {
    console.log(uploadRequest);
    if (uploadRequest) {
      uploadRequest.abort();
      setUploadStatus("");
      setUploadError("Something went Wrong!");
    }
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadStatus ? true : false,
    accept: { "image/*": [] },
    // maxFiles: 3 - imageData.length,
    maxFiles: 1,
    onDrop: async (acceptedFiles, error) => {
      console.log(error, acceptedFiles);
      if (error.length !== 0) {
        setUploadError(error[0].errors[0].message);
      } else {
        setUploadError("");
        try {
          acceptedFiles.map(async (elem) => {
            // let elem = acceptedFiles[0];
            if (elem.type.includes("image")) {
              const compressedFile = await imageCompression(elem, {
                maxSizeMB: 10,
                maxWidthOrHeight: 2000,
              });
              if (elem.size > 10485760) {
                setUploadError("Select File Size upto 10 Mb");
                return;
              }
              setUploadStatus("Start");
              return uploadToCloud(compressedFile);
              // console.log(elem, compressedFile);
            } else {
              if (elem.size > 10485760) {
                setUploadError("Select File Size upto 10 Mb");
                return;
              }
              setUploadStatus("Start");
              return uploadToCloud(elem);
            }
          });
        } catch (err) {
          console.log(err);
          setUploadStatus("");
          setUploadError("Something went Wrong!");
        }
        //extract files
        //compress if image
        //upload to cloud
      }
    },
  });
  return (
    // <div
    //   style={{
    //     width: 250,
    //     height: 150,
    //     backgroundColor: "#f7f7f7",
    //     borderRadius: 5,
    //   }}
    // ></div>
    <section
    //   style={{
    //     width: 250,
    //     height: 150,
    //     backgroundColor: "#f7f7f7",
    //     borderRadius: 5,
    //   }}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          style={{
            width: 250,
            height: 150,
            backgroundColor: "#f7f7f7",
            borderRadius: 5,
            border: "1px dashed #c9c8c7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            cursor: "pointer",
          }}
        >
          {uploadStatus ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              onClick={() => handleCancel()}
            >
              <Spinner
                animation="border"
                // variant="dark"
                size="md"
                style={{ color: "#407FEA" }}
              />
              <div style={{ marginTop: 20 }}>Uploading</div>
            </div>
          ) : (
            <>
              <UploadCloud
                size={35}
                strokeWidth={2}
                stroke={"#407FEA"}
                style={{ marginRight: 15 }}
              />
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                <span>Add Image</span>
              </div>
              <div>Maximum 10MB in size.</div>
            </>
          )}
          {uploadError ? (
            <div
              style={{
                color: "red",
                fontSize: 11,
                marginTop: 10,
              }}
            >
              {uploadError}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* <div
          onClick={() => {
            cancelUpload();
          }}
        >
          cancel
        </div> */}
      </div>
    </section>
  );
};
export default UploadHelper;
