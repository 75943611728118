export const useSumwithDiscountAndTax = () => {
  const sumCalculation = (sum, invoice) => {
    return (
      Number(sum).toFixed(2) -
      Number(
        invoice.invoice_discount_type &&
          invoice.invoice_discount_type === "absolute"
          ? Number(invoice.invoice_discount_rate).toFixed(2)
          : Number(sum * invoice.invoice_discount_rate).toFixed(2)
      ) +
      Number(
        invoice.invoice_tax_type && invoice.invoice_tax_type === "absolute"
          ? Number(invoice.invoice_tax_rate).toFixed(2)
          : (
              Number(
                invoice.invoice_discount_type &&
                  invoice.invoice_discount_type === "absolute"
                  ? sum - invoice.invoice_discount_rate
                  : sum * (1 - invoice.invoice_discount_rate)
              ) * invoice.invoice_tax_rate
            ).toFixed(2)
      )
    );
  };

  return { sumCalculation };
};
