import React, { useCallback, useContext, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Modal,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { debounce } from "lodash";

import { useTable, useGlobalFilter } from "react-table";
// import { COLUMNS } from "./column";
import { GlobalFilter } from "./GlobalFilter";
import { useEffect, useState } from "react";
import { Plus, PlusSquare, X } from "react-feather";
// import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import {
  useSearchParams,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import InvoiceDetail from "./InvoiceDetail";
import empty_invoice from "../../assets/img/icons/empty_invoice.png";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import { useSumwithDiscountAndTax } from "../../utils/useSumwithDiscountandTax";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";

// import InputComponent from "./InvoiceDetail";

const FilteringTable = (props) => {
  // const { usercurrency } = useAuth();
  // const navigate = useNavigate();
  let InvoiceTotal = 0;
  const navigate = useNavigate();
  const location = useLocation();
  const { sumCalculation } = useSumwithDiscountAndTax();
  let usercurrency = localStorage.getItem("currency") || "USD";
  const workingdata = props.productData;
  const [openModals, setOpenModals] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [deleteData, setDeletedata] = useState({});
  const { currencyFormater } = useCurrencyFormating();
  const { userPermission } = useAuth();
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";

  // const Data = props.dataa;
  // const customerData = Data.filter((elem) => elem.partner_type === "CUSTOMER");

  // console.log(props.dataa);
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  const COLUMNS = [
    {
      Header: "Estimate #",
      width: 100,
      accessor: "invoice_number",
      Cell: ({ value }) => {
        return <div style={{}}> {truncateTextByWordCount(`${value}`, 15)}</div>;
      },
    },
    {
      Header: "Date",
      width: 140,

      accessor: (elem) =>
        `${moment(new Date(elem.invoice_created_at)).format("DD MMM YYYY")}`,
    },
    {
      width: 180,

      Header: "Name",
      accessor: (elem) =>
        `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,

      Cell: ({ row, value }) => {
        return <span>{truncateTextByWordCount(value, 20)}</span>;
      },
    },
    // {
    //   Header: "Partner Type",
    //   accessor: "",
    //   Cell: ({ row }) => {
    //     if (row.original.invoice_partner_type === "CUSTOMER") {
    //       return <span className="text-success">Customer</span>;
    //     } else if (row.original.invoice_partner_type === "SUPPLIER") {
    //       return <span className="text-danger">Supplier</span>;
    //     } else {
    //       return <span classname="text-primary">Dont know</span>;
    //     }
    //   },
    // },
    {
      Header: "Company Name",
      accessor: "",
      width: 200,
      Cell: ({ row }) => {
        if (row.original.invoice_partner.partner_company_name) {
          return (
            <span>
              {truncateTextByWordCount(
                row.original.invoice_partner.partner_company_name,
                20
              )}
            </span>
          );
        } else {
          return "Individual";
        }
      },
    },
    {
      // Header: "Estimate Total",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Estimate Total
        </div>
      ),
      width: 120,
      accessor: "estimatetotal",
      Cell: ({ value, row }) => {
        // const value = row.original.
        let invoice = row.original;
        var sum = 0;
        var price_list = 1;
        if (
          invoice &&
          invoice.invoice_partner &&
          invoice.invoice_partner.partner_price_list &&
          invoice.invoice_partner.partner_price_list.price_list_multiplier &&
          !isNaN(
            invoice.invoice_partner.partner_price_list.price_list_multiplier
          )
        ) {
          price_list = Number(
            invoice.invoice_partner.partner_price_list.price_list_multiplier
          );
        }
        for (var i = 0; i < invoice.invoice_order.length; i++) {
          if (
            invoice.invoice_partner_type === "CUSTOMER" ||
            invoice.invoice_partner_type === "LEAD"
          ) {
            sum =
              sum +
              (invoice.invoice_order[i].item_quantity
                ? Number(invoice.invoice_order[i].item_quantity)
                : 1) *
                (invoice.invoice_order[i].item_selling_price &&
                !isNaN(invoice.invoice_order[i].item_selling_price)
                  ? Number(invoice.invoice_order[i].item_selling_price)
                  : 0) *
                (price_list && !isNaN(price_list) ? price_list : 1);
            console.log(sum);
          } else {
            sum =
              sum +
              (invoice.invoice_order[i].item_quantity
                ? Number(invoice.invoice_order[i].item_quantity)
                : 1) *
                (invoice.invoice_order[i].item_cost_price &&
                !isNaN(invoice.invoice_order[i].item_cost_price)
                  ? Number(invoice.invoice_order[i].item_cost_price)
                  : 0);
          }
        }
        var sumWithDiscountAndTax = sumCalculation(sum, invoice);
        // Number(sum).toFixed(2) -
        // Number(
        //   invoice.invoice_discount_type &&
        //     invoice.invoice_discount_type === "absolute"
        //     ? Number(invoice.invoice_discount_rate).toFixed(2)
        //     : Number(sum * invoice.invoice_discount_rate).toFixed(2)
        // ) +
        // Number(
        //   invoice.invoice_tax_type && invoice.invoice_tax_type === "absolute"
        //     ? Number(invoice.invoice_tax_rate).toFixed(2)
        //     : (
        //         Number(
        //           invoice.invoice_discount_type &&
        //             invoice.invoice_discount_type === "absolute"
        //             ? sum - invoice.invoice_discount_rate
        //             : sum * (1 - invoice.invoice_discount_rate)
        //         ) * invoice.invoice_tax_rate
        //       ).toFixed(2)
        // );
        //   (sum - sum * invoice.invoice_discount_rate) *
        //   (1 + invoice.invoice_tax_rate);
        // InvoiceTotal = sumWithDiscountAndTax;
        return (
          <div style={{ textAlign: "right" }}>
            {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
              style: "currency",
              currency:
                invoice && invoice.invoice_currency_code
                  ? invoice.invoice_currency_code
                  : usercurrency,
            })} */}
            {truncateTextByWordCount(
              currencyFormater(
                sumWithDiscountAndTax,
                invoice && invoice.invoice_currency_code
                  ? invoice.invoice_currency_code
                  : usercurrency
              ),
              30
            )}
          </div>
        );
        // return <div>{sumwith}</div>;
      },
    },
    // {
    //   // Header: " Pending",
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "right",
    //       }}
    //     >
    //       Pending
    //     </div>
    //   ),
    //   width: 120,
    //   accessor: "pending",
    //   // accessor: (elem) => elem.invoice_settled_amount,
    //   // elem.invoice_grand_total - elem.invoice_settled_amount,
    //   Cell: ({ row }) => {
    //     const value = row.original.invoice_settled_amount;
    //     const num = InvoiceTotal - parseInt(value);
    //     if (Number.isNaN(num)) {
    //       return <div style={{ textAlign: "right" }}>{"N/A"}</div>;
    //     } else {
    //       return (
    //         <div style={{ textAlign: "right" }}>
    //           {num.toLocaleString("en-US", {
    //             style: "currency",
    //             currency: usercurrency,
    //           })}
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      // Header: "Status",
      Header: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // textAlign: "center",
          }}
        >
          Status
        </div>
      ),
      accessor: "status",
      // width: 200,
      Cell: ({ row }) => {
        // console.log(InvoiceTotal, row.values);
        if (row.original.invoice_status === "ACCEPTED") {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#1AB395",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: 65,
                  textAlign: "center",
                }}
              >
                Accepted
              </div>
            </div>
          );
        } else if (row.original.invoice_status === "CONVERTED") {
          if (
            row.original.invoice_children &&
            row.original.invoice_children.some(
              (e) => e.invoice_workflow_type === "INVOICE"
            )
          ) {
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    // backgroundColor: "#1a7bb8",
                    backgroundColor: "#3f80ea",
                    padding: "4px",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: 65,
                    textAlign: "center",
                  }}
                >
                  Invoiced
                </div>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#71BE1D",
                    padding: "4px",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    // width: 65,
                    textAlign: "center",
                  }}
                >
                  Booking Created
                  {/* Converted to Job */}
                </div>
              </div>
            );
          }
        } else if (row.original.invoice_status === "OPEN") {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#DE5474",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: 65,
                  textAlign: "center",
                }}
              >
                Open
              </div>
            </div>
          );
        }
      },
    },
    {
      // Header: "Actions",
      width: 140,
      Header: () => (
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        // const num = parseInt(value);
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ButtonGroup>
              <Button
                size="sm"
                variant="light"
                style={{ backgroundColor: "white" }}
                className="border"
                onClick={(e) => {
                  e.stopPropagation();
                  mixpanel.track(
                    "Individual Estimate Row (Estimate List Web)",
                    {
                      ...row.original,
                    }
                  );
                  navigate("/estimates/" + row.original.invoice_id, {
                    state: { row: row.original, items: props.items },
                  });
                }}
              >
                View
              </Button>
              {location.state != null && location.state.from.includes("add") ? (
                <></>
              ) : (
                <>
                  {estimatePermission.includes("EDIT") ? (
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        mixpanel.track(
                          "Individual Estimate Row Edit (Estimate List Web)",
                          {
                            ...row.original,
                          }
                        );
                        navigate(`/estimates/edit/` + row.original.invoice_id, {
                          state: row.original,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <></>
                  )}
                  {estimatePermission.includes("DELETE") ? (
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        mixpanel.track(
                          "Individual Estimate Row Delete (Estimate List Web)",
                          {
                            id: row.original.invoice_id,
                          }
                        );
                        setDeletedata({
                          invoice_id: row.original.invoice_id,
                          invoice_number: row.original.invoice_number,
                        });
                        setOpenModals(true);
                      }}
                    >
                      Delete
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  // console.log(dataa);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => workingdata);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  // const { globalFilter } = state;

  return (
    <>
      <InfiniteScroll
        dataLength={rows.length}
        next={
          !props.searchtable
            ? () => props.update(props.token, props.id)
            : () =>
                // props.setSearchdata([]);
                props.update(
                  props.searchname,
                  props.searchnumb,
                  props.searchtotal,
                  props.searchtype,
                  props.token,
                  props.id,
                  props.signal
                )
        }
        hasMore={props.hasmore}
        style={{ overflowX: "scroll", overflowY: "hidden" }}
        loader={
          <div className="d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="me-2"
            />
          </div>
        }
      >
        <Table
          striped
          bordered
          size="sm"
          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => {
                    // alert("Edit action performed on " + row.values.price_list_name);
                    // console.log(row);
                    // props.show(true);
                    // navigate("/estimates/" + row.original.invoice_id);
                    // props.setSearchParams({ id: row.original.invoice_id });
                    // props.rowData(row);
                    // props.rowData(row);
                    mixpanel.track(
                      "Individual Estimate Row (Estimate List Web)",
                      {
                        ...row.original,
                      }
                    );
                    navigate("/estimates/" + row.original.invoice_id, {
                      state: { row: row.original, items: props.items },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          color: "black",
                          fontSize: "12.6px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
      {workingdata.length === 0 ? (
        props.searchloading ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center p-3 flex-column"
            id={"Emptybox"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (estimatePermission.includes("ADD")) {
                mixpanel.track("Add Estimate (Estimate List Web)");
                navigate("/estimates/add");
              }
            }}
          >
            {!props.searchtable ? (
              <p
                // className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                {/* No Invoice Added. Start by Clicking On Add Invoice */}
                No estimate added
              </p>
            ) : (
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                {estimatePermission.includes("ADD")
                  ? "No Result Found. Click to Add Estimate"
                  : "No Result Found"}
              </p>
            )}
            <img
              src={empty_invoice}
              style={{ height: "80px", marginTop: 10, marginBottom: 20 }}
              alt="empty_invoice"
            ></img>

            {estimatePermission.includes("ADD") ? (
              <Button
                onClick={() => {
                  mixpanel.track("Add Estimate (Estimate List Web)");
                  navigate("/estimates/add");
                }}
                style={{ padding: 10, fontWeight: "bold" }}
              >
                Add Estimate
              </Button>
            ) : (
              <></>
            )}
          </div>
        )
      ) : (
        <></>
      )}
      {/* <Modal
        show={openModals}
        onHide={() => setOpenModals(!openModals)}
        centered
      >
        <Modal.Header className="font-weight-bold" closeButton>
          Delete Estimate
        </Modal.Header>
        <Modal.Body className="text-left m-3">
          <p className="mb-0 font-weight-bold">
            Are you sure you want to delete {deleteData["invoice_number"]}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenModals(!openModals)}
          >
            Cancel
          </Button>{" "}
          <Button
            variant="danger"
            onClick={() => {
              setOpenModals(!openModals);
              props.deleteRecord(deleteData);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={openModals}
        onHide={() => setOpenModals(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Delete Estimate
            </h1>
            <div
              onClick={() => {
                setOpenModals(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0px 20px", textAlign: "center" }}>
                Are you sure you want to delete Estimate #
                {deleteData["invoice_number"]}?
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: 5,
                  padding: "5px 10px",
                  backgroundColor: "white",
                  borderWidth: 1,
                }}
                onClick={() => setOpenModals(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setOpenModals(false);
                  props.deleteRecord(deleteData);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Estimate = () => {
  const controller = new AbortController();
  const signal = controller.signal;

  const [token, settoken] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [id, setId] = useState(0);
  const [hasmore, sethasmore] = useState(true);

  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchname, setSearchname] = useState("");
  const [tempsearchname, setTempsearchname] = useState("");

  const [searchnumb, setSearchnumb] = useState("");
  const [tempsearchnumb, setTempsearchnumb] = useState("");

  const [searchtotal, setSearchtotal] = useState("");
  const [tempsearchtotal, setTempsearchtotal] = useState("");

  const [searchtype, setSearchtype] = useState("");
  const [tempsearchtype, setTempsearchtype] = useState("");
  const [searchhasmore, setSearchhasmore] = useState(true);
  const [searchid, setSearchid] = useState(0);
  const [searchloading, setsearchloading] = useState(true);
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const { isTeamAccount, userPermission } = useAuth();
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  const deleteRecord = async (deleteData) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/invoices/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log(response);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Estimate deleted successfully",
              ripple: "true",
              dismissible: "true",
            });

            setLoading(true);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track(
            //   "Fetch Error (Individual Estimate Row Delete >>> Estimate List Web)",
            //   {
            //     error: "invoice delete api",
            //   }
            // );
            logError({
              error: "fetch error",
              api: "invoice delete api",
              component: `Individual Estimate Row Delete >>> Estimate List Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // mixpanel.track(
          //   "Fetch Error (Individual Estimate Row Delete >>> Estimate List Web)",
          //   {
          //     error: error,
          //   }
          // );
          logError({
            error: error,
            component: `Individual Estimate Row Delete >>> Estimate List Web`,
          });
        });
    }
    // });
  };

  const getData = (token, id) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    fetch(
      `https://bestmate.us/api/invoices/findAllPaginated/ESTIMATE/${id}?timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setData((old) => [...old, ...result]);
        setLoading(false);
        // sethasmore(false);

        if (result.length === 0 || result.length <= 10) {
          sethasmore(false);
        } else if (result.length > 10) {
          setId(result[result.length - 1].invoice_modified_at);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const searchData = async (
    searchname,
    searchnumb,
    searchtotal,
    searchtype,
    token,
    searchid,
    signal
  ) => {
    console.log(searchid);
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    fetch(
      `https://bestmate.us/api/invoices/searchMix/ESTIMATE/${searchid}?number=${searchnumb}&status=${searchtotal}&name=${searchname}&partner_type=${searchtype}&timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        signal,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setSearchdata((old) => [...old, ...result]);
        setsearchloading(false);
        if (result.length === 0 || result.length <= 10) {
          setSearchhasmore(false);
        } else if (result.length > 10) {
          console.log(result[result.length - 1].invoice_modified_at);
          console.log("-----");
          setSearchid(result[result.length - 1].invoice_modified_at);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    mixpanel.track(`On Estiamte List (Web)`);
  }, []);

  useEffect(() => {
    setsearchloading(true);
    setSearchdata([]);
    setSearchid(0);
    showSearchtable(false);
    if (
      searchname !== "" ||
      searchnumb !== "" ||
      searchtotal !== "" ||
      searchtype !== ""
    ) {
      showSearchtable(true);
      searchData(
        searchname,
        searchnumb,
        searchtotal,
        searchtype,
        token,
        searchid,
        signal
      );
    }
  }, [searchname, searchnumb, searchtotal, searchtype]);

  useEffect(async () => {
    if (loading) {
      var user = firebase.auth().currentUser;
      // firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let tokenss = await user.getIdToken();
        settoken(tokenss);
        setData([]);
        setId(0);
        sethasmore(true);

        showSearchtable(false);
        setSearchdata([]);
        setSearchname("");
        setTempsearchname("");

        setSearchnumb("");
        setTempsearchnumb("");

        setSearchtotal("");
        setTempsearchtotal("");

        setSearchtype("");
        setTempsearchtype("");

        setSearchhasmore(true);
        setSearchid(0);
        setsearchloading(true);
        getData(tokenss, 0);
      }
      // });
    }
  }, [loading]);

  const deb0 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Name (Estimate List Web)", { searchterm: value });
      setSearchname(value);
    }, 1000),
    []
  );
  const handleSearch0 = (value) => {
    deb0(value);
  };

  const deb1 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Estimate Number (Estimate List Web)", {
        searchterm: value,
      });

      setSearchnumb(value);
    }, 1000),
    []
  );
  const handleSearch1 = (value) => {
    deb1(value);
  };

  const deb2 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Status (Estimate List Web)", {
        searchterm: value,
      });
      setSearchtotal(value);
    }, 0),
    []
  );
  const handleSearch2 = (value) => {
    deb2(value);
  };

  const deb3 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Partner Type (Estimate List Web)", {
        searchterm: value,
      });
      setSearchtype(value);
    }, 0),
    []
  );
  const handleSearch3 = (value) => {
    deb3(value);
  };
  return (
    <React.Fragment>
      <Helmet title="Estimates" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Estimates</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Estimates
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Work</Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Estimates
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {estimatePermission.includes("ADD") ? (
            <div style={{ marginRight: "21px" }}>
              <Link
                to="/estimates/add"
                onClick={() =>
                  mixpanel.track("Add Estimate (Estimate List Web)")
                }
                style={{ textDecoration: "none" }}
              >
                {/* <PlusSquare stroke="white" fill="#316284" size={35} /> */}
                <div
                  style={{
                    backgroundColor: "#3f80ea",
                    // backgroundColor: "#3367d6",
                    borderRadius: "5px",
                    padding: "8px 15px",
                    // padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    // color: "#676a6c",
                    alignItems: "center",
                    textDecoration: "none",
                    // fontSize: 14,
                    // fontSize
                    cursor: "pointer",
                    // fontWeight: "bold",
                  }}
                >
                  <Plus
                    size={15}
                    strokeWidth={3.5}
                    stroke="white"
                    style={{ marginRight: "5px" }}
                  />
                  Add Estimate
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label> Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={tempsearchname}
                            // name="email"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid(0);
                              setSearchhasmore(true);
                              setTempsearchname(e.target.value);
                              handleSearch0(e.target.value);
                              // handleSearch(e.target.value, ...Array(3));
                              // setSearchterm(e.target.value);
                            }}
                            placeholder=" Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Estimate Number</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={tempsearchnumb}
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid(0);
                              setSearchhasmore(true);
                              setTempsearchnumb(e.target.value);
                              handleSearch1(e.target.value);

                              // handleSearch(...Array(3), e.target.value);
                              // setSearchterm(e.target.value);
                            }}
                            placeholder="Estimate Number"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Status</Form.Label>

                          <Form.Select
                            value={tempsearchtotal}
                            // name="email"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid(0);
                              setSearchhasmore(true);
                              setTempsearchtotal(e.target.value);
                              handleSearch2(e.target.value);
                            }}
                          >
                            <option value="">All</option>
                            <option value="OPEN">Open</option>
                            <option value="ACCEPTED">Accepted</option>
                            <option value="CONVERTED">Converted</option>
                            {/* <option>Three</option> */}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Partner Type</Form.Label>

                          <Form.Select
                            // id="exampleCustomSelect"
                            // name="customSelect"
                            // className="mb-3"
                            value={tempsearchtype}
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid(0);
                              setSearchhasmore(true);
                              setTempsearchtype(e.target.value);

                              handleSearch3(e.target.value);
                            }}
                          >
                            <option value="">All</option>
                            <option value="CUSTOMER">CUSTOMER</option>
                            <option value="LEAD">LEAD</option>
                            <option value="SUPPLIER">SUPPLIER</option>
                            {/* <option>Three</option> */}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <Spinner
                        animation="border"
                        variant="dark"
                        className="me-2"
                      />
                    </div>
                  ) : (
                    <>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="mb-2 d-flex justify-content-between">
                          <div className="justify-content-start">
                            <strong>Search:</strong>{" "}
                            <input
                              size={20}
                              // value={searchterm}
                              onChange={
                                (e) => {
                                  setsearchloading(true);
                                  setSearchdata([]);
                                  setSearchid(0);
                                  setSearchhasmore(true);
                                  handleSearch(e.target.value);
                                  // setSearchterm(e.target.value);
                                }

                                // onChange(e.target.value);
                                // (e) => console.log(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <Link to="/estimates/add">
                          <PlusSquare stroke="white" fill="#316284" size={35} />
                        </Link>
                      </div> */}
                      {!searchtable ? (
                        <FilteringTable
                          productData={data}
                          token={token}
                          searchname={searchname}
                          update={getData}
                          hasmore={hasmore}
                          id={id}
                          deleteRecord={deleteRecord}
                        />
                      ) : (
                        <FilteringTable
                          productData={searchdata}
                          token={token}
                          searchname={searchname}
                          searchnumb={searchnumb}
                          searchtotal={searchtotal}
                          searchtable={searchtable}
                          searchtype={searchtype}
                          update={searchData}
                          hasmore={searchhasmore}
                          id={searchid}
                          searchloading={searchloading}
                          signal={signal}
                          deleteRecord={deleteRecord}
                        />
                      )}
                    </>
                  )}
                </Card.Header>
                {/* <Card.Body>&nbsp;</Card.Body> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Estimate;
