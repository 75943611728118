import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import { getCountryCallingCode } from "libphonenumber-js";
import * as Yup from "yup";
// import "../assets/css/App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { COLUMN } from "../pages/dashboards/Default/currency";
import useAuth from "../hooks/useAuth";
import mixpanel from "mixpanel-browser";
import { useLocalCountry } from "../contexts/LocalCountryContext";
import NotyfContext from "../contexts/NotyfContext";
import useSidebar from "../hooks/useSidebar";
import { logError } from "../utils/useErrorlogging";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const NewBusinessmodal = (props) => {
  const { setBdata, getbdata } = useAuth();
  const [businessadd, setbusinessadd] = useState({});
  //   const [pricelistoptions, setPricelistoptions] = useState([]);
  //   const [savemodalloading, setSavemodalloading] = useState(true);
  const [savemodalopen, setsavemodalopen] = useState("first");
  const [globalValidationError, setGlobalValidationError] = useState(false);

  //   const [newselectedplist, setCreatenewselectedplist] = useState("");
  const [disablesave, setDisablesave] = useState(false);
  const inputRef = useRef();

  const { country } = useLocalCountry();
  const phoneCode = `+ ${getCountryCallingCode(country)} `;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
    libraries: ["places"],
  });
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setbusinessadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };

  const submitData = async (data) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    console.log(user);

    if (user) {
      let tokenss = await user.getIdToken();
      data.business_email = user.email;
      fetch("https://bestmate.us/api/business/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          if (res.status >= 200 && res.status <= 300) {
            // getbdata();
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `Business Profile Created Successfully.`,
              ripple: "true",
              dismissible: "true",
            });
            props.setCreatenewmodal(false);
            setGlobalValidationError(false);
            window.location.reload();

            //     "appointment_partner",
            //     result[0].partner_first_name + " " + result[0].partner_last_name
            //   );
            //   props.setPartners(result[0]);
            //   props.setData({
            //     ...props.data,
            //     invoice_partner: result[0],
            //   });
            //   setbusinessadd({});

            //   setSameasbilling(true);
            //   setsavemodalopen("first");
            //   props.setCreatenewmodal(false);
            //   setDisablesave(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track(`Fetch Error (New Business Modal Web)`, {
            //   error: "business create api",
            // });
            logError({
              error: "fetch error",
              api: "business create api",
              component: "New Business Modal Web",
            });
            setDisablesave(false);
          }
        })
        .catch((err) => {
          // mixpanel.track(`Error (New Business Modal Web)`, {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: "New Business Modal Web",
          });
          setDisablesave(false);
          console.log(err);
        });
    }
    // });
  };

  const submitHandler = (elem) => {
    // elem.preventDefault();
    setDisablesave(true);

    const data = {
      business_user_name: capitalizeFirstLetter(elem.fullName),
      business_name: capitalizeFirstLetter(elem.companyName),
      business_email: "",
      business_phone: elem.phoneNumber,
      business_currency_name: elem.currencyName,
      business_currency_code: elem.currencyCode,
      business_platform: "Web",
      business_address: {
        street: businessadd.street,
        city: businessadd.city,
        state: businessadd.state,
        zip_code: businessadd.zip_code,
        country: businessadd.country,
      },

      business_industry: elem.bIndustry,
    };
    console.log(data);
    submitData(data);
  };

  const data = [
    { label: "Appliance Repair", value: "Appliance Repair" },
    { label: "Carpet Cleaning", value: "Carpet Cleaning" },
    { label: "Electrical", value: "Electrical" },
    { label: "Garage", value: "Garage" },
    { label: "General Contractor", value: "General Contractor" },
    { label: "Handyman", value: "Handyman" },
    { label: "Home Cleaning", value: "Home Cleaning" },
    { label: "HVAC", value: "HVAC" },
    { label: "IT Services", value: "IT Services" },
    { label: "Junk Removal", value: "Junk Removal" },
    { label: "Landscaping", value: "Landscaping" },
    { label: "Painting", value: "Painting" },
    { label: "Pest Control", value: "Pest Control" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Pool & Spa Services", value: "Pool & Spa Services" },
    { label: "Roofing Services", value: "Roofing Services" },
    { label: "Snow Removal", value: "Snow Removal" },
    { label: "Tree Services", value: "Tree Services" },
    { label: "Window Washing", value: "Window Washing" },
    { label: "Other", value: "Other" },
  ];

  return (
    <>
      <Modal show={props.createnewmodal} centered size="md">
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
              //   marginBottom: 10,
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 17,
                color: "black",
                margin: 0,
              }}
            >
              {`Setup Your Business Profile`}
            </h1>
            {/* <div
              onClick={() => {
                setbillingadd({});
                setshippingadd({});
                setSameasbilling(true);
                setsavemodalopen("first");

                props.setCreatenewmodal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div> */}
          </div>

          <div style={{ padding: "10px 24px" }}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                fullName: Yup.string().required("Name is required"),
                phoneNumber: Yup.string().matches(
                  /^[0-9+\-\s]+$/,
                  "Phone number is not valid"
                ),
              })}
              onSubmit={(elem) => submitHandler(elem)}
              initialValues={{
                fullName: "",
                companyName: "",
                phoneNumber: "",
                bIndustry: "",
                currencyCode: "USD",
                currencyName: "United States Dollar",
                // pricelist: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
                setValues,
                validateForm,
              }) => (
                <Form
                  noValidate
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const obj = await validateForm();
                    console.log(obj);
                    if (Object.keys(obj).length !== 0) {
                      setGlobalValidationError(true);
                    } else {
                      setGlobalValidationError(false);
                      handleSubmit();
                    }
                  }}
                  onKeyDown={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                >
                  <div
                    className="createnewhover"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      cursor: "pointer",
                      // backgroundColor: "pink",
                      padding: "10px 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => setsavemodalopen("first")}
                  >
                    <div>{`Business Details`}</div>
                    {savemodalopen === "first" ? (
                      <ChevronUp
                        size={24}
                        strokeWidth={2}
                        stroke="#3679e8"
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <ChevronDown
                        size={24}
                        strokeWidth={2}
                        stroke="#3679e8"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                  {savemodalopen === "first" ? (
                    <>
                      <div style={{ margin: "5px 0" }}>
                        <Row className="g-2">
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Full Name"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                name="fullName"
                                placeholder="Joe"
                                value={values.fullName}
                                onChange={handleChange}
                                isInvalid={!!errors.fullName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.fullName}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <FloatingLabel
                          controlId="floatingInput"
                          label="Company Name"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={values.companyName}
                            onChange={handleChange}
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Phone Number"
                          className="mb-3"
                          onClick={() => {
                            console.log("ASDF");
                            if (!values.phoneNumber) {
                              setValues({ ...values, phoneNumber: phoneCode });
                              // values.phoneNumber = phoneCode;
                            }
                          }}
                        >
                          <Form.Control
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.phoneNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phoneNumber}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <Row className="g-2">
                          <Col md>
                            <FloatingLabel
                              controlId="floatingSelectGrid"
                              label="Select Currency"
                            >
                              <Form.Select
                                type="text"
                                name="currencyCode"
                                placeholder="Select currency"
                                value={values.currencyCode}
                                onChange={(e) => {
                                  // console.log(e);
                                  let selected = e.target.value;
                                  setFieldValue("currencyCode", selected);
                                  let found = COLUMN.find(
                                    (elem) => elem.id === selected
                                  );
                                  console.log(found);
                                  setFieldValue("currencyName", found.name);
                                }}
                                aria-label="Floating label select example"
                              >
                                {COLUMN.map((currency) => (
                                  <option
                                    placeholder="Select Currency"
                                    value={currency.id}
                                  >
                                    {currency.id}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          {/* <Col md>
                            <FloatingLabel
                              controlId="floatingSelectGrid"
                              label="Select Industry"
                            >
                              <Form.Select
                                type="text"
                                name="bIndustry"
                                placeholder="Select Currency"
                                value={values.bIndustry}
                                onChange={handleChange}
                                aria-label="Floating label select example"
                              >
                                <option value="">Select Industry</option>
                                {data.map((industry) => (
                                  <option
                                    placeholder="Select Induscty"
                                    value={industry.value}
                                  >
                                    {industry.value}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Col> */}
                        </Row>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <hr style={{ margin: 0 }} />
                  <div
                    className="createnewhover"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      // marginBottom: 10,
                      padding: "10px 0",

                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => setsavemodalopen("second")}
                  >
                    <div>Business Address</div>
                    {savemodalopen === "second" ? (
                      <ChevronUp
                        size={24}
                        strokeWidth={2}
                        stroke="#3679e8"
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <ChevronDown
                        size={24}
                        strokeWidth={2}
                        stroke="#3679e8"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                  {savemodalopen === "second" ? (
                    <>
                      <div style={{ margin: "5px 0" }}>
                        {isLoaded ? (
                          <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Street"
                              className="mb-3"
                            >
                              <Form.Control
                                // ref={ref}
                                type="text"
                                // name="bstreet"
                                placeholder="1234 Main St"
                                // value={values.bstreet}
                                // onChange={handleChange}
                                value={businessadd.street}
                                onChange={(e) =>
                                  setbusinessadd({
                                    ...businessadd,
                                    street: e.target.value,
                                  })
                                }
                                // onSelect={(d)=>{console.l}}
                              />
                            </FloatingLabel>
                          </StandaloneSearchBox>
                        ) : (
                          <></>
                        )}
                        <Row className="g-2">
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="City"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                // name="bcity"
                                placeholder="St"
                                // value={values.bcity}
                                // onChange={handleChange}
                                value={businessadd.city}
                                onChange={(e) =>
                                  setbusinessadd({
                                    ...businessadd,
                                    city: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="State"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                // name="bstate"
                                placeholder="California"
                                // value={values.bstate}
                                // onChange={handleChange}
                                value={businessadd.state}
                                onChange={(e) =>
                                  setbusinessadd({
                                    ...businessadd,
                                    state: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="g-2">
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Zip Code"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                // name="bzip"
                                placeholder="115632"
                                // value={values.bzip}
                                // onChange={handleChange}
                                value={businessadd.zip_code}
                                onChange={(e) =>
                                  setbusinessadd({
                                    ...businessadd,
                                    zip_code: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Country"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                // name="bcountry"
                                placeholder="USA"
                                // value={values.bcountry}
                                // onChange={handleChange}
                                value={businessadd.country}
                                onChange={(e) =>
                                  setbusinessadd({
                                    ...businessadd,
                                    country: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <hr style={{ margin: 0 }} />
                  {globalValidationError ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        alignItems: "center",
                      }}
                    >
                      <AlertCircle size={15} strokeWidth={2} stroke="#d8534e" />
                      <div
                        style={{
                          color: "#d8534e",
                          fontSize: 11,
                          fontWeight: "400",
                          marginLeft: 5,
                        }}
                      >
                        There was an error saving the form. Please check the
                        messages above and try again.
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    {/* <button
                      style={{
                        marginRight: 5,
                        padding: "5px 10px",
                        backgroundColor: "white",
                        borderWidth: 1,
                      }}
                      onClick={() => {
                        setbillingadd({});
                        setshippingadd({});
                        setSameasbilling(true);
                        setsavemodalopen("first");

                        props.setCreatenewmodal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "#3679e8",
                        borderWidth: 0,
                        color: "white",
                      }}
                      type="submit"
                      disabled={disablesave}
                      onClick={() =>
                        mixpanel.track("Create Profile (Dashboard Web)")
                      }
                    >
                      {`Create Profile`}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default NewBusinessmodal;
