import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { User, Plus, X, Archive } from "react-feather";
import mixpanel from "mixpanel-browser";
import { useCurrencyFormating } from "../utils/useCurrencyFormating";
import useAuth from "../hooks/useAuth";
const Selectmodal = (props) => {
  const [selected, setSelected] = useState(props.type);
  const [index, setIndex] = useState(props.index);
  let usercurrency = localStorage.getItem("currency") || "USD";
  const { userPermission } = useAuth();

  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";
  // console.log(props.pricelist);
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  const { currencyFormater } = useCurrencyFormating();

  useEffect(() => {
    if (props.openModals) {
      setSelected(props.type);
      setIndex(props.index);
      props.setsearchtem("");
    } else {
    }
  }, [props.openModals]);
  return (
    <>
      <Modal
        show={props.openModals}
        onHide={() => {
          mixpanel.track(`Close (Select ${selected} Modal Web)`);
          props.setOpenModals(!props.openModals);
        }}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              {`Select or create a ${selected}`}
            </h1>
            <div
              onClick={() => {
                mixpanel.track(`Close (Select ${selected} Modal Web)`);

                props.setOpenModals(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {props.modloading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "20px 30px" }}>
              <p style={{ marginBottom: 10 }}>
                {`Which ${
                  selected.charAt(0).toUpperCase() + selected.slice(1)
                } would you like to create this for?`}
              </p>
              <div
                style={{
                  width: "100%",
                  height: 500,
                  border: "solid 1px #e1e1e1",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 80,
                    padding: 16,
                    backgroundColor: "#f4f4f4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Group
                    style={{
                      width: "100%",
                      margin: "0px 20px",
                      height: 35,
                    }}
                  >
                    <Form.Control
                      type="text"
                      value={props.searchterm}
                      onChange={(e) => {
                        mixpanel.track(
                          `Search ${selected} (Select ${selected} Modal Web)`,
                          {
                            searchterm: e.target.value,
                          }
                        );

                        props.setsearchtem(e.target.value);
                      }}
                      placeholder={`Search ${
                        selected.charAt(0).toUpperCase() + selected.slice(1)
                      }s`}
                      style={{ height: 35 }}
                    />
                  </Form.Group>
                  {/* <p style={{ margin: 0 }}>or</p> */}
                  {/* <div
                    style={{
                      border: "1px #E7EAEC solid",
                      borderRadius: "3px",
                      padding: "0.375rem 0.75rem",
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      backgroundColor: "#3f80ea",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: "inherit",
                      cursor: "pointer",
                      width: "100%",
                      margin: "0px 20px",
                    }}
                    onClick={() => {
                      // navigate("/customer/add-customer")
                      props.setOpenModals(false);
                      props.fetchPriceList();
                      // props.setsavemodalopen("first");
                      props.setCreatenewmodal(true);
                    }}
                  >
                    <div style={{ fontWeight: "bold" }} onClick={() => {}}>
                      {`Create New ${
                        props.type.charAt(0).toUpperCase() + props.type.slice(1)
                      }`}
                    </div>
                  </div> */}
                </div>
                {props.isLead ? (
                  <div
                    style={{
                      borderBottom: "1px solid #f5f5f5",
                      color: "black",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        // backgroundColor: "pink",
                        padding: 10,
                        borderBottom:
                          selected === "customer"
                            ? "2px solid #4f83cc"
                            : "1px solid #f5f5f5",
                        color: selected === "customer" ? "black" : "#7d7d7d",
                        fontWeight: selected === "customer" ? "bold" : "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mixpanel.track(
                          "Customer Tab (Select an Customer Modal Web)"
                        );
                        setSelected("customer");
                        setIndex(0);
                      }}
                    >
                      Customers
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        // backgroundColor: "pink",
                        padding: 10,
                        borderBottom:
                          selected === "lead"
                            ? "2px solid #71BE1D"
                            : "1px solid #f5f5f5",
                        color: selected === "lead" ? "black" : "#7d7d7d",
                        fontWeight: selected === "lead" ? "bold" : "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mixpanel.track(
                          "Lead Tab (Select an Customer Modal Web)"
                        );
                        setSelected("lead");
                        setIndex(2);
                      }}
                    >
                      Leads
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      color: "black",
                      padding: 10,
                    }}
                  >
                    {selected.charAt(0).toUpperCase() + selected.slice(1)}
                  </div>
                )}
                <div
                  style={{
                    height: 375,
                    width: "100%",
                    overflow: "scroll",
                  }}
                >
                  {(partnerPermission.includes("ADD") &&
                    selected === "customer") ||
                  (itemPermission.includes("ADD") &&
                    (selected === "product" || selected === "service")) ? (
                    <div
                      style={{
                        border: "1px #E7EAEC solid",
                        // borderRadius: "3px",
                        padding: "0.375rem 0.75rem",
                        display: "flex",
                        // justifyContent: "center",
                        color: "white",
                        backgroundColor: "#3f80ea",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: "inherit",
                        cursor: "pointer",
                        height: 50,
                        width: "100%",
                        // margin: "0px 20px",
                      }}
                      onClick={() => {
                        // navigate("/customer/add-customer")
                        mixpanel.track(
                          `Add New (Select ${selected} Modal Web)`
                        );

                        props.setOpenModals(false);
                        props.fetchPriceList();
                        // props.setsavemodalopen("first");
                        if (props.isLead && selected === "lead") {
                          props.setCreatenewmodallead(true);
                        } else {
                          props.setCreatenewmodal(true);
                        }
                      }}
                    >
                      <Plus
                        size={20}
                        strokeWidth={2}
                        stroke="white"
                        style={{ marginRight: 15 }}
                      />
                      <div style={{ fontWeight: "bold" }} onClick={() => {}}>
                        {`Create New ${
                          selected.charAt(0).toUpperCase() + selected.slice(1)
                        }`}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.options[index].options.length !== 0 &&
                  props.options[index].options.filter((elem) =>
                    elem.label
                      .toLowerCase()
                      .includes(props.searchterm.toLowerCase())
                  ).length !== 0 ? (
                    <>
                      {props.options[index].options
                        .filter((elem) =>
                          elem.label
                            .toLowerCase()
                            .includes(props.searchterm.toLowerCase())
                        )
                        .map((elem) => (
                          <div
                            style={{
                              color: "black",
                              padding: 10,
                              cursor: "pointer",
                              borderBottom: "1px solid #e1e1e1",
                              display: "flex",
                              // padding: 15,
                            }}
                            className="Listmodal"
                            onClick={(e) => {
                              // e.stopPropagation();
                              console.log(elem.label);
                              mixpanel.track(
                                `Selected ${selected} (Select ${selected} Modal Web)`,
                                {
                                  elem,
                                }
                              );

                              props.setFieldValue(
                                "appointment_partner",
                                elem.label
                              );
                              props.setPartners(elem.value);
                              if (
                                selected === "customer" ||
                                selected === "supplier" ||
                                selected === "lead"
                              ) {
                                props.setData({
                                  ...props.data,
                                  invoice_partner: elem.value,
                                });
                              } else if (
                                selected === "product" ||
                                selected === "service"
                              ) {
                                const temp = props.data;
                                console.log(temp);
                                console.log("thishere");
                                for (
                                  let i = 0;
                                  i < temp["invoice_order"].length;
                                  i++
                                ) {
                                  if (
                                    temp["invoice_order"][i].item_id ===
                                    elem.value.item_id
                                  ) {
                                    temp["invoice_order"][i].item_quantity =
                                      temp["invoice_order"][i].item_quantity +
                                      1;
                                    console.log(temp);
                                    // props.setData(temp);
                                    let newchange = {
                                      ...props.data,
                                      invoice_order: temp.invoice_order,
                                    };
                                    props.setData(newchange);
                                    props.setOpenModals(false);
                                    return;
                                  }
                                }
                                console.log(elem.value);
                                let obj = { ...elem.value };
                                if (
                                  obj &&
                                  (!Object.keys(obj).includes(
                                    "item_cost_price"
                                  ) ||
                                    !obj.item_cost_price)
                                ) {
                                  obj.item_cost_price = 0;
                                }
                                if (
                                  obj &&
                                  (!Object.keys(obj).includes(
                                    "item_selling_price"
                                  ) ||
                                    !obj.item_selling_price)
                                ) {
                                  obj.item_selling_price = 0;
                                }
                                temp["invoice_order"].push(obj);
                                obj.item_quantity = 1;
                                console.log(temp);
                                let newchange = {
                                  ...props.data,
                                  invoice_order: temp.invoice_order,
                                };
                                props.setData(newchange);
                                // return;
                              }
                              props.setOpenModals(false);
                            }}
                          >
                            {selected === "customer" ||
                            selected === "supplier" ||
                            selected === "lead" ? (
                              <>
                                <User
                                  size={20}
                                  strokeWidth={2}
                                  stroke="#007fff"
                                  style={{ marginRight: 15 }}
                                />

                                <div
                                  style={{
                                    width: "90%",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      fontWeight: "bold",
                                      wordWrap: "break-word",
                                      maxWidth: 500,
                                    }}
                                  >
                                    {truncateTextByWordCount(elem.label, 40)}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                      maxWidth: 500,
                                    }}
                                  >
                                    {/* {truncateTextByWordCount(
                                      elem.value.partner_number,
                                      20
                                    )} */}
                                    {elem.value.partner_number
                                      ? truncateTextByWordCount(
                                          elem.value.partner_number,
                                          20
                                        )
                                      : ""}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <Archive
                                  size={20}
                                  strokeWidth={2}
                                  stroke="#007fff"
                                  style={{ marginRight: 15 }}
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // backgroundColor: "pink",
                                    flexGrow: 1,
                                    // maxWidth: "100%",
                                    // width: "90%",
                                    // wordWrap: "break-word",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      fontWeight: "bold",
                                      wordWrap: "break-word",
                                      maxWidth: 300,
                                    }}
                                  >
                                    {truncateTextByWordCount(elem.label, 40)}
                                  </p>
                                  {props.partner === "CUSTOMER" ||
                                  props.partner === "LEAD" ? (
                                    <p
                                      style={{
                                        margin: 0,
                                        marginRight: 10,
                                        // fontWeight: "bold",
                                      }}
                                    >
                                      {/* {(
                                        (elem.value.item_selling_price
                                          ? Number(
                                              elem.value.item_selling_price
                                            )
                                          : 0) *
                                        (props.pricelist
                                          ? Number(props.pricelist)
                                          : 1)
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: usercurrency,
                                      })} */}
                                      {truncateTextByWordCount(
                                        currencyFormater(
                                          (elem.value.item_selling_price
                                            ? Number(
                                                elem.value.item_selling_price
                                              )
                                            : 0) *
                                            (props.pricelist
                                              ? Number(props.pricelist)
                                              : 1),
                                          usercurrency
                                        ),
                                        30
                                      )}
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        margin: 0,
                                        marginRight: 10,
                                        // fontWeight: "bold",
                                      }}
                                    >
                                      {/* {(elem.value.item_cost_price
                                        ? Number(elem.value.item_cost_price)
                                        : 0
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: usercurrency,
                                      })} */}
                                      {truncateTextByWordCount(
                                        currencyFormater(
                                          elem.value.item_cost_price
                                            ? Number(elem.value.item_cost_price)
                                            : 0,
                                          usercurrency
                                        ),
                                        30
                                      )}
                                    </p>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>{`No ${selected} found. Start by creating a new ${selected}.`}</p>
                        <div
                          style={{
                            border: "1px #E7EAEC solid",
                            borderRadius: "3px",
                            padding: "0.375rem 0.75rem",
                            display: "flex",
                            justifyContent: "center",
                            color: "white",
                            backgroundColor: "#3f80ea",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: "inherit",
                            cursor: "pointer",
                            width: 180,
                            margin: "0px 20px",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              `Add New (Select ${selected} Modal Web)`
                            );

                            // navigate("/customer/add-customer")
                            props.setOpenModals(false);
                          }}
                        >
                          <div
                            style={{ fontWeight: "bold" }}
                            onClick={() => {
                              mixpanel.track(
                                `Add New (Select ${selected} Modal Web)`
                              );
                              props.fetchPriceList();
                              if (props.isLead && selected === "lead") {
                                props.setCreatenewmodallead(true);
                              } else {
                                props.setCreatenewmodal(true);
                              }
                            }}
                          >
                            {`Create New ${selected}`}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Selectmodal;
