import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { COLUMN } from "./currency.js";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import useAuth from "../../../hooks/useAuth";
import { Navigate, useNavigate } from "react-router-dom";
import empty_invoice from "../../../assets/img/icons/empty_invoice.png";
import empty_inventory from "../../../assets/img/icons/empty_inventory.png";
import empty_partner from "../../../assets/img/icons/empty_partner.png";
import empty_transaction from "../../../assets/img/icons/empty_transaction.png";
import schedule from "../../../assets/img/icons/schedule.png";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Offcanvas,
  Nav,
  Tab,
} from "react-bootstrap";
import Header from "./Header";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import Appointments from "./Appointments";
import BarChart from "./BarChart";
import Calendar from "./Calendar";
import Feed from "./Feed";
import PieChart from "./PieChart";
import Projects from "./Projects";
import Statistics from "./Statistics";
import Buttons from "../Buttons.js";
import { ChevronRight, Plus } from "react-feather";
import useAuth from "../../../hooks/useAuth.js";
import NewBusinessmodal from "../../../components/NewBusinessmodal.js";
import mixpanel from "mixpanel-browser";
import { usePremium } from "../../../contexts/PremiumContext.js";
import { STRIPE_PRODUCTION } from "../../../constants.js";
// import Appointments from "./Appointments";

// const BasicForm = (props) => (
//   <Formik
//     validationSchema={Yup.object().shape({
//       business_user_name: Yup.string().required("Name is required"),
//       business_name: Yup.string().required("Business Name is required"),
//       business_currency_code: Yup.string().required("Currency is required"),
//     })}
//     onSubmit={(values) => {
//       values["business_currency_name"] = COLUMN.filter(
//         (elem) => elem.id === values.business_currency_code
//       )[0]["name"];
//       props.setData(values);

//       props.setModal_1(!props.modal_1);
//       props.setModal_2(!props.modal_2);
//     }}
//     initialValues={{
//       business_user_name: "",
//       business_name: "",
//       business_currency_code: "",
//     }}
//   >
//     {({ handleSubmit, handleChange, values, errors }) => (
//       <Form onSubmit={handleSubmit}>
//         {errors.submit && (
//           <Alert className="my-3" variant="danger">
//             <div className="alert-message">{errors.submit}</div>
//           </Alert>
//         )}
//         {/* Card get Started*/}
//         <Card>
//           <Card.Header className="pb-0">
//             <Card.Title className="mb-0">
//               Get Started by setting up your business profile
//             </Card.Title>
//           </Card.Header>
//           <Card.Body>
//             <Row className="g-2">
//               <FloatingLabel
//                 controlId="floatingInput"
//                 label="Full Name "
//                 className="mb-3"
//               >
//                 <Form.Control
//                   type="text"
//                   isInvalid={errors.business_user_name}
//                   name="business_user_name"
//                   placeholder="text"
//                   value={values.business_user_name}
//                   onChange={handleChange}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.business_user_name}
//                 </Form.Control.Feedback>
//               </FloatingLabel>
//             </Row>
//             <Row className="g-2">
//               <FloatingLabel
//                 controlId="floatingInput"
//                 label="Business Name "
//                 className="mb-3"
//               >
//                 <Form.Control
//                   type="text"
//                   isInvalid={errors.business_name}
//                   name="business_name"
//                   placeholder="text"
//                   value={values.business_name}
//                   onChange={handleChange}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.business_name}
//                 </Form.Control.Feedback>
//               </FloatingLabel>
//             </Row>
//             <Row className="g-2">
//               <FloatingLabel controlId="floatingSelectGrid" label="Currency">
//                 <Form.Select
//                   type="text"
//                   isInvalid={errors.business_currency_code}
//                   name="business_currency_code"
//                   placeholder="Select currency"
//                   value={values.business_currency_code}
//                   onChange={handleChange}
//                   aria-label="Floating label select example"
//                 >
//                   <option value="">Select a currency</option>
//                   {COLUMN.map((currency) => (
//                     <option placeholder="Select currency" value={currency.id}>
//                       {currency.id}
//                     </option>
//                   ))}
//                 </Form.Select>
//                 <Form.Control.Feedback type="invalid">
//                   {errors.business_currency_code}
//                 </Form.Control.Feedback>
//               </FloatingLabel>
//             </Row>
//           </Card.Body>
//         </Card>
//         <div className="text-center">
//           <Button type="submit" variant="success" size="lg">
//             Next
//           </Button>
//         </div>
//       </Form>
//     )}
//   </Formik>
// );

// const NextForm = (props) => {
//   const navigate = useNavigate();
//   const { setBdata, getbdata } = useAuth();
//   const inputRef = useRef();
//   const [adddata, setAdddata] = useState({});

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
//     libraries: ["places"],
//   });

//   const handlePlaceChanged = () => {
//     const [place] = inputRef.current.getPlaces();
//     if (place) {
//       let country = place.address_components.filter((elem) =>
//         elem.types.includes("country")
//       );
//       let state = place.address_components.filter((elem) =>
//         elem.types.includes("administrative_area_level_1")
//       );
//       let city = place.address_components.filter((elem) =>
//         elem.types.includes("locality")
//       );
//       let street = place.address_components.filter(
//         (elem) =>
//           elem.types.includes("street_number") ||
//           elem.types.includes("route") ||
//           elem.types.includes("sublocality")
//       );
//       let zip = place.address_components.filter((elem) =>
//         elem.types.includes("postal_code")
//       );
//       setAdddata({
//         country: country.length !== 0 ? country[0].long_name : "",
//         state: state.length !== 0 ? state[0].long_name : "",
//         city: city.length !== 0 ? city[0].long_name : "",
//         zip_code: zip.length !== 0 ? zip[0].long_name : "",
//         street:
//           street.length !== 0
//             ? street.map((elem) => elem.long_name).join(" ")
//             : "",
//       });
//     }
//   };
//   const createBusiness = (data) => {
//     // firebase.auth().onAuthStateChanged(async (user) => {
//     //   if (user) {
//     //     let tokenss = await user.getIdToken();

//     fetch("https://bestmate.us/api/business/create", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + props.token,
//       },
//       body: JSON.stringify(data),
//     })
//       .then((response) => {
//         getbdata();
//         console.log("response", response);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };
//   // });
//   // };
//   const reset = () => {
//     let val = {
//       street: "",
//       city: "",
//       state: "",
//       zip_code: "",
//       country: "",
//     };
//     props.businessData["business_address"] = val;
//     props.businessData["business_email"] = props.businessEmail;
//     console.log(props.businessData);
//     createBusiness(props.businessData);
//     props.setModal_2(!props.modal_2);
//   };
//   return (
//     <Formik
//       enableReinitialize
//       validationSchema={Yup.object().shape({
//         // street: Yup.string().required("Street is required"),
//         // city: Yup.string().required("City is required"),
//         // state: Yup.string().required("State is required"),
//         // zip_code: Yup.string().required("Zip Code is required"),
//         // country: Yup.string().required("Country is required"),
//       })}
//       onSubmit={(values) => {
//         console.log(props.businessData);
//         console.log(values);
//         const finalData = Object.assign(props.businessData);
//         finalData["business_address"] = values;
//         finalData["business_email"] = props.businessEmail;
//         console.log(finalData);
//         createBusiness(finalData);
//         props.setModal_2(!props.modal_2);
//       }}
//       // onReset={() => {
//       //   // console.log(props.businessData);
//       //   let val = {
//       //     street: "",
//       //     city: "",
//       //     state: "",
//       //     zip_code: "",
//       //     country: "",
//       //   };
//       //   props.businessData["business_address"] = val;
//       //   props.businessData["business_email"] = props.businessEmail;
//       //   console.log(props.businessData);
//       //   createBusiness(props.businessData);
//       //   props.setModal_2(!props.modal_2);
//       // }}
//       initialValues={adddata}
//     >
//       {({ handleSubmit, handleChange, handleReset, values, errors }) => (
//         <Form
//           onSubmit={handleSubmit}
//           onKeyDown={(keyEvent) => {
//             if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
//               keyEvent.preventDefault();
//             }
//           }}
//         >
//           {errors.submit && (
//             <Alert className="my-3" variant="danger">
//               <div className="alert-message">{errors.submit}</div>
//             </Alert>
//           )}
//           {/* Business Address*/}
//           <Card>
//             <Card.Header className="pb-0">
//               <Card.Title className="mb-0">Business Address</Card.Title>
//             </Card.Header>
//             <Card.Body>
//               {isLoaded ? (
//                 <StandaloneSearchBox
//                   onLoad={(ref) => (inputRef.current = ref)}
//                   onPlacesChanged={handlePlaceChanged}
//                 >
//                   <FloatingLabel
//                     controlId="floatingInput"
//                     label="Street "
//                     className="mb-3"
//                   >
//                     <Form.Control
//                       type="text"
//                       isInvalid={errors.street}
//                       name="street"
//                       placeholder="text"
//                       value={values.street}
//                       onChange={handleChange}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.street}
//                     </Form.Control.Feedback>
//                   </FloatingLabel>
//                 </StandaloneSearchBox>
//               ) : (
//                 <></>
//               )}
//               <Row className="g-2">
//                 <Col md>
//                   <FloatingLabel
//                     controlId="floatingInput"
//                     label="City "
//                     className="mb-3"
//                   >
//                     <Form.Control
//                       type="text"
//                       isInvalid={errors.city}
//                       name="city"
//                       placeholder="text"
//                       value={values.city}
//                       onChange={handleChange}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.city}
//                     </Form.Control.Feedback>
//                   </FloatingLabel>
//                 </Col>
//                 <Col md>
//                   <FloatingLabel
//                     controlId="floatingInput"
//                     label="State "
//                     className="mb-3"
//                   >
//                     <Form.Control
//                       type="text"
//                       isInvalid={errors.state}
//                       name="state"
//                       placeholder="text"
//                       value={values.state}
//                       onChange={handleChange}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.state}
//                     </Form.Control.Feedback>
//                   </FloatingLabel>
//                 </Col>
//               </Row>
//               <Row className="g-2">
//                 <Col md>
//                   <FloatingLabel
//                     controlId="floatingInput"
//                     label="Zip Code "
//                     className="mb-3"
//                   >
//                     <Form.Control
//                       type="text"
//                       isInvalid={errors.zip_code}
//                       name="zip_code"
//                       placeholder="text"
//                       value={values.zip_code}
//                       onChange={handleChange}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.zip_code}
//                     </Form.Control.Feedback>
//                   </FloatingLabel>
//                 </Col>
//                 <Col md>
//                   <FloatingLabel
//                     controlId="floatingInput"
//                     label="Country "
//                     className="mb-3"
//                   >
//                     <Form.Control
//                       type="text"
//                       isInvalid={errors.country}
//                       name="country"
//                       placeholder="text"
//                       value={values.country}
//                       onChange={handleChange}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.country}
//                     </Form.Control.Feedback>
//                   </FloatingLabel>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//           {/* call to action buttons */}
//           <div className="text-center">
//             <Button
//               type="submit"
//               variant="success"
//               className="me-1 mb-1 center"
//               size="lg"
//             >
//               Save
//             </Button>
//             <Button
//               variant="primary"
//               size="lg"
//               type="button"
//               // type="reset"
//               className="me-1 mb-1 center"
//               onClick={() => reset()}
//             >
//               Cancel
//             </Button>
//           </div>
//         </Form>
//       )}
//     </Formik>
//   );
// };

const MyModal = (props) => {
  const [openModals, setOpenModals] = useState(true);
  const [openModals2, setOpenModals2] = useState(false);
  const [businessData, setBusinessData] = useState();
  const [createnewmodal, setCreatenewmodal] = useState(true);
  return (
    <>
      <React.Fragment>
        {/* <Modal
          backdrop="static"
          show={openModals}
          onHide={() => setOpenModals(!openModals)}
          centered
          data-keyboard="false"
          data-backdrop="static"
        >
          <Modal.Body className="text-left m-3">
            <BasicForm
              setData={setBusinessData}
              token={props.token}
              modal_1={openModals}
              modal_2={openModals2}
              setModal_1={setOpenModals}
              setModal_2={setOpenModals2}
            />
          </Modal.Body>
        </Modal> */}
        <NewBusinessmodal
          createnewmodal={createnewmodal}
          setCreatenewmodal={setCreatenewmodal}
        />
      </React.Fragment>
      {/* <Modal
        show={openModals2}
        onHide={() => setOpenModals2(!openModals2)}
        centered
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Body className="text-left m-3">
          <NextForm
            businessEmail={props.businessEmail}
            businessData={businessData}
            token={props.token}
            modal_2={openModals2}
            setModal_2={setOpenModals2}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

const Default = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [value, setvalue] = useState(false);
  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [invoicedata, setInvoicedata] = useState([]);
  const [activeTab, setactiveTab] = useState("first");
  const {
    moreInfo,
    setMoreInfo,
    setIsPremium,
    setIsFree,
    setIsTrial,
    useIsFree,
    setMoreinfoCount,
  } = usePremium();
  const {
    isTeamAccount,
    userPermission,
    setUserPermission,
    setUserData,
    updateAuth,
  } = useAuth();
  // console.log(token);
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  const [business_email, setBusiness_email] = useState("");
  const getData = (token) => {
    fetch("https://bestmate.us/api/business/business-exists-team", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // setData(result);
          setvalue(!result.businessExists);
          // setLoading(false);
          console.log(result, result.businessExists);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  let usercurrency = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "USD";

  const invoiceData = (token) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    fetch(
      `https://bestmate.us/api/invoices/findAll?timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setInvoicedata(result);
          setLoading(false);
          console.log(result);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  const getmoreInfo = async () => {
    var user = firebase.auth().currentUser;
    let token = await user.getIdToken();
    setBusiness_email(user.email);
    fetch(
      "https://bestmate.us/api/business/more-info" +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        fetch(
          `https://bestmate.us/api/business/web-subscription/get-subscription?${
            STRIPE_PRODUCTION ? "?" : "?stripe_test=true&"
          }${isTeamAccount ? "team=true" : ""}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
          .then((res1) => res1.json())
          .then((result2) => {
            // let final = { ...result };
            let final = { ...result, ...result2 };
            setMoreInfo(final);
            console.log(final);
            setIsFree(final.isFreeUser);
            setIsTrial(final.isTrialUser);
            setIsPremium(
              final.isPremiumAndroidUser ||
                final.isPremiumIOSUser ||
                final.isPremiumStripeUser
            );
            let value = localStorage.getItem("PSCCount");
            console.log(value);
            let tempValue = JSON.parse(value);
            console.log(tempValue);
            let containId = false;
            if (value !== null)
              tempValue.map((elem) => {
                console.log(elem.id, user.email);
                if (elem.id === user.email) {
                  containId = true;
                  return;
                }
              });

            console.log(containId);
            if (value === null || !containId) {
              console.log("first");
              localStorage.setItem(
                "PSCCount",
                JSON.stringify(
                  value
                    ? [...JSON.parse(value), { id: user.email, attempt: 0 }]
                    : [{ id: user.email, attempt: 0 }]
                )
              );
            } else if (
              final.isTrialUser == true ||
              final.isPremiumAndroidUser == true ||
              final.isPremiumIOSUser == true ||
              final.isPremiumStripeUser == true ||
              (useIsFree && final.isFreeUser == true)
            ) {
              console.log("second");
              let obj = tempValue.map((elem) => {
                if (elem.id === user.email) {
                  return { id: user.email, attempt: 0 };
                } else {
                  return elem;
                }
              });

              localStorage.setItem("PSCCount", JSON.stringify(obj));
            } else {
              console.log("third");
              let PSCattempts = 0;
              let obj = tempValue.map((elem) => {
                if (elem.id === user.email) {
                  PSCattempts = ++elem.attempt;
                  setMoreinfoCount(PSCattempts);
                  return { id: user.email, attempt: PSCattempts };
                } else {
                  return elem;
                }
              });

              localStorage.setItem("PSCCount", JSON.stringify(obj));
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const getPermissions = async () => {
    try {
      var user = firebase.auth().currentUser;
      if (user) {
        let token = await user.getIdToken();
        let response = await fetch(
          "https://bestmate.us/api/team/get-permissions",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(response.ok);

        if (response.ok) {
          let result = await response.json();
          console.log(result, result.status === "DEACTIVATED");
          if (result.status === "DEACTIVATED") {
            console.log("updating ....");
            updateAuth({ isTeamAccount: true, isDeactivated: true });
          } else {
            console.log("90909099999");

            setUserData(result);

            console.log("user data and permission both updated 78877878");
            setUserPermission(
              Object.keys(result).includes("team_member_permissions")
                ? result.team_member_permissions
                : {}
            );
            console.log("user data and permission both updated");
          }
        } else {
          console.log("user data and permission both updatedmjokml");
          setUserPermission({});
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    console.log(user);
    if (user) {
      console.log(user);
      let tokenss = await user.getIdToken();
      setBusiness_email(user.email);
      settoken(tokenss);
      // setLoading(false);
      invoiceData(tokenss);
      // getData();
      getPermissions();
      getmoreInfo();
    }
    mixpanel.track("On Dashboard (Web)");
    // });
  }, []);
  console.log(usercurrency);
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid style={{ padding: "1.5rem 1.5rem 2rem" }}>
        <Header />
        {/* {value === true ? (
          <MyModal businessEmail={business_email} token={token} />
        ) : (
          <></>
        )} */}
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "400px" }}
          >
            <Spinner animation="border" variant="dark" className="me-2" />
          </div>
        ) : (
          <>
            <Statistics invoicedata={invoicedata} usercurrency={usercurrency} />
            <Row>
              <Col lg="8" className="d-flex">
                {/* <Projects type="Invoice" usercurrency={usercurrency} /> */}
                <Card className="flex-fill w-100 mt-2  border rounded-0">
                  <Card.Body
                    style={{
                      paddingTop: 8,
                      paddingRight: 10,
                      paddingLeft: 10,
                      // marginBottom: -2,
                    }}
                  >
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav
                        variant="tabs"
                        style={{
                          borderBottom: "1px solid #c2c0c0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Nav.Item
                            style={{
                              width: 130,
                              maxWidth: "100%",
                            }}
                          >
                            <Nav.Link
                              className="text-dark"
                              onClick={() => {
                                mixpanel.track("Invoice Card(Dashboard Web)");
                                setactiveTab("first");
                              }}
                              style={
                                activeTab === "first"
                                  ? {
                                      borderBottom: "2px solid #4f83cc",
                                      fontWeight: "bold",
                                      fontSize: 15,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                                  : {
                                      // fontWeight: "bold",
                                      fontSize: 14,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                              }
                              eventKey="first"
                            >
                              Invoices
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            style={{
                              width: 130,
                              maxWidth: "100%",
                            }}
                          >
                            <Nav.Link
                              className="text-dark"
                              onClick={() => {
                                mixpanel.track("Booking Card(Dashboard Web)");
                                setactiveTab("second");
                              }}
                              style={
                                activeTab === "second"
                                  ? {
                                      borderBottom: "2px solid #71BE1D",
                                      fontWeight: "bold",
                                      fontSize: 15,

                                      display: "flex",
                                      justifyContent: "center",
                                    }
                                  : {
                                      // fontWeight: "bold",
                                      fontSize: 14,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                              }
                              eventKey="second"
                            >
                              Bookings
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            style={{
                              width: 130,
                              maxWidth: "100%",
                            }}
                          >
                            <Nav.Link
                              className="text-dark"
                              onClick={() => {
                                mixpanel.track("Estimate Card(Dashboard Web)");
                                setactiveTab("third");
                              }}
                              style={
                                activeTab === "third"
                                  ? {
                                      borderBottom: "2px solid #DE5474",
                                      fontWeight: "bold",
                                      fontSize: 15,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                                  : {
                                      // fontWeight: "bold",
                                      fontSize: 14,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                              }
                              eventKey="third"
                            >
                              Estimates
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            style={{
                              width: 130,
                              maxWidth: "100%",
                            }}
                          >
                            <Nav.Link
                              className="text-dark"
                              onClick={() => {
                                mixpanel.track("Request Card(Dashboard Web)");
                                setactiveTab("fourth");
                              }}
                              style={
                                activeTab === "fourth"
                                  ? {
                                      borderBottom: "2px solid #4f83cc",
                                      fontWeight: "bold",
                                      fontSize: 15,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                                  : {
                                      // fontWeight: "bold",
                                      fontSize: 14,
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                              }
                              eventKey="fourth"
                            >
                              Requests
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                        <ChevronRight
                          size={20}
                          strokeWidth={3}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            mixpanel.track("Go To List (Dashboard Web)");
                            if (activeTab === "first" && invoicePermission) {
                              navigate("/invoices");
                            } else if (
                              activeTab === "second" &&
                              bookingPermission
                            ) {
                              navigate("/bookings");
                            } else if (
                              activeTab === "third" &&
                              estimatePermission
                            ) {
                              navigate("/estimates");
                            } else if (
                              activeTab === "fourth" &&
                              requestPermission
                            ) {
                              navigate("/requests");
                            }
                          }}
                        />
                        {/* <div style={{ fontSize: 11 }}>GO TO INVOICES -></div> */}
                      </Nav>

                      <Tab.Content style={{ marginTop: 20 }}>
                        <Tab.Pane eventKey="first">
                          <Projects
                            type="Invoice"
                            invoicedata={invoicedata}
                            usercurrency={usercurrency}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <Projects
                            type="Booking"
                            invoicedata={invoicedata}
                            usercurrency={usercurrency}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <Projects
                            type="Estimate"
                            invoicedata={invoicedata}
                            usercurrency={usercurrency}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <Projects
                            type="Request"
                            invoicedata={invoicedata}
                            usercurrency={usercurrency}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                lg="4"
                className="d-flex"
                style={{ flexDirection: "column" }}
              >
                <Appointments />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};
const OffcanvasPlacementSingle = ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="me-2">
        {name}
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Shortcuts</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc. */}
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              // // backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                mixpanel.track(
                  "Add Invoice >> Empty Invoice List(Dashboard Web)"
                );
                navigate("/invoices/add");
              }}
            >
              <img
                src={empty_invoice}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Invoice</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/products/add-item")}
            >
              <img
                src={empty_inventory}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Item</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/customer/add-customer")}
            >
              <img
                src={empty_partner}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Customer</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/estimates/add")}
            >
              <img
                src={empty_invoice}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Estimate</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/schedule")}
            >
              <img
                src={schedule}
                style={{ height: "50px" }}
                alt="schedule"
              ></img>
              <p style={{ margin: "0" }}>Add Booking</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/supplier/add-supplier")}
            >
              <img
                src={empty_partner}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Supplier</p>
            </Card>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Default;
