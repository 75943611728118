import React, { useEffect } from "react";

import { THEME } from "../constants";
import useSettingsState from "../hooks/useSettingsState";
import useAuth from "../hooks/useAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
const initialState = {
  // theme: THEME.DEFAULT,
  theme: THEME.LIGHT,
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  // const [theme, setTheme] = useSettingsState("theme", THEME.DEFAULT);
  const { bdata } = useAuth();
  const [theme, setTheme] = useSettingsState("theme", THEME.LIGHT);
  useEffect(() => {
    console.log("running");
    if (bdata && bdata.business_theme) {
      console.log(bdata.business_theme);
      const themeOptions = [
        {
          name: "light",
          value: THEME.LIGHT,
        },
        {
          name: "default",
          value: THEME.DEFAULT,
        },
        {
          name: "colored",
          value: THEME.COLORED,
        },
        // {
        //   name: "Dark",
        //   value: THEME.DARK,
        // },
      ];
      let val = themeOptions.filter(
        (elem) => elem.name === bdata.business_theme
      );
      console.log("setting", val, bdata.business_theme);

      if (val.length !== 0) {
        setTheme(val[0].value);
      } else {
        setTheme("light");
      }
    } else {
      setTheme("light");
    }
    // setTheme();
  }, [bdata]);

  useEffect(async () => {
    console.log(theme);
    if (theme && theme !== bdata.business_theme) {
      var user = firebase.auth().currentUser;

      if (user) {
        let tokenss = await user.getIdToken();

        fetch("https://bestmate.us/api/business/update", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
          body: JSON.stringify({
            business_theme: theme,
          }),
        })
          .then(async (res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
