import {
  Bell,
  BookOpen,
  Calendar,
  CheckSquare,
  Grid,
  Heart,
  Layout,
  List,
  PieChart,
  Sliders,
  MapPin,
  Users,
  Share,
  Archive,
  FileText,
  Tag,
  DollarSign,
  Settings,
  CreditCard,
  Home,
  Clock,
  LogOut,
  User,
  Globe,
} from "react-feather";
import useAuth from "../../hooks/useAuth";

const useNav = () => {
  const { userPermission, isTeamAccount } = useAuth();
  console.log(userPermission);
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";

  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let schedulePermission =
    Object.keys(userPermission).includes("schedule") &&
    Object.keys(userPermission.schedule).includes("permission_key")
      ? userPermission.schedule.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  let timesheetPermission =
    Object.keys(userPermission).includes("timesheet") &&
    Object.keys(userPermission.timesheet).includes("permission_key")
      ? userPermission.timesheet.permission_key
      : "";
  let expensePermission =
    Object.keys(userPermission).includes("expense") &&
    Object.keys(userPermission.expense).includes("permission_key")
      ? userPermission.expense.permission_key
      : "";
  console.log(
    userPermission,
    estimatePermission,
    bookingPermission,
    invoicePermission,
    requestPermission
  );
  console.log(isTeamAccount);
  const pagesSection = !isTeamAccount
    ? [
        {
          href: "/dashboard",
          icon: Home,
          title: "Home",
        },
        {
          href: "/invoice",
          icon: FileText,
          title: "Work",
          // badge: "Yash",
          children: [
            {
              href: "/requests",
              title: "Requests",
            },
            {
              href: "/estimates",
              title: "Estimates",
            },
            {
              href: "/bookings",
              title: "Bookings",
            },
            {
              href: "/invoices",
              title: "Invoices",
            },
          ],
        },
        {
          href: "/schedule",
          icon: Calendar,
          title: "Schedule",
        },
        {
          href: "/items",
          icon: Archive,
          title: "Items",
          children: [
            {
              href: "/products",
              title: "Products",
            },
            {
              href: "/services",
              title: "Services",
            },
          ],
        },
        // {
        //   href: "/partners",
        //   icon: Users,
        //   title: "Partners",
        //   children: [
        //     {
        //       href: "/customer",
        //       title: "Customer",
        //     },
        //     {
        //       href: "/supplier",
        //       title: "Supplier",
        //     },
        //   ],
        // },
        {
          href: "/customer",
          icon: Users,
          title: "Customers",
        },
        {
          href: "/lead",
          icon: Users,
          title: "Leads",
        },
        {
          href: "/supplier",
          icon: Users,
          title: "Suppliers",
        },
        {
          href: "/expense",
          icon: CreditCard,
          title: "Expenses",
        },
        {
          href: "/timesheet",
          icon: Clock,
          title: "Timesheet",
        },
        {
          href: "/transaction",
          icon: DollarSign,
          title: "Transactions",
        },
        {
          href: "/subscription",
          icon: CheckSquare,
          title: "Subscription",
        },
        {
          href: "/payments",
          icon: DollarSign,
          title: "Payments",
        },
        {
          href: "/manage-team",
          icon: Users,
          title: "Manage Team",
        },
        {
          href: "/business-website",
          icon: Globe,
          title: "Business Website",
        },
        {
          href: "/profile",
          icon: Settings,
          title: "Settings",
        },
        {
          href: "/",
          icon: LogOut,
          title: "Log Out",
        },
      ]
    : [];
  if (isTeamAccount) {
    pagesSection.push({
      href: "/dashboard",
      icon: Home,
      title: "Home",
    });
  }
  if (
    isTeamAccount &&
    (requestPermission ||
      estimatePermission ||
      bookingPermission ||
      invoicePermission)
  ) {
    pagesSection.push({
      href: "/invoice",
      icon: FileText,
      title: "Work",
      children: [],
    });
  }
  if (isTeamAccount && requestPermission) {
    pagesSection[1].children.push({
      href: "/requests",
      title: "Requests",
    });
  }
  if (isTeamAccount && estimatePermission) {
    pagesSection[1].children.push({
      href: "/estimates",
      title: "Estimates",
    });
  }
  if (isTeamAccount && bookingPermission) {
    pagesSection[1].children.push({
      href: "/bookings",
      title: "Bookings",
    });
  }
  if (isTeamAccount && invoicePermission) {
    pagesSection[1].children.push({
      href: "/invoices",
      title: "Invoices",
    });
  }
  if (
    isTeamAccount &&
    (bookingPermission || requestPermission || schedulePermission)
  ) {
    pagesSection.push({
      href: "/schedule",
      icon: Calendar,
      title: "Schedule",
    });
  }
  if (isTeamAccount && itemPermission) {
    pagesSection.push({
      href: "/items",
      icon: Archive,
      title: "Items",
      children: [
        {
          href: "/products",
          title: "Products",
        },
        {
          href: "/services",
          title: "Services",
        },
      ],
    });
  }
  if (isTeamAccount && partnerPermission && partnerPermission.includes("ADD")) {
    pagesSection.push({
      href: "/customer",
      icon: Users,
      title: "Customers",
    });
    pagesSection.push({
      href: "/lead",
      icon: Users,
      title: "Leads",
    });
    pagesSection.push({
      href: "/supplier",
      icon: Users,
      title: "Suppliers",
    });
  }
  if (isTeamAccount && expensePermission) {
    pagesSection.push({
      href: "/expense",
      icon: CreditCard,
      title: "Expenses",
    });
  }
  if (isTeamAccount && timesheetPermission) {
    pagesSection.push({
      href: "/timesheet",
      icon: Clock,
      title: "Timesheet",
    });
  }
  if (isTeamAccount) {
    pagesSection.push({
      href: "/user-profile",
      icon: Settings,
      title: "Settings",
    });
    pagesSection.push({
      href: "/",
      icon: LogOut,
      title: "Log Out",
    });
  }

  const navItems = [
    {
      title: "",
      pages: pagesSection,
    },
  ];
  return { navItems };
};

export default useNav;
