import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Delete, Trash, UploadCloud, X } from "react-feather";
import { useImageData } from "../contexts/ImageUploadContext";
import imageCompression from "browser-image-compression";
import s3 from "../aws-config";
import { v4 } from "uuid";

const AttachDocuments = ({
  imageData,
  setImageData,
  showModal,
  setShowModal,
}) => {
  //   const [files, setFiles] = useState([]);
  //   const { imageData, setImageData } = useImageData();
  const [uploadError, setUploadError] = useState("");
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploadsInProgress, setUploadsInProgress] = useState(0);
  const counter = useRef(0);
  const uploadToCloud = async (compressedFile) => {
    if (compressedFile == null) return;

    try {
      // setUploadsInProgress((prevCount) => {
      //   console.log(prevCount);
      //   return prevCount + 1;
      // });
      counter.current += 1;
      // const bucketName = "bestmate";
      const bucketName = "bestmate-attachments";
      const key = `work/${v4()}${compressedFile.name}`; // S3 object key

      const params = {
        Bucket: bucketName,
        Key: key,
        Body: compressedFile,
        ContentType: compressedFile.type,
      };
      const response = await s3.upload(params).promise();
      console.log(response, response.status);

      setImageData((old) => [
        ...old,
        {
          id: response.Location,
          description: "",
          key: response.Key,
          type: compressedFile.type,
        },
      ]);
      // setUploadsInProgress((prevCount) => {
      //   console.log(prevCount);
      //   return prevCount - 1;
      // });
      counter.current -= 1;
      console.log(uploadsInProgress);
      // if (uploadsInProgress === ) {
      //   setUploadStatus("");
      // }
    } catch (err) {
      console.log(err);
      // setUploadsInProgress((prevCount) => {
      //   console.log(prevCount);
      //   return prevCount - 1;
      // });
      counter.current -= 1;
      // if (uploadsInProgress === 1) {
      //   setUploadStatus("");
      // }
      setUploadError("Something went Wrong!");
    }
    if (counter.current === 0) {
      setUploadStatus("");
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {},
    disabled: uploadStatus || imageData.length >= 3 ? true : false,
    // maxFiles: 3 - imageData.length,
    maxFiles: 3,
    onDrop: async (acceptedFiles, error) => {
      setUploadStatus("Start");
      console.log(error, acceptedFiles);
      if (error.length !== 0) {
        setUploadError(error[0].errors[0].message);
        setUploadStatus("");
      } else {
        if (imageData.length >= 3 && acceptedFiles.length >= 1) {
          setUploadError("Too many files");
          setUploadStatus("");
          return;
        } else if (imageData.length === 2 && acceptedFiles.length > 1) {
          setUploadError("Too many files");
          setUploadStatus("");
          return;
        } else if (imageData.length === 1 && acceptedFiles.length > 2) {
          setUploadError("Too many files");
          setUploadStatus("");
          return;
        } else if (imageData.length === 0 && acceptedFiles.length > 3) {
          setUploadError("Too many files");
          setUploadStatus("");
          return;
        }
        setUploadError("");

        try {
          acceptedFiles.map(async (elem) => {
            // let elem = acceptedFiles[0];
            if (elem.type.includes("image")) {
              const compressedFile = await imageCompression(elem, {
                maxSizeMB: 10,
                maxWidthOrHeight: 2000,
              });
              if (elem.size > 10485760) {
                setUploadError("Select File Size upto 10 Mb");
                return;
              }
              // setUploadStatus("Start");
              return uploadToCloud(compressedFile);
              // console.log(elem, compressedFile);
            } else {
              if (elem.size > 10485760) {
                setUploadError("Select File Size upto 10 Mb");
                return;
              }
              // setUploadStatus("Start");
              return uploadToCloud(elem);
            }
          });
        } catch (err) {
          console.log(err);
          setUploadStatus("");
          setUploadError("Something went Wrong!");
        }
        //extract files
        //compress if image
        //upload to cloud
      }
    },
  });
  //   console.log(imageData);
  return (
    <>
      <Modal
        show={showModal}
        // onHide={() => props.setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 17,
                color: "black",
                margin: 0,
              }}
            >
              {`Photos & Documents`}
            </h1>
            <X
              color="black"
              style={{ cursor: "pointer" }}
              onClick={() => setShowModal(false)}
            />
          </div>

          <div style={{ padding: "10px 24px" }}>
            <Row>
              <Col sm={12} md={6} style={{ borderRight: "1px solid #f2f2f2" }}>
                <section
                //   onClick={() =>
                //     mixpanel.track(`Upload Logo (Business Profile Web)`)
                //   }
                // style={{ backgroundColor: "green", position: "relative" }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        width: "100%",
                        height: 300,
                        // backgroundColor: "pink",
                        borderRadius: 5,
                        border: "1px dashed #c9c8c7",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5,
                        cursor: "pointer",
                        backgroundColor:
                          imageData.length >= 3 ? "#edeff0" : "transparent",
                      }}
                    >
                      {uploadStatus ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Spinner
                            animation="border"
                            // variant="dark"
                            size="md"
                            style={{ color: "#407FEA" }}
                          />
                          <div style={{ marginTop: 20 }}>Uploading</div>
                        </div>
                      ) : (
                        <>
                          <UploadCloud
                            size={35}
                            strokeWidth={2}
                            stroke={imageData.length >= 3 ? "grey" : "#407FEA"}
                            style={{ marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            <span
                              style={{
                                color:
                                  imageData.length >= 3 ? "grey" : "#407FEA",
                              }}
                            >
                              Browse
                            </span>{" "}
                            or drop your files here
                          </div>
                          <div>Maximum 10MB in size.</div>
                          <div>Max 3 Files Allowed</div>
                        </>
                      )}
                      {uploadError ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: 11,
                            marginTop: 10,
                          }}
                        >
                          {uploadError}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </section>
              </Col>
              <Col sm={12} md={6}>
                {imageData.length !== 0 ? (
                  <>
                    {imageData.map((elem, index) => {
                      return (
                        <div
                          style={{
                            paddingBottom: 10,
                            paddingTop: 10,
                            display: "flex",
                            borderBottom: "1px solid #edebeb",
                          }}
                          key={index}
                        >
                          {!elem.type.includes("image") ? (
                            <div
                              style={{
                                height: 100,
                                width: 100,
                                backgroundColor: "#f5f5f5",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(elem.id)}
                            >
                              <div
                                style={{
                                  border: "1px solid grey",
                                  padding: 10,
                                  paddingRight: 15,
                                  paddingLeft: 15,
                                }}
                              >
                                {elem.type.split("/")[1].toUpperCase()}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                height: 100,
                                width: 100,
                                backgroundColor: "#f5f5f5",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(elem.id)}
                            >
                              <img
                                src={elem.id}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              padding: "0px 20px",
                              //   backgroundColor: "pink",
                              flex: 1,
                            }}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Description"
                              as="textarea"
                              rows="4"
                              value={elem.description}
                              onChange={(e) => {
                                // const updatedData = imageData.map((item) =>
                                //   item.id === elem.id
                                //     ? { ...item, description: e.target.value }
                                //     : item
                                // );
                                // console.log(updatedData);
                                setImageData((old) => {
                                  return old.map((item) =>
                                    item.id === elem.id
                                      ? { ...item, description: e.target.value }
                                      : item
                                  );
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Trash
                              size={20}
                              color="grey"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // const updatedData = imageData.filter(
                                //   (item) => item.id !== elem.id
                                // );
                                // console.log(updatedData);
                                setImageData((old) => {
                                  return old.filter(
                                    (item) => item.id !== elem.id
                                  );
                                });
                                // deleteImageFromS3(elem.key);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                    }}
                  >
                    No Image or Document Uploaded
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AttachDocuments;
