import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { User, Plus, X, Archive } from "react-feather";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import mixpanel from "mixpanel-browser";
import { useCurrencyFormating } from "../utils/useCurrencyFormating";
import useAuth from "../hooks/useAuth";
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
const SelectInvoicemodal = (props) => {
  let usercurrency = localStorage.getItem("currency") || "USD";
  const [invoicedata, setInvoicedata] = useState([]);
  const [selected, setSelected] = useState("Invoice");
  const [loading, setLoading] = useState(true);
  const [searchterm, setSearchterm] = useState("");
  const { currencyFormater } = useCurrencyFormating();
  const { isTeamAccount } = useAuth();

  const invoiceData = async (token) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);
    var user = firebase.auth().currentUser;
    let tokenss = await user.getIdToken();
    fetch(
      `https://bestmate.us/api/invoices/findAll?timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setInvoicedata(result);
          setLoading(false);
          console.log(result);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  useEffect(() => {
    if (props.openModals) {
      setInvoicedata([]);
      setSelected("Invoice");
      setLoading(true);
      invoiceData([]);
      setSearchterm("");
    } else {
    }
  }, [props.openModals]);

  return (
    <>
      <Modal
        show={props.openModals}
        onHide={() => props.setOpenModals(!props.openModals)}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              {`Select an Invoice`}
            </h1>
            <div
              onClick={() => {
                mixpanel.track("Close Modal (Select an Invoice Modal Web)");
                props.setOpenModals(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {/* {props.modloading ? ( */}
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            // <div style={{ padding: "20px 30px" }}>
            //   <p style={{ marginBottom: 10 }}>
            //     {`Which ${
            //       props.type.charAt(0).toUpperCase() + props.type.slice(1)
            //     } would you like to create this for?`}
            //   </p>
            //   <div
            //     style={{
            //       width: "100%",
            //       height: 500,
            //       border: "solid 1px #e1e1e1",
            //     }}
            //   >
            //     <div
            //       style={{
            //         width: "100%",
            //         height: 80,
            //         padding: 16,
            //         backgroundColor: "#f4f4f4",
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "space-between",
            //       }}
            //     >
            //       <Form.Group
            //         style={{
            //           width: "100%",
            //           margin: "0px 20px",
            //           height: 35,
            //         }}
            //       >
            //         <Form.Control
            //           type="text"
            //           value={props.searchterm}
            //           onChange={(e) => {
            //             props.setsearchtem(e.target.value);
            //           }}
            //           placeholder={`Search ${
            //             props.type.charAt(0).toUpperCase() + props.type.slice(1)
            //           }s`}
            //           style={{ height: 35 }}
            //         />
            //       </Form.Group>
            //       {/* <p style={{ margin: 0 }}>or</p> */}
            //       {/* <div
            //         style={{
            //           border: "1px #E7EAEC solid",
            //           borderRadius: "3px",
            //           padding: "0.375rem 0.75rem",
            //           display: "flex",
            //           justifyContent: "center",
            //           color: "white",
            //           backgroundColor: "#3f80ea",
            //           alignItems: "center",
            //           textDecoration: "none",
            //           fontSize: "inherit",
            //           cursor: "pointer",
            //           width: "100%",
            //           margin: "0px 20px",
            //         }}
            //         onClick={() => {
            //           // navigate("/customer/add-customer")
            //           props.setOpenModals(false);
            //           props.fetchPriceList();
            //           // props.setsavemodalopen("first");
            //           props.setCreatenewmodal(true);
            //         }}
            //       >
            //         <div style={{ fontWeight: "bold" }} onClick={() => {}}>
            //           {`Create New ${
            //             props.type.charAt(0).toUpperCase() + props.type.slice(1)
            //           }`}
            //         </div>
            //       </div> */}
            //     </div>
            //     <div
            //       style={{
            //         borderBottom: "1px solid black",
            //         color: "black",
            //         padding: 10,
            //       }}
            //     >
            //       {props.type.charAt(0).toUpperCase() + props.type.slice(1)}
            //     </div>
            //     <div
            //       style={{
            //         height: 375,
            //         width: "100%",
            //         overflow: "scroll",
            //       }}
            //     >
            //       <div
            //         style={{
            //           border: "1px #E7EAEC solid",
            //           // borderRadius: "3px",
            //           padding: "0.375rem 0.75rem",
            //           display: "flex",
            //           // justifyContent: "center",
            //           color: "white",
            //           backgroundColor: "#3f80ea",
            //           alignItems: "center",
            //           textDecoration: "none",
            //           fontSize: "inherit",
            //           cursor: "pointer",
            //           height: 50,
            //           width: "100%",
            //           // margin: "0px 20px",
            //         }}
            //         onClick={() => {
            //           // navigate("/customer/add-customer")
            //           props.setOpenModals(false);
            //           props.fetchPriceList();
            //           // props.setsavemodalopen("first");
            //           props.setCreatenewmodal(true);
            //         }}
            //       >
            //         <Plus
            //           size={20}
            //           strokeWidth={2}
            //           stroke="white"
            //           style={{ marginRight: 15 }}
            //         />
            //         <div style={{ fontWeight: "bold" }} onClick={() => {}}>
            //           {`Create New ${
            //             props.type.charAt(0).toUpperCase() + props.type.slice(1)
            //           }`}
            //         </div>
            //       </div>
            //       {props.options[props.index].options.length !== 0 &&
            //       props.options[props.index].options.filter((elem) =>
            //         elem.label
            //           .toLowerCase()
            //           .includes(props.searchterm.toLowerCase())
            //       ).length !== 0 ? (
            //         <>
            //           {props.options[props.index].options
            //             .filter((elem) =>
            //               elem.label
            //                 .toLowerCase()
            //                 .includes(props.searchterm.toLowerCase())
            //             )
            //             .map((elem) => (
            //               <div
            //                 style={{
            //                   color: "black",
            //                   padding: 10,
            //                   cursor: "pointer",
            //                   borderBottom: "1px solid #e1e1e1",
            //                   display: "flex",
            //                   // padding: 15,
            //                 }}
            //                 className="Listmodal"
            //                 onClick={(e) => {
            //                   console.log(elem.label);
            //                   props.setFieldValue(
            //                     "appointment_partner",
            //                     elem.label
            //                   );
            //                   props.setPartners(elem.value);
            //                   if (
            //                     props.type === "customer" ||
            //                     props.type === "supplier"
            //                   ) {
            //                     props.setData({
            //                       ...props.data,
            //                       invoice_partner: elem.value,
            //                     });
            //                   } else if (
            //                     props.type === "product" ||
            //                     props.type === "service"
            //                   ) {
            //                     const temp = props.data;
            //                     console.log(temp);
            //                     for (
            //                       let i = 0;
            //                       i < temp["invoice_order"].length;
            //                       i++
            //                     ) {
            //                       if (
            //                         temp["invoice_order"][i].item_id ===
            //                         elem.value.item_id
            //                       ) {
            //                         temp["invoice_order"][i].item_quantity =
            //                           temp["invoice_order"][i].item_quantity +
            //                           1;
            //                         console.log(temp);
            //                         // props.setData(temp);
            //                         let newchange = {
            //                           ...props.data,
            //                           invoice_order: temp.invoice_order,
            //                         };
            //                         props.setData(newchange);
            //                         props.setOpenModals(false);
            //                         return;
            //                       }
            //                     }
            //                     temp["invoice_order"].push(elem.value);
            //                     elem.value.item_quantity = 1;
            //                     console.log(temp);
            //                     let newchange = {
            //                       ...props.data,
            //                       invoice_order: temp.invoice_order,
            //                     };
            //                     props.setData(newchange);
            //                     // return;
            //                   }
            //                   props.setOpenModals(false);
            //                 }}
            //               >
            //                 {props.type === "customer" ||
            //                 props.type === "supplier" ? (
            //                   <>
            //                     <User
            //                       size={20}
            //                       strokeWidth={2}
            //                       stroke="#007fff"
            //                       style={{ marginRight: 15 }}
            //                     />

            //                     <div>
            //                       <p style={{ margin: 0, fontWeight: "bold" }}>
            //                         {elem.label}
            //                       </p>
            //                       <p style={{ margin: 0 }}>
            //                         {elem.value.partner_number}
            //                       </p>
            //                     </div>
            //                   </>
            //                 ) : (
            //                   <>
            //                     <Archive
            //                       size={20}
            //                       strokeWidth={2}
            //                       stroke="#007fff"
            //                       style={{ marginRight: 15 }}
            //                     />

            //                     <div
            //                       style={{
            //                         display: "flex",
            //                         flexGrow: 1,
            //                         justifyContent: "space-between",
            //                         // backgroundColor: "pink",
            //                       }}
            //                     >
            //                       <p style={{ margin: 0, fontWeight: "bold" }}>
            //                         {elem.label}
            //                       </p>
            //                       {props.partner === "CUSTOMER" ? (
            //                         <p
            //                           style={{
            //                             margin: 0,
            //                             marginRight: 10,
            //                             // fontWeight: "bold",
            //                           }}
            //                         >
            //                           {(
            //                             (elem.value.item_selling_price
            //                               ? Number(
            //                                   elem.value.item_selling_price
            //                                 )
            //                               : 0) *
            //                             (props.pricelist
            //                               ? Number(props.pricelist)
            //                               : 1)
            //                           ).toLocaleString("en-US", {
            //                             style: "currency",
            //                             currency: usercurrency,
            //                           })}
            //                         </p>
            //                       ) : (
            //                         <p
            //                           style={{
            //                             margin: 0,
            //                             marginRight: 10,
            //                             // fontWeight: "bold",
            //                           }}
            //                         >
            //                           {(elem.value.item_cost_price
            //                             ? Number(elem.value.item_cost_price)
            //                             : 0
            //                           ).toLocaleString("en-US", {
            //                             style: "currency",
            //                             currency: usercurrency,
            //                           })}
            //                         </p>
            //                       )}
            //                     </div>
            //                   </>
            //                 )}
            //               </div>
            //             ))}
            //         </>
            //       ) : (
            //         <>
            //           <div
            //             style={{
            //               height: "100%",
            //               width: "100%",
            //               // backgroundColor: "pink",
            //               display: "flex",
            //               flexDirection: "column",
            //               justifyContent: "center",
            //               alignItems: "center",
            //             }}
            //           >
            //             <p>{`Noting Found !!! Start by Creating New ${props.type}.`}</p>
            //             <div
            //               style={{
            //                 border: "1px #E7EAEC solid",
            //                 borderRadius: "3px",
            //                 padding: "0.375rem 0.75rem",
            //                 display: "flex",
            //                 justifyContent: "center",
            //                 color: "white",
            //                 backgroundColor: "#3f80ea",
            //                 alignItems: "center",
            //                 textDecoration: "none",
            //                 fontSize: "inherit",
            //                 cursor: "pointer",
            //                 width: 180,
            //                 margin: "0px 20px",
            //               }}
            //               onClick={() =>
            //                 // navigate("/customer/add-customer")
            //                 props.setOpenModals(false)
            //               }
            //             >
            //               <div
            //                 style={{ fontWeight: "bold" }}
            //                 onClick={() => {
            //                   props.fetchPriceList();
            //                   props.setCreatenewmodal(true);
            //                 }}
            //               >
            //                 {`Create New ${props.type}`}
            //               </div>
            //             </div>
            //           </div>
            //         </>
            //       )}
            //     </div>
            //   </div>
            // </div>
            <>
              <div style={{ padding: "20px 30px" }}>
                {/* <p style={{ marginBottom: 10 }}>{`Select Invoice`}</p> */}
                <div
                  style={{
                    width: "100%",
                    height: 500,
                    // backgroundColor: "pink",
                    border: "solid 1px #e1e1e1",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 80,
                      padding: 16,
                      backgroundColor: "#f4f4f4",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Group
                      style={{
                        width: "100%",
                        margin: "0px 20px",
                        height: 35,
                      }}
                    >
                      <Form.Control
                        type="text"
                        value={searchterm}
                        onChange={(e) => {
                          mixpanel.track(
                            "Search Invoice (Select an Invoice Modal Web)"
                          );
                          setSearchterm(e.target.value);
                        }}
                        placeholder={`Search ${selected}`}
                        style={{ height: 35 }}
                      />
                    </Form.Group>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #f5f5f5",
                      color: "black",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        // backgroundColor: "pink",
                        padding: 10,
                        borderBottom:
                          selected === "Invoice"
                            ? "2px solid #4f83cc"
                            : "1px solid #f5f5f5",
                        color: selected === "Invoice" ? "black" : "#7d7d7d",
                        fontWeight: selected === "Invoice" ? "bold" : "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mixpanel.track(
                          "Invoice Tab (Select an Invoice Modal Web)"
                        );
                        setSelected("Invoice");
                      }}
                    >
                      Invoices
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        // backgroundColor: "pink",
                        padding: 10,
                        borderBottom:
                          selected === "Booking"
                            ? "2px solid #71BE1D"
                            : "1px solid #f5f5f5",
                        color: selected === "Booking" ? "black" : "#7d7d7d",
                        fontWeight: selected === "Booking" ? "bold" : "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mixpanel.track(
                          "Booking Tab (Select an Invoice Modal Web)"
                        );
                        setSelected("Booking");
                      }}
                    >
                      Bookings
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        // backgroundColor: "pink",
                        padding: 10,
                        borderBottom:
                          selected === "Estimate"
                            ? "2px solid #DE5474"
                            : "1px solid #f5f5f5",
                        color: selected === "Estimate" ? "black" : "#7d7d7d",
                        fontWeight: selected === "Estimate" ? "bold" : "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mixpanel.track(
                          "Estimate Tab (Select an Invoice Modal Web)"
                        );
                        setSelected("Estimate");
                      }}
                    >
                      Estimates
                    </div>
                  </div>
                  <div
                    style={{ height: 375, width: "100%", overflow: "scroll" }}
                  >
                    {invoicedata.length !== 0 &&
                    invoicedata.filter((elem) => {
                      if (elem.invoice_workflow_type) {
                        return (
                          elem.invoice_workflow_type.toLowerCase() ===
                            (selected === "Booking"
                              ? "order"
                              : selected.toLowerCase()) &&
                          (
                            elem.invoice_partner.partner_first_name +
                            " " +
                            elem.invoice_partner.partner_last_name
                          )
                            .toLowerCase()
                            .includes(searchterm.toLowerCase())
                        );
                      }
                    }).length !== 0 ? (
                      <div>
                        {invoicedata
                          .filter((elem) => {
                            if (elem.invoice_workflow_type) {
                              return (
                                elem.invoice_workflow_type.toLowerCase() ===
                                  (selected === "Booking"
                                    ? "order"
                                    : selected.toLowerCase()) &&
                                (
                                  elem.invoice_partner.partner_first_name +
                                  " " +
                                  elem.invoice_partner.partner_last_name
                                )
                                  .toLowerCase()
                                  .includes(searchterm.toLowerCase())
                              );
                            }
                          })
                          .map((elem) => {
                            return (
                              <div
                                style={{
                                  color: "black",
                                  padding: 10,
                                  cursor: "pointer",
                                  borderBottom: "1px solid #e1e1e1",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    "Invoice Selected (Select an Invoice Modal Web)",
                                    {
                                      ...elem,
                                    }
                                  );
                                  props.setInvoiceData(elem);
                                  props.setOpenModals(false);
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 14,
                                    }}
                                  >
                                    {/* {elem.invoice_partner.partner_first_name +
                                      " " +
                                      elem.invoice_partner.partner_last_name} */}
                                    {truncateTextByWordCount(
                                      `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
                                      30
                                    )}
                                  </div>
                                  <div
                                    style={{ fontSize: 12, color: "grey" }}
                                  >{`${selected} No. : ${elem.invoice_number}`}</div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <div>
                                    {truncateTextByWordCount(
                                      currencyFormater(
                                        (Number(elem.invoice_grand_total)
                                          ? elem.invoice_grand_total
                                          : 0) -
                                          (Number(elem.invoice_settled_amount)
                                            ? elem.invoice_settled_amount
                                            : 0),
                                        elem.invoice_currency_code
                                          ? elem.invoice_currency_code
                                          : usercurrency
                                      ),
                                      25
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 11,
                                      color: "grey",
                                      display: "flex",
                                    }}
                                  >
                                    {/* <div>Total -</div> */}
                                    <div>
                                      {truncateTextByWordCount(
                                        // (Number(elem.invoice_grand_total)
                                        //   ? elem.invoice_grand_total
                                        //   : 0
                                        // ).toLocaleString("en-US", {
                                        //   style: "currency",
                                        //   currency: usercurrency,
                                        // }),
                                        currencyFormater(
                                          Number(elem.invoice_grand_total)
                                            ? elem.invoice_grand_total
                                            : 0,
                                          usercurrency
                                        ),

                                        25
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            //   height: "100%",
                            //   width: "100%",
                            height: 350,
                            //   backgroundColor: "pink",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p>{`No records found.`}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SelectInvoicemodal;
