import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import icon from "../../assets/img/illustrations/icon.png";
import google from "../../assets/img/illustrations/googlel.png";
import google3 from "../../assets/img/icons/google3.png";
import apple from "../../assets/img/illustrations/apple2.png";
import apple3 from "../../assets/img/icons/apple3.png";
import useAuth from "../../hooks/useAuth";
import mixpanel from "mixpanel-browser";
import { usePremium } from "../../contexts/PremiumContext";

const TeamAcceptSuccess = (props) => {
  const location = useLocation();
  const { setMoreInfo } = usePremium();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const signout = async (e) => {
    // e.preventDefault();
    try {
      await signOut();
      setMoreInfo(null);
      navigate("/");
      // navigate("/sign-in");
    } catch (error) {
      console.log(error.message);
    }
  };
  let isAcceptStatus = !props.isNormal;
  console.log("asdfpo");
  console.log(props);
  return (
    <React.Fragment>
      <Helmet title="Blank Page" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 40,
              display: "flex",
              flexDirection: "column",
              // marginBottom: 15,
              marginBottom: 50,

              alignItems: "center",
              // backgroundColor: "red",
              justifyContent: "center",
            }}
          >
            <img
              src={icon}
              alt="BestMate"
              id="authheaderlogo"
              style={{ alignSelf: "center", height: 40, width: 45 }}
            />
            <h3
              style={{
                fontSize: 10,
                fontWeight: "bold",
                // marginLeft: 5,
                marginTop: 3,
                marginBottom: 0,
              }}
            >
              BESTMATE
            </h3>
          </div>
          <div style={{ marginRight: 20, marginLeft: 20 }}>
            <div
              style={{
                // height: 200,
                maxWidth: 350,
                width: "100%",
                backgroundColor: "white",
                padding: 15,
              }}
            >
              <p
                style={{
                  marginBottom: 15,
                  fontSize: 30,
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {isAcceptStatus ? "Success!" : "Access Denied"}
              </p>
              <p style={{ marginBottom: 0, fontSize: 15, color: "black" }}>
                {isAcceptStatus
                  ? "Your account is ready to go."
                  : "Account Deactivated"}
              </p>
              <p style={{ marginBottom: 5, fontSize: 13 }}>
                {isAcceptStatus
                  ? "Start using Bestmate by downloading the app"
                  : "Please ask Owner / Admin to activate this teams account"}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                {/* <div
                  style={{
                    flex: 1,
                    borderWidth: 1,
                    borderColor: "#707070",
                    borderStyle: "solid",
                    margin: 20,
                    marginLeft: 0,
                    padding: 10,

                    cursor: "pointer",
                    display: "flex",
                  }}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.bestmate.pro",
                      "_blank"
                    )
                  }
                >
                  <div style={{ marginRight: 15 }}>
                    <img
                      src={google}
                      alt="google"
                      style={{ alignSelf: "center", height: 25 }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: 11,
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      GET IT ON
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      Google Play
                    </p>
                  </div>
                </div> */}
                {isAcceptStatus ? (
                  <>
                    {" "}
                    <div
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.bestmate.pro",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={google3}
                        alt="google3"
                        style={{ alignSelf: "center", maxHeight: 50 }}
                      />
                    </div>
                    <div
                      onClick={() =>
                        window.open(
                          "https://apps.apple.com/us/app/bestmate-small-business-app/id1626461580",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={apple3}
                        alt="apple"
                        style={{ alignSelf: "center", maxHeight: 49 }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {/* <div
                  style={{
                    flex: 1,
                    margin: 20,
                    borderWidth: 1,
                    borderColor: "#707070",
                    borderStyle: "solid",
                    marginRight: 0,
                    padding: 10,
                    cursor: "pointer",
                    display: "flex",
                  }}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/bestmate-small-business-app/id1626461580",
                      "_blank"
                    )
                  }
                >
                  <div style={{ marginRight: 15 }}>
                    <img
                      src={apple}
                      alt="apple"
                      style={{ alignSelf: "center", height: 30 }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: 11,
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      DOWNLOAD ON THE
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      App Store
                    </p>
                  </div>
                </div> */}
              </div>
              {/* <div style={{ fontSize: 12 }}>
                Don't have a supported device?{" "}
                <a href="/" style={{ color: "#407FEA" }}>
                  Open Bestmate Online
                </a>
              </div> */}
              {!isAcceptStatus ? (
                <div style={{ fontSize: 12, marginTop: 10 }}>
                  Login with differnt account?{" "}
                  <span
                    onClick={() => {
                      signout();
                    }}
                    style={{ color: "#407FEA", cursor: "pointer" }}
                  >
                    Logout
                  </span>
                </div>
              ) : (
                <div style={{ fontSize: 12, marginTop: 10 }}>
                  Want to use Bestmate on Web?{" "}
                  <span
                    onClick={() => {
                      navigate("/");
                      const cameFromShare = location.state?.from === "/share";
                      if (cameFromShare) {
                        window.location.reload(true);
                      }
                    }}
                    style={{ color: "#407FEA", cursor: "pointer" }}
                  >
                    Continue here
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default TeamAcceptSuccess;
