import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span
                className="text-muted"
                style={{ cursor: "pointer" }}
                href="#"
                onClick={() => window.open("mailto: support@bestmate.us")}
              >
                Support
              </span>
            </li>
            <li
              className="list-inline-item"
              onClick={() => {
                window.tidioChatApi.open();
              }}
              style={{ cursor: "pointer", marginLeft: 5 }}
            >
              <span className="text-muted" href="#">
                Help Center
              </span>
            </li>
            <li
              className="list-inline-item"
              onClick={() => {
                window.open("https://bestmate.us/privacy.html", "_blank");
              }}
              style={{ cursor: "pointer", marginLeft: 5 }}
            >
              <span className="text-muted" href="#">
                Privacy
              </span>
            </li>
            <li
              className="list-inline-item"
              onClick={() => {
                window.open(
                  "https://bestmate.us/terms-of-services.html",
                  "_blank"
                );
              }}
              style={{ cursor: "pointer", marginLeft: 5 }}
            >
              <span className="text-muted" href="#">
                Terms of Service
              </span>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            &copy; 2024 {/* &copy; {new Date().getFullYear()} -{" "} */}
            <span href="/" className="text-muted">
              Tango Ventures Inc
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
