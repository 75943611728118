export const COLUMN = [
  {
    id: "AED",
    name: "United Arab Emirates Dirham",
  },
  {
    id: "AFN",
    name: "Afghan Afghani",
  },
  {
    id: "ALL",
    name: "Albanian Lek",
  },
  {
    id: "AMD",
    name: "Armenian Dram",
  },
  {
    id: "ANG",
    name: "Netherlands Antillean Gulden",
  },
  {
    id: "AOA",
    name: "Angolan Kwanza",
  },
  {
    id: "ARS",
    name: "Argentine Peso",
  },
  {
    id: "AUD",
    name: "Australian Dollar",
  },
  {
    id: "AWG",
    name: "Aruban Florin",
  },
  {
    id: "AZN",
    name: "Azerbaijani Manat",
  },
  {
    id: "BAM",
    name: "Bosnia and Herzegovina Convertible Mark",
  },
  {
    id: "BBD",
    name: "Barbadian Dollar",
  },
  {
    id: "BDT",
    name: "Bangladeshi Taka",
  },
  {
    id: "BGN",
    name: "Bulgarian Lev",
  },
  {
    id: "BHD",
    name: "Bahraini Dinar",
  },
  {
    id: "BIF",
    name: "Burundian Franc",
  },
  {
    id: "BMD",
    name: "Bermudian Dollar",
  },
  {
    id: "BND",
    name: "Brunei Dollar",
  },
  {
    id: "BOB",
    name: "Bolivian Boliviano",
  },
  {
    id: "BRL",
    name: "Brazilian Real",
  },
  {
    id: "BSD",
    name: "Bahamian Dollar",
  },
  {
    id: "BTN",
    name: "Bhutanese Ngultrum",
  },
  {
    id: "BWP",
    name: "Botswana Pula",
  },
  {
    id: "BYN",
    name: "Belarusian Ruble",
  },
  {
    id: "BYR",
    name: "Belarusian Ruble",
  },
  {
    id: "BZD",
    name: "Belize Dollar",
  },
  {
    id: "CAD",
    name: "Canadian Dollar",
  },
  {
    id: "CDF",
    name: "Congolese Franc",
  },
  {
    id: "CHF",
    name: "Swiss Franc",
  },
  {
    id: "CLF",
    name: "Unidad de Fomento",
  },
  {
    id: "CLP",
    name: "Chilean Peso",
  },
  {
    id: "CNH",
    name: "Chinese Renminbi Yuan Offshore",
  },
  {
    id: "CNY",
    name: "Chinese Renminbi Yuan",
  },
  {
    id: "COP",
    name: "Colombian Peso",
  },
  {
    id: "CRC",
    name: "Costa Rican Colón",
  },
  {
    id: "CUC",
    name: "Cuban Convertible Peso",
  },
  {
    id: "CVE",
    name: "Cape Verdean Escudo",
  },
  {
    id: "CZK",
    name: "Czech Koruna",
  },
  {
    id: "DJF",
    name: "Djiboutian Franc",
  },
  {
    id: "DKK",
    name: "Danish Krone",
  },
  {
    id: "DOP",
    name: "Dominican Peso",
  },
  {
    id: "DZD",
    name: "Algerian Dinar",
  },
  {
    id: "EGP",
    name: "Egyptian Pound",
  },
  {
    id: "ETB",
    name: "Ethiopian Birr",
  },
  {
    id: "EUR",
    name: "Euro",
  },
  {
    id: "FJD",
    name: "Fijian Dollar",
  },
  {
    id: "FKP",
    name: "Falkland Pound",
  },
  {
    id: "GBP",
    name: "British Pound",
  },
  {
    id: "GBX",
    name: "British Penny",
  },
  {
    id: "GEL",
    name: "Georgian Lari",
  },
  {
    id: "GGP",
    name: "Guernsey Pound",
  },
  {
    id: "GHS",
    name: "Ghanaian Cedi",
  },
  {
    id: "GIP",
    name: "Gibraltar Pound",
  },
  {
    id: "GMD",
    name: "Gambian Dalasi",
  },
  {
    id: "GNF",
    name: "Guinean Franc",
  },
  {
    id: "GTQ",
    name: "Guatemalan Quetzal",
  },
  {
    id: "GYD",
    name: "Guyanese Dollar",
  },
  {
    id: "HKD",
    name: "Hong Kong Dollar",
  },
  {
    id: "HNL",
    name: "Honduran Lempira",
  },
  {
    id: "HRK",
    name: "Croatian Kuna",
  },
  {
    id: "HTG",
    name: "Haitian Gourde",
  },
  {
    id: "HUF",
    name: "Hungarian Forint",
  },
  {
    id: "IDR",
    name: "Indonesian Rupiah",
  },
  {
    id: "ILS",
    name: "Israeli New Sheqel",
  },
  {
    id: "IMP",
    name: "Isle of Man Pound",
  },
  {
    id: "INR",
    name: "Indian Rupee",
  },
  {
    id: "IQD",
    name: "Iraqi Dinar",
  },
  {
    id: "ISK",
    name: "Icelandic Króna",
  },
  {
    id: "JEP",
    name: "Jersey Pound",
  },
  {
    id: "JMD",
    name: "Jamaican Dollar",
  },
  {
    id: "JOD",
    name: "Jordanian Dinar",
  },
  {
    id: "JPY",
    name: "Japanese Yen",
  },
  {
    id: "KES",
    name: "Kenyan Shilling",
  },
  {
    id: "KGS",
    name: "Kyrgyzstani Som",
  },
  {
    id: "KHR",
    name: "Cambodian Riel",
  },
  {
    id: "KMF",
    name: "Comorian Franc",
  },
  {
    id: "KRW",
    name: "South Korean Won",
  },
  {
    id: "KWD",
    name: "Kuwaiti Dinar",
  },
  {
    id: "KYD",
    name: "Cayman Islands Dollar",
  },
  {
    id: "KZT",
    name: "Kazakhstani Tenge",
  },
  {
    id: "LAK",
    name: "Lao Kip",
  },
  {
    id: "LBP",
    name: "Lebanese Pound",
  },
  {
    id: "LKR",
    name: "Sri Lankan Rupee",
  },
  {
    id: "LRD",
    name: "Liberian Dollar",
  },
  {
    id: "LSL",
    name: "Lesotho Loti",
  },
  {
    id: "LTL",
    name: "Lithuanian Litas",
  },
  {
    id: "LYD",
    name: "Libyan Dinar",
  },
  {
    id: "MAD",
    name: "Moroccan Dirham",
  },
  {
    id: "MDL",
    name: "Moldovan Leu",
  },
  {
    id: "MGA",
    name: "Malagasy Ariary",
  },
  {
    id: "MKD",
    name: "Macedonian Denar",
  },
  {
    id: "MMK",
    name: "Myanmar Kyat",
  },
  {
    id: "MNT",
    name: "Mongolian Tögrög",
  },
  {
    id: "MOP",
    name: "Macanese Pataca",
  },
  {
    id: "MUR",
    name: "Mauritian Rupee",
  },
  {
    id: "MVR",
    name: "Maldivian Rufiyaa",
  },
  {
    id: "MWK",
    name: "Malawian Kwacha",
  },
  {
    id: "MXN",
    name: "Mexican Peso",
  },
  {
    id: "MYR",
    name: "Malaysian Ringgit",
  },
  {
    id: "MZN",
    name: "Mozambican Metical",
  },
  {
    id: "NAD",
    name: "Namibian Dollar",
  },
  {
    id: "NGN",
    name: "Nigerian Naira",
  },
  {
    id: "NIO",
    name: "Nicaraguan Córdoba",
  },
  {
    id: "NOK",
    name: "Norwegian Krone",
  },
  {
    id: "NPR",
    name: "Nepalese Rupee",
  },
  {
    id: "NZD",
    name: "New Zealand Dollar",
  },
  {
    id: "OMR",
    name: "Omani Rial",
  },
  {
    id: "PAB",
    name: "Panamanian Balboa",
  },
  {
    id: "PEN",
    name: "Peruvian Sol",
  },
  {
    id: "PGK",
    name: "Papua New Guinean Kina",
  },
  {
    id: "PHP",
    name: "Philippine Peso",
  },
  {
    id: "PKR",
    name: "Pakistani Rupee",
  },
  {
    id: "PLN",
    name: "Polish Złoty",
  },
  {
    id: "PYG",
    name: "Paraguayan Guaraní",
  },
  {
    id: "QAR",
    name: "Qatari Riyal",
  },
  {
    id: "RON",
    name: "Romanian Leu",
  },
  {
    id: "RSD",
    name: "Serbian Dinar",
  },
  {
    id: "RUB",
    name: "Russian Ruble",
  },
  {
    id: "RWF",
    name: "Rwandan Franc",
  },
  {
    id: "SAR",
    name: "Saudi Riyal",
  },
  {
    id: "SBD",
    name: "Solomon Islands Dollar",
  },
  {
    id: "SCR",
    name: "Seychellois Rupee",
  },
  {
    id: "SEK",
    name: "Swedish Krona",
  },
  {
    id: "SGD",
    name: "Singapore Dollar",
  },
  {
    id: "SHP",
    name: "Saint Helenian Pound",
  },
  {
    id: "SLL",
    name: "Sierra Leonean Leone",
  },
  {
    id: "SOS",
    name: "Somali Shilling",
  },
  {
    id: "SRD",
    name: "Surinamese Dollar",
  },
  {
    id: "SSP",
    name: "South Sudanese Pound",
  },
  {
    id: "STD",
    name: "São Tomé and Príncipe Dobra",
  },
  {
    id: "SVC",
    name: "Salvadoran Colón",
  },
  {
    id: "SZL",
    name: "Swazi Lilangeni",
  },
  {
    id: "THB",
    name: "Thai Baht",
  },
  {
    id: "TJS",
    name: "Tajikistani Somoni",
  },
  {
    id: "TMT",
    name: "Turkmenistani Manat",
  },
  {
    id: "TND",
    name: "Tunisian Dinar",
  },
  {
    id: "TOP",
    name: "Tongan Paʻanga",
  },
  {
    id: "TRY",
    name: "Turkish Lira",
  },
  {
    id: "TTD",
    name: "Trinidad and Tobago Dollar",
  },
  {
    id: "TWD",
    name: "New Taiwan Dollar",
  },
  {
    id: "TZS",
    name: "Tanzanian Shilling",
  },
  {
    id: "UAH",
    name: "Ukrainian Hryvnia",
  },
  {
    id: "UGX",
    name: "Ugandan Shilling",
  },
  {
    id: "USD",
    name: "United States Dollar",
  },
  {
    id: "UYU",
    name: "Uruguayan Peso",
  },
  {
    id: "UZS",
    name: "Uzbekistan Som",
  },
  {
    id: "VEF",
    name: "Venezuelan Bolívar",
  },
  {
    id: "VES",
    name: "Venezuelan Bolívar Soberano",
  },
  {
    id: "VND",
    name: "Vietnamese Đồng",
  },
  {
    id: "VUV",
    name: "Vanuatu Vatu",
  },
  {
    id: "WST",
    name: "Samoan Tala",
  },
  {
    id: "XAF",
    name: "Central African Cfa Franc",
  },
  {
    id: "XAG",
    name: "Silver (Troy Ounce)",
  },
  {
    id: "XAU",
    name: "Gold (Troy Ounce)",
  },
  {
    id: "XBA",
    name: "European Composite Unit",
  },
  {
    id: "XCD",
    name: "East Caribbean Dollar",
  },
  {
    id: "XDR",
    name: "Special Drawing Rights",
  },
  {
    id: "XOF",
    name: "West African Cfa Franc",
  },
  {
    id: "XPD",
    name: "Palladium",
  },
  {
    id: "XPF",
    name: "Cfp Franc",
  },
  {
    id: "XPT",
    name: "Platinum",
  },
  {
    id: "XTS",
    name: "Codes specifically reserved for testing purposes",
  },
  {
    id: "YER",
    name: "Yemeni Rial",
  },
  {
    id: "ZAR",
    name: "South African Rand",
  },
  {
    id: "ZMW",
    name: "Zambian Kwacha",
  },
  {
    id: "ZWD",
    name: "Zimbabwean Dollar",
  },
  {
    id: "ZWL",
    name: "Zimbabwean Dollar",
  },
];
