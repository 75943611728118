import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: "AKIA3L5F2Z7GJEE5OHWP",
  // accessKeyId: "AKIA3P6V52IVVB6W7ZTN", <-- practice
  secretAccessKey: "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",
  // secretAccessKey: "JFTLJme1zGROQtsRouxQZmJoHrw/RvN32J006vbv", <--practice
  // secretAccessKey: "jVsK3Tc/+52B93A2kai9CdfSbmxi3UHqBEzsHvtt",
  region: "us-east-1",
  // Bucket: "bestmate",
  Bucket: "bestmate-attachments",
  signtureVersion: "v4",
});

export default s3;
