import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
  InputGroup,
} from "react-bootstrap";
import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import * as Yup from "yup";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import mixpanel from "mixpanel-browser";
import useAuth from "../hooks/useAuth";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const Newitemmodal = (props) => {
  const [savemodalloading, setSavemodalloading] = useState(false);
  const [savemodalopen, setsavemodalopen] = useState("first");
  const [disablesave, setDisablesave] = useState(false);
  const [globalValidationError, setGlobalValidationError] = useState(false);
  const { isTeamAccount } = useAuth();
  let usercurrency = localStorage.getItem("currency") || "USD";
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (props.createnewmodal) {
      setDisablesave(false);
    }
  }, [props.createnewmodal]);
  const submitData = async (data) => {
    mixpanel.track(`Save >>>  (Create ${props.type} Modal Web)`, { ...data });
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        data.team = true;
      } else {
        delete data.team;
      }

      fetch("https://bestmate.us/api/items/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          console.log(res);
          const result = await res.json();
          // navigate(`/items/${small}s`);
          // notyf.open({
          //   type: isOpen ? "home" : "full",
          //   message: "Item added successfully",
          //   ripple: "true",
          //   dismissible: "true",
          // });
          let obj = { ...result, item_quantity: 1 };
          if (
            obj &&
            (!Object.keys(obj).includes("item_cost_price") ||
              !obj.item_cost_price)
          ) {
            obj.item_cost_price = 0;
          }
          if (
            obj &&
            (!Object.keys(obj).includes("item_selling_price") ||
              !obj.item_selling_price)
          ) {
            obj.item_selling_price = 0;
          }
          let newchange = {
            ...props.data,
            invoice_order: [...props.data.invoice_order, obj],
          };
          console.log(newchange);
          props.setData(newchange);
          setsavemodalopen("first");
          props.setCreatenewmodal(false);
          setGlobalValidationError(false);
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
        });
    }
    // });
  };

  // submitHandler function is used to arrange data before sending to backend
  const submitHandler = (elem) => {
    setDisablesave(true);

    const data = {
      //   item_name: elem.itemName,
      //   item_description: elem.description,
      //   //   item_description: desc,
      //   item_unit_type: elem.unit,
      //   item_selling_price: elem.sell.toString(),
      //   item_cost_price: elem.cost.toString(),
      //   item_code: elem.code,
      item_type: props.type === "product" ? "PRODUCT" : "SERVICE",
      //   item_currency: usercurrency,
      //   item_supplier: elem.supplier,
      //   item_quantity: elem.quantity.toString(),
      //   item_quantity_per_box: elem.quantityperbox.toString(),
    };
    if (elem.itemName) {
      data.item_name = capitalizeFirstLetter(elem.itemName);
    }
    if (elem.description) {
      data.item_description = elem.description;
    }
    if (elem.unit) {
      data.item_description = elem.unit;
    }
    if (elem.sell.toString()) {
      data.item_selling_price = elem.sell.toString();
    }
    if (elem.cost.toString()) {
      data.item_cost_price = elem.cost.toString();
    }
    if (elem.code) {
      data.item_code = elem.code;
    }
    if (elem.supplier) {
      data.item_supplier = elem.supplier;
    }
    if (elem.quantity.toString()) {
      data.item_quantity = elem.quantity.toString();
    }
    if (elem.quantityperbox.toString()) {
      data.item_quantity_per_box = elem.quantityperbox.toString();
    }
    console.log(data);
    submitData(data);
  };

  return (
    <>
      <Modal
        show={props.createnewmodal}
        onHide={() => {
          mixpanel.track(`Close >>>  (Create ${props.type} Modal Web)`);
          setsavemodalopen("first");
          props.setCreatenewmodal(false);
          setGlobalValidationError(false);
        }}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              {`Create new ${props.type}`}
            </h1>
            <div
              onClick={() => {
                mixpanel.track(`Close >>>  (Create ${props.type} Modal Web)`);
                setsavemodalopen("first");
                props.setCreatenewmodal(false);
                setGlobalValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {savemodalloading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "10px 24px" }}>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={
                  props.type === "product"
                    ? Yup.object().shape({
                        itemName: Yup.string()
                          .required(
                            `${
                              props.type.charAt(0).toUpperCase() +
                              props.type.slice(1)
                            } Name is Requied`
                          )
                          .max(
                            200,
                            `${
                              props.type.charAt(0).toUpperCase() +
                              props.type.slice(1)
                            } name must be less than 200 characters`
                          ),
                        sell: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Number must be greater than zero"),
                        cost: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Number must be greater than zero"),
                        quantity: Yup.number()
                          .typeError("Enter a valid number")
                          .positive("Must be greater than 0"),
                        quantityperbox: Yup.number()
                          .typeError("Enter a valid number")
                          .positive("Must be greater than 0"),
                        // .moreThan(0, "Inventory must be greater than zero"),
                      })
                    : Yup.object().shape({
                        itemName: Yup.string()
                          .required(
                            `${
                              props.type.charAt(0).toUpperCase() +
                              props.type.slice(1)
                            } Name is Requied`
                          )
                          .max(
                            200,
                            `${
                              props.type.charAt(0).toUpperCase() +
                              props.type.slice(1)
                            } name must be less than 200 characters`
                          ),
                        sell: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Number must be greater than zero"),
                        cost: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Number must be greater than zero"),
                      })
                }
                onSubmit={(elem) => submitHandler(elem)}
                initialValues={{
                  itemName: "",
                  description: "",
                  unit: "",
                  sell: "",
                  cost: "",
                  code: "",
                  supplier: "",
                  quantity: "",
                  quantityperbox: "",
                  type: props.type.toUpperCase(),
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  validateForm,
                }) => (
                  <Form
                    noValidate
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const obj = await validateForm();
                      console.log(obj);
                      if (Object.keys(obj).length !== 0) {
                        setGlobalValidationError(true);
                      } else {
                        setGlobalValidationError(false);
                        handleSubmit();
                      }
                    }}
                    // onKeyDown={(keyEvent) => {
                    //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    //   }
                    // }}
                  >
                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        // backgroundColor: "pink",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("first")}
                    >
                      <div>{`${
                        props.type.charAt(0).toUpperCase() + props.type.slice(1)
                      } Details`}</div>
                      {savemodalopen === "first" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "first" ? (
                      <>
                        <div style={{ margin: "5px 0" }}>
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label={`${
                                  props.type.charAt(0).toUpperCase() +
                                  props.type.slice(1)
                                } Name`}
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="itemName"
                                  onKeyDown={handleKeyDown}
                                  placeholder={`${
                                    props.type.charAt(0).toUpperCase() +
                                    props.type.slice(1)
                                  } Name`}
                                  value={values.itemName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.itemName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.itemName}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label={`${
                                  props.type.charAt(0).toUpperCase() +
                                  props.type.slice(1)
                                } Code`}
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="code"
                                  onKeyDown={handleKeyDown}
                                  placeholder={`${
                                    props.type.charAt(0).toUpperCase() +
                                    props.type.slice(1)
                                  } Code`}
                                  value={values.code}
                                  onChange={handleChange}
                                  style={{ padding: "8px" }}
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Description"
                            className="mb-3"
                          >
                            <Form.Control
                              as="textarea"
                              rows="6"
                              type="text"
                              name="description"
                              placeholder="Type something"
                              value={values.description}
                              onChange={handleChange}
                              // isInvalid={errors.item_name}
                            />
                          </FloatingLabel>
                          {props.type === "product" ? (
                            <Row className="g-2">
                              <Col md>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Supplier"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    name="supplier"
                                    placeholder="Supplier"
                                    value={values.supplier}
                                    onChange={handleChange}
                                  />
                                </FloatingLabel>
                              </Col>
                              <Col md>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Quantity Per Box"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    min={0}
                                    name="quantityperbox"
                                    placeholder="Quantity Per Box"
                                    value={values.quantityperbox}
                                    onChange={(event) => {
                                      if (
                                        event.target.value.replace(/\s/g, "")
                                          .length <= 10
                                      ) {
                                        handleChange(event);
                                      }
                                    }}
                                    style={{ padding: "8px" }}
                                    isInvalid={!!errors.quantityperbox}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.quantityperbox}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <hr style={{ margin: 0 }} />

                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("second")}
                    >
                      <div>
                        {props.type === "product"
                          ? "Pricing & Inventory"
                          : "Pricing"}
                      </div>
                      {savemodalopen === "second" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "second" ? (
                      <>
                        <div style={{ margin: "5px 0" }}>
                          <Row className="g-2">
                            <Col md>
                              <Form.Group className="mb-3">
                                <Form.Label style={{ color: "black" }}>
                                  {props.type === "product"
                                    ? `Selling Price`
                                    : `Service Charge`}
                                </Form.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    {getSymbolFromCurrency(usercurrency)}
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    name="sell"
                                    min={0}
                                    placeholder={
                                      props.type === "product"
                                        ? `Selling Price`
                                        : `Service Charge`
                                    }
                                    value={values.sell}
                                    onChange={(event) => {
                                      if (
                                        event.target.value.replace(/\s/g, "")
                                          .length <= 10
                                      ) {
                                        handleChange(event);
                                      }
                                    }}
                                    style={{ padding: "8px" }}
                                    isInvalid={!!errors.sell}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.sell}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col md>
                              <Form.Group className="mb-3">
                                <Form.Label style={{ color: "black" }}>
                                  {props.type === "product"
                                    ? `Cost Price`
                                    : `Service Cost`}
                                </Form.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    {getSymbolFromCurrency(usercurrency)}
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    name="cost"
                                    onKeyDown={handleKeyDown}
                                    min={0}
                                    placeholder={
                                      props.type === "product"
                                        ? `Cost Price`
                                        : `Service Cost`
                                    }
                                    value={values.cost}
                                    onChange={(event) => {
                                      if (
                                        event.target.value.replace(/\s/g, "")
                                          .length <= 10
                                      ) {
                                        handleChange(event);
                                      }
                                    }}
                                    style={{ padding: "8px" }}
                                    isInvalid={!!errors.cost}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.cost}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          {props.type === "product" ? (
                            <Form.Group className="mb-3">
                              <Form.Label style={{ color: "black" }}>
                                Inventory
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="quantity"
                                onKeyDown={handleKeyDown}
                                min={0}
                                isInvalid={!!errors.quantity}
                                placeholder="Inventory"
                                value={values.quantity}
                                onChange={(event) => {
                                  if (
                                    event.target.value.replace(/\s/g, "")
                                      .length <= 10
                                  ) {
                                    handleChange(event);
                                  }
                                }}
                                // onChange={handleChange}
                                style={{ padding: "8px" }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.quantity}
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : (
                            <Form.Group className="mb-3">
                              <Form.Label style={{ color: "black" }}>
                                Unit Type
                              </Form.Label>
                              <Form.Control
                                type="text"
                                onKeyDown={handleKeyDown}
                                name="unit"
                                placeholder="Unit Type"
                                value={values.unit}
                                onChange={handleChange}
                                style={{ padding: "8px" }}
                              />
                            </Form.Group>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <hr style={{ margin: 0 }} />
                    {globalValidationError ? (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          alignItems: "center",
                        }}
                      >
                        <AlertCircle
                          size={15}
                          strokeWidth={2}
                          stroke="#d8534e"
                        />
                        <div
                          style={{
                            color: "#d8534e",
                            fontSize: 11,
                            fontWeight: "400",
                            marginLeft: 5,
                          }}
                        >
                          There was an error saving the form. Please check the
                          messages above and try again.
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Close >>>  (Create ${props.type} Modal Web)`
                          );
                          setsavemodalopen("first");
                          props.setCreatenewmodal(false);
                          setGlobalValidationError(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                        }}
                        type="submit"
                        disabled={disablesave}
                      >
                        {`Create ${
                          props.type.charAt(0).toUpperCase() +
                          props.type.slice(1)
                        }`}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Newitemmodal;
