import React, { useEffect, useState } from "react";

function MyIframeComponent() {
  //   const [link, setlink] = useState(false);
  useEffect(() => {});
  //   useEffect(() => {
  //     const iframe = document.createElement("iframe");
  //     iframe.src = "http://example.com";
  //     iframe.title = "My Iframe";

  //     const headers = {
  //       Authorization: "Bearer your-access-token-here",
  //     };

  //     const headersString = Object.keys(headers)
  //       .map((key) => `${key}: ${headers[key]}`)
  //       .join("\n");

  //     iframe.style.width = "800px";
  //     iframe.style.height = "600px";
  //     iframe.style.border = "none";

  //     document.body.appendChild(iframe);

  //     iframe.addEventListener("load", () => {
  //       // Ensure that the iframe content is from the same origin to access its contentDocument
  //       if (iframe.contentDocument) {
  //         iframe.contentDocument.defaultView.postMessage(
  //           headersString,
  //           "http://example.com"
  //         );
  //       }
  //     });

  //     return () => {
  //       document.body.removeChild(iframe);
  //     };
  //   }, []);

  return (
    <>
      {true ? (
        <>
          <iframe
            src="https://bestmate.us/onboard/oauth-link"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default MyIframeComponent;
