import React, { useCallback, useContext, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useGlobalFilter } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useEffect, useState } from "react";
import { Plus, PlusSquare, X } from "react-feather";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import empty_inventory from "../../assets/img/icons/empty_inventory.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";
// import { Form } from "formik";

function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
function truncateTextByWordCountLine(text, wordLimit, lineLimit) {
  function getWords(str) {
    return str.split(/\s+/).filter(Boolean);
  }

  function countLines(str) {
    return str.split("\n").length;
  }

  // Handle falsy text values
  if (!text) {
    return "";
  }

  console.log(text);
  const words = getWords(text);
  console.log("Original word count:", words.length);

  let truncatedText = text;
  if (words.length > wordLimit) {
    truncatedText = words.slice(0, wordLimit).join(" ") + "...";
  }

  let lines = truncatedText.split("\n");

  if (lines.length > lineLimit) {
    truncatedText = lines.slice(0, lineLimit).join("\n") + "...";
  }

  console.log("Truncated text:", truncatedText);
  return truncatedText;
}
const FilteringTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currencyFormater } = useCurrencyFormating();

  const [openModals, setOpenModals] = useState(false);
  const [disabledelete, setDisableDelete] = useState(false);
  const { isTeamAccount, userPermission } = useAuth();
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";

  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [deleteData, setDeletedata] = useState({});
  let usercurrency = localStorage.getItem("currency") || "USD";
  const workingdata = props.productData;
  useEffect(() => {
    if (openModals) {
      setDisableDelete(false);
    }
  }, [openModals]);
  const multiplier =
    location.state !== null
      ? Object.keys(location.state.data).length !== 0 &&
        Object.keys(location.state.data).includes("invoice_partner")
        ? Object.keys(location.state.data.invoice_partner).length !== 0 &&
          Object.keys(location.state.data.invoice_partner).includes(
            "partner_price_list"
          )
          ? Object.keys(location.state.data.invoice_partner.partner_price_list)
              .length !== 0
            ? location.state.data.invoice_partner.partner_type === "CUSTOMER" ||
              location.state.data.invoice_partner.partner_type === "LEAD"
              ? location.state.data.invoice_partner.partner_price_list
                  .price_list_multiplier
              : 1
            : 1
          : 1
        : 1
      : 1;
  const COLUMNS = [
    {
      Header: "Service Code",
      width: 130,
      accessor: "item_code",
      Cell: ({ value, row }) => {
        // console.log(value);
        // const num = parseInt(value) * multiplier;
        if (
          Object.keys(row.original).includes("item_code") &&
          row.original.item_code !== ""
        ) {
          return (
            <span>{truncateTextByWordCount(row.original.item_code, 20)}</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: "Service Name",
      width: 350,

      accessor: "item_name",
      Cell: ({ value, row }) => {
        // const num = parseInt(value) * multiplier;
        // console.log(row);

        if (
          Object.keys(row.original).includes("item_description") &&
          row.original.item_description !== ""
        ) {
          return (
            <div
              style={{
                wordWrap: "break-word",
                maxWidth: 350,
              }}
            >
              <div>
                {value.length > 55 ? value.substring(0, 55) + " ..." : value}
              </div>
              <div
                style={{
                  marginTop: "0px",
                  fontSize: "0.7rem",
                  color: "grey",
                  whiteSpace: "pre-line",
                  // maxHeight: "3.3rem",
                  // overflow: "hidden",
                }}
              >
                {/* {row.original.item_description.length > 120
                  ? row.original.item_description.substring(0, 120) + " ..."
                  : row.original.item_description} */}
                {truncateTextByWordCountLine(
                  row.original.item_description,
                  120,
                  3
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div
              style={{
                wordWrap: "break-word",
                maxWidth: 350,
              }}
            >
              <div>{value > 55 ? value.substring(0, 55) + " ..." : value}</div>
              {/* <div style={{ marginTop: "10px" }}>No Description</div> */}
            </div>
          );
        }
      },
    },
    {
      // Header: "Service Charge",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Service Charge
        </div>
      ),
      width: 150,

      accessor: "item_selling_price",
      Cell: ({ value }) => {
        // console.log(value);
        let zero = 0;
        const num = Number(value) * multiplier;
        if (Number.isNaN(num)) {
          return (
            <div style={{ textAlign: "right" }}>
              {zero.toLocaleString("en-US", {
                style: "currency",
                currency: usercurrency,
              })}
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "right" }}>
              {/* {num.toLocaleString("en-US", {
                style: "currency",
                currency: usercurrency,
              })} */}
              {truncateTextByWordCount(currencyFormater(num, usercurrency), 30)}
            </div>
          );
        }
      },
    },
    {
      // Header: "Service Cost",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Service Cost
        </div>
      ),
      width: 150,
      accessor: "item_cost_price",
      Cell: ({ value }) => {
        let zero = 0;
        const num = Number(value);
        if (Number.isNaN(num)) {
          return (
            <div style={{ textAlign: "right" }}>
              {zero.toLocaleString("en-US", {
                style: "currency",
                currency: usercurrency,
              })}
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "right" }}>
              {/* {num.toLocaleString("en-US", {
                style: "currency",
                currency: usercurrency,
              })} */}
              {truncateTextByWordCount(currencyFormater(num, usercurrency), 30)}
            </div>
          );
        }
      },
    },
    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         // textAlign: "center",
    //       }}
    //     >
    //       Status
    //     </div>
    //   ),
    //   width: 120,

    //   // Header: "Status",
    //   accessor: "status",
    //   Cell: ({ row }) => {
    //     return (
    //       <div
    //         style={{
    //           width: "100%",
    //           display: "flex",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <div
    //           style={{
    //             backgroundColor: "#1AB395",
    //             padding: "4px",
    //             color: "white",
    //             borderRadius: "5px",
    //             fontSize: "10px",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Avilable
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];
  if (itemPermission.includes("EDIT")) {
    COLUMNS.push({
      // Header: "Actions",
      Header: () => (
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      width: 100,
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        // const num = parseInt(value);
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ButtonGroup>
              <Button
                size="sm"
                variant="light"
                style={{ backgroundColor: "white" }}
                className="border"
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    location.state != null &&
                    location.state.from.includes("invoice")
                  ) {
                    const temp = location.state.data;
                    for (let i = 0; i < temp["invoice_order"].length; i++) {
                      console.log(
                        temp["invoice_order"][i].item_id,
                        row.original.item_id
                      );
                      if (
                        temp["invoice_order"][i].item_id ===
                        row.original.item_id
                      ) {
                        temp["invoice_order"][i].item_quantity =
                          temp["invoice_order"][i].item_quantity + 1;
                        navigate(
                          `/invoices/edit/` + location.state.from.substring(23),
                          {
                            state: temp,
                          }
                        );
                        return;
                      }
                    }
                    temp["invoice_order"].push(row.original);
                    row.original.item_quantity = 1;
                    navigate(
                      `/invoices/edit/` + location.state.from.substring(23),
                      {
                        state: temp,
                      }
                    );
                    return;
                  } else if (
                    location.state != null &&
                    location.state.from.includes("add")
                  ) {
                    const temp = location.state.data;
                    for (let i = 0; i < temp["invoice_order"].length; i++) {
                      console.log(
                        temp["invoice_order"][i].item_id,
                        row.original.item_id
                      );
                      if (
                        temp["invoice_order"][i].item_id ===
                        row.original.item_id
                      ) {
                        temp["invoice_order"][i].item_quantity =
                          temp["invoice_order"][i].item_quantity + 1;
                        navigate(`/${location.state.type}/add`, {
                          // navigate(`/invoices/add`, {
                          state: temp,
                        });
                        return;
                      }
                    }
                    temp["invoice_order"].push(row.original);
                    row.original.item_quantity = 1;
                    navigate(`/${location.state.type}/add`, {
                      state: temp,
                    });
                    return;
                  }
                  mixpanel.track("Individual Service row(Service List Web)", {
                    ...row.original,
                  });
                  navigate("edit-item/" + row.original.item_id, {
                    state: { row: row.original, items: props.items },
                  });
                }}
              >
                {location.state != null && location.state.from.includes("add")
                  ? "Select"
                  : "View"}
              </Button>
              {location.state != null && location.state.from.includes("add") ? (
                <></>
              ) : (
                <>
                  <Button
                    size="sm"
                    variant="light"
                    style={{ backgroundColor: "white" }}
                    className="border"
                    onClick={(e) => {
                      e.stopPropagation();
                      mixpanel.track(
                        "Individual Service row Edit(Service List Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("edit-item/" + row.original.item_id, {
                        state: { row: row.original, items: props.items },
                      });
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    variant="light"
                    style={{ backgroundColor: "white" }}
                    className="border"
                    onClick={(e) => {
                      e.stopPropagation();
                      mixpanel.track(
                        "Individual Service row Delete (Service List Web)",
                        {
                          id: row.original.item_id,
                        }
                      );
                      setDeletedata({
                        item_id: row.original.item_id,
                        item_name: row.original.item_name,
                      });
                      setOpenModals(true);
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}
            </ButtonGroup>
          </div>
        );
      },
    });
  }

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => workingdata);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  // const { globalFilter } = state;
  // console.log(data, props.id);
  return (
    <>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <Link to="/services/add-item">
          <PlusSquare
            stroke="white"
            fill="#316284"
            size={35}
            // onClick={() => alert("Add new")}
          />
        </Link>
      </div> */}
      <InfiniteScroll
        dataLength={rows.length}
        // next={() => props.update(props.token, props.id)}
        next={
          !props.searchtable
            ? () => props.update(props.token, props.id)
            : () =>
                // props.setSearchdata([]);
                props.update(
                  props.searchterm,
                  props.searchcost,
                  props.searchsell,
                  props.searchqty,
                  props.token,
                  props.id,
                  props.signal
                )
        }
        hasMore={props.hasmore}
        style={{ overflowX: "scroll", overflowY: "hidden" }}
        loader={
          <div className="d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="me-2"
            />
          </div>
        }
      >
        <Table
          striped
          bordered
          size="sm"
          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  style={{ paddingTop: "20px", cursor: "pointer" }}
                  onClick={() => {
                    // alert("Edit action performed on " + row.values.item_name);
                    // console.log(row);
                    // props.show(true);
                    if (
                      location.state != null &&
                      location.state.from.includes("invoice")
                    ) {
                      const temp = location.state.data;
                      for (let i = 0; i < temp["invoice_order"].length; i++) {
                        console.log(
                          temp["invoice_order"][i].item_id,
                          row.original.item_id
                        );
                        if (
                          temp["invoice_order"][i].item_id ===
                          row.original.item_id
                        ) {
                          temp["invoice_order"][i].item_quantity =
                            temp["invoice_order"][i].item_quantity + 1;
                          navigate(
                            `/invoices/edit/` +
                              location.state.from.substring(23),
                            {
                              state: temp,
                            }
                          );
                          return;
                        }
                      }
                      temp["invoice_order"].push(row.original);
                      row.original.item_quantity = 1;
                      navigate(
                        `/invoices/edit/` + location.state.from.substring(23),
                        {
                          state: temp,
                        }
                      );
                      return;
                    } else if (
                      location.state != null &&
                      location.state.from.includes("add")
                    ) {
                      const temp = location.state.data;
                      for (let i = 0; i < temp["invoice_order"].length; i++) {
                        console.log(
                          temp["invoice_order"][i].item_id,
                          row.original.item_id
                        );
                        if (
                          temp["invoice_order"][i].item_id ===
                          row.original.item_id
                        ) {
                          temp["invoice_order"][i].item_quantity =
                            temp["invoice_order"][i].item_quantity + 1;
                          navigate(`/${location.state.type}/add`, {
                            // navigate(`/invoices/add`, {
                            state: temp,
                          });
                          return;
                        }
                      }
                      temp["invoice_order"].push(row.original);
                      row.original.item_quantity = 1;
                      navigate(`/${location.state.type}/add`, {
                        state: temp,
                      });
                      return;
                    }
                    if (itemPermission.includes("EDIT")) {
                      mixpanel.track(
                        "Individual Service row(Service List Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("edit-item/" + row.original.item_id, {
                        state: { row: row.original, items: props.items },
                      });
                    }
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          color: "black",
                          fontSize: "12.6px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
      {workingdata.length === 0 ? (
        props.searchloading ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center pt-3 flex-column "
            id={"Emptybox"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (itemPermission.includes("ADD")) {
                mixpanel.track("Add Service(Service List Web)");
                navigate("/services/add-item");
              }
            }}
          >
            {!props.searchtable ? (
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                {itemPermission.includes("ADD")
                  ? "Lets add your first Service"
                  : "No Service Found"}
              </p>
            ) : (
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                {itemPermission.includes("ADD")
                  ? "No Result Found. Click to Add Service"
                  : "No Result Found"}
              </p>
            )}
            <img
              src={empty_inventory}
              style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
              alt="empty_invoice"
            ></img>
            {itemPermission.includes("ADD") ? (
              <Button
                onClick={() => {
                  mixpanel.track("Add Service(Service List Web)");
                  navigate("/services/add-item");
                }}
                style={{ padding: 10, fontWeight: "bold" }}
              >
                Add Service
              </Button>
            ) : (
              <></>
            )}
          </div>
        )
      ) : (
        <></>
      )}
      {/* <Modal
        show={openModals}
        onHide={() => setOpenModals(!openModals)}
        centered
      >
        <Modal.Header className="font-weight-bold" closeButton>
          Delete Service
        </Modal.Header>
        <Modal.Body className="text-left m-3">
          <p className="mb-0 font-weight-bold">
            Are you sure you want to delete {deleteData["item_name"]}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenModals(!openModals)}
          >
            Cancel
          </Button>{" "}
          <Button
            variant="danger"
            onClick={() => {
              setOpenModals(!openModals);
              props.deleteRecord(deleteData);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={openModals}
        onHide={() => {
          mixpanel.track("Close >>> Delete Modal (Service List Web)");
          setOpenModals(false);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Delete Service
            </h1>
            <div
              onClick={() => {
                mixpanel.track("Close >>> Delete Modal (Service List Web)");

                setOpenModals(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0 20px", textAlign: "center" }}>
                Are you sure you want to delete this service?
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: 5,
                  padding: "5px 10px",
                  backgroundColor: "white",
                  borderWidth: 1,
                }}
                onClick={() => {
                  mixpanel.track("Close >>> Delete Modal (Service List Web)");

                  setOpenModals(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                disable={disabledelete}
                onClick={() => {
                  setOpenModals(false);
                  mixpanel.track("Delete >>> Delete Modal (Service List Web)");
                  setDisableDelete(true);
                  props.deleteRecord(deleteData);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Service = () => {
  const controller = new AbortController();
  const signal = controller.signal;
  const [token, settoken] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [hasmore, sethasmore] = useState(true);

  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchterm, setSearchterm] = useState("");
  const [tempsearchterm, setTempsearchterm] = useState("");

  const [searchcost, setSearchcost] = useState("");
  const [tempsearchcost, setTempsearchcost] = useState("");

  const [searchsell, setSearchsell] = useState("");
  const [tempsearchsell, setTempsearchsell] = useState("");

  const [searchqty, setSearchqty] = useState("");
  const [tempsearchqty, setTempsearchqty] = useState("");

  const [searchhasmore, setSearchhasmore] = useState(true);
  const [searchid, setSearchid] = useState("");
  const [searchloading, setsearchloading] = useState(true);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const { isTeamAccount, userPermission } = useAuth();
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";
  const navigate = useNavigate();

  const deleteRecord = async (deleteData) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/items/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log(response);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Item deleted successfully",
              ripple: "true",
              dismissible: "true",
            });

            setLoading(true);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track("Fetch Error (Service List Web)", {
            //   error: "item delete api",
            // });
            logError({
              error: "fetch error",
              api: "item delete api",
              component: `Service List Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // mixpanel.track("Error (Service List Web)", {
          // error: error.message,
          // stack: error.stack,
          // });
          logError({
            error: error.message,
            stack: error.stack,
            component: `Service List Web`,
          });
        });
    }
    // });
  };
  const getData = (token, id) => {
    fetch(
      "https://bestmate.us/api/items/findAllPaginated/SERVICE/" +
        id +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData((old) => [...old, ...result]);
          setLoading(false);
          if (result.length === 0 || result.length <= 5) {
            sethasmore(false);
          } else if (result.length > 5) {
            setId(result[result.length - 1].item_id);
          }
          // console.log(id);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  const searchData = async (
    searchterm,
    searchcost,
    searchsell,
    searchqty,
    token,
    searchid,
    signal
  ) => {
    console.log("in search");
    const res = await fetch(
      `https://bestmate.us/api/items/search/SERVICE${
        searchid ? `/${searchid}` : ""
      }?name=${searchterm}&cost_price=${searchcost}&selling_price=${searchsell}&quantity=${searchqty}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        signal,
      }
    );
    const result = await res.json();
    setSearchdata((old) => [...old, ...result]);
    setsearchloading(false);
    if (result.length === 0 || result.length <= 5) {
      setSearchhasmore(false);
    } else if (result.length > 5) {
      setSearchid(result[result.length - 1].item_id);
    }
  };

  useEffect(() => {
    setsearchloading(true);
    setSearchdata([]);
    setSearchid("");
    showSearchtable(false);
    console.log("calling search data");
    if (
      searchterm !== "" ||
      searchcost !== "" ||
      searchsell !== "" ||
      searchqty !== ""
    ) {
      console.log("calling again");
      showSearchtable(true);
      searchData(
        searchterm,
        searchcost,
        searchsell,
        searchqty,
        token,
        searchid,
        signal
      );
      // console.log("search");
    }

    // return controller.abort();
  }, [searchterm, searchcost, searchsell, searchqty]);

  useEffect(() => {
    mixpanel.track(`On Service List (Web)`);
  }, []);
  useEffect(async () => {
    if (loading) {
      // firebase.auth().onAuthStateChanged(async (user) => {
      var user = firebase.auth().currentUser;

      if (user) {
        let tokenss = await user.getIdToken();
        settoken(tokenss);
        setData([]);
        setId("");
        sethasmore(true);
        showSearchtable(false);
        setSearchdata([]);
        setSearchterm("");
        setTempsearchterm("");
        setTempsearchsell("");
        setTempsearchcost("");
        setTempsearchqty("");
        setSearchcost("");
        setSearchsell("");
        setSearchqty("");
        setSearchhasmore(true);
        setSearchid("");
        setsearchloading(true);
        getData(tokenss, "");
      }
      // });
    }
  }, [loading]);

  const deb0 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Name(Service List Web)", {
        searchterm: value,
      });
      setSearchterm(value);
    }, 1000),
    []
  );
  const handleSearch0 = (value) => {
    deb0(value);
  };

  const deb1 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Service Cost (Service List Web)", {
        searchterm: value,
      });
      setSearchcost(value);
    }, 1000),
    []
  );
  const handleSearch1 = (value) => {
    deb1(value);
  };

  const deb2 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Service Chnage (Service List Web)", {
        searchterm: value,
      });
      setSearchsell(value);
    }, 1000),
    []
  );
  const handleSearch2 = (value) => {
    deb2(value);
  };

  const deb3 = useCallback(
    debounce((value) => setSearchqty(value), 1000),
    []
  );
  const handleSearch3 = (value) => {
    deb3(value);
  };

  return (
    <React.Fragment>
      <Helmet title="Service" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Service</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Services
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Items</Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Services
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {itemPermission.includes("ADD") ? (
            <div style={{ marginRight: "21px" }}>
              <Link
                to="/services/add-item"
                onClick={() => {
                  mixpanel.track("Add Service(Service List Web)");
                }}
                style={{ textDecoration: "none" }}
              >
                {/* <PlusSquare stroke="white" fill="#316284" size={35} /> */}
                <div
                  style={{
                    // backgroundColor: "#3367d6",
                    backgroundColor: "#3f80ea",
                    borderRadius: "5px",
                    padding: "8px 15px",
                    // padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    // color: "#676a6c",
                    alignItems: "center",
                    textDecoration: "none",
                    // fontSize: 12,
                    cursor: "pointer",
                  }}
                >
                  <Plus
                    size={15}
                    strokeWidth={3.5}
                    stroke="white"
                    style={{ marginRight: "5px" }}
                  />
                  Add Service
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Service Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={tempsearchterm}
                            placeholder="Service Name"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchterm(e.target.value);
                              handleSearch0(e.target.value);
                              // handleSearch(e.target.value, ...Array(3));
                              // setSearchterm(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Service Charge</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={tempsearchsell}
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchsell(e.target.value);
                              handleSearch2(e.target.value);

                              // handleSearch(...Array(3), e.target.value);
                              // setSearchterm(e.target.value);
                            }}
                            placeholder="Service Charge"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Service Cost</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={tempsearchcost}
                            placeholder="Service Cost"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchcost(e.target.value);
                              handleSearch1(e.target.value);

                              // handleSearch(...Array(3), e.target.value);
                              // setSearchterm(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Inventory</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            placeholder="Inventory"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              handleSearch3(e.target.value);

                              // handleSearch(...Array(3), e.target.value);
                              // setSearchterm(e.target.value);
                            }}
                          />
                        </Form.Group> */}
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Card className="border rounded-0">
                <Card.Body>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <Spinner
                        animation="border"
                        variant="dark"
                        className="me-2"
                      />
                    </div>
                  ) : (
                    <>
                      {!searchtable ? (
                        <FilteringTable
                          productData={data}
                          token={token}
                          searchterm={searchterm}
                          searchtable={searchtable}
                          update={getData}
                          hasmore={hasmore}
                          id={id}
                          deleteRecord={deleteRecord}
                        />
                      ) : (
                        <FilteringTable
                          productData={searchdata}
                          token={token}
                          searchterm={searchterm}
                          searchcost={searchcost}
                          searchsell={searchsell}
                          searchqty={searchqty}
                          searchtable={searchtable}
                          update={searchData}
                          hasmore={searchhasmore}
                          id={searchid}
                          searchloading={searchloading}
                          signal={signal}
                          deleteRecord={deleteRecord}
                        />
                      )}
                    </>
                  )}
                  {/* </>
                )}

                <Card.Body>&nbsp;</Card.Body> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Service;
