import React, { useEffect, useState, useContext, useRef } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import useSidebar from "../../hooks/useSidebar";
import Dropzone, { useDropzone } from "react-dropzone";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  ProgressBar,
  Row,
  Spinner,
} from "react-bootstrap";
import imageCompression from "browser-image-compression";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import Pica from "pica";
// import Select from "react-select";
import { COLUMN } from "../dashboards/Default/currency";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useAuth from "../../hooks/useAuth";
import { MapPin, Target, UploadCloud, User, X } from "react-feather";
import InvoiceTemplate from "../../components/InvoiceTemplate";
import moment from "moment";
import { v4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  TaskEvent,
  getStorage,
} from "firebase/storage";
import mixpanel from "mixpanel-browser";
import { useLocalCountry } from "../../contexts/LocalCountryContext";
import { getCountryCallingCode } from "libphonenumber-js";
import { logError } from "../../utils/useErrorlogging";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const schema = Yup.object().shape({
  name: Yup.string().required("Name is a required field"),
  phone: Yup.string().matches(/^[0-9+\-\s]+$/, "Phone number is not valid"),
});

const UserProfileEdit = () => {
  const { bdata: bdataa, setBdata, getbdata } = useAuth();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  // const { bdata, token, getbdata } = useAuth();
  // console.log(bdata, token, getbdata, signOut);
  // const [options, setOptions] = useState({});
  const [bdata, setbdata] = useState(bdataa);
  const [token, settoken] = useState("");
  const [saveloader, setSaveloader] = useState(false);
  const navigate = useNavigate();
  const [mailstatus, setMailstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [blogo, setblogo] = useState((bdataa && bdataa.business_logo) || "");
  const [openModals, setOpenModals] = useState(false);
  const inputRef = useRef();
  const [uploadError, setUploadError] = useState("");
  const [percent, setPercent] = useState(0);
  const [uploadedurl, setUploadedurl] = useState("");
  let usercurrency = localStorage.getItem("currency") || "USD";
  const [filetoUpload, setFiletoUpload] = useState(null);
  const { country: selectedCountry } = useLocalCountry();
  const [validationError, setValidationError] = useState(false);

  const phoneCode = `+ ${getCountryCallingCode(selectedCountry)} `;
  const uploadToCloud = (compressedFile) => {
    if (compressedFile == null) return;
    const store = getStorage();
    const imageref = ref(store, `logo/${v4() + compressedFile.name}`);

    // uploadBytes(imageref, compressedFile).then((res) => {
    //   console.log(res);
    // });
    // const uploadTask = imageref.put(compressedFile);
    const uploadTask = uploadBytesResumable(imageref, compressedFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setPercent(percent);
        console.log(percent);
      },
      (err) => {
        // setUploadError(err);
        console.log(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUploadedurl(url);
        });
      }
    );
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles, error) => {
      // console.log(error);
      if (error.length !== 0) {
        setUploadError(error[0].errors[0].message);
      } else {
        try {
          setUploadError("");
          const compressedFile = await imageCompression(acceptedFiles[0], {
            maxSizeMB: 10,
            maxWidthOrHeight: 500,
          });
          setFiletoUpload(compressedFile);
          uploadToCloud(compressedFile);
          console.log(compressedFile);
        } catch (err) {
          console.log(err);
        }
      }
    },
  });

  // useEffect(() => {
  //   if (acceptedFiles.length !== 0) {
  //     console.log(acceptedFiles);
  //     const imageUrl = URL.createObjectURL(acceptedFiles[0]);
  //     setImagePath(imageUrl);
  //     setFiletoUpload(acceptedFiles[0]);
  //   }
  // }, [acceptedFiles]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
    libraries: ["places"],
  });

  const handlePlaceChanged = (values) => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setbdata({
        ...bdata,
        business_user_name: values.name,
        business_name: values.businessName,
        business_phone: values.phone,
        business_currency_code: values.currency,
        business_address: {
          country: country.length !== 0 ? country[0].long_name : "",
          state: state.length !== 0 ? state[0].long_name : "",
          city: city.length !== 0 ? city[0].long_name : "",
          zip_code: zip.length !== 0 ? zip[0].long_name : "",
          street:
            street.length !== 0
              ? street.map((elem) => elem.long_name).join(" ")
              : "",
        },
      });

      // setbillingadd({
      //   country: country.length !== 0 ? country[0].long_name : "",
      //   state: state.length !== 0 ? state[0].long_name : "",
      //   city: city.length !== 0 ? city[0].long_name : "",
      //   zip_code: zip.length !== 0 ? zip[0].long_name : "",
      //   street:
      //     street.length !== 0
      //       ? street.map((elem) => elem.long_name).join(" ")
      //       : "",
      // });
    }
  };

  // const getBusinessData = (token) => {
  //   fetch("https://bestmate.us/api/business/get", {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setbdata(result);
  //         setblogo((result && result.business_logo) || "");
  //         console.log((result && result.business_logo) || "");
  //         setLoading(false);
  //       },
  //       (error) => {
  //         console.error("Error fetching data: ", error);
  //       }
  //     );
  // };

  useEffect(async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    mixpanel.track(`On Business Profile (Web)`);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // getBusinessData(tokenss);
    }
    // });
  }, []);

  // const [bdata, setBdata] = useState([]);
  console.log(bdata);
  // const data = useContext(Context);
  // console.log(data);
  let street = "";
  let city = "";
  let state = "";
  let zip = "";
  let country = "";
  if (bdata && bdata.business_address != undefined) {
    street = bdata.business_address.street;
    city = bdata.business_address.city;
    state = bdata.business_address.state;
    zip = bdata.business_address.zip_code;
    country = bdata.business_address.country;
  }
  const update = async (dat) => {
    setSaveloader(true);
    mixpanel.track(`Save (Business Profile Web)`);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/business/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(dat),
      })
        .then(async (res) => {
          if (res.status >= 200 && res.status <= 300) {
            console.log(res);
            console.log("55555555555");
            let ress = await res.json();
            // setBdata(ress);
            // console.log(ress);
            getbdata();
            // setBdata(ress);
            // setblogo((ress && ress.business_logo) || "");
            // // getBusinessData(token);
            // setFiletoUpload(null);
            // setUploadError("");
            // setUploadedurl("");

            localStorage.setItem("currency", dat.business_currency_code);
            localStorage.setItem("currencyName", dat.business_currency_name);

            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Profile Updated successfully",
              ripple: "true",
              dismissible: "true",
            });
            document.documentElement?.style.setProperty(
              "--widthh",
              window.screen.width
            );
            navigate("/user-profile");
            setSaveloader(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track(`Fetch Error (Business Profile Web)`, {
            //   error: "business website update api",
            // });
            logError({
              error: "fetch error",
              api: "user website update api",
              component: `User Profile Web`,
            });

            setSaveloader(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setMailstatus("fail");
          setSaveloader(false);
          mixpanel.track(` Error (Business Profile Web)`, {
            error: error,
          });
        });
    }
    //   }
    // });
  };
  const submitHandler = (elem) => {
    let found = COLUMN.find((elems) => {
      console.log(elems.id, elem.currency);
      return elems.id === elem.currency;
    });
    console.log(found);
    const dat = {
      business_user_name: capitalizeFirstLetter(elem.name),
      business_name: capitalizeFirstLetter(elem.businessName),
      business_currency_code: elem.currency,
      business_phone: elem.phone,
      business_currency_name: found.name,
      business_logo: uploadedurl ? uploadedurl : blogo,
      business_address: {
        zip_code: elem.zip ? elem.zip : "",
        state: elem.state ? elem.state : "",
        country: elem.country ? elem.country : "",
        street: elem.street ? elem.street : "",
        city: elem.city ? elem.city : "",
      },
    };
    // if (uploadedurl || blogo) {
    //   dat.business_logo = uploadedurl ? uploadedurl : blogo ? blogo : "";
    // }
    console.log(dat);
    update(dat);
  };
  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      ) : (
        <React.Fragment>
          <Helmet title="Edit Business Profile" />
          {mailstatus === "sent" ? (
            <Alert
              variant="primary"
              style={{ zIndex: "1" }}
              className="position-fixed bottom-0 start-50 w-25"
              onClose={() => setMailstatus("")}
              dismissible
            >
              <div className="alert-message">
                <strong>Profile Updated!</strong>
              </div>
            </Alert>
          ) : (
            <></>
          )}
          {mailstatus === "fail" ? (
            <Alert
              variant="danger"
              style={{ zIndex: "1" }}
              className="position-fixed bottom-0 end-0 w-25"
              onClose={() => setMailstatus("")}
              dismissible
            >
              <div className="alert-message">
                <strong>Profile not Updated Please try later!</strong>
              </div>
            </Alert>
          ) : (
            <></>
          )}
          <Container fluid className="p-0">
            {/* <h1 className="h3 mb-3">Business Profile</h1> */}
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "white",
                padding: "0rem 1.5rem 2rem 1.5rem",
              }}
              className="pt-2 pb-2 border-bottom "
            >
              <div>
                <h2
                  className="mt-3 lead"
                  style={{
                    fontSize: "24px",
                    // , fontWeight: 100
                  }}
                >
                  Edit User Profile
                </h2>
                <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                  <Breadcrumb.Item
                    active
                    onClick={() => navigate("/dashboard")}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </Breadcrumb.Item>
                  {/* <Breadcrumb.Item active>Invoice</Breadcrumb.Item> */}
                  <Breadcrumb.Item
                    onClick={() => navigate("/user-profile")}
                    style={{ cursor: "pointer" }}
                    active
                  >
                    User Profile & Settings
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active className="font-weight-bold">
                    Edit User Profile
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* <div>
                <Link to="/invoices/add">
                  <div
                    style={{
                      border: "1px #E7EAEC solid",
                      borderRadius: "3px",
                      padding: "0.375rem 0.75rem",
                      display: "flex",
                      justifyContent: "center",
                      color: "#676a6c",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    <Plus
                      size={15}
                      strokeWidth={3.5}
                      stroke="#676a6c"
                      style={{ marginRight: "2px" }}
                    />
                    Add
                  </div>
                </Link>
              </div> */}
            </Card>
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              <Row>
                <Col lg="12">
                  <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={schema}
                    onSubmit={(elem) => submitHandler(elem)}
                    initialValues={{
                      name:
                        bdata && bdata.business_user_name
                          ? bdata.business_user_name
                          : "",
                      businessName:
                        bdata && bdata.business_name ? bdata.business_name : "",
                      currency:
                        bdata && bdata.business_currency_code
                          ? bdata.business_currency_code
                          : "",
                      phone:
                        bdata && bdata.business_phone
                          ? bdata.business_phone
                          : "",
                      // street: "Asfasf",
                      street: street,
                      // city: "Afsdfdfdsfasd",
                      city: city,
                      // state: "ASdfasdfsdf",
                      state: state,
                      // zip: "asdfasdfsdf",
                      zip: zip,
                      // country: "Asfsadfsdf",
                      country: country,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      dirty,
                      setValues,
                      validateForm,
                    }) => (
                      <Form
                        noValidate
                        onSubmit={async (e) => {
                          e.preventDefault();
                          const obj = await validateForm();
                          if (Object.keys(obj).length !== 0) {
                            setValidationError(true);
                          } else {
                            handleSubmit();
                          }
                        }}
                        onKeyDown={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      >
                        <Row>
                          <Col
                            md={6}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Card
                              className="mb-2 border rounded-0"
                              style={{ flexGrow: 1 }}
                            >
                              <Card.Header
                                className="pb-0"
                                style={{ display: "flex" }}
                              >
                                <User
                                  size={20}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{ marginRight: 15 }}
                                />
                                <Card.Title
                                  className="mb-0"
                                  style={{ fontSize: 18 }}
                                >
                                  User Information
                                </Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Name"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Joe"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                  </Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Phone Number"
                                  className="mb-3"
                                  onClick={() => {
                                    console.log("ASDF");
                                    if (!values.phone) {
                                      setValues({
                                        ...values,
                                        phone: phoneCode,
                                      });
                                      // values.phoneNumber = phoneCode;
                                    }
                                  }}
                                >
                                  <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="d"
                                    value={values.phone}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phone}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.phone}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Card.Body>
                            </Card>
                            <div
                              className="d-flex mt-3"
                              style={{ justifyContent: "space-between" }}
                            >
                              <Button
                                variant="secondary"
                                size="lg"
                                onClick={() => {
                                  mixpanel.track(
                                    `Cancel (Business Profile Web)`
                                  );

                                  cancelHandler();
                                }}
                                className="me-1 mb-1 center"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                type="submit"
                                size="lg"
                                className="me-1 mb-1 center"
                                disabled={saveloader}
                              >
                                Save
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </div>
          </Container>
          <Modal
            show={openModals}
            onHide={() => setOpenModals(false)}
            centered
            size="md"
          >
            {/* <Modal.Header className="font-weight-bold" closeButton> */}
            {/* Send Invoice */}
            {/* </Modal.Header> */}
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Invoice Preview
                </h1>
                <div
                  onClick={() => {
                    setOpenModals(false);
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <Row>
                  <Col>
                    <InvoiceTemplate
                      data={{
                        invoice_partner: {
                          partner_business_id: "kQbDyfnwnIeDQo2PfytrGCYWq782",
                          partner_first_name: "Sample",
                          partner_email: "new@gmail.com",
                          partner_id: "frPhAvhAwHoT4N8ukrxU",
                          partner_modified_at: 1682490678198,
                          partner_outstanding: 0,
                          partner_type: "CUSTOMER",
                          partner_last_name: "Client",
                          partner_shipping_address: {},
                          partner_grand_total: 0,
                          partner_created_at: 1682490594834,
                          partner_billing_address: {
                            street: "823 Main Street",
                            city: "Atlanta",
                            country: "30458",
                            state: "",
                            zip_code: "GA",
                          },
                        },
                        invoice_order: [
                          {
                            item_business_id: "kQbDyfnwnIeDQo2PfytrGCYWq782",
                            item_selling_price: "50",
                            item_modified_at: 1682489637221,
                            item_created_at: 1681378759571,
                            item_id: "Xbt67sDNd0vy72HqI0Sc",
                            item_type: "PRODUCT",
                            item_cost_price: 0,
                            item_name: "Labor Costs",
                            item_quantity: 3,
                          },
                          {
                            item_business_id: "kQbDyfnwnIeDQo2PfytrGCYWq782",
                            item_selling_price: "100",
                            item_modified_at: 1682489637221,
                            item_created_at: 1681378759571,
                            item_id: "Xbt67sDNd0vy72HqI0Sc",
                            item_type: "PRODUCT",
                            item_cost_price: 0,
                            item_name: "Sample Item",
                            item_quantity: 2,
                          },
                        ],
                        invoice_tax_rate: 0.1,
                        invoice_partner_type: "CUSTOMER",
                        invoice_currency_code: usercurrency,
                        invoice_discount_rate: 0.14285,
                        invoice_number: "001",
                        invoice_created_at: moment(new Date()),
                        invoice_due_date: moment(new Date()).add(7, "days"),
                        invoice_workflow_type: "INVOICE",
                        invoice_status: "OPEN",
                        invoice_payment_status: "PAID",
                        invoice_settled_amount: 0,
                      }}
                      from="businessProfile"
                      subtotal={350}
                    />
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={validationError}
            onHide={() => setValidationError(false)}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Error
                </h1>
                <div
                  onClick={() => {
                    setValidationError(false);
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p style={{ margin: "15px 0 20px" }}>
                    There was an error saving the form. Please check the
                    messages in the form and try again.
                  </p>
                </div>

                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    onClick={() => {
                      setValidationError(false);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </>
  );
};

export default UserProfileEdit;
