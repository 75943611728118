import React, { useEffect, useState, useContext, useRef } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import useSidebar from "../../hooks/useSidebar";
import Dropzone, { useDropzone } from "react-dropzone";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Spinner,
  Tab,
  Table,
} from "react-bootstrap";
import imageCompression from "browser-image-compression";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import Pica from "pica";
// import Select from "react-select";
import { COLUMN } from "../dashboards/Default/currency";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useAuth from "../../hooks/useAuth";
import {
  ArrowUpRight,
  Edit2,
  MapPin,
  Target,
  UploadCloud,
  User,
  X,
} from "react-feather";
import InvoiceTemplate from "../../components/InvoiceTemplate";
import moment from "moment";
import { v4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  TaskEvent,
  getStorage,
} from "firebase/storage";
import mixpanel from "mixpanel-browser";
import useTheme from "../../hooks/useTheme";
import { THEME } from "../../constants";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const schema = Yup.object().shape({
  name: Yup.string().required("Name is a required field"),
});

const addressFormating = (value) => {
  if (!value || Object.keys(value).length === 0) {
    return "-";
  }
  const { street, city, state, zip_code, country } = value;
  console.log(street, city, state, zip_code, country);

  const lineone = [street, city].filter((field) => field).join(", ");
  const linetwo = [state, zip_code].filter((field) => field).join(" - ");
  const linethree = [country].filter((field) => field).join("");

  console.log(lineone, linetwo, linethree);
  if (!lineone && !linetwo && !linethree) {
    return "-";
  }

  return `${lineone ? lineone : ""} ${lineone && linetwo && "\n"}${
    linetwo ? linetwo : ""
  }${linetwo && linethree && "\n"}${linethree ? linethree : ""}`;
};

const themeOptions = [
  {
    name: "Light",
    value: THEME.LIGHT,
  },
  {
    name: "Colored 1",
    value: THEME.DEFAULT,
  },
  {
    name: "Colored 2",
    value: THEME.COLORED,
  },
  // {
  //   name: "Dark",
  //   value: THEME.DARK,
  // },
];

const UserProfile = () => {
  const { bdata, setBdata, getbdata } = useAuth();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  // const { bdata, token, getbdata } = useAuth();
  // console.log(bdata, token, getbdata, signOut);
  // const [options, setOptions] = useState({});
  // const [bdata, setbdata] = useState({});
  const [token, settoken] = useState("");
  const navigate = useNavigate();
  const [mailstatus, setMailstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [blogo, setblogo] = useState("");
  const [openModals, setOpenModals] = useState(false);
  const inputRef = useRef();
  const [uploadError, setUploadError] = useState("");
  const [percent, setPercent] = useState(0);
  const [uploadedurl, setUploadedurl] = useState("");
  let usercurrency = localStorage.getItem("currency") || "USD";
  const [filetoUpload, setFiletoUpload] = useState(null);
  const [activeTab, setactiveTab] = useState("first");
  const [notes, setNotes] = useState(
    bdata && bdata.business_default_notes ? bdata.business_default_notes : ""
  );
  const [terms, setTerms] = useState(
    bdata && bdata.business_default_terms_and_conditions
      ? bdata.business_default_terms_and_conditions
      : ""
  );
  const [disableupdate, setDisableupdate] = useState(false);
  // console.log(bdataa);
  const { theme, setTheme } = useTheme();

  // const getBusinessData = (token) => {
  //   fetch("https://bestmate.us/api/business/get", {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setbdata(result);
  //         setblogo((result && result.business_logo) || "");
  //         console.log((result && result.business_logo) || "");
  //         setNotes(
  //           result.business_default_notes ? result.business_default_notes : ""
  //         );
  //         setTerms(
  //           result.business_default_terms_and_conditions
  //             ? result.business_default_terms_and_conditions
  //             : ""
  //         );
  //         setLoading(false);
  //       },
  //       (error) => {
  //         console.error("Error fetching data: ", error);
  //       }
  //     );
  // };

  useEffect(async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    mixpanel.track(`On Business Profile (Web)`);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // getBusinessData(tokenss);
    }
    // });
  }, []);

  const submitSetting = async () => {
    setDisableupdate(true);
    mixpanel.track(`Update Setting (Business Profile Web)`);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/business/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify({
          business_default_terms_and_conditions: terms,
          business_default_notes: notes,
        }),
      })
        .then(async (res) => {
          console.log(res);
          console.log("55555555555");
          let ress = await res.json();

          getbdata();
          // setBdata(ress);

          notyf.open({
            type: isOpen ? "home" : "full",
            message: "Invoice & Estimate Settings Updated successfully",
            ripple: "true",
            dismissible: "true",
          });
          document.documentElement?.style.setProperty(
            "--widthh",
            window.screen.width
          );
          setDisableupdate(false);
        })
        .catch((error) => {
          alert(error);
          setDisableupdate(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      ) : (
        <React.Fragment>
          <Helmet title="Business Profile & Settings" />
          {mailstatus === "sent" ? (
            <Alert
              variant="primary"
              style={{ zIndex: "1" }}
              className="position-fixed bottom-0 start-50 w-25"
              onClose={() => setMailstatus("")}
              dismissible
            >
              <div className="alert-message">
                <strong>Profile Updated!</strong>
              </div>
            </Alert>
          ) : (
            <></>
          )}
          {mailstatus === "fail" ? (
            <Alert
              variant="danger"
              style={{ zIndex: "1" }}
              className="position-fixed bottom-0 end-0 w-25"
              onClose={() => setMailstatus("")}
              dismissible
            >
              <div className="alert-message">
                <strong>Profile not Updated Please try later!</strong>
              </div>
            </Alert>
          ) : (
            <></>
          )}
          <Container fluid className="p-0">
            {/* <h1 className="h3 mb-3">Business Profile</h1> */}
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "white",
                padding: "0rem 1.5rem 2rem 1.5rem",
              }}
              className="pt-2 pb-2 border-bottom "
            >
              <div>
                <h2
                  className="mt-3 lead"
                  style={{
                    fontSize: "24px",
                    // , fontWeight: 100
                  }}
                >
                  User Profile & Settings
                </h2>
                <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                  <Breadcrumb.Item
                    active
                    onClick={() => navigate("/dashboard")}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </Breadcrumb.Item>
                  {/* <Breadcrumb.Item active>Invoice</Breadcrumb.Item> */}
                  <Breadcrumb.Item active className="font-weight-bold">
                    User Profile & Settings
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "21px",
                }}
              >
                <div
                  onClick={() => {
                    mixpanel.track("Edit( Business Profile Web)");
                    navigate("/user-profile/edit");
                  }}
                >
                  <div
                    style={{
                      border: "1px #E7EAEC solid",
                      borderRadius: "3px",
                      padding: "0.375rem 0.75rem",
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      // backgroundColor: "#4f83cb",
                      backgroundColor: "#3f80ea",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: "inherit",
                      cursor: "pointer",
                      width: "100px",
                    }}
                  >
                    <Edit2
                      size={15}
                      strokeWidth={2}
                      stroke="white"
                      style={{ marginRight: "8px" }}
                    />
                    <div>Edit</div>
                  </div>
                </div>
              </div>
            </Card>
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              <Row>
                <Col lg="4">
                  <Card
                    className="mb-0 border rounded-0"
                    style={{ overflow: "scroll" }}
                  >
                    <Card.Body>
                      <div>
                        <div
                          className="mb-0"
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >{`${
                          bdata && bdata.business_user_name
                            ? bdata.business_user_name.charAt(0).toUpperCase() +
                              bdata.business_user_name.slice(1)
                            : ""
                        }`}</div>
                        <div
                          style={{
                            // fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {/* Company Name */}
                          {bdata && bdata.business_name
                            ? bdata.business_name.charAt(0).toUpperCase() +
                              bdata.business_name.slice(1)
                            : ""}
                        </div>
                      </div>

                      <Table size="sm" className="my-2" style={{}}>
                        <tbody style={{}}>
                          <tr style={{}}>
                            <th style={{ color: "#545b61" }}>Name</th>
                            <td style={{ wordBreak: "break-word" }}>
                              {bdata && bdata.business_user_name
                                ? bdata.business_user_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  bdata.business_user_name.slice(1)
                                : "-"}
                            </td>
                          </tr>

                          <tr>
                            <th style={{ color: "#545b61" }}>Email</th>
                            <td style={{ wordBreak: "break-word" }}>
                              {bdata && bdata.business_email
                                ? bdata.business_email
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ color: "#545b61" }}>Phone</th>
                            <td style={{ wordBreak: "break-word" }}>
                              {bdata && bdata.business_phone
                                ? bdata.business_phone
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="8">
                  <Card
                    className="mb-0 border rounded-0"
                    style={{ overflow: "scroll", height: "100%" }}
                  >
                    <Card.Body>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Nav variant="tabs">
                          <Nav.Item
                            style={{
                              width: 100,
                              maxWidth: "100%",
                            }}
                          >
                            <Nav.Link
                              className="text-dark"
                              onClick={() => {
                                setactiveTab("first");
                              }}
                              style={
                                activeTab === "first"
                                  ? {
                                      borderBottom: "2px solid black",
                                      fontWeight: "bold",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                                  : {
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }
                              }
                              eventKey="first"
                            >
                              Settings
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content style={{}}>
                          <Tab.Pane eventKey="first">
                            <div style={{ marginTop: 30 }}>
                              <div style={{ fontSize: "15px", color: "black" }}>
                                App Theme
                              </div>
                              <div className="row g-0 text-center mx-n1 mb-2 mt-3">
                                {themeOptions.map(({ name, value }) => (
                                  <div className="col-3" key={value}>
                                    <label className="mx-1 d-block mb-1">
                                      <input
                                        className="settings-scheme-label"
                                        type="radio"
                                        name="theme"
                                        value={value}
                                        checked={theme === value}
                                        onChange={() => {
                                          setTheme(value);
                                        }}
                                      />
                                      <div className="settings-scheme">
                                        <div
                                          className={`settings-scheme-theme settings-scheme-theme-${value}`}
                                        ></div>
                                      </div>
                                    </label>
                                    {name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default UserProfile;
