import React from "react";
import { Notyf } from "notyf";
import "./head.css";
import useSidebar from "../hooks/useSidebar";
export default React.createContext(
  new Notyf({
    duration: 2500,
    position: {
      x: "right",
      y: "bottom",
    },
    types: [
      {
        type: "default",
        backgroundColor: "#3B7DDD",
        icon: {
          className: "notyf__icon--success",
          tagName: "i",
        },
      },
      {
        type: "success",
        backgroundColor: "#28a745",
        icon: {
          className: "notyf__icon--success",
          tagName: "i",
        },
      },
      {
        type: "warning",
        backgroundColor: "#ffc107",
        className: "notyf__toast",
        icon: {
          className: "notyf__icon--error",
          tagName: "i",
        },
      },
      {
        type: "danger",
        backgroundColor: "#dc3545",
        className: "notyf__toast",
        icon: {
          className: "notyf__icon--error",
          tagName: "i",
        },
      },
      {
        type: "full",
        backgroundColor: "#28a745",
        className: "notyf__toast2",
        icon: {
          className: "notyf__icon--success",
          tagName: "i",
        },
      },
      {
        type: "fullwarning",
        backgroundColor: "#dc3545",
        className: "notyf__toast2",
        icon: {
          className: "notyf__icon--error",
          tagName: "i",
        },
      },
      {
        type: "home",
        backgroundColor: "#28a745",
        className: "notyf__toast",
        icon: {
          className: "notyf__icon--success",
          tagName: "i",
        },
      },
      {
        type: "homewarning",
        backgroundColor: "#dc3545",
        className: "notyf__toast",
        icon: {
          className: "notyf__icon--error",
          tagName: "i",
        },
      },
    ],
  })
);
