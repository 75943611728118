import { createContext, useEffect, useReducer, useRef, useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// import { getStorage } from "firebase/storage";

import { firebaseConfig } from "../config";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { usePremium } from "./PremiumContext";
import { useParams } from "react-router-dom";

const INITIALIZE = "INITIALIZE";
const UPDATE_AUTH = "UPDATE_AUTH";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  // var store = firebase.storage();
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isTeamAccount: false,
  isDeactivated: false,
  user: null,
  // showMobileAcceptui: true,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isTeamAccount, isAuthenticated, user, isDeactivated } =
      action.payload;
    // const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isTeamAccount,
      isDeactivated,
      isInitialized: true,
      user,
      // showMobileAcceptui,
    };
  } else if (action.type === UPDATE_AUTH) {
    return {
      ...state,
      ...action.payload,
      // showMobileAcceptui,
    };
  }

  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [token, setToken] = useState("");
  const [bdata, setBdata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userData, setUserData] = useState({});
  const [userPermission, setUserPermission] = useState({});
  const stateRef = useRef(state);
  const { setMoreInfo, setIsPremium, setIsFree, setIsTrial, setPremiumID } =
    usePremium();
  const { id } = useParams();

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const getbdata = async () => {
    console.log(stateRef.state);
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      setToken(tokenss);
      const res = await fetch(
        "https://bestmate.us/api/business/get" +
          `${state.isTeamAccount ? "?team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      );
      const result = await res.json();
      console.log(user);
      if (user.email) {
        mixpanel.identify(user.email);
        mixpanel.people.set({
          $email: user.email,
          $name: result.business_user_name,
          $phone: result.business_phone,
          Platform: "web",
          Industry: result.business_industry,
          "Business Name": result.business_name,
          "Business Address": result.business_address,
          "Business Created at": moment(result.business_created_at).format(
            "MMM Do YYYY, ddd h:mm a"
          ),
          "Business Created at (Time)": result.business_created_at,
          "Business Currency Name": result.business_currency_name,
          "Business Currency Code": result.business_currency_code,
        });
      } else if (user.phoneNumber) {
        mixpanel.identify(user.phoneNumber);
        mixpanel.people.set({
          $phone: user.phoneNumber,
          $name: result.business_user_name,
          $email: result.business_email,
          Platform: "web",
          Industry: result.business_industry,
          "Business Name": result.business_name,
          "Business Address": result.business_address,
          "Business Created at": moment(result.business_created_at).format(
            "MMM Do YYYY, ddd h:mm a"
          ),
          "Business Created at (Time)": result.business_created_at,
          "Business Currency Name": result.business_currency_name,
          "Business Currency Code": result.business_currency_code,
        });
      }
      setCurrency(result.business_currency_code);
      if (result.business_currency_code) {
        localStorage.setItem("currency", result.business_currency_code);
        localStorage.setItem("currencyName", result.business_currency_name);
      } else {
        localStorage.setItem("currency", "USD");
        localStorage.setItem("currencyName", "United States Dollar");
      }
      setBdata(result);
      // setLoading(false);
      console.log(result);
    } else {
      setToken("");
      setLoading(false);
      setMoreInfo(null);
      setIsFree(false);
      setIsPremium(false);
      setIsTrial(false);
      setPremiumID("");
    }
  };

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user, tokenid) => {
        console.log("firstauthlistener");
        var isRequestAccepted = false;
        if (user) {
          setLoading(true);
          function getUserIdFromUrl() {
            const path = window.location.pathname;
            const parts = path.split("/");
            console.log(parts);

            if (
              parts[1] === "user-signup" &&
              parts[2] &&
              parts[2].length !== 0
            ) {
              return parts[2];
            } else {
              return null; // Custom user ID not found in URL
            }
          }
          let isRequest = localStorage.getItem("isRequest");
          let InviteData = localStorage.getItem("InviteData");
          var name = "";
          var email = "";
          var phone = "";
          if (InviteData !== null) {
            let dat = JSON.parse(InviteData);
            name = dat.invited_member_name ? dat.invited_member_name : "";
            email = dat.invited_member_email ? dat.invited_member_email : "";
            phone = dat.invited_member_phone ? dat.invited_member_phone : "";
          }

          console.log("check this ++++++--++++");
          console.log(typeof isRequest);
          const userId = getUserIdFromUrl();
          if (userId && isRequest === "true") {
            isRequestAccepted = true;
            console.log("after kokasadfokok");
            let tokenss = await user.getIdToken();
            fetch("https://bestmate.us/api/team/accept-request", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenss,
              },
              body: JSON.stringify({
                hash: userId,
                team_member_name: name,
                team_member_email: email,
                team_member_phone: phone,
              }),
            })
              .then((res) => {
                localStorage.setItem("isRequest", false);
                console.log("after kokdokok");
                localStorage.removeItem("InviteData");
                console.log("set remove 1");
              })
              .catch((err) => {
                localStorage.setItem("isRequest", false);
                console.log("after kokodkok");
                localStorage.removeItem("InviteData");
                console.log("set remove 2");
              });
          }
          console.log("after kokokok");
          const params = new URLSearchParams(window.location.search);
          const filteredParams = {};
          for (const [key, value] of params) {
            if (key.startsWith("utm")) {
              filteredParams[key] = value;
            }
          }
          let tokenss = await user.getIdToken();
          console.log(tokenss);
          console.log("after asdfasdfok");
          if (Object.keys(filteredParams).length !== 0) {
            fetch("https://bestmate.us/api/business/update", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenss,
              },
              body: JSON.stringify({
                business_referrer: filteredParams,
                business_email: user.email,
              }),
            })
              .then((res) => {})
              .catch((err) => console.log(err));
          }
          console.log("after asdfasdfasdfasdfok");
          // const getdat = await fetch(
          //   "https://bestmate.us/api/business/business-exists-team",
          //   {
          //     method: "GET",
          //     headers: {
          //       Accept: "application/json",
          //       "Content-Type": "application/json",
          //       Authorization: "Bearer " + tokenss,
          //     },
          //   }
          // );
          // let dat = await getdat.json();
          const res = await fetch(
            "https://bestmate.us/api/team/get-permissions",
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenss,
              },
            }
          );
          let data = await res.json();
          setUserData(data);
          setUserPermission(
            Object.keys(data).includes("team_member_permissions")
              ? data.team_member_permissions
              : {}
          );
          console.log(
            Object.keys(data).includes("team_member_permissions")
              ? data.team_member_permissions
              : {}
          );
          if (
            (res.ok &&
              data &&
              Object.keys(data).includes("team_member_permissions") &&
              data.team_member_permissions &&
              Object.keys(data.team_member_permissions).includes("isOwner") &&
              !data.team_member_permissions.isOwner) ||
            isRequestAccepted
          ) {
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                isTeamAccount: true,
                isDeactivated: false,
                user,
                token: tokenid,
              },
            });
          } else {
            if (
              res.ok &&
              Object.keys(data).includes("status") &&
              data.status === "DEACTIVATED"
            ) {
              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: true,
                  isTeamAccount: true,
                  isDeactivated: true,
                  user,
                  token: tokenid,
                },
              });
            } else {
              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: true,
                  isTeamAccount: false,
                  isDeactivated: false,
                  user,
                  token: tokenid,
                },
              });
            }
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              isTeamAccount: false,
              isDeactivated: false,
              token: "",
            },
          });
          setLoading(false);
          setMoreInfo(null);
          setIsFree(false);
          setIsPremium(false);
          setIsTrial(false);
          setPremiumID("");
          setUserPermission({});
          setUserData({});
          setToken("");
        }
      }),
    [dispatch]
  );

  useEffect(() => {
    console.log(state.isAuthenticated);
    if (state.isAuthenticated) {
      console.log(state.isTeamAccount);
      getbdata();
    }
  }, [state.isAuthenticated]);

  const signIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  // const signInWithFaceBook = () => {
  //   const provider = new firebase.auth.FacebookAuthProvider();
  //   return firebase.auth().signInWithPopup(provider);
  // };
  const signInWithApple = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    return firebase.auth().signInWithPopup(provider);
  };
  // const getToken = async () => {
  //   let token = await firebase.auth().currentUser.getIdToken(true);
  //   setToken(token);
  // };

  // const getData = async () => {
  //   firebase.auth().onAuthStateChanged(async (user) => {
  //     setLoading(true);
  //     getbdata();
  //   });
  // };
  // useEffect(async () => {
  //   await getData();
  // }, []);

  const signUp = async (email, password) => {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
    console.log("created");
  };
  const signOut = async () => {
    await firebase.auth().signOut();
    setBdata(null);
    localStorage.removeItem("currency");
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  // const auth = { ...state.user };
  const updateAuth = (payload) => {
    dispatch({ type: UPDATE_AUTH, payload });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        // user: {
        //   id: auth.uid,
        //   email: auth.email,
        //   avatar: auth.avatar || profile?.avatar,
        //   displayName: auth.displayName || profile?.displayName,
        //   role: "user",
        // },
        token,
        bdata,
        signIn,
        signUp,
        signInWithGoogle,
        signInWithApple,
        usercurrency: currency,
        // signInWithFaceBook,
        // signInWithTwitter,
        signOut,
        setBdata,

        resetPassword,
        getbdata,
        loading,
        setLoading,
        userData,
        userPermission,
        setUserPermission,
        setUserData,
        updateAuth,
        // store,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
