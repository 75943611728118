import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row, Badge } from "react-bootstrap";
import empty_invoice from "../../../assets/img/icons/empty_invoice.png";
import empty_partner from "../../../assets/img/icons/empty_partner.png";
import schedule from "../../../assets/img/icons/schedule.png";
import { DollarSign, ShoppingBag, Users } from "react-feather";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useSumwithDiscountAndTax } from "../../../utils/useSumwithDiscountandTax";
import { useCurrencyFormating } from "../../../utils/useCurrencyFormating";
import useAuth from "../../../hooks/useAuth";
import PermissionRequireModal from "../../../components/PermissionRequireModal";
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
// import illustration from "../../../assets/img/illustrations/customer-support.png";
// import useAuth from "../../../hooks/useAuth";

const Statistics = (props) => {
  // console.log(localStorage.getItem("currency"));
  // let { currency } = useAuth();
  // let usercurrency = localStorage.getItem("currency")
  // ? localStorage.getItem("currency")
  // : "USD";
  const navigate = useNavigate();
  let usercurrency = props.usercurrency;
  const { t } = useTranslation();
  console.log(usercurrency);
  const { sumCalculation } = useSumwithDiscountAndTax();
  const { currencyFormater } = useCurrencyFormating();
  // const { t } = useTranslation();
  const [token, settoken] = useState("");
  const [invoicedata, setInvoicedata] = useState([]);
  const [customerdata, setCustomerdata] = useState([]);
  const [invoicetotal, setInvoicetotal] = useState(0);
  const [estimatetotal, setEstimatetotal] = useState(0);
  const [ordertotal, setOrdertotal] = useState(0);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false);
  const { isTeamAccount } = useAuth();
  const { userPermission } = useAuth();
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";

  const customerData = (token) => {
    fetch(
      "https://bestmate.us/api/partners/findAll" +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setCustomerdata(result);
          // console.log(result);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  useEffect(async () => {
    console.log("calling again");
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      customerData(tokenss);
      setInvoicedata(props.invoicedata);
      // invoiceData(tokenss);
    }
    // });
  }, []);

  const customerNumber = customerdata.filter(
    (elem) => elem.partner_type === "CUSTOMER"
  ).length;
  useEffect(() => {
    console.log(invoicedata);

    const invoicefilter = invoicedata.filter(
      (elem) => elem.invoice_workflow_type === "INVOICE"
    );

    const estimatefilter = invoicedata.filter(
      (elem) => elem.invoice_workflow_type === "ESTIMATE"
    );

    const orderfilter = invoicedata.filter(
      (elem) => elem.invoice_workflow_type === "ORDER"
    );
    invoicefilter.forEach((elem) => {
      // var sum = 0;
      // var price_list = 1;
      // if (
      //   elem &&
      //   elem.invoice_partner &&
      //   elem.invoice_partner.partner_price_list &&
      //   elem.invoice_partner.partner_price_list.price_list_multiplier &&
      //   !isNaN(elem.invoice_partner.partner_price_list.price_list_multiplier)
      // ) {
      //   price_list = Number(
      //     elem.invoice_partner.partner_price_list.price_list_multiplier
      //   );
      // }
      // for (var i = 0; i < elem.invoice_order.length; i++) {
      //   if (elem.invoice_partner_type === "CUSTOMER") {
      //     sum =
      //       sum +
      //       elem.invoice_order[i].item_quantity *
      //         elem.invoice_order[i].item_selling_price *
      //         price_list;
      //   } else {
      //     sum =
      //       sum +
      //       elem.invoice_order[i].item_quantity *
      //         elem.invoice_order[i].item_cost_price;
      //   }
      // }
      // var sumWithDiscountAndTax =
      //   (sum - sum * elem.invoice_discount_rate) * (1 + elem.invoice_tax_rate);
      let invoice = elem;
      var sum = 0;
      var price_list = 1;
      if (
        invoice &&
        invoice.invoice_partner &&
        invoice.invoice_partner.partner_price_list &&
        invoice.invoice_partner.partner_price_list.price_list_multiplier &&
        !isNaN(invoice.invoice_partner.partner_price_list.price_list_multiplier)
      ) {
        price_list = Number(
          invoice.invoice_partner.partner_price_list.price_list_multiplier
        );
      }
      for (var i = 0; i < invoice.invoice_order.length; i++) {
        if (invoice.invoice_partner_type === "CUSTOMER") {
          sum =
            sum +
            (invoice.invoice_order[i].item_quantity
              ? Number(invoice.invoice_order[i].item_quantity)
              : 1) *
              (invoice.invoice_order[i].item_selling_price &&
              !isNaN(invoice.invoice_order[i].item_selling_price)
                ? Number(invoice.invoice_order[i].item_selling_price)
                : 0) *
              (price_list && !isNaN(price_list) ? price_list : 1);
          console.log(sum);
        } else {
          sum =
            sum +
            (invoice.invoice_order[i].item_quantity
              ? Number(invoice.invoice_order[i].item_quantity)
              : 1) *
              (invoice.invoice_order[i].item_cost_price &&
              isNaN(invoice.invoice_order[i].item_cost_price)
                ? Number(invoice.invoice_order[i].item_cost_price)
                : 0);
        }
      }
      // var sumWithDiscountAndTax =
      //   (sum - sum * invoice.invoice_discount_rate) *
      //   (1 + invoice.invoice_tax_rate);
      var sumWithDiscountAndTax = sumCalculation(sum, invoice);
      setInvoicetotal((prev) => {
        console.log(prev);
        return (
          prev + sumWithDiscountAndTax
          //  -
          // (!elem.invoice_settled_amount || isNaN(elem.invoice_settled_amount)
          //   ? 0
          //   : elem.invoice_settled_amount)
        );
      });
    });
    estimatefilter.forEach((elem) => {
      let invoice = elem;
      var sum = 0;
      var price_list = 1;
      if (
        invoice &&
        invoice.invoice_partner &&
        invoice.invoice_partner.partner_price_list &&
        invoice.invoice_partner.partner_price_list.price_list_multiplier &&
        !isNaN(invoice.invoice_partner.partner_price_list.price_list_multiplier)
      ) {
        price_list = Number(
          invoice.invoice_partner.partner_price_list.price_list_multiplier
        );
      }
      for (var i = 0; i < invoice.invoice_order.length; i++) {
        if (invoice.invoice_partner_type === "CUSTOMER") {
          sum =
            sum +
            (invoice.invoice_order[i].item_quantity
              ? Number(invoice.invoice_order[i].item_quantity)
              : 1) *
              (invoice.invoice_order[i].item_selling_price &&
              !isNaN(invoice.invoice_order[i].item_selling_price)
                ? Number(invoice.invoice_order[i].item_selling_price)
                : 0) *
              (price_list && !isNaN(price_list) ? price_list : 1);
          console.log(sum);
        } else {
          sum =
            sum +
            (invoice.invoice_order[i].item_quantity
              ? Number(invoice.invoice_order[i].item_quantity)
              : 1) *
              (invoice.invoice_order[i].item_cost_price &&
              isNaN(invoice.invoice_order[i].item_cost_price)
                ? Number(invoice.invoice_order[i].item_cost_price)
                : 0);
        }
      }
      // var sumWithDiscountAndTax =
      //   (sum - sum * invoice.invoice_discount_rate) *
      //   (1 + invoice.invoice_tax_rate);
      var sumWithDiscountAndTax = sumCalculation(sum, invoice);
      setEstimatetotal((prev) => {
        console.log(prev);
        return (
          prev + sumWithDiscountAndTax
          // -
          // (!elem.invoice_settled_amount || isNaN(elem.invoice_settled_amount)
          //   ? 0
          //   : elem.invoice_settled_amount)
        );
      });
    });
    // orderfilter.forEach((elem) => {
    //   let invoice = elem;
    //   var sum = 0;
    //   var price_list = 1;
    //   if (
    //     invoice &&
    //     invoice.invoice_partner &&
    //     invoice.invoice_partner.partner_price_list &&
    //     invoice.invoice_partner.partner_price_list.price_list_multiplier &&
    //     !isNaN(invoice.invoice_partner.partner_price_list.price_list_multiplier)
    //   ) {
    //     price_list = Number(
    //       invoice.invoice_partner.partner_price_list.price_list_multiplier
    //     );
    //   }
    //   for (var i = 0; i < invoice.invoice_order.length; i++) {
    //     if (invoice.invoice_partner_type === "CUSTOMER") {
    //       sum =
    //         sum +
    //         (invoice.invoice_order[i].item_quantity
    //           ? Number(invoice.invoice_order[i].item_quantity)
    //           : 1) *
    //           (invoice.invoice_order[i].item_selling_price &&
    //           !isNaN(invoice.invoice_order[i].item_selling_price)
    //             ? Number(invoice.invoice_order[i].item_selling_price)
    //             : 0) *
    //           (price_list && !isNaN(price_list) ? price_list : 1);
    //       console.log(sum);
    //     } else {
    //       sum =
    //         sum +
    //         (invoice.invoice_order[i].item_quantity
    //           ? Number(invoice.invoice_order[i].item_quantity)
    //           : 1) *
    //           (invoice.invoice_order[i].item_cost_price &&
    //           isNaN(invoice.invoice_order[i].item_cost_price)
    //             ? Number(invoice.invoice_order[i].item_cost_price)
    //             : 0);
    //     }
    //   }
    //   // var sumWithDiscountAndTax =
    //   //   (sum - sum * invoice.invoice_discount_rate) *
    //   //   (1 + invoice.invoice_tax_rate);
    //   var sumWithDiscountAndTax = sumCalculation(sum, invoice);
    //   setOrdertotal((prev) => {
    //     console.log(prev);
    //     return (
    //       prev +
    //       (sumWithDiscountAndTax -
    //         (!elem.invoice_settled_amount || isNaN(elem.invoice_settled_amount)
    //           ? 0
    //           : elem.invoice_settled_amount))
    //     );
    //   });
    // });
    setOrdertotal(orderfilter.length);
  }, [invoicedata]);

  // console.log(invoicefilter, estimatefilter, orderfilter, invoicetotal);

  return (
    // <Row>
    //   <Col md="6" xl className="d-flex">
    //     <Card className="flex-fill" style={{ borderLeft: "5px solid #5a8ed6" }}>
    //       <Card.Body className=" py-4">
    //         <div className="d-flex align-items-start">
    //           <div className="flex-grow-1">
    //             <h3 className="mb-2">
    //               {invoicetotal.toLocaleString("en-US", {
    //                 style: "currency",
    //                 currency: usercurrency,
    //               })}
    //             </h3>
    //             <p className="mb-2">Invoices</p>
    //           </div>
    //           <div className="d-inline-block ms-3">
    //             <div className="stat">
    //               <DollarSign className="align-middle text-success" />
    //             </div>
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //   </Col>
    //   <Col md="6" xl className="d-flex">
    //     <Card
    //       className="flex-fill "
    //       style={{ borderLeft: "5px solid #5a8ed6" }}
    //     >
    //       <Card.Body className=" py-4">
    //         <div className="d-flex align-items-start">
    //           <div className="flex-grow-1">
    //             <h3 className="mb-2">
    //               {estimatetotal.toLocaleString("en-US", {
    //                 style: "currency",
    //                 currency: usercurrency,
    //               })}
    //             </h3>
    //             <p className="mb-2">Estimates</p>
    //           </div>
    //           <div className="d-inline-block ms-3">
    //             <div className="stat">
    //               <DollarSign className="align-middle text-success" />
    //             </div>
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //   </Col>
    //   <Col md="6" xl className="d-flex">
    //     <Card className="flex-fill" style={{ borderLeft: "5px solid #5a8ed6" }}>
    //       <Card.Body className=" py-4">
    //         <div className="d-flex align-items-start">
    //           <div className="flex-grow-1">
    //             <h3 className="mb-2">
    //               {ordertotal.toLocaleString("en-US", {
    //                 style: "currency",
    //                 currency: usercurrency,
    //               })}
    //             </h3>
    //             <p className="mb-2">Orders</p>
    //           </div>
    //           <div className="d-inline-block ms-3">
    //             <div className="stat">
    //               <DollarSign className="align-middle text-success" />
    //             </div>
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //   </Col>
    //   <Col md="6" xl className="d-flex">
    //     <Card className="flex-fill" style={{ borderLeft: "5px solid #5a8ed6" }}>
    //       <Card.Body className=" py-4">
    //         <div className="d-flex align-items-start">
    //           <div className="flex-grow-1">
    //             <h3 className="mb-2">{customerNumber}</h3>
    //             <p className="mb-2">Customer</p>
    //           </div>
    //           <div className="d-inline-block ms-3">
    //             <div className="stat">
    //               <Users className="align-middle text-success" />
    //             </div>
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //   </Col>
    // </Row>
    //? change ??????????????????
    <Row className="mb-2">
      <Col md="6" xl className="d-flex" style={{ flexDirection: "column" }}>
        <Card
          className="flex-fill"
          style={{ marginBottom: "10px", cursor: "pointer" }}
          onClick={() => {
            if (partnerPermission.includes("ADD")) {
              navigate("/customer");
              mixpanel.track("Customer Card (Dashboard Web)");
            } else {
              setShowAccessDeniedModal(true);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // borderBottom: "2px solid pink",
              // backgroundColor: "pink",
              // flex: 1,
              // justifyContent: "space-between",
            }}
          >
            {/* <div style={{ flexGrow: 1 }}> */}
            <div
              className="flex-grow-1 "
              style={{
                borderBottom: "2px solid #8f8dc9",
                // borderBottom: "2px solid #edeff1",
                padding: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
              }}
            >
              <div>Customers</div>
              {/* <Badge bg="primary" className=" me-2">
                Monthly
              </Badge> */}

              <img
                className="me-2"
                src={empty_partner}
                style={{ height: "20px" }}
                alt="empty_invoice"
              ></img>
            </div>
            <div style={{ paddingTop: 15, paddingLeft: 15 }}>
              <p className="lead m-0" style={{ fontSize: "25px" }}>
                {customerNumber}
              </p>
            </div>
            {/* </div> */}
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15,
                // backgroundColor: "pink",
              }}
            >
              <small>Total Customers</small>
              {/* <small
                style={{
                  fontWeight: "bold",
                  fontSize: "110%",
                  color: "#4080ea",
                }}
              >
                8%
              </small> */}
              {/* <Badge
                bg="primary"
                // className=" me-2"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/customer/add-customer")}
              >
                New Customer
              </Badge> */}
              {partnerPermission.includes("ADD") ? (
                <div
                  style={{
                    padding: 5,
                    // backgroundColor: "#DE5474",
                    backgroundColor: "#8f8dc9",
                    color: "white",
                    fontSize: 10,
                    fontWeight: "bold",
                    borderRadius: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (partnerPermission.includes("ADD")) {
                      navigate("/customer/add-customer");
                      mixpanel.track(
                        "New Customer >>> Customer Card (Dashboard Web)"
                      );
                    }
                  }}
                >
                  New Customer
                </div>
              ) : (
                <></>
              )}
              {/* <div
                style={{
                  padding: 5,
                  backgroundColor: "white",
                  color: "black",
                  fontSize: 10,
                  fontWeight: "bold",
                  borderRadius: 5,
                  paddingRight: 10,
                  paddingLeft: 10,
                  border: "2px solid pink",
                }}
              >
                New Customer
              </div> */}
            </div>
          </div>
        </Card>
        {/* <div
          style={{
            // width: "100%",
            height: 50,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            // borderRadius: 50,
            cursor: "pointer",
          }}
          onClick={() => {
            mixpanel.track("Add Customer(Dashboard Web)");
            return navigate("/customer/add-customer");
          }}
        >
          <div
            style={{
              backgroundColor: "#DE5474",
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            <img
              src={empty_partner}
              style={{ height: "25px" }}
              alt="empty_invoice"
            ></img>
          </div>
          <div
            style={{
              backgroundColor: "#f786a0",
              // backgroundColor: "#F75E81",
              flex: 1,
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              paddingLeft: 30,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <text style={{ color: "white" }}>Add Customer</text>
          </div>
        </div> */}
      </Col>

      <Col md="6" xl className="d-flex" style={{ flexDirection: "column" }}>
        <Card
          className="flex-fill"
          style={{ marginBottom: "10px", cursor: "pointer" }}
          onClick={() => {
            if (estimatePermission) {
              navigate("/estimates");
              mixpanel.track("Estimate Card (Dashboard Web)");
            } else {
              setShowAccessDeniedModal(true);
            }
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="flex-grow-1 "
              style={{
                borderBottom: "2px solid #DE5474",
                padding: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
              }}
            >
              <div>Estimates</div>
              {/* <Badge bg="primary" className=" me-2">
                Monthly
              </Badge> */}
              <img
                className=" me-2"
                src={empty_invoice}
                style={{ height: "20px" }}
                alt="empty_invoice"
              ></img>
            </div>
            <div style={{ paddingTop: 15, paddingLeft: 15 }}>
              <p className="lead m-0" style={{ fontSize: "25px" }}>
                {truncateTextByWordCount(
                  currencyFormater(estimatetotal, usercurrency),
                  50
                )}
                {/* {currencyFormater(estimatetotal)} */}
              </p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <small>Total Estimates</small>
              {/* <small
                style={{
                  fontWeight: "bold",
                  fontSize: "110%",
                  color: "#4080ea",
                }}
              >
                50%
              </small> */}
              {/* <Badge
                bg="primary"
                // className=" me-2"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/estimates/add")}
              >
                New Estimate
              </Badge> */}
              {estimatePermission.includes("ADD") ? (
                <div
                  style={{
                    padding: 5,
                    backgroundColor: "#DE5474",
                    color: "white",
                    fontSize: 10,
                    fontWeight: "bold",
                    borderRadius: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (estimatePermission.includes("ADD")) {
                      navigate("/estimates/add");
                      mixpanel.track(
                        "New Estiamte >>> Estimate Card (Dashboard Web)"
                      );
                    }
                  }}
                >
                  New Estimate
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card>
        {/* <div
          style={{
            // width: "100%",
            height: 50,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            // borderRadius: 50,
            cursor: "pointer",
          }}
          onClick={() => {
            mixpanel.track("Add Estimate(Dashboard Web)");
            return navigate("/estimates/add");
          }}
        >
          <div
            style={{
              backgroundColor: "#665ca8",
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            <img
              src={empty_invoice}
              style={{ height: "25px" }}
              alt="empty_invoice"
            ></img>
          </div>
          <div
            style={{
              backgroundColor: "#928cbd",
              flex: 1,
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              paddingLeft: 30,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <text style={{ color: "white" }}>Add Estimate</text>
          </div>
        </div> */}
      </Col>
      <Col md="6" xl className="d-flex" style={{ flexDirection: "column" }}>
        <Card
          className="flex-fill"
          style={{ marginBottom: "10px", cursor: "pointer" }}
          onClick={() => {
            if (bookingPermission) {
              navigate("/bookings");
              mixpanel.track("Booking Card (Dashboard Web)");
            } else {
              setShowAccessDeniedModal(true);
            }
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="flex-grow-1 "
              style={{
                borderBottom: "2px solid #71BE1D",
                padding: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
              }}
            >
              <div>Bookings</div>
              {/* <Badge bg="primary" className=" me-2">
                Monthly
              </Badge> */}
              <img
                className=" me-2"
                src={schedule}
                style={{ height: "20px" }}
                alt="empty_invoice"
              ></img>
            </div>
            <div style={{ paddingTop: 15, paddingLeft: 15 }}>
              <p className="lead m-0" style={{ fontSize: "25px" }}>
                {/* {ordertotal.toLocaleString("en-US", {
                  style: "currency",
                  currency: usercurrency,
                })} */}
                {ordertotal}
              </p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <small>Total Bookings</small>
              {/* <small
                style={{
                  fontWeight: "bold",
                  fontSize: "110%",
                  color: "#4080ea",
                }}
              >
                10%
              </small>{" "} */}
              {/* <Badge
                bg="primary"
                // className=" me-2"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/jobs/add")}
              >
                New Job
              </Badge> */}

              {bookingPermission.includes("ADD") ? (
                <div
                  style={{
                    padding: 5,
                    backgroundColor: "#71BE1D",
                    color: "white",
                    fontSize: 10,
                    fontWeight: "bold",
                    borderRadius: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (bookingPermission.includes("ADD")) {
                      navigate("/bookings/add");
                      mixpanel.track(
                        "New Booking >>> Booking Card (Dashboard Web)"
                      );
                    }
                  }}
                >
                  New Booking
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card>
        {/* <div
          style={{
            // width: "100%",
            height: 50,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            // borderRadius: 50,
            cursor: "pointer",
          }}
          onClick={() => {
            mixpanel.track("Add Job(Dashboard Web)");
            return navigate("/jobs/add");
          }}
        >
          <div
            style={{
              backgroundColor: "#71BE1D",
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            <img
              src={empty_invoice}
              style={{ height: "25px" }}
              alt="empty_invoice"
            ></img>
          </div>
          <div
            style={{
              backgroundColor: "#9fc96d",
              // backgroundColor: "#7ED31E",
              flex: 1,
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              paddingLeft: 30,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <text style={{ color: "white" }}>Add Job</text>
          </div>
        </div> */}
      </Col>
      <Col md="6" xl className="d-flex" style={{ flexDirection: "column" }}>
        <Card
          className="flex-fill"
          style={{ marginBottom: "10px", cursor: "pointer" }}
          onClick={() => {
            if (invoicePermission) {
              navigate("/invoices");
              mixpanel.track("Invoice Card (Dashboard Web)");
            } else {
              setShowAccessDeniedModal(true);
            }
          }}
        >
          {/* <Card.Body className=" py-4"> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="flex-grow-1"
              style={{
                borderBottom: "2px solid #4f83cc",
                padding: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
              }}
            >
              <div>Invoices</div>
              {/* <Badge bg="primary" className=" me-2">
                Monthly
              </Badge> */}
              <img
                className=" me-2"
                src={empty_invoice}
                style={{ height: "20px" }}
                alt="empty_invoice"
              ></img>
            </div>
            <div style={{ paddingTop: 15, paddingLeft: 15 }}>
              <p className="lead m-0" style={{ fontSize: "25px" }}>
                {/* {invoicetotal.toLocaleString("en-US", {
                  style: "currency",
                  currency: usercurrency,
                })} */}
                {truncateTextByWordCount(
                  currencyFormater(invoicetotal, usercurrency),
                  50
                )}
              </p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <small>Total Invoices</small>
              {/* <small
                style={{
                  fontWeight: "bold",
                  fontSize: "110%",
                  color: "#4080ea",
                }}
              >
                30%
              </small> */}
              {/* <Badge
                bg="primary"
                // className=" me-2"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/invoices/add")}
              >
                New Invoice
              </Badge> */}
              {invoicePermission.includes("ADD") ? (
                <div
                  style={{
                    padding: 5,
                    backgroundColor: "#4f83cc",
                    color: "white",
                    fontSize: 10,
                    fontWeight: "bold",
                    borderRadius: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (invoicePermission.includes("ADD")) {
                      navigate("/invoices/add");
                      mixpanel.track(
                        "New Invoice >>> Invoice Card (Dashboard Web)"
                      );
                    } else {
                      setShowAccessDeniedModal(true);
                    }
                  }}
                >
                  New Invoice
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* </Card.Body> */}
        </Card>
        {/* <div
          style={{
            // width: "100%",
            height: 50,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            // borderRadius: 50,
            cursor: "pointer",
          }}
          onClick={() => {
            mixpanel.track("Add Invoice(Dashboard Web)");
            return navigate("/invoices/add");
          }}
        >
          <div
            style={{
              backgroundColor: "#3B94DD",
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            <img
              src={empty_invoice}
              style={{ height: "25px" }}
              alt="empty_invoice"
            ></img>
          </div>
          <div
            style={{
              backgroundColor: "#73bbf5",
              // backgroundColor: "#42A5F5",
              flex: 1,
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              paddingLeft: 30,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <text style={{ color: "white" }}>Add Invoice</text>
          </div>
        </div> */}
      </Col>
      <PermissionRequireModal
        showAccessDeniedModal={showAccessDeniedModal}
        setShowAccessDeniedModal={setShowAccessDeniedModal}
      />
    </Row>
  );
};

export default Statistics;
