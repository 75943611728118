import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Page404Authenticated from "../auth/Page404authenticated";
import icon from "../../assets/img/illustrations/icon.png";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "../../assets/css/App.css";
import mixpanel from "mixpanel-browser";

// import { useTable, useGlobalFilter } from "react-table";
// import { COLUMNS } from "./column";
// import { GlobalFilter } from "./GlobalFilter";
// import { useEffect, useState } from "react";
import {
  Calendar,
  Clipboard,
  Codesandbox,
  Compass,
  Edit2,
  Slack,
  GitCommit,
  Mail,
  MapPin,
  Phone,
  Send,
  X,
} from "react-feather";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Formik } from "formik";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import InvoiceTemplate from "../../components/InvoiceTemplate";
import { useSumwithDiscountAndTax } from "../../utils/useSumwithDiscountandTax";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";
import { logError } from "../../utils/useErrorlogging";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const addressFormating = (value) => {
  if (!value || Object.keys(value).length === 0) {
    return "-";
  }
  const { street, city, state, zip_code, country } = value;
  console.log(street, city, state, zip_code, country);

  const lineone = [street, city].filter((field) => field).join(", ");
  const linetwo = [state, zip_code].filter((field) => field).join(" - ");
  const linethree = [country].filter((field) => field).join("");

  console.log(lineone, linetwo, linethree);
  if (!lineone && !linetwo && !linethree) {
    return "-";
  }

  // return (
  //   <>
  //     {lineone && (
  //       <>
  //         <p className="m-0">{lineone} </p>
  //       </>
  //     )}
  //     {linetwo && (
  //       <>
  //         <p className="m-0">{linetwo} </p>
  //       </>
  //     )}
  //     {linethree && (
  //       <>
  //         <p className="m-0">{linethree} </p>
  //       </>
  //     )}
  //   </>
  // );
  return `${lineone ? lineone : ""} ${lineone && linetwo && "\n"}${
    linetwo ? linetwo : ""
  }${linetwo && linethree && "\n"}${linethree ? linethree : ""}`;
};
const schema = Yup.object().shape({
  mail: Yup.string()
    .email("Must be a valid email")
    .transform((value) => value.trim())
    .required("Email is required"),
  subject: Yup.string().required(),
});

const InvoiceDetail = () => {
  let usercurrency = localStorage.getItem("currency") || "USD";
  const { bdata } = useAuth();
  const { sumCalculation } = useSumwithDiscountAndTax();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [mailstatus, setMailstatus] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [openModals, setOpenModals] = useState(false);
  const [opendeleteModals, setOpendeleteModals] = useState(false);
  const [rowdata, setRowdata] = useState({});
  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("CUSTOMER");
  const [multiplier, setMultiplier] = useState(1);
  const [invoiceerror, setinvoiceerror] = useState(false);
  const [paidtotal, setpaidtotal] = useState(0);
  const [markaspaiddialog, setmarkaspaiddialog] = useState(false);
  const [paidbtndisabled, setPaidbtndisabled] = useState(false);
  const [acceptdialog, setacceptdialog] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [workflow, setWorkflow] = useState({ type: "Invoice" });
  // const [sendmecopy, setSendmecopy] = useState(false);
  const [disabledelete, setDisableDelete] = useState(false);
  const { currencyFormater } = useCurrencyFormating();
  const { isTeamAccount, userPermission } = useAuth();
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let estiamtePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  console.log(rowdata);
  useEffect(() => {
    if (opendeleteModals) {
      setDisableDelete(false);
    }
  }, [opendeleteModals]);
  const getRequiredData = (token) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    // fetch("https://bestmate.us/api/invoices/findAll", {
    fetch(
      `https://bestmate.us/api/invoices/get/${id}?timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          setinvoiceerror(true);
          setLoading(false);
          setFetchError(true);
          throw new Error(`HTTP error, status code ${res.status}`);
        }

        return res.json();
      })
      .then((result) => {
        console.log("new asdfasdf");
        console.log(result);
        setRowdata(result);
        setpaidtotal(result.invoice_grand_total);
        console.log(location);
        if (location.state.action === "send") {
          setOpenModals(true);
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  useEffect(async () => {
    mixpanel.track(`On Invoice Detail (Web)`);
    setLoading(true);
    setFetchError(false);
    setSubtotal(0);
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // if (
      //   location.state !== undefined &&
      //   Object.keys(location.state.row).length !== 0
      // ) {
      //   console.log("first");

      //   setRowdata(location.state.row);
      //   getBusinessData(tokenss);
      //   if (location.state.action === "send") {
      //     setOpenModals(true);
      //   }
      //   // setType(
      //   //   rowdata !== undefined
      //   //     ? rowdata.invoice_partner.partner_type
      //   //     : "CUSTOMER"
      //   // );
      // } else {
      console.log("second");
      getRequiredData(tokenss);
      // getBusinessData(tokenss);
      // setLoading(false);

      // }
    }
    // });
  }, [id]);

  useEffect(() => {
    if (rowdata && Object.keys(rowdata).length !== 0) {
      setinvoiceerror(false);
      setWorkflow({
        type: capitalizeFirstLetter(
          rowdata.invoice_workflow_type.toLowerCase() === "order"
            ? "booking"
            : rowdata.invoice_workflow_type.toLowerCase()
        ),
      });
      setType(
        rowdata.invoice_partner.partner_type
          ? rowdata.invoice_partner.partner_type
          : "CUSTOMER"
      );
      const mult =
        rowdata !== undefined && Object.keys(rowdata).length !== 0
          ? rowdata.invoice_partner.partner_price_list !== undefined &&
            Object.keys(rowdata.invoice_partner.partner_price_list).length !== 0
            ? rowdata.invoice_partner.partner_type === "SUPPLIER"
              ? 1
              : Number(
                  rowdata.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
            : 1
          : 1;
      setMultiplier(mult);
      rowdata.invoice_order.forEach((element) => {
        setSubtotal(
          (prev) =>
            prev +
            element.item_quantity *
              (rowdata.invoice_partner.partner_type === "SUPPLIER"
                ? element.item_cost_price
                  ? element.item_cost_price
                  : 0
                : element.item_selling_price
                ? element.item_selling_price
                : 0) *
              mult
        );
      });
      setLoading(false);
    } else if (rowdata === undefined) {
      setinvoiceerror(true);
      setLoading(false);
    }
    return () => setSubtotal(0);
  }, [rowdata]);

  // useEffect(async () => {
  //   setRowdata({});
  //   var user = firebase.auth().currentUser;
  //   if (user) {
  //     let tokenss = await user.getIdToken();
  //     settoken(tokenss);
  //     console.log("second");
  //     getRequiredData(tokenss);
  //     // getBusinessData(tokenss);
  //   }
  // }, [id]);

  // console.log(location);
  console.log(rowdata);
  console.log();
  // const type =
  //   rowdata !== undefined && Object.keys(rowdata).length !== 0
  //     ? rowdata.invoice_partner.partner_type
  //     : "CUSTOMER";
  // const multiplier =
  //   rowdata !== undefined && Object.keys(rowdata).length !== 0
  //     ? rowdata.invoice_partner.partner_price_list !== undefined &&
  //       Object.keys(rowdata.invoice_partner.partner_price_list).length !== 0
  //       ? type === "SUPPLIER"
  //         ? 1
  //         : Number(
  //             rowdata.invoice_partner.partner_price_list.price_list_multiplier
  //           )
  //       : 1
  //     : 1;

  // console.log(type, multiplier);
  const printableArea = useRef();
  console.log(rowdata, multiplier);

  // useEffect(() => {
  //   rowdata !== undefined && Object.keys(rowdata).length !== 0 ? (
  //     rowdata.invoice_order.forEach((element) => {
  //       setSubtotal(
  //         (prev) =>
  //           prev +
  //           element.item_quantity *
  //             (type === "SUPPLIER"
  //               ? element.item_cost_price
  //                 ? element.item_cost_price
  //                 : 0
  //               : element.item_selling_price
  //               ? element.item_selling_price
  //               : 0) *
  //             multiplier
  //       );
  //     })
  //   ) : (
  //     <></>
  //   );
  // }, [rowdata, multiplier]);

  // useEffect(() => {
  //   rowdata !== undefined ? (
  //     rowdata.invoice_workflow_type === "INVOICE" ? (
  //       setType("Invoice")
  //     ) : rowdata.invoice_workflow_type === "ESTIMATE" ? (
  //       setType("Estimate")
  //     ) : (
  //       setType("Order")
  //     )
  //   ) : (
  //     <></>
  //   );
  // }, [rowdata]);

  // console.log(subtotal);

  const submitData = async (dat) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        dat.team = true;
      } else {
        delete dat.team;
      }
      fetch("https://bestmate.us/api/invoices/send", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(dat),
      })
        .then(async (res) => {
          if (res.status >= 200 && res.status <= 300) {
            console.log(res);
            // const res2 = await res.json();
            // console.log(res2);
            // setMailstatus("sent");
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `${workflow.type} email sent to ${dat.invoice_email_recipient}`,
              ripple: "true",
              dismissible: "true",
            });
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track("Fetch Error (Invoice/Estimate Detail Web)", {
            //   error: "send mail api",
            // });
            logError({
              error: "fetch error",
              api: "send mail api",
              component: `Invoice/Estimate Detail Web`,
            });
          }
        })
        .catch((err) => {
          // mixpanel.track("Error (Invoice/Estiamte Detail Web)", {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: `Invoice/Estimate Detail Web`,
          });
          console.log(err);
          // setMailstatus("fail");
        });
    }
  };
  const submitHandler = (elem) => {
    const dat = {
      invoice_id: id,
      invoice_email_recipient: elem.mail,
      invoice_email_subject: elem.subject,
      invoice_send_copy: elem.sendcopytome,
    };
    mixpanel.track(
      `Send >>> Send Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
      {
        ...dat,
      }
    );
    submitData(dat);
    console.log(dat);
    setOpenModals(!openModals);
  };

  // let grandtotal =
  //   subtotal -
  //   subtotal * (rowdata.invoice_discount_rate / 100) +
  //   (subtotal - subtotal * (rowdata.invoice_discount_rate / 100)) *
  //     (rowdata.invoice_tax_rate / 100);

  const deleteHandler = async (type) => {
    setDisableDelete(true);
    var user = firebase.auth().currentUser;
    mixpanel.track(
      `Delete >>> Delete Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
      {
        id: id,
      }
    );
    if (user) {
      let tokenss = await user.getIdToken();
      console.log(rowdata.invoice_id);
      let deleteData = {
        invoice_id: id,
        // invoice_id: "BmuiRP5cpYWsOUluqHbj",
      };
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/invoices/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((res) => {
          // navigate(-1);
          if (res.status >= 200 && res.status <= 300) {
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `${type} deleted successfully`,
              ripple: "true",
              dismissible: "true",
            });
            navigate(`/${workflow.type.toLowerCase()}s`);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track(
            //   `Fetch Error (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
            //   {
            //     error: "invoice delete api",
            //   }
            // );
            logError({
              error: "fetch error",
              api: "invoice delete api",
              component: `${rowdata.invoice_workflow_type.toLowerCase()} Detail Web`,
            });
          }
        })
        .catch((err) => {
          console.log(err);

          // mixpanel.track(
          //   `Error (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
          //   {
          //     error: err,
          //   }
          // );
          logError({
            error: err,
            component: `Error (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
          });
        });
    }
  };
  const title = `${workflow.type} Detail`;
  console.log(rowdata);
  return (
    <>
      {/* {rowdata !== undefined && Object.keys(bdata).length !== 0 ? ( */}
      {!loading ? (
        <>
          {!invoiceerror ? (
            <>
              <Helmet title={title} />
              <Container fluid className="p-0 ">
                {/* {mailstatus === "sent" ? (
              <Alert
                variant="primary"
                className="position-fixed bottom-0 start-50 w-25"
                onClose={() => setMailstatus("")}
                dismissible
              >
                <div className="alert-message">
                  <strong>Mail Sent Succesfully!</strong>
                </div>
              </Alert>
            ) : (
              <></>
            )}
            {mailstatus === "fail" ? (
              <Alert
                variant="danger"
                className="position-fixed bottom-0 end-0 w-25"
                onClose={() => setMailstatus("")}
                dismissible
              >
                <div className="alert-message">
                  <strong>Mail Not sent Please try later!</strong>
                </div>
              </Alert>
            ) : (
              <></>
            )} */}
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // backgroundColor: "white",
                    padding: "0rem 1.5rem 2rem 1.5rem",
                  }}
                  className="pt-2 pb-2 border-bottom "
                >
                  <div>
                    <h2
                      className="mt-3 lead"
                      style={{
                        fontSize: "24px",
                        // , fontWeight: 100
                      }}
                    >
                      {/* Invoices */}
                      {title + "s"}
                    </h2>
                    <Breadcrumb
                      className="mb-0 lead"
                      style={{ fontSize: "13px" }}
                    >
                      <Breadcrumb.Item
                        active
                        onClick={() => navigate("/dashboard")}
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active>Work</Breadcrumb.Item>
                      <Breadcrumb.Item
                        active
                        onClick={() =>
                          navigate(`/${workflow.type.toLowerCase()}s`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {workflow.type}s
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active className="font-weight-bold">
                        {title + "s"}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ display: "flex" }}>
                      {workflow.type === "Invoice" ||
                      workflow.type === "Estimate" ? (
                        <>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: 12,
                              // marginRight: 10,
                            }}
                          >
                            {`Created from: `}
                          </p>
                          {rowdata.invoice_parent &&
                          rowdata.invoice_parent.length !== 0 ? (
                            <>
                              {rowdata.invoice_parent.map((elem, index) => {
                                console.log(elem);
                                return (
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      fontSize: 12,
                                      marginLeft: 3,
                                      color: "#407fea",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      mixpanel.track(
                                        `Created from (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                        {
                                          id: elem.invoice_id,
                                        }
                                      );
                                      if (
                                        elem.invoice_workflow_type ===
                                          "ORDER" &&
                                        bookingPermission
                                      ) {
                                        navigate(
                                          `/${
                                            elem.invoice_workflow_type ===
                                            "ORDER"
                                              ? "booking"
                                              : elem.invoice_workflow_type.toLowerCase()
                                          }s/${elem.invoice_id}`
                                        );
                                      }
                                      if (
                                        elem.invoice_workflow_type ===
                                          "REQUEST" &&
                                        requestPermission
                                      ) {
                                        navigate(
                                          `/${
                                            elem.invoice_workflow_type ===
                                            "ORDER"
                                              ? "booking"
                                              : elem.invoice_workflow_type.toLowerCase()
                                          }s/${elem.invoice_id}`
                                        );
                                      }
                                      if (
                                        elem.invoice_workflow_type ===
                                          "ESTIMATE" &&
                                        estiamtePermission
                                      ) {
                                        navigate(
                                          `/${
                                            elem.invoice_workflow_type ===
                                            "ORDER"
                                              ? "booking"
                                              : elem.invoice_workflow_type.toLowerCase()
                                          }s/${elem.invoice_id}`
                                        );
                                      }
                                      if (
                                        elem.invoice_workflow_type ===
                                          "INVOICE" &&
                                        invoicePermission
                                      ) {
                                        navigate(
                                          `/${
                                            elem.invoice_workflow_type ===
                                            "ORDER"
                                              ? "booking"
                                              : elem.invoice_workflow_type.toLowerCase()
                                          }s/${elem.invoice_id}`
                                        );
                                      }
                                    }}
                                  >
                                    {` ${
                                      elem.invoice_workflow_type === "ORDER"
                                        ? "Booking"
                                        : capitalizeFirstLetter(
                                            elem.invoice_workflow_type.toLowerCase()
                                          )
                                    } #${elem.invoice_number}${
                                      rowdata.invoice_parent.length - 1 ===
                                      index
                                        ? ""
                                        : ","
                                    }  `}
                                  </p>
                                );
                              })}
                            </>
                          ) : (
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: 12,
                                marginLeft: 3,
                                color: "#407fea",
                                cursor: "pointer",
                              }}
                            >
                              No
                              {rowdata.invoice_workflow_type === "ESTIMATE"
                                ? " Request"
                                : " Estimate"}
                            </p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {workflow.type === "Estimate" ? (
                        <>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: 12,
                              marginLeft: 10,
                              // marginLeft: rowdata.invoice_parent ? 10 : 0,
                            }}
                          >
                            {`Used for: `}
                          </p>
                          {rowdata.invoice_children &&
                          rowdata.invoice_children.length !== 0 ? (
                            <>
                              {rowdata.invoice_children.map((elem, index) => (
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 12,
                                    marginLeft: 3,
                                    color: "#407fea",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    mixpanel.track(
                                      `Used for (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                      {
                                        id: elem.invoice_id,
                                      }
                                    );
                                    if (
                                      elem.invoice_workflow_type === "ORDER" &&
                                      bookingPermission
                                    ) {
                                      navigate(
                                        `/${
                                          elem.invoice_workflow_type === "ORDER"
                                            ? "booking"
                                            : elem.invoice_workflow_type.toLowerCase()
                                        }s/${elem.invoice_id}`
                                      );
                                    }
                                    if (
                                      elem.invoice_workflow_type ===
                                        "REQUEST" &&
                                      requestPermission
                                    ) {
                                      navigate(
                                        `/${
                                          elem.invoice_workflow_type === "ORDER"
                                            ? "booking"
                                            : elem.invoice_workflow_type.toLowerCase()
                                        }s/${elem.invoice_id}`
                                      );
                                    }
                                    if (
                                      elem.invoice_workflow_type ===
                                        "ESTIMATE" &&
                                      estiamtePermission
                                    ) {
                                      navigate(
                                        `/${
                                          elem.invoice_workflow_type === "ORDER"
                                            ? "booking"
                                            : elem.invoice_workflow_type.toLowerCase()
                                        }s/${elem.invoice_id}`
                                      );
                                    }
                                    if (
                                      elem.invoice_workflow_type ===
                                        "INVOICE" &&
                                      invoicePermission
                                    ) {
                                      navigate(
                                        `/${
                                          elem.invoice_workflow_type === "ORDER"
                                            ? "booking"
                                            : elem.invoice_workflow_type.toLowerCase()
                                        }s/${elem.invoice_id}`
                                      );
                                    }
                                  }}
                                >{` ${
                                  elem.invoice_workflow_type === "ORDER"
                                    ? "Booking"
                                    : capitalizeFirstLetter(
                                        elem.invoice_workflow_type.toLowerCase()
                                      )
                                } #${elem.invoice_number}${
                                  rowdata.invoice_children.length - 1 === index
                                    ? ""
                                    : ","
                                } `}</p>
                              ))}
                            </>
                          ) : (
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: 12,
                                marginLeft: 3,
                                color: "#407fea",
                                cursor: "pointer",
                              }}
                            >
                              Not Used
                            </p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "21px",
                    }}
                  >
                    {/* {workflow.type === "Estimate" ? (
                      <>
                        <div
                          style={{
                            border: "1px #E7EAEC solid",
                            borderRadius: "3px",
                            padding: "0.375rem 0.75rem",
                            display: "flex",
                            justifyContent: "center",
                            color: "#676a6c",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: "inherit",
                            cursor: "pointer",
                            marginRight: "6px",
                          }}
                          onClick={() => {
                            const { invoice_children, ...restdata } = rowdata;
                            console.log(rowdata, restdata);
                            navigate(
                              `/jobs/add/`,

                              {
                                state: {
                                  ...restdata,
                                  invoice_workflow_type: "ESTIMATE",
                                  invoice_id: id,
                                },
                              }
                            );
                          }}
                        >
                          <Edit
                            size={15}
                            strokeWidth={3}
                            stroke="#676a6c"
                            style={{ marginRight: "2px" }}
                          />
                          Convert to Job
                        </div>
                        <div
                          style={{
                            border: "1px #E7EAEC solid",
                            borderRadius: "3px",
                            padding: "0.375rem 0.75rem",
                            display: "flex",
                            justifyContent: "center",
                            color: "#676a6c",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: "inherit",
                            cursor: "pointer",
                            marginRight: "6px",
                          }}
                          onClick={() => {
                            // console.log(workflow.rowData.original);
                            const { invoice_children, restdata } = rowdata;
                            navigate(
                              `/invoices/add/`,
                              // `/invoice/${workflow.type}s/edit/` + workflow.urlparamsdata,
                              {
                                // state: workflow.rowData.original,
                                state: { ...restdata, invoice_id: id },
                              }
                            );
                          }}
                        >
                          <Edit
                            size={15}
                            strokeWidth={3}
                            stroke="#676a6c"
                            style={{ marginRight: "2px" }}
                          />
                          Convert to Invoice
                        </div>
                      </>
                    ) : workflow.type === "Job" ? (
                      <div
                        style={{
                          border: "1px #E7EAEC solid",
                          borderRadius: "3px",
                          padding: "0.375rem 0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          color: "#676a6c",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: "inherit",
                          cursor: "pointer",
                          marginRight: "6px",
                        }}
                        onClick={() => {
                          // console.log(workflow.rowData.original);
                          const { invoice_children, restdata } = rowdata;
                          navigate(
                            `/invoices/add/`,
                            // `/invoice/${workflow.type}s/edit/` + workflow.urlparamsdata,
                            {
                              // state: workflow.rowData.original,
                              state: {
                                ...restdata,
                                invoice_id: id,
                                // invoice_workflow_type: "INVOICE",
                              },
                            }
                          );
                        }}
                      >
                        <Edit
                          size={15}
                          strokeWidth={3}
                          stroke="#676a6c"
                          style={{ marginRight: "2px" }}
                        />
                        Convert to Invoice
                      </div>
                    ) : (
                      <></>
                    )} */}
                    {workflow.type === "Estimate" &&
                    rowdata.invoice_status === "OPEN" ? (
                      <div
                        style={{
                          // border: "1px #E7EAEC solid",
                          border: "1px #838485 solid",
                          borderRadius: "3px",
                          padding: "0.375rem 0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          color: "#676a6c",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: "inherit",
                          cursor: "pointer",
                          marginRight: "6px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Accept Estimate (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                          );
                          setacceptdialog(true);
                        }}
                      >
                        {/* <Edit
                          size={15}
                          strokeWidth={3}
                          stroke="#676a6c"
                          style={{ marginRight: "2px" }}
                        /> */}
                        Accept
                      </div>
                    ) : (
                      <></>
                    )}
                    {workflow.type === "Invoice" &&
                    invoicePermission.includes("EDIT") ? (
                      <div
                        style={{
                          // border: "1px #E7EAEC solid",
                          border: "1px #838485 solid",
                          borderRadius: "3px",
                          padding: "0.375rem 0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          color: "#676a6c",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: "inherit",
                          cursor: "pointer",
                          marginRight: "6px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Mark as Paid (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                          );
                          setmarkaspaiddialog(true);
                        }}
                      >
                        {/* <Edit
                          size={15}
                          strokeWidth={3}
                          stroke="#676a6c"
                          style={{ marginRight: "2px" }}
                        /> */}
                        Mark as Paid
                      </div>
                    ) : (
                      <></>
                    )}
                    {workflow.type !== "Invoice" &&
                    (bookingPermission.includes("ADD") ||
                      invoicePermission.includes("ADD")) ? (
                      <>
                        <div className="dropdown">
                          <div
                            // className="dropdown-toggle"
                            style={{
                              // border: "1px #E7EAEC solid",
                              border: "1px #838485 solid",
                              borderRadius: "3px",
                              padding: "0.375rem 0.75rem",
                              display: "flex",
                              justifyContent: "center",
                              color: "#676a6c",
                              alignItems: "center",
                              textDecoration: "none",
                              fontSize: "inherit",
                              cursor: "pointer",
                              marginRight: "6px",
                              fontWeight: "bold",
                            }}
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Convert to
                            <div className="dropdown-toggle ms-2"></div>
                          </div>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            {workflow.type === "Estimate" &&
                            bookingPermission.includes("ADD") ? (
                              <button
                                className="dropdown-item"
                                type="button"
                                eventKey="3"
                                onClick={() => {
                                  const { invoice_children, ...restdata } =
                                    rowdata;
                                  console.log(rowdata, restdata);
                                  mixpanel.track(
                                    `Convert to Booking (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                    {
                                      ...restdata,
                                      invoice_id: id,
                                    }
                                  );
                                  navigate(`/bookings/add/`, {
                                    state: {
                                      ...restdata,
                                      // invoice_workflow_type: "ESTIMATE",
                                      invoice_id: id,
                                    },
                                  });
                                }}
                              >
                                Booking
                              </button>
                            ) : (
                              <></>
                            )}

                            {invoicePermission.includes("ADD") ? (
                              <button
                                className="dropdown-item"
                                type="button"
                                eventKey="3"
                                onClick={() => {
                                  const { invoice_children, ...restdata } =
                                    rowdata;
                                  mixpanel.track(
                                    `Convert to Invoice (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                    {
                                      ...restdata,
                                      invoice_id: id,
                                    }
                                  );
                                  navigate(`/invoices/add/`, {
                                    state: {
                                      ...restdata,
                                      // invoice_workflow_type: "ESTIMATE",
                                      invoice_id: id,
                                    },
                                  });
                                  console.log({ ...restdata, invoice_id: id });
                                }}
                              >
                                Invoice
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="dropdown">
                      <div
                        // className="dropdown-toggle"
                        style={{
                          // border: "2px #001A66 solid",
                          border: "1px #838485 solid",
                          borderRadius: "3px",
                          padding: "0.375rem 0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          color: "#676a6c",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: "inherit",
                          cursor: "pointer",
                          marginRight: "6px",
                          fontWeight: "bold",
                        }}
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                        <div className="dropdown-toggle ms-2"></div>
                      </div>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        {(workflow.type === "Invoice" &&
                          invoicePermission.includes("EDIT")) ||
                        (workflow.type === "Estimate" &&
                          estiamtePermission.includes("EDIT")) ? (
                          <button
                            className="dropdown-item"
                            type="button"
                            eventKey="3"
                            onClick={() => {
                              mixpanel.track(
                                `Edit (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                              );
                              navigate(
                                `/${workflow.type.toLowerCase()}s/edit/` + id,
                                {
                                  state: { ...rowdata, invoice_id: id },
                                }
                              );
                            }}
                          >
                            Edit
                          </button>
                        ) : (
                          <></>
                        )}
                        {/* <div
                          onClick={() => {
                            mixpanel.track(
                              `Print (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                            );
                          }}
                        >
                          <ReactToPrint
                            trigger={() => (
                              <button
                                className="dropdown-item"
                                type="button"
                                eventKey="1"
                              >
                                Print
                              </button>
                            )}
                            content={() => printableArea.current}
                          />
                        </div> */}

                        <a
                          className="dropdown-item"
                          type="button"
                          href={
                            "https://bestmate.us/invoice/view/download/" + id
                          }
                          onClick={() => {
                            mixpanel.track(
                              `Download (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                            );
                          }}
                          // onClick={() =>
                          //   `https://bestmate.us/invoice/view/download/${id}`
                          // }
                          eventKey="2"
                        >
                          Download
                        </a>
                        <button
                          className="dropdown-item"
                          type="button"
                          eventKey="3"
                          onClick={() => {
                            mixpanel.track(
                              `View as CLient (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                            );
                            window.open(
                              `https://bestmate.us/invoice/view/${id}`,
                              "_blank"
                            );
                          }}
                        >
                          View as Client
                        </button>
                        {(workflow.type === "Invoice" &&
                          invoicePermission.includes("DELETE")) ||
                        (workflow.type === "Estimate" &&
                          estiamtePermission.includes("DELETE")) ? (
                          <button
                            className="dropdown-item"
                            type="button"
                            eventKey="3"
                            onClick={() => {
                              console.log("start");
                              mixpanel.track(
                                `Delete (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                              );
                              setOpendeleteModals(true);
                              console.log("end");
                            }}
                          >
                            Delete
                          </button>
                        ) : (
                          <></>
                        )}
                        {/* <button className="dropdown-item" type="button">
                      Action
                    </button>
                    <button className="dropdown-item" type="button">
                      Another action
                    </button>
                    <button className="dropdown-item" type="button">
                      Something else here
                    </button> */}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Send ${rowdata.invoice_workflow_type.toLowerCase()} (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                        );
                        setOpenModals(!openModals);
                      }}
                    >
                      <div
                        style={{
                          border: "1px #E7EAEC solid",
                          borderRadius: "3px",
                          padding: "0.375rem 0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          color: "white",
                          // backgroundColor: "#4f83cb",
                          backgroundColor: "#407FEA",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: "inherit",
                          cursor: "pointer",
                          width: "100px",
                        }}
                      >
                        <Send
                          size={15}
                          strokeWidth={3.5}
                          stroke="white"
                          style={{ marginRight: "8px" }}
                        />
                        Send
                      </div>
                    </div>
                  </div>
                </Card>
                <div
                  style={{
                    padding: "0rem 1.5rem 2rem 1.5rem",
                  }}
                >
                  <Row>
                    <Col xl={8}>
                      {/* <div style={{ width: "100%" }}>
                        <table
                          ref={printableArea}
                          border="0"
                          cellPadding="0"
                          cellSpacing="0"
                          width="100%"
                          // maxWidth="600"
                          align="center"
                          style={{
                            // width: "8s00",

                            // minWidth: "100%",
                            background: "#fff",
                            borderRadius: "5px 5px 0 0",
                            fontFamily: "'Libre Franklin', sans-serif",
                            fontSize: "13px",
                            fontWeight: "300",
                            color: "#000",
                          }}
                        >
                          <tr style={{}}>
                            <td
                              // align="left"
                              // valign="top"
                              style={{
                                padding: "20px 30px",
                                borderBottom: "1px solid #ddd",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {bdata.business_logo ? (
                                <div>
                                  <img
                                    src={bdata.business_logo}
                                    style={{
                                      maxHeight: 135,
                                      width: "auto",
                                      height: "auto",
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                {workflow.type !== "Job" ? (
                                  <h1
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                      paddingBottom: "4px",
                                      fontSize: "30px",
                                      fontWeight: "bold",
                                      marginBottom: 0,
                                      color: "#adadad",
                                      textAlign: `${
                                        bdata.business_logo ? "end" : "start"
                                      }`,
                                    }}
                                  >
                                    {workflow.type.toUpperCase()}
                                  </h1>
                                ) : (
                                  <h1
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                      paddingBottom: "4px",
                                      fontSize: "30px",
                                      fontWeight: "bold",
                                      marginBottom: 0,
                                      color: "#adadad",
                                      textAlign: "end",
                                    }}
                                  >
                                    {`${workflow.type.toUpperCase()} # ${
                                      rowdata.invoice_number
                                    }`}
                                  </h1>
                                )}

                                <div
                                  style={{
                                    width: 250,

                                    marginTop: 10,
                                  }}
                                >
                                  {workflow.type === "Job" ? (
                                    <></>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // padding: "2px 0",
                                      }}
                                    >
                                      <p style={{ margin: 0 }}>
                                        {workflow.type} No:
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          // fontSize: 15,
                                          // fontWeight: "bold",
                                          // color: "grey",
                                        }}
                                      >
                                        {rowdata.invoice_number}
                                      </p>
                                    </div>
                                  )}
                                  {workflow.type === "Job" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          // padding: "2px 0",
                                        }}
                                      >
                                        <p style={{ margin: 0 }}>
                                          {workflow.type} Date:
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          {moment(
                                            rowdata.invoice_created_at
                                          ).format("MMM DD, YYYY")}
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          // padding: "2px 0",
                                        }}
                                      >
                                        <p style={{ margin: 0 }}>
                                          {workflow.type === "Estimate"
                                            ? "Validity Date:"
                                            : "Due Date:"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          {moment(
                                            rowdata.invoice_due_date
                                          ).format("MMM DD, YYYY")}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      // padding: "2px 0",
                                    }}
                                  >
                                    {workflow.type !== "Job" ? (
                                      <p
                                        style={{
                                          margin: 0,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        Status
                                      </p>
                                    ) : (
                                      <></>
                                    )}

                                    {rowdata.invoice_workflow_type ===
                                    "INVOICE" ? (
                                      <>
                                        {Number(
                                          sumCalculation(subtotal, rowdata) -
                                            (isNaN(
                                              rowdata.invoice_settled_amount
                                            )
                                              ? (0).toFixed(2)
                                              : Number(
                                                  rowdata.invoice_settled_amount
                                                ).toFixed(2))
                                        ) > 0 ? (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",

                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                // backgroundColor: "pink",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#DE5474",
                                                  // padding: "4px",
                                                  color: "white",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 55,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Pay Now
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#1AB395",
                                                  // padding: "4px",
                                                  color: "white",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 55,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Paid
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : rowdata.invoice_workflow_type ===
                                      "ESTIMATE" ? (
                                      <>
                                        {rowdata.invoice_status ===
                                        "ACCEPTED" ? (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#1AB395",
                                                  // padding: "4px",
                                                  color: "white",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 65,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Accepted
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {rowdata.invoice_status ===
                                            "CONVERTED" ? (
                                              <>
                                                {rowdata.invoice_children.some(
                                                  (e) =>
                                                    e.invoice_workflow_type ===
                                                    "INVOICE"
                                                ) ? (
                                                  <>
                                                    <div
                                                      style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "flex-end",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          // backgroundColor:
                                                          //   "#1a7bb8",
                                                          backgroundColor:
                                                            "#3f80ea",
                                                          // padding: "4px",
                                                          color: "white",
                                                          borderRadius: "5px",
                                                          fontSize: "10px",
                                                          fontWeight: "bold",
                                                          width: 65,
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Invoiced
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "flex-end",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            "#71BE1D",
                                                          // padding: "4px",
                                                          color: "white",
                                                          borderRadius: "5px",
                                                          fontSize: "10px",
                                                          fontWeight: "bold",
                                                          width: 65,
                                                          textAlign: "center",
                                                          lineHeight: 1.3,
                                                        }}
                                                      >
                                                        Job Created
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#DE5474",
                                                      // padding: "4px",
                                                      color: "white",
                                                      borderRadius: "5px",
                                                      fontSize: "10px",
                                                      fontWeight: "bold",
                                                      width: 65,
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Open
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {rowdata.invoice_status ===
                                        "CONVERTED" ? (
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <div
                                              style={{
                                                // backgroundColor: "#1a7bb8",
                                                backgroundColor: "#3f80ea",
                                                // padding: "4px",
                                                color: "white",
                                                borderRadius: "5px",
                                                fontSize: "10px",
                                                fontWeight: "bold",
                                                width: 75,
                                                textAlign: "center",
                                              }}
                                            >
                                              Invoiced
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            {rowdata.invoice_time_status ===
                                              "PAST" &&
                                            rowdata.invoice_visits &&
                                            rowdata.invoice_visits.length !==
                                              0 ? (
                                              <div
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    backgroundColor: "#807e7f",
                                                    // padding: "4px",
                                                    color: "white",
                                                    borderRadius: "5px",
                                                    fontSize: "10px",
                                                    fontWeight: "bold",
                                                    width: 75,
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Past
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {rowdata.invoice_time_status ===
                                                  "UPCOMING" &&
                                                rowdata.invoice_visits &&
                                                rowdata.invoice_visits
                                                  .length !== 0 ? (
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          "#71BE1D",
                                                        // padding: "4px",
                                                        color: "white",
                                                        borderRadius: "5px",
                                                        fontSize: "10px",
                                                        fontWeight: "bold",
                                                        width: 75,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Upcoming
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          "white",
                                                        padding: "4px",
                                                        color: "grey",
                                                        border:
                                                          "1px solid #71BE1D",
                                                        borderRadius: "5px",
                                                        fontSize: "10px",
                                                        fontWeight: "bold",
                                                        // width: 75,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Unscheduled
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr style={{}}>
                            <td
                              align="left"
                              valign="top"
                              style={{
                                padding: "20px 30px",
                                borderBottom:
                                  workflow.type === "Job"
                                    ? "1px solid #ddd"
                                    : "0px solid white",
                              }}
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                align="center"
                              >
                                <tr>
                                  <td align="left" valign="top" width="50%">
                                    <h4
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                        fontSize: "10px",
                                        color: "#999",
                                      }}
                                    >
                                      {workflow.type === "Estimate"
                                        ? "NAME:"
                                        : "BILL TO:"}
                                    </h4>
                                    {Object.keys(rowdata.invoice_partner)
                                      .length !== 0 ? (
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            display: "block",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {
                                            rowdata.invoice_partner
                                              .partner_first_name
                                          }{" "}
                                          {
                                            rowdata.invoice_partner
                                              .partner_last_name
                                          }
                                        </div>
                                        {
                                          rowdata.invoice_partner
                                            .partner_billing_address.street
                                        }{" "}
                                        <br />
                                        {
                                          rowdata.invoice_partner
                                            .partner_billing_address.city
                                        }{" "}
                                        <br />
                                        {
                                          rowdata.invoice_partner
                                            .partner_billing_address.state
                                        }{" "}
                                        {
                                          rowdata.invoice_partner
                                            .partner_billing_address.zip_code
                                        }{" "}
                                        {
                                          rowdata.invoice_partner
                                            .partner_billing_address.country
                                        }
                                      </p>
                                    ) : (
                                      <>Partner not selected</>
                                    )}
                                  </td>

                                  <td align="right" valign="top" width="50%">
                                    <h4
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                        fontSize: "10px",
                                        color: "#999",
                                      }}
                                    >
                                      FROM:
                                    </h4>
                                    {Object.keys(rowdata.invoice_partner)
                                      .length !== 0 ? (
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            display: "block",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {bdata.business_name
                                            ? bdata.business_name
                                            : bdata.business_user_name}
                                        </div>
                                        {bdata.business_address?.street} <br />
                                        {bdata.business_address?.city} <br />
                                        {bdata.business_address?.state}{" "}
                                        {bdata.business_address?.zip_code}{" "}
                                        {bdata.business_address?.country}
                                      </p>
                                    ) : (
                                      <>Partner not selected</>
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          {workflow.type === "Job" ? (
                            <tr style={{}}>
                              <td
                                align="left"
                                valign="top"
                                style={{
                                  padding: "20px 30px",
                                }}
                              >
                                <table
                                  border="0"
                                  cellPadding="0"
                                  cellSpacing="0"
                                  width="100%"
                                  align="center"
                                >
                                  <tr>
                                    <td align="left" valign="top" width="50%">
                                      <p
                                        style={{
                                          fontSize: 19,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Job Description
                                      </p>
                                      <h4
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          fontSize: "12px",
                                          color: "#999",
                                        }}
                                      >
                                        Job Name:
                                      </h4>
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            display: "block",
                                            fontSize: 14,
                                            // fontWeight: "bold",
                                          }}
                                        >
                                          {rowdata.invoice_title
                                            ? rowdata.invoice_title
                                            : "-"}
                                        </div>
                                      </p>
                                      <h4
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          fontSize: "12px",
                                          color: "#999",
                                          marginTop: 10,
                                        }}
                                      >
                                        Job Description:
                                      </h4>
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                          // backgroundColor: "pink",
                                          maxWidth: 300,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            display: "block",
                                            fontSize: 14,
                                            // fontWeight: "bold",
                                          }}
                                        >
                                          {rowdata.invoice_description
                                            ? rowdata.invoice_description
                                            : "-"}
                                        </div>
                                      </p>
                                    </td>
                                    <td align="left" valign="top" width="50%">
                                      <p
                                        style={{
                                          fontSize: 19,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Job Timings
                                      </p>

                                      <h4
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          fontSize: "12px",
                                          color: "#999",
                                          marginTop: 10,
                                        }}
                                      >
                                        Job Timings:
                                      </h4>
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            display: "block",
                                            fontSize: 14,
                                            // fontWeight: "bold",
                                          }}
                                        >
                                          {rowdata.invoice_visits &&
                                          rowdata.invoice_visits.length !== 0
                                            ? `${moment(
                                                rowdata.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("h:mm A")} - ${moment(
                                                rowdata.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("h:mm A")}`
                                            : "Not Scheduled"}
                                        </div>
                                      </p>
                                      <h4
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          fontSize: "12px",
                                          color: "#999",
                                          marginTop: 10,
                                        }}
                                      >
                                        Job Date:
                                      </h4>
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            display: "block",
                                            fontSize: 14,
                                            // fontWeight: "bold",
                                          }}
                                        >
                                          {rowdata.invoice_visits &&
                                          rowdata.invoice_visits.length !== 0
                                            ? `${moment(
                                                rowdata.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("MMMM DD, YYYY")} `
                                            : "Not Scheduled"}
                                        </div>
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td
                              align="right"
                              valign="top"
                              style={{
                                minHeight:
                                  workflow.type === "Job" ? "500px" : "500px",
                                display: "table",
                                width: "100%",
                              }}
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                align="center"
                              >
                                <tr>
                                  <td
                                    align="left"
                                    valign="top"
                                    style={{ padding: "0 30px" }}
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      align="center"
                                    >
                                      <thead
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                        }}
                                      >
                                        <tr
                                          className="th"
                                          style={{
                                            margin: "0",
                                            padding: "0",
                                            background:
                                              workflow.type === "Job"
                                                ? "#1AB293"
                                                : "#4f83cc",
                                            color: "#fff",
                                          }}
                                        >
                                          <th
                                            align="left"
                                            valign="top"
                                            style={{
                                              padding: "8px",
                                              paddingLeft: 0,
                                              // backgroundColor: "pink",
                                              // paddingRight: 20,
                                            }}
                                          >
                                            <p
                                              style={{
                                                margin: "4px 10px",
                                              }}
                                            >
                                              Items
                                            </p>
                                          </th>
                                          <th
                                            align="center"
                                            valign="top"
                                            width="120px"
                                            style={{
                                              padding: "8px",
                                              // backgroundColor: "pink",
                                              // paddingRight: 20,
                                            }}
                                          >
                                            <p
                                              align="center"
                                              style={{
                                                margin: "4px 10px",
                                              }}
                                            >
                                              Qty
                                            </p>
                                          </th>
                                          <th
                                            align="center"
                                            valign="top"
                                            width="120px"
                                            style={{ padding: "8px" }}
                                          >
                                            <p
                                              align="center"
                                              style={{
                                                margin: "4px 10px",
                                              }}
                                            >
                                              Unit Cost
                                            </p>
                                          </th>
                                          <th
                                            align="right"
                                            valign="top"
                                            width="90px"
                                            // align="right"
                                            style={{ padding: "8px" }}
                                          >
                                            <p
                                              align="right"
                                              style={{
                                                margin: "4px 10px",
                                              }}
                                            >
                                              Total
                                            </p>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          border: "0",
                                        }}
                                      >
                                        {rowdata.invoice_order.map(
                                          (element, index) => {
                                            return (
                                              <tr key={index}>
                                                <td
                                                  align="left"
                                                  valign="top"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #ddd",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: "0",
                                                      padding: "0",
                                                      lineHeight: "30px",
                                                    }}
                                                  >
                                                    {element.item_name}
                                                  </p>
                                                  {element.item_description ? (
                                                    <p
                                                      style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        lineHeight: "13px",
                                                        fontSize: 11,
                                                        marginBottom: 4,
                                                      }}
                                                    >
                                                      {element.item_description
                                                        .length > 120
                                                        ? element.item_description.substring(
                                                            0,
                                                            120
                                                          ) + " ..."
                                                        : element.item_description}
                                                    </p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </td>
                                                <td
                                                  align="right"
                                                  // valign="top"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #ddd",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <p
                                                    align="center"
                                                    style={{
                                                      margin: "0",
                                                      padding: "0",
                                                      lineHeight: "30px",
                                                    }}
                                                  >
                                                    {element.item_quantity}
                                                  </p>
                                                </td>
                                                <td
                                                  align="right"
                                                  // valign="top"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #ddd",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <p
                                                    align="center"
                                                    style={{
                                                      margin: "0",
                                                      padding: "0",
                                                      lineHeight: "30px",
                                                    }}
                                                  >
                                                    {(
                                                      (type === "SUPPLIER"
                                                        ? element.item_cost_price
                                                          ? element.item_cost_price
                                                          : 0
                                                        : element.item_selling_price
                                                        ? element.item_selling_price
                                                        : 0) * multiplier
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency:
                                                        rowdata &&
                                                        rowdata.invoice_currency_code
                                                          ? rowdata.invoice_currency_code
                                                          : usercurrency,
                                                    })}
                                                  </p>
                                                </td>
                                                <td
                                                  align="right"
                                                  // valign="top"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #ddd",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: "0",
                                                      padding: "0",
                                                      lineHeight: "30px",
                                                    }}
                                                  >
                                                    {(
                                                      element.item_quantity *
                                                      (type === "SUPPLIER"
                                                        ? element.item_cost_price
                                                          ? element.item_cost_price
                                                          : 0
                                                        : element.item_selling_price
                                                        ? element.item_selling_price
                                                        : 0) *
                                                      multiplier
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency:
                                                        rowdata &&
                                                        rowdata.invoice_currency_code
                                                          ? rowdata.invoice_currency_code
                                                          : usercurrency,
                                                    })}
                                                  </p>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                {workflow.type !== "Job" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td
                                        align="right"
                                        valign="top"
                                        style={{
                                          padding: "20px 38px 20px 30px",
                                          paddingTop:
                                            workflow.type === "Job" ? 10 : 60,
                                        }}
                                      >
                                        <div>
                                          <p
                                            style={{
                                              margin: "0",
                                              padding: "0",
                                              lineHeight: "24px",
                                              fontWeight: "bold",
                                              // backgroundColor: "pink",
                                              display: "inline-flex",
                                              minWidth: "200px",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            Subtotal:
                                            <span
                                              style={{
                                                display: "inline-block",
                                                minWidth: "90px",
                                                // backgroundColor: "red",
                                              }}
                                            >
                                              {subtotal.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency:
                                                    rowdata &&
                                                    rowdata.invoice_currency_code
                                                      ? rowdata.invoice_currency_code
                                                      : usercurrency,
                                                }
                                              )}
                                            </span>
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <table>
                              <tr>
                                <td width="100%">
                                  {rowdata.invoice_notes ? (
                                    <td
                                      align="left"
                                      valign="top"
                                      style={{
                                        padding: "0px 30px 20px 30px",
                                        // borderTop: "1px solid #ddd",
                                        // backgroundColor: "pink",
                                      }}
                                    >
                                      <strong style={{ display: "block" }}>
                                        Notes:
                                      </strong>
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                          // marginTop: 10,
                                          // backgroundColor: "red",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {rowdata.invoice_notes}
                                      </p>
                                    </td>
                                  ) : (
                                    <td
                                      align="left"
                                      valign="top"
                                      style={{ padding: "20px 30px" }}
                                    >
                                      <p
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                          lineHeight: "21px",
                                        }}
                                      ></p>
                                    </td>
                                  )}
                                </td>
                                <td
                                  align="right"
                                  valign="top"
                                  style={{
                                    padding: "20px 38px 20px 30px",
                                    // paddingTop:
                                    //   workflow.type === "Job" ? 10 : 60,
                                  }}
                                >
                                  <div>
                                    {workflow.type === "Job" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <div>
                                          <p
                                            style={{
                                              margin: "0",
                                              padding: "0",
                                              lineHeight: "24px",
                                              fontWeight: "bold",
                                              // backgroundColor: "pink",
                                              display: "inline-flex",
                                              minWidth: "200px",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            Subtotal:
                                            <span
                                              style={{
                                                display: "inline-block",
                                                minWidth: "90px",
                                                // backgroundColor: "red",
                                              }}
                                            >
                                              {subtotal.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency:
                                                    rowdata &&
                                                    rowdata.invoice_currency_code
                                                      ? rowdata.invoice_currency_code
                                                      : usercurrency,
                                                }
                                              )}
                                            </span>
                                          </p>
                                        </div>

                                        <div>
                                          <p
                                            style={{
                                              margin: "0",
                                              padding: "0",
                                              lineHeight: "24px",
                                              display: "inline-flex",
                                              minWidth: "200px",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            Discount:{" "}
                                            <span
                                              style={{
                                                display: "inline-block",
                                                minWidth: "90px",
                                              }}
                                            >
                                              {`
                                      - ${Number(
                                        rowdata.invoice_discount_type &&
                                          rowdata.invoice_discount_type ===
                                            "absolute"
                                          ? rowdata.invoice_discount_rate
                                          : subtotal *
                                              rowdata.invoice_discount_rate
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency:
                                          rowdata &&
                                          rowdata.invoice_currency_code
                                            ? rowdata.invoice_currency_code
                                            : usercurrency,
                                      })}
                                        `}
                                            </span>
                                          </p>
                                        </div>
                                        <div>
                                          <p
                                            style={{
                                              margin: "0",
                                              padding: "0",
                                              lineHeight: "24px",
                                              display: "inline-flex",
                                              minWidth: "200px",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            Tax:
                                            <span
                                              style={{
                                                display: "inline-block",
                                                minWidth: "90px",
                                              }}
                                            >
                                              {Number(
                                                rowdata.invoice_tax_type &&
                                                  rowdata.invoice_tax_type ===
                                                    "absolute"
                                                  ? rowdata.invoice_tax_rate
                                                  : (rowdata.invoice_discount_type &&
                                                    rowdata.invoice_discount_type ===
                                                      "absolute"
                                                      ? subtotal -
                                                        rowdata.invoice_discount_rate
                                                      : subtotal *
                                                        (1 -
                                                          rowdata.invoice_discount_rate)) *
                                                      rowdata.invoice_tax_rate
                                              ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency:
                                                  rowdata &&
                                                  rowdata.invoice_currency_code
                                                    ? rowdata.invoice_currency_code
                                                    : usercurrency,
                                              })}
                                            </span>
                                          </p>
                                        </div>
                                        <div>
                                          <p
                                            className="total-txt"
                                            style={{
                                              margin: "0",
                                              padding: "0",
                                              paddingTop: "5px",
                                              fontWeight: "bold",
                                              lineHeight: "24px",
                                              display: "inline-flex",
                                              minWidth: "200px",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            Total:{" "}
                                            <span
                                              style={{
                                                display: "inline-block",
                                                minWidth: "90px",
                                              }}
                                            >
                                              {Number(
                                                sumCalculation(
                                                  subtotal,
                                                  rowdata
                                                )
                                              ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency:
                                                  rowdata &&
                                                  rowdata.invoice_currency_code
                                                    ? rowdata.invoice_currency_code
                                                    : usercurrency,
                                              })}
                                            </span>
                                          </p>
                                        </div>
                                        <div>
                                          <p
                                            style={{
                                              margin: "0",
                                              padding: "0",
                                              lineHeight: "24px",
                                              display: "inline-flex",
                                              minWidth: "200px",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            Settled Amount:{" "}
                                            <span
                                              style={{
                                                display: "inline-block",
                                                minWidth: "90px",
                                              }}
                                            >
                                              {Number(
                                                rowdata.invoice_settled_amount
                                                  ? rowdata.invoice_settled_amount
                                                  : 0
                                              ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency:
                                                  rowdata &&
                                                  rowdata.invoice_currency_code
                                                    ? rowdata.invoice_currency_code
                                                    : usercurrency,
                                              })}
                                            </span>
                                          </p>
                                        </div>

                                        <strong
                                          style={{
                                            // display: "inline-block",
                                            background: "#f5f5f5",
                                            marginTop: "8px",
                                            // width: "100%",
                                            margin: "0",
                                            padding: "0",
                                            lineHeight: "24px",
                                            display: "inline-flex",
                                            minWidth: "230px",
                                            justifyContent: "space-between",
                                            // backgroundColor: "red",
                                            marginRight: "-15px",
                                            padding: "5px 15px",
                                          }}
                                        >
                                          Balance Amount:{" "}
                                          <span
                                            style={{
                                              display: "inline-block",
                                              minWidth: "90px",
                                            }}
                                          >
                                            {Number(
                                              sumCalculation(
                                                subtotal,
                                                rowdata
                                              ) -
                                                (isNaN(
                                                  rowdata.invoice_settled_amount
                                                )
                                                  ? (0).toFixed(2)
                                                  : Number(
                                                      rowdata.invoice_settled_amount
                                                    ).toFixed(2))
                                            ).toLocaleString("en-US", {
                                              style: "currency",
                                              currency:
                                                rowdata &&
                                                rowdata.invoice_currency_code
                                                  ? rowdata.invoice_currency_code
                                                  : usercurrency,
                                            })}
                                          </span>
                                        </strong>
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </tr>
                          <tr style={{}}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 20,
                                // borderTop: "1px solid #ddd",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: 5,
                                }}
                              >
                                Powered by
                              </div>
                              <img
                                src={icon}
                                alt="BestMate"
                                id="authheaderlogo"
                                style={{
                                  alignSelf: "center",
                                  height: 20,
                                  width: 22,
                                  marginBottom: 3,
                                }}
                              />
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: 5,
                                }}
                              >
                                BESTMATE
                              </div>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                color: "#888a8a",
                              }}
                            >
                              {workflow.type} generated with Bestmate - All in
                              one Business App
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                color: "#888a8a",
                                lineHeight: 0.95,
                                marginBottom: 15,
                              }}
                            >
                              Visit bestmate.us and open your account free on
                              bestmate
                            </div>
                          </tr>
                        </table>
                        <table
                          border="0"
                          cellPadding="0"
                          cellSpacing="0"
                          width="100%"
                          // maxWidth="400"
                          // width="800"
                          align="center"
                          style={{
                            // width: "100%",
                            // maxWidth: "400",
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd",
                            borderRadius: "0 0 5px 5px",
                          }}
                        >
                          <tr>
                            <td
                              align="right"
                              valign="top"
                              style={{ padding: "20px 30px" }}
                            >
                              <span
                                onClick={() => {
                                  mixpanel.track(
                                    `Print (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                                  );
                                }}
                              >
                                <ReactToPrint
                                  trigger={() => (
                                    <button
                                      style={{
                                        display: "inline-block",
                                        cursor: "pointer",
                                        background: "#eee",
                                        border: "1px solid #999",
                                        color: "#000",
                                        textDecoration: "none",
                                        padding: "5px 10px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPrint}
                                        style={{ marginRight: 10 }}
                                      />
                                      Print
                                    </button>
                                  )}
                                  content={() => printableArea.current}
                                />
                              </span>
                              <a
                                href={
                                  "https://bestmate.us/invoice/view/download/" +
                                  id
                                }
                                title="Download"
                                onClick={() =>
                                  mixpanel.track(
                                    `Download (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                                  )
                                }
                                style={{
                                  display: "inline-block",
                                  background: "#eee",
                                  border: "1px solid #999",
                                  color: "#000",
                                  textDecoration: "none",
                                  padding: "5px 20px",
                                  marginLeft: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  style={{ marginRight: 10 }}
                                />
                                Download
                              </a>
                            </td>
                          </tr>
                        </table>
                      </div> */}
                      <Card
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: 420,
                          backgroundColor: "white",
                        }}
                        className="border rounded-0"
                      >
                        <div
                          className="p-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h3 className="m-0">
                            {`${workflow.type.toUpperCase()} #${
                              rowdata.invoice_number
                            }`}
                          </h3>

                          {(workflow.type === "Invoice" &&
                            invoicePermission.includes("EDIT")) ||
                          (workflow.type === "Estimate" &&
                            estiamtePermission.includes("EDIT")) ? (
                            <Edit2
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                // marginRight: 15,
                                position: "relative",
                                // top: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                mixpanel.track(`Edit(Booking Detail Web)`);
                                navigate(
                                  `/${workflow.type.toLowerCase()}s/edit/` + id,
                                  {
                                    state: { ...rowdata, invoice_id: id },
                                  }
                                );
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            CUSTOMER
                          </p>
                        </div>
                        <div>
                          <p
                            className="p-3 m-0"
                            style={{ fontSize: "16px", color: "#4F83CA" }}
                          >{`${rowdata.invoice_partner.partner_first_name}
                          ${rowdata.invoice_partner.partner_last_name}`}</p>
                        </div>
                        <hr className="m-0" />
                        <div className="p-3 ">
                          <Row>
                            <Col md={6}>
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                // onClick={() => {
                                //   console.log(servAddress);
                                //   if (servAddress !== "-") {
                                //     mixpanel.track(
                                //       `Service Address Partner (Job Detail Web)`
                                //     );
                                //     window.open(
                                //       `https://www.google.com/maps?q=${servAddress}`
                                //     );
                                //   }
                                // }}
                              >
                                <MapPin
                                  size={16}
                                  strokeWidth={2}
                                  stroke="#485056"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1, width: "90%" }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    {workflow.type === "Estimate"
                                      ? ""
                                      : "Billing"}{" "}
                                    Address
                                  </p>

                                  <p
                                    style={{
                                      color:
                                        addressFormating(
                                          rowdata.invoice_partner
                                            .partner_billing_address
                                        ) === "-"
                                          ? "#b5b3b3"
                                          : "black",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {addressFormating(
                                      rowdata.invoice_partner
                                        .partner_billing_address
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col
                              md={6}
                              style={{
                                borderLeft: "1px solid #D9DCDE",
                                paddingLeft: 20,
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (rowdata.invoice_partner.partner_number) {
                                    mixpanel.track(
                                      `Phone Partner (Booking Detail Web)`
                                    );
                                    window.location = `tel:${rowdata.invoice_partner.partner_number}`;
                                  }
                                }}
                              >
                                <Phone
                                  size={16}
                                  strokeWidth={2}
                                  stroke="#485056"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1, width: "90%" }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Phone No.
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_partner
                                        .partner_number
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_partner
                                        .partner_number
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_partner.partner_number
                                      ? rowdata.invoice_partner.partner_number
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (rowdata.invoice_partner.partner_email) {
                                    mixpanel.track(
                                      `Email Partner (Booking Detail Web)`
                                    );
                                    window.location = `mailto:${rowdata.invoice_partner.partner_email}`;
                                  }
                                }}
                              >
                                <Mail
                                  size={16}
                                  strokeWidth={2}
                                  stroke="#485056"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1, width: "90%" }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Email
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_partner
                                        .partner_email
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_partner
                                        .partner_email
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_partner.partner_email
                                      ? rowdata.invoice_partner.partner_email
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            ITEM DETAILS
                          </p>
                        </div>
                        <div>
                          {rowdata.invoice_order.length !== 0 ? (
                            <>
                              {rowdata.invoice_order.map((elem) => {
                                return (
                                  <>
                                    <div style={{}} className="p-3">
                                      <p className="m-0">{elem.item_name}</p>
                                      <div
                                        className="d-flex mt-2"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="d-flex">
                                          <p className="m-0">
                                            {elem.item_quantity}
                                          </p>
                                          <p className="m-0">&nbsp;X&nbsp;</p>
                                          <p
                                            className="m-0"
                                            style={{ maxWidth: 200 }}
                                          >
                                            {/* {(
                                              (type === "SUPPLIER"
                                                ? elem.item_cost_price
                                                  ? elem.item_cost_price
                                                  : 0
                                                : elem.item_selling_price
                                                ? elem.item_selling_price
                                                : 0) * multiplier
                                            ).toLocaleString("en-US", {
                                              style: "currency",
                                              currency:
                                                rowdata &&
                                                rowdata.invoice_currency_code
                                                  ? rowdata.invoice_currency_code
                                                  : usercurrency,
                                            })} */}
                                            {currencyFormater(
                                              (type === "SUPPLIER"
                                                ? elem.item_cost_price
                                                  ? elem.item_cost_price
                                                  : 0
                                                : elem.item_selling_price
                                                ? elem.item_selling_price
                                                : 0) * multiplier,
                                              rowdata &&
                                                rowdata.invoice_currency_code
                                                ? rowdata.invoice_currency_code
                                                : usercurrency
                                            )}
                                          </p>
                                        </div>
                                        <p
                                          className="m-0"
                                          style={{ maxWidth: 250 }}
                                        >
                                          {/* {(
                                            elem.item_quantity *
                                            (type === "SUPPLIER"
                                              ? elem.item_cost_price
                                                ? elem.item_cost_price
                                                : 0
                                              : elem.item_selling_price
                                              ? elem.item_selling_price
                                              : 0) *
                                            multiplier
                                          ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency:
                                              rowdata &&
                                              rowdata.invoice_currency_code
                                                ? rowdata.invoice_currency_code
                                                : usercurrency,
                                          })} */}
                                          {currencyFormater(
                                            elem.item_quantity *
                                              (type === "SUPPLIER"
                                                ? elem.item_cost_price
                                                  ? elem.item_cost_price
                                                  : 0
                                                : elem.item_selling_price
                                                ? elem.item_selling_price
                                                : 0) *
                                              multiplier,
                                            rowdata &&
                                              rowdata.invoice_currency_code
                                              ? rowdata.invoice_currency_code
                                              : usercurrency
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="m-0" />
                                  </>
                                );
                              })}
                              <div
                                className="p-3 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Subtotal
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    maxWidth: 250,
                                  }}
                                >
                                  {/* {subtotal.toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })} */}
                                  {currencyFormater(
                                    subtotal,
                                    rowdata && rowdata.invoice_currency_code
                                      ? rowdata.invoice_currency_code
                                      : usercurrency
                                  )}
                                </p>
                              </div>
                              <div
                                className="p-3 pb-0 pt-1 d-flex"
                                style={{
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    // fontWeight: "bold",
                                  }}
                                >
                                  Discount
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    // fontWeight: "bold",
                                    maxWidth: 250,
                                  }}
                                >
                                  {/* {`
                                      - ${Number(
                                        rowdata.invoice_discount_type &&
                                          rowdata.invoice_discount_type ===
                                            "absolute"
                                          ? rowdata.invoice_discount_rate
                                          : subtotal *
                                              rowdata.invoice_discount_rate
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency:
                                          rowdata &&
                                          rowdata.invoice_currency_code
                                            ? rowdata.invoice_currency_code
                                            : usercurrency,
                                      })}
                                        `} */}
                                  {` - ${currencyFormater(
                                    Number(
                                      rowdata.invoice_discount_type &&
                                        rowdata.invoice_discount_type ===
                                          "absolute"
                                        ? rowdata.invoice_discount_rate
                                        : subtotal *
                                            rowdata.invoice_discount_rate
                                    ),
                                    rowdata && rowdata.invoice_currency_code
                                      ? rowdata.invoice_currency_code
                                      : usercurrency
                                  )}`}
                                </p>
                              </div>
                              <div
                                className="p-3 pt-2 pb-1 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    // fontWeight: "bold",
                                  }}
                                >
                                  Tax
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    // fontWeight: "bold",
                                    maxWidth: 250,
                                  }}
                                >
                                  {/* {Number(
                                    rowdata.invoice_tax_type &&
                                      rowdata.invoice_tax_type === "absolute"
                                      ? rowdata.invoice_tax_rate
                                      : (rowdata.invoice_discount_type &&
                                        rowdata.invoice_discount_type ===
                                          "absolute"
                                          ? subtotal -
                                            rowdata.invoice_discount_rate
                                          : subtotal *
                                            (1 -
                                              rowdata.invoice_discount_rate)) *
                                          rowdata.invoice_tax_rate
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })} */}
                                  {currencyFormater(
                                    Number(
                                      rowdata.invoice_tax_type &&
                                        rowdata.invoice_tax_type === "absolute"
                                        ? rowdata.invoice_tax_rate
                                        : (rowdata.invoice_discount_type &&
                                          rowdata.invoice_discount_type ===
                                            "absolute"
                                            ? subtotal -
                                              rowdata.invoice_discount_rate
                                            : subtotal *
                                              (1 -
                                                rowdata.invoice_discount_rate)) *
                                            rowdata.invoice_tax_rate
                                    ),
                                    rowdata && rowdata.invoice_currency_code
                                      ? rowdata.invoice_currency_code
                                      : usercurrency
                                  )}
                                </p>
                              </div>
                              <div
                                className="p-3 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    maxWidth: 250,
                                  }}
                                >
                                  {/* {Number(
                                    sumCalculation(subtotal, rowdata)
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })} */}
                                  {currencyFormater(
                                    Number(sumCalculation(subtotal, rowdata)),
                                    rowdata && rowdata.invoice_currency_code
                                      ? rowdata.invoice_currency_code
                                      : usercurrency
                                  )}
                                </p>
                              </div>
                              <div
                                className="p-3 pt-1 pb-3 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    // fontWeight: "bold",
                                  }}
                                >
                                  Settled Amount
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    // fontWeight: "bold",
                                    maxWidth: 250,
                                  }}
                                >
                                  {/* {Number(
                                    rowdata.invoice_settled_amount
                                      ? rowdata.invoice_settled_amount
                                      : 0
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })} */}
                                  {currencyFormater(
                                    Number(
                                      rowdata.invoice_settled_amount
                                        ? rowdata.invoice_settled_amount
                                        : 0
                                    ),
                                    rowdata && rowdata.invoice_currency_code
                                      ? rowdata.invoice_currency_code
                                      : usercurrency
                                  )}
                                </p>
                              </div>
                              <hr style={{ margin: 0 }} />
                              <div
                                className="p-3 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Balance Amount
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    maxWidth: 250,
                                  }}
                                >
                                  {/* {Number(
                                    sumCalculation(subtotal, rowdata) -
                                      (isNaN(rowdata.invoice_settled_amount)
                                        ? (0).toFixed(2)
                                        : Number(
                                            rowdata.invoice_settled_amount
                                          ).toFixed(2))
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })} */}
                                  {/* truncateTextByWordCount */}
                                  {currencyFormater(
                                    Number(
                                      sumCalculation(subtotal, rowdata) -
                                        (isNaN(rowdata.invoice_settled_amount)
                                          ? (0).toFixed(2)
                                          : Number(
                                              rowdata.invoice_settled_amount
                                            ).toFixed(2))
                                    ),
                                    rowdata && rowdata.invoice_currency_code
                                      ? rowdata.invoice_currency_code
                                      : usercurrency
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <p className="p-3 m-0">
                                No Product / Service added to this{" "}
                                {workflow.type.toLowerCase()}.
                              </p>
                            </>
                          )}
                        </div>
                        {/* <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "end",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            JOB DETAILS
                          </p>
                        </div>
                        <div className="p-3">
                          <Row>
                            <Col md={6}>
                              <div className="d-flex">
                                <Edit2
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Job Title
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_title
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_title
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_title}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <Edit
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Job Description
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_description
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_description
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_description
                                      ? rowdata.invoice_description
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="d-flex">
                                <Calendar
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Date
                                  </p>
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <p
                                      style={{
                                        color:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "black"
                                            : "#b5b3b3",
                                        fontSize:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "14px"
                                            : "12px",
                                      }}
                                    >
                                      {(rowdata.invoice_visits.length !== 0 &&
                                        rowdata.invoice_visits[0]
                                          .appointment_start_time &&
                                        new Date(
                                          rowdata.invoice_visits[0].appointment_start_time
                                        ).toDateString()) ||
                                        "N/A"}
                                    </p>
                                    <>
                                      {rowdata.invoice_status ===
                                      "CONVERTED" ? (
                                        <div
                                          style={{
                                            // width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              // backgroundColor: "#1a7bb8",
                                              backgroundColor: "#3f80ea",
                                              // padding: "4px",
                                              marginLeft: "5px",
                                              color: "white",
                                              borderRadius: "5px",
                                              marginBottom: "13.2px",
                                              fontSize: "10px",
                                              fontWeight: "bold",
                                              width: 75,
                                              textAlign: "center",
                                            }}
                                          >
                                            Invoiced
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {rowdata.invoice_time_status ===
                                            "PAST" &&
                                          rowdata.invoice_visits &&
                                          rowdata.invoice_visits.length !==
                                            0 ? (
                                            <div
                                              style={{
                                                // width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#807e7f",
                                                  // padding: "4px",
                                                  marginBottom: "13.2px",
                                                  marginLeft: "5px",
                                                  color: "white",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 75,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Past
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {rowdata.invoice_time_status ===
                                                "UPCOMING" &&
                                              rowdata.invoice_visits &&
                                              rowdata.invoice_visits.length !==
                                                0 ? (
                                                <div
                                                  style={{
                                                    // width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#1AB395",
                                                      // padding: "4px",
                                                      color: "white",
                                                      marginBottom: "13.2px",
                                                      marginLeft: "5px",
                                                      borderRadius: "5px",
                                                      fontSize: "10px",
                                                      fontWeight: "bold",
                                                      width: 75,
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Upcoming
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    // width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#EC5667",
                                                      // padding: "4px",
                                                      color: "white",
                                                      marginLeft: "5px",
                                                      marginBottom: "13.2px",
                                                      borderRadius: "5px",
                                                      fontSize: "10px",
                                                      fontWeight: "bold",
                                                      width: 75,
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Unscheduled
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex">
                                <Clock
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Time
                                  </p>
                                  <div className="d-flex">
                                    <p
                                      style={{
                                        color:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "black"
                                            : "#b5b3b3",
                                        fontSize:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "14px"
                                            : "12px",
                                      }}
                                    >
                                      {(rowdata.invoice_visits.length !== 0 &&
                                        rowdata.invoice_visits[0]
                                          .appointment_start_time &&
                                        moment(
                                          new Date(
                                            rowdata.invoice_visits[0].appointment_start_time
                                          )
                                        ).format("hh:mm a")) ||
                                        "N/A"}
                                    </p>
                                    <p>&nbsp; - &nbsp;</p>
                                    <p
                                      style={{
                                        color:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_end_time
                                            ? "black"
                                            : "#b5b3b3",
                                        fontSize:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_end_time
                                            ? "14px"
                                            : "12px",
                                      }}
                                    >
                                      {(rowdata.invoice_visits.length !== 0 &&
                                        rowdata.invoice_visits[0]
                                          .appointment_end_time &&
                                        moment(
                                          new Date(
                                            rowdata.invoice_visits[0].appointment_end_time
                                          )
                                        ).format("hh:mm a")) ||
                                        "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div> */}
                      </Card>
                    </Col>
                    <Col xl={4}>
                      <Card
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: 420,
                          backgroundColor: "white",
                        }}
                        className="border rounded-0"
                      >
                        <div
                          style={{
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginRight: 15,
                            marginLeft: 15,
                          }}
                        >
                          <p
                            className="m-0"
                            style={{ fontSize: "16px", flexGrow: 1 }}
                          >{`${workflow.type.toUpperCase()} #${
                            rowdata.invoice_number
                          }`}</p>
                          {rowdata.invoice_workflow_type === "INVOICE" ? (
                            <>
                              {Number(
                                sumCalculation(subtotal, rowdata) -
                                  (isNaN(rowdata.invoice_settled_amount)
                                    ? (0).toFixed(2)
                                    : Number(
                                        rowdata.invoice_settled_amount
                                      ).toFixed(2))
                              ) > 0 ? (
                                // rowdata.invoice_payment_status === "UNPAID"
                                <>
                                  <div
                                    style={{
                                      // width: "100%",
                                      display: "flex",

                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                      // backgroundColor: "pink",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#DE5474",
                                        padding: "4px",
                                        color: "white",
                                        borderRadius: "5px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        width: 55,
                                        textAlign: "center",
                                      }}
                                    >
                                      Unpaid
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      // width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#1AB395",
                                        padding: "4px",
                                        color: "white",
                                        borderRadius: "5px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        width: 55,
                                        textAlign: "center",
                                      }}
                                    >
                                      Paid
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ) : rowdata.invoice_workflow_type === "ESTIMATE" ? (
                            <>
                              {rowdata.invoice_status === "ACCEPTED" ? (
                                <>
                                  <div
                                    style={{
                                      // width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#1AB395",
                                        padding: "4px",
                                        color: "white",
                                        borderRadius: "5px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        width: 65,
                                        textAlign: "center",
                                      }}
                                    >
                                      Accepted
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {rowdata.invoice_status === "CONVERTED" ? (
                                    <>
                                      {rowdata.invoice_children &&
                                      rowdata.invoice_children.some(
                                        (e) =>
                                          e.invoice_workflow_type === "INVOICE"
                                      ) ? (
                                        <>
                                          <div
                                            style={{
                                              // width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <div
                                              style={{
                                                // backgroundColor:
                                                //   "#1a7bb8",
                                                backgroundColor: "#3f80ea",
                                                padding: "4px",
                                                color: "white",
                                                borderRadius: "5px",
                                                fontSize: "10px",
                                                fontWeight: "bold",
                                                width: 65,
                                                textAlign: "center",
                                              }}
                                            >
                                              Invoiced
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              // width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor: "#71BE1D",
                                                padding: "4px",
                                                color: "white",
                                                borderRadius: "5px",
                                                fontSize: "10px",
                                                fontWeight: "bold",
                                                width: 65,
                                                textAlign: "center",
                                                lineHeight: 1.3,
                                              }}
                                            >
                                              {/* Converted to Job */}
                                              Booking Created
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          // width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#DE5474",
                                            padding: "4px",
                                            color: "white",
                                            borderRadius: "5px",
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                            width: 65,
                                            textAlign: "center",
                                          }}
                                        >
                                          Open
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            DATE
                          </p>
                        </div>
                        <div className="p-3 pb-0">
                          <div className="d-flex">
                            <Calendar
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                {workflow.type} Date
                              </p>
                              <div
                                className="d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  style={{
                                    color: rowdata.invoice_created_at
                                      ? "black"
                                      : "#b5b3b3",
                                    fontSize: rowdata.invoice_created_at
                                      ? "14px"
                                      : "12px",
                                  }}
                                >
                                  {(rowdata.invoice_created_at &&
                                    new Date(
                                      rowdata.invoice_created_at
                                    ).toDateString()) ||
                                    "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Calendar
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                {workflow.type === "Estimate"
                                  ? "Validity"
                                  : "Due"}{" "}
                                Date
                              </p>
                              <div className="d-flex">
                                <p
                                  style={{
                                    color: rowdata.invoice_due_date
                                      ? "black"
                                      : "#b5b3b3",
                                    fontSize: rowdata.invoice_due_date
                                      ? "14px"
                                      : "12px",
                                  }}
                                >
                                  {(rowdata.invoice_due_date &&
                                    new Date(
                                      rowdata.invoice_due_date
                                    ).toDateString()) ||
                                    "-"}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* </Col> */}
                          {/* </Row> */}
                        </div>
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            WORK
                          </p>
                        </div>
                        <div className="p-3 ">
                          {workflow.type === "Invoice" ||
                          workflow.type === "Estimate" ? (
                            <div className="d-flex">
                              <Codesandbox
                                size={16}
                                strokeWidth={2}
                                stroke="#485056"
                                style={{
                                  marginRight: 15,
                                  // position: "relative",
                                  // top: 5,
                                }}
                              />
                              <div style={{ flex: 1 }}>
                                <p className="m-0" style={{ fontSize: 11 }}>
                                  Created From
                                </p>
                                {rowdata.invoice_parent &&
                                rowdata.invoice_parent.length !== 0 ? (
                                  <>
                                    {rowdata.invoice_parent.map((elem) => (
                                      <p
                                        style={{
                                          margin: 0,
                                          padding: 2,
                                          // fontSize: 12,
                                          // marginLeft: 3,
                                          color: "#407fea",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          mixpanel.track(
                                            `Created from (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                            {
                                              id: elem.invoice_id,
                                            }
                                          );
                                          if (
                                            elem.invoice_workflow_type ===
                                              "ORDER" &&
                                            bookingPermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                          if (
                                            elem.invoice_workflow_type ===
                                              "REQUEST" &&
                                            requestPermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                          if (
                                            elem.invoice_workflow_type ===
                                              "ESTIMATE" &&
                                            estiamtePermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                          if (
                                            elem.invoice_workflow_type ===
                                              "INVOICE" &&
                                            invoicePermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                        }}
                                      >{` ${
                                        elem.invoice_workflow_type === "ORDER"
                                          ? "Booking"
                                          : capitalizeFirstLetter(
                                              elem.invoice_workflow_type.toLowerCase()
                                            )
                                      } #${elem.invoice_number} `}</p>
                                    ))}
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 2,
                                      // fontSize: 12,
                                      // marginLeft: 3,
                                      color: "#407fea",
                                      cursor: "pointer",
                                    }}
                                  >
                                    No{" "}
                                    {rowdata.invoice_workflow_type ===
                                    "ESTIMATE"
                                      ? " Request"
                                      : " Estimate"}
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {workflow.type === "Estimate" ? (
                            <div className="d-flex mt-3">
                              <Codesandbox
                                size={16}
                                strokeWidth={2}
                                stroke="#485056"
                                style={{
                                  marginRight: 15,
                                  // position: "relative",
                                  // top: 5,
                                }}
                              />
                              <div style={{ flex: 1 }}>
                                <p className="m-0" style={{ fontSize: 11 }}>
                                  Used For
                                </p>
                                {rowdata.invoice_children &&
                                rowdata.invoice_children.length !== 0 ? (
                                  <>
                                    {rowdata.invoice_children.map((elem) => (
                                      <p
                                        style={{
                                          margin: 0,
                                          padding: 2,
                                          // fontSize: 14,
                                          // marginLeft: 3,
                                          color: "#407fea",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          mixpanel.track(
                                            `Used for (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                            {
                                              id: elem.invoice_id,
                                            }
                                          );
                                          if (
                                            elem.invoice_workflow_type ===
                                              "ORDER" &&
                                            bookingPermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                          if (
                                            elem.invoice_workflow_type ===
                                              "REQUEST" &&
                                            requestPermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                          if (
                                            elem.invoice_workflow_type ===
                                              "ESTIMATE" &&
                                            estiamtePermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                          if (
                                            elem.invoice_workflow_type ===
                                              "INVOICE" &&
                                            invoicePermission
                                          ) {
                                            navigate(
                                              `/${
                                                elem.invoice_workflow_type ===
                                                "ORDER"
                                                  ? "booking"
                                                  : elem.invoice_workflow_type.toLowerCase()
                                              }s/${elem.invoice_id}`
                                            );
                                          }
                                        }}
                                      >{` ${
                                        elem.invoice_workflow_type === "ORDER"
                                          ? "Booking"
                                          : capitalizeFirstLetter(
                                              elem.invoice_workflow_type.toLowerCase()
                                            )
                                      } #${elem.invoice_number} `}</p>
                                    ))}
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 2,
                                        // fontSize: 12,
                                        // marginLeft: 3,
                                        color: "#407fea",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Not Used
                                    </p>
                                    {/* <div
                                      style={{
                                        backgroundColor: "#3f80ea",
                                        borderRadius: "5px",
                                        padding: "3px 10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "white",
                                        alignItems: "center",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        console.log("Asdf");
                                        const {
                                          invoice_children,
                                          ...restdata
                                        } = rowdata;
                                        navigate(`/invoices/add/`, {
                                          state: {
                                            ...restdata,
                                            // invoice_workflow_type: "ESTIMATE",
                                            invoice_id: id,
                                          },
                                        });
                                      }}
                                    >
                                      Convert to Invoice
                                    </div> */}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            CUSTOMER NOTES
                          </p>
                        </div>
                        <div className="p-3 pb-0">
                          <div className="d-flex">
                            <Edit
                              size={20}
                              strokeWidth={3}
                              stroke="grey"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Customer Notes
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_internal_notes
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_internal_notes
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_internal_notes
                                  ? rowdata.invoice_internal_notes
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            NOTES
                          </p>
                        </div>
                        <div className="p-3 pb-0">
                          <div className="d-flex">
                            <Clipboard
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1, width: "90%" }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Customer Notes
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_notes
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_notes
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_notes
                                  ? rowdata.invoice_notes
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Clipboard
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1, width: "90%" }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Internal Notes
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_internal_notes
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_internal_notes
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_internal_notes
                                  ? rowdata.invoice_internal_notes
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Clipboard
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1, width: "90%" }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Terms and Conditions
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_terms_and_conditions
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_terms_and_conditions
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_terms_and_conditions
                                  ? rowdata.invoice_terms_and_conditions
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>

                        {rowdata.invoice_attachments &&
                        rowdata.invoice_attachments.length !== 0 ? (
                          <>
                            <div
                              style={{
                                height: "30px",
                                width: "100%",
                                backgroundColor: "#f5f5f5",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p
                                className="ps-3 m-0"
                                style={{ fontSize: "11px", fontWeight: "bold" }}
                              >
                                PHOTOS & DOCUMENTS
                              </p>
                            </div>
                            <div className="p-3 pb-0">
                              {rowdata.invoice_attachments.map(
                                (elem, index) => {
                                  if (elem.type.includes("image")) {
                                    return (
                                      <div
                                        style={{
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                          display: "flex",
                                          borderBottom: "1px solid #edebeb",
                                          cursor: "pointer",

                                          // backgroundColor: "pink",
                                        }}
                                        onClick={() => window.open(elem.id)}
                                      >
                                        <div
                                          style={{
                                            height: 100,
                                            minWidth: 100,
                                            backgroundColor: "#f5f5f5",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // width: "100%",
                                            // flex: 1,
                                          }}
                                        >
                                          <img
                                            src={elem.id}
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            padding: "5px 20px",
                                            whiteSpace: "pre-line",
                                            width: "75%",
                                            // flexGrow: 1,
                                            // wordWrap: "break-word",
                                          }}
                                        >
                                          {elem.description
                                            ? elem.description
                                            : `Image No #${index + 1}`}
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        style={{
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                          display: "flex",
                                          borderBottom: "1px solid #edebeb",

                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(elem.id)}
                                      >
                                        <div
                                          style={{
                                            height: 100,
                                            minWidth: 100,
                                            backgroundColor: "#f5f5f5",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              border: "1px solid grey",
                                              padding: 10,
                                              paddingRight: 15,
                                              paddingLeft: 15,
                                            }}
                                          >
                                            {elem.type
                                              .split("/")[1]
                                              .toUpperCase()}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            padding: "5px 20px",
                                            whiteSpace: "pre-line",
                                            width: "75%",
                                          }}
                                        >
                                          {elem.description
                                            ? elem.description
                                            : `Document No #${index + 1}`}
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {rowdata.invoice_accept_details &&
                        Object.keys(rowdata.invoice_accept_details).length !==
                          0 ? (
                          <>
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: "#f2f2f2",
                                padding: 4,
                                paddingLeft: 15,
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                ESTIMATE ACCEPTED BY CUSTOMER
                              </span>
                            </div>
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 12,
                                  marginBottom: 12,
                                  marginLeft: 14,
                                  marginRight: 14,
                                }}
                              >
                                {rowdata.invoice_accept_details
                                  .signature_type === "text" ? (
                                  <span
                                    style={{
                                      color: "#525252",
                                    }}
                                  >
                                    {
                                      rowdata.invoice_accept_details
                                        .signature_data
                                    }
                                  </span>
                                ) : (
                                  <img
                                    src={
                                      rowdata.invoice_accept_details
                                        .signature_data
                                    } // Replace with your image URL
                                    alt="Signature"
                                    style={{
                                      width: 100,
                                      height: 50,
                                    }} // Adjust size as necessary
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Container>

              <Modal
                show={openModals}
                onHide={() => {
                  mixpanel.track(
                    `Close >>> Send Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                  );
                  setOpenModals(!openModals);
                }}
                centered
                size={workflow.type === "Booking" ? "md" : "lg"}
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Send {workflow.type}
                    </h1>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Close >>> Send Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                        );
                        setOpenModals(!openModals);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <Row>
                      <Col lg={workflow.type === "Booking" ? 12 : 6} xs={12}>
                        <Formik
                          validationSchema={schema}
                          onSubmit={(elem) => submitHandler(elem)}
                          initialValues={{
                            mail: rowdata.invoice_partner.partner_email,
                            subject: `You have new ${workflow.type} from ${
                              bdata && bdata.business_name
                                ? bdata.business_name
                                : bdata.business_user_name
                            }`,
                            sendcopytome: false,
                          }}
                          validateOnChange={false}
                          validateOnBlur={false}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                            dirty,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Recipient"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="mail"
                                  name="mail"
                                  placeholder="text"
                                  value={values.mail}
                                  onChange={handleChange}
                                  isInvalid={!!errors.mail}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.mail}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Subject"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="subject"
                                  placeholder="text"
                                  value={values.subject}
                                  onChange={handleChange}
                                  isInvalid={!!errors.subject}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.subject}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Body"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="text"
                                  placeholder="text"
                                  disabled
                                  value={`Hi ${
                                    rowdata.invoice_partner.partner_first_name
                                  } ${
                                    rowdata.invoice_partner.partner_last_name
                                  },\n\nThank you for your recent business. The ${workflow.type.toLowerCase()} total is $${sumCalculation(
                                    subtotal,
                                    rowdata
                                  ).toFixed(2)} posted on ${moment(
                                    rowdata.invoice_created_at
                                  ).format(
                                    "MMM DD, yyyy"
                                  )}.\n\nPlease see the attached ${workflow.type.toLowerCase()} or pay directly by clicking the link.\n\n${
                                    bdata && bdata.business_user_name
                                      ? bdata.business_user_name
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  as="textarea"
                                  // rows={3}
                                  style={{
                                    height: "200px",
                                    // borderColor: "#e8e8e8",
                                  }}

                                  // isInvalid={!!errors.mail}
                                />
                              </FloatingLabel>
                              <Form.Check
                                id="checkbox1"
                                label="Send copy to me"
                                name="sendcopytome"
                                style={{ fontSize: "15px" }}
                                onChange={handleChange}
                                type="checkbox"
                              />
                              <hr style={{ margin: 0 }} />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "7px 0",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    marginRight: 5,
                                    padding: "5px 10px",
                                    backgroundColor: "white",
                                    borderWidth: 1,
                                  }}
                                  onClick={() => {
                                    mixpanel.track(
                                      `Close >>> Send Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                                    );
                                    setOpenModals(!openModals);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  style={{
                                    padding: "5px 10px",
                                    backgroundColor: "#3679e8",
                                    borderWidth: 0,
                                    color: "white",
                                    width: 80,
                                  }}
                                  // onClick={() => {
                                  //   setOpenModals(!openModals);
                                  // }}
                                >
                                  Send
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </Col>
                      <Col
                        lg={6}
                        className={`d-none ${
                          workflow.type === "Booking" ? "" : "d-lg-block"
                        } p-0`}
                        style={{ overflow: "scroll" }}
                      >
                        <InvoiceTemplate data={rowdata} subtotal={subtotal} />
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={opendeleteModals}
                onHide={() => {
                  mixpanel.track(
                    `Close >>> Delete Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                  );
                  setOpendeleteModals(false);
                }}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Delete {workflow.type}
                    </h1>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Close >>> Delete Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                        );
                        setOpendeleteModals(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        // flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "15px 0 20px", textAlign: "center" }}>
                        Are you sure you want to delete{" "}
                        {rowdata.invoice_workflow_type.toLowerCase()} #
                        {rowdata.invoice_number}?
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Close >>> Delete Modal (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`
                          );
                          setOpendeleteModals(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        disabled={disabledelete}
                        onClick={() => {
                          setOpendeleteModals(false);
                          deleteHandler(workflow.type);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <Page404Authenticated />
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      )}
      <Modal
        show={markaspaiddialog}
        onHide={() => {
          setmarkaspaiddialog(false);
          setpaidtotal(rowdata.invoice_grand_total);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Set Settled Amount
            </h1>
            <div
              onClick={() => {
                setmarkaspaiddialog(false);
                setpaidtotal(rowdata.invoice_grand_total);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                flexDirection: "column",
                // alignItems: "center",
                marginBottom: 20,
              }}
            >
              <p className="mb-0 font-weight-bold">Enter Settled Amount</p>
              <Form.Control
                type="number"
                // isInvalid={errors.invoice_settled_amount}
                // required
                name="invoice_settled_amount"
                min={0}
                // placeholder="100000"
                defaultValue={paidtotal}
                onChange={(e) => setpaidtotal(e.target.value)}
              />
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "7px 0",
              }}
            >
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                  marginRight: 10,
                }}
                onClick={() => {
                  setmarkaspaiddialog(false);
                  setpaidtotal(rowdata.invoice_grand_total);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                disabled={paidbtndisabled}
                onClick={async () => {
                  try {
                    setPaidbtndisabled(true);
                    var user = firebase.auth().currentUser;
                    let data = {
                      ...rowdata,
                      invoice_id: id,
                      invoice_settled_amount: Number(paidtotal),
                    };
                    if (isTeamAccount) {
                      data.team = true;
                    } else {
                      delete data.team;
                    }
                    let tokenss = await user.getIdToken();
                    let response = await fetch(
                      "https://bestmate.us/api/invoices/update",
                      {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + tokenss,
                        },
                        body: JSON.stringify(data),
                      }
                    );
                    if (response.status >= 200 && response.status <= 300) {
                      setRowdata({
                        ...rowdata,
                        invoice_id: id,
                        // invoice_payment_status: "PAID",
                        invoice_settled_amount: Number(paidtotal),
                      });
                      setpaidtotal(rowdata.invoice_grand_total);
                      setPaidbtndisabled(false);
                      setmarkaspaiddialog(false);
                    }
                    // console.log(paidtotal);
                  } catch (err) {
                    setPaidbtndisabled(false);
                    setmarkaspaiddialog(false);
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={acceptdialog}
        onHide={() => {
          setacceptdialog(false);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Set Accept Status
            </h1>
            <div
              onClick={() => {
                setacceptdialog(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
                // marginBottom: 20,
              }}
            >
              <p
                // className="mb-0 font-weight-bold"
                style={{ margin: "15px 0 20px", textAlign: "center" }}
              >
                Click confirm to accept Estimate.
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                  marginRight: 10,
                }}
                onClick={() => {
                  setacceptdialog(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={async () => {
                  try {
                    var user = firebase.auth().currentUser;
                    let tokenss = await user.getIdToken();
                    let data = {
                      ...rowdata,
                      invoice_id: id,
                      invoice_status: "ACCEPTED",
                    };
                    if (isTeamAccount) {
                      data.team = true;
                    } else {
                      delete data.team;
                    }
                    let response = await fetch(
                      "https://bestmate.us/api/invoices/update",
                      {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + tokenss,
                        },
                        body: JSON.stringify(data),
                      }
                    );
                    if (response.status >= 200 && response.status <= 300) {
                      setRowdata({
                        ...rowdata,
                        invoice_id: id,
                        invoice_status: "ACCEPTED",
                      });
                      setacceptdialog(false);
                    }
                  } catch (err) {}
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoiceDetail;
