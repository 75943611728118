import React, { useCallback, useContext, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useEffect, useState } from "react";
import { Plus, PlusSquare, X } from "react-feather";
import empty_inventory from "../../assets/img/icons/empty_inventory.png";
import InputComponent from "./InputComponentProduct";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import expenses from "../../assets/img/icons/expenses.png";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import NewExpensemodal from "../../components/NewExpensemodal";
import Editexpensemodal from "../../components/Editexpensemodal";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";

//Filtering Table component consists the table and the search bar
function truncateTextByWordCountLine(text, wordLimit, lineLimit) {
  function getWords(str) {
    return str.split(/\s+/).filter(Boolean);
  }

  function countLines(str) {
    return str.split("\n").length;
  }

  // Handle falsy text values
  if (!text) {
    return "";
  }

  console.log(text);
  const words = getWords(text);
  console.log("Original word count:", words.length);

  let truncatedText = text;
  if (words.length > wordLimit) {
    truncatedText = words.slice(0, wordLimit).join(" ") + "...";
  }

  let lines = truncatedText.split("\n");

  if (lines.length > lineLimit) {
    truncatedText = lines.slice(0, lineLimit).join("\n") + "...";
  }

  console.log("Truncated text:", truncatedText);
  return truncatedText;
}

const FilteringTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  let usercurrency = localStorage.getItem("currency") || "USD";
  const [openModals, setOpenModals] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [deleteData, setDeletedata] = useState({});
  const [disabledelete, setDisableDelete] = useState(false);
  const { userPermission } = useAuth();
  let expensePermission =
    Object.keys(userPermission).includes("expense") &&
    Object.keys(userPermission.expense).includes("permission_key")
      ? userPermission.expense.permission_key
      : "";

  const workingdata = props.productData;
  const multiplier =
    location.state !== null
      ? Object.keys(location.state.data).length !== 0 &&
        Object.keys(location.state.data).includes("invoice_partner")
        ? Object.keys(location.state.data.invoice_partner).length !== 0 &&
          Object.keys(location.state.data.invoice_partner).includes(
            "partner_price_list"
          )
          ? Object.keys(location.state.data.invoice_partner.partner_price_list)
              .length !== 0
            ? location.state.data.invoice_partner.partner_type === "CUSTOMER" ||
              location.state.data.invoice_partner.partner_type === "LEAD"
              ? location.state.data.invoice_partner.partner_price_list
                  .price_list_multiplier
              : 1
            : 1
          : 1
        : 1
      : 1;

  useEffect(() => {
    if (openModals) {
      setDisableDelete(false);
    }
  }, [openModals]);
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  const COLUMNS = [
    {
      Header: "Date",
      accessor: (elem) =>
        `${moment(new Date(elem.expense_date)).format("DD MMM YYYY")}`,
    },
    {
      Header: () => {
        return <div style={{ textAlign: "center" }}>Category</div>;
      },
      accessor: "expense_category",
      Cell: ({ value }) => {
        if (!value) {
          return <div style={{ textAlign: "center" }}>{"-"}</div>;
        } else {
          return <div style={{ textAlign: "center" }}>{value}</div>;
        }
      },
    },
    {
      Header: "Expense Name",
      width: 350,
      accessor: "expense_name",
      Cell: ({ value, row }) => {
        console.log(
          expensePermission.includes("ALL") &&
            row.original.expense_assignee &&
            row.original.expense_assignee.team_member_name
        );
        if (
          Object.keys(row.original).includes("expense_description") &&
          row.original.expense_description !== ""
        ) {
          return (
            <div
              style={{
                wordWrap: "break-word",
                maxWidth: 350,
              }}
            >
              <div>
                {value.length > 55 ? value.substring(0, 55) + " ..." : value}
              </div>
              <div
                style={{
                  marginTop: "0px",
                  fontSize: "0.7rem",
                  color: "grey",
                  whiteSpace: "pre-line",
                  // maxHeight: "3.3rem",
                  // overflow: "hidden",
                }}
              >
                {/* {row.original.expense_description.length > 120
                  ? row.original.expense_description.substring(0, 120) + " ..."
                  : row.original.expense_description} */}
                {truncateTextByWordCountLine(
                  row.original.expense_description,
                  120,
                  3
                )}
              </div>
              {expensePermission.includes("ALL") &&
              row.original.expense_assignee &&
              row.original.expense_assignee.team_member_name ? (
                <div
                  style={{
                    fontSize: 12,
                    marginTop: 3,
                    color: "#424040",
                  }}
                >
                  Expense of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {row.original.expense_assignee &&
                    row.original.expense_assignee.team_member_name
                      ? truncateTextByWordCount(
                          row.original.expense_assignee.team_member_name,
                          50
                        )
                      : ""}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        } else {
          return (
            <>
              <div
                style={{
                  wordWrap: "break-word",
                  maxWidth: 350,
                }}
              >
                {value.length > 55 ? value.substring(0, 55) + " ..." : value}
              </div>
              {expensePermission.includes("ALL") &&
              row.original.expense_assignee &&
              row.original.expense_assignee.team_member_name ? (
                <div
                  style={{
                    fontSize: 12,
                    marginTop: 3,
                    color: "#424040",
                  }}
                >
                  Expense of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {row.original.expense_assignee &&
                    row.original.expense_assignee.team_member_name
                      ? truncateTextByWordCount(
                          row.original.expense_assignee.team_member_name,
                          50
                        )
                      : ""}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
            </>
          );
        }
      },
    },

    {
      Header: () => {
        return <div style={{ textAlign: "center" }}>Type</div>;
      },
      accessor: "expense_type",
      Cell: ({ value }) => {
        if (!value) {
          return <div style={{ textAlign: "center" }}>{"-"}</div>;
        } else {
          return <div style={{ textAlign: "center" }}>{value}</div>;
        }
      },
    },

    {
      Header: () => {
        return <div style={{ textAlign: "right" }}>Total Expense</div>;
      },
      accessor: "expense_total",
      Cell: ({ value, row }) => {
        const num = Number(value);
        if (Number.isNaN(num)) {
          return <div style={{ textAlign: "right" }}>{"-"}</div>;
        } else {
          return (
            <div style={{ textAlign: "right" }}>
              {num.toLocaleString("en-US", {
                style: "currency",
                currency: usercurrency,
              })}
            </div>
          );
        }
      },
    },
    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      width: 50,
      // width: 20,
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ButtonGroup>
              {/* <Button
                size="sm"
                variant="light"
                style={{ backgroundColor: "white" }}
                className="border"
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    location.state != null &&
                    location.state.from.includes("invoice")
                  ) {
                    const temp = location.state.data;
                    for (let i = 0; i < temp["invoice_order"].length; i++) {
                      if (
                        temp["invoice_order"][i].item_id ===
                        row.original.item_id
                      ) {
                        temp["invoice_order"][i].item_quantity =
                          temp["invoice_order"][i].item_quantity + 1;
                        navigate(
                          `/invoices/edit/` +
                            location.state.from.substring(23),
                          {
                            state: temp,
                          }
                        );
                        return;
                      }
                    }
                    temp["invoice_order"].push(row.original);
                    row.original.item_quantity = 1;

                    navigate(
                      `/invoices/edit/` +
                        location.state.from.substring(23),
                      {
                        state: temp,
                      }
                    );
                    return;
                  } else if (
                    location.state != null &&
                    location.state.from.includes("add")
                  ) {
                    const temp = location.state.data;
                    for (let i = 0; i < temp["invoice_order"].length; i++) {
                      if (
                        temp["invoice_order"][i].item_id ===
                        row.original.item_id
                      ) {
                        temp["invoice_order"][i].item_quantity =
                          temp["invoice_order"][i].item_quantity + 1;
                        navigate(`/invoice/${location.state.type}/add`, {
                          state: temp,
                        });
                        return;
                      }
                    }
                    temp["invoice_order"].push(row.original);
                    row.original.item_quantity = 1;

                    navigate(`/invoice/${location.state.type}/add`, {
                      state: temp,
                    });
                    return;
                  }

                  navigate("edit-item/" + row.original.item_id, {
                    state: { row: row.original, items: props.items },
                  });
                }}
              >
                {location.state != null && location.state.from.includes("add")
                  ? "Select"
                  : "View"}
              </Button> */}
              {location.state != null && location.state.from.includes("add") ? (
                <></>
              ) : (
                <>
                  <Button
                    size="sm"
                    variant="light"
                    style={{ backgroundColor: "white" }}
                    className="border"
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   navigate("edit-item/" + row.original.item_id, {
                    //     state: { row: row.original, items: props.items },
                    //   });
                    // }}
                    onClick={(e) => {
                      e.stopPropagation();
                      mixpanel.track(
                        "Indovidual Expense Row Edit (Expenses List Web)",
                        {
                          ...row.original,
                        }
                      );
                      props.setEditdata(row.original);
                      props.setEditmodal(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    variant="light"
                    style={{ backgroundColor: "white" }}
                    className="border"
                    onClick={(e) => {
                      e.stopPropagation();
                      mixpanel.track(
                        "Indovidual Expense Row Delete (Expenses List Web)",
                        {
                          id: row.original.expense_id,
                        }
                      );
                      setDeletedata({
                        expense_id: row.original.expense_id,
                        expense_name: row.original.expense_name,
                      });
                      setOpenModals(true);
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => workingdata);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  console.log("new object");

  return (
    <>
      <InfiniteScroll
        dataLength={rows.length}
        next={
          !props.searchtable
            ? () => props.update(props.token, props.id)
            : () =>
                props.update(
                  props.searchterm,
                  props.searchcost,
                  props.searchsell,
                  props.searchqty,
                  props.token,
                  props.id,
                  props.signal
                )
        }
        hasMore={props.hasmore}
        style={{ overflowX: "scroll", overflowY: "hidden" }}
        loader={
          <div className="d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="me-2"
            />
          </div>
        }
      >
        <Table
          striped
          bordered
          size="sm"
          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  style={{
                    paddingTop: "20px",
                    cursor: "pointer",
                  }}
                  //   onClick={() => {
                  //     if (
                  //       location.state != null &&
                  //       location.state.from.includes("invoice")
                  //     ) {
                  //       const temp = location.state.data;
                  //       for (let i = 0; i < temp["invoice_order"].length; i++) {
                  //         if (
                  //           temp["invoice_order"][i].item_id ===
                  //           row.original.item_id
                  //         ) {
                  //           temp["invoice_order"][i].item_quantity =
                  //             temp["invoice_order"][i].item_quantity + 1;
                  //           navigate(
                  //             `/invoices/edit/` +
                  //               location.state.from.substring(23),
                  //             {
                  //               state: temp,
                  //             }
                  //           );
                  //           return;
                  //         }
                  //       }
                  //       temp["invoice_order"].push(row.original);
                  //       row.original.item_quantity = 1;

                  //       navigate(
                  //         `/invoices/edit/` +
                  //           location.state.from.substring(23),
                  //         {
                  //           state: temp,
                  //         }
                  //       );
                  //       return;
                  //     } else if (
                  //       location.state != null &&
                  //       location.state.from.includes("add")
                  //     ) {
                  //       const temp = location.state.data;
                  //       for (let i = 0; i < temp["invoice_order"].length; i++) {
                  //         if (
                  //           temp["invoice_order"][i].item_id ===
                  //           row.original.item_id
                  //         ) {
                  //           temp["invoice_order"][i].item_quantity =
                  //             temp["invoice_order"][i].item_quantity + 1;
                  //           navigate(`/invoice/${location.state.type}/add`, {
                  //             state: temp,
                  //           });
                  //           return;
                  //         }
                  //       }
                  //       temp["invoice_order"].push(row.original);
                  //       row.original.item_quantity = 1;

                  //       navigate(`/invoice/${location.state.type}/add`, {
                  //         state: temp,
                  //       });
                  //       return;
                  //     }

                  //     navigate("edit-item/" + row.original.item_id, {
                  //       state: { row: row.original, items: props.items },
                  //     });
                  //   }}
                  onClick={(e) => {
                    e.stopPropagation();
                    mixpanel.track(
                      "Indovidual Expense Row Edit (Expenses List Web)",
                      {
                        ...row.original,
                      }
                    );
                    props.setEditdata(row.original);
                    props.setEditmodal(true);
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          color: "black",
                          fontSize: "12.6px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
      {workingdata.length === 0 ? (
        props.searchloading ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center pt-3 flex-column "
            id={"Emptybox"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              mixpanel.track("Add Expense (Expenses List Web)");
              props.setCreatenewmodal(true);
            }}
          >
            {!props.searchtable ? (
              <p
                className="mt-2"
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {/* No Invoice Added. Start by Clicking On Add Invoice */}
                Create an expense and start tracking your costs
              </p>
            ) : (
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                No Result Found. Click to Add Expense
              </p>
            )}
            <img
              src={expenses}
              style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
              alt="expenses"
            ></img>

            <Button
              //   onClick={() => navigate("/invoices/add")}
              onClick={() => {
                mixpanel.track("Add Expense (Expenses List Web)");
                props.setCreatenewmodal(true);
              }}
              style={{ padding: 10, fontWeight: "bold" }}
            >
              Add Expense
            </Button>
          </div>
        )
      ) : (
        <></>
      )}
      <Modal
        show={openModals}
        onHide={() => {
          mixpanel.track("Close >>> Delete Expense (Expenses List Web)");
          setOpenModals(false);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Delete Expense
            </h1>
            <div
              onClick={() => {
                mixpanel.track("Close >>> Delete Expense (Expenses List Web)");
                setOpenModals(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0px 20px", textAlign: "center" }}>
                Are you sure you want to delete this expense?
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: 5,
                  padding: "5px 10px",
                  backgroundColor: "white",
                  borderWidth: 1,
                }}
                onClick={() => {
                  mixpanel.track(
                    "Close >>> Delete Expense (Expenses List Web)"
                  );
                  setOpenModals(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                disable={disabledelete}
                onClick={() => {
                  mixpanel.track(
                    "Delete >>> Delete Expense (Expenses List Web)"
                  );
                  setOpenModals(false);
                  setDisableDelete(true);
                  props.deleteRecord(deleteData);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Expense = () => {
  const controller = new AbortController();
  const signal = controller.signal;

  const [token, settoken] = useState("");
  //loading variable takes care of the spinner
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [hasmore, sethasmore] = useState(true);
  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchterm, setSearchterm] = useState("");
  const [tempsearchterm, setTempsearchterm] = useState("");
  const [searchcost, setSearchcost] = useState("");
  const [tempsearchcost, setTempsearchcost] = useState("");
  const [searchsell, setSearchsell] = useState("");
  const [tempsearchsell, setTempsearchsell] = useState("");
  const [searchqty, setSearchqty] = useState("");
  const [tempsearchqty, setTempsearchqty] = useState("");
  const [searchhasmore, setSearchhasmore] = useState(true);
  const [searchid, setSearchid] = useState("");
  const [searchloading, setsearchloading] = useState(true);
  const [createnewmodal, setCreatenewmodal] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [editdata, setEditdata] = useState({});
  const [teamMemberList, setTeamMemberList] = useState([]);
  const { isTeamAccount, userPermission } = useAuth();
  let expensePermission =
    Object.keys(userPermission).includes("expense") &&
    Object.keys(userPermission.expense).includes("permission_key")
      ? userPermission.expense.permission_key
      : "";
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const navigate = useNavigate();

  const deleteRecord = async (deleteData) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/expenses/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log(response);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Expense deleted successfully",
              ripple: "true",
              dismissible: "true",
            });

            setLoading(true);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track("Fetch Error (Expense List Web)", {
            //   error: "expense delete api",
            // });
            logError({
              error: "fetch error",
              api: "expense delete api",
              component: `Expense List Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // mixpanel.track("Error (Expense List Web)", {
          //   error: error.message,
          //   stack: error.stack,
          // });
          logError({
            error: error.message,
            stack: error.stack,
            component: `Expense List Web`,
          });
        });
    }
    // });
  };
  const getTeamMemberList = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdToken();
      const response = await fetch(
        "https://bestmate.us/api/team/get-all-members",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let result = await response.json();
      setTeamMemberList(result);
      // setLoading(false);
    }
  };

  const getData = (token, id) => {
    fetch(
      "https://bestmate.us/api/expenses/findAllPaginated/EXPENSE/" +
        id +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData((old) => [...old, ...result]);
          setLoading(false);
          console.log("!1");
          if (result.length === 0 || result.length <= 5) {
            console.log("!12");

            sethasmore(false);
          } else if (result.length > 5) {
            console.log("!13");
            console.log(result[result.length - 1].expense_id);

            setId(result[result.length - 1].expense_id);
          }
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  //   console.log(
  //     `https://bestmate.us/api/items/search/PRODUCT${
  //       searchid ? `/${searchid}` : ""
  //     }?name=${searchterm}&cost_price=${searchcost}&selling_price=${searchsell}&quantity=${searchqty}`
  //   );
  console.log("search id =====>");
  const searchData = async (
    searchterm,
    searchcost,
    searchsell,
    searchqty,
    token,
    searchid,
    signal
  ) => {
    fetch(
      // `https://bestmate.us/api/items/search/${searchterm}/PRODUCT/` + searchid,
      `https://bestmate.us/api/expenses/search/EXPENSE${
        searchid ? `/${searchid}` : ""
      }?name=${searchterm}&category=${searchsell}&total=${searchcost}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        signal,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setSearchdata((old) => [...old, ...result]);
        setsearchloading(false);
        console.log("1");
        if (result.length === 0 || result.length <= 5) {
          setSearchhasmore(false);
          console.log("12");
        } else if (result.length > 5) {
          console.log("13");

          setSearchid(result[result.length - 1].expense_id);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    mixpanel.track(`On Expense List (Web)`);
  }, []);
  useEffect(() => {
    setsearchloading(true);
    setSearchdata([]);
    setSearchid("");
    showSearchtable(false);

    if (
      searchterm !== "" ||
      searchcost !== "" ||
      searchsell !== "" ||
      searchqty !== ""
    ) {
      console.log("calling again");
      showSearchtable(true);
      searchData(
        searchterm,
        searchcost,
        searchsell,
        searchqty,
        token,
        searchid,
        signal
      );
    }
  }, [searchterm, searchcost, searchsell, searchqty]);

  useEffect(async () => {
    if (loading) {
      //   firebase.auth().onAuthStateChanged(async (user) => {
      var user = firebase.auth().currentUser;

      if (user) {
        let tokenss = await user.getIdToken();
        settoken(tokenss);
        setData([]);
        setId("");
        sethasmore(true);
        showSearchtable(false);
        setSearchdata([]);
        setSearchterm("");
        setTempsearchterm("");
        setTempsearchsell("");
        setTempsearchcost("");
        setTempsearchqty("");
        setSearchcost("");
        setSearchsell("");
        setSearchqty("");
        setSearchhasmore(true);
        setSearchid("");
        setsearchloading(true);
        getData(tokenss, "");
        if (expensePermission.includes("ALL")) {
          getTeamMemberList();
        }
      }
      //   });
    }
  }, [loading]);
  console.log(searchid);
  console.log("search id =====>");

  const deb0 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Expense Name(Expense list Web)", {
        searchterm: value,
      });
      setSearchterm(value);
    }, 1000),
    []
  );
  const handleSearch0 = (value) => {
    deb0(value);
  };

  const deb1 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Total (Expense list Web)", {
        searchterm: value,
      });
      setSearchcost(value);
    }, 1000),
    []
  );
  const handleSearch1 = (value) => {
    deb1(value);
  };

  const deb2 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Category (Expense list Web)", {
        searchterm: value,
      });
      setSearchsell(value);
    }, 1000),
    []
  );
  const handleSearch2 = (value) => {
    deb2(value);
  };

  const deb3 = useCallback(
    debounce((value) => setSearchqty(value), 1000),
    []
  );
  const handleSearch3 = (value) => {
    deb3(value);
  };

  return (
    <React.Fragment>
      <Helmet title="Expenses" />
      <Container fluid className="p-0">
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Expenses
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Expenses
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{ marginRight: "21px" }}>
            {/* <Link to="/products/add-item"> */}
            <div
              style={{
                // backgroundColor: "#3367d6",
                backgroundColor: "#3f80ea",
                borderRadius: "5px",
                padding: "8px 15px",
                // padding: "0.375rem 0.75rem",
                display: "flex",
                justifyContent: "center",
                color: "white",
                // color: "#676a6c",
                alignItems: "center",
                textDecoration: "none",
                // fontSize: 12,
                cursor: "pointer",
              }}
              onClick={() => {
                mixpanel.track("Add Expense (Expense List Web)");
                setCreatenewmodal(true);
              }}
            >
              <Plus
                size={15}
                strokeWidth={3.5}
                stroke="white"
                style={{ marginRight: "5px" }}
              />
              Add Expense
            </div>
            {/* </Link> */}
          </div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Expense Category</Form.Label>
                          {/* <Form.Control
                            type="text"
                            value={tempsearchsell}
                            // name="email"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchsell(e.target.value);
                              handleSearch2(e.target.value);
                            }}
                            placeholder="Expense Category"
                          /> */}
                          <Form.Select
                            // type="text"
                            value={tempsearchsell}
                            // name="email"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchsell(e.target.value);
                              handleSearch2(e.target.value);
                            }}
                            placeholder="Expense Category"
                          >
                            <option value={""}>{"All"}</option>
                            <option value={"Automobile Expense"}>
                              {"Automobile Expense"}
                            </option>
                            <option value={"Employee Expense"}>
                              {"Employee Expense"}
                            </option>
                            <option value={"Parking Expense"}>
                              {"Parking Expense"}
                            </option>
                            <option value={"Equipment"}>{"Equipment"}</option>
                            <option value={"Fuel and Mileage Expense"}>
                              {"Fuel and Mileage Expense"}
                            </option>
                            <option value={"Insurance"}>{"Insurance"}</option>
                            <option value={"Labor"}>{"Labor"}</option>
                            <option value={"Materials"}>{"Materials"}</option>
                            <option value={"Meals and Entertainment"}>
                              {"Meals and Entertainment"}
                            </option>
                            <option value={"Office Supplies"}>
                              {"Office Supplies"}
                            </option>
                            <option value={"Other Expenses"}>
                              {"Other Expenses"}
                            </option>
                            <option value={"Phone Expense"}>
                              {"Phone Expense"}
                            </option>
                            <option value={"Rent"}>{"Rent"}</option>
                            <option value={"Taxes"}>{"Taxes"}</option>
                            <option value={"Travel Expense"}>
                              {"Travel Expenses"}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Expense Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={tempsearchterm}
                            placeholder="Expense Name"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchterm(e.target.value);
                              handleSearch0(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Expense Total</Form.Label>
                          <Form.Control
                            type="text"
                            value={tempsearchcost}
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchcost(e.target.value);
                              handleSearch1(e.target.value);
                            }}
                            placeholder="Expense Total"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Inventory</Form.Label>
                          <Form.Control
                            type="text"
                            value={tempsearchqty}
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid("");
                              setSearchhasmore(true);
                              setTempsearchqty(e.target.value);

                              handleSearch3(e.target.value);
                            }}
                            placeholder="Inventory"
                          />
                        </Form.Group> */}
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Card className="border rounded-0">
                <Card.Body>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <Spinner
                        animation="border"
                        variant="dark"
                        className="me-2"
                      />
                    </div>
                  ) : (
                    <>
                      {!searchtable ? (
                        <FilteringTable
                          productData={data}
                          token={token}
                          searchterm={searchterm}
                          searchtable={searchtable}
                          update={getData}
                          hasmore={hasmore}
                          id={id}
                          deleteRecord={deleteRecord}
                          setCreatenewmodal={setCreatenewmodal}
                          setEditmodal={setEditmodal}
                          setEditdata={setEditdata}
                        />
                      ) : (
                        <FilteringTable
                          productData={searchdata}
                          token={token}
                          searchterm={searchterm}
                          searchcost={searchcost}
                          searchsell={searchsell}
                          searchqty={searchqty}
                          searchtable={searchtable}
                          update={searchData}
                          hasmore={searchhasmore}
                          id={searchid}
                          searchloading={searchloading}
                          signal={signal}
                          deleteRecord={deleteRecord}
                          setCreatenewmodal={setCreatenewmodal}
                          setEditmodal={setEditmodal}
                          setEditdata={setEditdata}
                        />
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <NewExpensemodal
        type={"expense"}
        createnewmodal={createnewmodal}
        setCreatenewmodal={setCreatenewmodal}
        setFieldValue={() => {}}
        setPartners={() => {}}
        data={{}}
        setData={() => {}}
        setLoading={setLoading}
        teamMemberList={teamMemberList}

        // setCreatenewselectedplist={setCreatenewselectedplist}
      />
      <Editexpensemodal
        type={"expense"}
        createnewmodal={editmodal}
        setCreatenewmodal={setEditmodal}
        setFieldValue={() => {}}
        setPartners={() => {}}
        data={{}}
        setData={() => {}}
        setLoading={setLoading}
        editdata={editdata}
        teamMemberList={teamMemberList}
        // setCreatenewselectedplist={setCreatenewselectedplist}
      />
    </React.Fragment>
  );
};

export default Expense;
