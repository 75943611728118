import React from "react";
import { Modal } from "react-bootstrap";

import "../assets/css/App.css";

import { X } from "react-feather";

const PermissionRequireModal = (props) => {
  return (
    <>
      <Modal
        show={props.showAccessDeniedModal}
        onHide={() => {
          props.setShowAccessDeniedModal(false);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              Access Denied
            </h1>
            <div
              onClick={() => {
                props.setShowAccessDeniedModal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "24px 24px" }}>
            <div>Please ask Owner / Admin to add access to this page.</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PermissionRequireModal;
