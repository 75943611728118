import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, FloatingLabel, InputGroup, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/css/App.css";
import google from "../../assets/img/icons/google.png";
import SignUp from "../../components/auth/SignUp";
// import icon from "../../assets/img/icons/icon2.png";
import icon from "../../assets/img/illustrations/icon.png";

import useAuth from "../../hooks/useAuth";
import GoogleButton from "react-google-button";
import mixpanel from "mixpanel-browser";
import { Eye, EyeOff, X } from "react-feather";
import { logError } from "../../utils/useErrorlogging";
import { usePremium } from "../../contexts/PremiumContext";

const LoginModal = (props) => {
  const [showModal, setShowModal] = useState(props.showModal);
  // useEffect(() => {
  //   props.setShowModal(showModal);
  // }, [showModal]);
  console.log("Asdfasfdsf fds ");
  let navigate = useNavigate();
  return (
    <>
      <Modal
        show={showModal}
        // onHide={() => setShowModal(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          {/* <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
              //   marginBottom: 10,
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 25,
                color: "black",
                margin: 0,
                textAlign: "center",
                flex: 1,
              }}
            >
              {`Invitation Pending`}
            </h1>
            <div
              onClick={() => {
                // props.setCreatenewmodal(false);
                setShowModal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 15,
                  top: 15,
                  // marginBottom: 40,paddingB
                }}
              />
            </div>
          </div> */}
          <div
            style={{
              color: "black",
              textAlign: "center",
              fontSize: 28,
              fontWeight: "bold",
              marginTop: 20,
            }}
          >
            Invitation in the Mail
          </div>

          <div
            style={{ paddingTop: 0, padding: "10px 24px", textAlign: "center" }}
          >
            <div style={{ maxWidth: "100%", marginTop: 0 }}>
              We had sent you an invitation from{" "}
              <span style={{ fontWeight: "bold" }}>
                notification@bestmate.us.
              </span>{" "}
              Please check your email to get started, or reach out to us at
              support@bestmate.us.
            </div>
            <div
              style={{
                marginTop: 30,
                marginBottom: 20,
                backgroundColor: "#407FEA",
                padding: 10,
                flex: 1,
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => navigate("/sign-in")}
            >
              Back to login
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const BlockModal = (props) => {
  const [showModal, setShowModal] = useState(props.showModal);
  useEffect(() => {
    props.setShowModal(showModal);
  }, [showModal]);
  console.log("Asdfasfdsf fds ");
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
              //   marginBottom: 10,
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 25,
                color: "black",
                margin: 0,
                textAlign: "center",
                flex: 1,
              }}
            >
              {`Invitation Pending`}
            </h1>
            <div
              onClick={() => {
                // props.setCreatenewmodal(false);
                setShowModal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 15,
                  top: 15,
                  // marginBottom: 40,paddingB
                }}
              />
            </div>
          </div>

          <div style={{ padding: "10px 24px", textAlign: "center" }}>
            <div style={{ maxWidth: "100%", marginTop: 20 }}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                {props.mail}
              </span>{" "}
              has already been invited to join an existing company on Bestmate
            </div>
            <div
              style={{
                marginTop: 30,
                backgroundColor: "#407FEA",
                padding: 10,
                flex: 1,
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowModal(false);
                props.setShowLoginModal(true);
              }}
            >
              Join existing company
            </div>
            <div
              style={{ marginTop: 10, marginBottom: 20, cursor: "pointer" }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Continue creating a new company
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const SignUpPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { signUp, isAuthenticated, signInWithGoogle, signOut } = useAuth();
  const [queryEmail, setQueryEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [mail, setMail] = useState("");
  const { setMoreInfo } = usePremium();
  const location = useLocation();
  const signout = async (e) => {
    try {
      await signOut();
      setMoreInfo(null);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithGoogle();
      navigate("/dashboard");
    } catch (error) {
      console.log(error.message);
      // mixpanel.track(`Error Sign Up with Google (Sign Up Web)`, {
      //   error: error.code,
      // });
      logError({
        error: error.code,
        component: `Error Sign Up with Google (Sign Up Web)`,
      });
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    console.log(location);
    if (location.pathname === "/share") {
      signout();
    }
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    if (params.has("email")) {
      const paramValue = params.get("email");
      console.log(paramValue);

      setQueryEmail(paramValue);
    }
  }, []);
  const checkInvite = async (mail) => {
    try {
      let data = await fetch(
        `https://bestmate.us/team/pending-invite?email=${mail}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!data.ok) {
        console.log("Asdfsfd");
        // BlockModal(true);
        setMail(mail);
        setShowModal(true);

        // let res = await data.json()
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Sign Up" />

      {/* <div className="text-center mt-n5">
      <img
        className="img-fluid rounded-circle"
        src={icon}
        alt="BestMate"
        width="132"
        height="132"
      />
      <h1 className="h2">Get started</h1>
      <p className="lead">Create a new account</p>
    </div>
    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <SignUp />
        </div>
      </Card.Body>
    </Card> */}
      <div
        id="authheader"
        style={{
          width: "100%",
          // height: "80px",
          backgroundColor: "white",
          borderBottom: "1px solid #ebebeb",
          // boxShadow: "0px 45px 10px -15px #111",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            maxWidth: "820px",
            // maxWidth: "1050px",
            margin: "auto",
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              marginTop: 4,
            }}
          >
            <img
              src={icon}
              alt="BestMate"
              id="authheaderlogo"
              style={{ alignSelf: "center" }}
            />
            <strong id="authheadertext" style={{ fontWeight: "bolder" }}>
              BESTMATE
            </strong>
          </div>
          {/* <img src={icon} alt="BestMate" id="authheaderlogo" /> */}
          <a
            style={{
              color: "black",
              fontSize: "16px",
              margin: 0,
              fontWeight: "400",
            }}
            href="mailto:support@bestmate.us"
            onClick={() => mixpanel.track(`Help (Sign Up Web)`)}
          >
            Help
          </a>
        </div>
      </div>
      <div
        style={{
          // backgroundColor: "#f3f3f3",
          backgroundColor: "white",
          display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 80px)",
        }}
      >
        <div
          style={{
            // backgroundColor: "pink",
            paddingRight: 20,
            paddingLeft: 20,
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            id="signupheader"
            style={{
              color: "black",
              margin: 0,
              textAlign: "center",
              paddingTop: "5rem",
            }}
          >
            Start Free Trial for 30 days
          </h2>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              marginBottom: 30,
              textAlign: "center",
            }}
          >
            <strong
              style={{
                color: "black",
                fontSize: "13px",
              }}
            >
              &#10004; 30-day free trial
            </strong>
            <strong
              style={{
                color: "black",
                fontSize: "13px",
                marginLeft: 20,
              }}
            >
              &#10004; No credit card required
            </strong>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: 350,
              // marginBottom: 10,
            }}
          >
            <button
              style={{
                width: "100%",
                border: "1px solid #c4c4c2",
                padding: 10,
                backgroundColor: "transparent",
                fontWeight: "500",
                marginTop: 10,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                mixpanel.track(`Sign In with Google (Sign Up Web)`);
                return handleGoogleSignIn(e);
              }}
            >
              <img
                src={google}
                alt="google"
                style={{ height: 20, marginRight: 5 }}
              />
              Sign up with Google
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <hr style={{ width: "45%" }} />
              <p style={{ margin: 0 }}>OR</p>
              <hr style={{ width: "45%" }} />
            </div>
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                email: queryEmail,
                password: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                // firstName: Yup.string().max(255).required("First name is required"),
                // lastName: Yup.string().max(255).required("Last name is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .required("Email is required"),
                password: Yup.string()
                  // .min(12, "Must be at least 12 characters")
                  .required("Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await signUp(values.email, values.password);
                  if (window.innerWidth <= 768) {
                    navigate("/teams-onboard-block", {
                      state: { from: "/share" },
                    });
                  } else {
                    navigate("/dashboard");
                  }
                } catch (error) {
                  console.log(error);
                  // mixpanel.track(`Error Sign Up with Email (Sign Up Web)`, {
                  //   error: error.code,
                  // });
                  logError({
                    error: error.code,
                    component: `Error Sign Up with Email (Sign Up Web)`,
                  });
                  if (error.code === "auth/email-already-in-use") {
                    setErrors({
                      submit: "Email Already in use. Please Login",
                    });
                  } else if (error.code === "auth/user-not-found") {
                    setErrors({
                      submit: "No user found with this email. Please sign up.",
                    });
                  } else if (error.code === "auth/network-request-failed") {
                    setErrors({
                      submit:
                        "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
                    });
                  } else if (error.code === "auth/invalid-email") {
                    setErrors({
                      submit: "Invalid email address. Please try again.",
                    });
                  } else if (error.code === "auth/user-disabled") {
                    setErrors({
                      submit: "No user found with this email.",
                    });
                  } else if (error.code === "auth/wrong-password") {
                    setErrors({
                      submit: "Incorrect password. Please try again.",
                    });
                  } else if (error.code === "auth/too-many-requests") {
                    setErrors({
                      submit:
                        "Too many unsuccessful login attempts. Please try again later.",
                    });
                  } else if (error.code === "auth/operation-not-allowed") {
                    setErrors({
                      submit: "This sign-in method is not allowed.",
                    });
                  } else if (error.code === "auth/weak-password") {
                    setErrors({
                      submit: "Password should be at least 6 characters.",
                    });
                  } else {
                    setErrors({ submit: "Something went wrong" });
                  }

                  setStatus({ success: false });
                  // setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                validateForm,
              }) => (
                <Form
                  noValidate
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const obj = await validateForm();
                    if (Object.keys(obj).length !== 0) {
                      // mixpanel.track(`Error Sign Up with Email (Sign Up Web)`, {
                      //   error: obj,
                      // });
                      logError({
                        error: obj,
                        component: `Error Sign Up with Email (Sign Up Web`,
                      });
                    }
                    handleSubmit();
                  }}
                >
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  {/* <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
                  {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-3"
                  > */}
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email address"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (
                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(
                            e.target.value
                          )
                        ) {
                          // setShowModal(false);
                          checkInvite(e.target.value);
                        }
                      }}
                      style={{
                        borderColor: "black",
                        borderRadius: 5,
                        borderColor: "#c4c4c2",
                        height: 40,
                      }}
                      // defaultValue={queryEmail}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  {/* </FloatingLabel> */}
                  {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Password"
                    className="mb-3"
                  > */}
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={values.password}
                        isInvalid={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{
                          borderColor: "black",
                          borderTopLeftRadius: 5,
                          borderBottomLeftRadius: 5,
                          borderColor: "#c4c4c2",
                          height: 40,
                        }}
                        className="password_field"
                      />
                      <InputGroup.Text
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          cursor: "pointer",
                          width: 50,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff size={15} strokeWidth={2} stroke="#485056" />
                        ) : (
                          <Eye size={15} strokeWidth={2} stroke="#485056" />
                        )}
                      </InputGroup.Text>
                      {!!touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </Form.Group>
                  {/* </FloatingLabel> */}
                  {/* <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
                  <div className="text-center mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                      style={{ width: "100%", borderRadius: 5 }}
                      onClick={() =>
                        mixpanel.track(`Start Free Trial (Sign Up Web)`)
                      }
                    >
                      {/* Get Started */}
                      Start Free Trial
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <p className="mt-4 d-flex justify-content-center">
              Already have an account ? &nbsp;
              <Link
                to="/sign-in"
                onClick={() => mixpanel.track(`Sign In (Sign Up Web)`)}
              >
                Sign In
              </Link>
            </p>

            {/* <GoogleButton
            className="g-btn"
            type="dark"
            onClick={handleGoogleSignIn}
            style={{ width: "100%", height: 50, marginTop: 30 }}
          /> */}

            <p style={{ margin: 0, marginTop: 10, fontSize: 11 }}>
              By clicking Get Started you agree to Bestmate's{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://bestmate.us/terms-of-services.html",
                    "_blank"
                  );
                }}
              >
                Terms of Service
              </span>{" "}
              and{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://bestmate.us/privacy.html", "_blank");
                }}
              >
                Privacy Policy
              </span>
            </p>
          </div>
        </div>
      </div>
      {showModal ? (
        <BlockModal
          showModal={showModal}
          setShowModal={setShowModal}
          mail={mail}
          setShowLoginModal={setShowLoginModal}
        />
      ) : (
        <></>
      )}
      {showLoginModal ? (
        <LoginModal
          showModal={showLoginModal}
          setShowModal={setShowLoginModal}
          mail={mail}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default SignUpPage;
