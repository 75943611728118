import React, { useMemo, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useGlobalFilter } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useEffect, useState } from "react";
import { ArrowRight, Mail, Phone, Plus, PlusSquare, X } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
// import InputComponent from "./InputComponentPartner";
import InfiniteScroll from "react-infinite-scroll-component";
import mixpanel from "mixpanel-browser";

import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import empty_partner from "../../assets/img/icons/empty_partner.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";

const FilteringTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModals, setOpenModals] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [deleteData, setDeletedata] = useState({});
  const [nothavemodal, setNothavemodal] = useState(false);
  const [nothavetype, setNothavetype] = useState("Phone");
  const [disabledelete, setDisableDelete] = useState(false);
  const { userPermission } = useAuth();
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  const { currencyFormater } = useCurrencyFormating();

  console.log(location);

  let usercurrency = localStorage.getItem("currency") || "USD";
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  useEffect(() => {
    if (openModals) {
      setDisableDelete(false);
    }
  }, [openModals]);
  const COLUMNS = [
    {
      Header: "Lead Name",
      width: 250,
      accessor: (properties) =>
        `${properties.partner_first_name} ${properties.partner_last_name}`,
      Cell: ({ row, value }) => {
        return <span>{truncateTextByWordCount(value, 18)}</span>;
      },
    },
    {
      Header: "Company Name",
      width: 300,
      accessor: "partner_company_name",
      Cell: ({ value, row }) => {
        console.log(row);
        if (
          Object.keys(row.original).includes("partner_company_name") &&
          row.original.partner_company_name !== ""
        ) {
          return (
            <span>
              {truncateTextByWordCount(row.original.partner_company_name, 18)}
            </span>
          );
        } else {
          return "Individual";
        }
      },
    },
    {
      Header: "Email",
      accessor: "partner_email",
      width: 220,
      Cell: ({ value, row }) => {
        // if (value === "") {
        //   return "N/A";
        // } else {
        //   return value;
        // }
        if (
          Object.keys(row.original).includes("partner_email") &&
          row.original.partner_email !== ""
        ) {
          return (
            <span>
              {truncateTextByWordCount(row.original.partner_email, 20)}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: "Phone",
      width: 200,
      accessor: "partner_number",
      Cell: ({ value, row }) => {
        // console.log(Object.keys(row.original).includes("partner_number"));
        // if (Number.isNaN(value)) {
        //   return "N/A";
        // } else {
        //   return value;
        // }
        if (
          Object.keys(row.original).includes("partner_number") &&
          row.original.partner_number !== ""
        ) {
          return (
            <span>
              {truncateTextByWordCount(row.original.partner_number, 14)}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      // Header: "Total Outstanding",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Outstanding
        </div>
      ),
      width: 100,
      accessor: "partner_outstanding",
      Cell: ({ value, row }) => {
        const num = Number(value);
        console.log(num, value, row);
        if (Number.isNaN(num)) {
          return <div style={{ textAlign: "right" }}>{"-"}</div>;
        } else {
          return (
            <div style={{ textAlign: "right" }}>
              {/* {num.toLocaleString("en-US", {
                style: "currency",
                currency: usercurrency,
              })} */}
              {truncateTextByWordCount(currencyFormater(num, usercurrency), 30)}
            </div>
          );
        }
      },
    },
    {
      Header: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Contact
        </div>
      ),
      width: 200,
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              onClick={
                row.original.partner_number
                  ? (e) => {
                      e.stopPropagation();
                      mixpanel.track("Call (Lead list Web)");
                      window.location = `tel:${row.original.partner_number}`;
                    }
                  : (e) => {
                      e.stopPropagation();
                      setNothavetype("Phone");
                      setNothavemodal(true);
                    }
              }
            >
              <Phone
                size={18}
                strokeWidth={2}
                stroke="#09B29C"
                style={{ cursor: "pointer", marginRight: 25 }}
              />
            </div>
            <div
              onClick={
                row.original.partner_email
                  ? (e) => {
                      e.stopPropagation();
                      mixpanel.track("Mail (Lead list Web)");
                      window.location = `mailto:${row.original.partner_email}`;
                    }
                  : (e) => {
                      e.stopPropagation();
                      setNothavetype("Email");
                      setNothavemodal(true);
                    }
              }
            >
              <Mail
                size={18}
                strokeWidth={2}
                stroke="#3367d6"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          // <div
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //   }}
          //   onClick={(e) => {
          //     e.stopPropagation();
          //   }}
          // >
          //   <div
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       row.original.partner_number
          //         ? (window.location = `tel:${row.original.partner_number}`)
          //         : setNothavemodal(true);
          //     }}
          //     style={{
          //       backgroundColor: "#DCF1ED",
          //       padding: "7px 12px",
          //       margin: 5,
          //       borderRadius: 10,
          //     }}
          //   >
          //     <Phone
          //       size={16}
          //       strokeWidth={2}
          //       stroke="#09B29C"
          //       style={{ cursor: "pointer" }}
          //     />
          //   </div>

          //   <div
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       row.original.partner_email
          //         ? (window.location = `mailto:${row.original.partner_email}`)
          //         : setNothavemodal(true);
          //     }}
          //     style={{
          //       backgroundColor: "#FDE4DF",
          //       padding: "7px 12px",

          //       margin: 5,
          //       borderRadius: 10,
          //     }}
          //   >
          //     <Mail
          //       size={16}
          //       strokeWidth={2}
          //       stroke="#ef6547"
          //       style={{ cursor: "pointer" }}
          //     />
          //   </div>
          // </div>
        );
      },
    },
    {
      // Header: "Actions",
      Header: () => (
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        // const num = parseInt(value);
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ButtonGroup>
              <Button
                size="sm"
                variant="light"
                style={{ backgroundColor: "white" }}
                className="border"
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(location);
                  if (
                    location.state != null &&
                    location.state.from.includes("invoice")
                  ) {
                    let temp = location.state.data;
                    temp["invoice_partner"] = row.original;
                    // row.original.item_quantity = 1;
                    console.log(temp);
                    console.log("i have come from invoice page", row.original);
                    navigate(
                      `/invoices/edit/` + location.state.from.substring(23),
                      {
                        state: temp,
                      }
                    );
                    return;
                  } else if (
                    location.state != null &&
                    location.state.from.includes("add")
                  ) {
                    // console.log("i am from add invoice page");
                    // navigate("/invoices/add", {
                    //   state: {
                    //     partnerData: row.original,
                    //   },
                    // });
                    // return;
                    let temp = location.state.data;
                    temp["invoice_partner"] = row.original;
                    // row.original.item_quantity = 1;
                    console.log(temp);
                    console.log(
                      "i have come from add invoice page",
                      row.original
                    );
                    // navigate(`/invoices/add`, {
                    navigate(`/${location.state.type}/add`, {
                      state: temp,
                    });
                    return;
                  }
                  mixpanel.track("Individual Lead Row (Lead list Web)", {
                    ...row.original,
                  });
                  navigate("/lead/" + row.original.partner_id, {
                    state: { row: row.original, items: props.items },
                  });
                }}
              >
                {location.state != null && location.state.from.includes("add")
                  ? "Select"
                  : "View"}
              </Button>
              {location.state != null && location.state.from.includes("add") ? (
                <></>
              ) : (
                <>
                  <Button
                    size="sm"
                    variant="light"
                    style={{ backgroundColor: "white" }}
                    className="border"
                    onClick={(e) => {
                      e.stopPropagation();
                      mixpanel.track(
                        "Individual Lead Row Edit (Lead list Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("/lead/" + row.original.partner_id + "/edit", {
                        state: { row: row.original, items: props.items },
                      });
                    }}
                  >
                    Edit
                  </Button>
                  {partnerPermission.includes("DELETE") ? (
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        mixpanel.track(
                          "Individual Lead Row Delete (Lead list Web)",
                          {
                            id: row.original.partner_id,
                          }
                        );
                        setDeletedata({
                          partner_id: row.original.partner_id,
                          partner_first_name: row.original.partner_first_name,
                        });
                        setOpenModals(true);
                      }}
                    >
                      Delete
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  return (
    <>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <Link to="/customer/add-customer">
          <PlusSquare
            stroke="white"
            fill="#316284"
            size={35}
            // onClick={() => alert("Add new")}
          />
        </Link>
      </div> */}
      <InfiniteScroll
        dataLength={rows.length}
        next={() => props.update(props.token, props.id)}
        hasMore={props.hasmore}
        style={{ overflowX: "scroll", overflowY: "hidden" }}
        loader={
          <div className="d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="me-2"
            />
          </div>
        }
        // endMessage={
        //   <div className="d-flex justify-content-center">
        //     <b>Yay! You have seen it all</b>
        //   </div>
        //   // <p style={{ textAlign: "center" }}>
        //   // {/* </p> */}
        // }
      >
        <Table
          striped
          bordered
          size="sm"
          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            style={{ borderTop: 0, fontSize: 13 }}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => {
                    console.log(location);
                    if (
                      location.state != null &&
                      location.state.from.includes("invoice")
                    ) {
                      let temp = location.state.data;
                      temp["invoice_partner"] = row.original;
                      // row.original.item_quantity = 1;
                      console.log(temp);
                      console.log(
                        "i have come from invoice page",
                        row.original
                      );
                      navigate(
                        `/invoices/edit/` + location.state.from.substring(23),
                        {
                          state: temp,
                        }
                      );
                      return;
                    } else if (
                      location.state != null &&
                      location.state.from.includes("add")
                    ) {
                      // console.log("i am from add invoice page");
                      // navigate("/invoices/add", {
                      //   state: {
                      //     partnerData: row.original,
                      //   },
                      // });
                      // return;
                      let temp = location.state.data;
                      temp["invoice_partner"] = row.original;
                      // row.original.item_quantity = 1;
                      console.log(temp);
                      console.log(
                        "i have come from add invoice page",
                        row.original
                      );

                      // navigate(`/invoices/add`, {
                      navigate(`/${location.state.type}/add`, {
                        state: temp,
                      });
                      return;
                    }
                    mixpanel.track("Individual Lead Row (Lead list Web)", {
                      ...row.original,
                    });
                    navigate("/lead/" + row.original.partner_id, {
                      state: { row: row.original, items: props.items },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          color: "black",
                          fontSize: "12.6px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
      {props.data.length === 0 ? (
        <>
          {props.isSearch ? (
            <div
              className="d-flex justify-content-center align-items-center pt-3 flex-column "
              id={"Emptybox"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                mixpanel.track("Add Lead(Lead list Web)");
                navigate("add-lead");
              }}
            >
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                No Lead found. Click to add Lead
              </p>
              <img
                src={empty_partner}
                style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
                alt="empty_invoice"
              ></img>
              <Button
                onClick={() => {
                  mixpanel.track("Add Lead(Lead list Web)");
                  navigate("add-lead");
                }}
                style={{ padding: 10, fontWeight: "bold" }}
              >
                Add Lead
              </Button>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center pt-3 flex-column "
              id={"Emptybox"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                mixpanel.track("Add Lead(Lead list Web)");
                navigate("add-lead");
              }}
            >
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                Lets create your first Lead
              </p>
              <img
                src={empty_partner}
                style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
                alt="empty_invoice"
              ></img>
              <Button
                onClick={() => {
                  mixpanel.track("Add Lead(Lead list Web)");
                  navigate("add-lead");
                }}
                style={{ padding: 10, fontWeight: "bold" }}
              >
                Add Lead
              </Button>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      {/* <Modal
        show={openModals}
        onHide={() => setOpenModals(!openModals)}
        centered
      >
        <Modal.Header className="font-weight-bold" closeButton>
          Delete Customer
        </Modal.Header>
        <Modal.Body className="text-left m-3">
          <p className="mb-0 font-weight-bold">
            Are you sure you want to delete {deleteData["partner_first_name"]}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenModals(!openModals)}
          >
            Cancel
          </Button>{" "}
          <Button
            variant="danger"
            onClick={() => {
              setOpenModals(!openModals);
              props.deleteRecord(deleteData);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={openModals}
        onHide={() => setOpenModals(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Delete Lead
            </h1>
            <div
              onClick={() => {
                setOpenModals(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0px 20px", textAlign: "center" }}>
                Are you sure you want to delete this lead?
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: 5,
                  padding: "5px 10px",
                  backgroundColor: "white",
                  borderWidth: 1,
                }}
                onClick={() => setOpenModals(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                disable={disabledelete}
                onClick={() => {
                  setOpenModals(false);
                  setDisableDelete(true);
                  props.deleteRecord(deleteData);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={nothavemodal}
        onHide={() => setNothavemodal(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Not Available
            </h1>
            <div
              onClick={() => {
                setNothavemodal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                {nothavetype === "Phone"
                  ? " No phone number has been added. Add a new phone number from the lead page."
                  : " No Email has been added. Add a new email address from the lead page."}
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setNothavemodal(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Lead = () => {
  const [token, settoken] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchname, setSearchname] = useState("");

  const [searchcont, setSearchcont] = useState("");

  const [searchout, setSearchout] = useState("");
  const { isTeamAccount } = useAuth();
  const [id, setId] = useState("");
  const [hasmore, sethasmore] = useState(true);
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  const deleteRecord = async (deleteData) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/partners/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("response", response);
            // navigate("/customer");
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Lead deleted successfully",
              ripple: "true",
              dismissible: "true",
            });
            setLoading(true);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track("Fetch Error (Lead List Web)", {
            //   error: "partner delete api",
            // });
            logError({
              error: "fetch error",
              api: "partner delete api",
              component: `Lead List Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // mixpanel.track("Error (Lead List Web)", {
          // error: error.message,
          // stack: error.stack,
          // });
          logError({
            error: error.message,
            stack: error.stack,
            component: `Lead List Web`,
          });
        });
    }
    // });
    // setShow(false);
  };

  // console.log("render");

  const getData = (token) => {
    // fetch("https://bestmate.us/api/partners/findAllPaginated/CUSTOMER/" + id, {

    fetch(
      "https://bestmate.us/api/partners/findAll" +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result.filter((elem) => elem.partner_type === "LEAD"));
          // setData((old) => [...old, ...result]);
          setLoading(false);
          // if (result.length === 0) {
          sethasmore(false);
          // } else if (result.length > 0) {
          //   setId(result[result.length - 1].item_id);
          // }
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  // const deleteRecord = (deleteData) => {
  //   fetch("https://bestmate.us/api/partners/delete", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //     body: JSON.stringify(deleteData),
  //   })
  //     .then((response) => {
  //       console.log("response", response);
  //       // alert("Item Deleted");
  //       getData();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   // setShow(false);
  // };

  function searchnow(searchname, searchcont, searchout) {
    // console.log(searchname, searchcont, searchout);
    let result = data;
    if (searchname !== "") {
      result = result.filter(
        (elem) =>
          (elem.partner_first_name + " " + elem.partner_last_name)
            .toLowerCase()
            .includes(searchname.toLowerCase())
        // elem.partner_first_name.toLowerCase().includes(searchname.toLowerCase())
      );
    }

    if (searchcont !== "") {
      result = result.filter((elem) => {
        if ("partner_number" in elem && elem.partner_numer !== "") {
          console.log(elem.partner_number, searchcont);
          return elem.partner_number.includes(searchcont);
        }
      });
    }

    if (searchout !== "") {
      result = result.filter((elem) => {
        console.log(String(elem.partner_outstanding), searchout);
        // if ("partner_grand_total" in elem && elem.partner_grand_total !== "") {
        return String(elem.partner_outstanding).includes(searchout);
        // }
      });
    }

    setSearchdata(result);
  }
  useEffect(() => {
    mixpanel.track(`On Lead List (Web)`);
  }, []);
  useEffect(() => {
    // setsearchloading(true);
    // setSearchdata([]);
    // setSearchid("");
    showSearchtable(false);

    if (searchname !== "" || searchcont !== "" || searchout !== "") {
      console.log("calling again");
      showSearchtable(true);
      console.log(
        "ASDFASDFASDFSADFSADF}{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{}"
      );

      searchnow(searchname, searchcont, searchout);
    }

    // return controller.abort();
  }, [searchname, searchcont, searchout]);

  useEffect(async () => {
    if (loading) {
      var user = firebase.auth().currentUser;

      // firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let tokenss = await user.getIdToken();
        settoken(tokenss);
        setData([]);
        setId("");
        sethasmore(true);
        showSearchtable(false);
        setSearchdata([]);
        setSearchname("");
        // setTempsearchname("");
        // setTempsearchcont("");
        // setTempsearchout("");
        // setTempsearchout("");
        setSearchcont("");
        setSearchout("");
        // setSearchqty("");
        // setSearchhasmore(true);
        // setSearchid("");
        // setsearchloading(true);
        getData(tokenss);
      }
      // });
    }
  }, [loading]);

  console.log(data, searchdata);

  return (
    <React.Fragment>
      <Helmet title="Lead" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Customer</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Leads
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Partners</Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Leads
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{ marginRight: "21px" }}>
            <Link
              to="/lead/add-lead"
              onClick={() => mixpanel.track("Add Lead(Lead list Web)")}
              style={{ textDecoration: "none" }}
            >
              {/* <PlusSquare stroke="white" fill="#316284" size={35} /> */}
              <div
                style={{
                  // backgroundColor: "#3367d6",
                  backgroundColor: "#3f80ea",
                  borderRadius: "5px",
                  padding: "8px 15px",
                  // padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  // color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  // fontSize: 12,
                  cursor: "pointer",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="white"
                  style={{ marginRight: "5px" }}
                />
                Add Lead
              </div>
            </Link>
          </div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label> Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchname}
                            onChange={(e) => {
                              mixpanel.track("Search Name (Lead List Web)", {
                                searchterm: e.target.value,
                              });
                              setSearchname(e.target.value);
                            }}
                            placeholder=" Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Phone </Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchcont}
                            onChange={(e) => {
                              mixpanel.track("Search Phone (Lead List Web)", {
                                searchterm: e.target.value,
                              });
                              setSearchcont(e.target.value);
                            }}
                            placeholder="Phone "
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label> Outstanding</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchout}
                            onChange={(e) => {
                              mixpanel.track(
                                "Search Outstanding (Lead List Web)",
                                { searchterm: e.target.value }
                              );
                              setSearchout(e.target.value);
                            }}
                            placeholder="Outstanding"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Status"
                          />
                        </Form.Group> */}
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Row>
                <Col lg="12">
                  <Card className="mb-2 border rounded-0">
                    <Card.Header>
                      {/* {urlparamsdata ? (
                  <InputComponent
                    urlparamsdata={urlparamsdata}
                    rowData={rowData}
                    // show={setShow}
                    token={token}
                    delete={deleteRecord}
                    getData={getData}
                    setSearchParams={setSearchParams}
                  />
                ) : (
                  <> */}
                      {loading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <Spinner
                            animation="border"
                            variant="dark"
                            className="me-2"
                          />
                        </div>
                      ) : (
                        <>
                          {!searchtable ? (
                            <FilteringTable
                              data={data}
                              // show={setShow}
                              token={token}
                              update={getData}
                              hasmore={hasmore}
                              id={id}
                              deleteRecord={deleteRecord}
                              isSearch={false}
                            />
                          ) : (
                            <FilteringTable
                              data={searchdata}
                              // show={setShow}
                              token={token}
                              update={getData}
                              hasmore={hasmore}
                              id={id}
                              deleteRecord={deleteRecord}
                              isSearch={true}
                            />
                          )}
                        </>
                      )}
                      {/* </>
                )} */}
                    </Card.Header>
                    {/* <Card.Body>&nbsp;</Card.Body> */}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
    // <>
    //   <div>ed</div>
    // </>
  );
};

export default Lead;
