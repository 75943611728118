import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import * as Yup from "yup";
// import "../assets/css/App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import mixpanel from "mixpanel-browser";
import useAuth from "../hooks/useAuth";

const Newpartnermodal = (props) => {
  const [billingadd, setbillingadd] = useState({
    street: "",
    zip_code: "",
    country: "",
    state: "",
    city: "",
  });
  const [shippingadd, setshippingadd] = useState({
    street: "",
    zip_code: "",
    country: "",
    state: "",
    city: "",
  });
  const [sameasbilling, setSameasbilling] = useState(true);
  const [pricelistoptions, setPricelistoptions] = useState([]);
  const [savemodalloading, setSavemodalloading] = useState(true);
  const [savemodalopen, setsavemodalopen] = useState("first");
  const [newselectedplist, setCreatenewselectedplist] = useState("");
  const [disablesave, setDisablesave] = useState(false);
  const [globalValidationError, setGlobalValidationError] = useState(false);
  const { isTeamAccount } = useAuth();
  const inputRef = useRef();
  const inputRef2 = useRef();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
    libraries: ["places"],
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setbillingadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };
  const handlePlaceChanged2 = () => {
    const [place] = inputRef2.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setshippingadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const fetchPriceList = async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/pricelist/findAll", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            let data = result.map((elem) => {
              return {
                label: `${elem.price_list_name}: ${elem.price_list_multiplier}x`,
                value: {
                  price_list_name: elem.price_list_name,
                  price_list_multiplier: elem.price_list_multiplier,
                  price_list_id: elem.price_list_id,
                  ...elem,
                },
              };
            });
            console.log(data);
            setPricelistoptions(data);
            setSavemodalloading(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    }
    // });
  };

  const submitData = async (data, setFieldValue) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    mixpanel.track(`Create ${props.type} (Add ${props.type} Modal Web)`, {
      ...data,
    });
    if (user) {
      let tokenss = await user.getIdToken();

      console.log(data);
      if (isTeamAccount) {
        data.team = true;
      } else {
        delete data.team;
      }

      fetch("https://bestmate.us/api/partners/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          const result = await res.json();
          console.log(result);
          console.log(
            result[0].partner_first_name + " " + result[0].partner_last_name
          );
          props.setFieldValue(
            "appointment_partner",
            result[0].partner_first_name + " " + result[0].partner_last_name
          );
          props.setPartners(result[0]);
          props.setData({
            ...props.data,
            invoice_partner: result[0],
          });
          setbillingadd({});
          setshippingadd({});
          setSameasbilling(true);
          setsavemodalopen("first");
          props.setCreatenewmodal(false);
          setDisablesave(false);
          setGlobalValidationError(false);
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
        });
    }
    // });
  };

  const submitHandler = (elem, setFieldValue) => {
    // elem.preventDefault();
    setDisablesave(true);

    const data = {
      partner_first_name: elem.firstName,
      partner_last_name: elem.lastName,
      partner_company_name: elem.companyName,
      // partner_email: elem.email,
      // partner_number: elem.phoneNumber,
      // partner_price_list: newselectedplist
      //   ? pricelistoptions.filter(
      //       (elems) => elems.value.price_list_id === newselectedplist
      //     )[0].value
      //   : {},
      // partner_billing_address: {
      //   street: billingadd.street,
      //   city: billingadd.city,
      //   state: billingadd.state,
      //   zip_code: billingadd.zip_code,
      //   country: billingadd.country,
      // },
      // partner_shipping_address: {
      //   street: sameasbilling ? billingadd.street : shippingadd.street,
      //   city: sameasbilling ? billingadd.city : shippingadd.city,
      //   state: sameasbilling ? billingadd.state : shippingadd.state,
      //   zip_code: sameasbilling ? billingadd.zip_code : shippingadd.zip_code,
      //   country: sameasbilling ? billingadd.country : shippingadd.country,
      // },
      partner_billing_address: {},
      partner_shipping_address: {},
      // partner_payment_terms: elem.terms,
      // partner_notes: elem.notes,
      // partner_tax_number: elem.tax,
      partner_type:
        props.type === "customer"
          ? "CUSTOMER"
          : props.type === "lead"
          ? "LEAD"
          : "SUPPLIER",
    };
    if (
      newselectedplist &&
      Object.keys(
        pricelistoptions.filter(
          (elems) => elems.value.price_list_id === newselectedplist
        )[0].value !== 0
      )
    ) {
      data.partner_price_list = pricelistoptions.filter(
        (elems) => elems.value.price_list_id === newselectedplist
      )[0].value;
    }
    if (Object.keys(billingadd).includes("street") && billingadd.street) {
      data.partner_billing_address.street = billingadd.street;
    }
    if (Object.keys(billingadd).includes("city") && billingadd.city) {
      data.partner_billing_address.city = billingadd.city;
    }
    if (Object.keys(billingadd).includes("state") && billingadd.state) {
      data.partner_billing_address.state = billingadd.state;
    }
    if (Object.keys(billingadd).includes("zip_code") && billingadd.zip_code) {
      data.partner_billing_address.zip_code = billingadd.zip_code;
    }
    if (Object.keys(billingadd).includes("country") && billingadd.country) {
      data.partner_billing_address.country = billingadd.country;
    }
    if (sameasbilling) {
      if (Object.keys(billingadd).includes("street") && billingadd.street) {
        data.partner_shipping_address.street = billingadd.street;
      }
      if (Object.keys(billingadd).includes("city") && billingadd.city) {
        data.partner_shipping_address.city = billingadd.city;
      }
      if (Object.keys(billingadd).includes("state") && billingadd.state) {
        data.partner_shipping_address.state = billingadd.state;
      }
      if (Object.keys(billingadd).includes("zip_code") && billingadd.zip_code) {
        data.partner_shipping_address.zip_code = billingadd.zip_code;
      }
      if (Object.keys(billingadd).includes("country") && billingadd.country) {
        data.partner_shipping_address.country = billingadd.country;
      }
    } else {
      if (Object.keys(shippingadd).includes("street") && shippingadd.street) {
        data.partner_shipping_address.street = shippingadd.street;
      }
      if (Object.keys(shippingadd).includes("city") && shippingadd.city) {
        data.partner_shipping_address.city = shippingadd.city;
      }
      if (Object.keys(shippingadd).includes("state") && shippingadd.state) {
        data.partner_shipping_address.state = shippingadd.state;
      }
      if (
        Object.keys(shippingadd).includes("zip_code") &&
        shippingadd.zip_code
      ) {
        data.partner_shipping_address.zip_code = shippingadd.zip_code;
      }
      if (Object.keys(shippingadd).includes("country") && shippingadd.country) {
        data.partner_shipping_address.country = shippingadd.country;
      }
    }
    if (elem.email) {
      data.partner_email = elem.email;
    }
    if (elem.phoneNumber) {
      data.partner_number = elem.phoneNumber;
    }
    if (elem.terms) {
      data.partner_payment_terms = elem.terms;
    }
    if (elem.notes) {
      data.partner_notes = elem.notes;
    }
    if (elem.tax) {
      data.partner_tax_number = elem.tax;
    }
    console.log(data);
    submitData(data, setFieldValue);
  };

  useEffect(() => {
    // fetchPriceList();
    setSavemodalloading(false);
  }, []);

  return (
    <>
      <Modal
        show={props.createnewmodal}
        onHide={() => {
          setbillingadd({});
          setshippingadd({});
          setSameasbilling(true);
          setsavemodalopen("first");
          mixpanel.track(`Close (Add ${props.type} Modal Web)`);
          props.setCreatenewmodal(false);
          setGlobalValidationError(false);
        }}
        centered
        size="md"
        // className="createnewpartner"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
              marginBottom: 10,
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              {`Create new ${props.type}`}
            </h1>
            <div
              onClick={() => {
                setbillingadd({});
                setshippingadd({});
                setSameasbilling(true);
                setsavemodalopen("first");
                mixpanel.track(`Close (Add ${props.type} Modal Web)`);
                props.setCreatenewmodal(false);
                setGlobalValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {savemodalloading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "10px 24px" }}>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string()
                    .required("First Name is required")
                    .max(100, "First name must be less than 100 characters"),
                  lastName: Yup.string()
                    .required("Last Name is required")
                    .max(100, "Last name must be less than 100 characters"),
                  phoneNumber: Yup.string().matches(
                    /^[0-9+\-\s]+$/,
                    "Phone number is not valid"
                  ),
                  email: Yup.string().email("Email is not valid"),
                })}
                onSubmit={(elem) => submitHandler(elem, props.setFieldValue)}
                initialValues={{
                  firstName: "",
                  lastName: "",
                  companyName: "",
                  email: "",
                  phoneNumber: "",
                  bstreet: "",
                  bcity: "",
                  bstate: "",
                  bzip: "",
                  bcountry: "",
                  sstreet: "",
                  scity: "",
                  sstate: "",
                  szip: "",
                  scountry: "",
                  terms: "",
                  notes: "",
                  tax: "",
                  // pricelist: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  validateForm,
                }) => (
                  <Form
                    noValidate
                    onSubmit={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const obj = await validateForm();
                      console.log(obj);
                      if (Object.keys(obj).length !== 0) {
                        setGlobalValidationError(true);
                      } else {
                        setGlobalValidationError(false);
                        handleSubmit();
                      }
                    }}
                  >
                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        // backgroundColor: "pink",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("first")}
                    >
                      <div>{`${
                        props.type.charAt(0).toUpperCase() + props.type.slice(1)
                      } Details`}</div>
                      {savemodalopen === "first" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "first" ? (
                      <>
                        <div style={{ margin: "5px 0" }}>
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="firstName"
                                  placeholder="Joe"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.firstName}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  onKeyDown={handleKeyDown}
                                  name="lastName"
                                  placeholder="Kumar"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.lastName}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Company Name"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="companyName"
                              onKeyDown={handleKeyDown}
                              placeholder="Company Name"
                              value={values.companyName}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="email"
                                  name="email"
                                  onKeyDown={handleKeyDown}
                                  placeholder="example@mtree.com"
                                  value={values.email}
                                  onChange={handleChange}
                                  isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.email}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Phone Number"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="tel"
                                  name="phoneNumber"
                                  onKeyDown={handleKeyDown}
                                  placeholder="912346789"
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.phoneNumber}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <hr style={{ margin: 0 }} />
                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        // marginBottom: 10,
                        padding: "10px 0",

                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("second")}
                    >
                      <div>Billing Address</div>
                      {savemodalopen === "second" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "second" ? (
                      <>
                        <div style={{ margin: "5px 0" }}>
                          {isLoaded ? (
                            <StandaloneSearchBox
                              onLoad={(ref) => (inputRef.current = ref)}
                              onPlacesChanged={handlePlaceChanged}
                            >
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Street"
                                className="mb-3"
                              >
                                <Form.Control
                                  // ref={ref}
                                  type="text"
                                  // name="bstreet"
                                  onKeyDown={handleKeyDown}
                                  placeholder="1234 Main St"
                                  // value={values.bstreet}
                                  // onChange={handleChange}
                                  value={billingadd.street}
                                  onChange={(e) =>
                                    setbillingadd({
                                      ...billingadd,
                                      street: e.target.value,
                                    })
                                  }
                                  // onSelect={(d)=>{console.l}}
                                />
                              </FloatingLabel>
                            </StandaloneSearchBox>
                          ) : (
                            <></>
                          )}
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="City"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  // name="bcity"
                                  placeholder="St"
                                  onKeyDown={handleKeyDown}
                                  // value={values.bcity}
                                  // onChange={handleChange}
                                  value={billingadd.city}
                                  onChange={(e) =>
                                    setbillingadd({
                                      ...billingadd,
                                      city: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="State"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  // name="bstate"
                                  placeholder="California"
                                  onKeyDown={handleKeyDown}
                                  // value={values.bstate}
                                  // onChange={handleChange}
                                  value={billingadd.state}
                                  onChange={(e) =>
                                    setbillingadd({
                                      ...billingadd,
                                      state: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Zip Code"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  // name="bzip"
                                  placeholder="115632"
                                  onKeyDown={handleKeyDown}
                                  // value={values.bzip}
                                  // onChange={handleChange}
                                  value={billingadd.zip_code}
                                  onChange={(e) =>
                                    setbillingadd({
                                      ...billingadd,
                                      zip_code: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Country"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  // name="bcountry"
                                  placeholder="USA"
                                  onKeyDown={handleKeyDown}
                                  // value={values.bcountry}
                                  // onChange={handleChange}
                                  value={billingadd.country}
                                  onChange={(e) =>
                                    setbillingadd({
                                      ...billingadd,
                                      country: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Form.Check
                            id="checkbox1"
                            label="Service Address same as Billing Address"
                            // value={sameasbilling}
                            checked={sameasbilling}
                            // name="appointment_send_confirmation"
                            // style={{ fontSize: "15px" }}
                            onChange={() => setSameasbilling(!sameasbilling)}
                            type="checkbox"
                          />
                          {sameasbilling ? (
                            <></>
                          ) : (
                            <>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                              >
                                Service Address
                              </div>
                              {isLoaded ? (
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef2.current = ref)}
                                  onPlacesChanged={handlePlaceChanged2}
                                >
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Street"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      // ref={ref2}
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      name="sstreet"
                                      onKeyDown={handleKeyDown}
                                      placeholder="1234 Main St"
                                      // value={
                                      //   sameasbilling ? values.bstreet : values.sstreet
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.street
                                          : shippingadd.street
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          street: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </StandaloneSearchBox>
                              ) : (
                                <></>
                              )}
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="City"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      name="scity"
                                      onKeyDown={handleKeyDown}
                                      placeholder="1234 Main St"
                                      // value={
                                      //   sameasbilling ? values.bcity : values.scity
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.city
                                          : shippingadd.city
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          city: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="State"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      name="sstate"
                                      onKeyDown={handleKeyDown}
                                      placeholder="California"
                                      // value={
                                      //   sameasbilling ? values.bstate : values.sstate
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.state
                                          : shippingadd.state
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          state: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Zip Code"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      name="szip"
                                      onKeyDown={handleKeyDown}
                                      placeholder="115632"
                                      // value={sameasbilling ? values.bzip : values.szip}
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.zip_code
                                          : shippingadd.zip_code
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          zip_code: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Country"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      name="scountry"
                                      onKeyDown={handleKeyDown}
                                      placeholder="USA"
                                      // value={
                                      //   sameasbilling
                                      //     ? values.bcountry
                                      //     : values.scountry
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.country
                                          : shippingadd.country
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          country: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <hr style={{ margin: 0 }} />

                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("third")}
                    >
                      <div>Other Details</div>
                      {savemodalopen === "third" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "third" ? (
                      <>
                        {" "}
                        <div style={{ margin: "5px 0" }}>
                          <Row className="g-2">
                            {/* <Col md>
                              <FloatingLabel
                                controlId="floatingSelect"
                                label="Price List"
                                className="mb-3"
                              >
                                <Form.Select
                                  // as="select"
                                  // name="pricelist"
                                  // value={pricelist}
                                  onChange={(x) =>
                                    setCreatenewselectedplist(x.target.value)
                                  }
                                  aria-label="Floating label select example"
                                >
                                  <option value={""}>Select Price List</option>
                                  {pricelistoptions.map((elem) => {
                                    return (
                                      <option value={elem.value.price_list_id}>
                                        {elem.label}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </FloatingLabel>
                            </Col> */}
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Payment Terms"
                                className="mb-3"
                              >
                                <Form.Select
                                  aria-label="Floating label select example"
                                  name="terms"
                                  onKeyDown={handleKeyDown}
                                  onChange={handleChange}
                                >
                                  <option value={""}>
                                    Select Payment Terms
                                  </option>
                                  <option value={"Advance"}>{"Advance"}</option>
                                  <option value={"5 Days"}>{"5 Days"}</option>
                                  <option value={"7 Days"}>{"7 Days"}</option>
                                  <option value={"10 Days"}>{"10 Days"}</option>
                                  <option value={"20 Days"}>{"20 Days"}</option>
                                  <option value={"30 Days"}>{"30 Days"}</option>
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Notes"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              as="textarea"
                              name="notes"
                              placeholder="note"
                              value={values.notes}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Tax Number"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="tax"
                              placeholder="123456789"
                              value={values.tax}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <hr style={{ margin: 0 }} />
                    {globalValidationError ? (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          alignItems: "center",
                        }}
                      >
                        <AlertCircle
                          size={15}
                          strokeWidth={2}
                          stroke="#d8534e"
                        />
                        <div
                          style={{
                            color: "#d8534e",
                            fontSize: 11,
                            fontWeight: "400",
                            marginLeft: 5,
                          }}
                        >
                          There was an error saving the form. Please check the
                          messages above and try again.
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 20,
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          setbillingadd({});
                          setshippingadd({});
                          setSameasbilling(true);
                          setsavemodalopen("first");
                          mixpanel.track(`Close (Add ${props.type} Modal Web)`);
                          props.setCreatenewmodal(false);
                          setGlobalValidationError(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                        }}
                        type="submit"
                        disabled={disablesave}
                      >
                        {`Create ${
                          props.type.charAt(0).toUpperCase() +
                          props.type.slice(1)
                        }`}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Newpartnermodal;
