import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

function SignUp() {
  const navigate = useNavigate();
  const { signUp, isAuthenticated } = useAuth();

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          // firstName: Yup.string().max(255).required("First name is required"),
          // lastName: Yup.string().max(255).required("Last name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .required("Email is required"),
          password: Yup.string()
            // .min(12, "Must be at least 12 characters")
            .required("Required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signUp(values.email, values.password);
            // if (isAuthenticated) {
            // console.log("aSFDSF");
            navigate("/dashboard");
            // }
          } catch (error) {
            console.log(error);
            // const message =
            //   error.code === "auth/email-already-exists"
            //     ? "Mail Already Registered"
            //     : error.message || "Something went wrong";
            if (error.code === "auth/email-already-in-use") {
              setErrors({
                submit: "Email Already in use.",
              });
            } else {
              setErrors({ submit: "Something went wrong" });
            }

            setStatus({ success: false });
            // setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email address"
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Sign up
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <p className="mt-3 d-flex justify-content-center">
        Back to&nbsp;<Link to="/">Sign In</Link>
      </p>
    </>
  );
}

export default SignUp;
