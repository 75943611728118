import React, { useEffect, useState, useContext, useRef } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

// import Autocomplete from "react-google-autocomplete";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";

import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useSidebar from "../../hooks/useSidebar";
import { MapPin, Plus, Save, Sliders, User, X } from "react-feather";
// import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .max(100, "First name must be less than 100 characters"),
  lastName: Yup.string()
    .required("Last Name is required")
    .max(100, "Last name must be less than 100 characters"),
  phoneNumber: Yup.string().matches(
    /^[0-9+\-\s]+$/,
    "Phone number is not valid"
  ),
  email: Yup.string().email("Email is not valid"),
});
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const FormRow = (props) => {
  const [token, settoken] = useState("");
  const notyf = useContext(NotyfContext);
  let big = props.props.type.toUpperCase();
  let small = props.props.type.toLowerCase();
  const navigate = useNavigate();
  const [pricelist, setPricelist] = useState("");
  const [options, setOptions] = useState([]);
  const { isOpen } = useSidebar();
  const { isTeamAccount } = useAuth();
  const [billingadd, setbillingadd] = useState({
    street: "",
    zip_code: "",
    country: "",
    state: "",
    city: "",
  });
  const [shippingadd, setshippingadd] = useState({
    street: "",
    zip_code: "",
    country: "",
    state: "",
    city: "",
  });
  const [sameasbilling, setSameasbilling] = useState(false);
  const [disablesave, setDisablesave] = useState(false);
  const [validationError, setValidationError] = useState(false);

  // const { ref } = usePlacesWidget({
  //   apiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
  //   onPlaceSelected: (details) => {
  //     console.log(details);
  //     let country = details.address_components.filter((elem) =>
  //       elem.types.includes("country")
  //     );
  //     let state = details.address_components.filter((elem) =>
  //       elem.types.includes("administrative_area_level_1")
  //     );
  //     let city = details.address_components.filter((elem) =>
  //       elem.types.includes("locality")
  //     );
  //     let street = details.address_components.filter(
  //       (elem) =>
  //         elem.types.includes("street_number") ||
  //         elem.types.includes("route") ||
  //         elem.types.includes("sublocality")
  //     );
  //     let zip = details.address_components.filter((elem) =>
  //       elem.types.includes("postal_code")
  //     );
  //     setbillingadd({
  //       country: country.length !== 0 ? country[0].long_name : "",
  //       state: state.length !== 0 ? state[0].long_name : "",
  //       city: city.length !== 0 ? city[0].long_name : "",
  //       zip_code: zip.length !== 0 ? zip[0].long_name : "",
  //       street:
  //         street.length !== 0
  //           ? street.map((elem) => elem.long_name).join(" ")
  //           : "",
  //     });
  //   },
  // });
  // const { ref: ref2 } = usePlacesWidget({
  //   apiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
  //   onPlaceSelected: (details) => {
  //     console.log(details);
  //     let country = details.address_components.filter((elem) =>
  //       elem.types.includes("country")
  //     );
  //     let state = details.address_components.filter((elem) =>
  //       elem.types.includes("administrative_area_level_1")
  //     );
  //     let city = details.address_components.filter((elem) =>
  //       elem.types.includes("locality")
  //     );
  //     let street = details.address_components.filter(
  //       (elem) =>
  //         elem.types.includes("street_number") ||
  //         elem.types.includes("route") ||
  //         elem.types.includes("sublocality")
  //     );
  //     let zip = details.address_components.filter((elem) =>
  //       elem.types.includes("postal_code")
  //     );
  //     setshippingadd({
  //       country: country.length !== 0 ? country[0].long_name : "",
  //       state: state.length !== 0 ? state[0].long_name : "",
  //       city: city.length !== 0 ? city[0].long_name : "",
  //       zip_code: zip.length !== 0 ? zip[0].long_name : "",
  //       street:
  //         street.length !== 0
  //           ? street.map((elem) => elem.long_name).join(" ")
  //           : "",
  //     });

  //     // setbillingadd({
  //     //   ...billingadd,
  //     //   street: place.formatted_address,
  //     // });
  //   },
  // });

  const inputRef = useRef();
  const inputRef2 = useRef();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
    libraries: ["places"],
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setbillingadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };
  const handlePlaceChanged2 = () => {
    const [place] = inputRef2.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setshippingadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };
  // const autoCompleteRef = useRef();
  // const ref = useRef();
  // const [address, setaddress] = useState("");

  // const handleChange2 = (address) => {
  //   setaddress(address);
  // };

  // const handleSelect2 = (address) => {
  //   geocodeByAddress(address)
  //     .then((results) => getLatLng(results[0]))
  //     .then((latLng) => console.log("Success", latLng))
  //     .catch((error) => console.error("Error", error));
  // };
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const fetchPriceList = (token) => {
    fetch("https://bestmate.us/api/pricelist/findAll", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let data = result.map((elem) => {
            return {
              label: `${elem.price_list_name}: ${elem.price_list_multiplier}x`,
              value: {
                price_list_name: elem.price_list_name,
                price_list_multiplier: elem.price_list_multiplier,
                price_list_id: elem.price_list_id,
                ...elem,
              },
            };
          });
          console.log(data);
          setOptions(data);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  const submitData = async (data) => {
    console.log(data);
    var user = firebase.auth().currentUser;
    mixpanel.track(`Save (Add ${props.props.type} Web)`, {
      ...data,
    });

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        data.team = true;
      } else {
        delete data.team;
      }
      fetch("https://bestmate.us/api/partners/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            navigate(`/${small}`);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `${props.props.type} created successfully`,
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Add ${props.props.type} Web)`, {
            //   error: "partner add api",
            // });
            logError({
              error: "fetch error",
              api: "partner add api",
              component: `Add ${props.props.type} Web`,
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
          // mixpanel.track(`Error (Add ${props.props.type} Web)`, {
          //   error: err,
          // });
          logError({
            error: err,
            component: `${props.props.type} Web`,
          });
        });
    }
  };

  const submitHandler = (elem) => {
    setDisablesave(true);
    // elem.preventDefault();
    const data = {
      partner_first_name: capitalizeFirstLetter(elem.firstName),
      partner_last_name: capitalizeFirstLetter(elem.lastName),
      partner_company_name: elem.companyName,
      // partner_email: elem.email,
      // partner_number: elem.phoneNumber,
      // partner_price_list: pricelist
      //   ? options.filter((elems) => elems.value.price_list_id === pricelist)[0]
      //       .value
      //   : {},
      // partner_billing_address: {
      //   street: billingadd.street,
      //   city: billingadd.city,
      //   state: billingadd.state,
      //   zip_code: billingadd.zip_code,
      //   country: billingadd.country,
      // },
      // partner_shipping_address: {
      //   street: sameasbilling ? billingadd.street : shippingadd.street,
      //   city: sameasbilling ? billingadd.city : shippingadd.city,
      //   state: sameasbilling ? billingadd.state : shippingadd.state,
      //   zip_code: sameasbilling ? billingadd.zip_code : shippingadd.zip_code,
      //   country: sameasbilling ? billingadd.country : shippingadd.country,
      // },
      partner_billing_address: {},
      partner_shipping_address: {},
      // partner_payment_terms: elem.terms,
      // partner_notes: elem.notes,
      // partner_tax_number: elem.tax,
      partner_type: big,
    };
    if (
      pricelist &&
      Object.keys(
        options.filter((elems) => elems.value.price_list_id === pricelist)[0]
          .value !== 0
      )
    ) {
      data.partner_price_list = options.filter(
        (elems) => elems.value.price_list_id === pricelist
      )[0].value;
    }
    if (Object.keys(billingadd).includes("street") && billingadd.street) {
      data.partner_billing_address.street = billingadd.street;
    }
    if (Object.keys(billingadd).includes("city") && billingadd.city) {
      data.partner_billing_address.city = billingadd.city;
    }
    if (Object.keys(billingadd).includes("state") && billingadd.state) {
      data.partner_billing_address.state = billingadd.state;
    }
    if (Object.keys(billingadd).includes("zip_code") && billingadd.zip_code) {
      data.partner_billing_address.zip_code = billingadd.zip_code;
    }
    if (Object.keys(billingadd).includes("country") && billingadd.country) {
      data.partner_billing_address.country = billingadd.country;
    }
    if (sameasbilling) {
      if (Object.keys(billingadd).includes("street") && billingadd.street) {
        data.partner_shipping_address.street = billingadd.street;
      }
      if (Object.keys(billingadd).includes("city") && billingadd.city) {
        data.partner_shipping_address.city = billingadd.city;
      }
      if (Object.keys(billingadd).includes("state") && billingadd.state) {
        data.partner_shipping_address.state = billingadd.state;
      }
      if (Object.keys(billingadd).includes("zip_code") && billingadd.zip_code) {
        data.partner_shipping_address.zip_code = billingadd.zip_code;
      }
      if (Object.keys(billingadd).includes("country") && billingadd.country) {
        data.partner_shipping_address.country = billingadd.country;
      }
    } else {
      if (Object.keys(shippingadd).includes("street") && shippingadd.street) {
        data.partner_shipping_address.street = shippingadd.street;
      }
      if (Object.keys(shippingadd).includes("city") && shippingadd.city) {
        data.partner_shipping_address.city = shippingadd.city;
      }
      if (Object.keys(shippingadd).includes("state") && shippingadd.state) {
        data.partner_shipping_address.state = shippingadd.state;
      }
      if (
        Object.keys(shippingadd).includes("zip_code") &&
        shippingadd.zip_code
      ) {
        data.partner_shipping_address.zip_code = shippingadd.zip_code;
      }
      if (Object.keys(shippingadd).includes("country") && shippingadd.country) {
        data.partner_shipping_address.country = shippingadd.country;
      }
    }
    if (elem.email) {
      data.partner_email = elem.email;
    }
    if (elem.phoneNumber) {
      data.partner_number = elem.phoneNumber;
    }
    if (elem.terms) {
      data.partner_payment_terms = elem.terms;
    }
    if (elem.notes) {
      data.partner_notes = elem.notes;
    }
    if (elem.tax) {
      data.partner_tax_number = elem.tax;
    }
    console.log(data);
    submitData(data);
  };

  const cancelHandler = () => {
    navigate(`/${small}`);
  };
  useEffect(async () => {
    mixpanel.track(`On Add ${props.props.type} (Web)`);
    // autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    //   ref.current,
    //   {}
    // );
    // autoCompleteRef.current.addListener("place_changed", async function () {
    //   const place = await autoCompleteRef.current.getPlace();
    //   console.log({ place });
    // });
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // fetchPriceList(tokenss);
      props.setsubmithandler(() => submitHandler);
    }
    // });
  }, []);
  console.log(isLoaded, loadError);
  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(elem) => submitHandler(elem)}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          phoneNumber: "",
          bstreet: "",
          bcity: "",
          bstate: "",
          bzip: "",
          bcountry: "",
          sstreet: "",
          scity: "",
          sstate: "",
          szip: "",
          scountry: "",
          terms: "",
          notes: "",
          tax: "",
          // pricelist: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          validateForm,
        }) => (
          <Form
            noValidate
            onSubmit={async (e) => {
              e.preventDefault();
              const obj = await validateForm();
              if (Object.keys(obj).length !== 0) {
                setValidationError(true);
              } else {
                handleSubmit();
              }
            }}
            onKeyDown={(keyEvent) => {
              // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
              //   keyEvent.preventDefault();
              // }
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "white",
                padding: "0rem 1.5rem 2rem 1.5rem",
              }}
              className="pt-2 pb-2 border-bottom "
            >
              <div>
                <h2
                  className="mt-3 lead"
                  style={{
                    fontSize: "24px",
                    // , fontWeight: 100
                  }}
                >
                  New {props.props.type}
                </h2>
                <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                  <Breadcrumb.Item
                    active
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Partners</Breadcrumb.Item>
                  <Breadcrumb.Item
                    active
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/${props.props.type}`)}
                  >
                    {props.props.type}s
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active className="font-weight-bold">
                    New {props.props.type}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "21px",
                }}
              >
                {/* <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="#676a6c"
                  style={{ marginRight: "2px" }}
                />
                Btn 1
              </div>
            </Link>
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="#676a6c"
                  style={{ marginRight: "2px" }}
                />
                Btn 2
              </div>
            </Link> */}
                {/* <button
                  onClick={() => {
                    // submithandler();
                  }}
                  type="submit"
                > */}
                <button
                  onClick={() => {
                    // submithandler();
                  }}
                  type="submit"
                  style={{
                    border: "1px #E7EAEC solid",
                    borderRadius: "3px",
                    padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "#3f80ea",
                    // backgroundColor: "#4f83cb",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: "inherit",
                    cursor: "pointer",
                    width: "100px",
                  }}
                  disabled={disablesave}
                >
                  <Save
                    size={15}
                    strokeWidth={2}
                    stroke="white"
                    style={{ marginRight: "8px" }}
                  />
                  <div>Save</div>
                </button>
                {/* </div> */}
              </div>
            </Card>
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              <Row>
                <Col lg="6">
                  {/* <Card className="mb-2 border rounded-0">
                  <Card.Body>
                    <Card.Title className="mb-0">{`Partner Profile`}</Card.Title>
                    <Table size="sm" className="my-2">
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <td>
                            <Form.Control
                              type="text"
                              isInvalid={errors.firstName}
                              name="first_name"
                              placeholder="First Name"
                              value={values.firstName}
                              onChange={handleChange}
                              style={{ marginBottom: "10px" }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                            <Form.Control
                              type="text"
                              isInvalid={errors.lastName}
                              name="last_name"
                              placeholder="Last Name"
                              value={values.lastName}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </td>
                        </tr>
                        <tr>
                          <th>Company</th>
                          <td>
                            <Form.Control
                              value={""}
                              placeholder={"Company Name"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Email</th>

                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Phone</th>

                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Phone Number"
                              name="number"
                              value={values.number}
                              onChange={handleChange}
                              // isInvalid={!!errors.number}
                            />
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </Card.Body>
                </Card> */}
                  <Card className="mb-2 border rounded-0">
                    <Card.Header
                      className="pb-0"
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <User
                        size={18}
                        strokeWidth={3}
                        stroke="#485056"
                        style={{ marginRight: 15 }}
                      />
                      <Card.Title
                        className="mb-0"
                        style={{ fontSize: 18 }}
                      >{`${props.props.type} Profile`}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="First Name"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="firstName"
                              onKeyDown={handleKeyDown}
                              placeholder="Joe"
                              value={values.firstName}
                              onChange={handleChange}
                              isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Last Name"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="lastName"
                              onKeyDown={handleKeyDown}
                              placeholder="Kumar"
                              value={values.lastName}
                              onChange={handleChange}
                              isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Company Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="companyName"
                          onKeyDown={handleKeyDown}
                          placeholder="Company Name"
                          value={values.companyName}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="email"
                              onKeyDown={handleKeyDown}
                              placeholder="example@mtree.com"
                              value={values.email}
                              onChange={handleChange}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Phone Number"
                            className="mb-3"
                          >
                            <Form.Control
                              type="tel"
                              name="phoneNumber"
                              onKeyDown={handleKeyDown}
                              placeholder="912346789"
                              value={values.phoneNumber}
                              onChange={handleChange}
                              isInvalid={!!errors.phoneNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.phoneNumber}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Card.Body>
                    {/* </Card> */}
                    <hr style={{ margin: "0 20px" }} />
                    {/* <Card className="mb-2 border rounded-0"> */}
                    <Card.Header className="pb-0" style={{ display: "flex" }}>
                      <Sliders
                        size={18}
                        strokeWidth={3}
                        stroke="#485056"
                        style={{ marginRight: 15 }}
                      />
                      <Card.Title className="mb-0" style={{ fontSize: 18 }}>
                        Other Details
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {/* <Form.Label>Price List</Form.Label>
                    <Select
                      className="react-select-container mb-3"
                      classNamePrefix="react-select"
                      options={options}
                      onChange={(e) => setPricelist(e.value)}
                    /> */}
                      <Row className="g-2">
                        {/* <Col md>
                          <FloatingLabel
                            controlId="floatingSelect"
                            label="Price List"
                            className="mb-3"
                          >
                            <Form.Select
                              // as="select"
                              // name="pricelist"
                              // value={pricelist}
                              onKeyDown={handleKeyDown}
                              onChange={(x) => setPricelist(x.target.value)}
                              aria-label="Floating label select example"
                            >
                              <option value={""}>Select Price List</option>
                              {options.map((elem) => {
                                return (
                                  <option value={elem.value.price_list_id}>
                                    {elem.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </FloatingLabel>
                        </Col> */}
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Payment Terms"
                            className="mb-3"
                          >
                            <Form.Select
                              aria-label="Floating label select example"
                              name="terms"
                              onKeyDown={handleKeyDown}
                              onChange={handleChange}
                            >
                              <option value={""}>Select Payment Terms</option>
                              <option value={"Advance"}>{"Advance"}</option>
                              <option value={"5 Days"}>{"5 Days"}</option>
                              <option value={"7 Days"}>{"7 Days"}</option>
                              <option value={"10 Days"}>{"10 Days"}</option>
                              <option value={"20 Days"}>{"20 Days"}</option>
                              <option value={"30 Days"}>{"30 Days"}</option>
                            </Form.Select>
                            {/* <Form.Control
                              type="text"
                              name="terms"
                              placeholder="Terms"
                              value={values.terms}
                              onChange={handleChange}
                            /> */}
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Notes"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="notes"
                          as="textarea"
                          // rows="3"
                          // rows={1}
                          placeholder="note"
                          value={values.notes}
                          onChange={handleChange}
                          // style={{ overflow: "hidden", resize: "none" }}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Tax Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="tax"
                          onKeyDown={handleKeyDown}
                          placeholder="123456789"
                          value={values.tax}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6">
                  {/* <Card className="mb-2 border rounded-0">
                  <Card.Header className="pb-0">
                    <Card.Title className="mb-0">Partner Profile</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="First Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="Joe"
                        value={values.firstName}
                        onChange={handleChange}
                        isInvalid={!!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Last Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Kumar"
                        value={values.lastName}
                        onChange={handleChange}
                        isInvalid={!!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="example@mtree.com"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Phone Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="tel"
                        name="phoneNumber"
                        placeholder="912346789"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        // isInvalid={!!errors.phoneNumber}
                      />

                    </FloatingLabel>
                  </Card.Body>
                </Card> */}
                  <Card className="mb-2 border rounded-0">
                    <Card.Header className="pb-0" style={{ display: "flex" }}>
                      <MapPin
                        size={18}
                        strokeWidth={3}
                        stroke="#485056"
                        style={{ marginRight: 15 }}
                      />
                      <Card.Title className="mb-0" style={{ fontSize: 18 }}>
                        Billing Address
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {/* <Autocomplete
                      apiKey={`AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE`}
                      onPlaceSelected={(place) => {
                        console.log(place);
                      }}
                    /> */}
                      {/* <PlacesAutocomplete
                      value={address}
                      onChange={handleChange2}
                      onSelect={handleSelect2}
                    > */}
                      {/* custom render function */}
                      {/* <LoadScript
                        googleMapsApiKey={`AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE`}
                        libraries={["places"]}
                      > */}
                      {isLoaded ? (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                        >
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Street"
                            className="mb-3"
                          >
                            <Form.Control
                              // ref={ref}
                              type="text"
                              // name="bstreet"
                              placeholder="1234 Main St"
                              onKeyDown={handleKeyDown}
                              // value={values.bstreet}
                              // onChange={handleChange}
                              value={billingadd.street}
                              onChange={(e) =>
                                setbillingadd({
                                  ...billingadd,
                                  street: e.target.value,
                                })
                              }
                              // onSelect={(d)=>{console.l}}
                            />
                          </FloatingLabel>
                        </StandaloneSearchBox>
                      ) : (
                        <></>
                      )}

                      {/* </LoadScript> */}
                      {/* {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete> */}
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="City"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              // name="bcity"
                              placeholder="St"
                              onKeyDown={handleKeyDown}
                              // value={values.bcity}
                              // onChange={handleChange}
                              value={billingadd.city}
                              onChange={(e) =>
                                setbillingadd({
                                  ...billingadd,
                                  city: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="State"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              // name="bstate"
                              placeholder="California"
                              onKeyDown={handleKeyDown}
                              // value={values.bstate}
                              // onChange={handleChange}
                              value={billingadd.state}
                              onChange={(e) =>
                                setbillingadd({
                                  ...billingadd,
                                  state: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Zip Code"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              // name="bzip"
                              placeholder="115632"
                              onKeyDown={handleKeyDown}
                              // value={values.bzip}
                              // onChange={handleChange}
                              value={billingadd.zip_code}
                              onChange={(e) =>
                                setbillingadd({
                                  ...billingadd,
                                  zip_code: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Country"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              // name="bcountry"
                              placeholder="USA"
                              onKeyDown={handleKeyDown}
                              // value={values.bcountry}
                              // onChange={handleChange}
                              value={billingadd.country}
                              onChange={(e) =>
                                setbillingadd({
                                  ...billingadd,
                                  country: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Card.Body>
                    {/* </Card> */}
                    <hr style={{ margin: "0 20px" }} />
                    {/* <Card className="mb-2 border rounded-0"> */}
                    <Card.Header
                      className="pb-0"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <MapPin
                          size={18}
                          strokeWidth={3}
                          stroke="#485056"
                          style={{ marginRight: 15 }}
                        />
                        <Card.Title className="mb-0" style={{ fontSize: 18 }}>
                          Service Address
                        </Card.Title>
                      </div>

                      <Form.Check
                        id="checkbox1"
                        label="Same as Billing Address"
                        // value={sameasbilling}
                        checked={sameasbilling}
                        // name="appointment_send_confirmation"
                        // style={{ fontSize: "15px" }}
                        onChange={() => {
                          mixpanel.track(
                            `Same as Billing (Add ${props.props.type} Web)`
                          );
                          setSameasbilling(!sameasbilling);
                        }}
                        type="checkbox"
                      />
                    </Card.Header>
                    <Card.Body>
                      {isLoaded ? (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef2.current = ref)}
                          onPlacesChanged={handlePlaceChanged2}
                        >
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Street"
                            className="mb-3"
                          >
                            <Form.Control
                              // ref={ref2}
                              disabled={sameasbilling ? true : false}
                              type="text"
                              name="sstreet"
                              placeholder="1234 Main St"
                              // value={
                              //   sameasbilling ? values.bstreet : values.sstreet
                              // }
                              // onChange={handleChange}
                              value={
                                sameasbilling
                                  ? billingadd.street
                                  : shippingadd.street
                              }
                              onChange={(e) =>
                                setshippingadd({
                                  ...shippingadd,
                                  street: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </StandaloneSearchBox>
                      ) : (
                        <></>
                      )}
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="City"
                            className="mb-3"
                          >
                            <Form.Control
                              disabled={sameasbilling ? true : false}
                              type="text"
                              name="scity"
                              onKeyDown={handleKeyDown}
                              placeholder="1234 Main St"
                              // value={
                              //   sameasbilling ? values.bcity : values.scity
                              // }
                              // onChange={handleChange}
                              value={
                                sameasbilling
                                  ? billingadd.city
                                  : shippingadd.city
                              }
                              onChange={(e) =>
                                setshippingadd({
                                  ...shippingadd,
                                  city: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="State"
                            className="mb-3"
                          >
                            <Form.Control
                              disabled={sameasbilling ? true : false}
                              type="text"
                              name="sstate"
                              onKeyDown={handleKeyDown}
                              placeholder="California"
                              // value={
                              //   sameasbilling ? values.bstate : values.sstate
                              // }
                              // onChange={handleChange}
                              value={
                                sameasbilling
                                  ? billingadd.state
                                  : shippingadd.state
                              }
                              onChange={(e) =>
                                setshippingadd({
                                  ...shippingadd,
                                  state: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row className="g-2">
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Zip Code"
                            className="mb-3"
                          >
                            <Form.Control
                              disabled={sameasbilling ? true : false}
                              type="text"
                              name="szip"
                              onKeyDown={handleKeyDown}
                              placeholder="115632"
                              // value={sameasbilling ? values.bzip : values.szip}
                              // onChange={handleChange}
                              value={
                                sameasbilling
                                  ? billingadd.zip_code
                                  : shippingadd.zip_code
                              }
                              onChange={(e) =>
                                setshippingadd({
                                  ...shippingadd,
                                  zip_code: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Country"
                            className="mb-3"
                          >
                            <Form.Control
                              disabled={sameasbilling ? true : false}
                              type="text"
                              name="scountry"
                              onKeyDown={handleKeyDown}
                              placeholder="USA"
                              // value={
                              //   sameasbilling
                              //     ? values.bcountry
                              //     : values.scountry
                              // }
                              // onChange={handleChange}
                              value={
                                sameasbilling
                                  ? billingadd.country
                                  : shippingadd.country
                              }
                              onChange={(e) =>
                                setshippingadd({
                                  ...shippingadd,
                                  country: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  {/* <Card className="mb-2 border rounded-0">
                  <Card.Header className="pb-0">
                    <Card.Title className="mb-0">Other Details</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Label>Price List</Form.Label>
                    <Select
                      className="react-select-container mb-3"
                      classNamePrefix="react-select"
                      options={options}
                      onChange={(e) => setPricelist(e.value)}
                    />

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Payment Terms"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="terms"
                        placeholder="Terms"
                        value={values.terms}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notes"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="notes"
                        placeholder="note"
                        value={values.notes}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Tax Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="tax"
                        placeholder="123456789"
                        value={values.tax}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Card.Body>
                </Card> */}
                </Col>
              </Row>
              <div
                className="d-flex mt-4"
                style={{ justifyContent: "space-between" }}
              >
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => {
                    mixpanel.track(`Cancel (Add ${props.props.type} Web)`);
                    cancelHandler();
                  }}
                  className="mb-1 center"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  // onClick={submitHandler}
                  type="submit"
                  size="lg"
                  className="mb-1 center"
                  disabled={disablesave}

                  // disabled
                >
                  {/* Create {props.props.type} */}
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        show={validationError}
        onHide={() => setValidationError(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Error
            </h1>
            <div
              onClick={() => {
                setValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                There was an error saving the form. Please check the messages in
                the form and try again.
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setValidationError(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* </div> */}
    </>
  );
};

const Partnerform = (props) => {
  const navigate = useNavigate();
  const [submithandler, setsubmithandler] = useState();

  return (
    <React.Fragment>
      <Helmet title={`Add ${props.type}`} />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Add New {props.type}</h1> */}

        <FormRow props={props} setsubmithandler={setsubmithandler} />
      </Container>
    </React.Fragment>
  );
};

export default Partnerform;
