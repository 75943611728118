import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import "../assets/css/App.css";

import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import * as Yup from "yup";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Selectmodal from "./Selectmodal";
import Newpartnermodal from "./Newpatnermodal";
import NotyfContext from "../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import useSidebar from "../hooks/useSidebar";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import useAuth from "../hooks/useAuth";
import { logError } from "../utils/useErrorlogging";

const Newtimesheetmodal = (props) => {
  //   const [data, setData] = useState(null);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [note, setnote] = useState("");
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  const [globalValidationError, setGlobalValidationError] = useState(false);
  const [btndisable, setbtndisable] = useState(false);
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const { isTeamAccount, userPermission, userData } = useAuth();
  let timesheetPermission =
    Object.keys(userPermission).includes("timesheet") &&
    Object.keys(userPermission.timesheet).includes("permission_key")
      ? userPermission.timesheet.permission_key
      : "";
  const [teamMember, setTeamMember] = useState(
    timesheetPermission.includes("ALL") ? {} : userData
  );
  console.log(
    userData,
    teamMember,
    timesheetPermission.includes("ALL") ? {} : userData
  );
  const createTimesheet = async (data) => {
    setbtndisable(true);
    var user = firebase.auth().currentUser;
    var date = new Date(props.selecteddate).toDateString();
    var startdate = date.concat(" ", data["timesheet_start_time"]);
    // var endate = new Date(props.selected["timesheet_end_time"]).toDateString();
    var enddate = date.concat(" ", data["timesheet_end_time"]);
    console.log(date, startdate, enddate);
    // console.log({
    //   ...props.selected,
    //   timesheet_name: data.timesheet_name,
    //   timesheet_start_time: new Date(startdate).getTime(),
    //   timesheet_end_time: new Date(enddate).getTime(),
    // });
    let finalObj = {
      timesheet_start_time: new Date(startdate).getTime(),
      timesheet_end_time: new Date(enddate).getTime(),
    };
    if (data.timesheet_name) {
      finalObj.timesheet_name = data.timesheet_name;
    }
    mixpanel.track("Update Timesheet (Timesheet Add Modal Web)");
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        finalObj.team = true;
      } else {
        delete finalObj.team;
      }
      console.log(teamMember);
      if (teamMember && Object.keys(teamMember).length !== 0) {
        finalObj.timesheet_assignee_uid = teamMember.team_member_uid;
        finalObj.timesheet_assignee_ref_id =
          teamMember.team_member_reference_id;
        finalObj.timesheet_assignee = teamMember;
      }
      console.log(finalObj);
      fetch("https://bestmate.us/api/timesheet/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(finalObj),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("response", response);

            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Timesheet created successfully",
              ripple: "true",
              dismissible: "true",
            });
            // props.setLoading(true);
            props.fetchData();
            props.setCreatenewmodal(false);
            // props.setSelected(null);
            setGlobalValidationError(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setbtndisable(false);
            // mixpanel.track(`Fetch Error (Timesheet Add Modal Web)`, {
            //   error: "timesheet save api",
            // });
            logError({
              error: "fetch error",
              api: "timesheet add api",
              component: "Timesheet Add Modal Web",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setbtndisable(false);
          // mixpanel.track(`Error (Timesheet Add Modal Web)`, {
          //   error: error.message,
          //   stack: error.stack,
          // });
          logError({
            error: error.message,
            stack: error.stack,
            component: "Timesheet Add Modal Web",
          });
        });
    }
  };
  useEffect(() => {
    if (props.createnewmodal) {
      setbtndisable(false);
      setTeamMember(timesheetPermission.includes("ALL") ? {} : userData);
    } else {
      setTeamMember({});
    }
  }, [props.createnewmodal]);

  return (
    <>
      <Modal
        show={props.createnewmodal}
        onHide={() => {
          props.setCreatenewmodal(false);
          setGlobalValidationError(false);
        }}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
              }}
            >
              {`Add Timesheet`}
            </h1>
            <div
              onClick={() => {
                mixpanel.track("Close Modal (Timesheet Add Modal Web)");
                props.setCreatenewmodal(false);
                setGlobalValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {false ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "10px 24px" }}>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  timesheet_start_time: Yup.string().required(
                    "Start Time is required"
                  ),
                  timesheet_end_time: Yup.string()
                    .required("End Time is required")
                    .test(
                      "timesheet_end_time",
                      "End time must be later than the start time",
                      function (value) {
                        const { timesheet_start_time } = this.parent;
                        return value > timesheet_start_time;
                      }
                    ),
                })}
                onSubmit={(values) => {
                  createTimesheet(values);
                }}
                initialValues={{
                  timesheet_name: "",
                  timesheet_start_time: "",
                  timesheet_end_time: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  handleReset,
                  setFieldValue,
                  validateForm,
                }) => (
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const obj = await validateForm();
                      console.log(obj);
                      if (Object.keys(obj).length !== 0) {
                        setGlobalValidationError(true);
                      } else {
                        setGlobalValidationError(false);
                        handleSubmit();
                      }
                    }}
                    // onKeyDown={(keyEvent) => {
                    //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    //   }
                    // }}
                  >
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}
                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        // backgroundColor: "pink",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    ></div>

                    <div style={{ margin: "5px 0" }}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Timesheet Note"
                        className="mb-3"
                        onClick={() => {}}
                      >
                        <Form.Control
                          //   disabled={true}
                          type="text"
                          as="textarea"
                          name="timesheet_name"
                          placeholder="text"
                          value={values.timesheet_name}
                          //   isInvalid={errors.timesheet_name}
                          style={{
                            height: "100px",
                            // backgroundColor: "white",
                            // cursor: "pointer",
                          }}
                          onChange={handleChange}
                        ></Form.Control>
                      </FloatingLabel>
                      <Row>
                        <Col>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Start Time"
                            className="mb-3"
                          >
                            <Form.Control
                              type="time"
                              onKeyDown={handleKeyDown}
                              className="calendar-input"
                              isInvalid={errors.timesheet_start_time}
                              // required
                              name="timesheet_start_time"
                              placeholder="text"
                              value={values.timesheet_start_time}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.timesheet_start_time}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="End Time"
                            className="mb-3"
                          >
                            <Form.Control
                              type="time"
                              onKeyDown={handleKeyDown}
                              className="calendar-input"
                              isInvalid={errors.timesheet_end_time}
                              // required
                              name="timesheet_end_time"
                              placeholder="text"
                              value={values.timesheet_end_time}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.timesheet_end_time}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      </Row>
                      {timesheetPermission.includes("ALL") ? (
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Team Member"
                          className="mb-3"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="category"
                            onKeyDown={handleKeyDown}
                            onChange={(event) => {
                              const selectedId = event.target.value;
                              if (selectedId) {
                                const selectedMember =
                                  props.teamMemberList.find((member) => {
                                    console.log(
                                      member,
                                      member.team_member_email,
                                      selectedId,
                                      event
                                    );
                                    return (
                                      member.team_member_email === selectedId
                                    );
                                  });
                                console.log(selectedMember);
                                setTeamMember(selectedMember);
                              } else {
                                setTeamMember({});
                              }
                            }}
                            value={teamMember.team_member_email}
                          >
                            <option
                              disabled={
                                Object.keys(teamMember).length === 0
                                  ? false
                                  : true
                              }
                              value={""}
                            >
                              Select Team Member
                            </option>

                            {props.teamMemberList.map((elem) => (
                              <option value={elem.team_member_email}>
                                {elem.team_member_name}
                              </option>
                            ))}
                          </Form.Select>
                        </FloatingLabel>
                      ) : (
                        <></>
                      )}
                    </div>

                    <hr style={{ margin: 0 }} />
                    {globalValidationError ? (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          alignItems: "center",
                        }}
                      >
                        <AlertCircle
                          size={15}
                          strokeWidth={2}
                          stroke="#d8534e"
                        />
                        <div
                          style={{
                            color: "#d8534e",
                            fontSize: 11,
                            fontWeight: "400",
                            marginLeft: 5,
                          }}
                        >
                          There was an error saving the form. Please check the
                          messages above and try again.
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            "Close Modal (Timesheet Add Modal Web)"
                          );
                          props.setCreatenewmodal(false);
                          setGlobalValidationError(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                        }}
                        type="submit"
                        disabled={btndisable}
                      >
                        {`Save Timesheet`}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Newtimesheetmodal;
