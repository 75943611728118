import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  AtSign,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Clock,
  Copy,
  Crosshair,
  Edit,
  Eye,
  FileText,
  Image,
  Plus,
  Save,
  Settings,
  User,
  Users,
  X,
  XCircle,
} from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Edittimesheetmodal from "../../components/Edittimesheetmodal";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import mixpanel from "mixpanel-browser";
import Newtimesheetmodal from "../../components/Newtimesheetmodal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faCopy,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { logError } from "../../utils/useErrorlogging";

const BookingCustomization = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [websiteData, setWebsiteData] = useState({});
  const [dummyhours, setDummyhous] = useState([]);
  const [selected, setSelected] = useState("hours");
  const [saveloader, setSaveloader] = useState(false);
  const [validationError, setValidationError] = useState("");
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  const [id, setId] = useState("");
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const fetchdata = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/portal/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setWebsiteData(result);
          setId(result.business.business_app_name);
          setDummyhous(
            result &&
              result.portal &&
              Object.keys(result.portal).includes("working_hours") &&
              Object.keys(result.portal.working_hours).length !== 0 &&
              Object.keys(result.portal.working_hours).includes(
                "business_hours"
              ) &&
              result.portal.working_hours.business_hours.length !== 0
              ? result.portal.working_hours.business_hours
              : []
          );
          setLoading(false);

          console.log(result);
        })
        .catch((err) => console.log(err));
    }
  };
  function convertToDoubleDigit(time) {
    const [hours, minutes] = time.split(":");
    const formattedHours = hours.length === 1 ? hours.padStart(2, "0") : hours;
    return `${formattedHours}:${minutes}`;
  }
  function areIntervalsValid(day) {
    const intervals = JSON.parse(JSON.stringify(day.intervals));
    console.log(intervals);
    intervals.sort((a, b) => {
      const fromTimeA = new Date(`2023-08-15T${a.from}:00`);
      const fromTimeB = new Date(`2023-08-15T${b.from}:00`);
      const toTimeA = new Date(`2023-08-15T${a.to}:00`);
      const toTimeB = new Date(`2023-08-15T${b.to}:00`);

      if (fromTimeA < fromTimeB) {
        return -1;
      } else if (fromTimeA > fromTimeB) {
        return 1;
      }

      if (toTimeA < toTimeB) {
        return -1;
      } else if (toTimeA > toTimeB) {
        return 1;
      }

      return 0;
    });

    for (let i = 0; i < intervals.length; i++) {
      const currentInterval = intervals[i];
      console.log(currentInterval);
      const currentFrom = new Date(
        `2023-08-15T${convertToDoubleDigit(currentInterval.from)}:00`
      );
      const currentTo = new Date(
        `2023-08-15T${convertToDoubleDigit(currentInterval.to)}:00`
      );
      console.log(currentFrom, currentTo);
      if (currentFrom >= currentTo) {
        return false;
      }

      for (let j = i + 1; j < intervals.length; j++) {
        const nextInterval = intervals[j];
        const nextFrom = new Date(
          `2023-08-15T${convertToDoubleDigit(nextInterval.from)}:00`
        );
        const nextTo = new Date(
          `2023-08-15T${convertToDoubleDigit(nextInterval.to)}:00`
        );

        // if (currentTo > nextFrom || nextTo < currentFrom) {
        //   return false;
        // }
        console.log(nextFrom, currentFrom, nextTo, currentTo);

        if (nextFrom >= currentFrom && nextTo <= currentTo) {
          console.log("Sdsfdf");

          console.log(nextFrom, currentFrom, nextTo, currentTo);
          return false; // Handle complete containment
        }

        if (currentTo > nextFrom || nextTo < currentFrom) {
          return false;
        }
      }
    }

    return true;
  }

  function checkBusinessHours(businessHours) {
    let isValid = true;

    for (const day of businessHours) {
      const intervals = day.intervals;
      console.log(areIntervalsValid(day));
      if (!areIntervalsValid(day)) {
        console.log("not valid");
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  const submit = async (dummyhours) => {
    console.log(dummyhours);
    let arr = [...dummyhours];
    let isValid = checkBusinessHours(arr);
    console.log(!isValid);
    // if (true) {
    if (!isValid) {
      setValidationError(
        "Business hours not entered correctly. Business hours entered for the same day should not overlap and start time should be before end time."
      );
      setSaveloader(false);
      return;
    }
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);
      const response = await fetch("https://bestmate.us/api/portal/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify({
          working_hours: {
            business_hours: dummyhours,
            business_time_zone: timeZoneOffset,
          },
        }),
      });
      // console.log(response.status);
      if (response.status >= 200 && response.status <= 300) {
        notyf.open({
          type: isOpen ? "home" : "full",
          message: "Business Hours Updated Sucessfully",
          ripple: "true",
          dismissible: "true",
        });
        navigate("/business-website");
      } else {
        notyf.open({
          type: isOpen ? "homewarning" : "fullwarning",
          message: `Something went wrong.`,
          ripple: "true",
          dismissible: "true",
        });
        setSaveloader(false);
        // mixpanel.track(`Fetch Error (Booking Website Customization Web)`, {
        //   error: "booking customization save api",
        // });
        logError({
          error: "fetch error",
          api: "booking customization add api",
          component: `Booking Website Customization Web`,
        });
      }
    } else {
      setSaveloader(false);
      // mixpanel.track(`Error (Booking Website Customization Web)`, {
      //   error: "no user",
      // });
      logError({
        error: "no user",
        component: `Booking Website Customization Web`,
      });
    }
  };

  useEffect(() => {
    mixpanel.track(`On Booking Website Customization (Web)`);
    setSelected("hours");
    fetchdata();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Booking Customization" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      ) : (
        <Container fluid className="p-0">
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor: "white",
              padding: "0rem 1.5rem 2rem 1.5rem",
            }}
            className="pt-2 pb-2 border-bottom "
          >
            <div>
              <h2
                className="mt-3 lead"
                style={{
                  fontSize: "24px",
                  // , fontWeight: 100
                }}
              >
                Booking Customization
              </h2>
              <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                <Breadcrumb.Item
                  active
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  active
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/business-website")}
                >
                  Business Website
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="font-weight-bold">
                  Booking Customization
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "21px",
              }}
            >
              <button
                onClick={() => {
                  mixpanel.track(`Save (Booking Website Customization Web)`);
                  if (saveloader) {
                    return;
                  }
                  console.log("ASDf");
                  setSaveloader(true);
                  submit(dummyhours);
                }}
                type="submit"
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3f80ea",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  width: "100px",
                }}
              >
                {saveloader ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    className="m-1"
                    size="sm"
                  />
                ) : (
                  <>
                    <Save
                      size={15}
                      strokeWidth={2}
                      stroke="white"
                      style={{ marginRight: "8px" }}
                    />
                    <div>Save</div>
                  </>
                )}
              </button>
            </div>
          </Card>
          <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
            <Row style={{ display: "flex" }}>
              <Col md={3}>
                <Card
                  className="mb-2 border rounded-0"
                  // style={{ minHeight: 00 }}
                >
                  {/* <Card.Header> */}

                  {/* </Card.Header> */}
                  <Card.Body>
                    <div
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        color: "black",
                        marginBottom: 10,
                      }}
                    >
                      Customizations
                    </div>
                    <div>
                      <div
                        className="website-custom-menu"
                        style={selected === "hours" ? { color: "#558FED" } : {}}
                        onClick={() => setSelected("hours")}
                      >
                        <Clock
                          size={18}
                          strokeWidth={2}
                          // stroke="#495057"
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>Business Hours</div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={9}>
                {selected === "hours" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Business Hours
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Set up your business hours so that new leads and
                        customers can book slots according to your schedule
                      </div>
                      <div
                        style={
                          {
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                            // marginLeft: 100,
                          }
                        }
                      >
                        <div
                          style={{
                            maxWidth: 700,
                          }}
                        >
                          {[
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ].map((elem, index) => {
                            return (
                              <SingleComponent
                                day={elem}
                                key={index}
                                objj={
                                  dummyhours.find((obj) =>
                                    obj.day.includes(elem.toLowerCase())
                                  ) || {}
                                }
                                dummyhours={dummyhours}
                                setDummyhous={setDummyhous}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
          <Modal
            show={validationError}
            onHide={() => setValidationError("")}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Error
                </h1>
                <div
                  onClick={() => {
                    setValidationError("");
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p style={{ margin: "15px 0 20px" }}>{validationError}</p>
                </div>

                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    onClick={() => {
                      setValidationError("");
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </React.Fragment>
  );
};
function convertToTwoDigitFormat(time) {
  const [hours, minutes] = time.split(":");
  const formattedHours = hours.padStart(2, "0");
  return `${formattedHours}:${minutes}`;
}
const SingleComponent = ({ day, objj, dummyhours, setDummyhous }) => {
  const [isSwitch, setIsSwitch] = useState(
    Object.keys(objj).length !== 0 ? true : false
  );
  const [timePicker, setTimePicker] = useState(false);
  const [obj, setObj] = useState(objj);
  const [pickertime, setPickedTime] = useState("");
  const [selectedtime, setSelectedTime] = useState(null);
  const [timetype, settimetype] = useState("from");
  const [timeindex, setTimeindex] = useState(0);
  console.log(isSwitch, objj, Object.keys(objj).length !== 0);
  useEffect(() => {
    if (!isSwitch) {
      setDummyhous((old) => {
        let arr = old.filter((elem) => elem.day !== day.toLowerCase());
        return [...arr];
      });
    } else {
      setDummyhous((old) => {
        let arr = old.filter((elem) => elem.day !== day.toLowerCase());
        // console.log(arr);
        return [...arr, obj];
      });
    }
  }, [obj]);
  return (
    <div
      style={{
        borderBottomWidth: 1,
        borderBottomColor: "#e0dede",
        borderBottomStyle: "solid",
        padding: 20,
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        // paddingTop: 20,
        // paddingBottom: 20,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Form.Check
          id="checkbox1"
          // label="Send copy to me"
          name="sendcopytome"
          style={{ fontSize: "16px", marginRight: 20 }}
          defaultChecked={isSwitch}
          onChange={() => {
            setIsSwitch((old) => {
              if (old) {
                let data = {};
                setObj(data);
              } else {
                let data = {
                  day: day.toLowerCase(),
                  intervals: [{ from: "9:00", to: "17:00" }],
                };
                setObj(data);
              }
              return !old;
            });
            // setIsSwitch(!isSwitch);
          }}
          type="checkbox"
        />
        <div style={{ color: "black", fontWeight: "bold", fontSize: 16 }}>
          {day + "s"}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          // alignItems: "center",
        }}
      >
        <div>
          {isSwitch ? (
            <>
              {obj.intervals.map((elem, index) => {
                console.log(elem);
                const start = convertToTwoDigitFormat(elem.from);
                const end = convertToTwoDigitFormat(elem.to);
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 15,
                    }}
                  >
                    {index !== 0 ? (
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 10,
                            left: -30,
                            top: -11,
                          }}
                        >
                          <XCircle
                            size={18}
                            strokeWidth={2}
                            color={"grey"}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              console.log("ASdf");
                              let newarr = obj.intervals;
                              newarr.splice(index, 1);

                              let data = {
                                day: day.toLowerCase(),
                                intervals: newarr,
                              };
                              setObj(data);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        // onKeyDown={handleKeyDown}
                        type="time"
                        className="calendar-input"
                        // isInvalid={errors.appointment_end_time}
                        name="appointment_start_time"
                        placeholder="text"
                        value={start}
                        style={{ minWidth: 90 }}
                        onChange={(e) => {
                          console.log(e, e.target.value);
                          let res;

                          res = { ...elem, from: e.target.value };

                          // console.log(res);
                          let newarr = obj.intervals;
                          newarr.splice(index, 1, res);
                          // console.log(newarr);
                          // console.log("changeis hs");
                          let data = {
                            day: day.toLowerCase(),
                            intervals: newarr,
                          };
                          setObj(data);
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}>-</div>
                    <div style={{ position: "relative", marginLeft: 10 }}>
                      <Form.Control
                        // onKeyDown={handleKeyDown}
                        type="time"
                        className="calendar-input"
                        // isInvalid={errors.appointment_end_time}
                        name="appointment_end_time"
                        placeholder="text"
                        style={{ minWidth: 90 }}
                        value={end}
                        // value={values.appointment_end_time}
                        onChange={(e) => {
                          console.log(e, e.target.value);
                          let res;

                          res = { ...elem, to: e.target.value };
                          console.log(index, res);

                          // console.log(res);
                          let newarr = obj.intervals;
                          newarr.splice(index, 1, res);
                          // console.log(newarr);
                          // console.log("changeis hs");
                          let data = {
                            day: day.toLowerCase(),
                            intervals: newarr,
                          };
                          console.log(data);
                          setObj(data);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div style={{ width: 200, textAlign: "center", marginTop: 3 }}>
              <div>Unavailable</div>
            </div>
          )}
        </div>
        <div style={{ marginLeft: 30 }}>
          <Plus
            size={18}
            strokeWidth={2}
            color={isSwitch ? "#3679E8" : "grey"}
            style={{ cursor: "pointer", marginRight: 15, marginTop: 7 }}
            onClick={() => {
              if (isSwitch) {
                setObj((old) => {
                  return {
                    ...old,
                    intervals: [
                      ...obj.intervals,
                      { from: "00:00", to: "01:00" },
                    ],
                  };
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default BookingCustomization;
