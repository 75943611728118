import React, { useEffect, useState, useContext, useRef } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import { Link, useLocation, useNavigate } from "react-router-dom";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useSidebar from "../../hooks/useSidebar";
import { MapPin, Plus, Save, Sliders, User, X } from "react-feather";
import ReactSwitch from "react-switch";
import { logError } from "../../utils/useErrorlogging";

const schema = Yup.object().shape({
  name: Yup.string()
    .required("Team Member Name is required")
    .max(100, "Name must be less than 100 characters"),

  email: Yup.string()
    .required("Team Member Email is required")
    .email("Email is not valid"),
  phoneNumber: Yup.string().matches(
    /^[0-9+\-\s]+$/,
    "Phone number is not valid"
  ),
});
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const FormRow = (props) => {
  const [token, settoken] = useState("");
  const notyf = useContext(NotyfContext);
  let big = props.props.type.toUpperCase();
  let small = props.props.type.toLowerCase();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  let isEdit = location.state ? true : false;
  const { isOpen } = useSidebar();

  const [disablesave, setDisablesave] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [invoicePermission, setInvoicePermission] = useState(true);
  const [invocieaccesslevel, setInvoiceaccesslevel] = useState("VIEW_ALL");
  const [schedulePermission, setSchedulePermission] = useState(true);
  const [scheduleaccesslevel, setScheduleaccesslevel] = useState("VIEW");

  const [bookingPermision, setBookingPermission] = useState(true);
  const [bookingccesslevel, setBookingaccesslevel] = useState("VIEW");

  const [estimatePermission, setEstimatePermission] = useState(true);
  const [estimateaccesslevel, setEstimateaccesslevel] = useState("VIEW_ALL");

  const [requestPermission, setRequestPermission] = useState(true);
  const [requestaccesslevel, setRequestaccesslevel] = useState("VIEW_ALL");

  const [customerPermission, setCustomerPermision] = useState(true);
  const [customeraccesslevel, setCustomeraccesslevel] =
    useState("VIEW_LIMITED");

  const [itemPermission, setItemPermission] = useState(true);
  const [itemaccesslevel, setItemaccesslevel] = useState("VIEW_ALL");

  const [timesheetPermission, setTimesheetPermission] = useState(true);
  const [timesheetaccesslevel, setTimesheetaccesslevel] =
    useState("VIEW_ADD_EDIT");

  const [expensePermission, setExpensePermission] = useState(true);
  const [expenseaccesslevel, setExpenseaccesslevel] = useState("VIEW_ADD_EDIT");

  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const submitData = async (data) => {
    console.log(data);
    var user = firebase.auth().currentUser;
    mixpanel.track(`Save (Add ${props.props.type} Web)`, {
      ...data,
    });

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/team/add-team-member", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            navigate(`/manage-team`);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: isEdit
                ? "Member Updated Successfully"
                : `Member added. Please ask them to check their email to accept your invite.`,
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Add ${props.props.type} Web)`, {
            //   error: "member add api",
            // });
            logError({
              error: "fetch error",
              api: "member add api",
              component: `Add ${props.props.type} Web`,
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
          // mixpanel.track(`Error (Add ${props.props.type} Web)`, {
          //   error: err,
          // });
          logError({
            error: err,
            component: `Add ${props.props.type} Web`,
          });
        });
    }
  };

  const submitHandler = (elem) => {
    setDisablesave(true);
    // elem.preventDefault();
    const data = {
      team_member_name: capitalizeFirstLetter(elem.name),
      team_member_email: elem.email,
      team_member_phone: elem.phoneNumber,
      team_member_job_profile: elem.jobProfile,
      team_member_permissions: {
        isAdmin: false,
        isOwner: false,
        schedule: {
          permission_key:
            !schedulePermission || !customerPermission
              ? ""
              : scheduleaccesslevel,
        },
        timesheet: {
          permission_key: !timesheetPermission ? "" : timesheetaccesslevel,
        },
        expense: {
          permission_key: !expensePermission ? "" : expenseaccesslevel,
        },
        item: {
          permission_key: !itemPermission ? "" : itemaccesslevel,
        },
        partner: {
          permission_key: !customerPermission ? "" : customeraccesslevel,
        },
        booking: {
          permission_key:
            !bookingPermision || !itemPermission || !customerPermission
              ? ""
              : bookingccesslevel,
        },
        request: {
          permission_key:
            !requestPermission || !itemPermission || !customerPermission
              ? ""
              : requestaccesslevel,
        },
        estimate: {
          permission_key:
            !estimatePermission || !itemPermission || !customerPermission
              ? ""
              : estimateaccesslevel,
        },
        invoice: {
          permission_key:
            !invoicePermission || !itemPermission || !customerPermission
              ? ""
              : invocieaccesslevel,
        },
      },
    };

    if (elem.email) {
      data.partner_email = elem.email;
    }
    if (elem.phoneNumber) {
      data.partner_number = elem.phoneNumber;
    }
    if (elem.terms) {
      data.partner_payment_terms = elem.terms;
    }
    if (elem.notes) {
      data.partner_notes = elem.notes;
    }
    if (elem.tax) {
      data.partner_tax_number = elem.tax;
    }
    console.log(data);
    if (isEdit) {
      data.team_ember_uid = location.state.row.team_member_uid;
    }
    submitData(data);
  };

  const cancelHandler = () => {
    navigate(`/manage-team`);
  };
  useEffect(() => {
    mixpanel.track(`On Add Team (Web)`);
    var user = firebase.auth().currentUser;
    if (user) {
      const func = async () => {
        let tokenss = await user.getIdToken();
        settoken(tokenss);
        props.setsubmithandler(() => submitHandler);
      };
      func();
    }
    // });
  }, []);

  useEffect(() => {
    if (isEdit) {
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "schedule"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.schedule
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.schedule.permission_key &&
        location.state.row.team_member_permissions.schedule.permission_key
          .length !== 0
      ) {
        setSchedulePermission(true);
        setScheduleaccesslevel(
          location.state.row.team_member_permissions.schedule.permission_key
        );
      } else {
        setSchedulePermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "booking"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.booking
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.booking.permission_key &&
        location.state.row.team_member_permissions.booking.permission_key
          .length !== 0
      ) {
        setBookingPermission(true);
        setBookingaccesslevel(
          location.state.row.team_member_permissions.booking.permission_key
        );
      } else {
        setBookingPermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "timesheet"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.timesheet
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.timesheet.permission_key &&
        location.state.row.team_member_permissions.timesheet.permission_key
          .length !== 0
      ) {
        setTimesheetPermission(true);
        setTimesheetaccesslevel(
          location.state.row.team_member_permissions.timesheet.permission_key
        );
      } else {
        setTimesheetPermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "expense"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.expense
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.expense.permission_key &&
        location.state.row.team_member_permissions.expense.permission_key
          .length !== 0
      ) {
        setExpensePermission(true);
        setExpenseaccesslevel(
          location.state.row.team_member_permissions.expense.permission_key
        );
      } else {
        setExpensePermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "request"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.request
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.request.permission_key &&
        location.state.row.team_member_permissions.request.permission_key
          .length !== 0
      ) {
        setRequestPermission(true);
        setRequestaccesslevel(
          location.state.row.team_member_permissions.request.permission_key
        );
      } else {
        setRequestPermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "item"
        ) &&
        Object.keys(location.state.row.team_member_permissions.item).includes(
          "permission_key"
        ) &&
        location.state.row.team_member_permissions.item.permission_key &&
        location.state.row.team_member_permissions.item.permission_key
          .length !== 0
      ) {
        setItemPermission(true);
        setItemaccesslevel(
          location.state.row.team_member_permissions.item.permission_key
        );
      } else {
        setItemPermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "partner"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.partner
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.partner.permission_key &&
        location.state.row.team_member_permissions.partner.permission_key
          .length !== 0
      ) {
        setCustomerPermision(true);
        setCustomeraccesslevel(
          location.state.row.team_member_permissions.partner.permission_key
        );
      } else {
        setCustomerPermision(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "estimate"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.estimate
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.estimate.permission_key &&
        location.state.row.team_member_permissions.estimate.permission_key
          .length !== 0
      ) {
        setEstimatePermission(true);
        setEstimateaccesslevel(
          location.state.row.team_member_permissions.estimate.permission_key
        );
      } else {
        setEstimatePermission(false);
      }
      if (
        location.state.row &&
        Object.keys(location.state.row).includes("team_member_permissions") &&
        location.state.row.team_member_permissions.length !== 0 &&
        Object.keys(location.state.row.team_member_permissions).includes(
          "invoice"
        ) &&
        Object.keys(
          location.state.row.team_member_permissions.estimate
        ).includes("permission_key") &&
        location.state.row.team_member_permissions.invoice.permission_key &&
        location.state.row.team_member_permissions.invoice.permission_key
          .length !== 0
      ) {
        setInvoicePermission(true);
        setInvoiceaccesslevel(
          location.state.row.team_member_permissions.invoice.permission_key
        );
      } else {
        setInvoicePermission(false);
      }
    }
  }, [isEdit]);

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(elem) => submitHandler(elem)}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          name: location.state ? location.state.row.team_member_name : "",
          email: location.state ? location.state.row.team_member_email : "",
          phoneNumber: location.state
            ? location.state.row.team_member_phone
            : "",
          jobProfile: location.state
            ? location.state.row.team_member_job_profile
            : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          validateForm,
        }) => (
          <Form
            noValidate
            onSubmit={async (e) => {
              e.preventDefault();
              const obj = await validateForm();
              if (Object.keys(obj).length !== 0) {
                setValidationError(true);
              } else {
                handleSubmit();
              }
            }}
            onKeyDown={(keyEvent) => {
              // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
              //   keyEvent.preventDefault();
              // }
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "white",
                padding: "0rem 1.5rem 2rem 1.5rem",
              }}
              className="pt-2 pb-2 border-bottom "
            >
              <div>
                <h2
                  className="mt-3 lead"
                  style={{
                    fontSize: "24px",
                    // , fontWeight: 100
                  }}
                >
                  {isEdit ? "Edit" : "New"} {props.props.type}
                </h2>
                <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                  <Breadcrumb.Item
                    active
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    active
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/manage-team`)}
                  >
                    {props.props.type}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active className="font-weight-bold">
                    {isEdit ? "Edit" : "New"} {props.props.type}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "21px",
                }}
              >
                <button
                  onClick={() => {
                    // submithandler();
                  }}
                  type="submit"
                  style={{
                    border: "1px #E7EAEC solid",
                    borderRadius: "3px",
                    padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "#3f80ea",
                    // backgroundColor: "#4f83cb",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: "inherit",
                    cursor: "pointer",
                    width: "100px",
                  }}
                  disabled={disablesave}
                >
                  <Save
                    size={15}
                    strokeWidth={2}
                    stroke="white"
                    style={{ marginRight: "8px" }}
                  />
                  <div>Save</div>
                </button>
                {/* </div> */}
              </div>
            </Card>
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              <Card className="mb-2 border rounded-0">
                <Card.Header
                  className="pb-0"
                  style={{
                    display: "flex",
                  }}
                >
                  <User
                    size={18}
                    strokeWidth={3}
                    stroke="#485056"
                    style={{ marginRight: 15 }}
                  />
                  <Card.Title
                    className="mb-0"
                    style={{ fontSize: 18 }}
                  >{`${props.props.type} Profile`}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          onKeyDown={handleKeyDown}
                          placeholder="Joe"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          onKeyDown={handleKeyDown}
                          disabled={isEdit}
                          placeholder="example@mtree.com"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Phone Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="tel"
                          name="phoneNumber"
                          onKeyDown={handleKeyDown}
                          placeholder="912346789"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Job Profile"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="jobProfile"
                          onKeyDown={handleKeyDown}
                          placeholder="Company Name"
                          value={values.jobProfile}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Card.Body>

                <hr style={{ margin: "0 20px" }} />

                <Card.Header className="pb-0" style={{ display: "flex" }}>
                  <Sliders
                    size={18}
                    strokeWidth={3}
                    stroke="#485056"
                    style={{ marginRight: 15 }}
                  />
                  <Card.Title className="mb-0" style={{ fontSize: 18 }}>
                    Permissions
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Invoices
                      </div>
                      <ReactSwitch
                        onChange={() => setInvoicePermission((old) => !old)}
                        checked={
                          itemPermission &&
                          customerPermission &&
                          invoicePermission
                        }
                        disabled={!itemPermission || !customerPermission}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {invoicePermission &&
                    customerPermission &&
                    itemPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="invoice"
                              checked={invocieaccesslevel === "VIEW_ALL"}
                              value={"VIEW_ALL"}
                              onChange={() => {
                                setInvoiceaccesslevel("VIEW_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view all invoices
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="invoice"
                              checked={
                                invocieaccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setInvoiceaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit all invoices
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="invoice"
                              checked={
                                invocieaccesslevel ===
                                "VIEW_ADD_EDIT_DELETE_ALL"
                              }
                              value={"VIEW_ADD_EDIT_DELETE_ALL"}
                              onChange={() => {
                                setInvoiceaccesslevel(
                                  "VIEW_ADD_EDIT_DELETE_ALL"
                                );
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit, delete all invoices
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {!(itemPermission && customerPermission) ? (
                      <>
                        <div style={{ marginTop: 5 }}>
                          Turn on{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Products & Services
                          </span>{" "}
                          and{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Customers, Leads, Suppliers
                          </span>{" "}
                          to create and edit invoices
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Schedule
                      </div>
                      <ReactSwitch
                        onChange={() => setSchedulePermission((old) => !old)}
                        checked={customerPermission && schedulePermission}
                        disabled={!customerPermission}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {schedulePermission && customerPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="schedule"
                              checked={scheduleaccesslevel === "VIEW"}
                              value={"VIEW"}
                              onChange={() => {
                                setScheduleaccesslevel("VIEW");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view their schedule
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="schedule"
                              checked={scheduleaccesslevel === "VIEW_ADD_EDIT"}
                              value={"VIEW_ADD_EDIT"}
                              onChange={() => {
                                setScheduleaccesslevel("VIEW_ADD_EDIT");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit their schedule
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="schedule"
                              checked={
                                scheduleaccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setScheduleaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit everyone’s schedule
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="schedule"
                              checked={
                                scheduleaccesslevel ===
                                "VIEW_ADD_EDIT_DELETE_ALL"
                              }
                              value={"VIEW_ADD_EDIT_DELETE_ALL"}
                              onChange={() => {
                                setScheduleaccesslevel(
                                  "VIEW_ADD_EDIT_DELETE_ALL"
                                );
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit, delete everyone’s
                              schedule
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {!customerPermission ? (
                      <>
                        <div style={{ marginTop: 5 }}>
                          Turn on{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Customers, Leads, Suppliers
                          </span>{" "}
                          to create and edit schedules
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Bookings
                      </div>
                      <ReactSwitch
                        onChange={() => setBookingPermission((old) => !old)}
                        checked={
                          itemPermission &&
                          customerPermission &&
                          bookingPermision
                        }
                        disabled={!itemPermission || !customerPermission}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {bookingPermision &&
                    customerPermission &&
                    itemPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="booking"
                              checked={bookingccesslevel === "VIEW"}
                              value={"VIEW"}
                              onChange={() => {
                                setBookingaccesslevel("VIEW");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view their booking{" "}
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="booking"
                              checked={bookingccesslevel === "VIEW_ADD_EDIT"}
                              value={"VIEW_ADD_EDIT"}
                              onChange={() => {
                                setBookingaccesslevel("VIEW_ADD_EDIT");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit their booking{" "}
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="booking"
                              checked={
                                bookingccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setBookingaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit everyone’s booking{" "}
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="booking"
                              checked={
                                bookingccesslevel === "VIEW_ADD_EDIT_DELETE_ALL"
                              }
                              value={"VIEW_ADD_EDIT_DELETE_ALL"}
                              onChange={() => {
                                setBookingaccesslevel(
                                  "VIEW_ADD_EDIT_DELETE_ALL"
                                );
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit, delete everyone’s
                              booking{" "}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {!(itemPermission && customerPermission) ? (
                      <>
                        <div style={{ marginTop: 5 }}>
                          Turn on{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Products & Services
                          </span>{" "}
                          and{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Customers, Leads, Suppliers
                          </span>{" "}
                          to create and edit bookings
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Estimates
                      </div>
                      <ReactSwitch
                        onChange={() => setEstimatePermission((old) => !old)}
                        checked={
                          itemPermission &&
                          customerPermission &&
                          estimatePermission
                        }
                        disabled={!itemPermission || !customerPermission}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {estimatePermission &&
                    customerPermission &&
                    itemPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="estaimte"
                              checked={estimateaccesslevel === "VIEW_ALL"}
                              value={"VIEW_ALL"}
                              onChange={() => {
                                setEstimateaccesslevel("VIEW_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view all estiamtes
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="estaimte"
                              checked={
                                estimateaccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setEstimateaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit all estiamtes
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="estaimte"
                              checked={
                                estimateaccesslevel ===
                                "VIEW_ADD_EDIT_DELETE_ALL"
                              }
                              value={"VIEW_ADD_EDIT_DELETE_ALL"}
                              onChange={() => {
                                setEstimateaccesslevel(
                                  "VIEW_ADD_EDIT_DELETE_ALL"
                                );
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit, delete all estiamtes
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {!(itemPermission && customerPermission) ? (
                      <>
                        <div style={{ marginTop: 5 }}>
                          Turn on{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Products & Services
                          </span>{" "}
                          and{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Customers, Leads, Suppliers
                          </span>{" "}
                          to create and edit estimates
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Requests
                      </div>
                      <ReactSwitch
                        onChange={() => setRequestPermission((old) => !old)}
                        checked={
                          itemPermission &&
                          customerPermission &&
                          requestPermission
                        }
                        disabled={!itemPermission || !customerPermission}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {requestPermission &&
                    customerPermission &&
                    itemPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="request"
                              checked={requestaccesslevel === "VIEW_ALL"}
                              value={"VIEW_ALL"}
                              onChange={() => {
                                setRequestaccesslevel("VIEW_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view all requests
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="request"
                              checked={
                                requestaccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setRequestaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit all requests
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="request"
                              checked={
                                requestaccesslevel ===
                                "VIEW_ADD_EDIT_DELETE_ALL"
                              }
                              value={"VIEW_ADD_EDIT_DELETE_ALL"}
                              onChange={() => {
                                setRequestaccesslevel(
                                  "VIEW_ADD_EDIT_DELETE_ALL"
                                );
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit, delete all requests
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {!(itemPermission && customerPermission) ? (
                      <>
                        <div style={{ marginTop: 5 }}>
                          Turn on{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Products & Services
                          </span>{" "}
                          and{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Customers, Leads, Suppliers
                          </span>{" "}
                          to create and edit requests
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Customers, Leads, Suppliers
                      </div>
                      <ReactSwitch
                        onChange={() => setCustomerPermision((old) => !old)}
                        checked={customerPermission}
                        disabled={false}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>

                    <div>
                      Allow access to all customers, leads, and suppliers.
                    </div>
                    {customerPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="customer"
                              checked={customeraccesslevel === "VIEW_LIMITED"}
                              value={"VIEW_LIMITED"}
                              onChange={() => {
                                setCustomeraccesslevel("VIEW_LIMITED");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to only view customer, lead, supplier
                              information in an invoice, estimate, schedule, or
                              request
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="customer"
                              checked={customeraccesslevel === "VIEW_ALL"}
                              value={"VIEW_ALL"}
                              onChange={() => {
                                setCustomeraccesslevel("VIEW_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to only view a list of customers,
                              leads, suppliers to create an invoice, estimate,
                              schedule, or request
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="customer"
                              checked={
                                customeraccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setCustomeraccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit all customers, leads,
                              and suppliers
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="customer"
                              checked={
                                customeraccesslevel ===
                                "VIEW_ADD_EDIT_DELETE_ALL"
                              }
                              value={"VIEW_ADD_EDIT_DELETE_ALL"}
                              onChange={() => {
                                setCustomeraccesslevel(
                                  "VIEW_ADD_EDIT_DELETE_ALL"
                                );
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to add, edit, delete all customers,
                              leads, and suppliers
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Products & Services
                      </div>
                      <ReactSwitch
                        onChange={() => setItemPermission((old) => !old)}
                        checked={itemPermission}
                        disabled={false}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    <div>
                      Allow access to all products & services to create invoices
                      & estimates
                    </div>
                    {itemPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="item"
                              checked={itemaccesslevel === "VIEW_ALL"}
                              value={"VIEW_ALL"}
                              onChange={() => {
                                setItemaccesslevel("VIEW_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              View product & service list only
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="item"
                              checked={itemaccesslevel === "VIEW_ADD_EDIT_ALL"}
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setItemaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Add, view, edit, and delete full product & service
                              info
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Timesheets
                      </div>
                      <ReactSwitch
                        onChange={() => setTimesheetPermission((old) => !old)}
                        checked={timesheetPermission}
                        disabled={false}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {timesheetPermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="timesheet"
                              checked={timesheetaccesslevel === "VIEW_ADD_EDIT"}
                              value={"VIEW_ADD_EDIT"}
                              onChange={() => {
                                setTimesheetaccesslevel("VIEW_ADD_EDIT");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view, record, edit their
                              timesheets
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="timesheet"
                              checked={
                                timesheetaccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setTimesheetaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view, record, edit everyone’s
                              timesheets
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 25, marginBottom: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Expenses
                      </div>
                      <ReactSwitch
                        onChange={() => setExpensePermission((old) => !old)}
                        checked={expensePermission}
                        disabled={false}
                        onColor={"#3679E8"}
                        offColor={"#d6d6d6"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={45}
                        handleDiameter={23}
                      />
                    </div>
                    {expensePermission ? (
                      <>
                        <div style={{ marginTop: 5, marginLeft: 10 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="expenses"
                              checked={expenseaccesslevel === "VIEW_ADD_EDIT"}
                              value={"VIEW_ADD_EDIT"}
                              onChange={() => {
                                setExpenseaccesslevel("VIEW_ADD_EDIT");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view, record, edit their expenses
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Check
                              type={"radio"}
                              name="expenses"
                              checked={
                                expenseaccesslevel === "VIEW_ADD_EDIT_ALL"
                              }
                              value={"VIEW_ADD_EDIT_ALL"}
                              onChange={() => {
                                setExpenseaccesslevel("VIEW_ADD_EDIT_ALL");
                              }}
                            />
                            <div style={{ fontSize: 14, marginLeft: 10 }}>
                              Allow member to view, record, edit everyone’s
                              expenses
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Card.Body>
              </Card>

              <div
                className="d-flex mt-4"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  {isEdit ? (
                    <Button
                      variant={
                        location.state.row.team_member_invite_accepted !==
                        "DEACTIVATED"
                          ? "danger"
                          : "success"
                      }
                      size="lg"
                      onClick={async () => {
                        try {
                          if (
                            location.state.row.team_member_invite_accepted !==
                            "DEACTIVATED"
                          ) {
                            const dat = await fetch(
                              "https://bestmate.us/api/team/deactivate-team-member",
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization: "Bearer " + token,
                                },
                                body: JSON.stringify({
                                  team_member_reference_id:
                                    location.state.row.team_member_reference_id,
                                }),
                              }
                            );
                            console.log(dat.status);
                            if (dat.status >= 200 && dat.status <= 300) {
                              navigate(`/manage-team`);
                              notyf.open({
                                type: isOpen ? "home" : "full",
                                message: "Team Member Deactivated",
                                ripple: "true",
                                dismissible: "true",
                              });
                              setDisablesave(false);
                            } else {
                              notyf.open({
                                type: isOpen ? "homewarning" : "fullwarning",
                                message: "Something went wrong",
                                ripple: "true",
                                dismissible: "true",
                              });
                              console.log("Something Went Wrong");
                            }
                          } else {
                            const dat = await fetch(
                              "https://bestmate.us/api/team/reactivate-team-member",
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization: "Bearer " + token,
                                },
                                body: JSON.stringify({
                                  team_member_reference_id:
                                    location.state.row.team_member_reference_id,
                                  team_member_email:
                                    location.state.row.team_member_email,
                                }),
                              }
                            );
                            console.log(dat.status);
                            if (dat.status >= 200 && dat.status <= 300) {
                              navigate(`/manage-team`);
                              notyf.open({
                                type: isOpen ? "home" : "full",
                                message: "Team Member Activated",
                                ripple: "true",
                                dismissible: "true",
                              });
                              setDisablesave(false);
                            } else {
                              notyf.open({
                                type: isOpen ? "homewarning" : "fullwarning",
                                message: "Something went wrong",
                                ripple: "true",
                                dismissible: "true",
                              });
                              console.log("Something Went Wrdong");
                            }
                          }
                        } catch (err) {}
                      }}
                      className="me-1 mb-1 center"
                    >
                      {location.state.row.team_member_invite_accepted !==
                      "DEACTIVATED"
                        ? "Deactivate"
                        : "Activate"}
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="secondary"
                    size="lg"
                    onClick={() => {
                      mixpanel.track(`Cancel (Add Team member Web)`);
                      cancelHandler();
                    }}
                    className="mb-1 center"
                  >
                    Cancel
                  </Button>
                </div>
                <Button
                  variant="primary"
                  // onClick={submitHandler}
                  type="submit"
                  size="lg"
                  className="mb-1 center"
                  disabled={disablesave}

                  // disabled
                >
                  {/* Create {props.props.type} */}
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        show={validationError}
        onHide={() => setValidationError(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Error
            </h1>
            <div
              onClick={() => {
                setValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                There was an error saving the form. Please check the messages in
                the form and try again.
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setValidationError(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* </div> */}
    </>
  );
};

const AddTeamMember = (props) => {
  const navigate = useNavigate();
  const [submithandler, setsubmithandler] = useState();

  return (
    <React.Fragment>
      <Helmet title={`Add Team member`} />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Add New {props.type}</h1> */}

        <FormRow
          props={{ type: "Team Member" }}
          setsubmithandler={setsubmithandler}
        />
      </Container>
    </React.Fragment>
  );
};

export default AddTeamMember;
