import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/css/App.css";

import ResetPassword from "../../components/auth/ResetPassword";
import icon from "../../assets/img/illustrations/icon.png";

import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import mixpanel from "mixpanel-browser";
import { logError } from "../../utils/useErrorlogging";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const notyf = useContext(NotyfContext);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
  });
  return (
    <React.Fragment>
      <Helmet title="Reset Password" />
      {/* <div className="text-center mt-4">
      <h1 className="h2">Reset password</h1>
      <p className="lead">Enter your email to reset your password.</p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <ResetPassword />
        </div>
      </Card.Body>
    </Card> */}
      <div
        id="authheader"
        style={{
          width: "100%",
          // height: "80px",
          backgroundColor: "white",
          borderBottom: "1px solid #ebebeb",
          // boxShadow: "0px 45px 10px -15px #111",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            maxWidth: "820px",
            // maxWidth: "1050px",
            margin: "auto",
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              marginTop: 4,
            }}
          >
            <img
              src={icon}
              alt="BestMate"
              id="authheaderlogo"
              style={{ alignSelf: "center" }}
            />
            <strong id="authheadertext" style={{ fontWeight: "bolder" }}>
              BESTMATE
            </strong>
          </div>
          <a
            style={{
              color: "black",
              fontSize: "16px",
              margin: 0,
              fontWeight: "400",
            }}
            href="mailto:support@bestmate.us"
            onClick={() => mixpanel.track(`Help (Reset Password Web)`)}
          >
            Help
          </a>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          // backgroundColor: "#f3f3f3",
          display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 80px)",
        }}
      >
        <div
          style={{
            // backgroundColor: "pink",
            paddingRight: 20,
            paddingLeft: 20,
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            id="signupheader"
            style={{
              color: "black",
              margin: 0,
              textAlign: "center",
              paddingTop: "5rem",
            }}
          >
            Reset Password
          </h2>
          <strong
            style={{
              color: "black",
              fontSize: "20px",
              marginTop: 10,
              marginBottom: 30,
              textAlign: "center",
            }}
          >
            Enter your email to reset your password.
          </strong>
          <div
            style={{
              width: "100%",
              maxWidth: 400,
              // marginBottom: 10,
            }}
          >
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                email: "",
                submit: false,
              }}
              validationSchema={validationSchema}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await resetPassword(values.email);
                  notyf.open({
                    type: "full",
                    message: "Reset Password link is sent to your email",
                    ripple: "true",
                    dismissible: "true",
                  });
                  navigate("/");
                } catch (error) {
                  // mixpanel.track(`Error Reset Password (Reset Password Web)`, {
                  //   error: error.message,
                  //   stack: error.stack,
                  // });
                  logError({
                    error: error.message,
                    stack: error.stack,
                    component: "Error Reset Password Web",
                  });
                  if (error.code === "auth/user-not-found") {
                    setErrors({
                      submit: "No user found with this email. Please sign up.",
                    });
                  } else if (error.code === "auth/network-request-failed") {
                    setErrors({
                      submit:
                        "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
                    });
                  } else if (error.code === "auth/invalid-email") {
                    setErrors({
                      submit: "Invalid email address. Please try again.",
                    });
                  } else if (error.code === "auth/too-many-requests") {
                    setErrors({
                      submit:
                        "Too many unsuccessful login attempts. Please try again later.",
                    });
                  } else if (error.code === "auth/operation-not-allowed") {
                    setErrors({
                      submit: "This sign-in method is not allowed.",
                    });
                  } else {
                    setErrors({ submit: "Something went wrong" });
                  }

                  setStatus({ success: false });
                  // setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                validateForm,
              }) => (
                <Form
                  noValidate
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const obj = await validateForm();
                    if (Object.keys(obj).length !== 0) {
                      // mixpanel.track(
                      //   `Error Reset Password (Reset Password Web`,
                      //   {
                      //     error: obj,
                      //   }
                      // );
                      logError({
                        error: obj,
                        component: `Error Reset Password (Reset Password Web`,
                      });
                    }
                    handleSubmit();
                  }}
                >
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      label="Email Address"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group> */}
                  {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      name="email"
                      label="Email Address"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{
                        borderColor: "black",
                        borderRadius: 10,
                        borderColor: "#c4c4c2",
                      }}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel> */}
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{
                        borderColor: "black",
                        borderRadius: 5,
                        borderColor: "#c4c4c2",
                      }}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <div className="text-center mt-4">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                      style={{ width: "100%", borderRadius: 5 }}
                    >
                      Reset password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <p className="mt-4 d-flex justify-content-center">
              Already have an account ? &nbsp;
              <Link
                to="/sign-in"
                onClick={() => mixpanel.track(`Help (Reset Password Web)`)}
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
