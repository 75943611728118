import React from "react";

import { Dropdown } from "react-bootstrap";

import { PieChart, Settings, User } from "react-feather";
import useAuth from "../../hooks/useAuth";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import { useNavigate } from "react-router-dom";
import { usePremium } from "../../contexts/PremiumContext";
import mixpanel from "mixpanel-browser";

const NavbarUser = () => {
  const { setMoreInfo } = usePremium();
  const navigate = useNavigate();
  const { signOut, bdata } = useAuth();
  const { isTeamAccount } = useAuth();
  const signout = async (e) => {
    e.preventDefault();
    try {
      mixpanel.track(`Sign Out (Navbar Web)`);
      await signOut();
      setMoreInfo(null);
      navigate("/");
      // navigate("/sign-in");
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log(bdata);
  console.log("999999999");
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }

  return (
    <Dropdown
      className="nav-item"
      align="end"
      // style={{ minWidth: 130, display: "flex", justifyContent: "flex-end" }}
    >
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {/* <img
            src={avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          /> */}
          <span className="text-dark" style={{ fontWeight: "bold" }}>
            {bdata && bdata.business_name
              ? ` ${truncateTextByWordCount(bdata.business_name, 70)} `
              : bdata && bdata.business_user_name
              ? ` ${truncateTextByWordCount(bdata.business_user_name, 70)} `
              : ` Welcome `}
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {isTeamAccount ? (
          <Dropdown.Item
            onClick={() => {
              mixpanel.track(`User Profile (Navbar Web)`);

              return navigate("/user-profile");
            }}
          >
            <User size={16} className="align-middle me-2" />
            User Profile
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            onClick={() => {
              mixpanel.track(`Business Profile (Navbar Web)`);

              return navigate("/profile");
            }}
          >
            <User size={16} className="align-middle me-2" />
            Business Profile
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => {
            mixpanel.track(`Contact Support (Navbar Web)`);
            return window.open("mailto:support@bestmate.us");
          }}
        >
          Contact Support
        </Dropdown.Item>
        <Dropdown.Item onClick={signout}>Log Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
