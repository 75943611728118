import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Breadcrumb,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useSidebar from "../../hooks/useSidebar";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { PlusSquare, X } from "react-feather";
import ReactQuill from "react-quill";
import getSymbolFromCurrency from "currency-symbol-map";
import Page404Authenticated from "../auth/Page404authenticated";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
/*
  Input Component containes the edit form which opens when we click on a particular row in the table.
  The Edit form contains three buttons (Save, cancel and delete).
*/
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const InputComponent = (props) => {
  const { isOpen } = useSidebar();
  const location = useLocation();
  const navigate = useNavigate();
  let usercurrency = localStorage.getItem("currency") || "USD";
  const { id } = useParams();
  // notyf is a javascript libraray used for notifications/alerts
  const notyf = useContext(NotyfContext);
  const [openModals, setOpenModals] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [rowdata, setRowdata] = useState();
  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(props.type);
  const [desc, setDesc] = useState("");
  const [disablesave, setDisablesave] = useState(false);
  const [fetcherror, setFetcherror] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [disabledelete, setDisableDelete] = useState(false);
  const { isTeamAccount } = useAuth();
  let big = type.toUpperCase();
  let small = type.toLowerCase();
  const handler = (big) => {
    let isProduct;
    let isService;
    if (big === "PRODUCT") {
      isProduct = true;
      isService = false;
    } else if (big === "SERVICE") {
      isProduct = false;
      isService = true;
    }
    return { isProduct, isService };
  };

  useEffect(() => {
    if (openModals) {
      setDisableDelete(false);
    }
  }, [openModals]);

  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  let res = handler(big);
  console.log(isOpen);
  //submitData function is used to submit the updated data to the backend
  const submitData = async (values) => {
    const itemData = JSON.parse(JSON.stringify({ ...values }));
    itemData["item_business_id"] = rowdata.item_business_id;
    itemData["item_type"] = big;
    itemData["item_currency"] = localStorage.getItem("currency") || "USD";
    if (big === "SERVICE") {
      // itemData["item_quantity_per_box"] = "";
      // itemData["item_quantity"] = "";
    }
    if (big === "PRODUCT") {
      if (
        itemData.item_quantity_per_box !== null &&
        itemData.item_quantity_per_box !== undefined
      ) {
        itemData["item_quantity_per_box"] =
          itemData.item_quantity_per_box.toString();
      }
      if (
        itemData.item_quantity !== null &&
        itemData.item_quantity !== undefined
      ) {
        itemData["item_quantity"] = itemData.item_quantity.toString();
      }
      // itemData["item_unit_type"] = "";
    }
    itemData["item_name"] = capitalizeFirstLetter(itemData.item_name);
    if (
      itemData.item_cost_price !== null &&
      itemData.item_cost_price !== undefined
    ) {
      itemData["item_cost_price"] = itemData.item_cost_price.toString();
    }
    if (
      itemData.item_selling_price !== undefined &&
      itemData.item_selling_price !== undefined
    ) {
      itemData["item_selling_price"] = itemData.item_selling_price.toString();
    }

    // console.log(values, rowdata, itemData);
    mixpanel.track(`Save (Edit ${big.toLowerCase()} Web)`, {
      ...itemData,
    });
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        itemData.team = true;
      } else {
        delete itemData.team;
      }
      fetch("https://bestmate.us/api/items/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(itemData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("response", response);
            navigate(`/${small}s`);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Item updated successfully",
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);

            // mixpanel.track(`Fetch Error (Edit ${big.toLowerCase()} Web)`, {
            //   error: "item create api",
            // });
            logError({
              error: "fetch error",
              api: "item create api",
              component: `Edit ${big.toLowerCase()} Web`,
            });
          }
        })

        .catch((error) => {
          setDisablesave(false);

          console.error(error);
          // mixpanel.track(`Error (Edit ${big.toLowerCase()} Web)`, {
          //   error: error,
          // });
          logError({
            error: error,
            component: `Edit ${big.toLowerCase()} Web`,
          });
        });
    }
  };

  //getRequiredData function is used to get the data of particular item by using its id
  const getRequiredData = async (token) => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      fetch(
        "https://bestmate.us/api/items/get/" +
          id +
          `${isTeamAccount ? "?team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            setFetcherror(true);
            setLoading(false);
            throw new Error(`HTTP error, status code ${res.status}`);
          }
          return res.json();
        })
        .then((result) => {
          setRowdata(result);
          setLoading(false);
          setDesc(result.item_description);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  };

  //deleteRecord function is used to delete the item
  const deleteRecord = async (deleteData) => {
    setDisableDelete(true);
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/items/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            // props.restart();
            navigate(-1);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Item deleted successfully",
              ripple: "true",
              dismissible: "true",
            });

            // console.log("response", response);
            // getData();
            // alert("Item Deleted");
            // props.setSearchParams({});
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track("Fetch Error (Item Edit Web)", {
            //   error: "item edit api",
            // });
            logError({
              error: "fetch error",
              api: "item edit api",
              component: `Edit ${big.toLowerCase()} Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // mixpanel.track("Error (Item Edit Web)", {
          //   error: error.message,
          //   stack: error.stack,
          // });
          logError({
            error: error.message,
            stack: error.stack,
            component: `Edit ${big.toLowerCase()} Web`,
          });
        });
    }
    // setShow(false);

    // navigate("/services");
  };

  useEffect(async () => {
    mixpanel.track(`On Edit ${big.toLowerCase()} (Web)`);
    setLoading(true);
    // firebase.auth().onAuthStateChanged(async (user) => {
    setFetcherror(false);
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      if (location.state !== null) {
        setRowdata(location.state.row);
        setDesc(location.state.row.item_description);
        setLoading(false);
      } else {
        // if we don't get data from navigation we fetch it by using the id of that particular item. we get id from url
        getRequiredData(tokenss);
      }
    }
    // });
  }, []);
  console.log(rowdata);

  return (
    <>
      {!loading ? (
        <>
          {!fetcherror && rowdata ? (
            <>
              <Formik
                initialValues={{
                  item_name: rowdata.item_name,
                  item_description: rowdata.item_description,
                  item_unit_type: rowdata.item_unit_type,
                  item_selling_price: rowdata.item_selling_price,
                  item_cost_price: rowdata.item_cost_price,
                  item_quantity: rowdata.item_quantity,
                  item_currency: rowdata.item_currency,
                  item_supplier: rowdata.item_supplier,
                  item_quantity_per_box: rowdata.item_quantity_per_box,
                  item_code: rowdata.item_code,
                  item_type: rowdata.item_type,
                  item_id: rowdata.item_id,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={
                  big === "PRODUCT"
                    ? Yup.object().shape({
                        item_name: Yup.string()
                          .required(
                            `${
                              big === "PRODUCT" ? `Product` : `Service`
                            } name is required`
                          )
                          .max(
                            200,
                            `${
                              big === "PRODUCT" ? `Product` : `Service`
                            } name must be less than 200 characters`
                          ),

                        item_selling_price: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Selling Price must be greater than zero"),
                        item_cost_price: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Cost Price must be greater than zero"),
                        item_quantity: Yup.number()
                          .nullable()
                          .typeError("Enter a valid number")
                          .positive("Must be greater than 0"),

                        item_quantity_per_box: Yup.number()
                          .typeError("Enter a valid number")
                          .positive("Must be greater than 0"),
                      })
                    : Yup.object().shape({
                        item_name: Yup.string()
                          .required(
                            `${
                              big === "PRODUCT" ? `Product` : `Service`
                            } name is required`
                          )
                          .max(
                            200,
                            `${
                              big === "PRODUCT" ? `Product` : `Service`
                            } name must be less than 200 characters`
                          ),

                        item_selling_price: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Selling Price must be greater than zero"),
                        item_cost_price: Yup.number()
                          .typeError("Enter a valid number")
                          .min(0, "Cost Price must be greater than zero"),
                      })
                }
                onSubmit={(values) => {
                  // console.log(values);
                  setDisablesave(true);

                  submitData(values);
                  // props.setproductData([]);
                }}
                onReset={() => {
                  //sending back to product/services page if pressed cancel
                  navigate(`/${small}s`);
                }}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  handleChange,
                  handleReset,
                  dirty,
                  validateForm,
                }) => (
                  <>
                    <Form
                      noValidate
                      onSubmit={async (e) => {
                        e.preventDefault();
                        const obj = await validateForm();
                        if (Object.keys(obj).length !== 0) {
                          setValidationError(true);
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      {errors.submit && (
                        <Alert className="my-3" variant="danger">
                          <div className="alert-message">{errors.submit}</div>
                        </Alert>
                      )}
                      <Card
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // backgroundColor: "white",
                          padding: "0rem 1.5rem 2rem 1.5rem",
                        }}
                        className="pt-2 pb-2 border-bottom "
                      >
                        <div>
                          <h2
                            className="mt-3 lead"
                            style={{
                              fontSize: "24px",
                              // , fontWeight: 100
                            }}
                          >
                            {truncateTextByWordCount(rowdata.item_name, 40)}
                          </h2>
                          <Breadcrumb
                            className="mb-0 lead"
                            style={{ fontSize: "13px" }}
                          >
                            <Breadcrumb.Item
                              onClick={() => navigate("/dashboard")}
                              style={{ cursor: "pointer" }}
                              active
                            >
                              Home
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Items</Breadcrumb.Item>
                            <Breadcrumb.Item
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                rowdata.item_type === "PRODUCT"
                                  ? navigate("/products")
                                  : navigate("/services")
                              }
                              active
                            >
                              {rowdata.item_type === "PRODUCT"
                                ? "Products"
                                : "Services"}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                              active
                              className="font-weight-bold"
                            >
                              {`Update Item`}
                            </Breadcrumb.Item>
                          </Breadcrumb>
                        </div>
                        <div>
                          {/* <Link to="/products/add-item">
                      <PlusSquare stroke="white" fill="#316284" size={35} />
                    </Link> */}
                          <Form.Group as={Row} className="">
                            <Col sm={10}>
                              <div className="custom-controls-stacked d-flex gap-2">
                                <Form.Check
                                  type="radio"
                                  id="radio1"
                                  name="item_type"
                                  label="Product"
                                  onChange={
                                    (handleChange,
                                    () => {
                                      mixpanel.track(
                                        `Type Product (Edit ${big.toLowerCase()} Web)`
                                      );
                                      setType("Product");
                                    })
                                  }
                                  defaultChecked={res.isProduct}
                                  value="PRODUCT"
                                  className="form-control-lg"
                                />
                                <Form.Check
                                  type="radio"
                                  id="radio2"
                                  value="SERVICE"
                                  onChange={
                                    (handleChange,
                                    () => {
                                      mixpanel.track(
                                        `Type Service (Edit ${big.toLowerCase()} Web)`
                                      );
                                      setType("Service");
                                    })
                                  }
                                  name="item_type"
                                  label="Service"
                                  className="form-control-lg"
                                  defaultChecked={res.isService}
                                />
                              </div>
                            </Col>
                          </Form.Group>
                        </div>
                      </Card>
                      {/* Card 1 Item Type*/}
                      {/* <Card>
                    <Card.Header className="pb-0">
                      <Card.Title className="mb-0">Item Type</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={10}>
                          <div className="custom-controls-stacked d-flex gap-2">
                            <Form.Check
                              type="radio"
                              id="radio1"
                              name="item_type"
                              label="Product"
                              onChange={
                                (handleChange,
                                () => {
                                  setType("Product");
                                })
                              }
                              defaultChecked={res.isProduct}
                              value="PRODUCT"
                              className="form-control-lg"
                            />
                            <Form.Check
                              type="radio"
                              id="radio2"
                              value="SERVICE"
                              onChange={
                                (handleChange,
                                () => {
                                  setType("Service");
                                })
                              }
                              name="item_type"
                              label="Service"
                              className="form-control-lg"
                              defaultChecked={res.isService}
                            />
                          </div>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Card> */}
                      {/* Card 2 Item Details */}
                      <div
                        // className="container"
                        style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}
                      >
                        <Row>
                          <Col lg="8" className="p-2">
                            <Card className="border rounded-0">
                              {/* <Card.Header className="pb-0">
                            <Card.Title className="mb-0">
                              Item Details
                            </Card.Title>
                          </Card.Header> */}
                              <Card.Body>
                                {/* <FloatingLabel
                              controlId="floatingInput"
                              label="Title "
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                isInvalid={errors.item_name}
                                name="item_name"
                                placeholder="text"
                                value={values.item_name}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.item_name}
                              </Form.Control.Feedback>
                            </FloatingLabel> */}
                                <h4
                                  style={{
                                    borderBottom: "1px solid lightgrey",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  {big === "PRODUCT"
                                    ? `Product Details`
                                    : `Service Details`}
                                </h4>
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ color: "black" }}>
                                    {big === "PRODUCT"
                                      ? `Product Name`
                                      : `Service Name`}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    name="item_name"
                                    placeholder={
                                      big === "PRODUCT"
                                        ? `Product Name`
                                        : `Service Name`
                                    }
                                    value={values.item_name}
                                    onChange={handleChange}
                                    isInvalid={errors.item_name}
                                    style={{ padding: "8px" }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.item_name}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                {/* <FloatingLabel
                              controlId="floatingInput"
                              label="Description "
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="description"
                                name="item_description"
                                value={values.item_description}
                                onChange={handleChange}
                              />
                            </FloatingLabel> */}
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ color: "black" }}>
                                    Description
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows="6"
                                    type="text"
                                    name="item_description"
                                    placeholder="Type something"
                                    value={values.item_description}
                                    onChange={handleChange}
                                    // isInvalid={errors.item_name}
                                    style={{ padding: "8px" }}
                                  />
                                  {/* <ReactQuill
                                theme="snow"
                                modules={{
                                  toolbar: false,
                                }}
                                placeholder="Type something"
                                name="item_description"
                                // value={values.item_description}
                                // onChange={handleChange}
                                value={desc}
                                onChange={(data) => setDesc(data)}
                              /> */}
                                </Form.Group>
                                {/* <FloatingLabel
                              controlId="floatingInput"
                              label="Item Code "
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="0"
                                name="item_code"
                                value={values.item_code}
                                onChange={handleChange}
                              />
                            </FloatingLabel> */}
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ color: "black" }}>
                                    {big === "PRODUCT"
                                      ? `Product Code`
                                      : `Service Code`}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="item_code"
                                    onKeyDown={handleKeyDown}
                                    placeholder={
                                      big === "PRODUCT"
                                        ? `Product Code`
                                        : `Service Code`
                                    }
                                    value={values.item_code}
                                    onChange={handleChange}
                                    style={{ padding: "8px" }}
                                  />
                                </Form.Group>
                                {big === "PRODUCT" ? (
                                  <>
                                    {/* <FloatingLabel
                                  controlId="floatingInput"
                                  label="Supplier "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="supplier"
                                    name="item_supplier"
                                    value={values.item_supplier}
                                    onChange={handleChange}
                                  />
                                </FloatingLabel> */}
                                    <Form.Group className="mb-3">
                                      <Form.Label style={{ color: "black" }}>
                                        Supplier
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        name="item_supplier"
                                        placeholder="Supplier"
                                        value={values.item_supplier}
                                        onChange={handleChange}
                                        style={{ padding: "8px" }}
                                      />
                                    </Form.Group>
                                    {/* <FloatingLabel
                                  controlId="floatingInput"
                                  label="Quantity Per Box "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="0"
                                    isInvalid={errors.item_quantity_per_box}
                                    name="item_quantity_per_box"
                                    value={values.item_quantity_per_box}
                                    onChange={handleChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.item_quantity_per_box}
                                  </Form.Control.Feedback>
                                </FloatingLabel> */}
                                    <Form.Group className="mb-3">
                                      <Form.Label style={{ color: "black" }}>
                                        Quantity Per Box
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        step="any"
                                        onKeyDown={handleKeyDown}
                                        min={0}
                                        name="item_quantity_per_box"
                                        placeholder="Quantity Per Box"
                                        value={values.item_quantity_per_box}
                                        onChange={handleChange}
                                        style={{ padding: "8px" }}
                                        isInvalid={
                                          !!errors.item_quantity_per_box
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.item_quantity_per_box}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                          {/* </div> */}
                          <Col lg="4" className="p-2">
                            {/* Card 3 Pricing and Inventory */}
                            {/* <div className="col-lg-4 p-2"> */}
                            <Card className="border rounded-0">
                              {/* <Card.Header className="pb-0">
                            <Card.Title className="mb-0">
                              {big === "PRODUCT"
                                ? `Pricing & Inventory`
                                : `Pricing`}
                            </Card.Title>
                          </Card.Header> */}
                              <Card.Body>
                                <h4
                                  style={{
                                    borderBottom: "1px solid lightgrey",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  {big === "PRODUCT"
                                    ? `Pricing & Inventory`
                                    : `Pricing`}
                                </h4>
                                {/* <FloatingLabel
                              controlId="floatingInput"
                              label={
                                big === "PRODUCT"
                                  ? `Selling Price`
                                  : `Service Charge`
                              }
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="0"
                                isInvalid={errors.item_selling_price}
                                name="item_selling_price"
                                value={values.item_selling_price}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.item_selling_price}
                              </Form.Control.Feedback>
                            </FloatingLabel> */}
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ color: "black" }}>
                                    {big === "PRODUCT"
                                      ? `Selling Price`
                                      : `Service Charge`}
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      {getSymbolFromCurrency(usercurrency)}
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      min={0}
                                      step="any"
                                      onKeyDown={handleKeyDown}
                                      name="item_selling_price"
                                      placeholder={
                                        big === "PRODUCT"
                                          ? `Selling Price`
                                          : `Service Charge`
                                      }
                                      value={values.item_selling_price}
                                      onChange={(event) => {
                                        if (
                                          event.target.value.replace(/\s/g, "")
                                            .length <= 10
                                        ) {
                                          handleChange(event);
                                        }
                                      }}
                                      style={{ padding: "8px" }}
                                      isInvalid={!!errors.item_selling_price}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.item_selling_price}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                                {/* <FloatingLabel
                              controlId="floatingInput"
                              label={
                                big === "PRODUCT"
                                  ? `Cost Price`
                                  : `Service Cost`
                              }
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="0"
                                isInvalid={errors.item_cost_price}
                                name="item_cost_price"
                                value={values.item_cost_price}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.item_cost_price}
                              </Form.Control.Feedback>
                            </FloatingLabel> */}
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ color: "black" }}>
                                    {big === "PRODUCT"
                                      ? `Cost Price`
                                      : `Service Cost`}
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      {getSymbolFromCurrency(usercurrency)}
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="item_cost_price"
                                      min={0}
                                      step="any"
                                      onKeyDown={handleKeyDown}
                                      placeholder={
                                        big === "PRODUCT"
                                          ? `Cost Price`
                                          : `Service Cost`
                                      }
                                      value={values.item_cost_price}
                                      onChange={(event) => {
                                        if (
                                          event.target.value.replace(/\s/g, "")
                                            .length <= 10
                                        ) {
                                          handleChange(event);
                                        }
                                      }}
                                      style={{ padding: "8px" }}
                                      isInvalid={!!errors.item_cost_price}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.item_cost_price}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                                {big === "PRODUCT" ? (
                                  <>
                                    {/* <FloatingLabel
                                  controlId="floatingInput"
                                  label="Inventory"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="0"
                                    isInvalid={errors.item_quantity}
                                    name="item_quantity"
                                    value={values.item_quantity}
                                    onChange={handleChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.item_quantity}
                                  </Form.Control.Feedback>
                                </FloatingLabel> */}
                                    <Form.Group className="mb-3">
                                      <Form.Label style={{ color: "black" }}>
                                        Inventory
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        min={0}
                                        isInvalid={!!errors.item_quantity}
                                        // step="any"
                                        onKeyDown={handleKeyDown}
                                        name="item_quantity"
                                        placeholder="Inventory"
                                        value={values.item_quantity}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.replace(
                                              /\s/g,
                                              ""
                                            ).length <= 10
                                          ) {
                                            handleChange(event);
                                          }
                                        }}
                                        // onChange={handleChange}
                                        style={{ padding: "8px" }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.item_quantity}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </>
                                ) : (
                                  <>
                                    {/* <FloatingLabel
                                  controlId="floatingInput"
                                  label="Unit Type "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="0"
                                    name="item_unit_type"
                                    value={values.item_unit_type}
                                    onChange={handleChange}
                                  />
                                </FloatingLabel> */}
                                    <Form.Group className="mb-3">
                                      <Form.Label style={{ color: "black" }}>
                                        Unit Type
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        step="any"
                                        onKeyDown={handleKeyDown}
                                        name="item_unit_type"
                                        placeholder="Unit Type"
                                        value={
                                          values.item_unit_type
                                            ? values.item_unit_type
                                            : ""
                                        }
                                        onChange={handleChange}
                                        style={{ padding: "8px" }}
                                      />
                                    </Form.Group>
                                  </>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        {/* </div> */}
                        {/* </div> */}
                        {/* Call to Action Button*/}
                        <div className="d-flex justify-content-between">
                          <div>
                            <Button
                              variant="danger"
                              size="lg"
                              onClick={() => {
                                mixpanel.track(
                                  `Delete (Edit ${big.toLowerCase()} Web)`,
                                  {
                                    id: rowdata.item_id,
                                  }
                                );
                                setDeleteData({
                                  item_id: rowdata.item_id,
                                  item_name: rowdata.item_name,
                                });
                                setOpenModals(true);
                              }}
                              className="me-1 mb-1 center"
                            >
                              Delete
                            </Button>
                            <Button
                              variant="secondary"
                              size="lg"
                              type="reset"
                              onClick={() => {
                                mixpanel.track(
                                  `Cancel (Edit ${big.toLowerCase()} Web)`
                                );
                                handleReset();
                              }}
                              className="me-1 mb-1 center"
                            >
                              Cancel
                            </Button>
                          </div>
                          <Button
                            variant="primary"
                            // disabled={!dirty}
                            size="lg"
                            type="submit"
                            onClick={() => {
                              console.log(values);
                            }}
                            className="me-1 mb-1 center"
                            disabled={disablesave}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
              <Modal
                show={validationError}
                onHide={() => setValidationError(false)}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Error
                    </h1>
                    <div
                      onClick={() => {
                        setValidationError(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <p style={{ margin: "15px 0 20px" }}>
                        There was an error saving the form. Please check the
                        messages in the form and try again.
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        onClick={() => {
                          setValidationError(false);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={openModals}
                onHide={() => setOpenModals(false)}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Delete {type}
                    </h1>
                    <div
                      onClick={() => {
                        setOpenModals(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        // flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <p
                        style={{ margin: "15px 0px 20px", textAlign: "center" }}
                      >
                        {`Are you sure you want to delete this ${
                          type === "Product" ? "product" : "service"
                        }?`}
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => setOpenModals(false)}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        disable={disabledelete}
                        onClick={() => {
                          setOpenModals(false);
                          deleteRecord(deleteData);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <Page404Authenticated />
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      )}
    </>
  );
};
export default InputComponent;
