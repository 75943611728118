import React, { useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { AtSign, ChevronRight, LogOut, Trello } from "react-feather";
import { ReactComponent as Logo } from "../../assets/img/logo1.svg";
import icon from "../../assets/img/illustrations/icon.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const Sidebar = ({ items = true }) => {
  // const [data, setData] = useState({});
  const [token, settoken] = useState("");
  const { isOpen } = useSidebar();

  const navigate = useNavigate();
  // const getData = (token) => {
  //   fetch("https://bestmate.us/api/business/get", {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setData(result);
  //         // setLoading(false);
  //         console.log(result, result.businessExists);
  //       },
  //       (error) => {
  //         console.error("Error fetching data: ", error);
  //       }
  //     );
  // };

  useEffect(async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    if (user) {
      console.log(user);
      let tokenss = await user.getIdToken();
      // setBusiness_email(user.email);
      settoken(tokenss);
      // getData(tokenss);
    }
    // });
  }, []);
  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar
          style={{
            display: "flex",
            flexDirection: "column",
            // marginBottom: 400,
            paddingBottom: 40,
          }}
        >
          <a
            className="sidebar sidebar-brand mb-0"
            href="/dashboard"
            onClick={() => {
              mixpanel.track("Clicked Bestmate (Web)");
            }}
            // style={{ margin: "30px 0 " }}
            style={{
              position: "sticky",
              top: "0",
              // backgroundColor: "pink",
              // borderBottom: "px solid",
              minWidth: "220px",
              maxWidth: "220px",
              zIndex: "20",
              // textAlign: "left",
              padding: "18.4px 20px 15px",
              display: "flex",
              // justifyContent: "flex-start",
              alignItems: "end",
              justifyContent: "center",
              // backgroundColor: "red",
            }}
          >
            {/* <Logo style={{ marginRight: 12 }} /> */}
            <img
              src={icon}
              alt="bestmate"
              style={{ height: 25, marginRight: 9, marginBottom: 8 }}
            />
            <span className="align-middle me-3">BestMate</span>
          </a>
          {/* {Object.keys(data).length !== 0 ? (
            <div
              style={{
                padding: "10px 0",
                marginBottom: "10px",
                backgroundColor: "#252633",
                display: "flex",
                flexDirection: "row",
                justifyContent: "centre",
                alignItems: "center",
                height: "106px",
                marginTop: "66.8px",
              }}
            >
              <div
                style={{
                  height: "50px",
                  minWidth: "50px",
                  borderRadius: "5px",
                  backgroundColor: "black",
                  margin: "0 10px",
                }}
              ></div>
              <div>
                <div style={{ color: "white", fontSize: "1.2rem" }}>
                  {data.business_name}
                </div>
                <div>{data.business_user_name}</div>
              </div>
            </div>
          ) : (
            <> </>
          )} */}

          <div
            className="align-middle"
            style={{
              padding: "10px 10px 10px 19px",
              margin: 10,
              backgroundColor: "#3f80ea",
              color: "white",
              borderRadius: 5,
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              alignItems: "center",
              marginTop: 15,
            }}
            onClick={() => {
              mixpanel.track("Getting Started (Web)");
              navigate("/gettingstarted");
            }}
          >
            <div>
              <Trello
                size={16}
                strokeWidth={2}
                style={{ marginRight: "0.95rem" }}
              />
              Getting Started
            </div>
            <ChevronRight
              size={16}
              strokeWidth={2}
              // style={{ marginRight: "0.95rem" }}
            />
          </div>
          <SidebarNav
            items={items}
            // style={{ marginTop: "250px", paddingBottom: "600px" }}
          />
          {/* <div
            className="align-middle"
            style={{
              padding: "10px 26px",
              // backgroundColor: "",
              color: "black",
              // position: "fixed",
              width: "220px",
              cursor: "pointer",
            }}
            onClick={() => {
              console.log("opening");
              window.tidioChatApi.open();
            }}
          >
            <LogOut
              size={16}
              strokeWidth={2}
              style={{ marginRight: "0.95rem" }}
            />
            Sign Out
          </div> */}
          {/* {!!showFooter && <SidebarFooter />} */}
        </PerfectScrollbar>
        <div
          className="align-middle"
          style={{
            padding: "10px 26px",
            backgroundColor: "#3f80ea",
            color: "white",
            position: "fixed",
            // width: "100vh",
            width: "220px",
            // flex: 1,
            bottom: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            console.log("opening");
            window.tidioChatApi.open();
            mixpanel.track("Sidebar Support (Web)");
          }}
        >
          <AtSign
            size={16}
            strokeWidth={2}
            style={{ marginRight: "0.95rem" }}
          />
          Support
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
