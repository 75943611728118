import React, { useState } from "react";

import { Button, Card, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";

import { Calendar, Filter, RefreshCw } from "react-feather";
import { Navigate, useNavigate } from "react-router-dom";
import empty_invoice from "../../../assets/img/icons/empty_invoice.png";
import empty_inventory from "../../../assets/img/icons/empty_inventory.png";
import empty_partner from "../../../assets/img/icons/empty_partner.png";
import empty_transaction from "../../../assets/img/icons/empty_transaction.png";
import schedule from "../../../assets/img/icons/schedule.png";
import moment from "moment";

const Header = () => {
  return (
    <Row className="mb-2 mb-xl-3" style={{ marginBottom: 3 }}>
      <Col
        xs="auto"
        className="d-none d-sm-block"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Dashboard</h3>
        {/* <div>sadf</div> */}
      </Col>

      <Col
        xs="auto"
        className="ms-auto text-end mt-n1"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Dropdown className="d-inline me-2">
          <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
            <Calendar className="feather align-middle mt-n1" /> Today
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Action</Dropdown.Item>
            <Dropdown.Item>Another Action</Dropdown.Item>
            <Dropdown.Item>Something else here</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>Seperated link</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <div className="d-inline " style={{ marginRight: 14 }}>
          {moment(new Date()).format("MMM DD, YYYY")}
        </div>

        {/* <Button variant="primary" className="shadow-sm me-1">
          <Filter className="feather" />
        </Button>
        <Button variant="primary" className="shadow-sm me-1">
          <RefreshCw className="feather" />
        </Button> */}
        {/* <OffcanvasPlacementSingle
          key={1}
          placement={"end"}
          name={`Add Shortcuts`}
        /> */}
      </Col>
    </Row>
  );
};
const OffcanvasPlacementSingle = ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="me-2">
        {name}
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Shortcuts</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc. */}
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              // // backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/invoices/add")}
            >
              <img
                src={empty_invoice}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Invoice</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/products/add-item")}
            >
              <img
                src={empty_inventory}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Item</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/customer/add-customer")}
            >
              <img
                src={empty_partner}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Customer</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/estimates/add")}
            >
              <img
                src={empty_invoice}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Estimate</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/schedule")}
            >
              <img
                src={schedule}
                style={{ height: "50px" }}
                alt="schedule"
              ></img>
              <p style={{ margin: "0" }}>Add Booking</p>
            </Card>
            <Card
              style={{
                // backgroundColor: "white",
                height: "100px",
                minWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/supplier/add-supplier")}
            >
              <img
                src={empty_partner}
                style={{ height: "50px" }}
                alt="empty_invoice"
              ></img>
              <p style={{ margin: "0" }}>Add Supplier</p>
            </Card>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
