import React, { useEffect, useMemo, useState } from "react";
import { Card, Table, Dropdown, Button } from "react-bootstrap";
import { useTable, useGlobalFilter } from "react-table";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import { GlobalFilter } from "../../pages/GlobalFilter";
import { MoreHorizontal } from "react-feather";
import moment from "moment";
import { useSumwithDiscountAndTax } from "../../../utils/useSumwithDiscountandTax";
import { useNavigate } from "react-router-dom";
import empty_invoice from "../../../assets/img/icons/empty_invoice.png";
import schedule from "../../../assets/img/icons/schedule.png";

// import useAuth from "../../../hooks/useAuth";
import mixpanel from "mixpanel-browser";
import { useCurrencyFormating } from "../../../utils/useCurrencyFormating";
import useAuth from "../../../hooks/useAuth";
import PermissionRequireModal from "../../../components/PermissionRequireModal";

const FilteringTable = (props) => {
  // const { usercurrency } = useAuth();
  // let usercurrency = localStorage.getItem("currency");
  // ? localStorage.getItem("currency")
  // : "USD";
  // const Data = props.dataa;
  // const customerData = Data.filter((elem) => elem.partner_type === "CUSTOMER");
  const { currencyFormater } = useCurrencyFormating();
  const { userPermission } = useAuth();
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false);
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";

  // console.log(props.dataa);
  const { sumCalculation } = useSumwithDiscountAndTax();
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  let InvoiceTotal = 0;
  let usercurrency = props.props.usercurrency;
  console.log(usercurrency);
  const COLUMNS =
    props.props.type === "Invoice"
      ? [
          {
            Header: `${props.props.type} #`,
            accessor: "invoice_number",
            Cell: ({ value, row }) => {
              return (
                <div style={{ maxWidth: 50 }}>
                  {truncateTextByWordCount(`${value}`, 10)}
                </div>
              );
            },
          },
          {
            Header: "Name",

            accessor: (elem) =>
              `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
            Cell: ({ value, row }) => {
              return (
                <div style={{ maxWidth: 150 }}>
                  {truncateTextByWordCount(`${value}`, 15)}
                </div>
              );
            },
          },
          // {
          //   Header: "Partner Type",
          //   accessor: "",
          //   Cell: ({ row }) => {
          //     if (row.original.invoice_partner_type === "CUSTOMER") {
          //       return <span className="text-success">Customer</span>;
          //     } else if (row.original.invoice_partner_type === "SUPPLIER") {
          //       return <span className="text-danger">Supplier</span>;
          //     } else {
          //       return <span classname="text-primary">Don't know</span>;
          //     }
          //   },
          // },
          {
            Header: "Company Name",
            accessor: "",
            Cell: ({ row }) => {
              if (row.original.invoice_partner.partner_company_name) {
                return (
                  <div style={{ maxWidth: 150 }}>
                    {truncateTextByWordCount(
                      `${row.original.invoice_partner.partner_company_name}`,
                      15
                    )}
                  </div>
                );
              } else {
                return "Individual";
              }
            },
          },
          {
            Header: () => (
              <div
                style={{ textAlign: "end" }}
              >{`${props.props.type} Total`}</div>
            ),
            accessor: "invoice_grand_total",
            Cell: ({ value, row }) => {
              console.log(value, row);
              let invoice = row.original;
              var sum = 0;
              var price_list = 1;
              if (
                invoice &&
                invoice.invoice_partner &&
                invoice.invoice_partner.partner_price_list &&
                invoice.invoice_partner.partner_price_list
                  .price_list_multiplier &&
                !isNaN(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
              ) {
                price_list = Number(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                );
              }
              for (var i = 0; i < invoice.invoice_order.length; i++) {
                if (invoice.invoice_partner_type === "CUSTOMER") {
                  // console.log(
                  //   sum,
                  //   invoice.invoice_order[i].item_quantity
                  //     ? Number(invoice.invoice_order[i].item_quantity)
                  //     : 1,
                  //   invoice.invoice_order[i].item_selling_price &&
                  //     !isNaN(invoice.invoice_order[i].item_selling_price)
                  //     ? Number(invoice.invoice_order[i].item_selling_price)
                  //     : 0,
                  //   price_list ? price_list : 1,
                  //   sum +
                  //     (invoice.invoice_order[i].item_quantity
                  //       ? Number(invoice.invoice_order[i].item_quantity)
                  //       : 1) *
                  //       (invoice.invoice_order[i].item_selling_price
                  //         ? Number(invoice.invoice_order[i].item_selling_price)
                  //         : 0) *
                  //       (price_list ? price_list : 1)
                  // );
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_selling_price &&
                      !isNaN(invoice.invoice_order[i].item_selling_price)
                        ? Number(invoice.invoice_order[i].item_selling_price)
                        : 0) *
                      (price_list && !isNaN(price_list) ? price_list : 1);
                  console.log(sum);
                } else {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_cost_price &&
                      !isNaN(invoice.invoice_order[i].item_cost_price)
                        ? Number(invoice.invoice_order[i].item_cost_price)
                        : 0);
                }
              }
              // var sumWithDiscountAndTax =
              //   (sum - sum * invoice.invoice_discount_rate) *
              //   (1 + invoice.invoice_tax_rate);
              var sumWithDiscountAndTax = sumCalculation(sum, invoice);
              InvoiceTotal = sumWithDiscountAndTax;
              console.log(price_list, sum, sumWithDiscountAndTax);
              return (
                <div style={{ textAlign: "right" }}>
                  {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
                    style: "currency",
                    currency:
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency,
                  })} */}
                  {truncateTextByWordCount(
                    currencyFormater(
                      sumWithDiscountAndTax,
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency
                    ),
                    25
                  )}
                </div>
              );
            },
          },
          {
            Header: () => <div style={{ textAlign: "end" }}>{`Pending`}</div>,
            accessor: "invoice_settled_amount",
            Cell: ({ value, row }) => {
              const num = InvoiceTotal - Number(value);
              if (Number.isNaN(num)) {
                return <div style={{ textAlign: "right" }}>{"-"}</div>;
              } else {
                return (
                  <div style={{ textAlign: "right" }}>
                    {/* {num.toLocaleString("en-US", {
                      style: "currency",
                      currency:
                        row.original && row.original.invoice_currency_code
                          ? row.original.invoice_currency_code
                          : usercurrency,
                    })} */}
                    {truncateTextByWordCount(
                      currencyFormater(
                        num,
                        row.original && row.original.invoice_currency_code
                          ? row.original.invoice_currency_code
                          : usercurrency
                      ),
                      25
                    )}
                  </div>
                );
              }
            },
          },
          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);
              if (props.props.type === "Invoice") {
                if (
                  Number(row.original.invoice_grand_total) -
                    Number(row.original.invoice_settled_amount) <=
                  0
                  // row.original.invoice_payment_status === "PAID"
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Paid
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Unpaid
                      </div>
                    </div>
                  );
                }
              } else if (props.props.type === "Estimate") {
                if (row.original.invoice_status === "ACCEPTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </div>
                    </div>
                  );
                } else if (row.original.invoice_status === "CONVERTED") {
                  if (
                    row.original.invoice_children &&
                    row.original.invoice_children.some(
                      (e) => e.invoice_workflow_type === "INVOICE"
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1a7bb8",
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 65,
                            textAlign: "center",
                          }}
                        >
                          Invoiced
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 65,
                            textAlign: "center",
                          }}
                        >
                          {/* Converted to Job */}
                          Booking Created
                        </div>
                      </div>
                    );
                  }
                } else if (row.original.invoice_status === "OPEN") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  );
                }
              } else if (props.props.type === "Booking") {
                if (row.original.invoice_status === "CONVERTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#1a7bb8",
                          backgroundColor: "#3f80ea",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Invoiced
                      </div>
                    </div>
                  );
                } else {
                  if (
                    row.original.invoice_time_status === "PAST" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#807e7f",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Past
                        </div>
                      </div>
                    );
                  } else if (
                    row.original.invoice_time_status === "UPCOMING" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Upcoming
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "4px",
                            color: "grey",
                            border: "1px solid #71BE1D",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 75,
                            textAlign: "center",
                          }}
                        >
                          Unscheduled
                        </div>
                      </div>
                    );
                  }
                }
              }
            },
          },
        ]
      : props.props.type === "Estimate"
      ? [
          {
            Header: `${props.props.type} #`,
            accessor: "invoice_number",
            Cell: ({ value, row }) => {
              return (
                <div style={{ maxWidth: 50 }}>
                  {truncateTextByWordCount(`${value}`, 10)}
                </div>
              );
            },
          },
          {
            Header: "Name",
            accessor: (elem) =>
              `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
            Cell: ({ value, row }) => {
              return (
                <div style={{ maxWidth: 150 }}>
                  {truncateTextByWordCount(`${value}`, 15)}
                </div>
              );
            },
          },
          // {
          //   Header: "Partner Type",
          //   accessor: "",
          //   Cell: ({ row }) => {
          //     if (row.original.invoice_partner_type === "CUSTOMER") {
          //       return <span className="text-success">Customer</span>;
          //     } else if (row.original.invoice_partner_type === "SUPPLIER") {
          //       return <span className="text-danger">Supplier</span>;
          //     } else {
          //       return <span classname="text-primary">Don't know</span>;
          //     }
          //   },
          // },
          {
            Header: "Company Name",
            accessor: "",
            Cell: ({ row }) => {
              if (row.original.invoice_partner.partner_company_name) {
                return (
                  <div style={{ maxWidth: 150 }}>
                    {truncateTextByWordCount(
                      `${row.original.invoice_partner.partner_company_name}`,
                      15
                    )}
                  </div>
                );
              } else {
                return "Individual";
              }
            },
          },
          {
            Header: () => (
              <div
                style={{ textAlign: "end" }}
              >{`${props.props.type} Total`}</div>
            ),
            accessor: "invoice_grand_total",
            Cell: ({ value, row }) => {
              console.log(value, row);
              let invoice = row.original;
              var sum = 0;
              var price_list = 1;
              if (
                invoice &&
                invoice.invoice_partner &&
                invoice.invoice_partner.partner_price_list &&
                invoice.invoice_partner.partner_price_list
                  .price_list_multiplier &&
                !isNaN(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
              ) {
                price_list = Number(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                );
              }
              for (var i = 0; i < invoice.invoice_order.length; i++) {
                if (invoice.invoice_partner_type === "CUSTOMER") {
                  // console.log(
                  //   sum,
                  //   invoice.invoice_order[i].item_quantity
                  //     ? Number(invoice.invoice_order[i].item_quantity)
                  //     : 1,
                  //   invoice.invoice_order[i].item_selling_price &&
                  //     !isNaN(invoice.invoice_order[i].item_selling_price)
                  //     ? Number(invoice.invoice_order[i].item_selling_price)
                  //     : 0,
                  //   price_list ? price_list : 1,
                  //   sum +
                  //     (invoice.invoice_order[i].item_quantity
                  //       ? Number(invoice.invoice_order[i].item_quantity)
                  //       : 1) *
                  //       (invoice.invoice_order[i].item_selling_price
                  //         ? Number(invoice.invoice_order[i].item_selling_price)
                  //         : 0) *
                  //       (price_list ? price_list : 1)
                  // );
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_selling_price &&
                      !isNaN(invoice.invoice_order[i].item_selling_price)
                        ? Number(invoice.invoice_order[i].item_selling_price)
                        : 0) *
                      (price_list && !isNaN(price_list) ? price_list : 1);
                  console.log(sum);
                } else {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_cost_price &&
                      !isNaN(invoice.invoice_order[i].item_cost_price)
                        ? Number(invoice.invoice_order[i].item_cost_price)
                        : 0);
                }
              }
              // var sumWithDiscountAndTax =
              //   (sum - sum * invoice.invoice_discount_rate) *
              //   (1 + invoice.invoice_tax_rate);
              var sumWithDiscountAndTax = sumCalculation(sum, invoice);
              InvoiceTotal = sumWithDiscountAndTax;
              console.log(price_list, sum, sumWithDiscountAndTax);
              return (
                <div style={{ textAlign: "right" }}>
                  {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
                    style: "currency",
                    currency:
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency,
                  })} */}
                  {truncateTextByWordCount(
                    currencyFormater(
                      sumWithDiscountAndTax,
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency
                    ),
                    25
                  )}
                </div>
              );
            },
          },

          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);
              if (props.props.type === "Invoice") {
                if (
                  Number(row.original.invoice_grand_total) -
                    Number(row.original.invoice_settled_amount) <=
                  0
                  // row.original.invoice_payment_status === "PAID"
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Paid
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Unpaid
                      </div>
                    </div>
                  );
                }
              } else if (props.props.type === "Estimate") {
                if (row.original.invoice_status === "ACCEPTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </div>
                    </div>
                  );
                } else if (row.original.invoice_status === "CONVERTED") {
                  if (
                    row.original.invoice_children &&
                    row.original.invoice_children.some(
                      (e) => e.invoice_workflow_type === "INVOICE"
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1a7bb8",
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 65,
                            textAlign: "center",
                          }}
                        >
                          Invoiced
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 65,
                            textAlign: "center",
                          }}
                        >
                          {/* Converted to Job */}
                          Booking Created
                        </div>
                      </div>
                    );
                  }
                } else if (row.original.invoice_status === "OPEN") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  );
                }
              } else if (props.props.type === "Booking") {
                if (row.original.invoice_status === "CONVERTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#1a7bb8",
                          backgroundColor: "#3f80ea",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Invoiced
                      </div>
                    </div>
                  );
                } else {
                  if (
                    row.original.invoice_time_status === "PAST" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#807e7f",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Past
                        </div>
                      </div>
                    );
                  } else if (
                    row.original.invoice_time_status === "UPCOMING" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Upcoming
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "4px",
                            color: "grey",
                            border: "1px solid #71BE1D",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 75,
                            textAlign: "center",
                          }}
                        >
                          Unscheduled
                        </div>
                      </div>
                    );
                  }
                }
              }
            },
          },
        ]
      : props.props.type === "Request"
      ? [
          {
            Header: `${props.props.type} #`,
            accessor: "invoice_number",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: 0, fontSize: 12.5, maxWidth: 50 }}>
                  {truncateTextByWordCount(`${value}`, 10)}
                </div>
              );
            },
          },
          {
            Header: "Start Time",
            accessor: "datetime",
            Cell: ({ row }) => {
              if (
                row.original.invoice_visits &&
                row.original.invoice_visits.length !== 0
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                      marginLeft: 0,
                      fontSize: 12.5,
                    }}
                  >
                    <div style={{}}>
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("hh:mm a DD MMM")}`}
                      {/* <div>{` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`}</div> */}
                    </div>
                  </div>
                );
              } else {
                return <div style={{ marginLeft: 0 }}>-</div>;
              }
            },
          },

          {
            Header: "Name",
            accessor: (elem) =>
              `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
            Cell: ({ value, row }) => {
              return (
                <div style={{ maxWidth: 150 }}>
                  {truncateTextByWordCount(`${value}`, 15)}
                </div>
              );
            },
          },
          {
            Header: "Company Name",
            accessor: "",
            Cell: ({ row }) => {
              if (row.original.invoice_partner.partner_company_name) {
                return (
                  <div style={{ maxWidth: 150 }}>
                    {truncateTextByWordCount(
                      `${row.original.invoice_partner.partner_company_name}`,
                      15
                    )}
                  </div>
                );
              } else {
                return "Individual";
              }
            },
          },
          // {
          //   Header: () => (
          //     <div
          //       style={{ textAlign: "end" }}
          //     >{`${props.props.type} Total`}</div>
          //   ),
          //   accessor: "invoice_grand_total",
          //   Cell: ({ value, row }) => {
          //     console.log(value, row);
          //     let invoice = row.original;
          //     var sum = 0;
          //     var price_list = 1;
          //     if (
          //       invoice &&
          //       invoice.invoice_partner &&
          //       invoice.invoice_partner.partner_price_list &&
          //       invoice.invoice_partner.partner_price_list
          //         .price_list_multiplier &&
          //       !isNaN(
          //         invoice.invoice_partner.partner_price_list
          //           .price_list_multiplier
          //       )
          //     ) {
          //       price_list = Number(
          //         invoice.invoice_partner.partner_price_list
          //           .price_list_multiplier
          //       );
          //     }
          //     for (var i = 0; i < invoice.invoice_order.length; i++) {
          //       if (invoice.invoice_partner_type === "CUSTOMER") {
          //         // console.log(
          //         //   sum,
          //         //   invoice.invoice_order[i].item_quantity
          //         //     ? Number(invoice.invoice_order[i].item_quantity)
          //         //     : 1,
          //         //   invoice.invoice_order[i].item_selling_price &&
          //         //     !isNaN(invoice.invoice_order[i].item_selling_price)
          //         //     ? Number(invoice.invoice_order[i].item_selling_price)
          //         //     : 0,
          //         //   price_list ? price_list : 1,
          //         //   sum +
          //         //     (invoice.invoice_order[i].item_quantity
          //         //       ? Number(invoice.invoice_order[i].item_quantity)
          //         //       : 1) *
          //         //       (invoice.invoice_order[i].item_selling_price
          //         //         ? Number(invoice.invoice_order[i].item_selling_price)
          //         //         : 0) *
          //         //       (price_list ? price_list : 1)
          //         // );
          //         sum =
          //           sum +
          //           (invoice.invoice_order[i].item_quantity
          //             ? Number(invoice.invoice_order[i].item_quantity)
          //             : 1) *
          //             (invoice.invoice_order[i].item_selling_price &&
          //             !isNaN(invoice.invoice_order[i].item_selling_price)
          //               ? Number(invoice.invoice_order[i].item_selling_price)
          //               : 0) *
          //             (price_list && !isNaN(price_list) ? price_list : 1);
          //         console.log(sum);
          //       } else {
          //         sum =
          //           sum +
          //           (invoice.invoice_order[i].item_quantity
          //             ? Number(invoice.invoice_order[i].item_quantity)
          //             : 1) *
          //             (invoice.invoice_order[i].item_cost_price &&
          //             isNaN(invoice.invoice_order[i].item_cost_price)
          //               ? Number(invoice.invoice_order[i].item_cost_price)
          //               : 0);
          //       }
          //     }
          //     // var sumWithDiscountAndTax =
          //     // (sum - sum * invoice.invoice_discount_rate) *
          //     // (1 + invoice.invoice_tax_rate);
          //     var sumWithDiscountAndTax = sumCalculation(sum, invoice);
          //     InvoiceTotal = sumWithDiscountAndTax;
          //     console.log(price_list, sum, sumWithDiscountAndTax);
          //     return (
          //       <div style={{ textAlign: "right" }}>
          //         {sumWithDiscountAndTax.toLocaleString("en-US", {
          //           style: "currency",
          //           currency:
          //             invoice && invoice.invoice_currency_code
          //               ? invoice.invoice_currency_code
          //               : usercurrency,
          //         })}
          //       </div>
          //     );
          //   },
          // },

          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);

              return (
                <>
                  {row.original.invoice_status === "CONVERTED" ? (
                    <>
                      {/* <div
                    style={{
                      // width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        // backgroundColor: "#1a7bb8",
                        backgroundColor: "#3f80ea",
                        padding: "4px",
                        marginLeft: "5px",
                        color: "white",
                        borderRadius: "5px",
                        // marginBottom: "13.2px",
                        fontSize: "10px",
                        fontWeight: "bold",
                        width: 75,
                        textAlign: "center",
                      }}
                    > */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            marginLeft: "5px",
                            color: "white",
                            borderRadius: "5px",
                            // marginBottom: "13.2px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Converted
                        </div>
                      </div>
                    </>
                  ) : row.original.invoice_status === "ARCHIVED" ? (
                    <div
                      style={{
                        // width: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#807e7f",
                          padding: "4px",
                          // marginBottom: "13.2px",
                          marginLeft: "5px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Archived
                      </div>
                    </div>
                  ) : row.original.invoice_partner_type === "LEAD" ? (
                    <div
                      style={{
                        // width: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#71BE1D",
                          padding: "4px",
                          color: "white",
                          // marginBottom: "13.2px",
                          marginLeft: "5px",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Lead
                      </div>
                    </div>
                  ) : row.original.invoice_status === "OPEN" ? (
                    <div
                      style={{
                        // width: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,

                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            },
          },
        ]
      : [
          {
            Header: `${props.props.type} #`,
            accessor: "invoice_number",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: 0, fontSize: 12.5, maxWidth: 50 }}>
                  {truncateTextByWordCount(`${value}`, 10)}
                </div>
              );
            },
          },
          {
            Header: "Start Time",
            accessor: "datetime",
            Cell: ({ row }) => {
              if (
                row.original.invoice_visits &&
                row.original.invoice_visits.length !== 0
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                      marginLeft: 0,
                      fontSize: 12.5,
                    }}
                  >
                    <div style={{}}>
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("hh:mm a DD MMM")}`}
                      {/* <div>{` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`}</div> */}
                    </div>
                  </div>
                );
              } else {
                return <div style={{ marginLeft: 0 }}>-</div>;
              }
            },
          },

          {
            Header: "Name",
            accessor: (elem) =>
              `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
            Cell: ({ value, row }) => {
              return (
                <div style={{ maxWidth: 150 }}>
                  {truncateTextByWordCount(`${value}`, 15)}
                </div>
              );
            },
          },

          {
            Header: "Company Name",
            accessor: "",
            Cell: ({ row }) => {
              if (row.original.invoice_partner.partner_company_name) {
                return (
                  <div style={{ maxWidth: 150 }}>
                    {truncateTextByWordCount(
                      `${row.original.invoice_partner.partner_company_name}`,
                      15
                    )}
                  </div>
                );
              } else {
                return "Individual";
              }
            },
          },
          {
            Header: () => (
              <div
                style={{ textAlign: "end" }}
              >{`${props.props.type} Total`}</div>
            ),
            accessor: "invoice_grand_total",
            Cell: ({ value, row }) => {
              console.log(value, row);
              let invoice = row.original;
              var sum = 0;
              var price_list = 1;
              if (
                invoice &&
                invoice.invoice_partner &&
                invoice.invoice_partner.partner_price_list &&
                invoice.invoice_partner.partner_price_list
                  .price_list_multiplier &&
                !isNaN(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
              ) {
                price_list = Number(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                );
              }
              for (var i = 0; i < invoice.invoice_order.length; i++) {
                if (invoice.invoice_partner_type === "CUSTOMER") {
                  // console.log(
                  //   sum,
                  //   invoice.invoice_order[i].item_quantity
                  //     ? Number(invoice.invoice_order[i].item_quantity)
                  //     : 1,
                  //   invoice.invoice_order[i].item_selling_price &&
                  //     !isNaN(invoice.invoice_order[i].item_selling_price)
                  //     ? Number(invoice.invoice_order[i].item_selling_price)
                  //     : 0,
                  //   price_list ? price_list : 1,
                  //   sum +
                  //     (invoice.invoice_order[i].item_quantity
                  //       ? Number(invoice.invoice_order[i].item_quantity)
                  //       : 1) *
                  //       (invoice.invoice_order[i].item_selling_price
                  //         ? Number(invoice.invoice_order[i].item_selling_price)
                  //         : 0) *
                  //       (price_list ? price_list : 1)
                  // );
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_selling_price &&
                      !isNaN(invoice.invoice_order[i].item_selling_price)
                        ? Number(invoice.invoice_order[i].item_selling_price)
                        : 0) *
                      (price_list && !isNaN(price_list) ? price_list : 1);
                  console.log(sum);
                } else {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_cost_price &&
                      !isNaN(invoice.invoice_order[i].item_cost_price)
                        ? Number(invoice.invoice_order[i].item_cost_price)
                        : 0);
                }
              }
              // var sumWithDiscountAndTax =
              // (sum - sum * invoice.invoice_discount_rate) *
              // (1 + invoice.invoice_tax_rate);
              var sumWithDiscountAndTax = sumCalculation(sum, invoice);
              InvoiceTotal = sumWithDiscountAndTax;
              console.log(price_list, sum, sumWithDiscountAndTax);
              return (
                <div style={{ textAlign: "right" }}>
                  {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
                    style: "currency",
                    currency:
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency,
                  })} */}
                  {truncateTextByWordCount(
                    currencyFormater(
                      sumWithDiscountAndTax,
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency
                    ),
                    25
                  )}
                </div>
              );
            },
          },

          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);
              if (props.props.type === "Invoice") {
                if (
                  Number(row.original.invoice_grand_total) -
                    Number(row.original.invoice_settled_amount) <=
                  0
                  // row.original.invoice_payment_status === "PAID"
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Paid
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Unpaid
                      </div>
                    </div>
                  );
                }
              } else if (props.props.type === "Estimate") {
                if (row.original.invoice_status === "ACCEPTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </div>
                    </div>
                  );
                } else if (row.original.invoice_status === "CONVERTED") {
                  if (
                    row.original.invoice_children &&
                    row.original.invoice_children.some(
                      (e) => e.invoice_workflow_type === "INVOICE"
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1a7bb8",
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 65,
                            textAlign: "center",
                          }}
                        >
                          Invoiced
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 65,
                            textAlign: "center",
                          }}
                        >
                          {/* Converted to Job */}
                          Booking Created
                        </div>
                      </div>
                    );
                  }
                } else if (row.original.invoice_status === "OPEN") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  );
                }
              } else if (props.props.type === "Booking") {
                if (row.original.invoice_status === "CONVERTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#1a7bb8",
                          backgroundColor: "#3f80ea",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Invoiced
                      </div>
                    </div>
                  );
                } else {
                  if (
                    row.original.invoice_time_status === "PAST" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#807e7f",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Past
                        </div>
                      </div>
                    );
                  } else if (
                    row.original.invoice_time_status === "UPCOMING" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Upcoming
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "4px",
                            color: "grey",
                            border: "1px solid #71BE1D",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 75,
                            textAlign: "center",
                          }}
                        >
                          Unscheduled
                        </div>
                      </div>
                    );
                  }
                }
              }
            },
          },
        ];

  const columns = useMemo(() => COLUMNS, []);
  const data = props.data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  // const { globalFilter } = state;
  const navigate = useNavigate();

  return (
    <>
      <div style={{ overflowX: "scroll", overflowY: "hidden" }}>
        <Table
          striped
          bordered
          size="sm"
          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={(e) => {
                    // alert("Edit action performed on " + row.values.price_list_name);
                    // console.log(row);
                    // props.show(true);
                    // props.rowData(row);
                    e.stopPropagation();
                    if (props.props.type === "Request") {
                      mixpanel.track(
                        "Individual Request Row (Invoice List Dashboard Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("/requests/" + row.original.invoice_id, {
                        state: { row: row.original },
                      });
                    } else if (props.props.type === "Invoice") {
                      mixpanel.track(
                        "Individual Invoice Row (Invoice List Dashboard Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("/invoices/" + row.original.invoice_id, {
                        state: { row: row.original },
                      });
                    } else if (props.props.type === "Estimate") {
                      mixpanel.track(
                        "Individual Estimate Row (Estimate List Dashboard Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("/estimates/" + row.original.invoice_id, {
                        state: { row: row.original },
                      });
                    } else if (props.props.type === "Booking") {
                      mixpanel.track(
                        "Individual Booking Row (Booking List Dashboard Web)",
                        {
                          ...row.original,
                        }
                      );
                      navigate("/bookings/" + row.original.invoice_id, {
                        state: { row: row.original },
                      });
                    }
                  }}
                  style={{ cursor: "pointer" }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ color: "black", fontSize: "12.7px" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {props.data.length === 0 ? (
        // <div
        //   style={{
        //     height: 150,
        //     display: "flex",
        //     width: "100%",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <div>No {props.type} added</div>
        // </div>
        <div
          className="d-flex justify-content-center align-items-center p-3 flex-column "
          id={"Emptybox"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (props.props.type === "Request") {
              if (requestPermission.includes("ADD")) {
                mixpanel.track("Add Invoice (Invoice List Dashboard Web)");
                navigate("/requests/add");
              } else {
                setShowAccessDeniedModal(true);
              }
            } else if (props.props.type === "Invoice") {
              if (invoicePermission.includes("ADD")) {
                mixpanel.track("Add Invoice (Invoice List Dashboard Web)");
                navigate("/invoices/add");
              } else {
                setShowAccessDeniedModal(true);
              }
            } else if (props.props.type === "Booking") {
              if (bookingPermission.includes("ADD")) {
                mixpanel.track("Add Booking (Booking List Dashboard Web)");
                navigate("/bookings/add");
              } else {
                setShowAccessDeniedModal(true);
              }
            } else if (props.props.type === "Estimate") {
              if (estimatePermission.includes("ADD")) {
                mixpanel.track("Add Estimate (Estimate List Dashboard Web)");
                navigate("/estimates/add");
              } else {
                setShowAccessDeniedModal(true);
              }
            }
          }}
        >
          <p style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>
            {props.props.type === "Request"
              ? "Manage Request"
              : props.props.type === "Invoice"
              ? "Create an invoice and get paid"
              : props.props.type === "Booking"
              ? "Schedule a Booking"
              : "Create an estimate"}
          </p>

          <img
            src={props.props.type === "Booking" ? schedule : empty_invoice}
            style={{ height: "60px", marginTop: 10, marginBottom: 10 }}
            alt="empty_invoice"
          ></img>

          {(props.props.type === "Request" &&
            requestPermission.includes("ADD")) ||
          (props.props.type === "Invoice" &&
            invoicePermission.includes("ADD")) ||
          (props.props.type === "Booking" &&
            bookingPermission.includes("ADD")) ||
          (props.props.type === "Estimate" &&
            estimatePermission.includes("ADD")) ? (
            <Button
              onClick={() => {
                if (props.props.type === "Request") {
                  mixpanel.track("Add Request (Invoice List Dashboard Web)");
                  navigate("/requests/add");
                } else if (props.props.type === "Invoice") {
                  mixpanel.track("Add Invoice (Invoice List Dashboard Web)");
                  navigate("/invoices/add");
                } else if (props.props.type === "Booking") {
                  mixpanel.track("Add Booking (Booking List Dashboard Web)");
                  navigate("/bookings/add");
                } else if (props.props.type === "Estimate") {
                  mixpanel.track("Add Estimate (Estimate List Dashboard Web)");
                  navigate("/estimates/add");
                }
              }}
              style={{
                padding: 5,
                paddingLeft: 15,
                paddingRight: 15,
                fontWeight: "bold",
              }}
            >
              Add {props.props.type}
            </Button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <PermissionRequireModal
        showAccessDeniedModal={showAccessDeniedModal}
        setShowAccessDeniedModal={setShowAccessDeniedModal}
      />
    </>
  );
};

const Projects = (props) => {
  const [data, setData] = useState(props.invoicedata);
  const [token, settoken] = useState("");
  const navigate = useNavigate();

  // const { token } = useAuth();

  useEffect(async () => {
    // useEffect(() => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // getData(tokenss);
      // getData(props.invoicedata);
    }
    // });
    // }, []);
    // getData();
  }, [props.usercurrency]);

  console.log(data);
  const invoiceData = data
    .filter((elem) => elem.invoice_workflow_type === "INVOICE")
    .slice(0, 7);

  const estimateData = data
    .filter((elem) => elem.invoice_workflow_type === "ESTIMATE")
    .slice(0, 7);
  const orderData = data
    .filter((elem) => elem.invoice_workflow_type === "ORDER")
    .slice(0, 7);
  const requestData = data
    .filter((elem) => elem.invoice_workflow_type === "REQUEST")
    .slice(0, 7);

  console.log(invoiceData);

  return (
    <>
      <Card className="flex-fill w-100 mt-2  border rounded-0">
        {/* <Card.Header style={{ borderBottom: "1px solid #edeff1" }}>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">{`Latest ${props.type}`}</Card.Title>
      </Card.Header> */}
        <FilteringTable
          data={
            props.type === "Invoice"
              ? invoiceData
              : props.type === "Estimate"
              ? estimateData
              : props.type === "Request"
              ? requestData
              : orderData
          }
          props={props}
        />
        {/* <div>ASdfasdf</div> */}
      </Card>
      {/* {invoiceData.length === 0 ? (
        // <div
        //   style={{
        //     height: 150,
        //     display: "flex",
        //     width: "100%",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <div>No {props.type} added</div>
        // </div>
        <div
          className="d-flex justify-content-center align-items-center p-3 flex-column "
          id={"Emptybox"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (props.type === "Invoice") {
              mixpanel.track("Add Invoice (Invoice List Dashboard)");
              navigate("/invoices/add");
            } else if (props.type === "Job") {
              mixpanel.track("Add Job (Job List Dashboard)");
              navigate("/jobs/add");
            } else if (props.type === "Estimate") {
              mixpanel.track("Add Estimate (Estimate List Dashboard)");
              navigate("/estimates/add");
            }
          }}
        >
          <p style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>
            {props.type === "Invoice"
              ? "Create an invoice and get paid"
              : props.type === "Job"
              ? "Schedule a job"
              : "Create an estimate"}
          </p>

          <img
            src={props.type === "Job" ? schedule : empty_invoice}
            style={{ height: "60px", marginTop: 10, marginBottom: 10 }}
            alt="empty_invoice"
          ></img>

          <Button
            onClick={() => {
              if (props.type === "Invoice") {
                mixpanel.track("Add Invoice (Invoice List Dashboard)");
                navigate("/invoices/add");
              } else if (props.type === "Job") {
                mixpanel.track("Add Job (Job List Dashboard)");
                navigate("/jobs/add");
              } else if (props.type === "Estimate") {
                mixpanel.track("Add Estimate (Estimate List Dashboard)");
                navigate("/estimates/add");
              }
            }}
            style={{ padding: 5, paddingLeft: 15, paddingRight: 15, fontWeight: "bold" }}
          >
            Add {props.type}
          </Button>
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
};
export default Projects;
