import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import "../assets/css/App.css";
import mixpanel from "mixpanel-browser";
import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import * as Yup from "yup";
import "../assets/css/AddSchedulemodal.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Selectmodal from "./Selectmodal";
import Newpartnermodal from "./Newpatnermodal";
import NotyfContext from "../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import useSidebar from "../hooks/useSidebar";
import { usePremium } from "../contexts/PremiumContext";
import useAuth from "../hooks/useAuth";
import PermissionRequireModal from "./PermissionRequireModal";
import { logError } from "../utils/useErrorlogging";
import Select from "react-select";

const Newschedulemodal = (props) => {
  const { isPremium, isFree, isTrial, useIsFree, moreInfoCount } = usePremium();
  const [savemodalloading, setSavemodalloading] = useState(false);
  const [savemodalopen, setsavemodalopen] = useState("first");
  const [partners, setPartners] = useState({});
  const [disablesave, setDisablesave] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchterm, setsearchtem] = useState("");
  const [token, settoken] = useState("");
  const [modloading, setModloading] = useState(true);
  const [createnewmodal, setCreatenewmodal] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const navigate = useNavigate();
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [globalValidationError, setGlobalValidationError] = useState(false);
  const { isTeamAccount, userPermission, userData } = useAuth();

  let schedulePermission =
    Object.keys(userPermission).includes("schedule") &&
    Object.keys(userPermission.schedule).includes("permission_key")
      ? userPermission.schedule.permission_key
      : "";
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  const [teamMember, setTeamMember] = useState(
    schedulePermission.includes("ALL") ? [] : [userData]
  );
  useEffect(() => {
    if (props.selecteddate) {
      let Start = props.selecteddate.startStr
        ? props.selecteddate.startStr
        : "";
      console.log(dateStart);
      let End = props.selecteddate.endStr ? props.selecteddate.endStr : "";
      // console.log(dateStart, dateEnd);
      // let startTime = "";
      // let endTime = "";
      if (Start.length > 10) {
        setStartTime(Start.match(/\d\d:\d\d/)[0]);
        setEndTime(End.match(/\d\d:\d\d/)[0]);
        setDateStart(Start.substring(0, 10));
        setDateEnd(End.substring(0, 10));
      } else {
        setStartTime("");
        setEndTime("");
        setDateStart(Start.substring(0, 10));
        setDateEnd(End.substring(0, 10));
      }
    } else {
      setStartTime("");
      setEndTime("");
      setDateStart("");
      setDateEnd("");
    }
  }, [props.selecteddate]);

  useEffect(() => {
    if (props.createnewmodal) {
      setDisablesave(false);
      setTeamMember(schedulePermission.includes("ALL") ? [] : [userData]);
    } else {
      setTeamMember([]);
    }
  }, [props.createnewmodal]);

  const addAppointment = async (appointmentData) => {
    delete appointmentData["appointment_start_date"];
    delete appointmentData["appointment_end_date"];
    delete appointmentData["reminder"];
    console.log(appointmentData);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        appointmentData.team = true;
      } else {
        delete appointmentData.team;
      }
      if (teamMember && teamMember.length !== 0) {
        appointmentData.multi_appointment_assignee_uid = teamMember.map(
          (elem) => elem.team_member_uid
        );
        appointmentData.multi_appointment_assignee_ref_id = teamMember.map(
          (elem) => elem.team_member_reference_id
        );
        appointmentData.multi_appointment_assignee = teamMember;
      } else {
        delete appointmentData.multi_appointment_assignee_uid;
        delete appointmentData.multi_appointment_assignee_ref_id;
        delete appointmentData.multi_appointment_assignee;
      }
      fetch("https://bestmate.us/api/appointments/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(appointmentData),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            // props.fetchData("0", "1672511400000", props.token);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Event scheduled successfully",
              ripple: "true",
              dismissible: "true",
            });
            setsavemodalopen("first");
            props.setSelecteddate(null);
            props.setCreatenewmodal(false);
            setGlobalValidationError(false);
            props.setLoading(true);
            setDisablesave(false);

            // props.setEventsData([]);
            // navigate("/schedule");
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setsavemodalopen("first");
            props.setSelecteddate(null);
            props.setCreatenewmodal(false);
            setGlobalValidationError(false);
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Schedule Add Modal Web)`, {
            //   error: "schedule add api",
            // });
            logError({
              error: "fetch error",
              api: "schedule add api",
              component: "Schedule Add Modal Web",
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
          mixpanel.track(` Error (Schedule Add Modal Web)`, {
            error: err.message,
            stack: err.stack,
          });
        });
    }
  };

  const fetchPartners = async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch(
        "https://bestmate.us/api/partners/findAll" +
          `${isTeamAccount ? "?team=true" : ""}`,
        {
          // fetch(
          //   "https://bestmate.us/api/partners/findAll" +
          //     `${isOwner ? "" : "?team=true"}`,
          // {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let data = [
              {
                label: "Customer",
                options: [],
              },
              {
                label: "Supplier",
                options: [],
              },
              {
                label: "Lead",
                options: [],
              },
            ];
            result.map((elem) => {
              const tempData = {
                label: `${elem.partner_first_name} ${elem.partner_last_name}`,
                value: elem,
              };
              if (elem.partner_type === "CUSTOMER") {
                data["0"].options.push(tempData);
              } else if (elem.partner_type === "SUPPLIER") {
                data["1"].options.push(tempData);
              } else if (elem.partner_type === "LEAD") {
                data["2"].options.push(tempData);
              }
            });
            // console.log(data);
            setOptions(data);
            setModloading(false);
            // props.setLoading(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    }
    // });
  };
  console.log(props.selecteddate);
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <>
      {schedulePermission.includes("ADD") ? (
        <>
          {moreInfoCount < 3 ||
          isPremium ||
          (useIsFree && isFree) ||
          isTrial ? (
            <>
              <Modal
                show={props.createnewmodal}
                onHide={() => {
                  mixpanel.track("Close (Add Schedule Modal Web)");

                  setsavemodalopen("first");
                  props.setSelecteddate(null);
                  props.setCreatenewmodal(false);
                  setGlobalValidationError(false);
                }}
                centered
                size="md"
                className="createnewschedule"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#f4f4f4",
                        height: 56,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 24px",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          color: "black",
                          margin: 0,
                        }}
                      >
                        {`Create new ${props.type}`}
                      </h1>
                      <div
                        onClick={() => {
                          mixpanel.track("Close (Add Schedule Modal Web)");

                          setsavemodalopen("first");
                          props.setSelecteddate(null);
                          props.setCreatenewmodal(false);
                          setGlobalValidationError(false);
                        }}
                      >
                        <X
                          size={24}
                          strokeWidth={2}
                          stroke="black"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>

                    {savemodalloading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "550px" }}
                      >
                        <Spinner
                          animation="border"
                          variant="dark"
                          className="me-2"
                        />
                      </div>
                    ) : (
                      <div style={{ padding: "10px 24px" }}>
                        <Formik
                          validateOnChange={false}
                          validateOnBlur={false}
                          enableReinitialize={true}
                          validationSchema={Yup.object().shape({
                            appointment_name:
                              Yup.string().required("Name is required"),
                            appointment_partner: Yup.string().required(
                              "Partner is required"
                            ),
                            appointment_start_date: Yup.date().required(
                              "Start Date is required"
                            ),
                            appointment_end_date: Yup.date()
                              .min(
                                Yup.ref("appointment_start_date"),
                                "End date can't be before start date"
                              )
                              .required("End Date is required"),
                            appointment_start_time: Yup.string().required(
                              "Start Time is required"
                            ),
                            appointment_end_time: Yup.string()
                              .required("End Time is required")
                              .test(
                                "is-start-date-time-valid",
                                "Start date and time must be before end date and time",
                                function (value) {
                                  const {
                                    appointment_start_date,
                                    appointment_start_time,
                                    appointment_end_date,
                                  } = this.parent;
                                  console.log(
                                    appointment_start_date,
                                    appointment_start_time,
                                    appointment_end_date
                                    // appointment_end_time
                                  );

                                  if (
                                    appointment_start_date &&
                                    !isNaN(appointment_start_date) &&
                                    appointment_end_date &&
                                    !isNaN(appointment_end_date) &&
                                    appointment_start_time &&
                                    value
                                  ) {
                                    const [shours, sminutes] =
                                      appointment_start_time
                                        .split(":")
                                        .map(Number);

                                    appointment_start_date.setHours(
                                      shours,
                                      sminutes,
                                      0,
                                      0
                                    );

                                    const stimeInMilliseconds =
                                      appointment_start_date.getTime();

                                    console.log(stimeInMilliseconds);

                                    const [ehours, eminutes] = value
                                      .split(":")
                                      .map(Number);

                                    appointment_end_date.setHours(
                                      ehours,
                                      eminutes,
                                      0,
                                      0
                                    );

                                    const etimeInMilliseconds =
                                      appointment_end_date.getTime();

                                    console.log(etimeInMilliseconds, value);

                                    console.log(
                                      stimeInMilliseconds < etimeInMilliseconds
                                    );
                                    return (
                                      stimeInMilliseconds < etimeInMilliseconds
                                    );
                                  } else {
                                    console.log("not passing");
                                    return false;
                                  }
                                }
                              ),
                          })}
                          onSubmit={(values) => {
                            setDisablesave(true);

                            // console.log(values);
                            values["appointment_partner"] = partners;
                            // values["appointment_partner_id"] = partners.partner_id;
                            values["appointment_zone_offset"] = -(
                              new Date(
                                values.appointment_start_date
                              ).getTimezoneOffset() * 60
                            );
                            var Start_Str = values[
                              "appointment_start_date"
                            ].concat(" ", values["appointment_start_time"]);
                            var End_Str = values["appointment_end_date"].concat(
                              " ",
                              values["appointment_end_time"]
                            );
                            values["appointment_start_time"] = new Date(
                              Start_Str
                            ).getTime();
                            values["appointment_end_time"] = new Date(
                              End_Str
                            ).getTime();
                            values["appointment_reminders"] = values.reminder
                              ? [
                                  {
                                    value: 1,
                                  },
                                ]
                              : [];
                            // if(values.appointment_notes){

                            // }
                            let {
                              appointment_notes,
                              appointment_description,
                              ...all
                            } = values;
                            if (values.appointment_notes) {
                              all["appointment_notes"] =
                                values.appointment_notes;
                            }
                            if (values.appointment_description) {
                              all["appointment_description"] =
                                values.appointment_description;
                            }
                            console.log(values);
                            mixpanel.track(
                              "Add Schedule (Add Schedule Modal Web)",
                              {
                                ...values,
                              }
                            );

                            addAppointment(all);
                          }}
                          initialValues={{
                            appointment_name: "",
                            appointment_description: "",
                            appointment_notes: "",
                            appointment_start_date: dateStart,
                            appointment_end_date: dateEnd,
                            appointment_start_time: startTime,
                            appointment_end_time: endTime,
                            appointment_partner: "",
                            appointment_send_confirmation: true,
                            reminder: true,
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            handleReset,
                            setFieldValue,
                            validateForm,
                          }) => (
                            <Form
                              noValidate
                              onSubmit={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const obj = await validateForm();
                                console.log(obj);
                                if (Object.keys(obj).length !== 0) {
                                  setGlobalValidationError(true);
                                } else {
                                  setGlobalValidationError(false);
                                  handleSubmit();
                                }
                              }}
                              // onKeyDown={(keyEvent) => {
                              //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                              //     keyEvent.preventDefault();
                              //   }
                              // }}
                            >
                              {errors.submit && (
                                <Alert className="my-3" variant="danger">
                                  <div className="alert-message">
                                    {errors.submit}
                                  </div>
                                </Alert>
                              )}
                              <div
                                className="createnewhover"
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  // backgroundColor: "pink",
                                  padding: "10px 0",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                onClick={() => setsavemodalopen("first")}
                              >
                                <div>{`Customer & Event Details`}</div>
                                {savemodalopen === "first" ? (
                                  <ChevronUp
                                    size={24}
                                    strokeWidth={2}
                                    stroke="#3679e8"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <ChevronDown
                                    size={24}
                                    strokeWidth={2}
                                    stroke="#3679e8"
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                              {savemodalopen === "first" ? (
                                <>
                                  <div style={{ margin: "5px 0" }}>
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Customer"
                                      className="mb-3"
                                      onKeyDown={handleKeyDown}
                                      onClick={() => {
                                        if (
                                          partnerPermission &&
                                          !partnerPermission.includes("LIMITED")
                                        ) {
                                          console.log("ASDFasdfasdfasdf");

                                          mixpanel.track(
                                            "Select Partner (Add Schedule Modal Web)"
                                          );
                                          setModloading(true);
                                          fetchPartners();
                                          setOpenModals(true);
                                        }
                                      }}
                                    >
                                      <Form.Control
                                        // disabled={true}
                                        type="text"
                                        name="appointment_partner"
                                        placeholder="text"
                                        value={values.appointment_partner}
                                        isInvalid={errors.appointment_partner}
                                        style={{
                                          backgroundColor: "white",
                                          cursor:
                                            partnerPermission &&
                                            !partnerPermission.includes(
                                              "LIMITED"
                                            )
                                              ? "pointer"
                                              : "not-allowed",
                                        }}
                                        // onClick={() => {
                                        //   console.log("ASDFasdfasdfasdf");

                                        //   // mixpanel.track(
                                        //   //   "Select Partner (Add Schedule Modal Web)"
                                        //   // );
                                        //   setModloading(true);
                                        //   fetchPartners();
                                        //   setOpenModals(true);
                                        // }}
                                        // onChange={handleChange}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.appointment_partner}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Event Name "
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        isInvalid={errors.appointment_name}
                                        name="appointment_name"
                                        placeholder="text"
                                        value={values.appointment_name}
                                        onChange={handleChange}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.appointment_name}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Event Description "
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        isInvalid={
                                          errors.appointment_description
                                        }
                                        name="appointment_description"
                                        placeholder="text"
                                        value={values.appointment_description}
                                        onChange={handleChange}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.appointment_description}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Internal Notes "
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        isInvalid={errors.appointment_notes}
                                        name="appointment_notes"
                                        placeholder="text"
                                        value={values.appointment_notes}
                                        onChange={handleChange}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.appointment_notes}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                    {schedulePermission.includes("ALL") ? (
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        className="mb-3"
                                      >
                                        {/* <Form.Select
                                          aria-label="Floating label select example"
                                          name="category"
                                          onKeyDown={handleKeyDown}
                                          onChange={(event) => {
                                            const selectedId =
                                              event.target.value;
                                            if (selectedId) {
                                              const selectedMember =
                                                props.teamMemberList.find(
                                                  (member) => {
                                                    console.log(
                                                      member,
                                                      member.team_member_email,
                                                      selectedId,
                                                      event
                                                    );
                                                    return (
                                                      member.team_member_email ===
                                                      selectedId
                                                    );
                                                  }
                                                );
                                              console.log(selectedMember);
                                              setTeamMember(selectedMember);
                                            } else {
                                              setTeamMember({});
                                            }
                                          }}
                                          value={teamMember.team_member_email}
                                        >
                                          <option
                                            disabled={
                                              Object.keys(teamMember).length ===
                                              0
                                                ? false
                                                : true
                                            }
                                            value={""}
                                          >
                                            Select Team Member
                                          </option>

                                          {props.teamMemberList.map((elem) => (
                                            <option
                                              value={elem.team_member_email}
                                            >
                                              {elem.team_member_name}
                                            </option>
                                          ))}
                                        </Form.Select> */}
                                        <Select
                                          isMulti
                                          options={props.teamMemberList.map(
                                            (elem) => ({
                                              value: elem.team_member_email,
                                              label: elem.team_member_name,
                                            })
                                          )}
                                          onChange={(selectedOptions) => {
                                            console.log(selectedOptions);
                                            const selectedMembers =
                                              selectedOptions.map((option) => {
                                                return props.teamMemberList.find(
                                                  (member) =>
                                                    member.team_member_email ===
                                                    option.value
                                                );
                                              });
                                            console.log(selectedMembers);
                                            setTeamMember(selectedMembers);
                                          }}
                                          // value={teamMember.map((member) => ({
                                          //   value: member.team_member_email,
                                          //   label: member.team_member_name,
                                          // }))}
                                          placeholder="Select Team Member"
                                        />
                                      </FloatingLabel>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <hr style={{ margin: 0 }} />

                              <div
                                className="createnewhover"
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  padding: "10px 0",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                onClick={() => setsavemodalopen("second")}
                              >
                                <div>Event Date & Time</div>
                                {savemodalopen === "second" ? (
                                  <ChevronUp
                                    size={24}
                                    strokeWidth={2}
                                    stroke="#3679e8"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <ChevronDown
                                    size={24}
                                    strokeWidth={2}
                                    stroke="#3679e8"
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                              {savemodalopen === "second" ? (
                                <>
                                  <div style={{ margin: "5px 0" }}>
                                    <Row className="g-2">
                                      <Col md>
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Start Date "
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="date"
                                            onKeyDown={handleKeyDown}
                                            className="calendar-input"
                                            isInvalid={
                                              errors.appointment_start_date
                                            }
                                            required
                                            name="appointment_start_date"
                                            placeholder="text"
                                            value={
                                              values.appointment_start_date
                                            }
                                            onChange={handleChange}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.appointment_start_date}
                                          </Form.Control.Feedback>
                                        </FloatingLabel>
                                      </Col>
                                      <Col md>
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Start Time "
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="time"
                                            onKeyDown={handleKeyDown}
                                            className="calendar-input"
                                            isInvalid={
                                              errors.appointment_start_time
                                            }
                                            name="appointment_start_time"
                                            placeholder="text"
                                            value={
                                              values.appointment_start_time
                                            }
                                            onChange={handleChange}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.appointment_start_time}
                                          </Form.Control.Feedback>
                                        </FloatingLabel>
                                      </Col>
                                    </Row>
                                    <Row className="g-2">
                                      <Col md>
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="End Date "
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="date"
                                            onKeyDown={handleKeyDown}
                                            className="calendar-input"
                                            isInvalid={
                                              errors.appointment_end_date
                                            }
                                            required
                                            name="appointment_end_date"
                                            placeholder="text"
                                            value={values.appointment_end_date}
                                            onChange={handleChange}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.appointment_end_date}
                                          </Form.Control.Feedback>
                                        </FloatingLabel>
                                      </Col>
                                      <Col md>
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="End Time "
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            onKeyDown={handleKeyDown}
                                            type="time"
                                            className="calendar-input"
                                            isInvalid={
                                              errors.appointment_end_time
                                            }
                                            name="appointment_end_time"
                                            placeholder="text"
                                            value={values.appointment_end_time}
                                            onChange={handleChange}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.appointment_end_time}
                                          </Form.Control.Feedback>
                                        </FloatingLabel>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div
                                    style={{
                                      padding: "0 0px",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Card.Title className="mb-0">
                                      Email Communication
                                    </Card.Title>

                                    <Row
                                      className="g-2"
                                      style={{ padding: "0 5px", marginTop: 1 }}
                                    >
                                      <Form.Check
                                        id="checkbox1"
                                        label="Send confirmation email to customer"
                                        name="appointment_send_confirmation"
                                        style={{}}
                                        onChange={handleChange}
                                        defaultChecked={
                                          values.appointment_send_confirmation
                                        }
                                        type="checkbox"
                                      />
                                    </Row>
                                    <Row
                                      className="g-2"
                                      style={{ padding: "0 5px" }}
                                    >
                                      <Form.Check
                                        id="checkbox2"
                                        name="reminder"
                                        label="Send reminder email to customer (one day before)"
                                        style={{}}
                                        onChange={handleChange}
                                        defaultChecked={values.reminder}
                                        // value={1}
                                        type="checkbox"
                                      />
                                    </Row>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <hr style={{ margin: 0 }} />
                              {globalValidationError ? (
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <AlertCircle
                                    size={15}
                                    strokeWidth={2}
                                    stroke="#d8534e"
                                  />
                                  <div
                                    style={{
                                      color: "#d8534e",
                                      fontSize: 11,
                                      fontWeight: "400",
                                      marginLeft: 5,
                                    }}
                                  >
                                    There was an error saving the form. Please
                                    check the messages above and try again.
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: 10,
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    marginRight: 5,
                                    padding: "5px 10px",
                                    backgroundColor: "white",
                                    borderWidth: 1,
                                  }}
                                  onClick={() => {
                                    mixpanel.track(
                                      "Close (Add Schedule Modal Web)"
                                    );
                                    setsavemodalopen("first");
                                    props.setSelecteddate(null);
                                    props.setCreatenewmodal(false);
                                    setGlobalValidationError(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  style={{
                                    padding: "5px 10px",
                                    backgroundColor: "#3679e8",
                                    borderWidth: 0,
                                    color: "white",
                                  }}
                                  type="submit"
                                  disabled={disablesave}
                                >
                                  {`Create ${
                                    props.type.charAt(0).toUpperCase() +
                                    props.type.slice(1)
                                  }`}
                                </button>
                              </div>
                              <Selectmodal
                                type={"customer"}
                                partner={""}
                                pricelist={""}
                                index={0}
                                openModals={openModals}
                                setOpenModals={setOpenModals}
                                modloading={modloading}
                                searchterm={searchterm}
                                setsearchtem={setsearchtem}
                                fetchPriceList={() => {}}
                                setsavemodalopen={() => {}}
                                setCreatenewmodal={setCreatenewmodal}
                                options={options}
                                setFieldValue={setFieldValue}
                                setPartners={setPartners}
                                data={{}}
                                setData={() => {}}
                              />
                              <Newpartnermodal
                                type={"customer"}
                                createnewmodal={createnewmodal}
                                setCreatenewmodal={setCreatenewmodal}
                                setFieldValue={setFieldValue}
                                setPartners={setPartners}
                                data={{}}
                                setData={() => {}}
                                // setCreatenewselectedplist={setCreatenewselectedplist}
                              />
                            </Form>
                          )}
                        </Formik>
                      </div>
                    )}
                  </>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <>
              <Modal
                show={props.createnewmodal}
                onHide={() => {
                  setsavemodalopen("first");
                  props.setSelecteddate(null);
                  props.setCreatenewmodal(false);
                  mixpanel.track(`Close (Add Schedule Modal Web)`);
                }}
                centered
                size="sm"
                className="createnewschedule"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div style={{}}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#f4f4f4",
                        height: 46,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 24px",
                      }}
                    >
                      {isTeamAccount ? (
                        <h1
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "black",
                            margin: 0,
                          }}
                        >
                          {`Access Denied`}
                        </h1>
                      ) : (
                        <h1
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "black",
                            margin: 0,
                          }}
                        >
                          {`Upgrade to Premium Plan`}
                        </h1>
                      )}
                      <div
                        onClick={() => {
                          setsavemodalopen("first");
                          props.setSelecteddate(null);
                          props.setCreatenewmodal(false);
                          mixpanel.track(`Close (Add Schedule Modal Web)`);
                        }}
                      >
                        <X
                          size={24}
                          strokeWidth={2}
                          stroke="black"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div style={{ padding: "10px 24px 0 24px" }}>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "20px 5px",
                        }}
                      >
                        {isTeamAccount ? (
                          <p
                            style={{
                              margin: 0,
                              // fontSize: "13px",
                              fontWeight: "500",
                              // textAlign: "center",
                            }}
                          >
                            Please ask owner/admin to update subscription or
                            contact Bestmate support.
                          </p>
                        ) : (
                          <p
                            style={{
                              margin: 0,
                              // fontSize: "13px",
                              fontWeight: "500",

                              textAlign: "center",
                            }}
                          >
                            Your Free Trial has ended. Upgrade to a Paid Plan to
                            continue using Bestmate today
                          </p>
                        )}
                      </div>

                      {!isTeamAccount ? (
                        <>
                          <hr style={{ margin: 0 }} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "7px 0",
                            }}
                          >
                            <button
                              style={{
                                border: "1px solid #407FEA",
                                cursor: "pointer",
                                color: "#407FEA",
                                padding: "3px 10px",
                                backgroundColor: "white",
                              }}
                              onClick={() => {
                                mixpanel.track(
                                  `Upgrade Now (Add Schedule Modal Web)`
                                );
                                navigate("/subscription");
                              }}
                            >
                              Upgrade Now
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      ) : (
        <>
          <PermissionRequireModal
            showAccessDeniedModal={props.createnewmodal}
            setShowAccessDeniedModal={props.setCreatenewmodal}
          />
        </>
      )}
    </>
  );
};
export default Newschedulemodal;
