import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { MapPin, Plus, Save, Sliders, User, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";
import useSidebar from "../../hooks/useSidebar";
import getSymbolFromCurrency from "currency-symbol-map";
import Big from "big.js";

import mixpanel from "mixpanel-browser";
import { logError } from "../../utils/useErrorlogging";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
var handler = (big) => {
  let isProduct;
  let isService;
  if (big === "PRODUCT") {
    isProduct = true;
    isService = false;
  } else if (big === "SERVICE") {
    isProduct = false;
    isService = true;
  }
  return { isProduct, isService };
};
// FormRow components consists of the form to add a new item
const FormRow = (props) => {
  const { token } = useAuth();
  const notyf = useContext(NotyfContext);
  const [type, setType] = useState(props.props.type);
  const [desc, setDesc] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [res, setRes] = useState(handler(props.props.type.toUpperCase()));
  const { isOpen } = useSidebar();
  let big = type.toUpperCase();
  let small = type.toLowerCase();
  const [disablesave, setDisablesave] = useState(false);
  const { isTeamAccount } = useAuth();
  useEffect(() => {
    setType(props.props.type);
    setRes(handler(props.props.type.toUpperCase()));
  }, [props.props.type]);
  let usercurrency = localStorage.getItem("currency") || "USD";
  // console.log(type, big, small);

  //function which is used to intialise the type of item

  const navigate = useNavigate();

  const submitData = async (data) => {
    setDisablesave(true);
    mixpanel.track(`Save (Add ${big.toLowerCase()} Web)`, {
      ...data,
    });
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        data.team = true;
      } else {
        delete data.team;
      }
      fetch("https://bestmate.us/api/items/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            console.log(res);
            navigate(`/${small}s`);

            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Item added successfully",
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Add ${big.toLowerCase()} Web)`, {
            //   error: "item create api",
            // });
            logError({
              error: "fetch error",
              api: "item create api",
              component: `Add ${big.toLowerCase()} Web`,
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
          // mixpanel.track(`Error (Add ${big.toLowerCase()} Web)`, {
          //   error: err,
          // });
          logError({
            error: err,
            component: `Add ${big.toLowerCase()} Web`,
          });
        });
    }
  };

  // submitHandler function is used to arrange data before sending to backend
  const submitHandler = (elem) => {
    // setDisablesave(true);
    const data = {
      // item_name: capitalizeFirstLetter(elem.itemName),
      // item_description: elem.description,
      // // item_description: desc,
      // item_unit_type: elem.unit,
      // item_selling_price: elem.sell.toString(),
      // item_cost_price: elem.cost.toString(),
      // item_code: elem.code,
      item_type: big,
      // item_currency: usercurrency,
      // item_supplier: elem.supplier,
      // item_quantity: elem.quantity.toString(),
      // item_quantity_per_box: elem.quantityperbox.toString(),
    };
    if (elem.itemName) {
      data.item_name = capitalizeFirstLetter(elem.itemName);
    }
    if (elem.description) {
      data.item_description = elem.description;
    }
    if (elem.unit) {
      data.item_description = elem.unit;
    }
    if (elem.sell.toString()) {
      data.item_selling_price = elem.sell.toString();
    }
    if (elem.cost.toString()) {
      data.item_cost_price = elem.cost.toString();
    }
    if (elem.code) {
      data.item_code = elem.code;
    }
    if (elem.supplier) {
      data.item_supplier = elem.supplier;
    }
    if (elem.quantity.toString()) {
      data.item_quantity = elem.quantity.toString();
    }
    if (elem.quantityperbox.toString()) {
      data.item_quantity_per_box = elem.quantityperbox.toString();
    }

    // console.log(data);
    // const bigNumber = new Big(elem.quantity);
    // const formatted = bigNumber.toString();
    // const fullNumber = bigNumber.toFixed();
    // console.log(elem.quantity, bigNumber, formatted, fullNumber);
    submitData(data);
  };

  const cancelHandler = () => {
    //sending back to product/services page if pressed cancel
    navigate(`/${small}s`);
  };
  // useEffect(() => {
  //   let res = handler(big);
  // }, [type]);
  useEffect(() => {
    mixpanel.track(`On Add ${big.toLowerCase()} (Web)`);
    // fetchPriceList();
    // console.log(res);
  }, []);
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  console.log(big);
  return (
    <>
      <Formik
        validationSchema={
          big === "PRODUCT"
            ? Yup.object().shape({
                itemName: Yup.string()
                  .required(
                    `${
                      big === "PRODUCT" ? `Product` : `Service`
                    } name is required`
                  )
                  .max(
                    200,
                    `${
                      big === "PRODUCT" ? `Product` : `Service`
                    } name must be less than 200 characters`
                  ),
                sell: Yup.number()
                  .typeError("Enter a valid number")
                  .min(0, "Number must be greater than zero"),
                cost: Yup.number()
                  .typeError("Enter a valid number")
                  .min(0, "Number must be greater than zero"),
                quantity: Yup.number()
                  .nullable()
                  .typeError("Enter a valid number")
                  .positive("Must be greater than 0"),

                quantityperbox: Yup.number()
                  .typeError("Enter a valid number")
                  .positive("Must be greater than 0"),
              })
            : Yup.object().shape({
                itemName: Yup.string()
                  .required(
                    `${
                      big === "PRODUCT" ? `Product` : `Service`
                    } name is required`
                  )
                  .max(
                    200,
                    `${
                      big === "PRODUCT" ? `Product` : `Service`
                    } name must be less than 200 characters`
                  ),
                sell: Yup.number()
                  .typeError("Enter a valid number")
                  .min(0, "Number must be greater than zero"),
                cost: Yup.number()
                  .typeError("Enter a valid number")
                  .min(0, "Number must be greater than zero"),
              })
        }
        onSubmit={(elem) => submitHandler(elem)}
        initialValues={{
          itemName: "",
          description: "",
          unit: "",
          sell: "",
          cost: "",
          code: "",
          supplier: "",
          quantity: "",
          quantityperbox: "",
          type: big,
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          validateForm,
        }) => (
          <Form
            noValidate
            onSubmit={async (e) => {
              e.preventDefault();
              const obj = await validateForm();
              if (Object.keys(obj).length !== 0) {
                setValidationError(true);
              } else {
                handleSubmit();
              }
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "white",
                padding: "0rem 1.5rem 2rem 1.5rem",
              }}
              className="pt-2 pb-2 border-bottom "
            >
              <div>
                <h2
                  className="mt-3 lead"
                  style={{
                    fontSize: "24px",
                    // , fontWeight: 100
                  }}
                >
                  {/* {rowdata.item_name} */}New Item
                </h2>
                <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                  <Breadcrumb.Item
                    onClick={() => navigate("/dashboard")}
                    style={{ cursor: "pointer" }}
                    active
                  >
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Items</Breadcrumb.Item>
                  <Breadcrumb.Item
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      props.props.type === "Product"
                        ? navigate("/products")
                        : navigate("/services")
                    }
                    active
                  >
                    {props.props.type === "Product" ? "Products" : "Services"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active className="font-weight-bold">
                    {/* {rowdata.item_name} */} New Item
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div>
                {/* <Link to="/products/add-item">
                      <PlusSquare stroke="white" fill="#316284" size={35} />
                    </Link> */}
                <Form.Group as={Row}>
                  <Col sm={10}>
                    <div className="custom-controls-stacked d-flex gap-2">
                      <Form.Check
                        type="radio"
                        id="radio1"
                        name="type"
                        label="Product"
                        onChange={
                          (handleChange,
                          () => {
                            mixpanel.track(
                              `Type Product (Add ${big.toLowerCase()} Web)`
                            );
                            setType("Product");
                          })
                        }
                        className="form-control-lg"
                        value="PRODUCT"
                        defaultChecked={res.isProduct}
                      />
                      <Form.Check
                        type="radio"
                        id="radio2"
                        value="SERVICE"
                        onChange={
                          (handleChange,
                          () => {
                            mixpanel.track(
                              `Type Product (Add ${big.toLowerCase()} Web)`
                            );
                            setType("Service");
                          })
                        }
                        name="type"
                        label="Service"
                        className="form-control-lg"
                        defaultChecked={res.isService}
                      />
                    </div>
                  </Col>
                </Form.Group>
              </div>
            </Card>
            {/* <Card>
              <Card.Header className="pb-0">
                <Card.Title className="mb-0">Item Type</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row}>
                  <Col sm={10}>
                    <div className="custom-controls-stacked d-flex gap-2">
                      <Form.Check
                        type="radio"
                        id="radio1"
                        name="type"
                        label="Product"
                        onChange={
                          (handleChange,
                          () => {
                            setType("Product");
                          })
                        }
                        className="form-control-lg"
                        value="PRODUCT"
                        defaultChecked={res.isProduct}
                      />
                      <Form.Check
                        type="radio"
                        id="radio2"
                        value="SERVICE"
                        onChange={
                          (handleChange,
                          () => {
                            setType("Service");
                          })
                        }
                        name="type"
                        label="Service"
                        className="form-control-lg"
                        defaultChecked={res.isService}
                      />
                    </div>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card> */}
            {/* Card 2 Item Details */}
            <div
              // className="container"
              style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}
            >
              <Row>
                <Col lg="8" className="p-2">
                  <Card className="border rounded-0">
                    {/* <Card.Header className="pb-0">
                      <Card.Title className="mb-0">Item Details</Card.Title>
                    </Card.Header> */}
                    <Card.Body>
                      {/* <FloatingLabel
                        controlId="floatingInput"
                        label="Title"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="itemName"
                          placeholder="text"
                          value={values.itemName}
                          onChange={handleChange}
                          isInvalid={!!errors.itemName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.itemName}
                        </Form.Control.Feedback>
                      </FloatingLabel> */}
                      <h4
                        style={{
                          borderBottom: "1px solid lightgrey",
                          paddingBottom: "20px",
                        }}
                      >
                        {big === "PRODUCT"
                          ? `Product Details`
                          : `Service Details`}
                      </h4>

                      <Form.Group className="mb-3">
                        <Form.Label style={{ color: "black" }}>
                          {big === "PRODUCT" ? `Product Name` : `Service Name`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="itemName"
                          placeholder={
                            big === "PRODUCT" ? `Product Name` : `Service Name`
                          }
                          value={values.itemName}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          isInvalid={!!errors.itemName}
                          style={{ padding: "8px" }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.itemName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <FloatingLabel
                        controlId="floatingInput"
                        label="Description"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="description"
                          placeholder="text"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </FloatingLabel> */}
                      <Form.Group className="mb-3">
                        <Form.Label style={{ color: "black" }}>
                          Description
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="6"
                          type="text"
                          name="description"
                          placeholder="Type something"
                          value={values.description}
                          onChange={handleChange}
                          // isInvalid={errors.item_name}
                          style={{ padding: "8px" }}
                        />
                        {/* <ReactQuill
                          placeholder="Type something"
                          name="description"
                          // value={values.description}
                          // onChange={handleChange}
                          value={desc}
                          onChange={(data) => setDesc(data)}
                        /> */}
                      </Form.Group>
                      {/* <FloatingLabel
                        controlId="floatingInput"
                        label="Item Code"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="text"
                          value={values.code}
                          onChange={handleChange}
                        />
                      </FloatingLabel> */}
                      <Form.Group className="mb-3">
                        <Form.Label style={{ color: "black" }}>
                          {big === "PRODUCT" ? `Product Code` : `Service Code`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="code"
                          onKeyDown={handleKeyDown}
                          placeholder={
                            big === "PRODUCT" ? `Product Code` : `Service Code`
                          }
                          value={values.code}
                          onChange={handleChange}
                          style={{ padding: "8px" }}
                        />
                      </Form.Group>
                      {big === "PRODUCT" ? (
                        // <FloatingLabel
                        //   controlId="floatingInput"
                        //   label="Supplier"
                        //   className="mb-3"
                        // >
                        //   <Form.Control
                        //     type="text"
                        //     name="supplier"
                        //     placeholder="text"
                        //     value={values.supplier}
                        //     onChange={handleChange}
                        //   />
                        // </FloatingLabel>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ color: "black" }}>
                            Supplier
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="supplier"
                            onKeyDown={handleKeyDown}
                            placeholder="Supplier"
                            value={values.supplier}
                            onChange={handleChange}
                            style={{ padding: "8px" }}
                          />
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                      {big === "PRODUCT" ? (
                        // <FloatingLabel
                        //   controlId="floatingInput"
                        //   label="Quantity Per Box"
                        //   className="mb-3"
                        // >
                        //   <Form.Control
                        //     type="text"
                        //     name="quantityperbox"
                        //     isInvalid={errors.quantityperbox}
                        //     placeholder="text"
                        //     value={values.quantityperbox}
                        //     onChange={handleChange}
                        //   />
                        //   <Form.Control.Feedback type="invalid">
                        //     {errors.quantityperbox}
                        //   </Form.Control.Feedback>
                        // </FloatingLabel>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ color: "black" }}>
                            Quantity Per Box
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            onKeyDown={handleKeyDown}
                            name="quantityperbox"
                            placeholder="Quantity Per Box"
                            value={values.quantityperbox}
                            // onChange={handleChange}
                            isInvalid={!!errors.quantityperbox}
                            onChange={(event) => {
                              if (
                                event.target.value.replace(/\s/g, "").length <=
                                10
                              ) {
                                handleChange(event);
                              }
                            }}
                            style={{ padding: "8px" }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.quantityperbox}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                {/* Card 3 Pricing and Inventory */}
                <Col lg="4" className="p-2">
                  <Card className="border rounded-0">
                    {/* <Card.Header className="pb-0">
                      <Card.Title className="mb-0">
                        {big === "PRODUCT" ? `Pricing & Inventory` : `Pricing`}
                      </Card.Title>
                    </Card.Header> */}
                    <Card.Body>
                      <h4
                        style={{
                          borderBottom: "1px solid lightgrey",
                          paddingBottom: "20px",
                        }}
                      >
                        {big === "PRODUCT" ? `Pricing & Inventory` : `Pricing`}
                      </h4>
                      {/* <FloatingLabel
                        controlId="floatingInput"
                        label={
                          big === "PRODUCT" ? `Selling Price` : `Service Charge`
                        }
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="sell"
                          isInvalid={errors.sell}
                          placeholder="text"
                          value={values.sell}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sell}
                        </Form.Control.Feedback>
                      </FloatingLabel> */}
                      <Form.Group className="mb-3">
                        <Form.Label style={{ color: "black" }}>
                          {big === "PRODUCT"
                            ? `Selling Price`
                            : `Service Charge`}
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            {getSymbolFromCurrency(usercurrency)}
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            min={0}
                            onKeyDown={handleKeyDown}
                            name="sell"
                            placeholder={
                              big === "PRODUCT"
                                ? `Selling Price`
                                : `Service Charge`
                            }
                            value={values.sell}
                            onChange={(event) => {
                              if (
                                event.target.value.replace(/\s/g, "").length <=
                                10
                              ) {
                                handleChange(event);
                              }
                            }}
                            style={{ padding: "8px" }}
                            isInvalid={!!errors.sell}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.sell}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      {/* <FloatingLabel
                        controlId="floatingInput"
                        label={
                          big === "PRODUCT" ? `Cost Price` : `Service Cost`
                        }
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="cost"
                          isInvalid={errors.cost}
                          placeholder="text"
                          value={values.cost}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cost}
                        </Form.Control.Feedback>
                      </FloatingLabel> */}
                      <Form.Group className="mb-3">
                        <Form.Label style={{ color: "black" }}>
                          {big === "PRODUCT" ? `Cost Price` : `Service Cost`}
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            {getSymbolFromCurrency(usercurrency)}
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            name="cost"
                            onKeyDown={handleKeyDown}
                            min={0}
                            placeholder={
                              big === "PRODUCT" ? `Cost Price` : `Service Cost`
                            }
                            value={values.cost}
                            onChange={(event) => {
                              if (
                                event.target.value.replace(/\s/g, "").length <=
                                10
                              ) {
                                handleChange(event);
                              }
                            }}
                            style={{ padding: "8px" }}
                            isInvalid={!!errors.cost}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cost}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      {big === "PRODUCT" ? (
                        // <FloatingLabel
                        //   controlId="floatingInput"
                        //   label="Inventory"
                        //   className="mb-3"
                        // >
                        //   <Form.Control
                        //     type="text"
                        //     name="quantity"
                        //     isInvalid={errors.quantity}
                        //     placeholder="text"
                        //     value={values.quantity}
                        //     onChange={handleChange}
                        //   />
                        //   <Form.Control.Feedback type="invalid">
                        //     {errors.quantity}
                        //   </Form.Control.Feedback>
                        // </FloatingLabel>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ color: "black" }}>
                            Inventory
                          </Form.Label>
                          <Form.Control
                            // type="text"
                            type="number"
                            name="quantity"
                            onKeyDown={handleKeyDown}
                            min={0}
                            isInvalid={errors.quantity}
                            placeholder="Inventory"
                            value={values.quantity}
                            onChange={(event) => {
                              if (
                                event.target.value.replace(/\s/g, "").length <=
                                10
                              ) {
                                handleChange(event);
                              }
                            }}
                            // onChange={handleChange}
                            style={{ padding: "8px" }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.quantity}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : (
                        // <FloatingLabel
                        //   controlId="floatingInput"
                        //   label="Unit Type"
                        //   className="mb-3"
                        // >
                        //   <Form.Control
                        //     type="text"
                        //     name="unit"
                        //     placeholder="text"
                        //     value={values.unit}
                        //     onChange={handleChange}
                        //   />
                        // </FloatingLabel>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ color: "black" }}>
                            Unit Type
                          </Form.Label>
                          <Form.Control
                            type="text"
                            onKeyDown={handleKeyDown}
                            name="unit"
                            placeholder="Unit Type"
                            value={values.unit}
                            onChange={handleChange}
                            style={{ padding: "8px" }}
                          />
                        </Form.Group>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* Call to Action Button*/}
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => {
                    mixpanel.track(`Cancel (Add ${big.toLowerCase()} Web)`);
                    cancelHandler();
                  }}
                  className=" mb-1 center"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  // disabled={!dirty}
                  size="lg"
                  type="submit"
                  className=" mb-1 center"
                  disabled={disablesave}
                >
                  Create {capitalizeFirstLetter(big.toLowerCase())}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        show={validationError}
        onHide={() => setValidationError(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Error
            </h1>
            <div
              onClick={() => {
                setValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                There was an error saving the form. Please check the messages in
                the form and try again.
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setValidationError(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Itemform = (props) => {
  console.log(props);
  return (
    <React.Fragment>
      <Helmet title={`Add Item`} />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Add New Item</h1> */}
        <Row>
          <Col lg="12">
            <FormRow props={props} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Itemform;
