import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import "./mocks";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import { PremiumProvider } from "./contexts/PremiumContext";
import { LocalCountryProvider } from "./contexts/LocalCountryContext";
import { ErrorBoundary } from "react-error-boundary";
import { Button } from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import { logError } from "./utils/useErrorlogging";
mixpanel.init(
  `31d65feec55899e0258956532fe315c8`,
  // `287e8e8f710edb2d6a7cb82e75f1177b`,
  {
    // track_pageview: true,
    // persistence: "localStorage",
  }
);
ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={(error, resetErrorBoundary) => {
      return (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 35,
            color: "black",
            fontWeight: "bold",
          }}
        >
          Something Went Wrong
        </div>
      );
    }}
    onError={(error, info) => {
      console.log(error);
      // mixpanel.track(`Error Boundary (Web)`, {
      //   error: error.message,
      //   stack: error.stack,
      // });
      logError({
        error: error.message,
        stack: error.stack,
        component: "Error Boundary Web",
      });
    }}
  >
    <BrowserRouter>
      <PremiumProvider>
        <AuthProvider>
          <LocalCountryProvider>
            <App />
          </LocalCountryProvider>
        </AuthProvider>
      </PremiumProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
