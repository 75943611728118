import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings2 from "../components/Settings";

import ScrolltoTop from "../components/ScrolltoTop";
import useAuth from "../hooks/useAuth";
import { usePremium } from "../contexts/PremiumContext";
import mixpanel from "mixpanel-browser";
import useNav from "../components/sidebar/dashboardItems";

const Dashboard = ({ children }) => {
  const {
    bdata,
    isAuthenticated,
    isInitialized,
    token,
    currency,
    setBdata,
    loading,
    setLoading,
    isTeamAccount,
  } = useAuth();
  const {
    moreInfo,
    setMoreInfo,
    isPremium,
    setIsPremium,
    setIsFree,
    isTrial,
    setIsTrial,
    useIsFree,
    setMoreinfoCount,
  } = usePremium();
  const navigate = useNavigate();
  const { navItems } = useNav();
  return (
    <React.Fragment>
      {isAuthenticated &&
      moreInfo &&
      !isPremium &&
      !isTrial &&
      !isTeamAccount ? (
        <div
          style={{
            // position: "fixed",
            height: 50,
            top: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 30px",
            backgroundColor: "#f0f6fa",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            Your free trial has ended. Please upgrade your plan to continue
            using Bestmate.
          </p>
          <div
            style={{
              border: "1px solid #407FEA",
              padding: "1px 9px",
              cursor: "pointer",
              color: "#407FEA",
              fontWeight: "bold",
            }}
            onClick={() => {
              mixpanel.track(`Upgrade Now (Top Bar Web)`);
              return navigate("/subscription");
            }}
          >
            Upgrade Now
          </div>
        </div>
      ) : (
        <></>
      )}
      <Wrapper>
        <Sidebar items={navItems} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
      <ScrolltoTop />
      <Settings2 />
    </React.Fragment>
  );
};

export default Dashboard;
