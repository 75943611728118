import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTable } from "react-table";
import {
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Nav,
  Tab,
  Table,
  Breadcrumb,
  Badge,
  Spinner,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import Page404Authenticated from "../auth/Page404authenticated";
import Select from "react-select";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useSidebar from "../../hooks/useSidebar";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { MapPin, Save, Sliders, User, X } from "react-feather";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import useAuth from "../../hooks/useAuth";
import { logError } from "../../utils/useErrorlogging";

/*
  Input Component Partner containes the edit form , Invoices , Estimate and Order
  The Edit form contains three buttons (Save, cancel and delete).
  -> If user edits any information user can save the edited information by pressing Save button
  -> If user don't want to edit any information user can use cancel button
  -> If user want to delete that particular record he can press delete button
  The Invoice tab, estimate tab and orders tab containes details corresoponding to particular partner
*/
// const EditComponent = (props) => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const { isOpen } = useSidebar();
//   const [openModals, setOpenModals] = useState(false);
//   const [deleteData, setDeleteData] = useState({});
//   const [rowdata, setRowdata] = useState();
//   const [token, settoken] = useState("");
//   const [pricelist, setPricelist] = useState({});
//   const [options, setOptions] = useState([]);
//   const notyf = useContext(NotyfContext);
//   const fetchPriceList = (token) => {
//     fetch("https://bestmate.us/api/pricelist/findAll", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//     })
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           console.log(result);
//           let data = result.map((elem) => {
//             return {
//               label: `${elem.price_list_name} : ${elem.price_list_multiplier}x`,
//               value: {
//                 price_list_name: elem.price_list_name,
//                 price_list_multiplier: elem.price_list_multiplier,
//                 price_list_id: elem.price_list_id,
//               },
//             };
//           });
//           console.log(data);
//           setOptions(data);
//         },
//         (error) => {
//           console.error("Error fetching data: ", error);
//         }
//       );
//   };

//   const getRequiredData = (token) => {
//     fetch("https://bestmate.us/api/partners/get/" + id, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//     })
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           setRowdata(result);
//           props.setPartner(result);
//         },
//         (error) => {
//           console.error("Error fetching data: ", error);
//         }
//       );
//   };

//   const deleteRecord = (deleteData) => {
//     fetch("https://bestmate.us/api/partners/delete", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//       body: JSON.stringify(deleteData),
//     })
//       .then((response) => {
//         console.log("response", response);
//         navigate("/customer");
//         notyf.open({
//           type: isOpen ? "home" : "full",
//           message: "Partner deleted successfully",
//           ripple: "true",
//           dismissible: "true",
//         });
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//     // setShow(false);
//   };
//   useEffect(() => {
//     firebase.auth().onAuthStateChanged(async (user) => {
//       if (user) {
//         let tokenss = await user.getIdToken();
//         settoken(tokenss);
//         fetchPriceList(tokenss);
//         if (location.state !== null) {
//           setRowdata(location.state.row);
//           props.setPartner(location.state.row);
//           let pldata;
//           if (
//             Object.entries(location.state.row.partner_price_list).length === 0
//           ) {
//             console.log(location.state.row.partner_price_list);
//             pldata = {};
//           } else {
//             pldata = {
//               label: `${location.state.row.partner_price_list.price_list_name} : ${location.state.row.partner_price_list.price_list_multiplier}x`,
//               value: location.state.row.partner_price_list,
//             };
//           }
//           setPricelist(pldata);
//         } else {
//           getRequiredData(tokenss);
//         }
//       }
//     });
//   }, []);

//   return (
//     <>
//       {rowdata !== undefined ? (
//         <>
//           <Formik
//             initialValues={{
//               partner_id: rowdata.partner_id,
//               partner_type: rowdata.partner_type,
//               first_name: rowdata.partner_first_name,
//               last_name: rowdata.partner_last_name,
//               email: rowdata.partner_email,
//               number: rowdata.partner_number,
//               billing_street: rowdata.partner_billing_address.street,
//               billing_city: rowdata.partner_billing_address.city,
//               billing_state: rowdata.partner_billing_address.state,
//               billing_zipcode: rowdata.partner_billing_address.zip_code,
//               billing_country: rowdata.partner_billing_address.country,
//               shipping_street: rowdata.partner_shipping_address.street,
//               shipping_city: rowdata.partner_shipping_address.city,
//               shipping_state: rowdata.partner_shipping_address.state,
//               shipping_zipcode: rowdata.partner_shipping_address.zip_code,
//               shipping_country: rowdata.partner_shipping_address.country,
//               notes: rowdata.partner_notes,
//               payment_terms: rowdata.partner_payment_terms,
//               tax_number: rowdata.partner_tax_number,
//             }}
//             validationSchema={Yup.object().shape({
//               first_name: Yup.string()
//                 .max(255)
//                 .required("Item name is required"),
//               last_name: Yup.string()
//                 .max(255)
//                 .required("Item name is required"),
//               // number: Yup.string().matches(
//               //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
//               //   "Phone number is not valid"
//               // ),
//             })}
//             onSubmit={(values) => {
//               // console.log(values);
//               const data = {
//                 partner_id: values.partner_id,
//                 partner_first_name: values.first_name,
//                 partner_last_name: values.last_name,
//                 partner_email: values.email,
//                 partner_number: values.number,
//                 partner_price_list: pricelist.value,
//                 partner_billing_address: {
//                   street: values.billing_street,
//                   city: values.billing_city,
//                   state: values.billing_state,
//                   zip_code: values.billing_zipcode,
//                   country: values.billing_country,
//                 },
//                 partner_shipping_address: {
//                   street: values.shipping_street,
//                   city: values.shipping_city,
//                   state: values.shipping_state,
//                   zip_code: values.shipping_zipcode,
//                   country: values.shipping_country,
//                 },
//                 partner_payment_terms: values.payment_terms,
//                 partner_notes: values.notes,
//                 partner_tax_number: values.tax_number,
//                 partner_type: values.partner_type,
//               };
//               // console.log(data);
//               fetch("https://bestmate.us/api/partners/update", {
//                 method: "POST",
//                 headers: {
//                   Accept: "application/json",
//                   "Content-Type": "application/json",
//                   Authorization: "Bearer " + token,
//                 },
//                 body: JSON.stringify(data),
//               })
//                 .then((response) => {
//                   navigate("/customer");
//                   notyf.open({
//                     type: isOpen ? "home" : "full",
//                     message: "Partner updated successfully",
//                     ripple: "true",
//                     dismissible: "true",
//                   });
//                 })
//                 .catch((error) => {
//                   console.error(error);
//                 });
//               // props.show(false);
//               // props.setSearchParams({});
//             }}
//             onReset={() => {
//               // props.show(false);
//               // props.setSearchParams({});
//               navigate("/customer");
//             }}
//           >
//             {({
//               handleSubmit,
//               values,
//               errors,
//               handleChange,
//               handleReset,
//               // dirty,
//             }) => (
//               <Form onSubmit={handleSubmit}>
//                 {errors.submit && (
//                   <Alert className="my-3" variant="danger">
//                     <div className="alert-message">{errors.submit}</div>
//                   </Alert>
//                 )}
//                 {/* Card 1 Partner Profile */}

//                 {/* <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Partner Profile</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="First Name "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.first_name}
//                             name="first_name"
//                             placeholder="text"
//                             value={values.first_name}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.first_name}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Last Name "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.last_name}
//                             name="last_name"
//                             placeholder="text"
//                             value={values.last_name}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.last_name}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Email "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="Email"
//                         name="email"
//                         value={values.email}
//                         onChange={handleChange}
//                       />
//                     </FloatingLabel>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Phone Number "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         name="number"
//                         value={values.number}
//                         onChange={handleChange}
//                         // isInvalid={!!errors.number}
//                       />

//                     </FloatingLabel>
//                   </Card.Body>
//                 </Card> */}
//                 {/* Card 2 Billing Address*/}
//                 <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Billing Address</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Street "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         isInvalid={errors.billing_street}
//                         name="billing_street"
//                         placeholder="text"
//                         value={values.billing_street}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.billing_street}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="City "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_city}
//                             name="billing_city"
//                             placeholder="text"
//                             value={values.billing_city}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_city}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="State "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_state}
//                             name="billing_state"
//                             placeholder="text"
//                             value={values.billing_state}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_state}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Zip Code "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_zipcode}
//                             name="billing_zipcode"
//                             placeholder="text"
//                             value={values.billing_zipcode}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_zipcode}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Country "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_country}
//                             name="billing_country"
//                             placeholder="text"
//                             value={values.billing_country}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_country}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//                 {/* Card 3 Shipping Address*/}
//                 <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Shipping Address</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Street "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         isInvalid={errors.shipping_street}
//                         name="shipping_street"
//                         placeholder="text"
//                         value={values.shipping_street}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.shipping_street}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="City "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_city}
//                             name="shipping_city"
//                             placeholder="text"
//                             value={values.shipping_city}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_city}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="State "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_state}
//                             name="shipping_state"
//                             placeholder="text"
//                             value={values.shipping_state}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_state}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Zip Code "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_zipcode}
//                             name="shipping_zipcode"
//                             placeholder="text"
//                             value={values.shipping_zipcode}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_zipcode}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Country "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_country}
//                             name="shipping_country"
//                             placeholder="text"
//                             value={values.shipping_country}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_country}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//                 {/* Card 4 Other Details */}
//                 <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Other Details</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     {/* <FloatingLabel
//                       controlId="floatingInput"
//                       label="Price List"
//                       className="mb-3"
//                     > */}
//                     <Form.Label>Price List</Form.Label>
//                     {/* <div className="d-flex gap-2">
//                       <Select
//                         className="react-select-container mb-3"
//                         classNamePrefix="react-select"
//                         value={
//                           Object.entries(pricelist).length === 0
//                             ? ""
//                             : pricelist
//                         }
//                         onChange={setPricelist}
//                         options={options}
//                       />
//                       <Button> Add PriceList</Button>
//                     </div> */}
//                     {/* <Row>
//                       <Col md>
//                         <Select
//                           className="react-select-container mb-3"
//                           classNamePrefix="react-select"
//                           value={
//                             Object.entries(pricelist).length === 0
//                               ? ""
//                               : pricelist
//                           }
//                           onChange={setPricelist}
//                           options={options}
//                         />
//                       </Col>
//                       <Col md>
//                         <Button> Add PriceList</Button>
//                       </Col>
//                     </Row> */}
//                     <Select
//                       className="react-select-container mb-3"
//                       classNamePrefix="react-select"
//                       value={
//                         Object.entries(pricelist).length === 0 ? "" : pricelist
//                       }
//                       onChange={setPricelist}
//                       options={options}
//                     />
//                     {/* <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.price_list}
//                         name="price_list"
//                         value={values.price_list}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.price_list}
//                       </Form.Control.Feedback>
//                     </FloatingLabel> */}
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Payment Terms"
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.payment_terms}
//                         name="payment_terms"
//                         value={values.payment_terms}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.payment_terms}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Notes"
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.notes}
//                         name="notes"
//                         value={values.notes}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.notes}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Tax Number"
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.tax_number}
//                         name="tax_number"
//                         value={values.tax_number}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.tax_number}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                   </Card.Body>
//                 </Card>
//                 {/* Call to Action Buttons*/}
//                 <div className="d-flex justify-content-between">
//                   <div>
//                     <Button
//                       variant="danger"
//                       size="lg"
//                       onClick={() => {
//                         setDeleteData({
//                           partner_id: rowdata.partner_id,
//                           partner_first_name: rowdata.partner_first_name,
//                         });
//                         setOpenModals(!openModals);
//                       }}
//                       className="me-1 mb-1 center"
//                     >
//                       Delete
//                     </Button>
//                     <Button
//                       variant="secondary"
//                       size="lg"
//                       type="reset"
//                       onClick={handleReset}
//                       className="me-1 mb-1 center"
//                     >
//                       Cancel
//                     </Button>
//                   </div>
//                   <Button
//                     variant="primary"
//                     // disabled={!dirty}
//                     size="lg"
//                     type="submit"
//                     className="me-1 mb-1 center"
//                   >
//                     Save
//                   </Button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//           <Modal
//             show={openModals}
//             onHide={() => setOpenModals(!openModals)}
//             centered
//           >
//             <Modal.Header className="font-weight-bold" closeButton>
//               Delete Product
//             </Modal.Header>
//             <Modal.Body className="text-left m-3">
//               <p className="mb-0 font-weight-bold">
//                 Are you sure you want to delete{" "}
//                 {deleteData["partner_first_name"]}?
//               </p>
//             </Modal.Body>
//             <Modal.Footer>
//               <Button
//                 variant="secondary"
//                 onClick={() => setOpenModals(!openModals)}
//               >
//                 Cancel
//               </Button>{" "}
//               <Button
//                 variant="danger"
//                 onClick={() => {
//                   deleteRecord(deleteData);
//                   setOpenModals(!openModals);
//                 }}
//               >
//                 Delete
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };

// const FilteringTable = (props) => {
//   // console.log(props.data);
//   let usercurrency = localStorage.getItem("currency") || "USD";

//   const COLUMNS = [
//     {
//       Header: "Invoice Number",
//       accessor: "invoice_number",
//     },
//     {
//       Header: "Name",
//       accessor: (elem) =>
//         `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
//     },
//     {
//       Header: "Invoice Total",
//       accessor: "invoice_grand_total",
//       Cell: ({ value, row }) => {
//         const num = parseInt(value);
//         // console.log(row);
//         if (Number.isNaN(num)) {
//           return "N/A";
//         } else {
//           return num.toLocaleString("en-US", {
//             style: "currency",
//             currency: usercurrency,
//           });
//         }
//       },
//     },
//     {
//       Header: "Invoice Settlement Amount",
//       accessor: (elem) =>
//         elem.invoice_grand_total - elem.invoice_settled_amount,
//       Cell: ({ value }) => {
//         const num = parseInt(value);
//         if (Number.isNaN(num)) {
//           return "N/A";
//         } else {
//           return num.toLocaleString("en-US", {
//             style: "currency",
//             currency: usercurrency,
//           });
//         }
//       },
//     },
//   ];

//   const columns = useMemo(() => COLUMNS, []);
//   const data = props.data;

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({
//       columns,
//       data,
//     });
//   return (
//     <>
//       <Table striped bordered size="sm" {...getTableProps()}>
//         <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
//           {headerGroups.map((headerGroup) => (
//             <tr {...headerGroup.getFooterGroupProps()}>
//               {headerGroup.headers.map((column) => (
//                 <th {...column.getHeaderProps()}>{column.render("Header")}</th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
//           {rows.map((row) => {
//             prepareRow(row);
//             return (
//               <tr {...row.getRowProps()}>
//                 {row.cells.map((cell) => {
//                   return (
//                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                   );
//                 })}
//               </tr>
//             );
//           })}
//         </tbody>
//       </Table>
//     </>
//   );
// };

// const Invoices = (props) => {
//   const data = props.dataInvoice;
//   return (
//     <>
//       {/* <Card>
//         <Card.Header> */}
//       {data.length === 0 ? <p>No Invoices</p> : <FilteringTable data={data} />}
//       {/* </Card.Header>
//       </Card> */}
//     </>
//   );
// };

// const Estimates = (props) => {
//   const data = props.dataEstimate;
//   return (
//     <>
//       {/* <Card>
//         <Card.Header> */}
//       {data.length === 0 ? <p>No Estimates</p> : <FilteringTable data={data} />}
//       {/* </Card.Header>
//       </Card> */}
//     </>
//   );
// };

// const Orders = (props) => {
//   const data = props.dataOrder;
//   return (
//     <>
//       {/* <Card>
//         <Card.Header> */}
//       {data.length === 0 ? <p>No Orders</p> : <FilteringTable data={data} />}
//       {/* </Card.Header>
//       </Card> */}
//     </>
//   );
// };

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}

const InputComponentPartner = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen } = useSidebar();
  const [openModals, setOpenModals] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [rowdata, setRowdata] = useState();
  const [token, settoken] = useState("");
  const [pricelist, setPricelist] = useState("");
  const [options, setOptions] = useState([]);
  const [disabledelete, setDisableDelete] = useState(false);
  const [billingadd, setbillingadd] = useState({
    street: "",
    zip_code: "",
    country: "",
    state: "",
    city: "",
  });
  const [shippingadd, setshippingadd] = useState({
    street: "",
    zip_code: "",
    country: "",
    state: "",
    city: "",
  });
  const [sameasbilling, setSameasbilling] = useState(false);
  const [disablesave, setDisablesave] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetcherror, setFetcherror] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const { isTeamAccount, userPermission } = useAuth();
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  const notyf = useContext(NotyfContext);

  const [data, setData] = useState([]);
  const [activeTab, setactiveTab] = useState("first");
  const [partner, setPartner] = useState([]);

  const inputRef = useRef();
  const inputRef2 = useRef();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
    libraries: ["places"],
  });

  useEffect(() => {
    if (openModals) {
      setDisableDelete(false);
    }
  }, [openModals]);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setbillingadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };
  const handlePlaceChanged2 = () => {
    const [place] = inputRef2.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setshippingadd({
        country: country.length !== 0 ? country[0].long_name : "",
        state: state.length !== 0 ? state[0].long_name : "",
        city: city.length !== 0 ? city[0].long_name : "",
        zip_code: zip.length !== 0 ? zip[0].long_name : "",
        street:
          street.length !== 0
            ? street.map((elem) => elem.long_name).join(" ")
            : "",
      });
    }
  };

  const fetchPriceList = (token) => {
    fetch("https://bestmate.us/api/pricelist/findAll", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let data = result.map((elem) => {
            return {
              label: `${elem.price_list_name}: ${elem.price_list_multiplier}x`,
              value: {
                price_list_name: elem.price_list_name,
                price_list_multiplier: elem.price_list_multiplier,
                price_list_id: elem.price_list_id,
                ...elem,
              },
            };
          });
          console.log(data);
          setOptions(data);
          // console.log(result);
          // setOptions(result);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };
  function object_equals(x, y) {
    if (x === y) return true;
    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    if (x.constructor !== y.constructor) return false;
    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue;
      if (!y.hasOwnProperty(p)) return false;
      if (x[p] === y[p]) continue;
      if (typeof x[p] !== "object") return false;
      if (!object_equals(x[p], y[p])) return false;
    }
    for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    return true;
  }

  const getRequiredData = (token) => {
    fetch("https://bestmate.us/api/partners/get/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          setFetcherror(true);
          setLoading(false);
          throw new Error(`HTTP error, status code ${res.status}`);
        }
        return res.json();
      })
      .then((result) => {
        setRowdata(result);
        // props.setPartner(result);
        console.log(result);
        setbillingadd(result.partner_billing_address);
        setshippingadd(result.partner_shipping_address);
        if (
          object_equals(
            result.partner_billing_address,
            result.partner_shipping_address
          )
        ) {
          setSameasbilling(true);
        }
        if (Object.entries(result.partner_price_list).length === 0) {
          setPricelist("");
        } else {
          setPricelist(result.partner_price_list.price_list_id);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const deleteRecord = async (deleteData) => {
    setDisableDelete(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/partners/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            console.log("response", response);
            navigate(`/${props.type.toLowerCase()}`);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `${props.type} deleted successfully`,
              ripple: "true",
              dismissible: "true",
            });
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track(`Fetch Error (Edit ${props.type} Web)`, {
            //   error: "partner delete api",
            // });
            logError({
              error: "fetch error",
              api: "partner delete api",
              component: `Edit ${props.type} Web`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // mixpanel.track(`Error (Edit ${props.type} Web)`, {
          //   error: error,
          // });
          logError({
            error: error,
            component: `(Edit ${props.type} Web)`,
          });
        });
    }
    // setShow(false);
  };

  const getInvoicedata = (tokenss) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);
    fetch(
      `https://bestmate.us/api/invoices/findByPartner/${id}?timeZoneOffset=${timeZoneOffset}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  useEffect(async () => {
    mixpanel.track(`On Edit ${props.type} (Web)`);
    setLoading(true);
    setFetcherror(false);
    // useEffect(() => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // fetchPriceList(tokenss);
      // getInvoicedata(tokenss);
      if (location.state !== null) {
        console.log("bugfree");
        // props.setPartner(location.state.row);
        let pldata;
        setRowdata(location.state.row);
        setbillingadd(location.state.row.partner_billing_address);
        setshippingadd(location.state.row.partner_shipping_address);
        if (
          object_equals(
            location.state.row.partner_billing_address,
            location.state.row.partner_shipping_address
          )
        ) {
          setSameasbilling(true);
        }
        // console.log(Object.entries(location.state.row.partner_price_list));
        if (
          Object.keys(location.state.row).includes("partner_price_list") &&
          Object.entries(location.state.row.partner_price_list).length !== 0
        ) {
          setPricelist(location.state.row.partner_price_list.price_list_id);
        } else {
          setPricelist("");
        }
        setLoading(false);
        // console.log(pldata);
        // setPricelist(pldata);
      } else {
        console.log("bugfree2");
        // getRequiredData(tokenss);
      }
    }
    // });
    // }, []);
  }, []);
  console.log(rowdata, pricelist, props, billingadd, shippingadd);
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const dataInvoice = [];
  const dataEstimate = [];
  const dataOrder = [];
  data.filter((elem) => {
    if (elem.invoice_workflow_type === "INVOICE") {
      dataInvoice.push(elem);
    } else if (elem.invoice_workflow_type === "ESTIMATE") {
      dataEstimate.push(elem);
    } else {
      dataOrder.push(elem);
    }
  });
  // console.log(
  //   Object.entries(pricelist).length === 0
  //     ? ""
  //     : options.find(
  //         (u) => u.value.price_list_id === pricelist.value.price_list_id
  //       ).value
  // );
  return (
    <>
      {!loading ? (
        <>
          {!fetcherror && rowdata ? (
            <>
              <Formik
                initialValues={{
                  partner_id: rowdata.partner_id,
                  partner_type: rowdata.partner_type,
                  first_name: rowdata.partner_first_name,
                  last_name: rowdata.partner_last_name,
                  company_name: rowdata.partner_company_name,
                  email: rowdata.partner_email,
                  number: rowdata.partner_number,
                  billing_street: rowdata.partner_billing_address.street,
                  billing_city: rowdata.partner_billing_address.city,
                  billing_state: rowdata.partner_billing_address.state,
                  billing_zipcode: rowdata.partner_billing_address.zip_code,
                  billing_country: rowdata.partner_billing_address.country,
                  shipping_street: rowdata.partner_shipping_address.street,
                  shipping_city: rowdata.partner_shipping_address.city,
                  shipping_state: rowdata.partner_shipping_address.state,
                  shipping_zipcode: rowdata.partner_shipping_address.zip_code,
                  shipping_country: rowdata.partner_shipping_address.country,
                  notes: rowdata.partner_notes,
                  payment_terms: rowdata.partner_payment_terms,
                  tax_number: rowdata.partner_tax_number,
                }}
                validationSchema={Yup.object().shape({
                  first_name: Yup.string()
                    .required("First name is required")
                    .max(100, "first name must be less than 100 characters"),
                  last_name: Yup.string()
                    .required("Last name is required")
                    .max(100, "Last name must be less than 100 characters"),
                  number: Yup.string().matches(
                    /^[0-9+\-\s]+$/,
                    "Phone number is not valid"
                  ),
                  email: Yup.string().email("Email is not valid"),
                })}
                onSubmit={async (values) => {
                  // console.log(values);
                  setDisablesave(true);

                  const data = {
                    partner_id: values.partner_id,
                    partner_first_name: capitalizeFirstLetter(
                      values.first_name
                    ),
                    partner_last_name: capitalizeFirstLetter(values.last_name),
                    partner_email: values.email,
                    partner_number: values.number,
                    partner_price_list: pricelist
                      ? options.filter(
                          (elems) => elems.value.price_list_id === pricelist
                        )[0].value
                      : {},
                    partner_billing_address: {
                      street: billingadd.street,
                      city: billingadd.city,
                      state: billingadd.state,
                      zip_code: billingadd.zip_code,
                      country: billingadd.country,
                    },
                    partner_shipping_address: {
                      street: sameasbilling
                        ? billingadd.street
                        : shippingadd.street,
                      city: sameasbilling ? billingadd.city : shippingadd.city,
                      state: sameasbilling
                        ? billingadd.state
                        : shippingadd.state,
                      zip_code: sameasbilling
                        ? billingadd.zip_code
                        : shippingadd.zip_code,
                      country: sameasbilling
                        ? billingadd.country
                        : shippingadd.country,
                    },
                    partner_payment_terms: values.payment_terms,
                    partner_notes: values.notes,
                    partner_tax_number: values.tax_number,
                    // partner_billing_address: {},
                    // partner_shipping_address: {},
                    partner_type: values.partner_type,
                    partner_company_name: values.company_name,
                  };
                  // if (
                  //   pricelist &&
                  //   Object.keys(
                  //     options.filter(
                  //       (elems) => elems.value.price_list_id === pricelist
                  //     )[0].value !== 0
                  //   )
                  // ) {
                  //   data.partner_price_list = options.filter(
                  //     (elems) => elems.value.price_list_id === pricelist
                  //   )[0].value;
                  // }
                  // if (
                  //   Object.keys(billingadd).includes("street") &&
                  //   billingadd.street
                  // ) {
                  //   data.partner_billing_address.street = billingadd.street;
                  // }
                  // if (
                  //   Object.keys(billingadd).includes("city") &&
                  //   billingadd.city
                  // ) {
                  //   data.partner_billing_address.city = billingadd.city;
                  // }
                  // if (
                  //   Object.keys(billingadd).includes("state") &&
                  //   billingadd.state
                  // ) {
                  //   data.partner_billing_address.state = billingadd.state;
                  // }
                  // if (
                  //   Object.keys(billingadd).includes("zip_code") &&
                  //   billingadd.zip_code
                  // ) {
                  //   data.partner_billing_address.zip_code = billingadd.zip_code;
                  // }
                  // if (
                  //   Object.keys(billingadd).includes("country") &&
                  //   billingadd.country
                  // ) {
                  //   data.partner_billing_address.country = billingadd.country;
                  // }
                  // if (sameasbilling) {
                  //   if (
                  //     Object.keys(billingadd).includes("street") &&
                  //     billingadd.street
                  //   ) {
                  //     data.partner_shipping_address.street = billingadd.street;
                  //   }
                  //   if (
                  //     Object.keys(billingadd).includes("city") &&
                  //     billingadd.city
                  //   ) {
                  //     data.partner_shipping_address.city = billingadd.city;
                  //   }
                  //   if (
                  //     Object.keys(billingadd).includes("state") &&
                  //     billingadd.state
                  //   ) {
                  //     data.partner_shipping_address.state = billingadd.state;
                  //   }
                  //   if (
                  //     Object.keys(billingadd).includes("zip_code") &&
                  //     billingadd.zip_code
                  //   ) {
                  //     data.partner_shipping_address.zip_code =
                  //       billingadd.zip_code;
                  //   }
                  //   if (
                  //     Object.keys(billingadd).includes("country") &&
                  //     billingadd.country
                  //   ) {
                  //     data.partner_shipping_address.country =
                  //       billingadd.country;
                  //   }
                  // } else {
                  //   if (
                  //     Object.keys(shippingadd).includes("street") &&
                  //     shippingadd.street
                  //   ) {
                  //     data.partner_shipping_address.street = shippingadd.street;
                  //   }
                  //   if (
                  //     Object.keys(shippingadd).includes("city") &&
                  //     shippingadd.city
                  //   ) {
                  //     data.partner_shipping_address.city = shippingadd.city;
                  //   }
                  //   if (
                  //     Object.keys(shippingadd).includes("state") &&
                  //     shippingadd.state
                  //   ) {
                  //     data.partner_shipping_address.state = shippingadd.state;
                  //   }
                  //   if (
                  //     Object.keys(shippingadd).includes("zip_code") &&
                  //     shippingadd.zip_code
                  //   ) {
                  //     data.partner_shipping_address.zip_code =
                  //       shippingadd.zip_code;
                  //   }
                  //   if (
                  //     Object.keys(shippingadd).includes("country") &&
                  //     shippingadd.country
                  //   ) {
                  //     data.partner_shipping_address.country =
                  //       shippingadd.country;
                  //   }
                  // }
                  // if (values.email) {
                  //   data.partner_email = values.email;
                  // }
                  // if (values.phoneNumber) {
                  //   data.partner_number = values.phoneNumber;
                  // }
                  // if (values.terms) {
                  //   data.partner_payment_terms = values.terms;
                  // }
                  // if (values.notes) {
                  //   data.partner_notes = values.notes;
                  // }
                  // if (values.tax) {
                  //   data.partner_tax_number = values.tax;
                  // }
                  console.log(data);
                  var user = firebase.auth().currentUser;
                  mixpanel.track(`Save (Edit ${props.type} Web)`, {
                    ...data,
                  });
                  if (user) {
                    let tokenss = await user.getIdToken();
                    if (isTeamAccount) {
                      data.team = true;
                    } else {
                      delete data.team;
                    }
                    fetch("https://bestmate.us/api/partners/update", {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + tokenss,
                      },
                      body: JSON.stringify(data),
                    })
                      .then((response) => {
                        if (response.status >= 200 && response.status <= 300) {
                          navigate(`/${props.type.toLowerCase()}`);
                          notyf.open({
                            type: isOpen ? "home" : "full",
                            message: `${props.type} updated successfully`,
                            ripple: "true",
                            dismissible: "true",
                          });
                          setDisablesave(false);
                        } else {
                          notyf.open({
                            type: isOpen ? "homewarning" : "fullwarning",
                            message: `Something went wrong.`,
                            ripple: "true",
                            dismissible: "true",
                          });
                          setDisablesave(false);
                          // mixpanel.track(
                          //   `Fetch Error (Edit ${props.type} Web)`,
                          //   {
                          //     error: "partner edit api",
                          //   }
                          // );
                          logError({
                            error: "fetch error",
                            api: "partner edit api",
                            component: `Edit ${props.type} Web`,
                          });
                        }
                      })
                      .catch((error) => {
                        setDisablesave(false);
                        console.error(error);
                        // mixpanel.track(`Error (Edit ${props.type} Web)`, {
                        //   error: error,
                        // });
                        logError({
                          error: error,
                          component: `(Edit ${props.type} Web)`,
                        });
                      });
                  }
                  // props.show(false);
                  // props.setSearchParams({});
                }}
                onReset={() => {
                  // props.show(false);
                  // props.setSearchParams({});
                  navigate(`/${props.type.toLowerCase()}`);
                }}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  handleChange,
                  handleReset,
                  validateForm,
                  // dirty,
                }) => (
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const obj = await validateForm();
                      if (Object.keys(obj).length !== 0) {
                        setValidationError(true);
                      } else {
                        handleSubmit();
                      }
                    }}

                    // onKeyDown={(keyEvent) => {
                    //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    //   }
                    // }}
                  >
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}
                    <Card
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // backgroundColor: "white",
                        padding: "0rem 1.5rem 2rem 1.5rem",
                      }}
                      className="pt-2 pb-2 border-bottom "
                    >
                      <div>
                        <h2
                          className="mt-3 lead"
                          style={{
                            fontSize: "24px",
                            // , fontWeight: 100
                          }}
                        >
                          {/* {props.type} Edit */}
                          {truncateTextByWordCount(
                            `${rowdata.partner_first_name} ${rowdata.partner_last_name}`,
                            40
                          )}
                        </h2>
                        <Breadcrumb
                          className="mb-0 lead"
                          style={{ fontSize: "13px" }}
                        >
                          <Breadcrumb.Item
                            active
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/dashboard")}
                          >
                            Home
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active>Partners</Breadcrumb.Item>
                          <Breadcrumb.Item
                            active
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/${props.type.toLowerCase()}`)
                            }
                          >
                            {props.type}s
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active className="font-weight-bold">
                            {`Update ${props.type}`}
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginRight: "21px",
                        }}
                      >
                        {/* <Link to="/invoices/add">
                <div
                  style={{
                    border: "1px #E7EAEC solid",
                    borderRadius: "3px",
                    padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "#676a6c",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: "inherit",
                    cursor: "pointer",
                    marginRight: "6px",
                  }}
                >
                  <Plus
                    size={15}
                    strokeWidth={3.5}
                    stroke="#676a6c"
                    style={{ marginRight: "2px" }}
                  />
                  Btn 1
                </div>
              </Link>
              <Link to="/invoices/add">
                <div
                  style={{
                    border: "1px #E7EAEC solid",
                    borderRadius: "3px",
                    padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "#676a6c",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: "inherit",
                    cursor: "pointer",
                    marginRight: "6px",
                  }}
                >
                  <Plus
                    size={15}
                    strokeWidth={3.5}
                    stroke="#676a6c"
                    style={{ marginRight: "2px" }}
                  />
                  Btn 2
                </div>
              </Link> */}

                        <button
                          type="submit"
                          style={{
                            border: "1px #E7EAEC solid",
                            borderRadius: "3px",
                            padding: "0.375rem 0.75rem",
                            display: "flex",
                            justifyContent: "center",
                            color: "white",
                            backgroundColor: "#3f80ea",
                            // backgroundColor: "#4f83cb",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: "inherit",
                            cursor: "pointer",
                            width: "100px",
                          }}
                          disabled={disablesave}
                        >
                          <Save
                            size={15}
                            strokeWidth={2}
                            stroke="white"
                            style={{ marginRight: "8px" }}
                          />
                          <div>Save</div>
                        </button>
                        {/* </div> */}
                      </div>
                    </Card>
                    <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
                      <Row>
                        <Col lg="6">
                          <Card className="mb-2 border rounded-0">
                            <Card.Header
                              className="pb-0"
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                              }}
                            >
                              <User
                                size={18}
                                strokeWidth={3}
                                stroke="#485056"
                                style={{ marginRight: 15 }}
                              />
                              <Card.Title
                                className="mb-0"
                                style={{ fontSize: 18 }}
                              >
                                {props.type} Profile
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="First Name"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      name="first_name"
                                      onKeyDown={handleKeyDown}
                                      placeholder="Joe"
                                      value={values.first_name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.first_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.first_name}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Last Name"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      name="last_name"
                                      onKeyDown={handleKeyDown}
                                      placeholder="Kumar"
                                      value={values.last_name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.last_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.last_name}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Company Name"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="company_name"
                                  onKeyDown={handleKeyDown}
                                  placeholder="Company Name"
                                  value={values.company_name}
                                  onChange={handleChange}
                                />
                              </FloatingLabel>
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      name="email"
                                      onKeyDown={handleKeyDown}
                                      placeholder="example@mtree.com"
                                      value={values.email}
                                      onChange={handleChange}
                                      isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.email}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Phone Number"
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="tel"
                                      onKeyDown={handleKeyDown}
                                      name="number"
                                      placeholder="912346789"
                                      value={values.number}
                                      onChange={handleChange}
                                      isInvalid={!!errors.number}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.number}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                            </Card.Body>
                            {/* </Card> */}
                            <hr style={{ margin: "0 20px" }} />
                            {/* <Card className="mb-2 border rounded-0"> */}
                            <Card.Header
                              className="pb-0"
                              style={{ display: "flex" }}
                            >
                              <Sliders
                                size={18}
                                strokeWidth={3}
                                stroke="#485056"
                                style={{ marginRight: 15 }}
                              />
                              <Card.Title
                                className="mb-0"
                                style={{ fontSize: 18 }}
                              >
                                Other Details
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              {/* <FloatingLabel
                      controlId="floatingInput"
                      label="Price List"
                      className="mb-3"
                    > */}
                              {/* <Form.Label>Price List</Form.Label> */}
                              {/* <div className="d-flex gap-2">
                      <Select
                        className="react-select-container mb-3"
                        classNamePrefix="react-select"
                        value={
                          Object.entries(pricelist).length === 0
                            ? ""
                            : pricelist
                        }
                        onChange={setPricelist}
                        options={options}
                      />
                      <Button> Add PriceList</Button>
                    </div> */}
                              {/* <Row>
                      <Col md>
                        <Select
                          className="react-select-container mb-3"
                          classNamePrefix="react-select"
                          value={
                            Object.entries(pricelist).length === 0
                              ? ""
                              : pricelist
                          }
                          onChange={setPricelist}
                          options={options}
                        />
                      </Col>
                      <Col md>
                        <Button> Add PriceList</Button>
                      </Col>
                    </Row> */}
                              {/* <Select
                            className="react-select-container mb-3"
                            classNamePrefix="react-select"
                            value={
                              Object.entries(pricelist).length === 0
                                ? ""
                                : pricelist
                            }
                            onChange={setPricelist}
                            options={options}
                          /> */}
                              {/* <Form.Control
                        type="text"
                        placeholder="0"
                        isInvalid={errors.price_list}
                        name="price_list"
                        value={values.price_list}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.price_list}
                      </Form.Control.Feedback>
                    </FloatingLabel> */}
                              <Row className="g-2">
                                {/* <Col md>
                                  <FloatingLabel
                                    controlId="floatingSelect"
                                    label="Price List"
                                    className="mb-3"
                                  >
                                    <Form.Select
                                      aria-label="Floating label select example"
                                      value={pricelist}
                                      onKeyDown={handleKeyDown}
                                      onChange={(x) =>
                                        setPricelist(x.target.value)
                                      }
                                    >
                                      <option value={""}>
                                        Select Price List
                                      </option>
                                      {options.map((elem) => {
                                        return (
                                          <option
                                            value={elem.value.price_list_id}
                                          >
                                            {elem.label}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </FloatingLabel>
                                </Col> */}
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Payment Terms"
                                    className="mb-3"
                                  >
                                    <Form.Select
                                      onKeyDown={handleKeyDown}
                                      aria-label="Floating label select example"
                                      name="payment_terms"
                                      value={values.payment_terms}
                                      onChange={handleChange}
                                    >
                                      <option value={""}>
                                        Select Payment Terms
                                      </option>
                                      <option value={"Advance"}>
                                        {"Advance"}
                                      </option>

                                      <option value={"5 Days"}>
                                        {"5 Days"}
                                      </option>
                                      <option value={"7 Days"}>
                                        {"7 Days"}
                                      </option>
                                      <option value={"10 Days"}>
                                        {"10 Days"}
                                      </option>
                                      <option value={"20 Days"}>
                                        {"20 Days"}
                                      </option>
                                      <option value={"30 Days"}>
                                        {"30 Days"}
                                      </option>
                                    </Form.Select>
                                    {/* <Form.Control
                                  type="text"
                                  name="terms"
                                  placeholder="Terms"
                                  value={values.terms}
                                  onChange={handleChange}
                                /> */}
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              {/* <FloatingLabel
                            controlId="floatingInput"
                            label="Payment Terms"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="0"
                              isInvalid={errors.payment_terms}
                              name="payment_terms"
                              value={values.payment_terms}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.payment_terms}
                            </Form.Control.Feedback>
                          </FloatingLabel> */}
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Notes"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  as="textarea"
                                  placeholder="0"
                                  isInvalid={errors.notes}
                                  name="notes"
                                  value={values.notes}
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.notes}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Tax Number"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  isInvalid={errors.tax_number}
                                  name="tax_number"
                                  value={values.tax_number}
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.tax_number}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col lg="6">
                          {/* <Card>
                        <Card.Body> */}
                          {/* <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="first"
                          >
                            <Nav variant="tabs">
                              <Nav.Item>
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    setactiveTab("first");
                                  }}
                                  style={
                                    activeTab === "first"
                                      ? { borderBottom: "2px solid black" }
                                      : {}
                                  }
                                  eventKey="first"
                                >
                                  Client Info
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    setactiveTab("second");
                                  }}
                                  style={
                                    activeTab === "second"
                                      ? {
                                          borderBottom: "2px solid black",
                                        }
                                      : {}
                                  }
                                  eventKey="second"
                                >
                                  Invoices
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    setactiveTab("third");
                                  }}
                                  style={
                                    activeTab === "third"
                                      ? { borderBottom: "2px solid black" }
                                      : {}
                                  }
                                  eventKey="third"
                                >
                                  Estimates
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    setactiveTab("fourth");
                                  }}
                                  style={
                                    activeTab === "fourth"
                                      ? {
                                          borderBottom: "2px solid black",
                                        }
                                      : {}
                                  }
                                  eventKey="fourth"
                                >
                                  Orders
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <hr></hr>
                            <Tab.Content>
                              <Tab.Pane eventKey="first"> */}
                          {/* <EditComponent setPartner={setPartner} /> */}
                          <Card className="mb-2 border rounded-0">
                            <Card.Header
                              className="pb-0"
                              style={{ display: "flex" }}
                            >
                              <MapPin
                                size={18}
                                strokeWidth={3}
                                stroke="#485056"
                                style={{ marginRight: 15 }}
                              />
                              <Card.Title
                                className="mb-0"
                                style={{ fontSize: 18 }}
                              >
                                Billing Address
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              {isLoaded ? (
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef.current = ref)}
                                  onPlacesChanged={handlePlaceChanged}
                                >
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Street "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.billing_street}
                                      // name="billing_street"
                                      placeholder="text"
                                      // value={values.billing_street}
                                      // onChange={handleChange}
                                      value={billingadd.street}
                                      onChange={(e) =>
                                        setbillingadd({
                                          ...billingadd,
                                          street: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.billing_street}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </StandaloneSearchBox>
                              ) : (
                                <></>
                              )}
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="City "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.billing_city}
                                      // name="billing_city"
                                      placeholder="text"
                                      // value={values.billing_city}
                                      // onChange={handleChange}
                                      value={billingadd.city}
                                      onChange={(e) =>
                                        setbillingadd({
                                          ...billingadd,
                                          city: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.billing_city}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="State "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.billing_state}
                                      // name="billing_state"
                                      placeholder="text"
                                      // value={values.billing_state}
                                      // onChange={handleChange}
                                      value={billingadd.state}
                                      onChange={(e) =>
                                        setbillingadd({
                                          ...billingadd,
                                          state: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.billing_state}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Zip Code "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.billing_zipcode}
                                      // name="billing_zipcode"
                                      placeholder="text"
                                      // value={values.billing_zipcode}
                                      // onChange={handleChange}
                                      value={billingadd.zip_code}
                                      onChange={(e) =>
                                        setbillingadd({
                                          ...billingadd,
                                          zip_code: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.billing_zipcode}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Country "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.billing_country}
                                      // name="billing_country"
                                      placeholder="text"
                                      // value={values.billing_country}
                                      // onChange={handleChange}
                                      value={billingadd.country}
                                      onChange={(e) =>
                                        setbillingadd({
                                          ...billingadd,
                                          country: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.billing_country}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                            </Card.Body>
                            {/* </Card> */}
                            {/* Card 3 Shipping Address*/}
                            <hr style={{ margin: "0 20px" }} />
                            {/* <Card className="mb-2 border rounded-0"> */}
                            <Card.Header
                              className="pb-0"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <MapPin
                                  size={18}
                                  strokeWidth={3}
                                  stroke="#485056"
                                  style={{ marginRight: 15 }}
                                />
                                <Card.Title
                                  className="mb-0"
                                  style={{ fontSize: 18 }}
                                >
                                  Service Address
                                </Card.Title>
                              </div>
                              <Form.Check
                                id="checkbox1"
                                label="Same as Billing Address"
                                // value={sameasbilling}
                                checked={sameasbilling}
                                // name="appointment_send_confirmation"
                                // style={{ fontSize: "15px" }}
                                onChange={() => {
                                  mixpanel.track(
                                    `Same as Billing(Edit ${props.type} Web)`
                                  );
                                  setSameasbilling(!sameasbilling);
                                }}
                                type="checkbox"
                              />
                            </Card.Header>
                            <Card.Body>
                              {isLoaded ? (
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef2.current = ref)}
                                  onPlacesChanged={handlePlaceChanged2}
                                >
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Street "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.shipping_street}
                                      // name="shipping_street"
                                      placeholder="text"
                                      // value={
                                      //   sameasbilling
                                      //     ? values.billing_street
                                      //     : values.shipping_street
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.street
                                          : shippingadd.street
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          street: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.shipping_street}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </StandaloneSearchBox>
                              ) : (
                                <></>
                              )}
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="City "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.shipping_city}
                                      name="shipping_city"
                                      placeholder="text"
                                      // value={
                                      //   sameasbilling
                                      //     ? values.billing_city
                                      //     : values.shipping_city
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.city
                                          : shippingadd.city
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          city: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.shipping_city}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="State "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.shipping_state}
                                      name="shipping_state"
                                      placeholder="text"
                                      // value={
                                      //   sameasbilling
                                      //     ? values.billing_state
                                      //     : values.shipping_state
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.state
                                          : shippingadd.state
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          state: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.shipping_state}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              <Row className="g-2">
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Zip Code "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.shipping_zipcode}
                                      name="shipping_zipcode"
                                      placeholder="text"
                                      // value={
                                      //   sameasbilling
                                      //     ? values.billing_zipcode
                                      //     : values.shipping_zipcode
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.zip_code
                                          : shippingadd.zip_code
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          zip_code: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.shipping_zipcode}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col md>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Country "
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      disabled={sameasbilling ? true : false}
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      isInvalid={errors.shipping_country}
                                      name="shipping_country"
                                      placeholder="text"
                                      // value={
                                      //   sameasbilling
                                      //     ? values.billing_country
                                      //     : values.shipping_country
                                      // }
                                      // onChange={handleChange}
                                      value={
                                        sameasbilling
                                          ? billingadd.country
                                          : shippingadd.country
                                      }
                                      onChange={(e) =>
                                        setshippingadd({
                                          ...shippingadd,
                                          country: e.target.value,
                                        })
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.shipping_country}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                          {/* Card 4 Other Details */}

                          {/* Call to Action Buttons*/}

                          {/* </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <Invoices dataInvoice={dataInvoice} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="third">
                                <Estimates dataEstimate={dataEstimate} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="fourth">
                                <Orders dataOrder={dataOrder} />
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container> */}
                          {/* </Card.Body>
                      </Card> */}
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between mt-4">
                        <div>
                          {partnerPermission.includes("DELETE") ? (
                            <Button
                              variant="danger"
                              size="lg"
                              onClick={() => {
                                mixpanel.track(
                                  `Delete Partner(Edit ${props.type} Web)`,
                                  {
                                    id: rowdata.partner_id,
                                  }
                                );
                                setDeleteData({
                                  partner_id: rowdata.partner_id,
                                  partner_first_name:
                                    rowdata.partner_first_name,
                                });
                                setOpenModals(true);
                              }}
                              className="me-1 mb-1 center"
                            >
                              Delete
                            </Button>
                          ) : (
                            <></>
                          )}
                          <Button
                            variant="secondary"
                            size="lg"
                            type="reset"
                            onClick={() => {
                              mixpanel.track(`Cancel (Edit ${props.type} Web)`);
                              handleReset();
                            }}
                            className="me-1 mb-1 center"
                          >
                            Cancel
                          </Button>
                        </div>
                        <Button
                          variant="primary"
                          // disabled={!dirty}
                          size="lg"
                          type="submit"
                          className="me-1 mb-1 center"
                          disabled={disablesave}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <Modal
            show={openModals}
            onHide={() => setOpenModals(!openModals)}
            centered
          >
            <Modal.Header className="font-weight-bold" closeButton>
              Delete Product
            </Modal.Header>
            <Modal.Body className="text-left m-3">
              <p className="mb-0 font-weight-bold">
                Are you sure you want to delete{" "}
                {deleteData["partner_first_name"]}?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setOpenModals(!openModals)}
              >
                Cancel
              </Button>{" "}
              <Button
                variant="danger"
                onClick={() => {
                  deleteRecord(deleteData);
                  setOpenModals(!openModals);
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal> */}
              <Modal
                show={openModals}
                onHide={() => {
                  mixpanel.track(
                    `Close >> Delete Modal(Edit ${props.type} Web)`
                  );
                  setOpenModals(false);
                }}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Delete {capitalizeFirstLetter(props.type.toLowerCase())}
                    </h1>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Close >> Delete Modal(Edit ${props.type} Web)`
                        );

                        setOpenModals(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        // flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <p
                        style={{ margin: "15px 0px 20px", textAlign: "center" }}
                      >
                        Are you sure you want to delete this{" "}
                        {props.type.toLowerCase()}?
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Close >> Delete Modal(Edit ${props.type} Web)`
                          );
                          setOpenModals(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        disable={disabledelete}
                        onClick={() => {
                          setOpenModals(false);
                          deleteRecord(deleteData);
                          mixpanel.track(
                            `Delete >> Delete Modal(Edit ${props.type} Web)`
                          );
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={validationError}
                onHide={() => setValidationError(false)}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Error
                    </h1>
                    <div
                      onClick={() => {
                        setValidationError(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <p style={{ margin: "15px 0 20px" }}>
                        There was an error saving the form. Please check the
                        messages in the form and try again.
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        onClick={() => {
                          setValidationError(false);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* </div> */}
            </>
          ) : (
            <Page404Authenticated />
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      )}
    </>
  );
};
export default InputComponentPartner;
