import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert, Button, Card } from "react-bootstrap";

import {
  Sliders,
  BookOpen,
  PlusSquare,
  PlusCircle,
  Settings,
} from "react-feather";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../constants";
import useOuterClick from "../hooks/useOuterClick";
import useTheme from "../hooks/useTheme";
import useSidebar from "../hooks/useSidebar";
import useLayout from "../hooks/useLayout";

import empty_invoice from "../assets/img/icons/empty_invoice.png";
import empty_inventory from "../assets/img/icons/empty_inventory.png";
import empty_partner from "../assets/img/icons/empty_partner.png";
import empty_transaction from "../assets/img/icons/empty_transaction.png";
import schedule from "../assets/img/icons/schedule.png";
import mixpanel from "mixpanel-browser";
import useAuth from "../hooks/useAuth";

const themeOptions = [
  {
    name: "Default",
    value: THEME.DEFAULT,
  },
  {
    name: "Colored",
    value: THEME.COLORED,
  },
  {
    name: "Dark",
    value: THEME.DARK,
  },
  {
    name: "Light",
    value: THEME.LIGHT,
  },
];

const sidebarPositionOptions = [
  {
    name: "Left",
    value: SIDEBAR_POSITION.LEFT,
  },
  {
    name: "Right",
    value: SIDEBAR_POSITION.RIGHT,
  },
];

const sidebarBehaviorOptions = [
  {
    name: "Sticky",
    value: SIDEBAR_BEHAVIOR.STICKY,
  },
  {
    name: "Fixed",
    value: SIDEBAR_BEHAVIOR.FIXED,
  },
  {
    name: "Compact",
    value: SIDEBAR_BEHAVIOR.COMPACT,
  },
];

const layoutOptions = [
  {
    name: "Fluid",
    value: LAYOUT.FLUID,
  },
  {
    name: "Boxed",
    value: LAYOUT.BOXED,
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Settings2 = ({ navigation }) => {
  const query = useQuery();
  const [isOpen, setIsOpen] = useState(false);

  const { theme, setTheme } = useTheme();
  const { position, setPosition, behavior, setBehavior } = useSidebar();
  const { layout, setLayout } = useLayout();
  const { userPermission } = useAuth();
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";

  const navigate = useNavigate();

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  const setSettingByQueryParam = (name, set) => {
    const value = query.get(name);
    if (value) {
      set(value);
    }
  };

  // Read from query parameter (e.g. ?theme=dark)
  // only for demo purposes
  useEffect(() => {
    setSettingByQueryParam("theme", setTheme);
    setSettingByQueryParam("sidebarPosition", setPosition);
    setSettingByQueryParam("sidebarBehavior", setBehavior);
    setSettingByQueryParam("layout", setLayout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [navigate]);

  return (
    <div
      ref={innerRef}
      className={`settings js-settings ${isOpen ? "open" : ""}`}
    >
      {partnerPermission.includes("ADD") ||
      estimatePermission.includes("ADD") ||
      bookingPermission.includes("ADD") ||
      invoicePermission.includes("ADD") ||
      itemPermission.includes("ADD") ? (
        <div className="settings-toggle" style={{ zIndex: 100 }}>
          <div
            className="settings-toggle-option settings-toggle-option-text js-settings-toggle"
            // title="Theme Builder"
            onClick={() => {
              setIsOpen(true);
              mixpanel.track("Open New Shortcuts (Web)");
            }}
          >
            <Settings
              className="feather align-middle rotate"
              style={{ marginTop: 1.5, marginLeft: 1.5 }}
            />
            {/* <div>
            <FontAwesomeIcon icon={["fab", "google"]} />
          </div> */}
            {/* <FontAwesomeIcon icon="check-square" /> */}
            {/* <Sliders className="feather align-middle" /> */}
            {/* <Sliders className="feather align-middle" /> Builder */}
          </div>
          {/* <a
          className="settings-toggle-option"
          title="Documentation"
          href="/docs"
          target="_blank"
        >
          <BookOpen className="feather align-middle" />
        </a> */}
        </div>
      ) : (
        <></>
      )}
      <div className="settings-panel">
        <div className="settings-content">
          <div className="settings-title d-flex align-items-center justify-content-center">
            {/* <button
              type="button"
              className="btn-close float-right js-settings-toggle"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            ></button> */}
            <h5 className="mb-0 ms-0 d-inline-block">New Shortcuts</h5>
          </div>
          <div className="settings-body">
            {/* <Alert variant="primary">
              <div className="alert-message">
                <strong>Hey there!</strong> Set your own customized style below.
                Choose the ones that best fits your needs.
              </div>
            </Alert>

            <div className="mb-3">
              <span className="d-block font-size-lg fw-bold">Color scheme</span>
              <span className="d-block text-muted mb-2">
                The perfect color mode for your app.
              </span>
              <div className="row g-0 text-center mx-n1 mb-2">
                {themeOptions.map(({ name, value }) => (
                  <div className="col-6" key={value}>
                    <label className="mx-1 d-block mb-1">
                      <input
                        className="settings-scheme-label"
                        type="radio"
                        name="theme"
                        value={value}
                        checked={theme === value}
                        onChange={() => setTheme(value)}
                      />
                      <div className="settings-scheme">
                        <div
                          className={`settings-scheme-theme settings-scheme-theme-${value}`}
                        ></div>
                      </div>
                    </label>
                    {name}
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="mb-3">
              <span className="d-block font-size-lg fw-bold">
                Sidebar position
              </span>
              <span className="d-block text-muted mb-2">
                Toggle the position of the sidebar.
              </span>
              <div>
                {sidebarPositionOptions.map(({ name, value }) => (
                  <label className="me-1" key={value}>
                    <input
                      className="settings-button-label"
                      type="radio"
                      name="sidebarPosition"
                      value={value}
                      checked={position === value}
                      onChange={() => setPosition(value)}
                    />
                    <div className="settings-button">{name}</div>
                  </label>
                ))}
              </div>
            </div>
            <hr />
            <div className="mb-3">
              <span className="d-block font-size-lg fw-bold">
                Sidebar behavior
              </span>
              <span className="d-block text-muted mb-2">
                Change the behavior of the sidebar.
              </span>
              <div>
                {sidebarBehaviorOptions.map(({ name, value }) => (
                  <label className="me-1" key={value}>
                    <input
                      className="settings-button-label"
                      type="radio"
                      name="sidebarBehavior"
                      value={value}
                      checked={behavior === value}
                      onChange={() => setBehavior(value)}
                    />
                    <div className="settings-button">{name}</div>
                  </label>
                ))}
              </div>
            </div>
            <hr />
            <div className="mb-3">
              <span className="d-block font-size-lg fw-bold">Layout</span>
              <span className="d-block text-muted mb-2">
                Toggle container layout system.
              </span>
              <div>
                {layoutOptions.map(({ name, value }) => (
                  <label className="me-1" key={value}>
                    <input
                      className="settings-button-label"
                      type="radio"
                      name="layout"
                      value={value}
                      checked={layout === value}
                      onChange={() => setLayout(value)}
                    />
                    <div className="settings-button">{name}</div>
                  </label>
                ))}
              </div>
            </div> */}
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                // // backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "scroll",
              }}
            >
              {partnerPermission.includes("ADD") ? (
                <Card
                  style={{
                    // backgroundColor: "white",
                    // height: "100px",
                    minWidth: "120px",
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "10px",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                  onClick={() => {
                    navigate("/customer/add-customer");
                    mixpanel.track("New Customer (New Shortcuts Web)");
                  }}
                >
                  <img
                    src={empty_partner}
                    style={{ height: "30px" }}
                    alt="empty_invoice"
                  ></img>
                  <p style={{ margin: "0" }}>New Customer</p>
                </Card>
              ) : (
                <></>
              )}
              {estimatePermission.includes("ADD") ? (
                <Card
                  style={{
                    // backgroundColor: "white",
                    // height: "100px",
                    minWidth: "120px",
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "10px",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                  onClick={() => {
                    navigate("/estimates/add");

                    mixpanel.track("New Estimate (New Shortcuts Web)");
                  }}
                >
                  <img
                    src={empty_invoice}
                    style={{ height: "30px" }}
                    alt="empty_invoice"
                  ></img>
                  <p style={{ margin: "0" }}>New Estimate</p>
                </Card>
              ) : (
                <></>
              )}
              {bookingPermission.includes("ADD") ? (
                <Card
                  style={{
                    // backgroundColor: "white",
                    // height: "100px",
                    minWidth: "120px",
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "10px",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                  onClick={() => {
                    navigate("/bookings/add");

                    mixpanel.track("New Bookings (New Shortcuts Web)");
                  }}
                >
                  <img
                    src={schedule}
                    style={{ height: "30px" }}
                    alt="schedule"
                  ></img>
                  <p style={{ margin: "0" }}>New Booking</p>
                </Card>
              ) : (
                <></>
              )}
              {invoicePermission.includes("ADD") ? (
                <Card
                  style={{
                    // backgroundColor: "white",
                    // height: "100px",
                    minWidth: "120px",
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "10px",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                  onClick={() => {
                    navigate("/invoices/add");
                    mixpanel.track("New Invoices (New Shortcuts Web)");
                  }}
                >
                  <img
                    src={empty_invoice}
                    style={{ height: "30px" }}
                    alt="empty_invoice"
                  ></img>
                  <p style={{ margin: "0" }}>New Invoice</p>
                </Card>
              ) : (
                <></>
              )}
              {itemPermission.includes("ADD") ? (
                <>
                  <Card
                    style={{
                      // backgroundColor: "white",
                      // height: "100px",
                      minWidth: "120px",
                      padding: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      margin: "10px",
                      cursor: "pointer",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                    onClick={() => {
                      navigate("/products/add-item");
                      mixpanel.track("New Product (New Shortcuts Web)");
                    }}
                  >
                    <img
                      src={empty_inventory}
                      style={{ height: "30px" }}
                      alt="empty_invoice"
                    ></img>
                    <p style={{ margin: "0" }}>New Product</p>
                  </Card>
                  <Card
                    style={{
                      // backgroundColor: "white",
                      // height: "100px",
                      minWidth: "120px",
                      padding: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      margin: "10px",
                      cursor: "pointer",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                    onClick={() => {
                      navigate("/services/add-item");
                      mixpanel.track("New Service (New Shortcuts Web)");
                    }}
                  >
                    <img
                      src={empty_inventory}
                      style={{ height: "30px" }}
                      alt="empty_invoice"
                    ></img>
                    <p style={{ margin: "0" }}>New Service</p>
                  </Card>
                </>
              ) : (
                <></>
              )}
              {partnerPermission.includes("ADD") ? (
                <Card
                  style={{
                    // backgroundColor: "white",
                    // height: "100px",
                    minWidth: "120px",
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "10px",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                  onClick={() => {
                    navigate("/supplier/add-supplier");
                    mixpanel.track("New Supplier (New Shortcuts Web)");
                  }}
                >
                  <img
                    src={empty_partner}
                    style={{ height: "30px" }}
                    alt="empty_invoice"
                  ></img>
                  <p style={{ margin: "0" }}>New Supplier</p>
                </Card>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* <div className="settings-footer">
            <div className="d-grid">
              <Button
                as="a"
                rel="noreferrer"
                href="https://themes.getbootstrap.com/product/appstack-react-admin-dashboard-template/"
                target="_blank"
                variant="primary"
                size="lg"
              >
                Purchase
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Settings2;
