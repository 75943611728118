import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertCircle,
  ArrowLeft,
  AtSign,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Copy,
  Crosshair,
  Delete,
  Edit,
  Edit2,
  Eye,
  FileText,
  Image,
  Save,
  Settings,
  Trash2,
  User,
  Users,
  X,
} from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Edittimesheetmodal from "../../components/Edittimesheetmodal";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import mixpanel from "mixpanel-browser";
import Newtimesheetmodal from "../../components/Newtimesheetmodal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faCopy,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import UploadHelper from "../../utils/UploadHelper";
import { logError } from "../../utils/useErrorlogging";

const WebsiteCustomization = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState("general");
  const [websiteData, setWebsiteData] = useState({});
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [teammodalObject, setTeammodalObject] = useState({});
  const [imagemodalObject, setImagemodalObject] = useState({});
  const [teameditIndex, setTeameditIndex] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [modalError, setModalError] = useState("");
  const [saveloader, setSaveloader] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  // const [appname, setAppName] = useState("");
  // const [heroImage, setHeroImage] = useState("");
  // const [servicelist, setServiceList] = useState([]);
  // const [aboutus, setAboutus] = useState({});
  // const [team, setTeam] = useState([]);
  // const [gallery, setGallery] = useState([]);
  const [id, setId] = useState("");
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const fetchdata = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/portal/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setWebsiteData(result && result.portal ? result.portal : {});
          let servicelength =
            result &&
            result.portal &&
            result.portal.services_list &&
            result.portal.services_list.length !== 0
              ? 4 - result.portal.services_list.length
              : 4;
          // console.log(
          //   result.portal &&
          //     result.portal.services_list &&
          //     result.portal.services_list.length !== 0,
          //   result.portal.services_list.length,
          //   servicelength
          // );

          setServiceData(
            result &&
              result.portal &&
              result.portal.services_list &&
              result.portal.services_list.length !== 0
              ? result.portal.services_list.length < 4
                ? [
                    ...result.portal.services_list,
                    ...Array(servicelength).fill({}),
                  ]
                : result.portal.services_list
              : [{}, {}, {}, {}]
          );
          // console.log([
          //   ...result.portal.services_list,
          //   ...Array(result.portal.services_list.length - 4).fill({}),
          // ]);
          setLoading(false);
          setId(result.business.business_app_name);
          console.log(result);
        })
        .catch((err) => console.log(err));
    }
  };

  const submit = async () => {
    console.log("Asdfsfd");
    let obj = { ...websiteData, business_app_name: id };
    if (!obj.business_app_name) {
      // Alert.alert("Error", "App Name is Required");
      setValidationError("App Name is Required.");
      setSaveloader(false);
      return;
    }
    const regex = /^[a-zA-Z0-9]+$/;

    if (!regex.test(obj.business_app_name)) {
      setValidationError("App name contains Invalid Characters.");
      setSaveloader(false);
      return;
    }
    if (obj.business_app_name && obj.business_app_name.length >= 30) {
      setValidationError("App name should not be more than 30 characters.");
      setSaveloader(false);
      return;
    }
    let filteredServices = [];
    // if service descriptin is there and no service title throw error
    if (serviceData && serviceData.length !== 0) {
      const validKeys = ["title", "description"];
      filteredServices = serviceData.filter(
        (obj) =>
          obj &&
          Object.keys(obj).length !== 0 &&
          (obj.title !== "" || obj.description !== "") &&
          Object.values(obj).some((value) => value !== "")
      );
      const result = filteredServices.some(
        (obj) => !obj.hasOwnProperty("title") || obj.title.trim() === ""
      );
      console.log(result, serviceData, filteredServices, result);
      // console.log(filteredServices.some((obj)=>))

      if (result) {
        setSaveloader(false);

        setValidationError("Service Name Could not be Empty.");
        return;
      }
    }

    console.log({ ...obj, services_list: filteredServices });
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      const response = await fetch("https://bestmate.us/api/portal/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify({ ...obj, services_list: filteredServices }),
      });
      // console.log(response.status);
      if (response.status >= 200 && response.status <= 300) {
        notyf.open({
          type: isOpen ? "home" : "full",
          message: "Website Data Updated Sucessfully",
          ripple: "true",
          dismissible: "true",
        });
        navigate("/business-website");
      } else {
        let res = await response.json();

        if (res && res.app_name_exists) {
          setValidationError(
            "This app name is already taken, please choose another"
          );
          setSaveloader(false);
        } else {
          notyf.open({
            type: isOpen ? "homewarning" : "fullwarning",
            message: `Something went wrong.`,
            ripple: "true",
            dismissible: "true",
          });
          setSaveloader(false);
          // mixpanel.track(`Fetch Error (Website Customization Web)`, {
          //   error: "website customization save api",
          // });
          logError({
            error: "fetch error",
            api: "website customization save api",
            component: `Website Customization Web`,
          });
        }
      }
    } else {
      setSaveloader(false);
      // mixpanel.track(`Error (Website Customization Web)`, {
      //   error: "no user",
      // });
      logError({
        error: "no user",
        component: "Website Customization Web",
      });
    }
  };

  useEffect(() => {
    mixpanel.track(`On Business Website Customization (Web)`);
    setSelected("general");
    fetchdata();
  }, []);
  console.log(serviceData);
  return (
    <React.Fragment>
      <Helmet title="Website Customization" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      ) : (
        <Container fluid className="p-0">
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor: "white",
              padding: "0rem 1.5rem 2rem 1.5rem",
            }}
            className="pt-2 pb-2 border-bottom "
          >
            <div>
              <h2
                className="mt-3 lead"
                style={{
                  fontSize: "24px",
                  // , fontWeight: 100
                }}
              >
                Website Customization
              </h2>
              <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                <Breadcrumb.Item
                  active
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  active
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/business-website")}
                >
                  Business Website
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="font-weight-bold">
                  Website Customization
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "21px",
              }}
            >
              <button
                onClick={() => {
                  mixpanel.track(`Save (Website Customization Web)`);
                  if (saveloader) {
                    return;
                  }
                  console.log("ASDf");
                  setSaveloader(true);
                  submit();
                }}
                // type="submit"
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3f80ea",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  width: "100px",
                }}
              >
                {saveloader ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    className="m-1"
                    size="sm"
                  />
                ) : (
                  <>
                    <Save
                      size={15}
                      strokeWidth={2}
                      stroke="white"
                      style={{ marginRight: "8px" }}
                    />
                    <div>Save</div>
                  </>
                )}
              </button>
            </div>
          </Card>
          <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
            <Row style={{ display: "flex" }}>
              <Col md={3}>
                <Card
                  className="mb-2 border rounded-0"
                  // style={{ minHeight: 00 }}
                >
                  {/* <Card.Header> */}

                  {/* </Card.Header> */}
                  <Card.Body>
                    <div
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        color: "black",
                        marginBottom: 10,
                      }}
                    >
                      Customizations
                    </div>
                    <div>
                      <div
                        className="website-custom-menu"
                        style={
                          selected === "general" ? { color: "#558FED" } : {}
                        }
                        onClick={() => {
                          mixpanel.track(
                            `General Tab (Website Customization Web)`
                          );

                          setSelected("general");
                        }}
                      >
                        <Settings
                          size={18}
                          strokeWidth={2}
                          // stroke="#495057"
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>General</div>
                      </div>
                      <div
                        className="website-custom-menu"
                        style={selected === "hero" ? { color: "#558FED" } : {}}
                        onClick={() => {
                          mixpanel.track(
                            `Hero Image Tab (Website Customization Web)`
                          );
                          setSelected("hero");
                        }}
                      >
                        <Image
                          size={18}
                          strokeWidth={2}
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>Hero Image</div>
                      </div>
                      <div
                        style={
                          selected === "service" ? { color: "#558FED" } : {}
                        }
                        className="website-custom-menu"
                        onClick={() => {
                          mixpanel.track(
                            `Service Tab (Website Customization Web)`
                          );
                          setSelected("service");
                        }}
                      >
                        <Settings
                          size={18}
                          strokeWidth={2}
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>Services</div>
                      </div>
                      <div
                        style={selected === "about" ? { color: "#558FED" } : {}}
                        className="website-custom-menu"
                        onClick={() => {
                          mixpanel.track(
                            `About Us Tab (Website Customization Web)`
                          );
                          setSelected("about");
                        }}
                      >
                        <User
                          size={18}
                          strokeWidth={2}
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>About Us</div>
                      </div>
                      <div
                        style={selected === "team" ? { color: "#558FED" } : {}}
                        className="website-custom-menu"
                        onClick={() => {
                          mixpanel.track(
                            `Our Team Tab (Website Customization Web)`
                          );
                          setSelected("team");
                        }}
                      >
                        <Users
                          size={18}
                          strokeWidth={2}
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>Our Team</div>
                      </div>
                      <div
                        style={
                          selected === "gallery" ? { color: "#558FED" } : {}
                        }
                        className="website-custom-menu"
                        onClick={() => {
                          mixpanel.track(
                            `Image Gallery (Website Customization Web)`
                          );
                          setSelected("gallery");
                        }}
                      >
                        <Image
                          size={18}
                          strokeWidth={2}
                          style={{ cursor: "pointer", marginRight: 15 }}
                        />
                        <div style={{ fontSize: 14.5 }}>Image Gallery</div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={9}>
                {selected === "general" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        General
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <AtSign
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            App Name
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          <div>
                            Will be used as the unique app name to identify your
                            website
                          </div>
                          <Form.Group className="mt-2 mb-3">
                            <Form.Control
                              type="text"
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="App Name"
                              value={id}
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setId(e.target.value);
                              }}
                              style={{ padding: "8px" }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
                {selected === "hero" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Hero Image
                      </div>
                      <div style={{ marginTop: 10 }}>
                        This image will be used in the first section of your
                        website landing page
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            Hero Image
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          {websiteData && websiteData.hero_image ? (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  width: 250,
                                  height: 150,
                                  backgroundColor: "#f7f7f7",
                                  borderRadius: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={websiteData.hero_image}
                                  style={{ maxWidth: 250, maxHeight: 150 }}
                                />
                              </div>
                              <Trash2
                                size={18}
                                strokeWidth={2}
                                style={{ cursor: "pointer", margin: 15 }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Remove Hero Image Tab (Website Customization Web)`
                                  );
                                  setWebsiteData((old) => {
                                    return { ...old, hero_image: "" };
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <UploadHelper
                              setWebsiteData={setWebsiteData}
                              type="hero"
                            />
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
                {selected === "service" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Services
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Edit and manage services that your business offers.
                        These services will be shown on landing page of your
                        website
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            Service # 1
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          <Form.Group className="mt-3 mb-2">
                            <Form.Label style={{ color: "black" }}>
                              Service Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={15}
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Name"
                              value={
                                Object.keys(serviceData[0]).includes("title")
                                  ? serviceData[0].title
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[0] = { ...old[0], title: e.target.value };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[0]).includes(
                                    "title"
                                  ) && serviceData[0].title.length > 15
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[0]).includes("title") &&
                              serviceData[0].title.length
                                ? serviceData[0].title.length
                                : 0
                            } / 15`}</div>
                          </Form.Group>
                          <Form.Group className="mt-1 mb-3">
                            <Form.Label style={{ color: "black" }}>
                              Service Detail
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={120}
                              // onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Detail"
                              as="textarea"
                              rows="3"
                              value={
                                Object.keys(serviceData[0]).includes(
                                  "description"
                                )
                                  ? serviceData[0].description
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[0] = {
                                    ...old[0],
                                    description: e.target.value,
                                  };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[0]).includes(
                                    "description"
                                  ) && serviceData[0].description.length > 120
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[0]).includes(
                                "description"
                              ) && serviceData[0].description.length
                                ? serviceData[0].description.length
                                : 0
                            } / 120`}</div>
                          </Form.Group>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            Service # 2
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          <Form.Group className="mt-3 mb-2">
                            <Form.Label style={{ color: "black" }}>
                              Service Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={15}
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Name"
                              value={
                                Object.keys(serviceData[1]).includes("title")
                                  ? serviceData[1].title
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[1] = { ...old[1], title: e.target.value };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[1]).includes(
                                    "title"
                                  ) && serviceData[1].title.length > 15
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[1]).includes("title") &&
                              serviceData[1].title.length
                                ? serviceData[1].title.length
                                : 0
                            } / 15`}</div>
                          </Form.Group>
                          <Form.Group className="mt-1 mb-3">
                            <Form.Label style={{ color: "black" }}>
                              Service Detail
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={120}
                              // onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Detail"
                              as="textarea"
                              rows="3"
                              value={
                                Object.keys(serviceData[1]).includes(
                                  "description"
                                )
                                  ? serviceData[1].description
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[1] = {
                                    ...old[1],
                                    description: e.target.value,
                                  };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[1]).includes(
                                    "description"
                                  ) && serviceData[1].description.length > 120
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[1]).includes(
                                "description"
                              ) && serviceData[1].description.length
                                ? serviceData[1].description.length
                                : 0
                            } / 120`}</div>
                          </Form.Group>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            Service # 3
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          <Form.Group className="mt-3 mb-2">
                            <Form.Label style={{ color: "black" }}>
                              Service Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={15}
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Name"
                              value={
                                Object.keys(serviceData[2]).includes("title")
                                  ? serviceData[2].title
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[2] = { ...old[2], title: e.target.value };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[2]).includes(
                                    "title"
                                  ) && serviceData[2].title.length > 15
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[2]).includes("title") &&
                              serviceData[2].title.length
                                ? serviceData[2].title.length
                                : 0
                            } / 15`}</div>
                          </Form.Group>
                          <Form.Group className="mt-1 mb-3">
                            <Form.Label style={{ color: "black" }}>
                              Service Detail
                            </Form.Label>
                            <Form.Control
                              maxLength={120}
                              type="text"
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Detail"
                              as="textarea"
                              rows="3"
                              value={
                                Object.keys(serviceData[2]).includes(
                                  "description"
                                )
                                  ? serviceData[2].description
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[2] = {
                                    ...old[2],
                                    description: e.target.value,
                                  };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[2]).includes(
                                    "description"
                                  ) && serviceData[2].description.length > 120
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[2]).includes(
                                "description"
                              ) && serviceData[2].description.length
                                ? serviceData[2].description.length
                                : 0
                            } / 120`}</div>
                          </Form.Group>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            Service # 4
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          <Form.Group className="mt-3 mb-2">
                            <Form.Label style={{ color: "black" }}>
                              Service Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={15}
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Name"
                              value={
                                Object.keys(serviceData[3]).includes("title")
                                  ? serviceData[3].title
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[3] = { ...old[3], title: e.target.value };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[3]).includes(
                                    "title"
                                  ) && serviceData[3].title.length > 15
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[3]).includes("title") &&
                              serviceData[3].title.length
                                ? serviceData[3].title.length
                                : 0
                            } / 15`}</div>
                          </Form.Group>
                          <Form.Group className="mt-1 mb-3">
                            <Form.Label style={{ color: "black" }}>
                              Service Detail
                            </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={120}
                              onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="Service Detail"
                              as="textarea"
                              rows="3"
                              value={
                                Object.keys(serviceData[3]).includes(
                                  "description"
                                )
                                  ? serviceData[3].description
                                  : ""
                              }
                              onChange={(e) => {
                                setServiceData((old) => {
                                  let obj = [...old];
                                  obj[3] = {
                                    ...old[3],
                                    description: e.target.value,
                                  };
                                  return obj;
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(serviceData[3]).includes(
                                    "description"
                                  ) && serviceData[3].description.length > 120
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(serviceData[3]).includes(
                                "description"
                              ) && serviceData[3].description.length
                                ? serviceData[3].description.length
                                : 0
                            } / 120`}</div>
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
                {selected === "about" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        About Us
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Add details about your business to be displayed on your
                        website landing page
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            About Us - Image
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: 5,
                            marginLeft: 32,
                            marginBottom: 20,
                          }}
                        >
                          {websiteData &&
                          websiteData.about_us &&
                          websiteData.about_us.image ? (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  width: 250,
                                  height: 150,
                                  backgroundColor: "#f7f7f7",
                                  borderRadius: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={websiteData.about_us.image}
                                  style={{ maxWidth: 250, maxHeight: 150 }}
                                />
                              </div>
                              <Trash2
                                size={18}
                                strokeWidth={2}
                                style={{ cursor: "pointer", margin: 15 }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Remove About Us Image (Website Customization Web)`
                                  );
                                  setWebsiteData((old) => {
                                    let oldabout = old.about_us;
                                    return {
                                      ...old,
                                      about_us: { ...oldabout, image: "" },
                                    };
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <UploadHelper
                              setWebsiteData={setWebsiteData}
                              type="about"
                            />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <FileText
                            size={18}
                            strokeWidth={2}
                            style={{ cursor: "pointer", marginRight: 15 }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 16,
                            }}
                          >
                            About Us - Description
                          </div>
                        </div>
                        <div style={{ marginTop: 5, marginLeft: 32 }}>
                          <Form.Group className="mt-2 mb-3">
                            <Form.Control
                              type="text"
                              // onKeyDown={handleKeyDown}
                              // name="quantityperbox"
                              placeholder="About Us"
                              as={"textarea"}
                              rows={3}
                              value={
                                (Object.keys(websiteData).includes(
                                  "about_us"
                                ) &&
                                  Object.keys(websiteData.about_us).length !==
                                    0 &&
                                  websiteData.about_us.description) ||
                                ""
                              }
                              maxLength={600}
                              onChange={(e) => {
                                setWebsiteData((old) => {
                                  let oldabout =
                                    Object.keys(old).includes("about_us") &&
                                    Object.keys(old.about_us).length !== 0
                                      ? old.about_us
                                      : {};
                                  return {
                                    ...old,
                                    about_us: {
                                      ...oldabout,
                                      description: e.target.value,
                                    },
                                  };
                                });
                              }}
                              style={{ padding: "8px" }}
                            />
                            <div
                              style={{
                                // position: "absolute",
                                // right: 35,
                                // bottom: -20,
                                marginTop: 5,
                                textAlign: "end",
                                fontSize: 11,
                                color:
                                  Object.keys(websiteData).includes(
                                    "about_us"
                                  ) &&
                                  Object.keys(websiteData.about_us).length !==
                                    0 &&
                                  Object.keys(websiteData.about_us).includes(
                                    "description"
                                  ) &&
                                  websiteData.about_us.description.length > 600
                                    ? "red"
                                    : "grey",
                              }}
                            >{`${
                              Object.keys(websiteData).includes("about_us") &&
                              Object.keys(websiteData.about_us).length !== 0 &&
                              Object.keys(websiteData.about_us).includes(
                                "description"
                              ) &&
                              websiteData.about_us.description.length
                                ? websiteData.about_us.description.length
                                : 0
                            } / 600`}</div>
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
                {selected === "team" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Our Team
                        </div>
                        <div
                          style={{ color: "#558FED", cursor: "pointer" }}
                          onClick={() => {
                            mixpanel.track(
                              `Add Team Member (Website Customization Web)`
                            );
                            setShowTeamModal(true);
                          }}
                        >
                          Add Team Member
                        </div>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Edit and manage team members to be displayed on your
                        website landing page
                      </div>
                      {!websiteData.team_members ||
                      websiteData.team_members.length === 0 ? (
                        <div
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ fontSize: 20, fontWeight: "bold" }}>
                            No Team Member Added
                          </div>
                          <div
                            style={{
                              color: "#558FED",
                              cursor: "pointer",
                              backgroundColor: "#e6eefc",
                              padding: 10,
                              marginTop: 20,
                            }}
                            onClick={() => {
                              mixpanel.track(
                                `Add Team Member (Website Customization Web)`
                              );
                              setShowTeamModal(true);
                            }}
                          >
                            Add Team Member
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginTop: 20 }}>
                          <Row>
                            {websiteData.team_members.map((elem, index) => {
                              return (
                                <Col>
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginTop: 20,
                                        maxWidth: 330,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <User
                                          size={18}
                                          strokeWidth={2}
                                          style={{
                                            cursor: "pointer",
                                            marginRight: 15,
                                          }}
                                        />
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            color: "black",
                                            fontSize: 16,
                                          }}
                                        >
                                          Team Member # {index + 1}
                                        </div>
                                      </div>
                                      <div>
                                        <X
                                          size={20}
                                          strokeWidth={2}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setWebsiteData((old) => {
                                              let newarr = [
                                                ...old.team_members,
                                              ];
                                              newarr.splice(index, 1);
                                              // let arr = newarr.filter((elem, i) => i !== index);
                                              console.log(newarr);
                                              return {
                                                ...old,
                                                team_members: newarr,
                                              };
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: 15,
                                        marginBottom: 10,
                                        marginLeft: 32,
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div>
                                        {elem.image ? (
                                          <div style={{}}>
                                            <div
                                              style={{
                                                width: 250,
                                                height: 150,
                                                backgroundColor: "#f7f7f7",
                                                borderRadius: 5,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={elem.image}
                                                style={{
                                                  maxWidth: 250,
                                                  maxHeight: 150,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {elem.name ? (
                                          <div style={{}}>
                                            <div
                                              style={{
                                                width: 250,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: 16,
                                                color: "black",
                                                marginTop: 20,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {elem.name}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {elem.description ? (
                                          <div style={{}}>
                                            <div
                                              style={{
                                                width: 250,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: 0,
                                              }}
                                            >
                                              {elem.description}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div style={{ marginLeft: 30 }}>
                                        <Edit2
                                          size={20}
                                          strokeWidth={2}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setTeammodalObject(elem);
                                            setTeameditIndex(index);
                                            setShowTeamModal(true);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
                {selected === "gallery" ? (
                  <Card
                    className="mb-2 border rounded-0"
                    // style={{ minHeight: 300 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Image Gallery
                        </div>
                        <div
                          style={{ color: "#558FED", cursor: "pointer" }}
                          onClick={() => {
                            mixpanel.track(
                              `Add Image (Website Customization Web)`
                            );
                            setShowImageModal(true);
                          }}
                        >
                          Add Image
                        </div>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Add multiple images of your business to be displayed on
                        your website landing page
                      </div>
                      {/* {true ? (
                        <div
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ fontSize: 20, fontWeight: "bold" }}>
                            No Image Added
                          </div>
                          <div
                            style={{
                              color: "#558FED",
                              cursor: "pointer",
                              backgroundColor: "#e6eefc",
                              padding: 10,
                              marginTop: 20,
                            }}
                            // onClick={() => setShowTeamModal(true)}
                          >
                            Add Image
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}
                      {!websiteData.gallery_images ||
                      websiteData.gallery_images.length === 0 ? (
                        <div
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ fontSize: 20, fontWeight: "bold" }}>
                            No Image Added
                          </div>
                          <div
                            style={{
                              color: "#558FED",
                              cursor: "pointer",
                              backgroundColor: "#e6eefc",
                              padding: 10,
                              marginTop: 20,
                            }}
                            onClick={() => {
                              mixpanel.track(
                                `Add Image (Website Customization Web)`
                              );
                              setShowImageModal(true);
                            }}
                          >
                            Add Image
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginTop: 20 }}>
                          <Row>
                            {websiteData.gallery_images.map((elem, index) => {
                              return (
                                <Col md="6">
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginTop: 20,
                                        maxWidth: 330,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <User
                                          size={18}
                                          strokeWidth={2}
                                          style={{
                                            cursor: "pointer",
                                            marginRight: 15,
                                          }}
                                        />
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            color: "black",
                                            fontSize: 16,
                                          }}
                                        >
                                          Image # {index + 1}
                                        </div>
                                      </div>
                                      <div>
                                        <X
                                          size={20}
                                          strokeWidth={2}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setWebsiteData((old) => {
                                              let newarr = [
                                                ...old.gallery_images,
                                              ];
                                              newarr.splice(index, 1);
                                              // let arr = newarr.filter((elem, i) => i !== index);
                                              console.log(newarr);
                                              return {
                                                ...old,
                                                gallery_images: newarr,
                                              };
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: 15,
                                        marginBottom: 10,
                                        marginLeft: 32,
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div>
                                        {elem.image ? (
                                          <div style={{}}>
                                            <div
                                              style={{
                                                width: 250,
                                                height: 150,
                                                backgroundColor: "#f7f7f7",
                                                borderRadius: 5,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={elem.image}
                                                style={{
                                                  maxWidth: 250,
                                                  maxHeight: 150,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
          <Modal
            show={showTeamModal}
            onHide={() => {
              // setSelected(null);
              setShowTeamModal(!showTeamModal);
              setTeameditIndex(null);
              setTeammodalObject({});
              setModalError("");
            }}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  {teameditIndex === null
                    ? "Add Team Member"
                    : "Edit Team Member"}
                </h1>
                <div
                  onClick={() => {
                    // setSelected(null);
                    setShowTeamModal(!showTeamModal);
                    setTeameditIndex(null);

                    setTeammodalObject({});
                    setModalError("");
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 52,
                    marginBottom: 20,
                  }}
                >
                  {teammodalObject && teammodalObject.image ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          width: 250,
                          height: 150,
                          backgroundColor: "#f7f7f7",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={teammodalObject.image}
                          style={{ maxWidth: 250, maxHeight: 150 }}
                        />
                      </div>
                      <Trash2
                        size={18}
                        strokeWidth={2}
                        style={{ cursor: "pointer", margin: 15 }}
                        onClick={() => {
                          mixpanel.track(
                            `Remove Team Member Image (Website Customization Web)`
                          );
                          setTeammodalObject((old) => {
                            return {
                              ...old,
                              image: "",
                            };
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <UploadHelper
                      setWebsiteData={setTeammodalObject}
                      type="team"
                    />
                  )}
                </div>
                <Form.Group className="mt-3 mb-2">
                  <Form.Label style={{ color: "black" }}>Name</Form.Label>
                  <Form.Control
                    type="text"
                    onKeyDown={handleKeyDown}
                    // name="quantityperbox"
                    placeholder="Name"
                    value={teammodalObject.name ? teammodalObject.name : ""}
                    onChange={(e) => {
                      setTeammodalObject((old) => {
                        return {
                          ...old,
                          name: e.target.value,
                        };
                      });
                    }}
                    maxLength={35}
                    style={{ padding: "8px" }}
                  />
                  <div
                    style={{
                      // position: "absolute",
                      // right: 35,
                      // bottom: -20,
                      textAlign: "end",
                      marginTop: 5,
                      fontSize: 11,
                      color:
                        teammodalObject &&
                        teammodalObject.name &&
                        teammodalObject.name.length > 35
                          ? "red"
                          : "grey",
                    }}
                  >{`${
                    teammodalObject && teammodalObject.name
                      ? teammodalObject.name.length
                      : 0
                  } / 35`}</div>
                </Form.Group>
                <Form.Group className="mt-1 mb-3">
                  <Form.Label style={{ color: "black" }}>Work Title</Form.Label>
                  <Form.Control
                    type="text"
                    onKeyDown={handleKeyDown}
                    // name="quantityperbox"
                    placeholder="Work Title"
                    maxLength={35}
                    value={
                      teammodalObject.description
                        ? teammodalObject.description
                        : ""
                    }
                    onChange={(e) => {
                      setTeammodalObject((old) => {
                        return {
                          ...old,
                          description: e.target.value,
                        };
                      });
                    }}
                    style={{ padding: "8px" }}
                  />
                  <div
                    style={{
                      // position: "absolute",
                      // right: 35,
                      // bottom: -20,
                      textAlign: "end",
                      marginTop: 5,
                      fontSize: 11,
                      color:
                        teammodalObject &&
                        teammodalObject.description &&
                        teammodalObject.description.length > 35
                          ? "red"
                          : "grey",
                    }}
                  >{`${
                    teammodalObject && teammodalObject.description
                      ? teammodalObject.description.length
                      : 0
                  } / 35`}</div>
                </Form.Group>
                <hr style={{ margin: 0 }} />
                {modalError ? (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <AlertCircle size={15} strokeWidth={2} stroke="#d8534e" />
                    <div
                      style={{
                        color: "#d8534e",
                        fontSize: 11,
                        fontWeight: "400",
                        marginLeft: 5,
                      }}
                    >
                      {modalError}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      marginRight: 5,
                      padding: "5px 10px",
                      backgroundColor: "white",
                      borderWidth: 1,
                    }}
                    onClick={() => {
                      // setSelected(null);
                      setShowTeamModal(!showTeamModal);
                      setTeameditIndex(null);

                      setTeammodalObject({});
                      setModalError("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    onClick={() => {
                      {
                        if (
                          !teammodalObject.hasOwnProperty("image") ||
                          teammodalObject.image.trim() === ""
                        ) {
                          setModalError(
                            "Image & Name are required field for adding Team member."
                          );
                          return;
                        }
                        if (
                          !teammodalObject.hasOwnProperty("name") ||
                          teammodalObject.name.trim() === ""
                        ) {
                          setModalError(
                            "Image & Name are required field for adding Team member."
                          );
                          return;
                        }
                        teameditIndex === null
                          ? setWebsiteData((old) => {
                              let arr =
                                old.team_members &&
                                old.team_members.length !== 0
                                  ? old.team_members
                                  : [];

                              return {
                                ...old,
                                team_members: [...arr, teammodalObject],
                              };
                            })
                          : setWebsiteData((old) => {
                              let arr =
                                old.team_members &&
                                old.team_members.length !== 0
                                  ? old.team_members
                                  : [];
                              let obj =
                                old.team_members &&
                                old.team_members.length !== 0 &&
                                Object.keys(old.team_members[teameditIndex])
                                  .length !== 0
                                  ? old.team_members[teameditIndex]
                                  : {};
                              arr[teameditIndex] = { ...teammodalObject };
                              return {
                                ...old,
                                team_members: [...arr],
                              };
                            });
                      }
                      setShowTeamModal(!showTeamModal);
                      setTeameditIndex(null);
                      setTeammodalObject({});
                      setModalError("");
                    }}
                  >
                    {teameditIndex === null ? "Add" : "Update"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showImageModal}
            onHide={() => {
              setShowImageModal(!showImageModal);
              setImagemodalObject({});
              setModalError("");
            }}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Add Image
                </h1>
                <div
                  onClick={() => {
                    setShowImageModal(!showImageModal);
                    setImagemodalObject({});
                    setModalError("");
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 52,
                    marginBottom: 20,
                  }}
                >
                  {imagemodalObject && imagemodalObject.image ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          width: 250,
                          height: 150,
                          backgroundColor: "#f7f7f7",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={imagemodalObject.image}
                          style={{ maxWidth: 250, maxHeight: 150 }}
                        />
                      </div>
                      <Trash2
                        size={18}
                        strokeWidth={2}
                        style={{ cursor: "pointer", margin: 15 }}
                        onClick={() =>
                          setImagemodalObject((old) => {
                            return {
                              image: "",
                            };
                          })
                        }
                      />
                    </div>
                  ) : (
                    <UploadHelper
                      setWebsiteData={setImagemodalObject}
                      type="image"
                    />
                  )}
                </div>

                <hr style={{ margin: 0 }} />
                {modalError ? (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <AlertCircle size={15} strokeWidth={2} stroke="#d8534e" />
                    <div
                      style={{
                        color: "#d8534e",
                        fontSize: 11,
                        fontWeight: "400",
                        marginLeft: 5,
                      }}
                    >
                      {modalError}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      marginRight: 5,
                      padding: "5px 10px",
                      backgroundColor: "white",
                      borderWidth: 1,
                    }}
                    onClick={() => {
                      setShowImageModal(!showImageModal);
                      setImagemodalObject({});
                      setModalError("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    onClick={() => {
                      if (
                        !imagemodalObject.hasOwnProperty("image") ||
                        imagemodalObject.image.trim() === ""
                      ) {
                        setModalError(
                          "Image is required for adding Image to Gallery."
                        );
                        return;
                      }
                      setWebsiteData((old) => {
                        let arr =
                          old.gallery_images && old.gallery_images.length !== 0
                            ? old.gallery_images
                            : [];

                        return {
                          ...old,
                          gallery_images: [...arr, imagemodalObject],
                        };
                      });
                      setShowImageModal(!showImageModal);
                      setImagemodalObject({});
                      setModalError("");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={validationError}
            onHide={() => setValidationError("")}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Error
                </h1>
                <div
                  onClick={() => {
                    setValidationError("");
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p style={{ margin: "15px 0 20px" }}>{validationError}</p>
                </div>

                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    onClick={() => {
                      setValidationError("");
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </React.Fragment>
  );
};
export default WebsiteCustomization;
